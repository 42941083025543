import React from 'react';

function SvgSite(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <defs>
        <path id="site_svg__a" d="M0 .802h8.812V15.4H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M20.402 0H8.834c-.85 0-1.54.69-1.54 1.54v20.39h13.108c.85 0 1.54-.687 1.54-1.538V1.539c0-.85-.69-1.539-1.54-1.539m-.018 1.559l.018 18.814H8.852L8.833 1.56h11.55"
        />
        <g transform="translate(0 6.532)">
          <mask id="site_svg__b" fill="currentColor">
            <use xlinkHref="#site_svg__a" />
          </mask>
          <path
            fill="currentColor"
            d="M8.812.802H1.515C.678.802 0 1.48 0 2.317v11.567c0 .837.68 1.516 1.516 1.516h7.297V.802zM7.277 2.337V13.87l-5.742.014-.02-11.547h5.762z"
            mask="url(#site_svg__b)"
          />
        </g>
        <path
          fill="currentColor"
          d="M11.507 7.31h2.177V5.134h-2.177zm4.046 0h2.176V5.134h-2.176zm-4.046 4.135h2.177V9.269h-2.177zm4.046 0h2.176V9.269h-2.176z"
        />
      </g>
    </svg>
  );
}

export default SvgSite;
