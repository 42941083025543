import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  screenContainer: {
    overflow: "auto",
    backgroundColor: "rgba(127, 118, 146, 0.25)",
    maxHeight: "calc(100% - 156px)",
    minHeight: "calc(100% - 156px)"
  }
}));
export default useStyles;
