import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  view: {
    width: '100%',
    height: '100%'
  },

  main: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.5em',
    alignItems: 'center',
    height: 'auto',
    width: '200px',
    paddingTop: '7px',
    // padding: '0 10px 0 10px',
    flex: 1,
    background: 'white', //theme.palette.primary.dark,
    '&>div>header': {
      background: 'white',// '#556972',
      borderTop: 'none',
      color: 'black',
      justifyContent: 'center'
    },
    '&>div>header:hover': {
      background: 'white',// '#556972',
      borderTop: 'none',
      color: 'black'
    },
    '&>div>header>span': {
      padding: '0.2em'
    }
  },

  modal: {
    '&>div': {
      top: '10%'
    },
    '&>div>div': {
      top: '0',
      width: '100%'
    }
  },

  select: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: 'black',
    marginBottom: '10px',
    '&>div': {
      padding: '0',
      height: 'auto',
      width: 'initial',
      margin: '0.5rem'
    },
    '&>div>div>select': {
      width: '100%'
    },
    '&>div>div>select>option': {
      whiteSpace: 'normal',
      maxWidth: '50px'
    },
    '&>div>div>select:disabled': {
      display: 'none'
    }
  },

  Dropdown: {
    background: 'white',//'rgba(255, 255, 255, 0.15)',
    margin: '0 0 10px',
    borderRadius: '6px',
    '& label': {
      color: 'red',
      background: 'red'
    }
  },

  submitButton: {
    background: 'white', //'#556972',
    color: 'white',
    borderRadius: '0',
    margin: '0'
  },
  titleContainer: {
    display: 'inline'
  },
  indoor: {
    fontSize: '1.5rem',
    paddingBottom: '0.5rem'
  },
  outdoor: {
    paddingBottom: '0.5rem'
  },
  hidden: {
    display: 'none'
  },
  outdoorChild: {
    marginLeft: '1rem'
  },
  '@media (min-width: 800px)': {
    '& modal > div': {
      left: '20%'
    }
  },
  menuWrapper: {
    width: '100%'
  }


}));
