import {AppBar , BottomNavigation, BottomNavigationAction, Box, Collapse, Container, Dialog, DialogActions , DialogContent, DialogTitle, Grid, IconButton, InputLabel , Paper, Typography} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import RestoreIcon from "@material-ui/icons/Restore";
import clsx from "clsx";
import _ from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import {ArrowDown} from "../../icons";
import {ArrowBack, Close, ControlMobile, Dashboard, MobileLogo, MobileMenu, NotitifactionMobile, ReportsMobile, SettingsMobile} from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import MenuDropDown from "../MobileMenuDropDown/MenuDropDown";
import DrawerMenu from "./DrawerMenu";

import useStyles from "./TopBar.style";
const generalNamesConst = {
  site: "All Sites",
  system: "All Systems",
  unit: "All Units"
};
export default function TopSelectionsMenu(props: any) {

  const {hideUnitsFilter, onCustomerSelect = () => {}, onSiteSelect = () => {}, customGeneralNames = {}, close, reload = false} = props;
  const generalNames = { ...generalNamesConst, ...customGeneralNames };

  const classes = useStyles();
  const selections = useStoreState((s) => s.selections.mobileSelections);
  const updateSelections = useStoreActions((a) => a.selections.setMobileSelections);
  // const customers = useStoreState((s) => s.selections.getCustomersBySelection);
  const sites = useStoreState((s) => s.selections.getSitesBySelection);
  const systems = useStoreState((s) => s.selections.getSystemsBySelection);
  const getUnitName = useStoreState((s) => s.units.getUnitName);
  const allSites = useStoreState((s) => s.sites.allSites);
  const allCustomers = useStoreState((s) => s.customers.allCustomers);
  const [filters, setFilters] = useState<any>({customerId: selections.customerId, siteId: selections.siteId, unitId: selections.unitId});
  const units = useStoreState((s) => s.units.allUnits);
  const devices = useStoreState((s) => s.devices.allDevices);

  const [allUnits, setAllUnits] = useState<any>({});
  const [allDevices, setAllDevices] = useState<any>({});
  const [customersOptions, setCustomersOptions] = useState<any>([]);
  const [sitesOptions, setSitesOptions] = useState<any>([]);
  const [unitsOptions, setUnitsOptions] = useState<any>([]);
  const [firstTimeLoadingOptions, setFirstTimeLoadingOptions] = useState<any>(true);

  useEffect(() => {
      setAllUnits(units);
      setAllDevices(devices);
      const customerItems = buildOptions(_.orderBy(Object.values(allCustomers),  [(customer) => customer.name?.toUpperCase()], ["asc"]), "customer");
      setCustomersOptions(customerItems);
      setFirstTimeLoadingOptions(false);
  }, []);

  useEffect(() => {
  if (reload){
      setAllUnits(units);
  }
}, [reload]);

  useEffect(() => {
    setSitesOptions(getOptions("site"));
    setUnitsOptions(getOptions("unit"));

}, [filters, allUnits]);

  const getOptions = (itemType: string) => {
    let items: any = [];
    if (itemType === "site"){
    items = _.orderBy(
       Object.values(allSites).filter((site) =>
         filters.customerId
           ? _.includes(allCustomers[filters.customerId].sites, site.id)
           : true
       ),
       [(site) => site.name?.toUpperCase()],
       ["asc"]
     );
    }

    if (itemType === "unit"){
      items =  Object.values(allUnits).filter((unit: any) => {
      if (!allDevices[unit.device]) { return false; }
      if (!allSites[unit.site]) { return false; }
      if (!allCustomers[unit.customer]) { return false; }
      if (unit.type !== 1) { return false; }
      return (
          (filters.customerId ? unit.customer === filters.customerId : true) &&
          (filters.siteId ? unit.site === filters.siteId : true)
        );
      });
    }

    return buildOptions(items, itemType);
  };

  const buildOptions = (items: any, itemType: string) => {
    const options = items.map((item: any) => ({
      name: `${item.name}`,
      value: item.id,
      key: item.id,
      type: item.type
    }));
    const optionsArr =
      itemType === "customer"
        ? options
        : [
          {
            name: options.length ? generalNames[itemType] : `No ${itemType}s`,
            value: "",
            key: itemType,
            type: 0
          },
          ...options
        ];

    return _.isEmpty(options)
      ? [{ name: `No ${itemType}s`, value: "", key: itemType, type: 0 }]
      : optionsArr;
  };

  const submitFilters = () => {
    close();
    updateSelections(filters);
  };

  if (firstTimeLoadingOptions){
    return null;
  }

  return (
   <Collapse in={true} collapsedHeight={40} className={classes.collapse}>
         <Paper elevation={4} className={clsx(classes.paper, {[classes.smallPaper]: hideUnitsFilter})}>
         <div>
         <div className={classes.dropDownContainer}>
        <InputLabel className={classes.label}>
        {t`Customer`}
        </InputLabel >
         <MenuDropDown
           onChange={(id: string) => setFilters({customerId: id, siteId: null, unitId: null})}
           value={filters.customerId || ""}
           options={customersOptions}
         />
         </div>
         <div className={classes.dropDownContainer}>
         <InputLabel className={classes.label}>
         {t`Site`}
         </InputLabel>
         <MenuDropDown
           onChange={(id: string) => setFilters({...filters , siteId: id, unitId: null})}
           value={filters.siteId || ""}
           options={sitesOptions}
         />
         </div>
        {!hideUnitsFilter && <div className={classes.dropDownContainer}>
         <InputLabel className={classes.label}>
         {t`Units`}
         </InputLabel>
         <MenuDropDown
           onChange={(id: string) => !id ? setFilters({...filters, unitId: null}) :  setFilters({customerId: allUnits[id]?.customer || null, siteId: allUnits[id]?.site || null, unitId: id})}
           value={filters.unitId || ""}
           options={unitsOptions}
         />
         </div>}
         </div>
         <Button
           className={classes.submitBtn}
           width={"100%"}
           red
           uppercase
           onClick={submitFilters}
         >
             {t`Submit`}
         </Button>
         </Paper>
       </Collapse>
 );
}
