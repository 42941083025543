import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Typography
} from "@material-ui/core";
import { Close } from "../../icons";

import { Field } from "formik";
import { Formik } from "formik";
import React, { useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import Button from "../../cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import PhoneField from "../UserManagement/PhoneField";
import UserDetailsField from "../UserManagement/UserDetailsField";
import useStyles from "./AddEditContact.style";

const ContactSchema =
  Yup.object().shape({
    firstName: Yup.string()
      .max(20, "Too Long!")
      .min(3, "Too Short!")
      .required("Required"),
    lastName: Yup.string()
      .max(20, "Too Long!")
      .min(3, "Too Short!")
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required"),
    phone: Yup.string()
      .required("Required")
      .test(
        "phone validation",
        t`Phone number should be 10 chars minimum.`,
        function(value) {
          if (!value) {
            return true;
          }

          let newValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
          newValue = newValue.replace(/\s/g, "");

          if (newValue.length > 9) {
            return true;
          }
          return false;
        }
      )
  });
export { ContactSchema };

const AddEditContact: React.FC<any> = (props) => {
    const classes = useStyles();
    const { onClose, editContact: contact, localContacts, setLocalContacts} = props;
    const [open, setOpen] = useState(true);
    const isEdit = contact ? true : false;

    const me = useStoreState((s) => s.users.me);
    const customerId = me.customer ? me.customer : "";
    const createContact = useStoreActions((action) => action.contacts.createContact);
    const updateContact = useStoreActions((action) => action.contacts.updateContact);
    const {addMessage} = useStoreActions((action) => action.errorMessage);

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    const initialValues: any = {
        firstName: isEdit ? contact.firstName : "",
        lastName: isEdit ? contact.lastName : "",
        email: isEdit ? contact.email : "",
        phone: isEdit ? contact.phone : "",
        description: isEdit ? contact.description : ""
    };

    const editContact = (values: any) => {
        values.phone = values.phone.replace(/[^a-zA-Z0-9 ]/g, "");
        values.phone = values.phone.replace(/\s/g, "");
        updateContact({id: contact._id, data: values})
        .then((res: any) => {
            const updatedCnotact = {...localContacts[contact._id], ...values};
            setLocalContacts({...localContacts, [contact._id]: updatedCnotact });
        })
        .catch((error: any) => {
            addMessage({message: error.message});
        });
        handleClose();

    };
    const createNewContact = (values: any) => {
        values.phone = values.phone.replace(/[^a-zA-Z0-9 ]/g, "");
        values.phone = values.phone.replace(/\s/g, "");
        createContact({id: customerId, data: values})
        .then((res: any) => {
          setLocalContacts({...localContacts, [res._id]: res});
        })
        .catch((error: any) => {
          addMessage({message: error.message});
        });
        handleClose();
    };
    return (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        classes={{ paper: classes.dialog }}
      >
          <div  className={classes.titleContent}>
            <Typography variant="h6" className={classes.headerTitle}>{isEdit ? t`Edit contact` : t`Add contact`}</Typography>
            <IconButton disableRipple onClick={handleClose} className={classes.iconBtnStyle}>
              <Close color="#7f7692" />
          </IconButton>
          </div>
          <DialogContent className={classes.dialogContent}>
            <Formik
              initialValues={initialValues}
              onSubmit={isEdit ? editContact : createNewContact}
              enableReinitialize={true}
              validationSchema={ContactSchema}
            >
              {({ handleSubmit, errors, touched, values, setFieldValue, ...restFormikProps }) => (
                <form onSubmit={handleSubmit}>
                      <Paper className={classes.leftCard} elevation={0}>
                        <Typography
                          className={classes.headerStyle}
                        >{t`Contact Details`}</Typography>
                        <Divider orientation="horizontal" />
                        <Grid className={classes.personalDetailsContainer}>
                            <Field
                            label={t`First name`}
                            value={values.firstName}
                            name="firstName"
                            component={UserDetailsField}
                            error={errors.firstName && touched.firstName ? true : false}
                            helperText={
                            errors.firstName && touched.firstName ? errors.firstName : "From 3 to 20 characters"
                            }
                            />
                            <Field
                                label={t`Last name`}
                                value={values.lastName}
                                name="lastName"
                                component={UserDetailsField}
                                error={errors.lastName && touched.lastName ? true : false}
                                helperText={errors.lastName && touched.lastName ? errors.lastName : "From 3 to 20 characters"}
                            />
                            <Field
                                label={t`Email Address`}
                                value={values.email}
                                name="email"
                                component={UserDetailsField}
                                error={errors.email && touched.email ? true : false}
                                helperText={errors.email && touched.email ? errors.email : "Valid email address"}
                            />
                            <Field
                                label={t`Telephone`}
                                value={values.phone}
                                name="phone"
                                component={PhoneField}
                                onChange={(e: any) => setFieldValue("phone", e)}
                                error={errors.phone && touched.phone ? true : false}
                                helperText={
                                    errors.phone && touched.phone ? errors.phone : "International format telephone number"
                                }
                            />
                                <Field
                                    label={t`Notes`}
                                    value={values.description}
                                    name="description"
                                    component={UserDetailsField}
                                    multiline
                                    notRequired
                                    error={errors.description && touched.description ? true : false}
                                />
                        </Grid>
                      </Paper>
                    <Divider orientation="vertical" />
                  <Grid container direction="row-reverse">
                    <Button
                      uppercase
                      width={150}
                      type="submit"
                      onMouseUp={handleSubmit}
                      onClick={handleSubmit}
                    >
                      {isEdit ? t`Update` : t`Add`}
                    </Button>
                    <Button
                      uppercase
                      width={150}
                      white
                      marginRight
                      onClick={handleClose}
                      onMouseUp={handleClose}
                    >
                      {t`Cancel`}
                    </Button>
                  </Grid>
                </form>
              )}
            </Formik>
          </DialogContent>
      </Dialog>
    );
};

export default AddEditContact;
