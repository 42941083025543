import * as React from 'react';

function SvgMoreVert(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 3 24" {...props}>
      <path d="M0 0h3v3H0zm0 14h3v3H0zm0-7h3v3H0zm0 14h3v3H0z" fill="#AAA2AA" fillRule="evenodd" />
    </svg>
  );
}

export default SvgMoreVert;
