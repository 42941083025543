import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
label: {
  fontFamily: "Roboto",
  fontSize: "12px",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "rgba(21, 17, 31, 0.5)"
},
selectedLabel : {
  color: "#4b1c46"
},
icon: {
  width: 26,
  height: 26
}
}));

export default useStyles;
