import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Tooltip,
    Typography
} from "@material-ui/core";
import clsx from "clsx";
import {
    addDays,
    addHours,
    endOfMinute,
    format,
    formatDistanceStrict,
    getHours,
    getMilliseconds,
    setHours,
    setMinutes,
    setSeconds, startOfHour, startOfMinute, sub
} from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import PowerChart from "../../components/PowerGraph/PowerGraph";
import SvgArrow from "../../icons/Arrow";
import { IGroup } from "../../models/Groups";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnit } from "../../models/Units";
import { IZone } from "../../models/Zones";
import ExportExcel from "./ExportExcel";
import useStyle from "./OneTenant.style";

const TenantReport: React.FC = (props: any) => {
    const classes = useStyle();
    const sites = useStoreState((s) => s.sites.allSites);
    const allunits = useStoreState((s) => s.units.allUnits);
    const allSystems = useStoreState((s) => s.systems.allSystems);
    const getUnitsBy = useStoreState((s) => s.units.getServiceUnitsBy);
    const getDeviceSite = useStoreState((state) =>
        state.devices.getDeviceSite);
    const [tenants, setTenants] = useState([]);

    const [chosenCustomer, setChosenCustomer] = useState<any>("");
    const [chosenSystem, setChosenSystem] = useState<string>("");

    const isInitialized = useStoreState((s) => s.isInitialized);
    const getZonesByCustomerId = useStoreActions((action) => action.zones.getZonesBySiteId);

    const selections = useStoreState((s) => s.selections.selections);
    const updateSelections = useStoreActions((a) => a.selections.updateSelections);
    const getPowerUsage = useStoreActions((action) => action.sites.getPowerUsage);
    const getZones = useStoreActions((action) => action.zones.getZones);

    const [reports, setReports] = useState<any>({});
    const [selectedTenantUnits, setSelectedTenantUnits] = useState<any>({});
    const [selectedSystem, setSelectedSystem] = useState<any>("");
    const [selectedUnits, setSelectedUnits] = useState<any>("");
    const [selectedTenant, setSelectedTenant] = useState<any>("");
    const [showSpinner, setSshowSpinner] = useState<boolean>(false);
    const [filterByState, setFilterByState] = useState<string>("total");
    const [graphFiltere, setGraphFilter] = useState<string>("tenant");
    const [chosenName, setChosenName] = useState<string>("");
    const [graphType, setGraphType] = useState<string>("unit");
    const [timeForGraph, setTimeForGraph] = useState<any>("7 days");
    const [numberOfTimeUnits, setNumberOfTimeUnits] = useState<any>(0);
    const [canSeeGraphData, setCanSeeGraphData] = useState<boolean>(true);

    const [selectedItemId, setSelectedItemId] = useState<string>("");
    // unique setSelectedItemId for unassigned Units by systems - as they have same key as systems
    const [selectedItemIdUnassignedUnits, setSelectedItemIdUnassignedUnits] = useState<string>("first");

    useEffect(() => {
        (async function() {
            if (selections.siteId) {
                const zones = await getZonesByCustomerId(selections.siteId);
                setTenants(Object.values(zones));
            }

        })();
    }, [selections.siteId]);
    useEffect(() => {

        fetchPowerUsage();
    }, [selections.dateRange, selections.siteId]);

    // Set date picker on 7 days on loading screen
    useEffect(() => {
        if (!selections.dateRange) {
            const today = new Date();
            const aWeekAgo = sub(today, { days: 7 });
            updateSelections({
                type: "time", data: {
                    startDate: aWeekAgo,
                    endDate: today
                }
            });
        }
        if (!selections.systemId) {
            setChosenSystem("allSystems");
        }

    }, []);

    // Refresh graph data on fetch and on change system view
    useEffect(() => {
        if (_.isEmpty(reports) || (_.isEmpty(reports.zones) && _.isEmpty(reports.units))) {
            setSelectedItemId("");
            setSelectedItemIdUnassignedUnits("");
            setSelectedTenantUnits({});
            return;
        }
        if (Object.keys(reports).length) {
            // On first load
            if (!Object.keys(selectedTenantUnits).length) {
                const tenantIds = Object.keys(reports.zones);
                if (tenantIds.length) {
                    onTenantSelections(tenantIds[0]);
                    // To always load the right tenant on
                    setSelectedTenant(tenantIds[0]);
                }
            }

            // On requesting a new report load the right system\tenant\unit
            if (selectedSystem) {
                onSystemSelections(selectedSystem);

            } else if (selectedTenant) {
                onTenantSelections(selectedTenant);
            } else if (selectedUnits) {
                onUnitSelection(selectedUnits);
            }

            // When swaping between systems to system with now tenant - show system units and not tenant units
            const tenantsBySystem: any = filteredTenants();
            if (!tenantsBySystem.length) {
                if (selections.systemId) {

                    const selectedSystem = reports.systems[selections.systemId];
                    if (selectedSystem) {
                        const selectedSystemUnits = selectedSystem.units;
                        setSelectedTenantUnits(selectedSystemUnits);
                    } else {
                        // IF this systems isn't connected to PPD set to nothing
                        setSelectedTenantUnits({});
                    }

                }

                if (!selections.systemId && reports.systems) {
                    const systemId = Object.keys(reports.systems)[0];
                    const selectedSystem = reports.systems[systemId];
                    if (selectedSystem) {
                        const selectedSystemUnits = selectedSystem.units;
                        setSelectedTenantUnits(selectedSystemUnits);
                    } else {
                        // IF this systems isn't connected to PPD set to nothing
                        setSelectedTenantUnits({});
                    }
                }
            }
            // will only set tenant view in graph on selecting any system that isn't "all systems"
            else if (selections.systemId) {
                onTenantSelections(tenantsBySystem[0].id);
            }

        }
    }, [reports, selections.systemId]);

    const fetchPowerUsage = async () => {

        if (!selections.siteId) {
            return;
        }

        let data: any;
        let startTime: any;
        let endTime: any;
        let distanceBetweenDates = "";
        let divideBy = 2;
        let numberOfTimeUnits: any = 0;
        let dates: any = [];

        setSshowSpinner(true);

        if (!selections.dateRange) {
            endTime = new Date();
            startTime = sub(endTime, { days: 7 });
        }

        if (selections.dateRange) {
            startTime = new Date(selections.dateRange.startDate);
            endTime = new Date(selections.dateRange.endDate);
        }

        // Always use round hours
        startTime = startOfHour(startOfMinute(startTime));
        endTime = startOfHour(startOfMinute(endTime));

        // Days always start at 00:00 and end at 23:59
        startTime = setHours(startTime, 0);
        endTime = setMinutes(setHours(endTime, 23), 59);
        endTime = setSeconds(endTime, 59);

        // calculate amount of time units in date - to show in graph
        distanceBetweenDates = formatDistanceStrict(startTime, endTime, {unit: "day"});

        // check resolution
        if (distanceBetweenDates.includes("month")) {
            divideBy = 3;

            const [months, ] = distanceBetweenDates.split("month");
            numberOfTimeUnits = _.toNumber(months);

        } else if (distanceBetweenDates.includes("days")) {
            divideBy = 2;

            const [days, ] = distanceBetweenDates.split("days");
            numberOfTimeUnits = _.toNumber(days);

        } else if (distanceBetweenDates.includes("day")) {
            divideBy = 1;
            numberOfTimeUnits = _.toNumber(24);

        } else if (distanceBetweenDates.includes("hours")) {
            divideBy = 1;
            const [hours, ] = distanceBetweenDates.split("hours");
            numberOfTimeUnits = _.toNumber(hours);
        }

        const buildHour = (time: number) => {
            if (time < 10) {
                return `0${time}: 00`;
            }
            return `${time}:00`;

        };

        // Get time units to be used in CSV export
        let baseTime = new Date(startTime);
        const firstTime = divideBy === 1 ? `${format(baseTime, "MM/dd/yyyy")} ${buildHour(getHours(baseTime))}` : `${format(baseTime, "MM/dd/yyyy")}`;
        let timeColumn = [firstTime];
        // as i already have the first item
        for (let i = 1; i < numberOfTimeUnits; i++) {
            if (divideBy === 1) {
                baseTime = addHours(baseTime, 1);
            } else {
                baseTime = addDays(baseTime, 1);
            }
            const objToPush = divideBy === 1 ? `${format(baseTime, "MM/dd/yyyy")} ${buildHour(getHours(baseTime))}` : `${format(baseTime, "MM/dd/yyyy")}`;
            timeColumn.push(objToPush);
        }

        // For export usage
        setNumberOfTimeUnits(timeColumn);

        data = await getPowerUsage({
            siteId: selections.siteId,
            startTime: Date.parse(startTime.toString()),
            endTime: Date.parse(endTime.toString()),
            resolution: divideBy
        });

        setSshowSpinner(false);
        if (data) {
            // create unassigned Units by systems
            const unassignedUnitsId = Object.keys(data.units);
            const unassignedUnitBySystems = _.reduce(unassignedUnitsId, (obj: any, unitId: string) => {
                const systemId: string = data.units[unitId].systemId;

                if (!obj[systemId]) {
                    obj[systemId] = {};
                }
                obj[systemId][unitId] = data.units[unitId];
                return obj;
            }, {});

            data.units = unassignedUnitBySystems;

            setReports(data);
            setTimeForGraph(distanceBetweenDates);
        }

    };

    if (!isInitialized) { return <Loading />; }

    // Set the headers in the tenant page && the units to be shown
    const setOneTenantsData = (queryType: any, id: any) => {
        // set subtitle - by site
        // set customer on chosen site
        if (queryType === "site" && sites[id] && sites[id].customer !== chosenCustomer) {
            setChosenCustomer(sites[id].customer);
            getZonesByCustomerId(id);
        }

        // on system filter
        if (queryType === "system" && id) {
            const deviceId = allSystems[id].device;
            const site: any = getDeviceSite(deviceId);

            if (!site.length) {
                console.log(`didn't find site of site ID: ${id}`);
                return;
            }

            if (site[0].customer !== chosenCustomer) {
                setChosenCustomer(site[0].customer);
                getZonesByCustomerId(site[0].id);
            }
        }

    };

    const onFilterSelect = (id: string | null, queryType: any) => {
        let type = queryType === "system" ? "system" : "site";

        // System may have an Empty Id for "all systems"
        if (!id && queryType !== "system") {
            return;
        }

        if (queryType === "customer") {
            // setChosenSite("");
            return;
        }

        if (queryType === "system" && id) {
            setChosenSystem(id);
        } else {
            setChosenSystem("allSystems");
        }

        setOneTenantsData(type, id);

    };

    const dropDownGraphOptions = () => {
        return (
            <FormControl variant="outlined" className={classes.graphDropDown}>
                <Select
                    labelId="graphOptions"
                    value={graphType}
                    className={classes.graphDropDownStyle}
                    onChange={(event: any) => setGraphType(event.target.value)}
                    IconComponent={SvgArrow}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                >
                    {graphFiltere === "system" && <MenuItem value={"tenant"}>Zones View</MenuItem>}
                    <MenuItem value={"unit"}>Units View</MenuItem>
                    <MenuItem value={"time"}>Time View</MenuItem>
                </Select>
            </FormControl>
        );
    };

    const oneTenantUsageData = () => {
        let coolSum = 0;
        let heatSum = 0;
        let otherSum = 0;
        let powerSum = 0;
        let timeSum = 0;
        let coolModeTime = 0;
        let heatModeTime = 0;
        let otherModeTime = 0;
        let workHrs = 0;
        const milliSecondsInHour = 60 * 60 * 1000;

        _.forEach(selectedTenantUnits, (unit) => {
            if (unit.message) {
                return;
            }

            coolSum += unit.coolPowerUsed || 0;
            heatSum += unit.heatPowerUsed || 0;
            otherSum += unit.otherPowerUsed || 0;
            // powerSum += unit.coolPowerUsed || 0 + unit.heatPowerUsed || 0 + unit.otherPowerUsed || 0;
            powerSum += unit.totalPowerUsed || 0;
            timeSum += unit.onTime || 0;
            // timeSum += unit.coolModeTime || 0 + unit.heatModeTime || 0 + unit.otherModeTime || 0;
            coolModeTime += unit.coolModeTime || 0;
            heatModeTime += unit.heatModeTime || 0;
            otherModeTime += unit.otherModeTime || 0;

        });

        switch (filterByState) {
            case "cool":
                workHrs = Number((((coolModeTime / milliSecondsInHour) * 10) / 10));
                break;
            case "heat":
                workHrs = Number((((heatModeTime / milliSecondsInHour) * 10) / 10));
                break;
            case "other":
                workHrs = Number((((otherModeTime / milliSecondsInHour) * 10) / 10));
                break;
            default:
                workHrs = Number((((timeSum / milliSecondsInHour) * 10) / 10));
        }

        // Use this function to show the output without rounding the numbers after the dot
        const calc = (num: any) => {
            return num.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];
        };

        return (
          <>
            <div>

                            <Button variant="contained" className={clsx(classes.btnUsage, classes.coolUsage, filterByState === "cool" && classes.activeCoolUsage)}
                                disableRipple
                                onClick={() => setFilterByState("cool")}
                            >
                                    <span className={classes.btnUsageTextState} >Cool</span>
                                    <span className={classes.btnUsageTextDate} >{coolSum.toFixed(1)} </span>
                                    <span className={classes.btnUsageTextType} >kWh</span>
                            </Button>

                            <Button variant="contained" className={clsx(classes.btnUsage, classes.heatUsage, filterByState === "heat" && classes.activeHeatUsage)}
                                disableRipple
                                onClick={() => setFilterByState("heat")}
                            >
                                <Typography>
                                    <span className={classes.btnUsageTextState} >Heat</span>
                                    <span className={classes.btnUsageTextDate} >{heatSum.toFixed(1)} </span>
                                    <span className={classes.btnUsageTextType} >kWh</span>
                                </Typography>
                            </Button>

                            <Button variant="contained" className={clsx(classes.btnUsage, filterByState === "other" && classes.activeBtnUsage)}
                                disableRipple
                                onClick={() => setFilterByState("other")}

                            >
                                <Typography>
                                    <span className={classes.btnUsageTextState} >Other</span>
                                    <span className={classes.btnUsageTextDate} >{otherSum.toFixed(1)} </span>
                                    <span className={classes.btnUsageTextType} >kWh</span>
                                </Typography>
                            </Button>

                            <Button variant="contained" className={clsx(classes.btnUsage, filterByState === "total" && classes.activeBtnUsage)}
                                disableRipple
                                onClick={() => setFilterByState("total")}
                            >
                                <Typography>
                                    <span className={classes.btnUsageTextState} >Total</span>
                                    <span className={classes.btnUsageTextDate} >{powerSum.toFixed(1)} </span>
                                    <span className={classes.btnUsageTextType} >kWh</span>
                                </Typography>
                            </Button>

                            <Button variant="contained" className={classes.btnUsage}
                                disableRipple
                                onClick={() => _.noop}
                            >
                                <Typography>
                                    <span className={classes.btnUsageTextState} >W<span className={classes.btnUsageTextStateSmaller} >/</span>Hrs</span>
                                    <span className={classes.btnUsageTextDate} >{workHrs.toFixed(1)} </span>
                                    <span className={classes.btnUsageTextType} >Hrs</span>
                                </Typography>
                            </Button>

                            </div>
                    {dropDownGraphOptions()}
                    </>
        );
    };

    const onTenantSelections = (tenantId: string) => {
        const tenantUnits = reports.zones[tenantId] ? reports.zones[tenantId]?.units : [];
        setSelectedTenantUnits(tenantUnits);
        setSelectedItemId(tenantId);
        setGraphFilter("tenant");
        // @ts-ignore
        const tenant: any = _.find(tenants, (tenant) => tenant.id === tenantId);

        if (!tenant) {
            return;
        }
        const tenantName = tenant.name;

        setChosenName(tenantName || "");

        // Cancel on date change searching for last system
        setSelectedSystem("");
        setSelectedUnits("");

        // doesn't have tenant view
        if (graphType === "tenant") {
            setGraphType("unit");
        }

        // including unique setSelectedItemId for unassigned Units by systems
        setSelectedItemIdUnassignedUnits("");

        // Enable seeing the tables content
        setCanSeeGraphData(true);
    };

    const onUnitSelection = (systemId: string) => {
        const selectedSyatemUnits = reports.units[systemId];
        setSelectedTenantUnits(selectedSyatemUnits);
        setGraphFilter("unit");
        setChosenName(allSystems[systemId].name || "");
        //
        // mark chosen unAssignedUnitsBy systems
        setSelectedItemIdUnassignedUnits(systemId);
        // for systems && tenants
        setSelectedItemId("");

        // doesn't have tenant view
        if (graphType === "tenant") {
            setGraphType("unit");
        }

        // Cancel on date change searching for last system
        setSelectedSystem("");
        setSelectedTenant("");

        // Enable seeing the tables content
        setCanSeeGraphData(true);

    };

    const onSystemSelections = (systemId: string) => {
        const allUnitsHaveCapacity = reports.systems[systemId].allUnitsHaveCapacity;
        const selectedSyatemUnits = reports.systems[systemId].units;
        const selectedUnAssignedUnits = reports.units[systemId];
        setSelectedTenantUnits({ ...selectedSyatemUnits, ...selectedUnAssignedUnits });
        setGraphFilter("system");
        setChosenName(allSystems[systemId].name || "");
        // Cancel on date change searching for last system
        setSelectedItemId(systemId);

        setSelectedUnits("");
        setSelectedTenant("");
        // including unique setSelectedItemId for unassigned Units by systems
        setSelectedItemIdUnassignedUnits("");

        setCanSeeGraphData(allUnitsHaveCapacity);
    };

    const labelContent = () => {
        return (
            <div>
                <div className={classes.graphLabelText}>
                    <div className={clsx(classes.graphLabelIcon, classes.graphLabelIconHeat)} />
                    Heat
                </div>
                <div className={classes.graphLabelText}>
                    <div className={clsx(classes.graphLabelIcon, classes.graphLabelIconCool)} />
                    Cool
                </div>
                <div className={classes.graphLabelText}>
                    <div className={classes.graphLabelIcon} />
                    Other
                </div>
                <div className={classes.graphLabelText}>
                    <div className={clsx(classes.graphLabelIcon, classes.graphLabelIconTime)} />
                    wHr
                </div>
            </div>
        );
    };

    const getSystems = () => {
        if (chosenSystem && chosenSystem !== "allSystems") {
            const system = reports.systems[chosenSystem];

            if (!system) {
                return;
            }

            const allUnitsHaveCapacity = system.allUnitsHaveCapacity;

            const tooltipUnitList = _.map(system.units, (unit, key) => allunits[key].name).join(",");
            return (
                <Tooltip
                    title={tooltipUnitList.length ? tooltipUnitList : "No units in system"}
                    key={chosenSystem}
                    enterDelay={1000}>
                    <Button
                    disableRipple
                        key={chosenSystem}
                        onClick={() => {
                            setSelectedSystem(chosenSystem);
                            onSystemSelections(chosenSystem);
                        }}
                        className={clsx(classes.listStyle, { [classes.selectedItem]: chosenSystem === selectedItemId }, { [classes.capacityFlagged]: !allUnitsHaveCapacity })}
                    >
                        {`${allSystems[chosenSystem].name} units`}

                    </Button>
                </Tooltip>

            );
        }

        return (
            _.map(reports.systems, (system: any, systemId: string) => {

                const tooltipUnitList = _.map(system.units, (unit, key) => allunits[key].name).join(",");
                const allUnitsHaveCapacity = system.allUnitsHaveCapacity;

                return (
                    <Tooltip
                        title={tooltipUnitList.length ? tooltipUnitList : "No units in system"}
                        key={systemId}
                        enterDelay={1000}>
                        <Button
                            disableRipple
                            key={systemId}
                            onClick={() => {
                                setSelectedSystem(systemId);
                                onSystemSelections(systemId);
                            }}
                            className={clsx(classes.listStyle, { [classes.selectedItem]: systemId === selectedItemId }, { [classes.capacityFlagged]: !allUnitsHaveCapacity })}
                        >
                            {`${allSystems[systemId].name} units`}

                        </Button>
                    </Tooltip>

                );
            })
        );
    };

    const filteredTenants = () => {
        return _.filter(tenants, (zone: IZone, index: number) => {
            const TenantUnitsIds = _.map(zone.units, (unitId) => allunits[unitId] ? allunits[unitId].id : "");

            const system = allSystems[chosenSystem] || { units: [] };
            const systemUnits = _.map(system.units, (unitId) => allunits[unitId] ? allunits[unitId].id : "");
            const belongsToChosenSystem = _.intersection(systemUnits, TenantUnitsIds).length;
            if (!belongsToChosenSystem && chosenSystem !== "allSystems") {
                return false;
            }
            return true;
        });

    };

    const getTenants = () => {
        const tenantsBySystem = filteredTenants();
        const tenants = _.map(tenantsBySystem, (zone: IZone, index: number) => {
            const tooltipUnitList = _.map(zone.units, (unitId) => allunits[unitId] ? allunits[unitId].name : "unknown").join(",");

            return (
                <Tooltip
                    title={tooltipUnitList.length ? tooltipUnitList : "No units assigned to tenant"}
                    key={index}
                    enterDelay={1000}>
                    <Button
                        disableRipple
                        key={index}
                        onClick={() => {
                            setSelectedTenant(zone.id);
                            onTenantSelections(zone.id);
                        }}
                        className={clsx(classes.listStyle, { [classes.selectedItem]: zone.id === selectedItemId })}
                    >
                        {zone.name}

                    </Button>
                </Tooltip>

            );
        });

        return tenants;
    };
    const getUnAssignedUnits = () => {

        if (chosenSystem && chosenSystem !== "allSystems") {
            const system = reports.units[chosenSystem];
            // If there's no unassigned units - do nothing
            if (!system) {
                return;
            }
            const tooltipUnitList = _.map(Object.keys(system), (unitId) => allunits[unitId].name).join(",");
            const key = chosenSystem + "unassigned";

            return (
                <Tooltip
                    title={tooltipUnitList.length ? tooltipUnitList : "No units in system"}
                    key={key}
                    enterDelay={1000}>
                    <Button
                    disableRipple
                        key={chosenSystem}
                        onClick={() => {
                            setSelectedUnits(chosenSystem);
                            onUnitSelection(chosenSystem);
                        }}
                        className={clsx(classes.listStyle, { [classes.selectedItem]: selectedItemIdUnassignedUnits === chosenSystem })}
                    >
                        {`${allSystems[chosenSystem].name} unassigned units`}
                    </Button>
                </Tooltip>

            );

        }

        return (
            _.map(reports.units, (unitsBySystem: any, key: string) => {
                const tooltipUnitList = _.map(Object.keys(unitsBySystem), (unitId) => allunits[unitId].name).join(",");
                return (
                    <Tooltip
                        title={tooltipUnitList.length ? tooltipUnitList : "No units in system"}
                        key={key}
                        enterDelay={1000}>
                        <Button
                        disableRipple
                            key={key}
                            onClick={() => {
                                setSelectedUnits(key);
                                onUnitSelection(key);
                            }}
                            className={clsx(classes.listStyle, { [classes.selectedItem]: selectedItemIdUnassignedUnits === key })}
                        >
                            {`${allSystems[key].name}  unassigned units`}
                        </Button>
                    </Tooltip>

                );
            })
        );
    };

    const tenantsBySystem = filteredTenants();
    const filteredTenantIds = _.map(tenantsBySystem, (zone: IZone, index: number) => zone.id);
    const numberOfSystems = (chosenSystem && chosenSystem !== "allSystems")
        ? (reports.systems && reports.systems[chosenSystem]) ? 1 : 0
        : reports.systems ? Object.keys(reports.systems).length : 0;

    const numberOfUnUssignedSystems = (chosenSystem && chosenSystem !== "allSystems")
        ? (reports.units && reports.units[chosenSystem]) ? 1 : 0
        : reports.units ? Object.keys(reports.units).length : 0;

    return (
        <div className={classes.view}>
            <ServiceNavigationBar {...props} />
            <div className={classes.contentArea}>
                <Header
                    path={["Power Distribution"]}
                    onSiteSelect={(id) => onFilterSelect(id, "site")}
                    onCustomerSelect={(id) => onFilterSelect(id, "customer")}
                    onSystemSelect={(id) => onFilterSelect(id, "system")}
                    showDateRangePicker={true}
                    hideUnitSelection={true}
                    customGeneralNames={{ site: "select Site" }}
                />

                <Grid container={true} className={classes.tableContainer}>
                    {showSpinner &&
                        <div className={classes.spinner}>
                            <CircularProgress size={80} />
                        </div>
                    }
                                <div className={classes.paperLeftContainer} >
                                <div className={classes.scrollContainer}>
                                            <div className={classes.HeaderHolder}>
                                            <Typography className={classes.reportTitle}>{t`Zones`}</Typography>
                                            <Typography className={classes.reportCounterStyle}>{filteredTenantIds.length}</Typography>
                                            </div>
                                            {!!Object.keys(reports).length && getTenants()}

                                            <div className={clsx(classes.HeaderHolder, classes.addTopMargin)}>
                                            <Typography className={classes.reportTitle}>{t`Systems`}</Typography>
                                            <Typography className={classes.reportCounterStyle}>{numberOfSystems}</Typography>
                                            </div>
                                            {!!Object.keys(reports).length && getSystems()}

                                            <div className={clsx(classes.HeaderHolder, classes.addTopMargin)}>
                                            <Typography className={classes.reportTitle}>{t`Un-Assigned Units`}</Typography>
                                            <Typography className={classes.reportCounterStyle}>{numberOfUnUssignedSystems}</Typography>
                                            </div>
                                            {!!Object.keys(reports).length && getUnAssignedUnits()}
                              </div>
                              <div className={classes.borderLine}/>
                              </div>

                                <div className={classes.rightContainer} >
                                                <div className={classes.headerContainer}>
                                                    <Typography className={classes.reportTitle} >{t`Detailed Analysis`}</Typography>
                                                    {!!Object.keys(selectedTenantUnits).length &&
                                                        <ExportExcel
                                                            reports={reports}
                                                            selections={selections}
                                                            chosenSystem={chosenSystem}
                                                            filteredTenants={filteredTenantIds}
                                                            units={selectedTenantUnits || {}}
                                                            numberOfTimeUnits={numberOfTimeUnits}
                                                            zones={tenants}
                                                        />}
                                                  </div>
                                                <div id="divider" className={classes.divider} />
                                                {!canSeeGraphData &&
                                                    <Typography className={classes.capacityFlaggedTitle} >Missing Unit/s Capacity Values. Please set in Site Management</Typography>
                                                }
                                                <div className={classes.chipContainer}>
                                                  {!!Object.keys(selectedTenantUnits).length && oneTenantUsageData()}
                                                </div>
                                              <div className={classes.graphSection}>
                                                <div className={classes.graphContainer}>
                                                    {
                                                        <PowerChart
                                                            allUnits={allunits}
                                                            units={selectedTenantUnits || {}}
                                                            filterByState={filterByState}
                                                            graphType={graphType}
                                                            graphFiltere={graphFiltere}
                                                            timeForGraph={timeForGraph}
                                                            tenants={reports.zones}
                                                            numberOfTimeUnits={numberOfTimeUnits}
                                                            chosenName={chosenName}
                                                            canSeeGraphData={canSeeGraphData}
                                                            zones={tenants}

                                                        />}
                                                        </div>
                                                     <div className={classes.legendContainer} >
                                                        {labelContent()}
                                                     </div>
                                                  </div>
                                </div>

                </Grid>

            </div>
        </div>
    );
};

export default TenantReport;
