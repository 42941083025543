import { fade, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  header: {
    display: "flex", flexFlow: "row nowrap",
    alignItems: "flex-end",
    paddingBottom: "10px",
    height: "83px",
    backgroundImage: "linear-gradient(to left, #421a40 100%, #29132e 0%)",
    paddingRight: "6px",
    paddingLeft: "10px"
     },
  appBarStyle: {
    height: "45px",
    boxShadow: "0px 5px 9px 0 rgba(0, 0, 0, 0.2)",
    background: "black",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  iosPadding: {
    paddingTop: "20px",
    height: "65px !important"
  },
  buttonText: {
    textDecoration: "underline",
    color: "gray",
    textTransform: "none",
    fontWeight: "bold"
  },
  titleStyle: {
    color: "white",
    fontSize: "18px"
  },
  leftActionStyle: {
    display: "flex",
    justifyContent: "flex-start"
  },
  titleWrapper: {
    display: "flex",
    // flex: 4,
    justifyContent: "center",
    flexFlow: "column nowrap",
    fontSize: 18,
    fontWeight: "bold",
    alignItems: "center",
    cursor: "pointer"
  },
  rightActionStyle: {
    display: "flex",
    // flex: 1,
    justifyContent: "flex-end"
  },
  rightButtonStyle: { padding: 0 },
  addPadding: { padding: "9px 10px" },
  paper: {
    height: "390px",
    padding: "20px 20px 44px 20px",
    backgroundImage: "linear-gradient(to top, #161220 54%, #3a183a)",
    borderRadius: 0,
    borderBottomLeftRadius: "22px",
    borderBottomRightRadius: "22px",
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    justifyContent: "space-between"
  },
  label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    marginBottom: "7px"
  },
  collapse: {
    position: "absolute",
    zIndex: 10,
    width: "100vw"
  },
  siteUnitName: {
    fontFamily: "Gilroy",
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  dropDownContainer: {
    marginBottom: 15
  },
  submitBtn: {
    height: 50,
    minHeight: 50,
    marginTop: 15
  },
  drawerStyle: {
      left: "auto !important",
      right: "auto !important"
    },
    //
  root: {
      width: "100%",
      left: "auto",
      right: "auto",
      margin: "auto",
      "@media (min-width: 769px)": {
        maxWidth: "375px",
        maxHeight: "812px",
        "& div:first-of-type:first-child": {
          maxWidth: "375px",
          maxHeight: "812px"
        }
      },
      "@media (min-height: 1025px)": {
        maxWidth: "375px",
        maxHeight: "812px",
        "& div:first-of-type:first-child": {
          maxWidth: "375px",
          maxHeight: "812px"
        }
      },
      "& div:first-of-type:first-child": {
        // width: "100%",
        // left: "auto",
        // right: "auto",
        // margin: "auto"
      }
    },
  drawerPaper: {
      width: "85%",
      background: "linear-gradient(to right, #491d47 2%, #381739 65%)",
      maxWidth: "500px",
      position: "absolute",
      flexDirection: "row",
      margin: "auto",
      top: "50%",
      bottom: "50%"
    },
  bigAvatar: {
      transform: "scale(0.8727)",
      marginTop: "14px",
      marginBottom: "5px"
    },
  menuHeader: {
      flexFlow: "column",
      alignItems: "center",
      paddingTop: "14px",
      marginBottom: "33px !important"
    },
  divider: {
      opacity: 0.2,
      background: "#fdfdfe"
    },
  listItemText: {
      opacity: 0.7,
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      fontSize: "16px",
      color: "#fdfdfe"
    },

  closeButton: { alignSelf: "flex-start" },
  menuBody: {
      paddingLeft: "27px",
      height: "94%",
      maxHeight: "850px",
      margin: 0,
      width: "100%"
    },
  usernameStyle: {
      fontFamily: "Roboto",
      fontSize: "20px",
      textAlign: "right",
      color: "#fff",
      lineHeight: "normal"
    },
  roleStyle: {
      fontFamily: "Roboto",
      fontSize: "14px",
      color: fade("#fff", 0.7),
      lineHeight: "normal"
    },
  listItemStyle: {
      display: "flex",
      justifyContent: "space-between",
      justifyItems: "center",
      paddingLeft: "3px",
      paddingRight: "14px",
      height: "54px"
    },
  iconTextContainer: {
      display: "inline-flex",
      justifyItems: "center",
      justifyContent: "center"
    },
  menuIconsStyle: {
      minWidth: "17px",
      minHeight: "17px",
      alignSelf: "center",
      paddingRight: "11px",
      fontSize: "20px",
      fill: fade("#fdfdfe", 0.8)
    },
  arrowIconStyle: {
      fontSize: "16px",
      opacity: 0.6,
      color: "#fdfdfe"
    },
  alertIcon: {
      "& g": {
        "& mask": {
          fill: "white"
        },
        "& path": {
          fill: "white"
        }
      }
    },
  auditIcon: {
      "& g": {
        "& path": {
          stroke: "white"
        }
      }
    },
  iconHolder: {
      width: 28,
      paddingRight: 11,
      margin: "auto"
    },
  iconBtn: {
      width: 30,
      height: 30,
      padding: 0,
      borderRadius: 6,
      alignSelf: "flex-start"
    },
  smallPaper: {
      height: 307
    },
  titleText: {
      fontSize: 18,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#fff"
    }
});
export default useStyles;
