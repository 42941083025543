import { createStyles } from "@material-ui/styles";

export const systemTableStyle = () =>
  createStyles({
    HVACTable: {
      borderLeft: "1px solid grey"
    },
    tableHead: {
      background: "rgb(236,235,239,0.5)",
      color: "rgb(84,89,100)"
    },
    tableHeadCell: {
      borderBottom: "none",
      padding: "0 15px",
      fontSize: "13px",
      color: "#79797d",
      fontWeight: "bold",
      lineHeight: "normal",
      letterSpacing: "normal",
      backgroundColor: "#efeff2",
      textTransform: "uppercase",
      height: 45
    },
    customTableHead: {
      border: "none",
      background: "white",
      borderTop: "1px solid grey",
      marginRight: "10px"
    },
    elevation: {
      boxShadow: "none"
    },
    customTableCell: {
      border: "none",
      marginRight: "10px",
      minWidth: "120px",
      display: "block",
      fontWeight: "normal"
    },
    customTable: {
      display: "table-cell",
      borderSpacing: "10px"
    },
    alertIcon: {
      color: "#f05347",
      marginTop: "4px",
      fontSize: "1.3rem"
    },
    hide: {
      visibility: "hidden"
    },
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "row"
    },

    tableWrapper: {
      width: "100%",
      height: "100%",
      overflow: "auto"
    },

    tableCell: {
      fontSize: "13px",
      color: "rgb(84, 89, 100)",
      padding: "0 15px",
      height: "35px"
    },
    customeTableCell: {
      fontSize: "0.75rem",
      color: "rgb(84, 89, 100)",
      border: "none"
    },
    HVACSection: {
      background: "red",
      flexDirection: "column",
      flexGrow: 0,
      maxWidth: "50%",
      flexBasis: "50%"
    },

    brandLogo: {
      WebkitFilter: "grayscale(1) /* Google Chrome, Safari 6+ & Opera 15+ */",
      filter: "grayscale(1) /* Microsoft Edge and Firefox 35+ */",
      minWidth: "4.5rem"
    },
    minWidthStyle: { minWidth: "22ch", wordBreak: "break-all", maxWidth: "22ch", width: "22ch" },
    pageContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      height: "calc(100% - 40px)",
      width: "100%",
      flex: 1
    },
    systemTableFooter: {
      position: "absolute",
      top: "5px",
      right: "20px",
      zIndex: 1
    },
    removeBottomBorder: {"& td": {border: 0}}
  });
