import { fade } from "@material-ui/core/styles/colorManipulator";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      margin: "auto"
    },
    dayStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "41px",
      borderRadius: "6px",
      backgroundColor: fade("rgb(255,255,255)", 0.1),
      margin: "4px",
      minWidth: "33px",
      maxWidth: "12%",
      width: "50px",
      flex: 1,
      "& *": {
        color: "white",
        fontSize: "14px"
      },
      cursor: "pointer"
    },
    activeDayStyle: {
      backgroundColor: theme.palette.secondary.main
    },
    disableClickPointer: {
      cursor: "unset"
    },
    mobileDayStyle: {
      backgroundColor: "#fff",
      border: "solid 1px #d5d2d5",
      borderRadius: "8px",
      minHeight: 45,
      minWidth: 45
    },
    mobileActiveDay: {
      background: "#e1dfe1"
    },
    dayTypo: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: "normal",
      lineHeight: "normal",
      letterspacing: "normal",
      textAlign: "center",
      color: "#545964"
    }
  });
