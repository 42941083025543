import React from 'react';

function SvgExpandMore(props: any) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#AAA2AA"
                    d="M13.267 8.97h-2.372l.39-5.155c.06-.793-.958-1.13-1.356-.449l-3.822 6.52c-.295.503.056 1.145.626 1.145h2.37l-.389 5.155c-.059.792.959 1.128 1.357.448l3.822-6.52c.295-.502-.056-1.144-.626-1.144"/>

                <circle cx="10" cy="10" r="9.2" stroke="#AAA2AA" strokeWidth="1.6"/>
            </g>
        </svg>
    );
}

export default SvgExpandMore;
