import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import AddRule from "../../components/AddRule/AddRule";
import Delete from "../../components/Delete/Delete";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import { Lookup } from "../../components/Lookup";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import TableColumnSearch from "../../components/TableColumnSearch/TableColumnSearch";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { ArrowDown } from "../../icons";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AddEditGroupsPopup from "./AddEditGroupsPopup";
import ClickableRow from "./ClickableRow";
import useStyles from "./Groups.style";
import Row from "./Row";

const Groups: React.FC = (props: any) => {
  const classes = useStyles();

  const getGroups = useStoreActions((a) => a.groups.getMygroups);
  const deleteGroup = useStoreActions((a) => a.groups.deleteGroupAPI);
  const updateGroup = useStoreActions((a) => a.groups.updateGroupAPI);
  const createGroup = useStoreActions((a) => a.groups.createCustomerGroup);
  const { addMessage } = useStoreActions((a) => a.errorMessage);

  const isInitialized = useStoreState((s) => s.isInitialized);
  const types = useStoreState((s) => s.types.allTypes);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const sites = useStoreState((s) => s.sites.allSites);
  const allSensors = useStoreState((s) => s.sensors.allSensors);
  const { selections } = useStoreState((s) => s.selections);
  const { customerId } = selections;

  const [units, setUnits] = useState<any>({});
  const [sensors, setSensors] = useState<any>({});
  const [selectedGroupSites, setSelectedGroupSites] = useState({});
  const [groups, setGroups] = useState<any>({});
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [editIdGroup, setEditIdGroup] = useState<string>("");
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [isDataReady, setDataReady] = useState<boolean>(false);

  useEffect(() => {
    const { sensorTypes } = types;
    const sensors: any = {};
    Object.values(allSensors).forEach((sensor: any) => {
      if (sensorTypes[sensor.type]?.enableView) {
        sensors[sensor.id] = sensor;
      }
    });
    setUnits(allUnits);
    setSensors(sensors);
    setDataReady(true);
  }, []);

  useEffect(() => {
    if (!isDataReady) {
      return;
    }

    customerId && getGroups().then((groups: any) => {
      const filteredGroupsPerCustomer: any = {};
      Object.values(groups).forEach((group: any) => {
        const { units, sensors: groupSensors, customer, id } = group;

        if (customerId !== customer) {
          return;
        }

        group.units = units.filter((unitId: string) => allUnits[unitId]);
        group.sensors = groupSensors.filter((sensorId: string) => sensors[sensorId]);
        filteredGroupsPerCustomer[id] = group;
      });
      setGroups(filteredGroupsPerCustomer);
    }).catch((err: any) => addMessage({ message: err.message }));
  }, [customerId, isDataReady]);

  const deleteSelectedGroup = (id: string) => {
    return deleteGroup(id).then(() => {
      delete groups[id];
      setGroups({ ...groups });
    }).catch((err: any) => addMessage({ message: err.message }));
  };

  const updateEditedGroup = (groupId: string, data: any) => {
    return updateGroup({ groupId, data }).then((group: any) => {
      groups[group.id] = group;
      setGroups(groups);
      if (groupId === selectedGroup) {
        handleGroupSelect(group);
      }
    })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => setEditIdGroup(""));
  };

  const createNewGroup = (data: any) => {
    return createGroup({ customerId: customerId || "", data }).then((group: any) => {
      groups[group.id] = group;
      setGroups(groups);
    })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => setEditIdGroup(""));
  };

  const handleGroupSelect = (group: any) => {
    const { id, units: groupUnits, sensors: groupSensors } = group;
    const groupSites: any = {};

    groupUnits.forEach((unitId: string) => {
      const { site = "" } = units[unitId] || {};
      const siteObj = sites[site];

      if (!site || !siteObj) {
        return;
      }

      if (!groupSites[site]) {
        groupSites[site] = { id: site, name: siteObj.name, sensors: [], units: [unitId] };
      } else {
        groupSites[site].units.push(unitId);
      }
    });

    groupSensors.forEach((sensorId: string) => {
      const { site = "" } = sensors[sensorId] || {};
      const siteObj = sites[site];

      if (!site || !siteObj) {
        return;
      }

      if (!groupSites[site]) {
        groupSites[site] = { id: site, name: siteObj.name, sensors: [sensorId], units: [] };
      } else {
        groupSites[site].sensors.push(sensorId);
      }
    });

    setSelectedSite("");
    setSelectedGroup(id);
    setSelectedGroupSites(groupSites);
  };

  const handleSiteSelect = (siteId: string) => {
    setSelectedSite(selectedSite === siteId ? "" : siteId);
  };

  if (!isInitialized) {
    return <Loading />;
  }

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          hideSiteSelection
          hideSystemSelection
          hideUnitSelection
          path={["Settings - Groups"]}
          btn="Add New"
          btnFc={() => setEditIdGroup("new")}
        />
        <Paper elevation={0} className={classes.paperTableContainer}>
          <div className={classes.groupsSection}>
            <Typography className={classes.title}>{t`Groups`}</Typography>
            <div className={classes.itemsContainer}>
              {Object.values(groups).map((group: any, index: number) =>
                <ClickableRow
                  key={index}
                  name={group.name}
                  selected={group.id === selectedGroup}
                  unitsNumber={group?.units?.length + group?.sensors?.length}
                  onDelete={() => deleteSelectedGroup(group.id)}
                  onEdit={(event: any) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setEditIdGroup(group.id);
                  }}
                  onClick={() => handleGroupSelect(group)}
                />
              )}
            </div>
          </div>
          <div className={classes.unitssSection}>
            <Typography className={classes.title}>{t`Units`}</Typography>
            <div className={clsx(classes.itemsContainer, classes.unitsItemsContainer)}>
              {groups[selectedGroup] && Object.values(selectedGroupSites).map((siteObj: any) => {
                const { id, name, sensors: siteSensors, units: siteUnits } = siteObj;
                const selected = id === selectedSite;

                return (<>
                  <ClickableRow
                    key={id}
                    name={name}
                    selected={selected}
                    unitsNumber={siteUnits?.length + siteSensors?.length}
                    collapse={true}
                    onClick={() => handleSiteSelect(id)}
                    removeMargin={selected}
                  />
                  {selected &&
                    <div>
                      {Object.values(siteUnits).map((unitId: any, index: number) =>
                        <Row key={`${id}-${unitId}`} name={units[unitId]?.name} hideLine={(siteUnits.length - 1 === index) && Object.values(siteSensors).length === 0} />
                      )}
                      {Object.values(siteSensors).map((sensorId: any, index: number) =>
                        <Row key={`${id}-${sensorId}`} name={sensors[sensorId]?.name} hideLine={siteSensors.length - 1 === index} />
                      )}
                    </div>}
                </>);
              })}
            </div>
          </div>
        </Paper>
      </div>
      {editIdGroup &&
        <AddEditGroupsPopup
          onClose={() => setEditIdGroup("")}
          group={groups[editIdGroup]}
          sites={sites}
          units={units}
          sensors={sensors}
          unitTypes={types?.unitTypes}
          createGroup={createNewGroup}
          updateGroup={updateEditedGroup}
        />
      }
    </div >
  );
};

export default Groups;
