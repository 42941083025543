import React from 'react';

function SvgSystemsAlertsLight(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <path id="systems-alerts-light_svg__a" d="M0 .34h14.846v11.41H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx={12.5} cy={12.5} r={12.5} fill="#E5DFE5" />
        <g transform="translate(5 4.897)">
          <mask id="systems-alerts-light_svg__b" fill="currentColor">
            <use xlinkHref="#systems-alerts-light_svg__a" />
          </mask>
          <path
            fill="#4B1C46"
            d="M13.526 10.237a.502.502 0 01-.44-.497V6.007A5.675 5.675 0 007.42.339a5.674 5.674 0 00-5.666 5.668v3.739a.503.503 0 01-.437.497l-.698.09a.712.712 0 00.093 1.417h13.417a.716.716 0 00.716-.667c.043-.673-.47-.738-1.318-.846"
            mask="url(#systems-alerts-light_svg__b)"
          />
        </g>
        <path fill="#4B1C46" d="M10.364 17.735a2.03 2.03 0 004.06 0h-4.06z" />
      </g>
    </svg>
  );
}

export default SvgSystemsAlertsLight;
