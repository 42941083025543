import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import SiteCountCard from "./SiteCountCard";

const OperationalOverview: React.FC<any> = (props) => {
  const getUnitsBy = useStoreState((s) => s.units.getUnitsBy);
  const { selections } = useStoreState((s) => s.selections);
  const getSiteUnits = useStoreState((state) => state.sites.getSiteUnits);

  const allUnits = getUnitsBy("customer", selections?.customerId, { type: "indoor" });
  const units = selections.siteId ? getSiteUnits(selections.siteId, 1) : Object.values(allUnits);
  const indoorUnits = units.length;

  const heatModeCount = {indoorUnits, onIndoorUnits: 0, coolIndoorUnits: 0, heatIndoorUnits: 0};
  let {onIndoorUnits, coolIndoorUnits, heatIndoorUnits} = heatModeCount;

  Object.values(units).forEach((unit: any) => {
    const { activeOperationMode, activeOperationStatus } = unit;

    if (activeOperationStatus === 1){ //on
      heatModeCount.onIndoorUnits = ++onIndoorUnits;

      if (activeOperationMode === 0){ //cool
        heatModeCount.coolIndoorUnits = ++coolIndoorUnits;
      }

      if (activeOperationMode === 1){ //heat
        heatModeCount.heatIndoorUnits = ++heatIndoorUnits;
      }
    }
  });

  const connectedModeCount = {indoorUnits, connectedIndoorUnits: 0, disconnectedIndoorUnits: 0};
  let {connectedIndoorUnits, disconnectedIndoorUnits} = connectedModeCount;

  Object.values(units).forEach((unit: any) => {
    const { isConnected  } = unit;

    if (isConnected){ //on
      connectedModeCount.connectedIndoorUnits = ++connectedIndoorUnits;
    }else{
      connectedModeCount.disconnectedIndoorUnits = ++disconnectedIndoorUnits;
    }
  });

  return (
  <>
    <SiteCountCard
      totalIndoorUnits={indoorUnits}
      conditionIndoorUnits={onIndoorUnits}
      blueIndoorUnits={coolIndoorUnits}
      redIndoorUnits={heatIndoorUnits}
      title={t`Indoor Units ON`}
      mode="heatcool"
    />
    <SiteCountCard
      totalIndoorUnits={indoorUnits}
      conditionIndoorUnits={connectedIndoorUnits}
      blueIndoorUnits={connectedIndoorUnits}
      redIndoorUnits={disconnectedIndoorUnits}
      title={t`Indoor Units\nConnected to App`}
      mode="connected"
    />

  </>
  );
};

export default OperationalOverview;
