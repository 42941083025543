import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  siteCard: {
    display: "flex",
    flexFlow: "row nowrap",
    width: "100%",
    padding: "0px 0px 25px 12px"
  },
  column1Holder: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "101px",
    justifyContent: "flex-end",
    marginRight: 10,
    alignItems: "center"
  },
  indoorUnitsText: {
    fontFamily: "Roboto",
    fontSize: "13px",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    marginTop: 5,
    flexDirection: "row",
    whiteSpace: "pre-line"
  },
  column2Holder: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "80px",
    justifyContent: "center"
  },
  modesCount: {
    fontFamily: "Roboto",
    fontSize: "12px",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    display: "flex",
    alignItems: "center",
    height: "32px"
  },
  modesIcons: { width: 18, height: 21, marginRight: 7 },
  indoorUnitsCountStyle: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    top: "66px",
    height: "14px",
    fontFamily: "Roboto",
    fontSize: "12px",
    letterSpacing: "normal",
    color: "#29132e"
  },
  onIndoorCountStyle: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    height: "40px",
    top: "22px",
    fontFamily: "Roboto",
    fontSize: "34px",
    letterSpacing: "normal",
    color: "#545964"
  },
  relativeContainer: {
    position: "relative"
  },
  container: {
    display: "flex",
    flexDirection: "column"
  },
  row: {
    textAlign: "center",
    display: "flex"
  }
}));

export default useStyles;
