import {
    CircularProgress,
    IconButton,
    makeStyles,
    Typography
  } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment-timezone";
import React, {useState} from "react";
import { t} from "ttag";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import { ClosedSensor, CO2, Humidity, OpenSensor, SwitcherOffIcon as SvgPowerOff, SwitcherOnIcon as SvgPowerOn, TempSensor} from "../../../../svgComponents";
import unitPanelStyle from "../UnitPanel/UnitPanel.style";
import SensorValue from "./SensorValue";

export const icons1: any = {
    1: <TempSensor />,
    2: <CO2 />,
    3: "",
    4: "",
    5: <Humidity style={{transform: "scale(4.4)"}} />
    };

interface IObject {
    [key: string]: any;
  }

const SensorPanel: React.FC<any> = (props) => {
    const {
      sensor,
      types,
      isCelsius,
      siteTemp,
      tempSymbol,
      timezoneOffset
    } = props;

    const useStyles = makeStyles(unitPanelStyle);
    const { dateFormat , timeFormat } = useStoreState((state) => state.users);
    const {updateSensorValue, _storeUpdateSensor} = useStoreActions((actions) => actions.sensors);
    const { addMessage } = useStoreActions((action) => action.errorMessage);

    const [loading, setLoading] = useState<boolean>(false);

    const classes = useStyles();
    const { name,
            type,
            readingValue = 0,
            readingValueTimestamp, userData = {}, isWritable, id} = sensor || {};

    const { sensorTypes, sensorMeasurementUnits } = types;
    const sensorType = sensorTypes[type] || {};
    const {enableMinMaxSelection, enableNormalModeSelection, measurementUnits, enableMeasurementUnitSelection, valueMax, valueMin} = sensorType;
    const activeMeasurementUnit = enableMeasurementUnitSelection && userData?.measurementUnitsType ? userData?.measurementUnitsType : measurementUnits;
    const sensorUnit = sensorMeasurementUnits[activeMeasurementUnit]?.name;

    const timeToString = (timestamp: any) => {
      return  moment(timestamp).utcOffset(timezoneOffset).format(timeFormat);
  };
    const dateToString = (timestamp: any) => {
      return  moment(timestamp).utcOffset(timezoneOffset).format(dateFormat);
  };

    const handleOnOff = () => {
      setLoading(true);
      const updateValueTo = +readingValue === 0 ? 1 : 0;
      updateSensorValue({id, data: {value: updateValueTo}})
      .then(() => {
        _storeUpdateSensor({id, data: {readingValue: updateValueTo}});
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      }).finally(() => setLoading(false));

    };

    return (
      <div  className={classes.unitView} >
        <div className={classes.firstRowContainer}>
          <Typography className={classes.siteTemp}>{(isCelsius ? siteTemp.C  || "" : siteTemp.F || "") + " "}<span className={classes.scaleStyle}>{siteTemp.C && tempSymbol || ""}</span></Typography>
          <div className={classes.nameSection}>
            <Typography >{name}</Typography>
            <div className={classes.loaderContainer}>
            {loading && (<CircularProgress size={40} className={classes.loaderStyle} />)}
            </div>
          </div>
          {/* this div is to keep name in middle instead of right */}
            <div className={classes.powerContainer}>
            {(sensorUnit === "Open/Close" && isWritable) && <>
            <IconButton className={classes.powerIconStyle}  onClick={handleOnOff}>
              {+readingValue === 0 ?  <SvgPowerOff className={classes.powerOnButtonClick}/> : <SvgPowerOn className={classes.powerOffButtonClick}/>}
            </IconButton>
            <Typography className={classes.powerTextStyle}>{+readingValue === 1 ? t`POWER OFF` : t`POWER ON`}</Typography>
            </>}
            </div>
        </div>
        <div className={classes.secondRowContainerSensor}>
          <div className={classes.controlDiv}>
            <div className={classes.sensorWrapperStyle} >
              <div className={clsx(classes.innerControlsSensor)}>
              {type !== 130 &&  <div className={classes.sensorIcon}>
                { type !== 6 ?
                  icons1[activeMeasurementUnit] || ""
                  : +readingValue === 0 ? <OpenSensor  height="70" width="70" className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)}/>
                                  : <ClosedSensor height="70" width="70" className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)} />}
                </div>}
                <SensorValue
                  id={id}
                  value={+readingValue}
                  unit={sensorUnit}
                  enableRatio={enableMinMaxSelection}
                  valueMax={valueMax}
                  valueMin={valueMin}
                  userMin={userData?.rangeMin}
                  userMax={userData?.rangeMax}
                  enableNormal={enableNormalModeSelection}
                  userNormal={userData?.normalStateValue}
                  isCelsius={isCelsius}
                  isWritable={isWritable}
                  setLoading={setLoading}
                  type={type}
                />
                <div className={clsx(classes.timeDateStyle, {[classes.addTopMargin]: type === 130})}>
                    <div className={clsx(classes.dataTimeFont, classes.timeStyle)}> {timeToString(readingValueTimestamp)}</div>
                    <div className={classes.dataTimeFont}> {dateToString(readingValueTimestamp)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    );
  };

export default SensorPanel;
