import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  root: {
    outline: 0
  },
  formWrapper: {
    paddingTop: 0
  },
  textField: {
    width: "90%",
    backgroundColor: "white",
    "& div>.MuiFilledInput-root": {
      backgroundColor: "#ffffff",
      border: "1px solid #d5d2d5"
    }
  },
  textFieldContent: {
    backgroundColor: "#ffffff"
  },

  registerDevice: {
    margin: "0",
    maxWidth: "100%"
  },
  title: {
    fontWeight: 700,
    marginBottom: "1rem"
  },

  divider: {
    margin: "2rem"
  },

  imgContainer: {
    display: "flex",
    justifyContent: "center"
  },

  loadingModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  iconButton: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
  }
}));

export default useStyles;
