import coolremoteSDK from "coolremote-sdk";
import { Action, action, Thunk, thunk } from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "../RootStore";
import { IUnit } from "../Units";

export interface IUnitActions {
  handleUnitReconnected: Thunk<IRootStoreModel, { id: string }>;
  handleUnitDisconnected: Thunk<IRootStoreModel, { id: string }>;
  deleteUnit: Thunk<IRootStoreModel, { id: string }>;
  associateControlUnitToServiceUnit: Thunk<IRootStoreModel, { id: string; controlUnitId: string }, any /*injections*/, IRootStoreModel>;
  dissociateControlUnitFromServiceUnit: Thunk<IRootStoreModel, { id: string }>;
  _storeAssociateControlUnitToServiceUnit: Action<IRootStoreModel, { id: string; controlUnitId: string }>;
  _storeDissociateControlUnitFromServiceUnit: Action<IRootStoreModel, { id: string }>;
}

export const unitActions: IUnitActions = {
  // ! same code in both functions
  // TODO: optimize
  handleUnitReconnected: thunk(async (actions, payload) => {
    // Update unit from DB - it should already have isConnected set to true
    // const data = await coolremoteSDK.Unit.getUnitById(payload.id);
    // //console.log('handleUnitReconnected: payload', payload);
    // Why call _storeCreateUnit()? Because it assigns the answer as-is.
    // actions.units._storeCreateUnit({ id: data.id, data: data });
    // ---
    // Instead of adding the overhead of another API call, we set isConnected directly
    // NOTE: fighting the freeze problem
    actions.units._storeUpdateUnit({ id: payload.id, data: { isConnected: true } });
  }),

  handleUnitDisconnected: thunk(async (actions, payload) => {
    // Update unit from DB - it should already have isConnected set to false
    // const data = await coolremoteSDK.Unit.getUnitById(payload.id);
    // Why call _storeCreateUnit()? Because it assigns the answer as-is.
    // actions.units._storeCreateUnit({ id: data.id, data: data });
    // Instead of adding the overhead of another API call, we set isConnected directly
    // ---
    // NOTE: fighting the freeze problem
    actions.units._storeUpdateUnit({ id: payload.id, data: { isConnected: false } });
  }),

  deleteUnit: thunk(async (actions, payload) => {
    // Remove unit from store
    actions.units._storeDeleteUnit({ id: payload.id });

    // And delete unit from DB
    await coolremoteSDK.Unit.delete(payload.id);
  }),

  associateControlUnitToServiceUnit: thunk(async (actions, payload, store) => {
    const unit: IUnit | undefined = store.getStoreState().units.allUnits[
      payload.id
    ];

    await coolremoteSDK.Unit.associateControlUnitToServiceUnit(payload.id, payload.controlUnitId);

    actions._storeAssociateControlUnitToServiceUnit(payload);
  }),

  dissociateControlUnitFromServiceUnit: thunk(async (actions, payload) => {
    await coolremoteSDK.Unit.dissociateControlUnitFromServiceUnit(payload.id);

    actions._storeDissociateControlUnitFromServiceUnit(payload);
  }),

  _storeAssociateControlUnitToServiceUnit: action((state, payload) => {
    const serviceUnit: IUnit | undefined = state.units.allUnits[payload.id];
    const nextControlUnit: IUnit | undefined = state.units.allUnits[payload.controlUnitId];
    const prevControlUnit: IUnit | undefined = serviceUnit && serviceUnit.controlUnit
      ? state.units.allUnits[serviceUnit.controlUnit]
      : undefined;

    if (!_.isUndefined(serviceUnit)) {
      serviceUnit.controlUnit = payload.controlUnitId;
    }

    if (!_.isUndefined(prevControlUnit) && prevControlUnit.serviceUnits) {
      _.pull(prevControlUnit.serviceUnits, payload.id);
    }

    if (!_.isUndefined(nextControlUnit)) {
      if (!nextControlUnit.serviceUnits) {
        nextControlUnit.serviceUnits = [];
      }
      if (nextControlUnit.serviceUnits.indexOf(payload.id) === -1) {
        nextControlUnit.serviceUnits.push(payload.id);
      }
    }
  }),

  _storeDissociateControlUnitFromServiceUnit: action((state, payload) => {
    const serviceUnit: IUnit | undefined = state.units.allUnits[payload.id];
    const prevControlUnit: IUnit | undefined = serviceUnit && serviceUnit.controlUnit
      ? state.units.allUnits[serviceUnit.controlUnit]
      : undefined;

    if (!_.isUndefined(prevControlUnit) && prevControlUnit.serviceUnits) {
      _.pull(prevControlUnit.serviceUnits, payload.id);
    }

    if (!_.isUndefined(serviceUnit)) {
      delete serviceUnit.controlUnit;
    }
  })
};