import { Card, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { IDevice } from "../../models/Devices";
import { useStoreState } from "../../models/RootStore";
import { ISite } from "../../models/Sites";
import { ISystem } from "../../models/Systems";
import { IUnit } from "../../models/Units";
import styles from "./UnitInfo.module.css";
import useStyles from "./UnitInfo.style";

interface IProps {
  unit: IUnit;
  site: ISite;
  device: IDevice;
  system: ISystem | null | undefined;
}

export default function UnitInfo(props: IProps) {
  const classes = useStyles();
  const {
    id,
    name,
    serialNumber,
    privateId,
    capacity,
    capacityMeasurementUnits,
    proId,
    airnet,
    model,
    type
  } = props.unit;
  const siteName = props.site.name;
  const types = useStoreState((state) => state.types.allTypes)
  const { hvacBrands, capacityMeasurementUnitTypes } = types
  const brandName =
    props.system && !_.isNil(_.find(hvacBrands, { value: props.system.brandNum }))
      ? _.find(hvacBrands, { value: props.system.brandNum }).name
      : null;
  const brand = brandName ? brandName : "--";

  const unitTypesMirrror = useStoreState((state) => state.types.unitTypesMirrror);

  return (
    <Card className={classes.card}>
      <div className={classes.unitInfoHeader}>
        <Typography className={classes.unitInfoTitle}>{t`${unitTypesMirrror[type] || ""} Unit Information`}</Typography>
        {!props.device.isConnected &&
          <Typography className={classes.unitInfoDisconnectedText}>{t`- Unit device is disconnected`}</Typography>
        }
      </div>
      <div className={classes.itemsWrapper}>
        <div className={clsx(classes.item, classes.firstItem)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Unit Name`}</Typography>
          <Typography className={clsx(classes.info, classes.limited20)}>{name}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Unit SN`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "12ch" }}>{serialNumber}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Brand`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "17ch" }}>{brand}</Typography>
        </div>
        <div className={classes.item} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Site Name`}</Typography>
          <Typography className={clsx(classes.info, classes.limited20)}>{siteName}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`ID`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "7ch" }}> {privateId}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Model`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "12ch" }}> {model}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "11%" }}>
          <Typography className={classes.infoTitle}>{t`Capacity`}</Typography>
          {capacity !== 0 && (
            <div>
              <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "12ch" }}>
                {capacity && capacity}
                {capacity &&
                  capacityMeasurementUnits &&
                  _.invert(capacityMeasurementUnitTypes)[capacityMeasurementUnits]}
              </Typography>
            </div>
          )}
        </div>
        {airnet && airnet !== 0 ? (
          <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "11%" }}>
            <Typography className={classes.infoTitle}>{t`Airnet: `}</Typography>
            <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "12ch" }}>{airnet}</Typography>
          </div>
        ) : (
            <></>
          )}
      </div>
    </Card>
  );
}
