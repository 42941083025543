import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useState } from 'react';
import { t } from 'ttag';
import Delete from '../../components/Delete/Delete';
import Header from '../../components/Header/Header';
import Loading from '../../components/Loading/Loading';
import ServiceNavigationBar from '../../components/Menu/ServiceNavigationBar';
import { useStoreActions, useStoreState } from '../../models/RootStore';
import { IUser } from '../../models/Users';
import getUserRole from "../../services/userRole";
import UserEdit from './UserEdit';
import useStyle from './UserList.style';
import UserNew from './UserNew';

const UserList: React.FC = (props: any) => {
  const classes = useStyle();

  const isInitialized = useStoreState((s) => s.isInitialized);
  const users = useStoreState((s) => s.users.users);
  const selections = useStoreState((s) => s.selections.selections);
  const getCustomerName = useStoreState((state) => state.customers.getCustomerName);
  const deleteUser = useStoreActions((a) => a.deleteUser);
  const setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus);
  const [selectedUserId, setSelectedUserId] = useState<string>("")
  setUnitUpdateStatus({ status: "" });


  const tryDeleteUser = async (payload: any) => {
    let message = null;
    await deleteUser(payload)
      .then(() => { })
      .catch((e: any) => {
        message = e.message;
      });
    return message;
  }

  if (!isInitialized) return <Loading />;

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={["User Management"]}
          hideFilterArea
        />
        <div className={classes.buttonContainer}>
          <UserNew />
        </div>
        <Paper elevation={0} className={classes.paperTableContainer}>
          <TableContainer className={classes.tableContainer}>

            <Table stickyHeader className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow className={classes.tableHeadRow}>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >{t`FIRST NAME`}</TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >{t`LAST NAME`}</TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >{t`USERNAME`}</TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >{t`EMAIL`}</TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >{t`ROLE`}</TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >{t`CUSTOMER`}</TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    className={classes.iconsColumnWidth}
                    align="left"
                  >{t`REMOVE`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.orderBy((false
                  ? _.filter(
                    Object.values(users),
                    (user) => user.customer === selections.customerId
                  )
                  : Object.values(users)
                ), [(user) => user.firstName?.toUpperCase()],
                  ["asc"]).map((user: IUser, index) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      classes={{ root: classes.overWritePadding }}
                      onDoubleClick={() => setSelectedUserId(user.id)}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                        align="left"
                      >
                        {user.firstName}
                      </TableCell>
                      <TableCell
                        classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                        align="left"
                      >
                        {user.lastName}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                        align="left"
                      >
                        {user.username}
                      </TableCell>
                      <TableCell
                        classes={{ root: clsx(classes.overWritePadding, classes.breakLine) }}
                        align="left"
                      >
                        {user.email}
                      </TableCell>
                      <TableCell
                        classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                        align="left"
                      >
                        {user.permissions ? getUserRole(user.permissions) : "None"}
                      </TableCell>
                      <TableCell
                        classes={{ root: clsx(classes.overWritePadding, classes.limitWidth) }}
                        align="left"
                      >
                        {getCustomerName(user.customer)}
                      </TableCell>
                      <TableCell classes={{ root: classes.overWritePadding }} align="left">
                        <Delete
                          type={t`User`}
                          object={user}
                          detach={tryDeleteUser}
                          buttonClass={classes.deleteIcon}
                        ></Delete>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>


      </div>
      {selectedUserId && <UserEdit id={selectedUserId} onClose={() => setSelectedUserId("")} />}

    </div>
  );
};

export default UserList;
