import { BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import { ControlMobile, DashboardMobile, NotitifactionMobile, ReportsMobile, SettingsMobile} from "../../icons/";
import useStyles from "./BottomNav.style";
import NavItem from "./NavItem";

const navItems: any = {
  0: {path: "/dashboard"},
  1: {path: "/control"},
  2: {path: "/audits"},
  3: {path: "/alerts"},
  4: {path: "/settings"}
};

export default function BottomNav(props: any) {
  const classes = useStyles();
  const history = useHistory();

  return (
   <BottomNavigation
     className={classes.navBar}
     onChange={(event, newValue) => {
       const path = navItems[newValue]?.path;
       path && history.push(path);
     }}
     showLabels
   >
     <BottomNavigationAction style={{minWidth: "auto"}} icon={<NavItem icon={DashboardMobile} label="Dashboard" path="/dashboard"/>} />
     <BottomNavigationAction style={{minWidth: "auto"}} icon={<NavItem icon={ControlMobile} label="Control" path="/control"/>} />
     <BottomNavigationAction style={{minWidth: "auto"}} icon={<NavItem icon={ReportsMobile} label="Reports" path="/audits"/>} />
     <BottomNavigationAction style={{minWidth: "auto"}} icon={<NavItem icon={NotitifactionMobile} label="Notifications" path="/alerts" />} />
     <BottomNavigationAction style={{minWidth: "auto"}} icon={<NavItem icon={SettingsMobile} label="Settings" path="/settings"/>} />
   </BottomNavigation>
 );
}
