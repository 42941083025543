import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  memo,
  Thunk,
  thunk
} from "easy-peasy";
import _ from "lodash";
import { t } from "ttag";

export interface IInvitesModel {
  allInvites: any;
  createInvite: Thunk<IInvitesModel, { data: any, id: string }>;
  deleteInvite: Thunk<IInvitesModel, string>;
  sendInviteEmail: Thunk<IInvitesModel, { email: string; inviteId: string }>;
  getInvites: Thunk<IInvitesModel, string>;
  addNewInvite: Action<IInvitesModel, any>;
  removeExistInvite: Action<IInvitesModel, string>;
  setInvites: Action<IInvitesModel, any>;
  getAllInvites: Thunk<IInvitesModel, any>;
}

export const invitesModel: IInvitesModel = {
  allInvites: {},
  createInvite: thunk(async (actions, payload) => {
    const { data, id } = payload;
    const invite = await coolremoteSDK.Customer.createInvite(id, data);
    actions.addNewInvite(invite);
    return invite;
  }),
  deleteInvite: thunk(async (actions, payload) => {
    const invite = await coolremoteSDK.Invite.delete(payload);
    actions.addNewInvite(invite);
    return invite;
  }),
  sendInviteEmail: thunk(async (actions, payload) => {
    const { email, inviteId } = payload;
    return coolremoteSDK.Services.sendInviteEmail({ email }, inviteId);
  }),
  getInvites: thunk(async (actions, payload) => {
    const invites = await coolremoteSDK.Customer.getInvites(payload);
    actions.setInvites(invites);
    return invites;
  }),
  addNewInvite: action((state, payload) => {
    state.allInvites = { ...state.allInvites, [payload.id]: payload };
  }),
  removeExistInvite: action((state, payload) => {
    const { allInvites } = state;
    allInvites[payload] && delete allInvites[payload];
    state.allInvites = allInvites;
  }),
  setInvites: action((state, payload) => {
    state.allInvites = payload;
  }),
  getAllInvites: thunk(async (actions, payload) => {
    // const invites = await coolremoteSDK.Invite.getAllIvites();
    // actions.setInvites(invites);
    // return invites;
  })
};
