import { Trigger as TriggerSdk } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  Thunk,
  thunk
} from "easy-peasy";
import _ from "lodash";
import { t } from "ttag";
import { IRootStoreModel } from "./RootStore";

export interface ITrigger {
  id: string;
  name: string | null;
  sendOnce: boolean;
  resources?: string[];
  resourceIds: string[];
  rule: any;
  template?: string | null;
  templateData?: any;
  triggerTemplateId?: string;
  site?: string;
  customer?: string;
}

export interface ITriggerMap {
  [key: string]: ITrigger;
}

export interface ITriggersModel {
  allTriggers: ITriggerMap;
  updateTrigger: Thunk<ITriggersModel, { triggerId: string; updatedData: any }>;
  initialize: Action<ITriggersModel, any>;
  onInitialized: ActionOn<ITriggersModel, IRootStoreModel>;
  _storeUpdateTrigger: Action<ITriggersModel, { triggerId: string; updatedTriggerData: any }>;
  getTriggerParsedData: Computed<ITriggersModel, (id: string) => any, IRootStoreModel>;
}

// TODO: Use interface keyOf instead of specifying each key.
export const triggersModel: ITriggersModel = {
  allTriggers: {},

  updateTrigger: thunk(async (actions, payload) => {
    const updatedTriggerData = await TriggerSdk.update(payload.triggerId, payload.updatedData);

    actions._storeUpdateTrigger({
      triggerId: payload.triggerId,
      updatedTriggerData
    });
  }),

  initialize: action((state, payload) => {
    const newTriggers: ITriggerMap = _(Object.values(payload))
      .map((trigger: any) => {
        const newTrigger: ITrigger = { ...trigger };

        return newTrigger;
      })
      .keyBy("id")
      .value();
    state.allTriggers = newTriggers;
  }),
  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      // console.log(target.resolvedTargets);
      // console.log('Got trigger : ', debug(state.allTriggers));
    }
  ),

  _storeUpdateTrigger: action((state, payload) => {
    if (state.allTriggers[payload.triggerId]) {
      state.allTriggers[payload.triggerId] = payload.updatedTriggerData;
    }
  }),

  getTriggerParsedData: computed(
    [
      (state) => state.allTriggers,
      (state, storeState) => storeState.triggerTemplates.allTriggerTemplates,
      (state, storeState) => storeState.triggerTemplates.myTriggerTemplates
    ],
    (allTriggers, allTriggerTemplates, myTriggerTemplates) => (id) => {
      const trigger: ITrigger = allTriggers[id];

      let triggerParsedData = {
        typeText: ""
      };

      if (!trigger.template) {
        if (trigger.triggerTemplateId) {
          if (myTriggerTemplates[trigger.triggerTemplateId]) {
            triggerParsedData.typeText = myTriggerTemplates[trigger.triggerTemplateId].name;
          } else if (allTriggerTemplates[trigger.triggerTemplateId]) {
            triggerParsedData.typeText = allTriggerTemplates[trigger.triggerTemplateId].name;
          }
        }

        return triggerParsedData;
      }

      if (trigger.template.indexOf("UNIT_ERROR") !== -1) {
        triggerParsedData = {
          typeText: t`Unit error`
        };
      } else if (trigger.template.indexOf("UNIT_LIMITS") !== -1) {
        triggerParsedData = {
          typeText: t`Safety limits exceeded`
        };
      } else if (trigger.template.indexOf("UNIT_EXCEEDED") !== -1) {
        triggerParsedData = {
          typeText: t`Threshold exceeded`
        };
      } else if (trigger.template.indexOf("UNIT_CLEAN_FILTER") !== -1) {
        triggerParsedData = {
          typeText: t`Clean filter`
        };
      } else if (trigger.template.indexOf("SCHEDULED_EVENT") !== -1) {
        triggerParsedData = {
          typeText: t`Scheduled maintenance`
        };
      } else if (trigger.template.indexOf("DEVICE_DISCONNECT") !== -1) {
        triggerParsedData = {
          typeText: t`Device disconnected`
        };
      } else if (
        trigger.template.indexOf("ISTAT_BREACH") !== -1 ||
        trigger.template.indexOf("OSTAT_BREACH") !== -1
      ) {
        if (
          !_.isNil(trigger.templateData.biggerThan) &&
          _.isNil(trigger.templateData.smallerThan)
        ) {
          triggerParsedData = {
            typeText: t`System Parameter > Value`
          };
        } else if (
          _.isNil(trigger.templateData.biggerThan) &&
          !_.isNil(trigger.templateData.smallerThan)
        ) {
          triggerParsedData = {
            typeText: t`System Parameter < Value`
          };
        } else if (
          !_.isNil(trigger.templateData.biggerThan) &&
          !_.isNil(trigger.templateData.smallerThan)
        ) {
          triggerParsedData = {
            typeText: t`System Parameter < Value1 OR system Parameter > Value2`
          };
        }
      } else if (
        trigger.template.indexOf("ISTAT_EQUALS") !== -1 ||
        trigger.template.indexOf("OSTAT_EQUALS") !== -1
      ) {
        triggerParsedData = {
          typeText: t`System Parameter = Value`
        };
      } else if (
        trigger.template.indexOf("ISTAT_THRESHOLD_EXCEEDED") !== -1 ||
        trigger.template.indexOf("OSTAT_THRESHOLD_EXCEEDED") !== -1
      ) {
        triggerParsedData = {
          typeText: t`Difference between system-Parameter-1 and system-Parameter-2 > Value`
        };
      }

      return triggerParsedData;
    }
  )
};
