import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    width: "100%",
    flexFlow: "column nowrap",
    maxHeight: "150px",
    boxSizing: "border-box",
    zIndex: 10
  },
  selectionMenu: {},
  // ---
  pathContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  pathItem: {
    fontSize: "1.25rem",
    color: "#79797d",
    fontFamily: "RobotoMedium",
    "&:last-child": {
      color: "#29132e",
      fontWeight: "bold"
    }
  },
  pathSeparator: {
    color: "#7f7692",
    fontSize: "1.025rem !important"
  },
  userMenuContainer: { backgroundColor: theme.palette.primary.dark, height: "70px", display: "flex", justifyContent: "space-between", padding: "0 26px" },
  selectionMenuContainer: { boxShadow: "0 2px 3px 0 rgba(84, 89, 100, 0.07)", height: "80px", backgroundColor: "white", width: "100%", display: "flex", flexFlow: "row nowrap", padding: "0 30px", justifyContent: "space-between", alignItems: "center" }
  , pathStyle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#ffffff",
    display: "flex",
    alignItems: "center"
  },
  searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px"
  },
  searchIcon: {
    transform: "scale(0.7)"
  },
  inputRoot: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: "rgba(170, 162, 170, 0.3)",
    maxWidth: "70%",
    marginRight: "40px"
  },
  closeIconStyle: {
    color: "white",
    padding: 0,
    "& svg": {
      fontSize: "24px"
    },
    width: 30,
    height: 30,
    borderRadius: 6
  },
  rightSideHolder: {
    display: "flex",
    alignItems: "center"
  }
}));

export default useStyles;
