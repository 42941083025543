import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  computed,
  Computed,
  debug,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface IZone {
  id: string;
  name?: string;
  description?: string;
  site: string;
  units: string[];
  role: any;
}

export interface IZoneMap {
  [key: string]: IZone;
}

export interface IZonesModel {
  allZones: IZoneMap;
  initialize: Action<IZonesModel, any>;
  onInitialized: ActionOn<IZonesModel, IRootStoreModel>;
  deleteZone: Thunk<IZonesModel, string>;
  removeZone: Action<IZonesModel, string>;
  updateZone: Thunk<IZonesModel, { id: string; data: any }>;
  updateZonesState: Action<IZonesModel, any>;
  createZone: Thunk<IZonesModel, { siteId: string; data: any }>;
  addZone: Action<IZonesModel, IZone>;
  getZonesBySiteId: Thunk<IZonesModel, string>;
  getZones: Thunk<IZonesModel>;

  // getZones: Computed<IZonesModel>;
}

export const zonesModel: IZonesModel = {
  allZones: {},

  initialize: action((state, payload) => {
    const newZones: IZoneMap = _(Object.values(payload))
      .map((zone: any) => {
        const newZone: IZone = { ...zone };

        return newZone;
      })
      .keyBy("id")
      .value();
    return { ...state, allZones: newZones };
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      // console.log(target.resolvedTargets);
      // console.log('Got zones: ', debug(state.allZones));
    }
  ),
  deleteZone: thunk(async (actions, payload) => {
    const response = await coolremoteSDK.Zone.delete(payload);
    actions.removeZone(payload);
    return response;
  }),
  removeZone: action((state, payload) => {
    delete state.allZones[payload];
  }),
  updateZone: thunk(async (actions, payload) => {
    const updatedZone = await coolremoteSDK.Zone.update(
      payload.id,
      payload.data
    );
    actions.updateZonesState(updatedZone);
    return updatedZone;
  }),
  updateZonesState: action((state, payload) => {
    state.allZones[payload.id] = payload;
  }),
  createZone: thunk(async (actions, payload) => {
    const newZone = await coolremoteSDK.Site.createZone(
      payload.siteId,
      payload.data
    );
    actions.addZone(newZone);
    return newZone;
  }),
  addZone: action((state, payload) => {
    state.allZones[payload.id] = payload;
  }),
  getZonesBySiteId: thunk(async (actions, payload) => {
    const response = await coolremoteSDK.Site.getZones(payload);
    // actions.setSelectedGroups(response);
    actions.initialize(response);
    return response;
  }),
  getZones: thunk(async (actions, payload) => {
    const response = await coolremoteSDK.Zone.getZones();
    for (let zone of Object.values(response)) {
      actions.updateZonesState(zone);
    }
    return response;
  }),
  // getZones: computed(state => {
  //   const tenants = Object.values(state.allZones);
  //   return tenants || [];
  // }),
};
