import React from "react";

function SvgAdd(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 31" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect width={30} height={30} rx={4} fill="none" />
        <path
          d="M15 7.385v15.23M7.385 15h15.23"
          stroke="#7F7692"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.8}
        />
      </g>
    </svg>
  );
}

export default SvgAdd;
