import { fade } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    scheduleInfoContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "100%",
      alignItems: "center",
      height: "100%",
      maxHeight: "100%",
      overflow: "hidden"
      // maxWidth: "768px",
      // backgroundImage: "linear-gradient(to bottom, #161220, #3a183a 96%)"
    },
    pageContent: {
      flexFlow: "column nowrap",
      flex: 1,
      alignItems: "center",
      background: "transparent",
      position: "relative",
      maxWidth: "470px"

    },
    fieldContainerStyle: { width: "100%" },
    inputStyle: {
      fontSize: "18px",
      color: "rgb(255,255,255)"
    },
    iconStyle: { fontSize: "22px", color: "white" },

    errorLabelStyle: {
      color: "#D32F2F",
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "center",
      marginTop: "10px",
      minHeight: "15px"
    },
    dividerStyle: { marginBottom: "5px", marginTop: "5px" },
    daysContainer: {
      padding: "0px 0px",
      margin: "0px 0px",
      justifyContent: "space-between"
    },
    dayLabelStyle: {
      color: "white",
      backgroundColor: fade("rgb(255,255,255)", 0.1),
      width: "12%",
      fontSize: "14px",
      textTransform: "none",
      padding: "0px 0px",
      margin: "5px 0px",
      "& :hover": {
        backgroundColor: `${fade("rgb(255,255,255)", 0.1)} !important`,
        color: "rgb(255,255,255)"
      }
    },
    tabRoot: {
      minWidth: "45px",
      minHeight: "45px",
      marginRight: "7px"
    },
    highlited: {
      background: theme.palette.secondary.main,
      color: "rgb(255,255,255)",
      "&:hover": {
        background: theme.palette.secondary.main,
        color: "rgb(255,255,255)"
      }
    },
    inputsContainer: {
      display: "flex",
      flexFlow: "row no-wrap",
      justifyContent: "center",
      alignItems: "center"
    },
    controlArrowButton: {
      background: "transparent !important",
      "&:disabled": {
        "& path": {
          opacity: 0.6
        }
      }
    },
    setpointNotSet: {
      opacity: 0.6
    },
    setpointStyle: {
      width: "fit-content",
      minHeight: "94px",
      fontSize: "80px",
      fontWeight: "normal",
      lineHeight: "normal"
    },
    checkboxContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    setpointContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingRight: "13px"
    },
    fieldStyle: { width: "30px", "& input": { fontSize: "16px" } },

    valueTitle: {
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontWeight: "normal",
      lineHeight: "normal",
      height: "100%",
      display: "block"
    },
    startEndTimeContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center"
    },
    timeContainer: {
      height: "44px",
      width: "48%",
      color: "#fdfdfe",
      borderRadius: "22px",
      backgroundColor: fade("rgb(255,255,255)", 0.1),
      fontSize: "16px",
      "&:hover": {
        backgroundColor: `${fade("rgb(255,255,255)", 0.1)} !important`,
        color: "rgb(255,255,255)"
      }
    },
    statusStyle: {
      fontSize: "18px",
      fontHeight: "normal",
      fontWeight: "normal"
    },
    selectModeStyle: {
      fontSize: "18px",
      lineHeight: "normal",
      fontWeight: "normal",
      width: "100%",
      marginBottom: "18px"
    },
    setPointSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    saveButton: {
      width: "100%",
      height: "100%",
      display: "flex",
      minHeight: "60px",
      flexDirection: "column",
      justifyContent: "flex-end"
    },
    container: {
      width: "100%",
      color: "rgb(255,255,255)",
      padding: "9px 10px 16px 10px",
      borderRadius: "6px",
      background: fade("#fdfdfe", 0.1),
      marginTop: "20px",
      maxWidth: "768px",
      minHeight: "max-content"
    },

    bodyRow: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "13px"
    },
    scheduleStatusStyle: {
      color: "rgb(255,255,255)",
      fontSize: "18px"
    },
    controlSec: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "100%"
    },
    powerOnPowerOffTimeIndication: {
      border: `1px solid ${theme.palette.secondary.main}`
    },
    checkboxStyle: { marginLeft: "5px" },
    selectModeContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between"
    },
    tempSymbolStyle: { fontSize: "30px" },
    timeSelected: { border: `solid 1px ${theme.palette.secondary.main}` },
    iosTimeInput: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 10,
      visibilty: "none",
      borderRadius: "22px",
      "& input": {
        background: "blue",
        borderRadius: "22px",
        height: "100%",
        padding: 0,
        opacity: 0
      }
    },
    iosTimeLabel: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 9,
      borderRadius: "22px",
      color: "#fdfdfe",
      fontSize: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    iosHourButton: {
      position: "relative",
      display: "flex",
      height: "44px",
      width: "48%",
      borderRadius: "22px",
      backgroundColor: fade("rgb(255,255,255)", 0.1),
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
    },
    redBtn: {
      marginBottom: 20,
      minWidth: "120px",
      height: "45px",
      borderRadius: "6px",
      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: "#f05347",
      border: 0,
      "&:hover": {
        backgroundColor: fade("#f05347", 0.7),
        border: 0
      },
      "&:focus": {
        backgroundColor: "#b3b3b3",
        border: 0
      },
      "&:active": {
        backgroundColor: "#f05347",
        border: 0
      },
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff",
      width: "100%",
      marginTop: "20px"
    },
    backgroundContainer: {
      display: "flex",
      width: "calc(100% - 9px)",
      justifyContent: "center",
      padding: "0 20px",
      height: "100%",
      backgroundImage: "linear-gradient(to bottom, #161220, #3a183a 96%)",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        backgroundColor: "transparent"
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(255,255,255,0.26)"
      }
    },
    bigIconBtnStyle: {
      width: 40,
      height: 40,
      borderRadius: 6,
      padding: 0
     }
  });
