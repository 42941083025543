import { Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import {
    Swing
} from "../../svgComponents";

import { useStoreState } from "../../models/RootStore";
import { IServiceTypes } from "../../models/Types";
import Switch from "../SmallSwitch/Switch";
import switchList from "./SwitchList.style";

const SwitchList = (props: any) => {
    const { unit, setTStatFlags, tStatFlags, isViewOnly, unitItem, unitLimits, disabled = false } = props;
    const useStyle = makeStyles(switchList);
    const classes = useStyle();
    const { container, switchContainer, switchTitle, viewTstatContainer, viewSwitchContainer, viewUnitItemContainer, UnitLimitsContainer  } = classes;

    if (!tStatFlags) {
        return null;
    }

    const onUpdateFlag = (flagName: string) => {
        const nextFlagState = _.clone(tStatFlags);
        nextFlagState[flagName] = !nextFlagState[flagName];
        setTStatFlags(nextFlagState);
    };

    const listMap: any = {
        "enableOnoff": "Allow On/Off",
        "enableMode": "Allow mode settings",
        "enableSetpoint": "Allow setpoint change",
        "enableOnState": "Allow On only",
        "all": "All"

    };

    return (
        <>
            {_.map(tStatFlags, (value, key) => {
                return (

                    <div key={key}className={clsx(
                        switchContainer ,
                        {[viewSwitchContainer]: isViewOnly} ,
                        {[viewUnitItemContainer]: unitItem && !isViewOnly},
                        {[UnitLimitsContainer]: unitLimits})} >
                        <Typography className={switchTitle}>{listMap[key]}</Typography>
                        {!isViewOnly &&
                        <Switch
                            className={classes.switch}
                            disabled={disabled}
                            checked={!!!value}
                            switchChange={(e: any) => onUpdateFlag(key)}
                        />
                        }
                    </div>

                );
            })}
        </>
    );
};

export default SwitchList;
