import React from 'react';

function SvgStatistics(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect width={3} height={13} x={9} y={3} fill="currentColor" fillRule="nonzero" rx={0.8} />
        <rect width={3} height={9} x={14} y={7} fill="currentColor" fillRule="nonzero" rx={0.8} />
        <rect width={3} height={5} x={4} y={11} fill="currentColor" fillRule="nonzero" rx={0.8} />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.4}
          d="M1 1v18h18"
        />
      </g>
    </svg>
  );
}

export default SvgStatistics;
