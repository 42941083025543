import { fade } from "@material-ui/core/styles/colorManipulator";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      flexWrap: "wrap"
    },
    modeStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "8px",
      borderRadius: "6px",
      backgroundColor: `#e1dfe1`,
      marginRight: 15,
      marginTop: 10,
      "& *": {
        color: "white",
        fontSize: "20px"
      },
      marginBottom: 0
    },
    activeModeStyle: {
      backgroundColor: "#ffffff",
      border: "solid 1px #e1dfe1"
    },

    fanStyleIcon: {
          "& *": {
              color: "#4b1c46",
              opacity: 1,
              // stroke: "#4b1c46",
              fill: "#4b1c46"
          },
          padding: "10px 19px"
      },

    swingStyleIcon: {
      "& *": {
        color: "#4b1c46",
        opacity: 1,
        stroke: "#4b1c46"
        // fill: "#4b1c46",
      },
      padding: "4px 12px"

    },

    operationModes: {
        width: "4rem",
        height: "3rem",
        "& *": {
              color: "#968896",
              opacity: 1,
              stroke: "#968896"
              // fill: "#4b1c46",
          }
      },

    operationModesCool: {
          "& p": { color: "#F6F5F6" },
          "& *": {
              color: "#35a8e0",
              opacity: 1,
              stroke: "#35a8e0"
          }
      },
    operationModesHeat: {
          "& p": { color: "#F6F5F6" },
          "& *": {
              color: "#f05146",
              opacity: 1,
              stroke: "#f05146"
          }
      },

    inactiveText: {
      // "& p": { color: "#D5D2D5" },
      "& *": {
        color: "#4b1c46",
        opacity: 1,
        stroke: "#4b1c46"
        // fill: "#4b1c46",
      }
      // "& g": {
      //   stroke: "#4b1c46",
      // },
      // "& path": {
      //   stroke: "#4b1c46",
      // },
    },

    nameStyle: {
        fontSize: "16px",
        color: "#545964",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        fontFamily: "Roboto",
        letterSpacing: "-0.39px"
      },
    daysBtns: {
        height: 40,
        width: 44,
        borderRadius: 8,
        margin: 0,
        marginTop: 9,
        marginRight: 10

    // background: #d5d2d5
  }
  });
