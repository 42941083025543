import { Grid , TextField, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import {BottomNav, TopBar} from "../../components";
import Switch from "../../components/SmallSwitch/Switch";
import Button from "../../cool_widgets/Button";
import { MobileLogo, MobileMenu} from "../../icons/";
import ArrowIcon from "../../icons/BigArrowUp";
import { ArrowBack } from "../../svgComponents";
import ModesList from "./ModesList/ModesList";

// import { ConfirmationDialog, Toolbar } from "../../components";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import unitManagementStyles from "./unitManagement.style";

const UnitManagement = (props: any) => {
  const history = useHistory();
  const [unit, setUnit] = useState<any>({});

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [reloadUnitsInTopFilters, setReloadUnitsInTopFilters] = useState<boolean>(false);

  const types: any = useStoreState((states) => states.types.allTypes);
  const addMessage = useStoreActions((actions) => actions.errorMessage.addMessage);
  const updateUnit = useStoreActions((actions) => actions.units.updateUnit);
  const getUnit = useStoreState((state) => state.units.getUnit);
  const {unitId} = useStoreState((state) => state.selections.mobileSelections);

  useEffect(() => {
    if (!unitId) {
      return;
    }

    const unit = getUnit(unitId);
    setUnit(unit);

  }, [unitId]);

  const goBack = () => {
    history.push("/unit-settings");
  };

  const getUnitKeyOfMode = (typesMode: string) => {
    let unitModeName: any = "";
    switch (typesMode) {
      case "operationModes":
        unitModeName = "supportedOperationModes";
        break;
      case "fanModes":
        unitModeName = "supportedFanModes";
        break;
      case "swingModes":
        unitModeName = "supportedSwingModes";
        break;

      default:
        unitModeName = null;
        break;
    }
    return unitModeName;
  };

  const updateLocalUnit = (modeName: string, modeNumber: any) => {
    const locUnit = { ...unit };
    const unitModeName = getUnitKeyOfMode(modeName);
    if (!unitModeName) {
      return;
    }

    const checkIndex = locUnit[unitModeName].indexOf(+modeNumber);
    if (checkIndex > -1) {
      locUnit[unitModeName].splice(checkIndex, 1);
    } else {
      locUnit[unitModeName] = [...locUnit[unitModeName], +modeNumber];
    }
    setIsSaveDisabled(false);
    setUnit(locUnit);
  };

  const saveUnit = () => {
    const {
      name,
      isVisible,
      supportedOperationModes,
      supportedFanModes,
      supportedSwingModes
    } = unit;
    updateUnit({
      id: unitId,
      updatedData: {
        name,
        isVisible,
        supportedOperationModes,
        supportedFanModes,
        supportedSwingModes
      }
    }).then(() => {
      setReloadUnitsInTopFilters(true);
      setIsSaveDisabled(true);
      // goBack();
    }).catch((error: any) => {
      addMessage({ message: error.message });
    });
  };

  const updateUnitName = (e: any) => {
    const { value } = e.target;
    setUnit({ ...unit, name: value });
    setIsSaveDisabled(false);
  };

  const changeSupportedValues = (e: any, mode: string) => {
    const isChecked = e.target.checked;
    const unitModeName = getUnitKeyOfMode(mode);
    const updatedUnit = { ...unit };

    if (!unitModeName) {
      return;
    }
    updatedUnit[unitModeName] = !isChecked
      ? []
      : Object.keys(types[mode]).map((key: any) => +key);
    setUnit(updatedUnit);
    setIsSaveDisabled(false);
  };

  const changeUnitVisibility = (isVisible: boolean) => {
    setUnit({ ...unit, isVisible });
    setIsSaveDisabled(false);
  };

  const useStyles = makeStyles(unitManagementStyles);
  const classes = useStyles();
  const checkIfCanGoBack = () => {
    setOpenDialog(true);
  };
  const cancel = () => {
    setOpenDialog(false);
  };
  const arrowBack = <ArrowBack />;
  const {
    name,
    isVisible,
    supportedOperationModes,
    supportedFanModes,
    supportedSwingModes,
    line,
    privateId
  } = unit;

  const NoUnitSelected = () => (
      <Grid
      container={true}
      direction={"column"}
      className={classes.noContentContainer}
      >
      <div className={classes.grayOval}>
      <ArrowIcon className={classes.arrowIcon} />
      </div>
      <Typography className={classes.noUnitText}>
      {t`Please select a unit using the above filters.`}
      </Typography>
      </Grid>
    );

  return (
    <>
    <TopBar  leftAction={() => history.push("/dashboard")}
    leftIconComponent={<MobileLogo />}
    rightIconComponent={<MobileMenu />}
    reload={reloadUnitsInTopFilters}
    />
    <div className={classes.root}>
      {!unitId ? <NoUnitSelected /> :
        <>
        <div className={classes.pageContent}>
        <div className={classes.nameSection}>
          <TextField
            value={name || ""}
            onChange={updateUnitName}
            className={classes.textStyle}
            label={t`Unit Name`}
            variant="outlined"
          />
        </div>

        <div className={clsx(classes.section, classes.controlSection)}>
        <div className={classes.controlSectionHead}>
        <Typography
        className={classes.controlSectionTitle}
        >{t`Mode Settings`}</Typography>
        <Switch
        checked={
          !!(supportedOperationModes && supportedOperationModes.length)
        }
        switchChange={(e: any) =>
          changeSupportedValues(e, "operationModes")
        }
        />
        </div>
        <div className={classes.controlSectionBody}>
        <ModesList
        modeName={"operationModes"}
        activeModes={supportedOperationModes}
        action={updateLocalUnit}
        />
        </div>
        </div>

        <div className={clsx(classes.section, classes.controlSection)}>
        <div className={classes.controlSectionHead}>
        <Typography
        className={classes.controlSectionTitle}
        >{t`Fan Speed Options`}</Typography>
        <Switch
        checked={!!(supportedFanModes && supportedFanModes.length)}
        switchChange={(e: any) => changeSupportedValues(e, "fanModes")}
        />
        </div>
        <div className={classes.controlSectionBody}>
        <ModesList
        modeName={"fanModes"}
        activeModes={supportedFanModes}
        action={updateLocalUnit}
        />
        </div>
        </div>

        <div className={clsx(classes.section, classes.controlSection)}>
        <div className={classes.controlSectionHead}>
        <Typography
        className={classes.controlSectionTitle}
        >{t`Swing Options`}</Typography>
        <Switch
        checked={!!(supportedSwingModes && supportedSwingModes.length)}
        switchChange={(e: any) => changeSupportedValues(e, "swingModes")}
        />
        </div>
        <div className={classes.controlSectionBody}>
        <ModesList
        modeName={"swingModes"}
        activeModes={supportedSwingModes}
        action={updateLocalUnit}
        />
        </div>
        </div>

        <div className={clsx(classes.controlSectionHead, classes.normalSection)}>
          <Typography className={classes.controlSectionTitle}>{t`Display Unit in Control Tab`}</Typography>
          <Switch
            checked={isVisible ? isVisible : ""}
            disableRipple={true}
            switchChange={(e: any) => changeUnitVisibility(!isVisible)}
            value="checkedA"
          />
        </div>

      </div>
      <Button
      disabled={isSaveDisabled}
      className={classes.saveButton}
      onClick={() => saveUnit()}
      >{t`Save`}</Button>
      </>}
    </div>
    < BottomNav  />
    </>
  );
};

export default UnitManagement;
