import React from 'react';

const SvgUser1 = (props: any) => (
  <svg width={25} height={26} {...props}>
    <g transform="translate(0 .5)" fill="#AAA2AA" fillRule="evenodd">
      <circle fillOpacity={0.2} cx={12.5} cy={12.5} r={12.5} />
      <path
        d="M18.651 15.663c.123.314.203.644.24.98.043.302.077.606.101.91l.008.084v.042c0 .815-.66 1.477-1.476 1.477H7.474A1.476 1.476 0 016 17.679v-.042l.006-.089c.026-.303.06-.607.101-.908.038-.336.12-.664.242-.977.454-.99 3.795-2.416 6.151-2.416s5.697 1.427 6.151 2.416M12.5 12.139A3.069 3.069 0 1112.498 6a3.069 3.069 0 01.002 6.138"
        opacity={0.5}
      />
    </g>
  </svg>
);

export default SvgUser1;
