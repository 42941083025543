import { System as SystemSdk } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  memo,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { IAlert } from "./Alerts";
import { IGroup } from "./Groups";
import { IRootStoreModel } from "./RootStore";
import { IUnit } from "./Units";

export interface ISystem {
  id: string;
  name: string;
  brand?: string;
  description?: string;
  type?: string;
  series?: string;
  capacity?: number;
  capacityMeasurementUnits?: number;
  line: number;
  device: string;
  units: string[];
  role: any;
  types: any;
  capacityMeasurement: any;
  brandNum?: number;
  powerMeters: any[];
}

export interface ISystemMap {
  [key: string]: ISystem;
}

export interface ISystemsModel {
  allSystems: ISystemMap;
  initialize: Action<ISystemsModel, any>;
  onInitialized: ActionOn<ISystemsModel, IRootStoreModel>;
  updateSystem: Thunk<ISystemsModel, { systemId: string; updatedData: any }>;
  systemTogglePower: Thunk<ISystemsModel, { id: string; data: any }>;
  assignPowerMeter: Thunk<
    ISystemsModel,
    { systemId: string; powerMeterId: string }
  >;
  unAssignPowerMeter: Thunk<
    ISystemsModel,
    { systemId: string; powerMeterId: string }
  >;
  addPowerMeter: Action<ISystemsModel, any>;
  deletePowerMeter: Action<ISystemsModel, any>;
  changeSystemOperationMode: Thunk<ISystemsModel, { id: string; data: any }>;
  _storeUpdateSystem: Action<
    ISystemsModel,
    { systemId: string; updatedSystemData: any }
  >;
  getSystemAlerts: Computed<
    ISystemsModel,
    (id: string, type: "all" | "unresolved") => IAlert[] | [],
    IRootStoreModel
  >;
  getSystemInfo: Computed<ISystemsModel, (id: string) => any, IRootStoreModel>;
  hasUnitsOn: Computed<ISystemsModel, (id: string) => boolean, IRootStoreModel>;
  getSystemMode: Computed<
    ISystemsModel,
    (id: string) => number,
    IRootStoreModel
  >;
  hasDisconnectedUnits: Computed<
    ISystemsModel,
    (id: string) => boolean,
    IRootStoreModel
  >;
  getSystemFirstUnitId: Computed<
    ISystemsModel,
    (id: string, unitType: number | undefined) => string | null,
    IRootStoreModel
  >;
  hasControlUnits: Computed<
    ISystemsModel,
    (id: string) => boolean,
    IRootStoreModel
  >;
  getSystemsBySite: Computed<
    ISystemsModel,
    (id?: string | null) => any[] | [],
    IRootStoreModel
  >;
  getSystem: Computed<
    ISystemsModel,
    (id?: string | null) => any,
    IRootStoreModel
  >;
  getSystemProData: Thunk<ISystemsModel, { systemId: string; data: any }>;
}

export const systemsModel: ISystemsModel = {
  allSystems: {},

  initialize: action((state, payload) => {
    state.allSystems = payload;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      // console.log(target.resolvedTargets);
      // console.log("Got systems: ", debug(state.allSystems));
    }
  ),

  updateSystem: thunk(async (actions, payload) => {
    const updatedSystemData = await SystemSdk.update(
      payload.systemId,
      payload.updatedData
    );

    actions._storeUpdateSystem({
      systemId: payload.systemId,
      updatedSystemData
    });
  }),
  getSystemsBySite: computed(
    [
      (state) => state.allSystems,
      (state, storeState) => storeState.devices.allDevices
    ],
    (allSystems, allDevices) =>
      memo((id) => {
        const siteDevices = Object.values(allDevices).filter(
          (device: any) => device.site === id
        );
        const systems: any[] = [];
        const devicesIds = siteDevices.map((device: any) => {
          if (device.serviceSystems.length) {
            device.serviceSystems.forEach((system: any) => {
              systems.push(allSystems[system]);
            });
          }
        });
        return systems;
      }, 100)
  ),
  assignPowerMeter: thunk(async (actions, payload) => {
    const updatedSystemData = await SystemSdk.assignPowerMeter(
      payload.systemId,
      payload.powerMeterId
    );

    actions.addPowerMeter({
      systemId: payload.systemId,
      powerMeterId: payload.powerMeterId
    });

    return updatedSystemData;
  }),

  unAssignPowerMeter: thunk(async (actions, payload) => {
    const updatedSystemData = await SystemSdk.unAssignPowerMeter(
      payload.systemId,
      payload.powerMeterId
    );

    actions.deletePowerMeter({
      systemId: payload.systemId,
      powerMeterId: payload.powerMeterId
    });

    return updatedSystemData;
  }),

  addPowerMeter: action((state, payload) => {
    if (
      state.allSystems[payload.systemId] &&
      !_.includes(
        state.allSystems[payload.systemId].powerMeters,
        payload.powerMeterId
      )
    ) {
      if (!state.allSystems[payload.systemId].powerMeters) {
        state.allSystems[payload.systemId].powerMeters = [];
      }
      state.allSystems[payload.systemId].powerMeters.push(payload.powerMeterId);
    }
  }),

  deletePowerMeter: action((state, payload) => {
    if (
      state.allSystems[payload.systemId] &&
      _.includes(
        state.allSystems[payload.systemId].powerMeters,
        payload.powerMeterId
      )
    ) {
      _.remove(state.allSystems[payload.systemId].powerMeters, function(
        powerMeterId
      ) {
        return powerMeterId === payload.powerMeterId;
      });
    }
  }),

  systemTogglePower: thunk(async (actions, payload) => {
    await SystemSdk.changeSystemPower(payload.id, payload.data);
  }),

  changeSystemOperationMode: thunk(async (actions, payload) => {
    await SystemSdk.changeSystemOperationMode(payload.id, payload.data);
  }),

  _storeUpdateSystem: action((state, payload) => {
    if (state.allSystems[payload.systemId]) {
      state.allSystems[payload.systemId] = payload.updatedSystemData;
    }
  }),

  getSystemAlerts: computed(
    [
      (state) => state.allSystems,
      (state, storeState) => storeState.units.allUnits,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.alerts.allAlerts,
      (state, storeState) => storeState.units.isItMe
    ],
    (systems, allUnits, allDevices, allSites, allAlerts, unitIsItMe) =>
      memo((id, type = "all") => {
        const system = systems[id];
        const systemDevice = allDevices[system.device];
        const systemSite = allSites[systemDevice.site];
        let systemAlerts: IAlert[] = [];
        Object.values(allAlerts).forEach((alert: IAlert) => {
          if (type === "unresolved" && !_.isUndefined(alert.clearTime)) { return; }
          if (systemSite.id === alert.site) {
            if (_.has(alert, "data.eventData.unitId")) {
              Object.values(allUnits).forEach((unit: IUnit) => {
                if (
                  alert.data.eventData.unitId &&
                  unitIsItMe(unit, alert.data.eventData.unitId) &&
                  unit.system === system.id
                ) {
                  // TODO: remove console.log
                  // console.log('alert1', systemSite.id === alert.site, alert);
                  systemAlerts.push(alert);
                }
              });
            } else if (
              alert.data.trigger.template.includes("DEVICE_DISCONNECT") &&
              systemDevice.serial === alert.data.eventData.deviceId
            ) {
              // TODO: remove console.log
              // console.log('alert2', systemSite.id === alert.site, alert);
              systemAlerts.push(alert);
            }
          }
        });
        return systemAlerts;
      }, 100)
  ),
  hasControlUnits: computed(
    [(state, storeState) => storeState.units.allUnits],
    (allUnits) => (id) => {
      let hasControlUnits = false;
      const serviceUnits = Object.values(allUnits).filter(
        (unit) => unit.system === id && unit.type === 3
      );
      const controlUnits = _.filter(serviceUnits, (unit: any) => {
        if (_.isNil(unit.controlUnit)) {
          return false;
        }
        return true;
      });
      if (controlUnits.length) {
        hasControlUnits = true;
      }

      return hasControlUnits;
    }
  ),
  getSystemInfo: computed(
    [
      (state) => state.allSystems,
      (state, storeState) => storeState.units.allUnits,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.customers.allCustomers
    ],
    (systems, allUnits, allDevices, allSites, allCustomers) =>
      memo((id) => {
        const system = systems[id];
        const systemDevice = allDevices[system.device];
        const systemSite = allSites[systemDevice.site];
        const systemCustomer = allCustomers[systemSite.customer];
        const units = Object.values(allUnits).filter((unit) => {
          if (unit.system === id && unit.type === 1) {
            return true;
          }
          return false;
        });
        return {
          system,
          device: systemDevice,
          site: systemSite,
          units,
          customer: systemCustomer
        };
      }, 100)
  ),

  hasUnitsOn: computed(
    [(state, storeState) => storeState.units.allUnits],
    (allUnits) => (id) => {
      let hasUnitsOn = false;

      const serviceUnits = Object.values(allUnits).filter(
        (unit) => unit.system === id && unit.type === 3
      );
      const controlUnitsIds = serviceUnits.map((unit: any) => unit.controlUnit);

      const units = Object.values(allUnits).filter((unit) => {
        if (
          _.includes(controlUnitsIds, unit.id) &&
          unit.activeOperationStatus === 1
        ) {
          return true;
        }
        return false;
      });
      if (units.length) {
        hasUnitsOn = true;
      }

      return hasUnitsOn;
    }
  ),

  getSystemMode: computed(
    [(state, storeState) => storeState.units.allUnits],
    (allUnits) => (id) => {
      let systemMode = -1; // not specified

      const serviceUnits = Object.values(allUnits).filter(
        (unit) => unit.system === id && unit.type === 3
      );
      const controlUnitsIds = serviceUnits.map((unit: any) => unit.controlUnit);
      const controlUnits = Object.values(allUnits).filter((unit) =>
        _.includes(controlUnitsIds, unit.id)
      );

      if (controlUnits.length > 0) {
        systemMode = controlUnits[0].activeOperationMode;
      } else {
        return -1;
      }

      for (let unit of controlUnits) {
        if (unit.activeOperationMode !== systemMode) {
          return -1;
        }
      }
      return systemMode;
    }
  ),

  hasDisconnectedUnits: computed(
    [(state, storeState) => storeState.units.allUnits],
    (allUnits) => (id) => {
      for (let unit of Object.values(allUnits)) {
        if (
          unit.system === id &&
          _.has(unit, "isConnected") &&
          !unit.isConnected
        ) {
          // console.log('Found disconnected unit:', unit);
          return true;
        }
      }

      return false;
    }
  ),

  getSystemFirstUnitId: computed(
    [(state, storeState) => storeState.units.allUnits],
    (allUnits) => (id, unitType) => {
      let systemFilteredUnits = Object.values(
        allUnits
      ).filter((unit: IUnit) => {
        // Filter out wrong typed units only if unit-type is specified.
        if (!_.isNil(unitType) && unitType !== unit.type) {
          return false;
        }

        // Filter in units with system assigned and it is the system requested.
        if (!_.isNil(unit.system) && unit.system === id) {
          return true;
        }

        // No match.
        return false;
      });

      // Return the first filtered unit's ID.
      if (systemFilteredUnits.length) {
        // Outdoor units are sorted by line ID.
        if (unitType === 2) {
          systemFilteredUnits = _.sortBy(
            systemFilteredUnits,
            (unit) => unit.line
          );
        }

        return systemFilteredUnits[0].id;
      }

      // No unit found.
      return null;
    }
  ),
  getSystemProData: thunk(async (actions, payload) => {
    const { systemId, data } = payload;
    return SystemSdk.getSystemProData(payload.systemId, {
      startTime: data.startTime,
      endTime: data.endTime
    });
  }),
  getSystem: computed(
    [
      (state) => state.allSystems,
      (state, storeState) => storeState.units.allUnits
    ],
    (allSystems, allUnits) =>
      memo((id) => {
        if (_.isNil(id)) {
          return undefined;
        }
        if (_.includes(id, "_")) {
          const unassignedUnits = Object.values(allUnits).filter(
            (unit: any) =>
              _.isNil(unit.system) &&
              unit.line.toString() === id.split("_")[0] &&
              unit.device === id.split("_")[1]
          );
          if (unassignedUnits.length) {
            const firstUnit = unassignedUnits[0];
            const system = {
              id,
              line: id.split("_")[0],
              name: `Line ${id.split("_")[0]} - unassigned units`,
              units: unassignedUnits.map((unit: any) => unit.id),
              device: firstUnit.device,
              site: firstUnit.site
            };

            return system;
          }
        }
        return allSystems[id];
      }, 100)
  )
};
