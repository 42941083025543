import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  processingAnimationContainer: {
    display: "flex",
    justifyContent: "center"
  },

  scanningLabel: {
    fontWeight: "bold",
    textAlign: "center"
  },

  infoTextLabel: {
    textAlign: "end"
  },

  infoTextValue: {
    fontSize: "14px",
    fontWeight: 500,
    marginRight: "1rem",
    color: "#7f7182"
  },

  imgContainer: {
    display: "flex",
    justifyContent: "center"
  },
  textFieldIndoorUnits: {
    width: "90px",
    "&>div": {
      fontWeight: "normal",
      color: "#7f7182"
    }
  },

  system: {
    display: "flex",
    flexDirection: "row",
    padding: "1rem"
  },
  infoView: {
    padding: "1rem"
  },
  hidden: {
    display: "none"
  },
  addNewSystem: {
    borderRadius: "4px",
    alignSelf: "flex-end",
    color: "linear-gradient(-60deg, rgb(66, 26, 64) 50%, rgb(41, 19, 46) 50%)",
    background: "white",
    fontFamily: "RobotoMedium",
    fontSize: "0.875rem",
    textAlign: "center"
  }
});
export default useStyles;
