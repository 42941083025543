import {
  createStyles,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { Field, FormikProps } from "formik";
import _ from "lodash";
import { Select } from "material-ui-formik-components/Select";
import React from "react";
import { t } from "ttag";
import Delete from "../../../components/Delete/Delete";
import { Arrow as SvgArrow } from "../../../icons/";
import { useStoreState } from "../../../models/RootStore";
import { IUnit } from "../../../models/Units";
import FixedInputField from "../FixedInputField";
import InputField from "../InputField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unitDisconnected: {
      background: "rgba(240, 81, 70, 0.2)"
    },
    iconDeleteUnit: {
      fontSize: "1rem",
      cursor: "pointer",
      color: "initial"
    },
    capacityItem: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& input": {
        fontSize: "14px"
      }
    },
    headCellType: {
      padding: "0",
      paddingLeft: "15px"
    },
    headCellSystem: {
      padding: "0"
    },
    headCell: {
      // backgroundColor: 'whitesmoke',
      // width: '40px'
    },
    arrowIcon: {
      top: 19.5,
      right: 10
    },
    removedUnit: {
      color: "red"
    },
    overrideMargin: {
      textAlign: "left",
      paddingRight: "25px !important",
      minWidth: "calc(100% - 39px)"
      },
    headCellAirnet: {
      padding: "0",
      "& div": {
        paddingLeft: "3px"
        // width: "5rem",
      }
    },
    headCellRole: {
      padding: "0",
      "& div": {
        fontSize: "14px",
        paddingLeft: "2px"
      }
    },
    wideCell: {
      // width: "15%",
      boxSizing: "border-box"
    },
    snModelWidth: {
      width: "18ch"
    },
    alignLeft: {
      textAlign: "left"
    },
    long30ch: {
      minWidth: "28ch"
    },
    padd15: {
      padding: "0 15px !important"
    },
    selectlongLength: {
      maxWidth: "26ch"
    }
  }));
const convertProIdToText = (proId: string) => {
  return proId[1] + proId[3] + proId[5];
};

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface IProps extends Omit<FormikProps<any>, "handleSubmit"> {
  touched: any;
  values: any;
  unit: any;
  index: any;
  deviceId: any;
  isDaikin: any;
  lineIndex: number;
  isRemoved: boolean;
  deleteUnitFromContainer: (lineIndex: number, unitIndex: any) => void;
  deviceSystems?: any;
  deviceControlUnits?: any;
}

const UnitTableRow: React.FC<IProps> = ({
  errors,
  touched,
  values,
  unit,
  index,
  deviceId,
  isDaikin,
  lineIndex,
  isRemoved,
  deleteUnitFromContainer,
  setFieldValue,
  deviceSystems,
  deviceControlUnits
}) => {
  const classes = useStyles();
  const allDeviceSystems = deviceSystems ?? useStoreState((state) => state.devices.getDeviceSystems(deviceId));
  const allDeviceControlUnits = deviceControlUnits ?? useStoreState((state) => state.devices.getDeviceUnits(deviceId, "all", "indoor"));

  const unitRoles: any = { Unknown: 0, Master: 1, Slave: 2 };

  const types = useStoreState((state) => state.types.allTypes);
  const { capacityMeasurementUnitTypes } = types;

  const deleteUnitFromContainerProxy = () => {
    deleteUnitFromContainer(lineIndex, index);
  };

  const unitConnected = (unit: IUnit) => {
    return !_.has(unit, "isConnected") || unit.isConnected;
  };

  let systems: any = [
    {
      value: 0,
      label: t`Not Assigned`
    }
  ];
  let controlUnits: any = [
    {
      value: 0,
      label: t`Not Assigned`
    }
  ];

  _.filter(allDeviceSystems, (system) => system.line === values.unitsChanges[lineIndex][index].line).map(
    (system, index) =>
      systems.push({
        value: system.id,
        label: system.name
      })
  );
  _.filter(allDeviceControlUnits, (controlUnit) => controlUnit.line === values.unitsChanges[lineIndex][index].line).map(
    (controlUnit, index) =>
      controlUnits.push({
        value: controlUnit.id,
        label: controlUnit.address
      })
  );

  return (
    <TableRow key={unit.id} className={unitConnected(unit) ? undefined : classes.unitDisconnected}>
      {isRemoved && (
        <TableCell align="center" style={{ padding: "0" }}>
          {!unit.isConnected && (
            <Delete
              type={t`unit`}
              object={unit}
              detach={deleteUnitFromContainerProxy}
              buttonClass={classes.iconDeleteUnit}
            />
          )}
        </TableCell>
      )}
      <TableCell align="center" style={{ padding: "0", paddingLeft: "15px" }} className={classes.wideCell}>
        <Field
          name={`unitsChanges[${lineIndex}][${index}].name`}
          component={FixedInputField}
          disabled={!unit.isConnected}
          classes={{ disabled: !unit.isConnected ? classes.removedUnit : "", root: clsx(classes.overrideMargin, classes.selectlongLength) }}

        // classes={{ disabled: !unit.isConnected ? classes.removedUnit : "", root: classes.long30ch }}
        />
      </TableCell>
      <TableCell align="center" style={{ padding: "0", paddingLeft: "15px" }} className={clsx(classes.wideCell, classes.snModelWidth)}>
        <Field
          name={`unitsChanges[${lineIndex}][${index}].serialNumber`}
          component={InputField}
          disabled={!unit.isConnected}
        />
      </TableCell>
      <TableCell align="center" style={{ padding: "0", paddingLeft: "15px" }} className={clsx(classes.wideCell, classes.snModelWidth)}>
        <Field
          name={`unitsChanges[${lineIndex}][${index}].model`}
          component={InputField}
          disabled={!unit.isConnected}
        />
      </TableCell>
      <TableCell align="center" className={classes.headCellType} style={{ width: "9ch" }}>
        {unit.type === 2 ? t`Outdoor` : t`Indoor`}
        {unitConnected(unit) ? "" : " disconnected"}
      </TableCell>
      <TableCell align="center" style={{ padding: "0", width: "16ch", paddingLeft: "15px" }} className={classes.headCellRole}>
        {unit.type === 2 ? (
          <Field
            label={""}
            name={`unitsChanges[${lineIndex}][${index}].task`}
            options={_.map(unitRoles, (value, key) => ({
              value,
              label: key
            }))}
            component={Select}
            variant="outlined"
            value={
              values.unitsChanges[lineIndex][index].task ?? unitRoles[Object.keys(unitRoles)[0]]
            }
            error={errors.task && touched.task ? true : false}
            helpertext={errors.role && touched.task ? errors.task : ""}
            classes={{ root: classes.alignLeft, icon: classes.arrowIcon }}
            IconComponent={SvgArrow}
            disabled={!unit.isConnected}
            margin="none"
            MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
          // style={{ maxHeight: "44px", height: "44px" }}
          />
        ) : (
            <></>
          )}
      </TableCell>
      <TableCell align="center" style={{ padding: "0", width: "32ch", maxWidth: "32ch", paddingLeft: "15px" }} className={classes.wideCell}>
        <div className={classes.capacityItem} style={{ maxHeight: "56px" }}>
          <div style={{ width: "100%", paddingRight: "15px", marginTop: "-15px" }}>
            <Field
              component={InputField}
              name={`unitsChanges[${lineIndex}][${index}].capacity`}
              variant="outlined"
              error={errors.capacity && touched.capacity ? true : false}
              helperText={errors.capacity && touched.capacity ? errors.capacity : ""}
              disabled={!unit.isConnected}
              margin="none"

            />
          </div>
          <Field
            label={""}
            name={`unitsChanges[${lineIndex}][${index}].capacityMeasurementUnits`}
            options={_.map(capacityMeasurementUnitTypes, (value, key) => ({
              value,
              label: key
            }))}
            component={Select}
            variant="outlined"
            value={
              values.unitsChanges[lineIndex][index].capacityMeasurementUnits ??
              capacityMeasurementUnitTypes[Object.keys(capacityMeasurementUnitTypes)[0]]
            }
            error={
              errors.capacityMeasurementUnits && touched.capacityMeasurementUnits ? true : false
            }
            helpertext={
              errors.capacityMeasurementUnits && touched.capacityMeasurementUnits
                ? errors.capacityMeasurementUnits
                : ""
            }
            classes={{ root: classes.alignLeft, icon: classes.arrowIcon }}
            IconComponent={SvgArrow}
            disabled={!unit.isConnected}
            margin="none"
            MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
          // style={{ maxHeight: "44px", height: "44px" }}
          />
        </div>
      </TableCell>
      <TableCell classes={{ root: classes.padd15 }} align="left" className={clsx(classes.headCellSystem, classes.wideCell)} style={{ width: "30ch", maxWidth: "27ch" }}>
        {unit.type === 1 ? unit.address : ""}
      </TableCell>
      <TableCell classes={{ root: classes.padd15 }} align="center" className={clsx(classes.headCellSystem, classes.wideCell)} style={{ width: "30ch", maxWidth: "27ch" }}>
        <Field
          label={""}
          name={`unitsChanges[${lineIndex}][${index}].system`}
          options={systems}
          component={Select}
          variant="outlined"
          value={values.unitsChanges[lineIndex][index].system ?? systems[0].value}
          error={errors.systems && touched.systems ? true : false}
          helpertext={errors.systems && touched.systems ? errors.systems : ""}
          classes={{ root: clsx(classes.overrideMargin, classes.selectlongLength), icon: classes.arrowIcon }}
          IconComponent={SvgArrow}
          disabled={!unit.isConnected}
          // style={{ height: "44px", maxHeight: "44px" }}
          margin="none"
          MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        ></Field>
      </TableCell>
    </TableRow>);

};

export default UnitTableRow;
