import { Services as ServicesSdk, Trap as TrapSdk } from "coolremote-sdk";
import { thunk, Thunk } from "easy-peasy";
import { useStoreState } from "./RootStore";
export interface ITrapsModel {
  getTraps: Thunk<ITrapsModel, { type: any }>;
  addTrap: Thunk<ITrapsModel, { data: any; customerId: string }>;
  updateTrap: Thunk<ITrapsModel, { data: any; trapId: string }>;
  deleteTrap: Thunk<ITrapsModel, { id: string }>;
  assignToTraps: Thunk<ITrapsModel, { id: string; data: any }>;
  getServiceParams: Thunk<ITrapsModel, { brand?: number | string; unitType: number }>; //assignToTraps
}

export const trapsModel: ITrapsModel = {
  getTraps: thunk((actions, payload) => {
    return TrapSdk.getTraps(payload.type);
  }),
  addTrap: thunk((actions, payload) => {
    return TrapSdk.createTrap(payload.data, payload.customerId);
  }),
  updateTrap: thunk((actions, payload) => {
    return TrapSdk.update(payload.data, payload.trapId);
  }),
  deleteTrap: thunk((actions, payload) => {
    return TrapSdk.delete(payload.id);
  }),
  assignToTraps: thunk((actions, payload) => {
    return TrapSdk.assignToTraps(payload.id, payload.data);
  }),
  getServiceParams: thunk((actions, payload) => {
    return ServicesSdk.getServiceParams(payload.brand, payload.unitType);
  })
};
