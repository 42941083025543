import { Card, Divider, makeStyles, Typography } from "@material-ui/core";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import clsx from "clsx";
import _ from "lodash";
import React, { Fragment } from "react";
import { t } from "ttag";
import alertSeverities from "../../constants/alertSeverities";
import { useStoreState } from "../../models/RootStore";

const useStyles = makeStyles((theme) => ({
  alerts: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    minHeight: "145px",
    padding: "20px 15px 0px 15px",
    borderBottom: "solid 1px #e5e2e5"
  },

  alertsTitle: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "0.5rem 0",
    fontSize: "33px",
    color: "#fff"
  },

  alertIcon: {
    paddingRight: "0.5rem"
  },

  alertsHeaderTitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46"
  },

  alertsStatistics: {
    display: "flex",
    marginTop: "25px",
    overflowX: "auto",
    overflowY: "hidden",
    width: "100%",
    justifyContent: "space-between"
  },

  singleAlertStatisticsBlock: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "solid 1px #dfdde1",
    flex: 1,
    "&:last-child": {
      border: "none"
    }
  },
  iconStyle: {
    color: "#aaa2aa",
    fontSize: "12px"
  },
  hideIcon: {
    visibility: "hidden"
  },
  singleAlertStatisticsText: {
    fontSize: "11px",
    fontFamily: "Roboto",
    fontWeight: 600,
    color: "#29132e",
    paddingRight: "3px",
    whiteSpace: "nowrap",
    lineHeight: "normal",
    letterSpacing: "normal",
    paddingTop: "10px"
  },

  singleAlertStatisticsCount: {
    fontSize: "36px",
    fontFamily: "RobotoLight",
    paddingRight: "2px"
  },
  selectedStyle: {
    backgroundColor: "#e9e7e9"
  }
}));

const AlertsBand = (props: any) => {
  const { setAlertFilterType, filterType } = props;
  const classes = useStyles();
  const getAlertsByFilter = useStoreState((state) => state.alerts.getAlertsByFilter);
  const selections = useStoreState((state) => state.selections.mobileSelections);

  // TODO: Move someplace else.
  let countSystemError = 0;
  let countIndoorError = 0;
  let countMaintenance = 0;
  let countNotConnected = 0;
  let countAnomalies = 0;

  const { customerId, siteId } = selections;
  getAlertsByFilter({ customerId, siteId }).forEach(
    (alert: any) => {
      const { severity: { id } } = alert;

      if (id === "SYSTEM_ERROR") {
        ++countSystemError;
      } else if (id === "INDOOR_ERROR") {
        ++countIndoorError;
      } else if (id === "MAINTENANCE") {
        ++countMaintenance;
      } else if (id === "NOT_CONNECTED") {
        ++countNotConnected;
      } else if (id === "ANOMALIES") {
        ++countAnomalies;
      }
    }
  );

  const alertMapping: any = {
    systemErrors: {
      id: "SYSTEM_ERROR",
      text: alertSeverities.SYSTEM_ERROR.name,
      color: alertSeverities.SYSTEM_ERROR.color,
      count: countSystemError
    },
    indoorErrors: {
      id: "INDOOR_ERROR",
      text: alertSeverities.INDOOR_ERROR.name,
      color: alertSeverities.INDOOR_ERROR.color,
      count: countIndoorError
    },
    notConnected: {
      id: "NOT_CONNECTED",
      text: alertSeverities.NOT_CONNECTED.name,
      color: alertSeverities.NOT_CONNECTED.color,
      count: countNotConnected
    },
    anomalies: {
      id: "ANOMALIES",
      text: alertSeverities.ANOMALIES.name,
      color: alertSeverities.ANOMALIES.color,
      count: countAnomalies
    },
    maintenance: {
      id: "MAINTENANCE",
      text: alertSeverities.MAINTENANCE.name,
      color: alertSeverities.MAINTENANCE.color,
      count: countMaintenance
    }
  };

  return (
    <Card elevation={0} className={classes.alerts}>
        <Typography className={classes.alertsHeaderTitle}>{t`OPEN ALERTS`}</Typography>
      <div className={classes.alertsStatistics}>
        {_.map(alertMapping, (alertType, typeKey) => {
          const isSelected = filterType === alertType.id;
          return (
            <Fragment key={typeKey}>
              <div className={clsx(classes.singleAlertStatisticsBlock)}
                // onClick={() => setAlertFilterType(isSelected ? null : alertType.id)}
              >
                <span
                  className={classes.singleAlertStatisticsCount}
                  style={{ color: alertType.color }}
                >
                  {alertType.count < 10 ? "0" + alertType.count : alertType.count}
                </span>
                <span className={classes.singleAlertStatisticsText}>{alertType.text.split(" ")[0]}</span>
              </div>
            </Fragment>
          );
        })}
      </div>
    </Card>
  );
};

export default AlertsBand;
