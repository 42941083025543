
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import Button from "../../cool_widgets/Button";
import { Disconnect, MoreVert } from "../../icons";
import styles from "./AddRule.style";
import Condition from "./Condition";
import { GButton } from "./CustomedComponents";

export default (props: any) => {
  const {
    errors,
    touched,
    groupIndex,
    noSingleConditions,
    groupsLastIndex,
    unGroup,
    name,
    onSelect,
    deleteCondition,
    conditions,
    relation,
    indexKey,
    setFieldValue,
    disabled,
    allParamsMap,
    serviceParamTypes,
    enumParams
  } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <>
      <div className={classes.groupContainer}>
        <div className={classes.groupContent}>
          <div className={classes.groupLeftPart}>
            <MoreVert className={classes.whiteMoreVert} /></div>
          <div className={classes.ingroupConditionsContainer}>
            {conditions.map((condition: any, index: any) =>
              <Condition
                enumParams={enumParams}
                serviceParamTypes={serviceParamTypes}
                errors={errors}
                touched={touched}
                disabled={disabled}
                inGroup
                key={`${name}.${index}`}
                index={index}
                condition={condition}
                deleteCondition={() => deleteCondition(index)}
                setFieldValue={setFieldValue}
                onSelect={onSelect}
                name={name}
                lastCondition={index !== conditions.length - 1}
                allParamsMap={allParamsMap}
              />)}
          </div>
          <div onClick={unGroup} className={classes.dissconnnectRect}>
            <Disconnect className={classes.disconnectIcon} />
          </div>
        </div>
      </div>
      {noSingleConditions && groupsLastIndex === groupIndex ? null : groupsLastIndex === groupIndex ? <Button disabled className={classes.addButton} >And</Button> :
        <GButton selected={relation} onClick={(text: any) => setFieldValue(`groups[${indexKey}].relation`, text)} />
      }
      <input
        style={{ visibility: "hidden" }}
        type="radio"
        name={`groups[${indexKey}].relation`}
        value="and"
        checked={relation === "and"}
      />
      <input
        style={{ visibility: "hidden" }}
        type="radio"
        name={`groups[${indexKey}].relation`}
        value="or"
        checked={relation === "or"}
      />
    </>
  );
};
