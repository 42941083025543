import { fade, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  view: {
    display: "flex",
    height: "100%"
  },
  contentArea: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    background: "#efeff2"
  },
  controlContainer: {
    flexFlow: "column nowrap",
    height: "100%",
    // maxHeight: "653px",
    width: "540px",
    borderLeft: "solid 2px #57455c",
    borderRadius: 4,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    display: "flex",
    minWidth: "432px",
    flex: 0.6
  },
  inputRoot: {
    color: "white",
    fontFamily: "sans-serif",
    fontSize: "14px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "45px",
    borderRadius: "6px",
    backgroundColor: "rgba(253, 253, 254, 0.1)",
    padding: "0 15px"
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px"
    }
  },
  powerOffButton: {
    minWidth: "120px",
    height: "45px",
    borderRadius: "6px",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#f05347",
    "&:hover": {
      backgroundColor: fade("#f05347", 0.7)
    },
    "&:focus": {
      backgroundColor: "#b3b3b3"
    },
    "&:active": {
      backgroundColor: "#f05347"
    },
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    marginLeft: "30px"
  },
  powerIconStyle: {
    "& path": { stroke: "#fdfdfe" },
    fontSize: "22px !important",
    marginRight: "2px"
  },
  itemsWrapper: {
    paddingLeft: "20px",
    paddingRight: "11px",
    width: "calc(100% - 9px)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(255,255,255,0.26)"
    }
  },
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%"
  },
  arrowIcon: { fontSize: "91px", transform: "rotate(90deg)" },
  noUnitText: {
    fontSize: "26px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#545964",
    marginTop: "43px"
  },
  noContentContainer: {
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    height: "100%",
    alignSelf: "center"
  },
  pageContentBox: {
    maxHeight: "100%",
    overflow: "auto",
    maxWidth: "calc(100vw - 240px)",
    display: "flex",
    padding: "16px",
    height: "100%"
  },
  cardsHolder: {
    overflow: "auto",
    backgroundImage:
      "linear-gradient(to right, rgb(21, 17, 31) 0%, rgb(70, 28, 66))",
    display: "flex",
    flexFlow: "row nowrap",
    width: "100%",
    height: "100%"
  },
  leftSideContainer: {
    minWidth: "500px",
    borderRadius: 4,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    display: "flex",
    flex: 0.4,
    flexFlow: "column nowrap"
  },
  searchBar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px"
  }
}));

export default useStyles;
