import { CircularProgress, Grid } from "@material-ui/core/";
import clsx from "clsx";
import {
  SubscriptionPlan as SdkSubscribe
} from "coolremote-sdk";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { t } from "ttag";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import ModalWithSteps from "../../components/ModalWithSteps/ModalWithSteps";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ISite } from "../../models/Sites";
import { IUnit } from "../../models/Units";
import { validatorForm } from "../../services/formService";
import ManuallySetSystems from "./Device/ManuallySetSystems";
import QueryDeviceStatus from "./Device/QueryDeviceStatus";
import RegisterDevice from "./Device/RegisterDevice";
import Site from "./Site";
import useStyles from "./siteManagement.style";
import SiteView from "./SiteView";

const formValidatorDevice = validatorForm(
  {
    site: ["required"],
    serial: ["required", "string"],
    pin: ["required", "string"]
  },
  undefined,
  {
    site: t`Site`,
    serial: t`Device SN MAC`,
    pin: t`Device PIN`
  }
);

const SiteManagement: React.FC<RouteComponentProps<any> & any> = (
  props: any
) => {
  const classes = useStyles();
  const allDevices = useStoreState((state) => state.devices.allDevices);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const types = useStoreState((state) => state.types.allTypes);
  const unassignUnitFromSystem = useStoreActions(
    (action) => action.unassignUnitFromSystem
  );
  const siteId = useStoreState((state) => state.selections.selections.siteId);
  const allSites = useStoreState((state) => state.sites.allSites);
  const currentSites = useStoreState(
    (state) => state.selections.getSitesBySelection
  );
  const addNewDeviceToSite = useStoreActions((action) => action.createDevice);
  const createSite = useStoreActions((action) => action.createSite);
  const getUnits = useStoreActions((action) => action.units.getUnits);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onStep, setOnStep] = useState(0);
  const [newDeviceId, setNewDeviceId] = useState("");
  const [newDeviceDbId, setNewDeviceDbId] = useState("");
  const [newDevicePin, setNewDevicePin] = useState("");
  const [selectedSite, setSelectedSite] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isExecutingAddDevice, setIsExecutingAddDevice] = useState(false);
  const [sitesToRender, setSitesToRender] = useState(currentSites);
  const [isSynchronized, setIsSynchronized] = useState(false);

  const { siteStatuses } = types;
  const siteWarnedStatus = siteStatuses["warned"];

  useEffect(() => {
    (async function() {
      props.unitUpdateStatusUpdate("all");
      await getUnits();
      setIsSynchronized(true);
    })();
  }, []);

  useEffect(() => {
    (async function() {
      if (siteId) {
        const temp = Object.values(allSites).filter(
          (site) => site.id === siteId
        );
        setSitesToRender(temp);
      } else {
        setSitesToRender(currentSites);
      }
    })();
  }, [siteId, currentSites, allSites]);

  const errorBox = error ? (
    <ErrorBox error={error} onClose={() => setError(null)} />
  ) : null;
  const handleSave = async (site: any) => {
    const { subscriptions } = site;

    const data = {
      name: site.name,
      country: site.country,
      city: site.city,
      state: site.state,
      address: site.address,
      postalCode: site.postalCode,
      timezone: site.timezone
    };
    await createSite({
      customerId: site.customer,
      data
    });

  };

  const onSave = async (deviceId: string) => {
    // If User didn't chose a site - will use first site as default to save too
    // const selectedSite = rootStore.selections.site ? rootStore.selections.site: this.getAllSites()[0]
    // const device = allDevices[deviceId];
    const deviceUnits = Object.values(allUnits).filter(
      (unit) => unit.device === deviceId
    );
    await Promise.all(
      _.map(deviceUnits, async (unit: IUnit) => {
        if (!unit.system) {
          return Promise.resolve();
        }

        const system = allSystems[unit.system];
        const device = allDevices[system.device];
        const allDeviceSystems = device.systems;
        // Meaning this unit was added to this system
        if (!_.includes(system.units, unit.id)) {
          await Promise.all(
            _.map(allDeviceSystems, async (oldSystemId) => {
              const oldSystem = allSystems[oldSystemId];
              if (
                _.includes(oldSystem.units, unit.id) &&
                unit.system !== oldSystem.id
              ) {
                await unassignUnitFromSystem({
                  systemId: oldSystem.id,
                  unitId: unit.id
                });
                return Promise.resolve();
              }
              return Promise.resolve();
            })
          );
        }
        // don't update units that were part of the system
        if (allUnits[unit.id] && unit.system === system.id) {
          return Promise.resolve();
        }
        // Update systems with new unit details
        // system.onlySetUnit(unit.id, unit);
        // await system.onlyUpdateUnit(unit.id);
        return Promise.resolve();
      })
    );
    onCancel();
  };

  //The right next action in the ModalWithSteps
  const next = () => {
    if (onStep === 0) {
      // Validate!
      const validate = formValidatorDevice({
        site: selectedSite,
        serial: newDeviceId,
        pin: newDevicePin
      });
      if (!validate.check()) {
        const errors = validate.errors.all();
        const allErrors = _(errors).values().flatten().value();
        allErrors[0] && setError(allErrors[0]);
        return;
      }

      setIsExecutingAddDevice(true);
      const selectedSiteId =
        selectedSite && selectedSite.id ? selectedSite.id : null;
      if (selectedSiteId) {
        addNewDeviceToSite({
          siteId: selectedSiteId,
          newDevicePin,
          newDeviceId
        })
          .then((data: any) => {
            setNewDeviceDbId(data.id);
            setOnStep(1);
            setIsNextDisabled(true);
            setIsExecutingAddDevice(false);
          })
          .catch((e: any) => {
            setError(e.message);
            setIsExecutingAddDevice(false);
            // console.log("something went wrong", e);
          });
      }
    }
    if (onStep === 1) {
      setOnStep(2);
    }
    if (onStep === 2) {
      onCancel();
    }
  };

  const onCancel = () => {
    setIsModalOpen(false);
    setNewDeviceId("");
    setNewDeviceDbId("");
    setNewDevicePin("");
    setSelectedSite(undefined);
    setOnStep(0);
  };

  const _setSelectedSite = (site: ISite | null) => {
    setSelectedSite(site);
  };

  const openAddDevice = (id: string) => {
    const site = allSites[id];
    setIsModalOpen(true);
    setSelectedSite(site);
  };

  return (
    // TODO: Implement.
    /*
    if (!_.has(rootStore.features, 'canViewManagementApp') || !_.get(rootStore.features, 'canViewManagementApp')) {
      return (
        <div>
          <Redirect to="/no-access" />
        </div>
      );
    }
    */
    // TODO: Move someplace else.

    <div className={classes.view}>
      {errorBox}
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={["Site Management"]}
          hideSystemSelection
          hideUnitSelection
          btnElement={
            isSynchronized ? <Site save={handleSave}></Site> : ""
          }
        />

        {isSynchronized ? (
          <Fragment>
            <Grid container className={classes.sitesContainer}>
              {sitesToRender.map((site: ISite, i: number) => {

                return (
                  <Grid
                    item
                    xs={12}
                    className={classes.siteViewContainer}
                    key={site.id}
                  >
                    <SiteView
                      site={site}
                      key={site.id}
                      openAddDevice={openAddDevice}
                    ></SiteView>
                  </Grid>
                );
              })}
              <div />
            </Grid>
          </Fragment>
        ) : (
            <Grid className={classes.progress}>
              <CircularProgress />
            </Grid>
          )}

        <ModalWithSteps
          closeModal={onCancel}
          showModal={isModalOpen}
          site={selectedSite}
          onStep={onStep}
          next={next}
          isNextDisabled={isNextDisabled}
        >
          <RegisterDevice
            className="registerDevice"
            site={selectedSite}
            sites={currentSites}
            next={() => next}
            setDeviceId={(id: string) => {
              setNewDeviceId(id);
            }}
            deviceId={newDeviceId}
            setDevicePin={(pin: string) => {
              setNewDevicePin(pin);
            }}
            devicePin={newDevicePin}
            selectSite={_setSelectedSite}
            isLoading={isExecutingAddDevice}
            setIsDisabled={(isDisabled: boolean) =>
              setIsNextDisabled(isDisabled)
            }
          />

          <QueryDeviceStatus
            site={selectedSite}
            deviceId={newDeviceDbId}
            setStep={() => setOnStep(2)}
            setIsDisabled={(isDisabled: boolean) =>
              setIsNextDisabled(isDisabled)
            }
          />

          <ManuallySetSystems
            mode={0}
            closeModal={onCancel}
            site={selectedSite}
            deviceId={newDeviceDbId}
          />
        </ModalWithSteps>
      </div>
    </div>
  );
};
export default SiteManagement;
