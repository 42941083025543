import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { t } from 'ttag';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useStyles from './QueryDeviceStatus.style';
import assetsService from '../../../services/assetsService';
import { TextField } from '@material-ui/core';
import DeviceSystems from './DeviceSystems';
import clsx from 'clsx';
import { IDevice } from '../../../models/Devices';
import { ISite } from '../../../models/Sites';
import { useStoreActions, useStoreState } from '../../../models/RootStore';

interface IProps {
  site: ISite;
  deviceId: string;
  setStep: any;
  setIsDisabled: any;
}

export default (function QueryDeviceStatus(props: IProps) {
  const classes = useStyles();
  const refreshDeviceTree = useStoreActions(actions => actions.refreshDeviceTree);
  const allDevices = useStoreState(state => state.devices.allDevices);
  const indoorCount = useStoreState(
    state => state.devices.getDeviceUnits(props.deviceId, 'all', 'service').length
  );
  const refreshSystems = useStoreActions(a => a.devices.refreshSystems);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    (async function () {
      const _device: IDevice | undefined = allDevices[props.deviceId];

      if (_.isUndefined(_device)) {
        return;
      }
      await refreshSystems({ deviceId: _device.id });
      await refreshDeviceTree({ id: _device.id });
      setIsDisabled(false);
      props.setIsDisabled(false);
    })();
  }, []);

  const device = allDevices[props.deviceId];

  return (
    <Grid container spacing={3} direction="row">
      <Grid item xs={5}>
        <div className={classes.imgContainer}>
          <img src={assetsService.getImagesPath('cmnet.png')}></img>
        </div>
      </Grid>
      <Grid item xs={7}>
        <Card className={classes.infoView}>
          <div
            className={clsx(
              classes.processingAnimationContainer,
              !isDisabled ? classes.hidden : ''
            )}
          >
            <CircularProgress size={40} />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.infoTextValue}>{t`Indoor Units`}</Typography>
              <TextField
                disabled
                id="outlined-disabled"
                defaultValue={indoorCount}
                className={classes.textFieldIndoorUnits}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <DeviceSystems device={device} />
        </Card>
      </Grid>
    </Grid>
  );
});
