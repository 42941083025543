import { IconButton } from "@material-ui/core";
import { format, getHours, getMinutes } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Export from "../../icons/Export";
import {useStoreState} from "../../models/RootStore";
import useStyle from "./OneTenant.style";

export default function ExportExcel(props: any) {
    const classes = useStyle();
    const sites = useStoreState((s) => s.sites.allSites);
    const tenants = !props.zones || !props.zones.length
        ? {}
        : _.reduce(props.zones, (obj: any, item: any) => {
        obj[item.id] = item;
        return obj;
    }, {});
    const units = useStoreState((s) => s.units.allUnits);
    const systems = useStoreState((s) => s.systems.allSystems);
    const milliSecondsInHour = 60 * 60 * 1000;

    // insert data - array of arrays
    // Each array is a row
    // the array inside is the cells:
    const createfileData = () => {
        const line0 = [""];
        const line1 = ["Period", "start", "end"];
        const timeLine2: any = [""];
        const siteNameLine3 = ["site"];
        const systemNameLine4 = ["system"];
        const addressLine4 = ["Site address"];
        const line5 = [""];
        const line6 = ["Distribution report"];
        const line7 = ["", "", "Cool", "", "Heat", "", "Other",  "", "total"];
        const line8 = ["Tenant Name",	"Unit Name"	, "kWh", "Hours", "kWh", "Hours", "kWh", "Hours", "kWh", "Hours"];
        const tenantChunkLine9: any = [];
        const naUnitsChunkLine10: any = [];
        let tenantAndUnitsChunkSum: any = [];
        const breakLine: any = [];
        const line11 = ["Time breakdown"];
        const unitNamesLine12 = ["", ""];
        const line13 = ["Date & Time", ""];
        const byTimeChunkLine14: any = [];

        const coolValuesSum: number[] = [0, 0];
        const heatValuesSum: number[] = [0, 0];
        const otherValuesSum: number[] = [0, 0];
        const totalValuesSum: number[] = [0, 0];
        const totalByTime: any = _.map(new Array(props.numberOfTimeUnits.length), (item) => [0, 0]);

        // Set time
        const startTime = props.selections.dateRange.startDate;
        const endTime = props.selections.dateRange.endDate;
        timeLine2.push(`${format(startTime, "MM/dd/yyyy")} ${getHours(startTime).toString().padStart(2, "0")}: ${getMinutes(startTime).toString().padStart(2, "0")}`);
        timeLine2.push(`${format(endTime, "MM/dd/yyyy")} ${getHours(endTime).toString().padStart(2, "0")}: ${getMinutes(endTime).toString().padStart(2, "0")}`);

        // Set site info
        const site = sites[props.selections.siteId];
        siteNameLine3.push(site.name);
        addressLine4.push(`${site.address}, ${site.city}, ${site.country}`);

        // Set system info
        const systemName = systems[props.selections.systemId] ? systems[props.selections.systemId].name || "" : "";
        if (systemName){
        systemNameLine4.push(systemName);
        } else{
            // Remove line from excel if "all systems"
            systemNameLine4.pop();
        }

        // Set tenant data
        _.forEach(props.reports.zones, (tenant, tenantId) => {
            // Don't show tenants not is selected system
            if (!_.includes(props.filteredTenants, tenantId) && props.chosenSystem !== "allSystems"){
                return;
            }

            if (_.isEmpty(tenant.units)){
                return;
            }

            _.forEach(tenant.units, (unit, unitId) => {
                const row: any = [];
                if (unit.message === "unit not in associated systems"){
                    console.log(`TENANT unit ${unitId} ${unit.message}`);
                    return;
                }

                row.push(tenants[tenantId].name);
                row.push(units[unitId].name);
                row.push(unit.coolPowerUsed || 0);
                row.push(Math.round((unit.coolModeTime / milliSecondsInHour) * 10) / 10 || 0);

                row.push(unit.heatPowerUsed || 0);
                row.push(Math.round((unit.heatModeTime / milliSecondsInHour) * 10) / 10 || 0);

                row.push(unit.otherPowerUsed || 0);
                row.push(Math.round((unit.otherModeTime / milliSecondsInHour) * 10) / 10 || 0);

                row.push(unit.totalPowerUsed || 0);
                row.push(Math.round((unit.onTime / milliSecondsInHour) * 10) / 10 || 0);
                tenantChunkLine9.push(row);

                // calculate the total
                coolValuesSum[0] += unit.coolPowerUsed || 0;
                coolValuesSum[1] += Math.round((unit.coolModeTime / milliSecondsInHour) * 10) / 10 || 0;

                heatValuesSum[0] += unit.heatPowerUsed || 0;
                heatValuesSum[1] += Math.round((unit.heatModeTime / milliSecondsInHour) * 10) / 10 || 0;

                otherValuesSum[0] += unit.otherPowerUsed || 0;
                otherValuesSum[1] += Math.round((unit.otherModeTime / milliSecondsInHour) * 10) / 10 || 0;

                totalValuesSum[0] += unit.totalPowerUsed || 0;
                totalValuesSum[1] += Math.round((unit.onTime / milliSecondsInHour) * 10) / 10 || 0;

            });

        });

        // set unassigned units data
        _.forEach(props.reports.units, (systemOfUnits, systemId) => {
            // don't show unassigned units of systems that were not chosen
            if (props.selections.systemId !== systemId && props.chosenSystem !== "allSystems"){
                return;
            }

            const system = systems[systemId];
            let systemByHourArr = _.map(new Array(8), (item) => 0);
            // const systemMeta = ["Not Assigned", `total Un-mapped Units of ${system.name}`];

            // Calculate the sum of each unit in system per mode
            _.forEach(systemOfUnits, (unit, unitId) => {
                const row: any = [];
                const unitName = units[unitId] ? units[unitId].name : "unknown unit name";
                const systemMeta = [`Not Assigned`, `${unitName}(${system.name})`];

                row.push((unit.coolPowerUsed || 0)) ;
                row.push((Math.round((unit.coolModeTime / milliSecondsInHour) * 10) / 10 || 0)) ;

                row.push((unit.heatPowerUsed || 0)) ;
                row.push((Math.round((unit.heatModeTime / milliSecondsInHour) * 10) / 10 || 0)) ;

                row.push((unit.otherPowerUsed || 0)) ;
                row.push((Math.round((unit.otherModeTime / milliSecondsInHour) * 10) / 10 || 0)) ;

                row.push((unit.totalPowerUsed || 0)) ;
                row.push((Math.round((unit.onTime / milliSecondsInHour) * 10) / 10 || 0)) ;

                naUnitsChunkLine10.push([...systemMeta, ...row]);

                // calculate the total
                coolValuesSum[0] += unit.coolPowerUsed || 0;
                coolValuesSum[1] += Math.round((unit.coolModeTime / milliSecondsInHour) * 10) / 10 || 0;

                heatValuesSum[0] += unit.heatPowerUsed || 0;
                heatValuesSum[1] += Math.round((unit.heatModeTime / milliSecondsInHour) * 10) / 10 || 0;

                otherValuesSum[0] += unit.otherPowerUsed || 0;
                otherValuesSum[1] += Math.round((unit.otherModeTime / milliSecondsInHour) * 10) / 10 || 0;

                totalValuesSum[0] += unit.totalPowerUsed || 0;
                totalValuesSum[1] += Math.round((unit.onTime / milliSecondsInHour) * 10) / 10 || 0;

            });

            // naUnitsChunkLine10.push([...systemMeta, ...systemByHourArr]);
        });

        tenantAndUnitsChunkSum = [
            "All", "Total",
            coolValuesSum[0], coolValuesSum[1],
            heatValuesSum[0], heatValuesSum[1],
            otherValuesSum[0], otherValuesSum[1],
            totalValuesSum[0], totalValuesSum[1]
        ];

        // breakdown by time
        const allUnitsDataByTime: any = [];
        _.forEach(props.reports.zones, (tenant, tenantId) => {
            // If there's a tenant without units - do nothing
            if (_.isEmpty(tenant.units)){
                return;
            }

            // Don't show tenants not is selected system
            if (!_.includes(props.filteredTenants, tenantId) && props.chosenSystem !== "allSystems"){
                return;
            }

            _.forEach(tenant.units, (unit, unitId) => {

                // prevent fail on missing unit or missing buckets of a unit
                if (!unit.buckets || !unit.buckets.length){
                    console.log(`unit${unitId} has no buckets: ${unit}`);
                    return;
                }
                let unitDataByTime: any = [];
                const unitName = units[unitId] ? units[unitId].name : "unknown";
                unitNamesLine12.push(unitName, "");
                line13.push("kWh", "Hours");

                // sum all units power usage per timeUnit
                _.forEach(unit.buckets, (chunk: any, i: number) => {
                    let totalPower = 0;
                    let totalTime = 0;

                    totalPower += chunk.heatPowerUsed || 0;
                    totalPower += chunk.coolPowerUsed || 0;
                    totalPower += chunk.otherPowerUsed || 0;
                    totalTime += (Math.round((chunk.unitOnTime / milliSecondsInHour) * 10) / 10 || 0);

                    // Calculate total of each time unit
                    totalByTime[i][0] += totalPower;
                    totalByTime[i][1] += totalTime;

                    unitDataByTime.push([totalPower, totalTime]);
                });

                allUnitsDataByTime.push(_.cloneDeep(unitDataByTime));
                unitDataByTime = [];
            });
        });

        // breakdown unassigned units by time
        const allUnassignedUnitsDataByTime: any = [];
        _.forEach(props.reports.units, (units, systemId) => {

            // don't show unassigned units of systems that were not chosen
            if (props.selections.systemId !== systemId && props.chosenSystem !== "allSystems"){
                return;
            }

            unitNamesLine12.push(`total U-mapped Units ${systems[systemId].name}`, "");
            line13.push("kWh", "Hours");

            // For each system create a new array
            let systemByHourArr = new Array(props.numberOfTimeUnits.length);
            _.forEach(units, (unit) => {

                    _.forEach(unit.buckets, (chunk: any, i: number) => {
                        if (!systemByHourArr[i]){
                            systemByHourArr[i] = [0, 0];
                        }

                        systemByHourArr[i][0] += chunk.heatPowerUsed || 0;
                        systemByHourArr[i][0] += chunk.coolPowerUsed || 0;
                        systemByHourArr[i][0] += chunk.otherPowerUsed || 0;
                        systemByHourArr[i][1] += (Math.round((chunk.unitOnTime / milliSecondsInHour) * 10) / 10 || 0);

                        // Calculate total of each time unit
                        totalByTime[i][0] += chunk.heatPowerUsed || 0;
                        totalByTime[i][0] += chunk.coolPowerUsed || 0;
                        totalByTime[i][0] += chunk.otherPowerUsed || 0;
                        totalByTime[i][1] += (Math.round((chunk.unitOnTime / milliSecondsInHour) * 10) / 10 || 0);

                    });
            });

            allUnassignedUnitsDataByTime.push(systemByHourArr);
            systemByHourArr = new Array(props.numberOfTimeUnits.length);
            });

        // Push the last title of breakdown by time
        unitNamesLine12.push("total");
        line13.push("kWh", "Hours");

        // Build data by time into rows
        let rows: any = [];
        for (let i = 0; i < props.numberOfTimeUnits.length ; i++){
            rows.push(props.numberOfTimeUnits[i], "");

            // Push units data
            _.forEach(allUnitsDataByTime, (oneUnit) => {
                // Prevent fail in case the units data is empty
                if (oneUnit.length){
                rows.push(...oneUnit[i]);
                }
            });

            // push unassinged units data
            _.forEach(allUnassignedUnitsDataByTime, (oneSystemsUnassignedUnits) => {
                rows.push(...oneSystemsUnassignedUnits[i]);
            });

            // add the total time and total hours
            rows.push(totalByTime[i][0]);
            rows.push(totalByTime[i][1]);

            byTimeChunkLine14.push(rows);
            rows = [];

        }

        return [line0, line1, timeLine2, siteNameLine3, addressLine4, systemNameLine4, line5, line6, line7, line8, ...tenantChunkLine9, ...naUnitsChunkLine10, tenantAndUnitsChunkSum, breakLine, line11, unitNamesLine12, line13, ...byTimeChunkLine14];

    };

    const handleClick = () => {
        const fileName = "PowerDistribution Report.xlsx";
        const sheet_name = "Sheet";
        // create Excell book
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "fist file",
            Subject: "the subject test",
            Author: "coolautomation",
            CreatedDate: new Date()
        };

        const data = createfileData();
        // craeting the sheet with the data in it
        console.log("data", data);
        const ws = XLSX.utils.aoa_to_sheet(data);

        // set column width (column by column)
        const wscols = [
            { wch: 25 },
            { wch: 40 }
        ];

        // cols = for columns (!rows for rows)
        ws["!cols"] = wscols;

        // connecting sheet to book
        XLSX.utils.book_append_sheet(wb, ws, sheet_name);

        XLSX.writeFile(wb, fileName);
    };

    return (
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={handleClick}><Export /></IconButton>
    );

}
