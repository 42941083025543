import { makeStyles } from '@material-ui/core';

export const systemInfoPanelStyle = makeStyles({
  infoPanel: {
    width: '15rem',
    padding: '1rem 1rem 1rem 0',
    overflow: 'auto'
  },

  infoLabel: {
    fontSize: '13px',
    color: 'rgb(84, 89, 100)'
  },

  infoData: {
    fontSize: '13px',
    color: 'rgb(84, 89, 100)',
    fontWeight: 'bold',
    textOverflow: "ellipsis",
    overflow: "hidden"
  },

  brandLogo: {
    //filter: "gray", // IE6-9
    '-webkit-filter': 'grayscale(1)', // Google Chrome, Safari 6+ & Opera 15+
    filter: 'grayscale(1)', // Microsoft Edge and Firefox 35+
    minWidth: '4.5rem'
  }
});
