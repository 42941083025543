import { Card, Paper, Typography, withStyles } from "@material-ui/core";
import { Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import Collapse from "../../../components/Collapse/Collapse";
import ErrorBox from "../../../components/ErrorBox/ErrorBox";
import Button from "../../../cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../../models/RootStore";
import { ISite } from "../../../models/Sites";
import { ISystem } from "../../../models/Systems";
import { IUnit } from "../../../models/Units";
import DeviceSystems from "./DeviceSystems";
import { manuallySetSystemViewStyle } from "./ManuallySetSystems.style";
import SystemDetails from "./SystemDetails";
import UnitsToSystemsMapTable from "./UnitsToSystemMapTable";
interface ISystemState {
  name: string;
  brand?: number | null;
  line: number | undefined;
  unitsChanges: any;
  series: any;
  type: any;
  capacityMeasurementUnits: any;
  capacity: any;
}
const isSystemDaikin = (system: ISystem | null | undefined) => {
  // Sorry for that. It's not me, it's typescript.
  return system && system.brandNum === 1 ? true : false;
};

interface IProps {
  mode: number;
  site: ISite;
  system?: ISystem;
  deviceId: string;
  closeModal: any;
  classes?: any;
  import?: boolean;
  deviceSystems?: any;
  deviceControlUnits?: any;
}
export default withStyles(manuallySetSystemViewStyle)(function ManuallySetSystems(props: IProps) {
  const { classes } = props;
  const {
    unitsContainer,
    unitsInfoTitle,
    modalActions,
    deviceSystemsContainer,
    systemAttributes,
    container
  } = classes;

  const device = useStoreState((state) => state.devices.allDevices[props.deviceId]);
  const system: ISystem | null = props.system || null;

  const updateSystem = useStoreActions((action) => action.systems.updateSystem);
  const createSystem = useStoreActions((action) => action.createSystem);
  const getDeviceById = useStoreState((state) => state.devices.getDeviceById);

  const [error, setError] = useState<string | null>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const deviceSystems = props.deviceSystems ?? useStoreState((state) => state.devices.getDeviceSystems(device.id));
  const getDeviceLines = useStoreActions((actions) => actions.devices.getDeviceLines);
  const deviceUnits: IUnit[] = useStoreState((state) =>
    state.devices.getDeviceUnits(device.id, "all", "all")
  );
  const [deviceLines, setDeviceLines] = useState<any>();
  const deviceControlUnits = props.deviceControlUnits;

  const types = useStoreState((state) => state.types.allTypes);
  const { hvacBrands, capacityMeasurementUnitTypes } = types;
  const allLines: IUnit[][] = Object.values(_.groupBy(deviceUnits, "line"));

  const allUnits = useStoreState((state) => state.units.allUnits);
  const updateUnit = useStoreActions((action) => action.units.updateUnit);
  const deleteUnit = useStoreActions((action) => action.deleteUnit);

  const [unitsChanges, setUnitChanges] = useState<IUnit[][]>(allLines);

  const assignUnitToSystem = useStoreActions((action) => action.assignUnitToSystem);
  const unassignUnitFromSystem = useStoreActions((action) => action.unassignUnitFromSystem);
  const associateControlUnitToServiceUnit = useStoreActions((action) => action.associateControlUnitToServiceUnit);
  const dissociateControlUnitFromServiceUnit = useStoreActions((action) => action.dissociateControlUnitFromServiceUnit);
  const [seriesByBrand, setSeriesByBrand] = useState<any>([
    {
      value: "other",
      label: "other"
    }
  ]);

  const startLoader = useStoreActions((a) => a.loader.startLoader);
  const finishLoader = useStoreActions((a) => a.loader.finishLoader);

  useEffect(() => {
    if (props.mode === 1) {
      getDeviceLines(device.id)
        .then((res: any) => {
          setDeviceLines(res);
        });
    }
  }, []);

  useEffect(() => {
    if (system) {
      // if (system.brand) {
      //     let foundHvacBrand: any = null;
      //     const foundHvacBrands = _.filter(hvacBrands, hvacBrand => _.includes(hvacBrand.systemsTypes, system.brand));
      //     foundHvacBrand = foundHvacBrands.length === 1 ? foundHvacBrands[0] : null;
      //     const temp = seriesByBrand;
      //     foundHvacBrand && _.map(foundHvacBrand.series, (key, value) => {
      //         temp.push({
      //             value: key,
      //             label: key
      //         })
      //     }
      //     )
      //     setSeriesByBrand(temp);
      // }
      setUnitChanges(
        allLines.filter(
          (line) => props.mode === 0 || (props.mode === 2 && system && line[0].line === system.line)
        )
      );
    }
  }, []);

  if (!props.deviceId || !device) {
    // TODO : what do i do in this situation??
    return <div></div>;
  }
  const errorBox = error ? <ErrorBox error={error} onClose={() => setError(null)} /> : null;

  const SystemSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    brand: Yup.string().required("Required").nullable(),
    line: Yup.number().required("Required"),
    capacity: Yup.number(),
    series: Yup.string(),
    type: Yup.string()
  });
  const UnitsSchema = Yup.object().shape({
    // unitsChanges: Yup
    //     .array()
    //     .of(
    //         Yup.array()
    //             .of(Yup.object().shape({
    //                 name: Yup.string().required('Required'),
    //                 model: Yup.string(),
    //                 capacity: Yup.number(),
    //             }),
    //             )
    //     )
  });

  const initialValues: ISystemState = {
    name: system ? system.name : "",
    brand: system ? system.brandNum : null,
    line: system ? system.line : undefined,
    unitsChanges,
    series: system ? system.series : "",
    type: system ? system.type : "",
    capacityMeasurementUnits: system
      ? system.capacityMeasurementUnits
      : capacityMeasurementUnitTypes[Object.keys(capacityMeasurementUnitTypes)[0]],
    capacity: system ? system.capacity : 0
  };

  const handleSave = async (values: ISystemState, actions: any) => {
    startLoader();

    if (props.mode !== 1) {
      await Promise.all(values.unitsChanges.map(async (line: any) => {

        await Promise.all(line.map(async (updatedUnit: any) => {//loop over unit's properties
          // Deleted units are already processed.
          if (_.isNull(updatedUnit)) {
            return;
          }

          const unitId = updatedUnit.id;
          const unit: IUnit | undefined = allUnits[unitId];

          let updatedData: any = {};

          unit.name !== updatedUnit.name && (updatedData.name = updatedUnit.name);
          unit.serialNumber !== updatedUnit.serialNumber && (updatedData.serialNumber = updatedUnit.serialNumber);

          unit.task !== updatedUnit.task && (updatedData.task = updatedUnit.task);
          unit.model !== updatedUnit.model && (updatedData.model = updatedUnit.model);
          if (unit.capacity !== updatedUnit.capacity ||
            unit.capacityMeasurementUnits !== updatedUnit.capacityMeasurementUnits) {
            updatedData.capacity = updatedUnit.capacity ? Math.round(updatedUnit.capacity * 10) / 10 : 0;
            updatedData.capacityMeasurementUnits =
              updatedUnit.capacityMeasurementUnits ??
              capacityMeasurementUnitTypes[Object.keys(capacityMeasurementUnitTypes)[0]];
          }

          !_.isEmpty(updatedData) && await updateUnit({ id: unit.id, updatedData });

          if (unit.system !== updatedUnit.system) {
            if (_.isEmpty(updatedUnit.system)) {
              if (!_.isUndefined(unit) && unit.system) {
                unassignUnitFromSystem({ systemId: unit.system, unitId }).catch((err: any) => {
                  setError(err.message);
                });
              }
            } else {
              if (updatedUnit.system) {
                assignUnitToSystem({ systemId: updatedUnit.system, unitId }).catch((err: any) => {
                  setError(err.message);
                });
              }
            }
          }
          if (unit.controlUnit !== updatedUnit.controlUnit) {
            if (_.isEmpty(updatedUnit.controlUnit)) {
              if (!_.isUndefined(unit) && unit.controlUnit) {
                dissociateControlUnitFromServiceUnit({ id: unitId }).catch((err: any) => {
                  setError(err.message);
                });
              }
            } else {
              if (updatedUnit.controlUnit) {
                associateControlUnitToServiceUnit({ id: unitId, controlUnitId: updatedUnit.controlUnit }).catch((err: any) => {
                  setError(err.message);
                });
              }
            }
          }
        })).catch((err: any) => {
          setError(err.message);
        });
      })).catch((err: any) => {
        setError(err.message);
      }).finally(() => finishLoader());

    }
    if (props.mode === 2 && system) {
      updateSystem({
        systemId: system.id,
        updatedData: {
          name: values.name,
          brandNum: values.brand,
          series: values.series ? values.series : "",
          type: values.type ? values.type : "",
          capacity: values.capacity ? parseInt(values.capacity) : 0,
          capacityMeasurementUnits:
            values.capacity && values.capacityMeasurementUnits
              ? values.capacityMeasurementUnits
              : capacityMeasurementUnitTypes[Object.keys(capacityMeasurementUnitTypes)[0]]
        }
      })
        .then(() => {
          props.closeModal();
        })
        .catch((err: any) => {
          setError(err.message);
        })
        .finally(() => finishLoader());
    }
    if (props.mode === 1 && !system) {
      createSystem({
        deviceId: device.id,
        data: {
          name: values.name,
          brandNum: values.brand,
          line: values.line,
          series: values.series ? values.series : "",
          type: values.type ? values.type : "",
          capacity: values.capacity ? parseInt(values.capacity) : 0,
          capacityMeasurementUnits:
            values.capacity && values.capacityMeasurementUnits
              ? values.capacityMeasurementUnits
              : capacityMeasurementUnitTypes[Object.keys(capacityMeasurementUnitTypes)[0]]
        }
      })
        .then(() => {
          setIsDisabled(true);
          props.closeModal();
        })
        .catch((err: any) => {
          setError(err.message);
        })
        .finally(() => finishLoader());
    }
    props.closeModal();
  };

  return (
    <>
      {errorBox}
      <Paper className={systemAttributes}>
        {(props.mode === 0 && !props.import) && (
          <DeviceSystems className={deviceSystemsContainer} device={device} />
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSave}
          enableReinitialize={true}
          validationSchema={(props.mode === 2 || props.mode === 1) ? SystemSchema : UnitsSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, errors, touched, values, setFieldValue, ...restFormikProps }) => {
            const handleUnitDeletion = async (lineIndex: number, unitIndex: any) => {
              const unitToDelete = values.unitsChanges[lineIndex][unitIndex];

              await deleteUnit({ id: unitToDelete.id });

              values.unitsChanges[lineIndex][unitIndex] = null;
            };
            return (
              <form onSubmit={handleSubmit} style={{
                display: "flex",
                flexFlow: "column",
                flex: 1,
                height: "100%"
              }}>
                {((props.mode === 2 || props.mode === 1) && !props.import) && (
                  <SystemDetails
                    isDisabled={isDisabled}
                    classes={classes}
                    values={values}
                    deviceUnits={deviceUnits}
                    system={system}
                    isSystemDaikin={isSystemDaikin}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    deviceLines={deviceLines}
                    {...restFormikProps}
                    {...props}
                  />
                )}
                {allLines
                  .filter(
                    (line) =>
                      props.mode === 0 ||
                      (props.mode === 2 && system && line[0].line === system.line)
                  )
                  .map((line: IUnit[], index) => {
                    let lineHasDaikinSystem = false;
                    // System edit mode, check the system directly.
                    if (_.isEmpty(line)) {
                      return null;
                    }

                    if (system) {
                      lineHasDaikinSystem = isSystemDaikin(system);
                    } else {
                      // If not system edit mode, check the systems of this line.
                      // TODO: Replace with something breakable.
                      _.forEach(deviceSystems, (_system: ISystem) => {
                        if (isSystemDaikin(_system) && line[0].line === _system.line) {
                          lineHasDaikinSystem = true;
                        }
                      });
                    }
                    return (props.mode === 2 || props.import) ? (
                      <Card key={index} className={unitsContainer} style={{ height: `${(values.unitsChanges[index].length * 77.3) + 136}px` }}>
                        <Typography className={unitsInfoTitle}>
                          {t`Units Information`} {(props.import) ? `: line ${line[index].line}` : ""}
                        </Typography>
                        <div style={{
                          padding: "0 14px", display: "flex",
                          height: "calc(100% - 60px)"
                        }}>
                          <UnitsToSystemsMapTable
                            lineIndex={index}
                            errors={errors}
                            touched={touched}
                            isDaikin={lineHasDaikinSystem}
                            systemLine={system && system.line || ""}
                            values={values}
                            setFieldValue={setFieldValue}
                            handleUnitDeletion={handleUnitDeletion}
                            {...restFormikProps}
                            {...props}
                            deviceId={device.id}
                          />
                        </div>
                      </Card>
                    ) : (
                        <Card key={index} className={unitsContainer}>
                          <Collapse title={t`Line: ${line[0]?.line}`}>
                            <div className={unitsContainer} style={{ paddingBottom: 0, height: `${(values.unitsChanges[index].length * 77.3) + 123}px`, maxHeight: "calc(100vh - 536px)" }}>
                              <Typography className={unitsInfoTitle}>
                                {" "}
                                {t`Units Information`}
                              </Typography>
                              <div style={{
                                padding: "0 14px", display: "flex",
                                height: "calc(100% - 78px)"
                              }}>
                                <UnitsToSystemsMapTable
                                  lineIndex={index}
                                  errors={errors}
                                  touched={touched}
                                  isDaikin={lineHasDaikinSystem}
                                  systemLine={line[0]?.line}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  handleUnitDeletion={handleUnitDeletion}
                                  removeHeight={14}
                                  deviceSystems={deviceSystems}
                                  deviceControlUnits={deviceControlUnits}
                                  {...restFormikProps}
                                  {...props}
                                  deviceId={device.id}
                                />
                              </div>
                            </div>
                          </Collapse>
                        </Card>
                      );
                  })}
                <div className={modalActions} style={{ bottom: props.mode === 0 ? -8 : 0 }}>
                  {(props.mode === 2 || props.mode === 1) && (
                    <Button
                      title="close edit"
                      onClick={props.closeModal}
                      uppercase
                      width={150}
                      white
                      marginRight
                    >
                      {t`Cancel`}
                    </Button>
                  )}

                  <Button
                    title="save system"
                    type="submit"
                    uppercase
                    width={150}
                  >
                    {props.mode === 0 && !props.import ? t`Next` : t`Save`}
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </Paper>
    </>
  );
});
