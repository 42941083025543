import _ from "lodash";
import React, { PropsWithChildren, useState } from "react";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnit } from "../../models/Units";
import GroupSearchMenuItem from "./GroupSearchMenuItem";
import MenuItemIcon from "./MenuItemIcon";
import { useStyles } from "./UnitsMenu.style";

interface IUnitsMenuProps {
  onCustomerSelect?: (customerId: string | null) => void;
  onSiteSelect?: (siteId: string | null) => void;
  onSystemSelect?: (systemId: string | null) => void;
  onUnitSelect?: (unitId: string | null) => void;
  isSystemHidden?: boolean;
  generalNaming: string;
  showGeneralOption: boolean;
  showOudRoodUnits?: boolean;
  disableSystemUpdate?: boolean;
  ignoreSystemFilterForUnits?: boolean;
}

const UnitsMenu: React.FC<PropsWithChildren<IUnitsMenuProps>> = (
  props: PropsWithChildren<IUnitsMenuProps>
) => {
  const c = useStyles();

  const selections = useStoreState((s) => s.selections.selections);
  const getUnitType = useStoreState((s) => s.units.getUnitType);

  const indoorUnits = useStoreState((s) => s.selections.getUnitsBySelection("indoor", "", !props.ignoreSystemFilterForUnits));
  const outdoorUnits = useStoreState((s) => s.selections.getUnitsBySelection("outdoor", "", !props.ignoreSystemFilterForUnits));

  const setUnit = (id: string, changePage = true) => {
    // updateSelections({ type: "unit", data: id, disableSystemUpdate: props.disableSystemUpdate });
    props.onUnitSelect && props.onUnitSelect(id);
  };

  const getUnitMenuItem = (unit: IUnit) => {
    let unitName: string = unit.name;
    if (unit && unit.name) {

      return (
        <div key={unit.id}>
          <MenuItemIcon
            title={unitName}
            onClick={setUnit}
            unit={unit.id}
            isSelected={selections.unitId === unit.id}
          />
        </div>
      );
    }
  };

  return (
    <div className={c.view}>
      <div className={c.main}>
        <div className={props.isSystemHidden ? c.hidden : c.menuWrapper}>
          <div >
            {!props.showGeneralOption && (<MenuItemIcon
              title={props.generalNaming}
              onClick={setUnit}
              unit={"null"}
              isSelected={selections.unitId === null}
            />)}
          </div>
          {props.showOudRoodUnits && <GroupSearchMenuItem
            title={t`Outdoor`}
            onClick={_.noop}
            numItems={outdoorUnits.length}
            className={c.outdoor}
            isOpen={true}
          >
            {_.sortBy(outdoorUnits, (unit) => [unit.line, unit.device, unit.privateId]).map((unit) =>
              getUnitMenuItem(unit)
            )}
          </GroupSearchMenuItem>}
          <GroupSearchMenuItem
            title={t`Indoor`}
            onClick={_.noop}
            numItems={indoorUnits.length}
            className={c.indoor}
            isOpen={true}
            hideTitle={props.showOudRoodUnits}
          >
            {_.sortBy(indoorUnits, "name").map((unit) => getUnitMenuItem(unit))}
          </GroupSearchMenuItem>
        </div>
      </div>
    </div>
  );
};

export default UnitsMenu;
