import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  thunk,
  Thunk,
} from "easy-peasy";
import _ from "lodash";
import { IGroup } from "./Groups";
import { IRootStoreModel } from "./RootStore";

export interface IPowerMeter {
  id: string;
  identifier: string;
  name: string;
  site: string;
  device: string;
  system: string;
  ca: string;
  model: string;
  systems?: string[];
}

export interface IPowerMeterMap {
  [key: string]: IPowerMeter;
}

export interface IPowerMetersModel {
  allPowerMeters: IPowerMeterMap;
  initialize: Action<IPowerMetersModel, any>;
  onInitialized: ActionOn<IPowerMetersModel, IRootStoreModel>;
  updatePowerMeter: Thunk<IPowerMetersModel, { id: string; data: any }>;
  updatePowerMeterState: Action<IPowerMetersModel, any>;
}

export const powerMetersModel: IPowerMetersModel = {
  allPowerMeters: {},

  initialize: action((state, payload) => {
    state.allPowerMeters = payload;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      // console.log(target.resolvedTargets);
      // console.log("Got groups: ", debug(state.allPowerMeters));
    }
  ),
  updatePowerMeter: thunk(async (actions, payload) => {
    const system = payload.data.system;
    // console.log(payload);
    const updatedPowerMeter = await coolremoteSDK.PowerMeter.updatePowerMeter(
      payload.id,
      payload.data
    );
    actions.updatePowerMeterState({
      ...updatedPowerMeter.data,
      ...{ system }
    });
    return updatedPowerMeter;
  }),

  updatePowerMeterState: action((state, payload) => {
    state.allPowerMeters[payload.id] = payload;
  })
};
