import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    filterStyle: { marginLeft: "10px" },
    blueFilter: { fill: "#1976d2" },
    textField: {
      marginRight: "14px",
      marginLeft: "5px",
      "& div": { height: "40px", marginBottom: "5px" }
    },
    searchPaperStyle : {
      marginTop: "43px",
      borderRadius: "6px",
      boxShadow: "rgba(0, 0, 0, 0.46) 0px 6px 17px 3px",
      backgroundColor: "#FFF",
      padding: "5px",
      minWidth: "200px",
      maxWidth: "200px",
      overflow: "hidden"
    },
    applyBtn: { width: "95%"},
    menuHeader: {
      width: "100%",
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "9px",
      marginBottom: "10px"
    }
}));
export default useStyles;
