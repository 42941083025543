import { Customer as sdkCustomer, Script as sdkScript } from "coolremote-sdk";
import { Thunk, thunk} from "easy-peasy";

interface IScriptPayload {
  id: string;
  data: object;
}

export interface IScriptModel {
  getCustomerScripts: Thunk<IScriptModel, string>;
  deleteScript: Thunk<IScriptModel, string>;
  updateScript: Thunk<IScriptModel, IScriptPayload>;
  updateScriptState: Thunk<IScriptModel, IScriptPayload>;
  createScript: Thunk<IScriptModel, IScriptPayload>;
}

export const scriptModel: IScriptModel = {
  getCustomerScripts: thunk((actions, payload) => {
    return sdkCustomer.getScripts(payload);
  }),
  deleteScript: thunk((actions, payload) => {
    return sdkScript.delete(payload);
  }),
  updateScript: thunk((actions, payload) => {
    const { id, data } = payload;
    return sdkScript.update(id, data);
  }),
  updateScriptState: thunk((actions, payload) => {
    const { id, data } = payload;
    return sdkScript.updateScriptState(id, data);
  }),
  createScript: thunk((actions, payload) => {
    const { id, data } = payload;
    return sdkCustomer.createScript(id, data);
  })
};
