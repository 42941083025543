import coolremoteSDK from "coolremote-sdk";
import { AlertGroup as AlertGroupSdk } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  Thunk,
  thunk
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface IAlertContact {
  contact: string;
  sendEmail: boolean;
  sendSms: boolean;
}
export interface IAlertGroup {
  id: string;
  name: string;
  enable: boolean;
  contacts: IAlertContact[];
  disconnectTriggerEnable: boolean;
  unitErrorTriggerEnable: boolean;
  unitFilterTriggerEnable: boolean;
  triggers: string[];
}

export interface alertGroupMap {
  [key: string]: IAlertGroup;
}

export interface IAlertGroupsModel {
  allAlertGroups: alertGroupMap;
  initialize: Action<IAlertGroupsModel, any>;
  onInitialized: ActionOn<IAlertGroupsModel, IRootStoreModel>;
  createAlertGroup: Thunk<IAlertGroupsModel, { id: string; alertGroup: any }, IRootStoreModel>;
  _storeAddAlertGroup: Action<IAlertGroupsModel, { id: string; alertGroup: IAlertGroup }>;
  _storeCreateAlertGroup: Action<IAlertGroupsModel, { id: string; data: IAlertGroup }>;
  _storeUpdateContactAlertGroup: Action<IAlertGroupsModel, { id: string; alertGroup: any }>;
  updateAlertGroup: Thunk<IAlertGroupsModel, { id: string; updatedData: any }>;
  updateContactAlertGroup: Thunk<IAlertGroupsModel, { id: string; contactId: string; updatedData: any }>;
  setAlertGroupName: Action<IAlertGroupsModel, { id: string; alertGroupName: any }>;
  setAlertGroupSwitch: Action<IAlertGroupsModel, { id: string }>;
  getAlertGroupContacts: Computed<IAlertGroupsModel, (id: string) => any[], IRootStoreModel>;
  getAlertGroupContactData: Computed<
    IAlertGroupsModel,
    (id: string, contactId: string, service: string) => boolean
  >;
  getSelectedAlertGroup: Computed<IAlertGroupsModel, (id: string) => IAlertGroup>;
}

export const alertGroupsModel: IAlertGroupsModel = {
  allAlertGroups: {},

  initialize: action((state, payload) => {
    state.allAlertGroups = payload;
  }),

  _storeAddAlertGroup: action((state, payload) => {
    state.allAlertGroups[payload.id] = payload.alertGroup;
  }),
  _storeCreateAlertGroup: action((state, payload) => {
    state.allAlertGroups[payload.id] = payload.data;
  }),
  _storeUpdateContactAlertGroup: action((state, payload) => {
    state.allAlertGroups[payload.id] = payload.alertGroup;
  }),

  setAlertGroupName: action((state, payload) => {
    state.allAlertGroups[payload.id].name = payload.alertGroupName;
  }),
  setAlertGroupSwitch: action((state, payload) => {
    const enable = state.allAlertGroups[payload.id].enable;
    state.allAlertGroups[payload.id].enable = !enable;
  }),
  updateAlertGroup: thunk(async (actions, payload) => {
    const data = await AlertGroupSdk.updateOperational(payload.id, payload.updatedData);
    actions._storeAddAlertGroup({ id: data.id, alertGroup: data });
  }),
  updateContactAlertGroup: thunk(async (actions, payload) => {
    const data = await AlertGroupSdk.updateOperationalContact(payload.id, payload.contactId, payload.updatedData);
    actions._storeUpdateContactAlertGroup({ id: data.id, alertGroup: data });
  }),

  createAlertGroup: thunk(async (actions, payload, { getStoreActions }) => {
    const newAlertGroupData = await coolremoteSDK.Customer.createOperationalAlertGroup(
      payload.id,
      payload.alertGroup
    );
    if (!newAlertGroupData) {
      // throw ErrorUtils.errorUnexpected;
    } else {
      actions._storeAddAlertGroup({
        id: newAlertGroupData.id,
        alertGroup: newAlertGroupData
      });
    }
    return newAlertGroupData;
  }),
  getAlertGroupContacts: computed(
    [(state) => state.allAlertGroups, (state, storeState) => storeState.contacts.allContacts],
    (allAlertGroups, contacts) => (id) => {
      if (!allAlertGroups[id]) { return []; }
      return Object.values(contacts).filter((contact: any) =>
        _.find(allAlertGroups[id].contacts, { contact: contact._id })
      );
    }
  ),
  getAlertGroupContactData: computed(
    [(state) => state.allAlertGroups],
    (allAlertGroups) => (id, contactId, service) => {
      if (!allAlertGroups[id]) { return false; }
      if (
        service === "sms" &&
        !_.isNil(_.find(allAlertGroups[id].contacts, { contact: contactId, sendSms: true }))
      ) {
        return true;
      }
      if (
        service === "email" &&
        !_.isNil(_.find(allAlertGroups[id].contacts, { contact: contactId, sendEmail: true }))
      ) {
        return true;
      }
      return false;
    }
  ),
  getSelectedAlertGroup: computed([(state) => state.allAlertGroups], (allAlertGroups) => (id) => {
    return allAlertGroups[id];
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      // console.log(target.resolvedTargets);
      // console.log('Got allAlertGroups: ', debug(state.allAlertGroups));
    }
  )
};
