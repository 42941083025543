import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import CommonUtils from "../utils/CommonUtils";
import { IRootStoreModel, useStoreState } from "./RootStore";

export interface IDisplayFlags {
  globalEnable: boolean;
  enableDashboard: boolean;
  enableUnitDiagnostics: boolean;
  enableSiteManagement: boolean;
  enableUserManagement: boolean;
  enableAlertLog: boolean;
  enableAuditLog: boolean;
  enableControl: boolean;
  enableSettings: boolean;
  enablePowerDistribution: boolean;
  enableUnitDiagnosticsExport?: boolean;
  enableUnitDiagnosticsDatepicker?: boolean;
  enableAlertDelete?: boolean;
  enableGroupManagement?: boolean;
  enableUnitStats?: boolean;
}

export interface IUser {
  id: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  role?: string;
  customer?: string;
  site?: string;
  temperatureScale?: any;
  permissions?: any;
  language?: string;
  alertGroups?: string[];
  isAcceptedTOU?: boolean;
  measurementUnits?: number;
  timeFormat?: number;
  dateFormat?: number;
}

export interface IUserMap {
  [key: string]: IUser;
}

export interface IUsersModel {
  me: IUser;
  dateFormat: any;
  timeFormat: any;
  displayFlags: IDisplayFlags;
  users: IUserMap;
  setUsers: Action<IUsersModel, IUserMap>;
  setMe: Action<IUsersModel, any>;
  setFlags: Action<IUsersModel, { displayFlags: IDisplayFlags }>;
  _storeUpdateUser: Action<
    IUsersModel,
    { userId: string; updatedUserData: IUser }
  >;
  _storeUpdateMe: Action<IUsersModel, { updatedUserData: IUser }>;
  _storeAddUser: Action<IUsersModel, { id: string; user: IUser }>;
  onInitialized: ActionOn<IUsersModel, IRootStoreModel>;
  initialize: Thunk<IUsersModel>;
  getRecoveryToken: Thunk<IUsersModel, { username?: string; email?: string }>;
  resetPassword: Thunk<IUsersModel, { token: any; password: any }>;
  reportProblem: Thunk<IUsersModel, any>;
  updateUser: Thunk<
    IUsersModel,
    { userId: string; updatedData: any },
    any,
    IUsersModel
  >;
  updatePassword: Thunk<
    IUsersModel,
    { userId: string; oldPassword: string; newPassword: string }
  >;
  acceptTOU: Thunk<IUsersModel, any, any, IUsersModel>;
  createCustomerUser: Thunk<
    IUsersModel,
    { id: string; user: IUser },
    IRootStoreModel
  >;
  myFullName: Computed<IUsersModel, string>;
  getFullName: Computed<IUsersModel, (userId: string) => string>;
  getUsername: Computed<IUsersModel, (userId: string) => string>;
  conditionalConvertValueToFahrenheit: Computed<
    IUsersModel,
    (value: any, measurementUnits: string, accuracy?: number) => number
  >;
  conditionalConvertThresholdToFahrenheit: Computed<
    IUsersModel,
    (value: any, measurementUnits: string, accuracy?: number) => number
  >;
  conditionalConvertValueToCelsius: Computed<
    IUsersModel,
    (value: any, measurementUnits: string, accuracy?: number) => number
  >;
  conditionalConvertThresholdToCelsius: Computed<
    IUsersModel,
    (value: any, measurementUnits: string, accuracy?: number) => number
  >;
  getTemperatureScaleDisplay: Computed<IUsersModel, () => string>;
  getTemperatureScaleDisplayPlainText: Computed<IUsersModel, () => string>;
  canLoggedInUserViewTriggerTemplates: Computed<IUsersModel, boolean>;
  isLoggedInUserCustomerUser: Computed<IUsersModel, () => boolean>;
  canLoggedInUserViewTriggers: Computed<IUsersModel, boolean>;
}

export const usersModel: IUsersModel = {
  me: { id: "" },
  users: {},
  timeFormat: computed([
    (state: any) => state.me,
    (state, storeState: any) => storeState.types.allTypes
  ], (me, allTypes) => {
    if (!allTypes || !allTypes.timeFormat) {
      return "HH:mm";
    }
    const { timeFormat: selectedTime = "0" } = me;
    const timeFormatObject = allTypes.timeFormat[selectedTime];
    if (timeFormatObject.text === "24 hours") {
      return "HH:mm";
    }
    if (timeFormatObject.text === "12 hours") {
      return "hh:mma";
    }
  }),
  dateFormat: computed([
    (state: any) => state.me,
    (state, storeState: any) => storeState.types.allTypes
  ], (me, allTypes) => {
    if (!allTypes || !allTypes.dateFormat) {
      return "DD/MM/YY";
    }
    const { dateFormat: selectedDate = "0" } = me;
    return allTypes.dateFormat[selectedDate].text;
  }),
  displayFlags: {
    globalEnable: true,
    enableDashboard: true,
    enableUnitDiagnostics: true,
    enableSiteManagement: true,
    enableUserManagement: true,
    enableAlertLog: true,
    enableAuditLog: true,
    enableControl: true,
    enableSettings: true,
    enablePowerDistribution: true,
    enableGroupManagement: true,
    enableUnitStats: true
  },
  setMe: action((state, payload) => {

    state.me = payload;
  }),
  setUsers: action((state, payload) => {
    state.users = payload;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      // console.log(target.resolvedTargets);
      // console.log("Got users: ", debug(state.users));
    }
  ),
  setFlags: action((state, payload) => {
    state.displayFlags = payload.displayFlags;
  }),
  initialize: thunk(async (actions) => {
    const me = await coolremoteSDK.User.getMe();
    actions.setMe(me);
    const displayFlags = await coolremoteSDK.ManagementApplication.getFlags();
    actions.setFlags({ displayFlags });

    return me;
  }),
  acceptTOU: thunk(async (actions, payload, state) => {
    const isAcceptedTOU = true;
    const userId = state.getState().me.id;
    const updatedUserData = await coolremoteSDK.User.update(userId, {
      isAcceptedTOU
    });
    actions._storeUpdateMe({ updatedUserData });
  }),
  getRecoveryToken: thunk(async (actions, payload) => {
    const data = payload.username ? payload.username : payload.email;
    const type = payload.username ? "username" : "email";
    const app = "service";
    return coolremoteSDK.User.getRecoveryToken(data, type, app);
  }),
  resetPassword: thunk(async (actions, payload) => {
    await coolremoteSDK.User.resetPassword(payload.token, payload.password);
  }),
  reportProblem: thunk(async (actions, payload) => {
    await coolremoteSDK.User.reportProblem(payload);
  }),
  _storeUpdateMe: action((state, payload) => {
    state.me = payload.updatedUserData;
    if (state.users[payload.updatedUserData.id]) {
      state.users[payload.updatedUserData.id] = payload.updatedUserData;
    }
  }),
  _storeUpdateUser: action((state, payload) => {
    if (state.users[payload.userId]) {
      state.users[payload.userId] = payload.updatedUserData;
    }
  }),
  updatePassword: thunk(async (actions, payload) => {
    const updatedUserData = await coolremoteSDK.User.updatePassword(
      payload.userId,
      payload.oldPassword,
      payload.newPassword
    );
  }),
  updateUser: thunk(async (actions, payload, state) => {
    const updatedUserData = await coolremoteSDK.User.update(
      payload.userId,
      payload.updatedData
    );
    const myId = state.getState().me.id;

    if (payload.userId === myId) {
      actions._storeUpdateMe({ updatedUserData });
    } else {
      actions._storeUpdateUser({ userId: payload.userId, updatedUserData });
    }
  }),

  _storeAddUser: action((state, payload) => {
    state.users[payload.id] = payload.user;
  }),

  createCustomerUser: thunk(async (actions, payload, { getStoreActions }) => {
    const newUserData = await coolremoteSDK.Customer.createUser(
      payload.id,
      payload.user
    );
    if (!newUserData) {
      // console.log("Error creating customer user");
    } else {
      actions._storeAddUser({ id: newUserData.id, user: newUserData });
    }

    return newUserData;
  }),

  myFullName: computed(
    [(state) => state.me, (state) => state.users],
    (me, users) => {
      return _createUserNamePresentation(
        me.username,
        me.firstName,
        me.lastName
      );
    }
  ),

  getFullName: computed(
    [(state) => state.me, (state) => state.users],
    (me, users) => (userId) => {
      if (_.isNil(users[userId])) { return "-"; }
      const user = users[userId];
      return _createUserNamePresentation(
        user.username,
        user.firstName,
        user.lastName
      );
    }
  ),

  getUsername: computed(
    [(state) => state.me, (state) => state.users],
    (me, users) => (userId) => {
      if (_.isNil(users[userId])) { return "-"; }
      return users[userId].username || "-";
    }
  ),

  conditionalConvertValueToFahrenheit: computed(
    [(state) => state.me],
    (me) => (value: any, measurementUnits: string, accuracy: number = 1) => {
      if (
        me.temperatureScale === 2 &&
        (measurementUnits === "°C" ||
          measurementUnits.toLowerCase() === "celsius")
      ) {
        return CommonUtils.celsiusToFahrenheit(value, accuracy);
      }
      return value;
    }
  ),
  conditionalConvertThresholdToFahrenheit: computed(
    [(state) => state.me, (state) => state.conditionalConvertValueToFahrenheit],
    (me, conditionalConvertValueToFahrenheit) => (
      value: any,
      measurementUnits: string,
      accuracy: number = 1
    ) => {
      const accuracyMultiplier = Math.pow(10, accuracy);

      return (
        Math.round(
          (conditionalConvertValueToFahrenheit(
            value,
            measurementUnits,
            accuracy
          ) -
            conditionalConvertValueToFahrenheit(
              0,
              measurementUnits,
              accuracy
            )) *
          accuracyMultiplier
        ) / accuracyMultiplier
      );
    }
  ),

  conditionalConvertValueToCelsius: computed(
    [(state) => state.me],
    (me) => (value: any, measurementUnits: string, accuracy: number = 1) => {
      if (
        me.temperatureScale === 2 &&
        (measurementUnits === "°F" ||
          measurementUnits.toLowerCase() === "fahrenheit")
      ) {
        return CommonUtils.fahrenheitToCelsius(value, accuracy);
      }
      return value;
    }
  ),

  conditionalConvertThresholdToCelsius: computed(
    [(state) => state.me, (state) => state.conditionalConvertValueToCelsius],
    (me, conditionalConvertValueToCelsius) => (
      value: any,
      measurementUnits: string,
      accuracy: number = 1
    ) => {
      const accuracyMultiplier = Math.pow(10, accuracy);

      return (
        Math.round(
          (conditionalConvertValueToCelsius(value, measurementUnits, accuracy) -
            conditionalConvertValueToCelsius(0, measurementUnits, accuracy)) *
          accuracyMultiplier
        ) / accuracyMultiplier
      );
    }
  ),

  getTemperatureScaleDisplay: computed([(state) => state.me], (me) => () => {
    if (me.temperatureScale === 2) {
      return "°F";
    }
    return "°C";
  }),

  getTemperatureScaleDisplayPlainText: computed(
    [(state) => state.me],
    (me) => () => {
      if (me.temperatureScale === 2) {
        return "F";
      }
      return "C";
    }
  ),

  canLoggedInUserViewTriggerTemplates: computed([(state) => state.me], (me) => {
    if (me.permissions === "globalAdmin") {
      return true;
    }

    return false;
  }),

  isLoggedInUserCustomerUser: computed([(state) => state.me], (me) => () => {
    return !_.isUndefined(me.customer);
  }),

  canLoggedInUserViewTriggers: computed([(state) => state.me], (me) => {
    return true;
  })
};

function _createUserNamePresentation(
  username?: string,
  firstName?: string,
  lastName?: string
) {
  if (firstName) {
    if (lastName) {
      return firstName + " " + lastName;
    } else {
      return firstName;
    }
  } else {
    return username || "no name";
  }
}
