import { fade, makeStyles } from "@material-ui/core";

export default makeStyles({
  menuStyle: { "& ul": { display: "flex", flexFlow: "column nowrap" } },
  menuHeader: {
    borderBottom: "solid 1px #e5e2e5",
    // backgroundColor: "#f6f6f7",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    height: 83,
    alignItems: "flex-end",
    paddingLeft: 20,
    paddingRight: 10,
    paddingBottom: 12
  },
  menuItemStyle: { padding: 0, width: "100%" },
  applyBtn: { margin: "15px 0", width: "90%", alignSelf: "center", height: "50px !important" },
  resetBtn: { width: "90%", alignSelf: "center" },
  optionsContainer: { width: "100%", height: "calc(100% - 245px)", overflow: "auto", padding: "0 20px" },
  optionTextStyle: {
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "normal",
  // line-height: 3.4,
    letterSpacing: "normal",
    color: "#545964",
    maxWidth: "calc(100vw - 88px)",
    "& span": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "calc(100% - 70px)"
    }
  },
  filtersPopup: {
    minWidth: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    position: "absolute",
    top: 0,
    margin: 0,
    borderRadius: 0,
    justifyContent: "space-between",
    background: "white",
    alignItems: "center",
    zIndex: 1
  },
  mainTitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#29132e"
  },
  tabTitle: {
 fontSize: 16,
 fontWeight: "normal",
 letterSpacing: "normal",
 color: fade("#15111f", 0.6),
 flex: 1,
 lineHeight: "52px",
 textAlign: "center",
 cursor: "pointer",
 textTransform: "capitalize"
},
  selectedTab: {
  borderBottom: "solid 1px #4b1c46",
  fontFamily: "RobotoMedium",
  fontWeight: 500,
  color: "#15111f"
},
  iconBtn: {
  borderRadius: "6px", height: 30, width: 30, padding: 0, marginBottom: -4
},
  tabContainer: {
  display: "flex", padding: "0 20px", width: "100%", height: 52
},
  inputRoot: {
  width: "100%",
  paddingLeft: 9,
  paddingRight: 4,
  height: "100%",
  borderRadius: "4px",
  backgroundColor: "rgba(170, 162, 170, 0.1)",
  fontFamily: "Roboto",
  fontSize: 14,
  fontWeight: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#4b4850" //charcoal-grey
},
  searchBox: {
    height: 52,
    width: "100%",
    margin: "20px 0",
    padding: "0 20px"
  },
  searchIcon: {
    transform: "scale(0.8695)"
  },
  closeIconStyle: {
      width: 25,
      height: 25,
      borderRadius: 6,
      padding: 0,
      "& svg": {
      transform: "scale(0.9)"
    }
  },
  listItemStyle: {
    height: 50,
    cursor: "pointer"
  },
  noValuesText: {
    textAlign: "center"
  },
  filtersContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    height: "100%",
    maxHeight: "calc(100% - 105px)"
  },
  rowSectionTitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    borderBottom: "solid 1px #e5e2e5",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    minHeight: 60,
    justifyContent: "space-between"
},
  coolSlider: {
  // width: "calc(100% - 40px)",
  color: "#35a8e0",
  // marginBottom: 32,
// left: "10px",
  "& input": {
fontSize: "8px"
},
  "& .MuiSlider-root": {
// left: "10px",
color: "#35a8e0"
},
  "& .MuiSlider-valueLabel": {
left: "calc(-50% - 8px)",
color: "inherit",
top: 24,
fontSize: "1rem",
"& *": {
  background: "transparent",
  color: "rgba(97, 97, 97, 0.9)",
  fontSize: "1rem",
  width: "50px"
}
},
  "& .MuiSlider-rail": {
color: "rgba(180, 175, 203, 0.63)"
}
},
  controlSectionContainer: {
display: "flex",
flexFlow: "column",
alignItems: "center",
borderBottom: "solid 1px #e5e2e5"
},
  sliderHolder: {
width: "calc(100% - 40px)",
minHeight: "45px",
marginBottom: "15px"
},
  titleContainer: {
display: "flex",
minHeight: 60,
justifyContent: "space-between",
padding: "0 10px", alignItems: "center", marginBottom: 15, width: "100%"
},
  coldIcon: {
transform: "scale(2)", marginRight: "21px", marginLeft: 10
},
  heatSlider: {
// width: "calc(100% - 40px)",
color: "#f05146",
// marginBottom: 32,
// left: "10px",
"& input": {
fontSize: "8px"
},
"& .MuiSlider-root": {
// left: "10px",
color: "#f05146"
},
"& .MuiSlider-valueLabel": {
left: "calc(-50% - 8px)",
color: "inherit",
top: 24,
fontSize: "1rem",
"& *": {
background: "transparent",
color: "rgba(97, 97, 97, 0.9)",
fontSize: "1rem",
width: "50px"
}
},
"& .MuiSlider-rail": {
color: "rgba(180, 175, 203, 0.63)"
}
},
  autoSlider: {
// width: "calc(100% - 40px)",
color: "#4b1c46",
// marginBottom: 32,
// left: "10px",
"& input": {
fontSize: "8px"
},
"& .MuiSlider-root": {
// left: "10px",
color: "#4b1c46"
},
"& .MuiSlider-valueLabel": {
left: "calc(-50% - 8px)",
color: "inherit",
top: 24,
fontSize: "1rem",
"& *": {
background: "transparent",
color: "rgba(97, 97, 97, 0.9)",
fontSize: "1rem",
width: "50px"
}
},
"& .MuiSlider-rail": {
color: "rgba(180, 175, 203, 0.63)"
}
},
  autoIcon: {
width: 32,
marginRight: 15
},
  controlSectionTitle: {
  fontFamily: "Roboto",
  fontSize: 16,
  fontWeight: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#545964"
},
  removeBorder: {
  border: "none"
},
  textStyle: {
  fontFamily: "Roboto",
  fontSize: 16,
  fontWeight: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#545964",
  marginBottom: "15px",
  width: "100%"
},
  flexContainer: {
  padding: "20px 10px",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",
  borderBottom: "solid 1px #e5e2e5"
},
  label: {
  fontFamily: "RobotoMedium",
  fontSize: 18,
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#545964",
  marginBottom: 15,
  width: "100%"
},
  textFieldStyle: {
    // padding: "15px 281px 17px 15px",
    borderRadius: 4,
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b4850",
    height: 52,
    width: "100%",
    "& div": {
      height: 52
    },
    "& fieldset": {
      border: "none"
    }
},
  checkboxSection: {
  fontFamily: "Roboto",
  fontSize: 15,
  fontWeight: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#545964",
  width: "100%",
  display: "flex",
  alignItems: "center",
  padding: "0 10px",
  marginBottom: 20
},
  checkboxStyle: {
  marginRight: "10px",
  padding: 0,
  "& span": { width: "24px", height: "24px", borderRadius: "3px", backgroundColor: "#fff" }
},
  timeContainer: {
  height: "44px",
  width: "48%",
  backgroundColor: fade("#fff", 0.1),
  borderRadius: 6,
  fontFamily: "Roboto",
  fontSize: 16,
  fontWeight: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "center",
  color: "#79797d",
  textTransform: "capitalize"
},
  timeSelected: {
   // border: `solid 1px ${theme.palette.secondary.main}`
 },
  btnsHolder: {
   display: "flex",
   width: "100%",
   justifyContent: "space-between"
 },
  iosTimeInput: {
   position: "absolute",
   width: "100%",
   height: "100%",
   zIndex: 10,
   visibilty: "none",
   borderRadius: "22px",
   "& input": {
     background: "blue",
     borderRadius: "22px",
     height: "100%",
     padding: 0,
     opacity: 0
   }
 },
  iosTimeLabel: {
   position: "absolute",
   width: "100%",
   height: "100%",
   zIndex: 9,
   borderRadius: 6,
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   fontFamily: "Roboto",
   fontSize: 16,
   fontWeight: "normal",
   lineHeight: "normal",
   letterSpacing: "normal",
   textAlign: "center",
   color: "#79797d"
 },
  iosHourButton: {
   position: "relative",
   display: "flex",
   height: "44px",
   width: "48%",
   borderRadius: 6,
   backgroundColor: fade("#fff", 0.1),
   border: "1px solid #d5d2d5"
 },
  errorLabelStyle: {
   color: "red",
   fontSize: "12px",
   fontWeight: "bold",
   textAlign: "center",
   marginTop: "10px",
   minHeight: "15px"
 }

});

export const paperStyle = {
  marginTop: "43px",
  borderRadius: "6px",
  boxShadow: "rgba(0, 0, 0, 0.46) 0px 6px 17px 3px",
  backgroundColor: "#FFF",
  padding: "5px",
  minWidth: "200px",
  maxWidth: "200px",
  minHeight: "150px",
  maxHeight: "320px",
  overflow: "hidden"
};
