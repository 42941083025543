import {
  IconButton,
  makeStyles
} from "@material-ui/core";
import clsx from "clsx";
import { getIn } from "formik";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { CheckboxChecked, Delete, MoreVert } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import styles from "./AddRule.style";
import AnomaliesUtils from "./AnomaliesUtils";
import { CustomedTextField, CustomSelect, GButton } from "./CustomedComponents";

const equalOptionOnly = [{ value: "=", label: "=" }, { value: "!=", label: "≠" }];
const theresholdOptions = [
  { value: "threshold", label: " >" },
  { value: "thresholdWithin", label: " <" }
];

export default (props: any) => {
  const {
    errors,
    touched,
    name,
    condition,
    deleteCondition,
    index,
    onSelect,
    lastCondition,
    setFieldValue,
    disabled,
    allParamsMap,
    enumParams,
    serviceParamTypes
  } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { inGroup } = props;
  const trapOperatorsOptions = useStoreState((state) => state.trapOperatorsOptions);
  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale);
  const pressureUnit = useStoreState((s) => s.users.me.measurementUnits);
  const ruleParameters = useStoreState((s) => s.ruleParameters);
  const handleCheck = (event: any) => {
    const { target: { checked } } = event;
    onSelect(condition.id, checked);
  };

  const isThreshHold = condition.operator?.includes("threshold");
  const isParamOperator = condition.operator?.includes("parameter");
  const selectedParamEnum = condition.parameter ? allParamsMap[condition.parameter]?.enum : undefined;
  const paramsEnumTypes = selectedParamEnum ? (serviceParamTypes[selectedParamEnum]) : {};
  const valueDropdownOptions = Object.keys(paramsEnumTypes || {}).map((key: any) => ({ status: paramsEnumTypes[key], value: key }));
  const param1Unit = !!condition.parameter && allParamsMap[condition.parameter]?.data_unit_of_measurement;
  const paramList2Filtered = !condition.parameter ? ruleParameters : ruleParameters.filter((param: any) => {
    return param.data_unit_of_measurement === param1Unit && param.code !== +condition.parameter;
  });

  const paramMeasurementMatch = (paramScale: any) => {

    if (temperatureScale === 2 && paramScale === "°C") {
      return "°F";
    }
    if (pressureUnit === 2 && paramScale === "kg/cm2") {
      return "PSI";
    }
    if (pressureUnit === 2 && paramScale === "MPa") {
      return "PSI";
    }

    return paramScale;
  };

  const valueUnit: any = !isThreshHold ?
    paramMeasurementMatch(allParamsMap[condition.parameter]?.data_unit_of_measurement) :
    (allParamsMap[condition.parameter]?.data_unit_of_measurement === allParamsMap[condition.parameter2]?.data_unit_of_measurement ?
      paramMeasurementMatch(allParamsMap[condition.parameter]?.data_unit_of_measurement) :
      "");

  return (
    <>
      <div className={clsx(classes.conditionContainer, { [classes.conditionInGroupContainer]: inGroup })}>
        {inGroup ? <MoreVert className={classes.purbleMoreEvert} /> :
          <div className={classes.moreVertContainer}><MoreVert className={classes.whiteMoreVert} /></div>
        }

        <Checkbox
          color="default"
          edge="end"
          variant="outlined"
          onChange={handleCheck}
          checkedIcon={<CheckboxChecked />}
          className={clsx(classes.checkboxStyle, { [classes.hideCheckbox]: inGroup })}
          disabled={disabled}
        />

        <CustomSelect disabled={disabled}
          error={getIn(errors, `${name}[${index}].parameter`) && getIn(touched, `${name}[${index}].parameter`)}
          placeholder={condition.parameter ? "Parameter" + ((isThreshHold || isParamOperator) && " 1" || "") : "Select Parameter"}
          onChange={(event: any) => {
            const { target: { value } } = event;
            if (allParamsMap[value]?.enum !== selectedParamEnum) { setFieldValue(`${name}[${index}].value`, ""); }
            if (allParamsMap[value]?.enum && !["=", "!="].includes(condition.operator)) { setFieldValue(`${name}[${index}].operator`, ""); setFieldValue(`${name}[${index}].parameter2`, ""); }
            setFieldValue(`${name}[${index}].parameter`, value);
          }}
          value={condition.parameter}
          label="title"
          optionValue="code"
          options={ruleParameters}
          notDisabledOptions={undefined}
          name={`${name}[${index}].parameter`}
        />

        <CustomSelect
          placeholder="Operator"
          className={classes.width110}
          error={getIn(errors, `${name}[${index}].operator`) && getIn(touched, `${name}[${index}].operator`)}
          onChange={(event: any) => setFieldValue(`${name}[${index}].operator`, event.target.value)}
          value={condition.operator}
          label="label"
          optionValue="value"
          options={selectedParamEnum ? equalOptionOnly : trapOperatorsOptions}
          name={`${name}[${index}].operator`}
          disabled={disabled}
        />

        {(isThreshHold || isParamOperator) &&
          <CustomSelect disabled={disabled || !condition.parameter}
            error={getIn(errors, `${name}[${index}].parameter2`) && getIn(touched, `${name}[${index}].parameter2`)}
            placeholder={condition.parameter2  ? "Parameter 2" : "Select Parameter"}
            onChange={(event: any) => setFieldValue(`${name}[${index}].parameter2`, event.target.value)}
            value={condition.parameter2}
            label="title"
            optionValue="code"
            options={paramList2Filtered}
            notDisabledOptions={isThreshHold || isParamOperator ? enumParams : undefined}
            name={`${name}[${index}].parameter2`}
          />
        }

        {isThreshHold &&
          <CustomSelect
            placeholder="Difference"
            className={classes.width110}
            error={getIn(errors, `${name}[${index}].thresholdOperator`) && getIn(touched, `${name}[${index}].thresholdOperator`)}
            onChange={(event: any) => setFieldValue(`${name}[${index}].thresholdOperator`, event.target.value)}
            value={condition.thresholdOperator}
            label="label"
            optionValue="value"
            options={theresholdOptions}
            name={`${name}[${index}].thresholdOperator`}
            disabled={disabled}
          />}

        {isParamOperator ? null : selectedParamEnum ?
          (
            <CustomSelect disabled={disabled}
              className={classes.width140}
              placeholder={"Value"}
              onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
              value={condition.value + ""}
              label="status"
              optionValue="value"
              options={valueDropdownOptions}
              notDisabledOptions={["default"]}
              name={`${name}[${index}].value`}
            />
          )
          : (<CustomedTextField
            disabled={disabled}
            className={classes.width140}
            error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
            onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
            value={condition.value}
            name={`${name}.${index}.value`}
            type="number"
            label={t`Value` + `${!!valueUnit ? `(${valueUnit})` : ""}`}
          />)}
        { <CustomSelect className={classes.width120} error={getIn(errors, `${name}[${index}].duration`) && getIn(touched, `${name}[${index}].duration`)}
          placeholder="Duration"
          name={`${name}[${index}].duration`}
          onChange={(event: any) => setFieldValue(`${name}[${index}].duration`, event.target.value)}
          value={condition.duration}
          label="title"
          optionValue="value"
          options={AnomaliesUtils.selectableDurations}
          disabled={disabled}
        />}

        <IconButton
          disabled={disabled}
          className={classes.iconBtnStyle}
          style={{ marginRight: inGroup ? 15 : 65, marginLeft: condition.operator?.includes("threshold") ? 40 : condition.operator?.includes("parameter") ? 318 : 359 }}
        >
          <Delete style={{ width: 20, height: 20 }} onClick={deleteCondition} />
        </IconButton>
      </div>
      {!lastCondition ? null :
        (<GButton
          inGroup={inGroup}
          disabled={disabled}
          selected={condition.relation}
          onClick={(text: any) => setFieldValue(`${name}[${index}].relation`, text)}
        />)
      }
      <input
        style={{ visibility: "hidden" }}
        type="radio"
        name={`${name}[${index}].relation`}
        value="and"
        checked={condition.relation === "and"}
      />
      <input
        style={{ visibility: "hidden" }}
        type="radio"
        name={`${name}[${index}].relation`}
        value="or"
        checked={condition.relation === "or"}
      />
    </>
  );
};
