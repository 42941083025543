import { Button, Popover, Typography } from "@material-ui/core";
import { CodeSharp } from "@material-ui/icons";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Arrow as SvgArrow, Calendar } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IDateRange } from "../../models/Selections";
import CoolDateRangePicker from "../CoolDateRangePicker/CoolDateRangePicker";
import UnitsMenu from "../Menu/UnitsMenu";
import MenuDropDown from "../MenuDropDown/MenuDropDown";
import useStyles from "./SelectionsMenu.style";

export interface ISelectionsMenuProps {
  onCustomerSelect?: (id: string) => void;
  onSiteSelect?: (id: string) => void;
  onSystemSelect?: (id: string, changePage?: boolean) => void;
  onUnitSelect?: any;
  onDateSelect?: (clearFilter: boolean) => void;
  hideCustomerSelection?: boolean;
  hideSiteSelection?: boolean;
  hideSystemSelection?: boolean;
  hideUnitSelection?: boolean;
  showDateRangePicker?: boolean;
  showOneDatePicker?: boolean;
  disableOneDatePicker?: boolean;
  hideAllOptionsFromDropdown?: string[];
  customGeneralNames?: any;
  disableSystemUpdate?: boolean;
  ignoreSystemFilterForUnits?: boolean;
}

const generalNamesConst = {
  site: "All Sites",
  system: "All Systems",
  unit: "All Units"
};

const SelectionsMenu = ({
  onCustomerSelect,
  onSiteSelect,
  onSystemSelect,
  onUnitSelect,
  onDateSelect,
  hideCustomerSelection = false,
  hideSiteSelection = false,
  hideSystemSelection = false,
  hideUnitSelection = false,
  showDateRangePicker = false,
  showOneDatePicker = false,
  disableOneDatePicker = false,
  hideAllOptionsFromDropdown = [],
  customGeneralNames = {},
  disableSystemUpdate = false,
  ignoreSystemFilterForUnits = false
}: ISelectionsMenuProps) => {

  const history = useHistory();
  const c = useStyles();
  const selections = useStoreState((s) => s.selections.selections);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const customers = useStoreState((s) => s.selections.getCustomersBySelection);
  const sites = useStoreState((s) => s.selections.getSitesBySelection);
  const systems = useStoreState((s) => s.selections.getSystemsBySelection);
  const getUnitName = useStoreState((s) => s.units.getUnitName);
  const countUnitsFor = useStoreState((s) => s.units.countUnitsFor);
  const requestStatsRefresh = useStoreActions((a) => a.units.requestStatsRefresh);

  const indoorUnits = useStoreState((s) => s.selections.getUnitsBySelection("indoor"));

  useEffect(() => {
    if (!selections.customerId) {
      return;
    }

    const sitesOptions = getOptions("site", sites);
    if (sitesOptions?.length === 1) {
      updateSelections({ type: "site", data: sitesOptions[0]?.value });
    }

  }, [selections.customerId]);

  useEffect(() => {
    if (!selections.siteId) {
      return;
    }

    const systemsOptions = getOptions("system", systems);
    if (systemsOptions?.length === 1) {
      updateSelections({ type: "system", data: systemsOptions[0]?.value });
    }

  }, [selections.siteId]);

  useEffect(() => {
    if (!selections.systemId) {
      return;
    }

    if ((indoorUnits.length) === 1) {
      const allUnitsInList = [...indoorUnits];
      updateSelections({ type: "unit", data: allUnitsInList[0]?.id });
    }

  }, [selections.systemId]);

  useEffect(() => {
    if (selections.dateRange !== null) {
      return;
    }
    const lastDayRange = { startDate: new Date(), endDate: new Date() };
    updateSelections({ type: "time", data: lastDayRange });
  }, [selections.dateRange]);

  // Popover state management
  const [anchorEl, setAnchorEl] = React.useState({ unitsMenu: null, dateRangePicker: null });
  const unitsMenuOpen = Boolean(anchorEl.unitsMenu);
  const dateRangePickerOpen = Boolean(anchorEl.dateRangePicker);

  // Unit in popover selected
  const setUnit = (unitId: string | null, changePage = true) => {
    if (onUnitSelect) {
      onUnitSelect(unitId as any);
      handleClose();
      return;
    }
    updateSelections({ type: "unit", data: unitId, disableSystemUpdate });
    handleClose();
  };

  // Selections state management
  const setCustomer = (id: string) => {
    updateSelections({ type: "customer", data: id });
    onCustomerSelect && onCustomerSelect(id);
  };

  const setSite = (id: string) => {
    updateSelections({ type: "site", data: id });
    onSiteSelect && onSiteSelect(id);
  };

  const setSystem = (id: string, changePage = true) => {
    updateSelections({ type: "system", data: id });
    onSystemSelect && onSystemSelect(id);
  };

  const handleNewDateRange = (range: IDateRange | null) => {
    // If null - user chose Cancel, so we don't change current selection
    if (range) {
      updateSelections({ type: "time", data: range });
    }
    setAnchorEl({ unitsMenu: null, dateRangePicker: null });
  };
  const handleDateChange = (date: any | null) => {
    // If null - user chose Cancel, so we don't change current selection
    onDateSelect && onDateSelect(false);
    if (date) {
      const now = new Date();
      let endDate;
      if (new Date(new Date(date?.getTime()).setHours(23, 59, 59)) > now) {
        endDate = now;
      } else {
        endDate = new Date(new Date(date?.getTime()).setHours(23, 59, 59));
      }
      const range = { startDate: new Date(new Date(date.getTime()).setHours(0, 0, 0)), endDate };
      updateSelections({ type: "time", data: range });
    }
    setAnchorEl({ unitsMenu: null, dateRangePicker: null });
  };

  const generalNames = { ...generalNamesConst, ...customGeneralNames };

  const getOptions = (itemType: "customer" | "site" | "system", items: any) => {
    if (itemType === "customer" && items.length > 0 && !selections.customerId) {
      updateSelections({
        type: "customer",
        data: items[0].id
      });
    }
    const options = items.map((item: any) => ({
      name: `${item.name} (${countUnitsFor(itemType, item.id)})`,
      value: item.id,
      key: item.id,
      type: item.type,
      device: item.device || ""
    }));

    // const optionsArr =
    //   itemType === "customer" || hideAllOptionsFromDropdown.includes(itemType)
    //     ? options
    //     : [
    //       {
    //         name: options.length ? generalNames[itemType] : `No ${itemType}s`,
    //         value: "",
    //         key: itemType,
    //         type: 0
    //       },
    //       ...options
    //     ];

    return _.isEmpty(options)
      ? [{ name: `No ${itemType}s`, value: "", key: itemType, type: 0 }]
      : options; //optionsArr;
  };

  const setDatePickerDialogReference = (ref: any) => {
  };
  const printDateRange = (range: IDateRange | null) => {
    if (_.isNil(range)) {
      return "no selection";
    }
    return `${moment(range.startDate).format("ll")} - ${moment(range.endDate).format("ll")}`;
  };

  // Click handler for dropdown button which assigns correct anchor and opens Popover
  type IAnchor = "unitsMenu" | "dateRangePicker";
  const handleClick = useCallback(
    (anchorName: IAnchor) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setAnchorEl({ ...anchorEl, [anchorName]: event.currentTarget });
    },
    []
  );
  const handleClose = () => {
    setAnchorEl({ unitsMenu: null, dateRangePicker: null });
  };

  const selectedUnitName = getUnitName(selections.unitId);

  const customersOptions = getOptions("customer", customers);
  const sitesOptions = getOptions("site", sites);
  const systemsOptions = getOptions("system", systems);

  return (
    <div className={c.selectionsContainer}>
      {!hideCustomerSelection && (
        <MenuDropDown
          onChange={setCustomer}
          value={selections.customerId || ""}
          options={customersOptions}
        />
      )}
      {!hideSiteSelection && (
        <MenuDropDown
          onChange={setSite}
          value={selections.siteId || (sitesOptions?.length === 1 ? sitesOptions[0].value : "")}
          options={[
            ...(
              (hideAllOptionsFromDropdown.includes("site") || sitesOptions?.length === 1) ?
                [] :
                [{
                  name: sitesOptions.length ? generalNames["site"] : `No sites`,
                  value: "",
                  key: "site",
                  type: 0
                }]
            ),
            ...sitesOptions]}
        />
      )}
      {!hideSystemSelection && (
        <MenuDropDown
          onChange={setSystem}
          value={
            selections.systemId || (systemsOptions?.length === 1 ? systemsOptions[0].value : "")
          }
          options={[
            ...(
              (hideAllOptionsFromDropdown.includes("system") || systemsOptions?.length === 1) ?
                [] :
                [{
                  name: systemsOptions.length ? generalNames["system"] : `No systems`,
                  value: "",
                  key: "system",
                  type: 0
                }]
            ),
            ...systemsOptions
          ]}
        />
      )}
      {!hideUnitSelection && (
        <React.Fragment>
          <Button
            disableRipple
            className={clsx(c.selectUnitButton)}
            classes={{
              label: c.selectUnitButton__text
            }}
            size="large"
            onClick={handleClick("unitsMenu")}
            endIcon={<SvgArrow className={clsx(c.selectUnitButton__icon, {[c.flipIcon]: unitsMenuOpen})} />}
          >
            <Typography className={c.selectedUnitStyle}>
              {selections.unitId ? selectedUnitName : generalNames.unit}
            </Typography>
          </Button>
          <Popover
            // elevation={0}
            open={unitsMenuOpen}
            anchorEl={anchorEl.unitsMenu}
            onClose={handleClose}
            classes={{ paper: c.paperBg }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: "left"
            }}
          >
            <UnitsMenu onUnitSelect={setUnit} generalNaming={generalNames.unit} showGeneralOption={hideAllOptionsFromDropdown.indexOf("unit") > -1} ignoreSystemFilterForUnits={ignoreSystemFilterForUnits} />
          </Popover>
        </React.Fragment>
      )}
      {showOneDatePicker && (
        <React.Fragment>
          <Button
            disableRipple
            disabled={disableOneDatePicker}
            className={c.selectDateRangeButton}
            classes={{
              label: c.selectDateRangeButton__text,
              iconSizeLarge: c.selectDateRangeButton__icon
            }}
            size="large"
            onClick={handleClick("dateRangePicker")}
            endIcon={<ArrowDropDownIcon />}
            startIcon={<Calendar style={{ marginLeft: "4px" }} classes={{ "MuiButton-startIcon": { border: "1px solid red" } }} />}
          >
            {`${moment(selections.dateRange?.endDate).format("ll")}`}
          </Button>

          <Popover
            open={dateRangePickerOpen}
            anchorEl={anchorEl.dateRangePicker}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: "left"
            }}
          >
            <DatePicker
              disableToolbar
              variant="static"
              value={selections.dateRange?.endDate}
              onChange={handleDateChange}
              className={c.datePicker}
              autoOk
              maxDate={new Date()}
              ref={(r) => setDatePickerDialogReference(r)}
            />
          </Popover>
        </React.Fragment>
      )}
      {showDateRangePicker && (
        <React.Fragment>
          <Button
            disableRipple
            className={c.selectDateRangeButton}
            classes={{
              label: c.selectDateRangeButton__text,
              iconSizeLarge: c.selectDateRangeButton__icon
            }}
            size="large"
            onClick={handleClick("dateRangePicker")}
            endIcon={<ArrowDropDownIcon />}
            startIcon={<Calendar style={{ marginLeft: "4px" }} />}
          >
            {printDateRange(selections.dateRange)}
          </Button>

          <Popover
            open={dateRangePickerOpen}
            anchorEl={anchorEl.dateRangePicker}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: "left"
            }}
          >
            <CoolDateRangePicker
              handleSubmit={handleNewDateRange}
              initialRange={selections.dateRange}
            />
          </Popover>
        </React.Fragment>
      )}
    </div>
  );
};

export default SelectionsMenu;
