import clsx from 'clsx';
import React from 'react';
import { ChevronRightOutlined } from '@material-ui/icons';
import _ from 'lodash';
import useStyles from './MenuItemIcon.style';

interface IMenuItemIconProps {
  Icon?: any;
  title: string;
  onClick?: (unitId: string) => void;
  unit: string;
  isSelected: boolean;
}

const MenuItemIcon: React.FC<IMenuItemIconProps & any> = props => {
  const classes = useStyles();
  const onClick = () => {
    if (_.isFunction(props.onClick)) {
      props.onClick(props.unit);
    }
  };
  return (
    <header>
      <div
        className={clsx(classes.menuHeader, { [classes.selectedItem]: props.isSelected })}
        onClick={onClick}
      >
        <span className={classes.menuTitle}>{props.title}</span>
      </div>
    </header>
  );
};
export default MenuItemIcon;
