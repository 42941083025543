import coolremoteSDK from "coolremote-sdk";
import { Action, action, Thunk, thunk } from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "../RootStore";

export interface IAlertGroupActions {
  deleteAlertGroup: Thunk<IRootStoreModel, { id: string }>;
  addContactToAlertGroup: Thunk<
    IRootStoreModel,
    { id: string; contactId: string; sendEmail: boolean; sendSms: boolean }
  >;
  removeContactFromAlertGroup: Thunk<IRootStoreModel, { id: string; contactId: string }>;
  _storeDeleteAlertGroup: Action<IRootStoreModel, { id: string }>;

  _storeContactToAlertGroup: Action<
    IRootStoreModel,
    { id: string; contactId: string; sendEmail: boolean; sendSms: boolean }
  >;
  _removeContactFromAlertGroup: Action<IRootStoreModel, { id: string; contactId: string }>;
}

export const alertGroupActions: IAlertGroupActions = {
  deleteAlertGroup: thunk(async (actions, payload) => {
    await coolremoteSDK.AlertGroup.deleteOperational(payload.id);

    actions._storeDeleteAlertGroup({ id: payload.id });
  }),

  addContactToAlertGroup: thunk(async (actions, payload) => {
    const data = { sendEmail: payload.sendEmail, sendSms: payload.sendSms };
    const temp = await coolremoteSDK.AlertGroup.addOperationalContact(payload.id, payload.contactId, data);
    actions._storeContactToAlertGroup({
      id: payload.id,
      contactId: payload.contactId,
      sendEmail: payload.sendEmail,
      sendSms: payload.sendSms
    });
    return temp;
  }),
  removeContactFromAlertGroup: thunk(async (actions, payload) => {
    const data = await coolremoteSDK.AlertGroup.removeOperationalContact(payload.id, payload.contactId);
    actions._removeContactFromAlertGroup({ id: payload.id, contactId: payload.contactId });
  }),

  _removeContactFromAlertGroup: action((state, payload) => {
    if (_.isUndefined(state.alertGroups.allAlertGroups[payload.id].contacts)) {
      // TODO: error handling
    } else {
      const contact = _.find(state.alertGroups.allAlertGroups[payload.id].contacts, {
        contact: payload.contactId
      });
      _.pull(state.alertGroups.allAlertGroups[payload.id].contacts, contact);
    }
    const tempContact = _.clone(state.contacts.allContacts[payload.contactId]);
    const alertGroups = tempContact.operationalAlertGroups;

    if (!_.isNil(alertGroups)) {
      _.pull(alertGroups, payload.id);
      state.contacts.allContacts[payload.contactId] = tempContact;
    }
  }),

  _storeDeleteAlertGroup: action((state, payload) => {
    const contacts: any[] | undefined = Object.values(state.contacts.allContacts).filter((contacts) =>
    _.includes(contacts.operationalAlertGroups, payload.id)
    );
    _.forEach(contacts, (contact) => {
      if (!_.isUndefined(contact.alertGroups)) {
        _.pull(contact.alertGroups, payload.id);
      }
    });
    delete state.alertGroups.allAlertGroups[payload.id];
  }),
  _storeContactToAlertGroup: action((state, payload) => {
    const alertGroups = [...state.contacts.allContacts[payload.contactId].operationalAlertGroups] || [];
    if (!_.isUndefined(alertGroups)) {
      alertGroups.push(payload.id);
      state.contacts.allContacts[payload.contactId].operationalAlertGroups = alertGroups;
    }
    state.alertGroups.allAlertGroups[payload.id].contacts.push({
      contact: payload.contactId,
      sendEmail: payload.sendEmail,
      sendSms: payload.sendSms
    });
  })
};
