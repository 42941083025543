import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  view: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    background: "rgb(236, 235, 239)",
    overflow: "hidden"
  },
  rightSide: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "calc(100% - 240px)",
    overflowY: "auto"
  },
  contentContainer: {
    boxSizing: "border-box",
    margin: "30px",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    minHeight: "70%"
  },
  noContentContainer: {
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    width: "calc(100% - 60px)",
    height: "calc(100% - 130px)",
    alignSelf: "center",
    marginTop: "30px"
  },
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%"
  },
  arrowIcon: { fontSize: "91px", transform: "rotate(90deg)" },
  noUnitText: {
    fontSize: "26px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#545964",
    marginTop: "43px"
  },
  titleDiv: {
    borderBottom: "1px solid #e1e2ea",
    margin: "0 20px",
    height: "64px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  title: {
    fontSize: "20px",
    color: "#4b1c46",
    fontWeight: 500
  },
  graphDiv: {
    display: "flex",
    height: "60%",
    flexDirection: "column"
  },
  btnsDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
    margin: "40px 0"
  },
  bottomDiv: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    margin: "30px",
    borderRadius: "10px",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#f6f5f6"

  },
  bottomSection: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px"
  },
  legendItem: {
    display: "flex"
  },
  legendIcon: {
    width: "9px",
    height: "9px",
    margin: "6px 6px 1px 0"
  },
  coolIcon: {
    backgroundColor: "#35a8e0"

  },
  heatIcon: {
    backgroundColor: "#f05347"
  },
  summaryBox: {
    width: "160px",
    height: "97px",
    borderRadius: "10px",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#f6f5f6",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  summaryText: {
    color: "#4b1c46",
    fontSize: "14px",
    lineHeight: "1.15",
    marginRight: "17px",
    display: "flex",
    alignItems: "center"
  },
  summaryUnit: {
    color: "#4b1c46",
    fontSize: "14px",
    display: "flex",
    alignItems: "center"
  },
  summaryInfo: {
    color: "#4b1c46",
    fontSize: "43px"
  },
  summaryInfoLine: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end"
  },
  summarySection: {
    justifyContent: "flex-end"
  },
  roomTempIcon: {
    backgroundColor: "#00b506"
  },
  setPointIcon: {
    backgroundColor: "#5e5764"
  }

}));

export default useStyles;
