import { ISite } from '../models/Sites';

const CommonUtils = {
  fahrenheitToCelsius(fahrenheit: number, accuracy: number) {
    const accuracyMultiplier = Math.pow(10, accuracy);

    return Math.round((((fahrenheit - 32) * 5) / 9) * accuracyMultiplier) / accuracyMultiplier;
  },

  celsiusToFahrenheit(celsius: number, accuracy: number) {
    const accuracyMultiplier = Math.pow(10, accuracy);

    return Math.round(((celsius * 9) / 5 + 32) * accuracyMultiplier) / accuracyMultiplier;
  }
};

export default CommonUtils;

export function makeFullAddressForSite(site: ISite) {
  if (!site) {
    return '';
  }
  let address = '';
  if (!site.country) return '';
  // US version !
  if (site.address) address += site.address;
  if (site.city) address += `, ${site.city}`;
  if (site.state) address += `, ${site.state}`;
  if (site.postalCode) address += `, ${site.postalCode}`;
  address += `, ${site.country}`;
  return address;
}

export function makeFullName({
  firstName,
  lastName
}: {
  firstName: string;
  lastName: string;
  [key: string]: any;
}) {
  if (!firstName) {
    if (!lastName) return '-';
    else return lastName;
  } else {
    return firstName + ' ' + lastName;
  }
}
