import {AppBar , BottomNavigation, BottomNavigationAction, Box, Collapse, Container, Dialog, DialogActions , DialogContent, DialogTitle, Grid, IconButton, InputLabel , Paper, Typography} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import RestoreIcon from "@material-ui/icons/Restore";
import clsx from "clsx";
import _ from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import {ArrowDown} from "../../icons";
import {ArrowBack, Close, ControlMobile, Dashboard, MobileLogo, MobileMenu, NotitifactionMobile, ReportsMobile, SettingsMobile} from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import MenuDropDown from "../MobileMenuDropDown/MenuDropDown";
import DrawerMenu from "./DrawerMenu";
import TopSelectionsMenu from "./TopFiltersMenu";

import useStyles from "./TopBar.style";
const generalNamesConst = {
  site: "All Sites",
  system: "All Systems",
  unit: "All Units"
};
export default function ErrorBox(props: any) {
  const {
   title,
   leftAction,
   rightIconComponent,
   leftIconComponent,
   addRightPadding,
   hideUnitsFilter,
   rightAction,
   ...restProps
 } = props;
  const {  onCustomerSelect = () => {}, onSiteSelect = () => {}, onSystemSelect = () => {},
           onDateSelect = () => {}, customGeneralNames = {}, hideAllOptionsFromDropdown = []} = props;
  const classes = useStyles();
  const selections = useStoreState((s) => s.selections.mobileSelections);
  const getUnitName = useStoreState((s) => s.units.getUnitName);
  const allSites = useStoreState((s) => s.sites.allSites);
  const allCustomers = useStoreState((s) => s.customers.allCustomers);
  const [openTopFilters, setOpenTopFilters] = React.useState<boolean>(false);
  const selectedUnitName = getUnitName(selections.unitId);
  const selectedCustomerName =  allCustomers[selections?.customerId || ""]?.name;
  const selectedSiteName =  allSites[selections?.siteId || ""]?.name || "All Sites";
  const updateSelections = useStoreActions((a) => a.selections.setMobileSelections);

  useEffect(() => {
    if (_.isEmpty(allCustomers) || selections.customerId){
      return;
    }

    const customerId = Object.keys(allCustomers)[0];
    updateSelections({customerId, siteId: null, unitId: null});

  }, [allCustomers]);

  const closeFilters = () => {
    setOpenTopFilters(false);
  };

  return (
    <>
    <AppBar position="static"  className={classes.header}>
    <Container>
      <Grid container justify="space-between" alignItems="center">
        <IconButton
          edge="start"
          aria-label="menu"
          onClick={leftAction}
          className={classes.leftActionStyle}
        >
          {leftIconComponent}
        </IconButton>

        {title ? <Typography className={classes.titleText}>{title}</Typography> :
        <Box className={classes.titleWrapper} onClick={() => setOpenTopFilters(true)}>
          <Typography className={classes.titleStyle}>
            {selectedCustomerName} <ArrowDown color={"#f4f4f6"} style={{height: 8, width: 12}}/>
          </Typography>
          <Typography
            className={classes.siteUnitName}
          >{selectedSiteName}{selectedUnitName === "-" || hideUnitsFilter ? "" : ", " + selectedUnitName}</Typography>
        </Box>}

        <div className={classes.rightActionStyle}>
          {(rightIconComponent || openTopFilters) &&
            <IconButton
              edge="start"
              aria-label="menu"
              className={clsx(classes.rightButtonStyle, {
                [classes.addPadding]: addRightPadding
              })}
              onClick={openTopFilters ? closeFilters  : rightAction}
            >
              {openTopFilters ? <Close/> : rightIconComponent}
            </IconButton>
          }
        </div>
      </Grid>
    </Container>
     </AppBar>
     {openTopFilters && <TopSelectionsMenu close={closeFilters} hideUnitsFilter={hideUnitsFilter} {...restProps} />}
     </>
 );
}
