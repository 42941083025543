import coolremoteSDK from "coolremote-sdk";
import { Action, action, Thunk, thunk } from "easy-peasy";
import _ from "lodash";
import { IAlert } from "../Alerts";
import { IDevice } from "../Devices";
import { IRootStoreModel } from "../RootStore";
import { ISite } from "../Sites";

export interface ISiteActions {
  createSite: Thunk<IRootStoreModel, { customerId: string; data: any }>;
  updateSite: Thunk<IRootStoreModel, { siteId: string; updatedData: any }>;
  deleteSite: Thunk<IRootStoreModel, { id: string }>;

  _storeCreateSite: Action<IRootStoreModel, { siteId: string; data: ISite }>;
  _storeUpdateSite: Action<IRootStoreModel, { siteId: string; data: any }>;
  _storeDeleteSite: Action<IRootStoreModel, { id: string }>;
}

export const siteActions: ISiteActions = {
  // ---
  // Actions: store manipulations
  _storeCreateSite: action((state, payload) => {
     state.sites.allSites = { ...state.sites.allSites, [payload.siteId]: payload.data };

     if (_.isUndefined(state.customers.allCustomers[payload.data.customer])) {
      // TODO: error handling
      // console.log('no customer exists for site ?', payload.data);
    } else {
      state.customers.allCustomers[payload.data.customer].sites.push(payload.siteId);
    }
     state.parentsMap.sites = { ...state.parentsMap.sites, [payload.siteId]: { customer: payload.data.customer } };
  }),

  _storeUpdateSite: action((state, payload) => {
    if (state.sites.allSites[payload.siteId]) {
      state.sites.allSites[payload.siteId] = payload.data;
    }
  }),

  _storeDeleteSite: action((state, payload) => {
    const site = state.sites.allSites[payload.id];

    if (_.isUndefined(site)) {
      // console.log(`site ${payload.id} not found`);
      return;
    }

    // Remove ourselves from our Customer
    if (_.isUndefined(state.customers.allCustomers[site.customer])) {
      // TODO: error handling
      // console.log(`no customer found for site ${payload.id}?`);
    } else {
      _.pull(state.customers.allCustomers[site.customer].sites, payload.id);
    }

    // Remove device and systems
    _.forEach(site.devices, (deviceId) => {
      const device = state.devices.allDevices[deviceId];
      _.forEach(device.systems, (systemId) => {
        delete state.systems.allSystems[systemId];

        // Reset systems selection if needed
        if (state.selections.selections.systemId === systemId) {
          state.selections.selections.systemId = null;
        }
      });

      _.forEach(device.units, (unitId) => delete state.units.allUnits[unitId]);

      delete state.devices.allDevices[deviceId];
    });

    // Remove related alerts
    _.forEach(site.alerts, (alertId) => {
      delete state.alerts.allAlerts[alertId];
    });

    // Reset sites selection if needed
    if (state.selections.selections.siteId === payload.id) {
      state.selections.selections.siteId = null;
    }

    // Finally delete the site itself
    delete state.sites.allSites[payload.id];
  }),

  // ---
  // Thunks: API and action calls
  createSite: thunk(async (actions, payload) => {
    const data: ISite = await coolremoteSDK.Customer.createSite(payload.customerId, payload.data);
    actions._storeCreateSite({ siteId: data.id, data });
    return data;
  }),

  updateSite: thunk(async (actions, payload) => {
    const data = await coolremoteSDK.Site.update(payload.siteId, payload.updatedData);

    actions._storeUpdateSite({ siteId: payload.siteId, data });
  }),

  deleteSite: thunk(async (actions, payload) => {
    await coolremoteSDK.Site.delete(payload.id);

    actions._storeDeleteSite({ id: payload.id });
  })
};
