import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { t } from "ttag";
import AlertsMap from "../../components/AlertsMap/AlertsMap";
import ErrorLogs from "../../components/ErrorLogs/ErrorLogs";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import SystemTable from "../../components/SystemTable/SystemTable";
import Button from "../../cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AlertInfoPopup from "../Alerts/AlertInfoPopup";
import AlertsBand from "./AlertsBand";
import useStyles from "./Dashboard.style";
import OperationalOverview from "./OperationalOverview";
import SystemOverviewPopUp from "./SystemOverviewPopUp";
import TOU from "./TOU";

const Dashboard: React.FC<RouteComponentProps & any> = (props) => {
  const classes = useStyles();
  const { errorLogsCard, HVACCard, googleCard, listItem, ...otherProps } = classes;

  const getSystemFirstUnitId = useStoreState((s) => s.systems.getSystemFirstUnitId);
  const me = useStoreState((s) => s.users.me);
  const acceptTOU = useStoreActions((a) => a.users.acceptTOU);

  const [clickedAlert, setClickedAlert] = React.useState<any>("");
  const [open, setOpen] = React.useState(false);
  const [systemId, setSystemId] = React.useState<string>("");
  const [unitId, setUnitId] = React.useState<string | null>("");
  const [alertFilterType, setAlertFilterType] = React.useState<any>(null);

  const accept = () => {
    acceptTOU();
  };

  const onSystemClick = (systemId: string) => {
    let firstUnitId: string | null = null;

    // Get the first outdoor unit of the selected system.
    firstUnitId = getSystemFirstUnitId(systemId, 2);

    // If no outdoor units on the selected system, get the first indoor.
    if (!firstUnitId) {
      firstUnitId = getSystemFirstUnitId(systemId, 1);
    }
    setUnitId(firstUnitId);
    setSystemId(systemId);
    setOpen(true);
  };
  // const onSystemSelect = (systemId: string) => {
  //   updateSelections({ type: "system", data: systemId });
  //   history.push(`/system-diagnostics/${systemId}`);
  // };
  const handleClose = () => {
    setOpen(false);
    setClickedAlert("");
  };

  const handleAlertInfo = (alert: any) => {
    setClickedAlert(alert);
  };
  
  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...otherProps} />
      <div className={classes.contentArea}>
        <Header path={["Dashboard"]} hideSystemSelection hideUnitSelection />
        {/* ================== NEW ================== */}
        <Grid container spacing={2} className={classes.content}>
          <Grid item xs={5} className={classes.mapArea}>
            <AlertsMap />
          </Grid>
          <Grid item xs={7} className={classes.tablesArea}>
            <Grid container direction="column" className={classes.tablesContainer}>
              <Grid item xs={12} container direction="row" className={classes.HVACContainer}>
                <Grid xs={8} className={classes.fullHeight}>
                  <Card raised={false} className={classes.HVACCard}>
                    <div className={classes.HVAC}>
                      <Typography
                        className={classes.detailsSectionSecondTitle}
                        >{t`HVAC Systems`}</Typography>
                      <SystemTable onSystemClick={onSystemClick} />
                    </div>
                  </Card>

                </Grid>
                <Grid xs={4} className={classes.operationalView}>
                  <Card raised={false} className={classes.HVACCard}>
                    <div className={classes.HVAC}>
                      <Typography
                        className={classes.detailsSectionSecondTitle}
                      >{t`Operational Overview`}</Typography>
                      <OperationalOverview />
                    </div>
                  </Card>
                </Grid>
              </Grid>
              <Grid item className={classes.alertsContainer}>
                <AlertsBand   setAlertFilterType={setAlertFilterType}
                                    filterType={alertFilterType}/>
                <Card className={classes.errorLogsCard}>
                  <ErrorLogs
                    showPointer
                    usePadding={false}
                    hideColumns={["status", "customerName", "alertType1"]}
                    onAlertClick={handleAlertInfo}
                    inDashboard={true}
                    filterType={alertFilterType}
                  />
                </Card>
              </Grid>
            </Grid>
            {/* ================== NEW with grid ================== */}
          </Grid>
        </Grid>
        <SystemOverviewPopUp
          open={open}
          // onSystemSelect={onSystemSelect}
          systemId={systemId}
          isUnit={!unitId}
          handleClose={handleClose}
        />
        <AlertInfoPopup
          alert={clickedAlert && clickedAlert}
          open={!!clickedAlert}
          handleClose={handleClose}
        />
      </div>

      <Dialog
        onClose={_.noop}
        aria-labelledby="simple-dialog-title"
        open={me.isAcceptedTOU ? false : true}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <TOU />
        </DialogContent>

        <DialogActions
        // className={classes.modalActions}
        >
          <Button onClick={accept}>
            {t`Accept`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Dashboard;
