import React from 'react';

function SvgOnGrey(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 19" {...props}>
      <path
        d="M4.846 3.9C2.93 5.288 1.68 7.524 1.68 10.055c0 4.217 3.456 7.636 7.719 7.636s7.718-3.419 7.718-7.636c0-2.53-1.25-4.767-3.165-6.157M9.225 1.68v7.592"
        fill="none"
        fillRule="evenodd"
        stroke="#AAA2AA"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
    </svg>
  );
}

export default SvgOnGrey;
