import { Grid , Slider, TextField, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import {BottomNav, TopBar} from "../../components";
import Switch from "../../components/SmallSwitch/Switch";
import Button from "../../cool_widgets/Button";
import { MobileLogo, MobileMenu} from "../../icons/";
import ArrowIcon from "../../icons/BigArrowUp";
import {
   AddIcon,
  AutoMode,
  ColdBlue,
  HotRed,
  Swing
} from "../../svgComponents";
import ModesList from "./ModesList/ModesList";

import { useStoreActions, useStoreState } from "../../models/RootStore";
import unitManagementStyles from "./unitManagement.style";

const UnitManagement = (props: any) => {
  const history = useHistory();
  const useStyles = makeStyles(unitManagementStyles);
  const classes = useStyles();
  const [unit, setUnit] = useState<any>({});

  const types: any = useStoreState((states) => states.types.allTypes);
  const addMessage = useStoreActions((actions) => actions.errorMessage.addMessage);
  const updateUnit = useStoreActions((actions) => actions.units.updateUnit);
  const getUnit = useStoreState((state) => state.units.getUnit);
  const {unitId} = useStoreState((state) => state.selections.mobileSelections);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay());
  const me = useStoreState((state) => state.users.me);
  const { temperatureScale } = me;
  const getDeviceById = useStoreState((state) => state.devices.getDeviceById);

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [reloadUnitsInTopFilters, setReloadUnitsInTopFilters] = useState<boolean>(false);
  const [disableMessage, setDisableMessage] = useState<string>("");

  const {
    name,
    isVisible,
    supportedOperationModes,
    supportedFanModes,
    supportedSwingModes,
    enableHeatMode,
    enableCoolMode,
    enableAutoMode,
    temperatureLimits
  } = unit;

  useEffect(() => {
    if (!unitId) {
      return;
    }

    const unit = getUnit(unitId);
    setUnit(unit);
    const device: any = getDeviceById(unit?.device || "");
    if (!device?.isConnected){
      setDisableMessage(t`Device is disconnected`);
      return;
    }
    setDisableMessage("");
  }, [unitId]);

  const getUnitKeyOfMode = (typesMode: string) => {
    let unitModeName: any = "";
    switch (typesMode) {
      case "operationModes":
        unitModeName = "supportedOperationModes";
        break;
      case "fanModes":
        unitModeName = "supportedFanModes";
        break;
      case "swingModes":
        unitModeName = "supportedSwingModes";
        break;

      default:
        unitModeName = null;
        break;
    }
    return unitModeName;
  };

  const updateLocalUnit = (modeName: string, modeNumber: any) => {
    const locUnit = { ...unit };
    const unitModeName = getUnitKeyOfMode(modeName);
    if (!unitModeName) {
      return;
    }

    const checkIndex = locUnit[unitModeName].indexOf(+modeNumber);
    if (checkIndex > -1) {
      locUnit[unitModeName].splice(checkIndex, 1);
    } else {
      locUnit[unitModeName] = [...locUnit[unitModeName], +modeNumber];
    }
    setIsSaveDisabled(false);
    setUnit(locUnit);
  };

  const saveUnit = () => {
    const {
      name,
      isVisible,
      supportedOperationModes,
      supportedFanModes,
      supportedSwingModes,
      enableAutoMode,
      enableCoolMode,
      enableHeatMode,
      temperatureLimits
    } = unit;
    updateUnit({
      id: unitId,
      updatedData: {
        name,
        isVisible,
        supportedOperationModes,
        supportedFanModes,
        supportedSwingModes,
        enableCoolMode,
        enableHeatMode,
        enableAutoMode,
        temperatureLimits
      }
    }).then(() => {
      // if(name )
      setReloadUnitsInTopFilters(true);
      setIsSaveDisabled(true);
    }).catch((error: any) => {
      addMessage({ message: error.message });
    });
  };

  const updateUnitName = (e: any) => {
    const { value } = e.target;
    setUnit({ ...unit, name: value });
    setIsSaveDisabled(false);
  };

  const changeSupportedValues = (e: any, mode: string) => {
    const isChecked = e.target.checked;
    const unitModeName = getUnitKeyOfMode(mode);
    const updatedUnit = { ...unit };

    if (!unitModeName) {
      return;
    }
    updatedUnit[unitModeName] = !isChecked
      ? []
      : Object.keys(types[mode]).map((key: any) => +key);
    setUnit(updatedUnit);
    setIsSaveDisabled(false);
  };

  const changeUnitVisibility = (isVisible: boolean) => {
    setUnit({ ...unit, isVisible });
    setIsSaveDisabled(false);
  };

  const valueLabelFormat = (value: number) => {
      const valueToShow = value + temperatureSymbol;
      return `${valueToShow}`;
  };

  const handleCoolRange = (event: any, newValue: number | number[]) => {
    const {temperatureLimits} = unit;
    setUnit({...unit, temperatureLimits: {...temperatureLimits, 0: newValue}});
    setIsSaveDisabled(false);
  };

  const handleHeatRange = (event: any, newValue: number | number[]) => {
    const {temperatureLimits} = unit;
    setUnit({...unit, temperatureLimits: {...temperatureLimits, 1: newValue}});
    setIsSaveDisabled(false);
  };

  const handleAutoRange = (event: any, newValue: number | number[]) => {
    const {temperatureLimits} = unit;
    setUnit({...unit, temperatureLimits: {...temperatureLimits, 2: newValue}});
    setIsSaveDisabled(false);
  };

  const NoUnitSelected = () => (
      <Grid
      container={true}
      direction={"column"}
      className={classes.noContentContainer}
      >
      <div className={classes.grayOval}>
      <ArrowIcon className={classes.arrowIcon} />
      </div>
      <Typography className={classes.noUnitText}>
      {t`Please select a unit using the above filters.`}
      </Typography>
      </Grid>
    );

  return (
    <>
    <TopBar  leftAction={() => history.push("/dashboard")}
    leftIconComponent={<MobileLogo />}
    rightIconComponent={<MobileMenu />}
    reload={reloadUnitsInTopFilters}
    />
    <div className={classes.root}>
      {!unitId || _.isEmpty(unit) ? <NoUnitSelected /> :
        <>
        <div className={classes.pageContent}>
        {disableMessage && <Typography className={classes.errorLabelStyle}>{disableMessage}</Typography>}
        <div className={classes.nameSection}>
          <TextField
            value={name || ""}
            onChange={updateUnitName}
            className={classes.textStyle}
            label={t`Unit Name`}
            variant="outlined"
          />
        </div>

        <div className={clsx(classes.section, classes.controlSection)}>
        <div className={classes.controlSectionHead}>
        <Typography
        className={classes.controlSectionTitle}
        >{t`Mode Settings`}</Typography>
        <Switch
        checked={
          !!(supportedOperationModes && supportedOperationModes.length)
        }
        switchChange={(e: any) => changeSupportedValues(e, "operationModes")}
        disabled={!!disableMessage}
        />
        </div>
        <div className={classes.controlSectionBody}>
        <ModesList
        modeName={"operationModes"}
        activeModes={supportedOperationModes}
        action={updateLocalUnit}
        disabled={!!disableMessage}
        />
        </div>
        </div>

        <div className={clsx(classes.section, classes.controlSection)}>
        <div className={classes.controlSectionHead}>
        <Typography
        className={classes.controlSectionTitle}
        >{t`Fan Speed Options`}</Typography>
        <Switch
        checked={!!(supportedFanModes && supportedFanModes.length)}
        switchChange={(e: any) => changeSupportedValues(e, "fanModes")}
        disabled={!!disableMessage}
        />
        </div>
        <div className={classes.controlSectionBody}>
        <ModesList
        modeName={"fanModes"}
        activeModes={supportedFanModes}
        action={updateLocalUnit}
        disabled={!!disableMessage}
        />
        </div>
        </div>

        <div className={clsx(classes.section, classes.controlSection)}>
        <div className={classes.controlSectionHead}>
        <Typography
        className={classes.controlSectionTitle}
        >{t`Swing Options`}</Typography>
        <Switch
        checked={!!(supportedSwingModes && supportedSwingModes.length)}
        switchChange={(e: any) => changeSupportedValues(e, "swingModes")}
        disabled={!!disableMessage}
        />
        </div>
        <div className={classes.controlSectionBody}>
        <ModesList
        modeName={"swingModes"}
        activeModes={supportedSwingModes}
        action={updateLocalUnit}
        disabled={!!disableMessage}
        />
        </div>
        </div>

        <div className={clsx(classes.controlSectionHead, classes.normalSection)}>
          <Typography className={classes.controlSectionTitle}>{t`Display Unit in Control Tab`}</Typography>
          <Switch
            checked={isVisible}
            disableRipple={true}
            switchChange={(e: any) => changeUnitVisibility(!isVisible)}
            value="checkedA"
            disabled={!!disableMessage}
          />
        </div>

        <div className={classes.controlSectionContainer}>
          <div className={classes.titleContainer}>
              <Typography className={classes.controlSectionTitle}>
                <ColdBlue className={classes.coldIcon}/>
                {t`Min/max Setpoint cooling`}
              </Typography>
            <Switch
            checked={enableCoolMode}
            disableRipple={true}
            switchChange={() => {setUnit({...unit, enableCoolMode: !enableCoolMode}); setIsSaveDisabled(false); }}
            value="checkedA"
            disabled={!!disableMessage}
            />
          </div>
          <div className={classes.sliderHolder}>
          <Slider
              className={classes.coolSlider}
              onChange={handleCoolRange}
              aria-labelledby="discrete-slider-always"
              min={temperatureScale === 1 ? 0 : 32}
              max={temperatureScale === 1 ? 50 : 122}
              value={temperatureLimits[0]}
              valueLabelDisplay="on"
              valueLabelFormat={valueLabelFormat}
              step={1}
              disabled={!enableCoolMode || !!disableMessage}
          />
          </div>
        </div>
        <div className={classes.controlSectionContainer}>
          <div className={classes.titleContainer}>
              <Typography className={classes.controlSectionTitle}>
                <HotRed className={classes.coldIcon} style={{transform: "scale(1.778)"}}/>
                {t`Min/max Setpoint heating`}
              </Typography>
            <Switch
            checked={enableHeatMode}
            disableRipple={true}
            switchChange={() => {setUnit({...unit, enableHeatMode: !enableHeatMode}); setIsSaveDisabled(false); }}
            value="checkedA"
            disabled={!!disableMessage}
            />
          </div>
          <div className={classes.sliderHolder}>
          <Slider
              className={classes.heatSlider}
              onChange={handleHeatRange}
              aria-labelledby="discrete-slider-always"
              min={temperatureScale === 1 ? 0 : 32}
              max={temperatureScale === 1 ? 50 : 122}
              value={temperatureLimits[1]}
              valueLabelDisplay="on"
              valueLabelFormat={valueLabelFormat}
              step={1}
              disabled={!enableHeatMode || !!disableMessage}
          />
          </div>
        </div>
        <div className={clsx(classes.controlSectionContainer, classes.removeBorder)}>
          <div className={classes.titleContainer}>
              <Typography className={classes.controlSectionTitle}>
                <AutoMode maincolor="#aaa2aa" className={classes.autoIcon} />
                {t`Auto Mode Temp Settings`}
              </Typography>
            <Switch
            checked={enableAutoMode}
            disableRipple={true}
            switchChange={() => {setUnit({...unit, enableAutoMode: !enableAutoMode}); setIsSaveDisabled(false); }}
            value="checkedA"
            disabled={!!disableMessage}
            />
          </div>
          <div className={classes.sliderHolder}>
          <Slider
              className={classes.autoSlider}
              onChange={handleAutoRange}
              aria-labelledby="discrete-slider-always"
              min={temperatureScale === 1 ? 0 : 32}
              max={temperatureScale === 1 ? 50 : 122}
              value={temperatureLimits[2]}
              valueLabelDisplay="on"
              valueLabelFormat={valueLabelFormat}
              step={1}
              disabled={!enableAutoMode || !!disableMessage}
          />
          </div>
        </div>

      </div>
      <Button
      disabled={isSaveDisabled || !!disableMessage}
      className={classes.saveButton}
      onClick={() => saveUnit()}
      >{t`Save`}</Button>
      </>}
    </div>
    < BottomNav  />
    </>
  );
};

export default UnitManagement;
