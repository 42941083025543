import coolremoteSDK from "coolremote-sdk";
import { Action, action, Thunk, thunk } from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel, useStoreState } from "./RootStore";

export interface IMessageHandlers {
  _handleSocketMessage: Thunk<IRootStoreModel, any, any, IRootStoreModel>;
}

// helper function
export const messageHandlers: IMessageHandlers = {
  _handleSocketMessage: thunk(async (actions, message: any, { getStoreState }) => {
    if (message.type === "ping") {
      actions.compareAppVersion(message.version);
      return;
    }

    switch (message.name) {
      case "UPDATE_UNIT":
        let updateData = {} as any;
        if (!_.isNil(message.data.name)) { updateData.name = message.data.name; }
        if (!_.isNil(message.data.operationMode)) {
          updateData.activeOperationMode = message.data.operationMode;
        }
        if (!_.isNil(message.data.operationStatus)) {
          updateData.activeOperationStatus = message.data.operationStatus;
        }
        if (!_.isNil(message.data.ambientTemperature)) {
          updateData.ambientTemperature = message.data.ambientTemperature;
        }

        if (!_.isNil(message.data.supportedOperationModes)) {
          updateData.supportedOperationModes = message.data.supportedOperationModes;
        }

        if (!_.isNil(message.data.supportedSwingModes)) {
          updateData.supportedSwingModes = message.data.supportedSwingModes;
        }

        if (!_.isNil(message.data.supportedFanModes)) {
          updateData.supportedFanModes = message.data.supportedFanModes;
        }

        // if (!_.isNil(message.data.tStatLock)) {
        //   updateData.tStatLock = message.data.tStatLock;
        // }

        if (!_.isNil(message.data.setpoint)) {
          updateData.activeSetpoint = message.data.setpoint;
        }

        // unit limits list
        if (!_.isNil(message.data.enableOnoff)) {
          updateData.enableOnoff = message.data.enableOnoff;
        }

        if (!_.isNil(message.data.enableMode)) {
          updateData.enableMode = message.data.enableMode;
        }

        if (!_.isNil(message.data.enableSetpoint)) {
          updateData.enableSetpoint = message.data.enableSetpoint;
        }

        if (!_.isNil(message.data.enableOnState)) {
          updateData.enableOnState = message.data.enableOnState;
        }

        if (!_.isNil(message.data.enableCoolMode)) {
          updateData.enableCoolMode = message.data.enableCoolMode;
        }


        if (!_.isNil(message.data.enableHeatMode)) {
          updateData.enableHeatMode = message.data.enableHeatMode;
        }

        if (!_.isNil(message.data.enableAutoMode)) {
          updateData.enableAutoMode = message.data.enableAutoMode;
        }

        if (!_.isNil(message.data.temperatureLimits)) {
          updateData.temperatureLimits = message.data.temperatureLimits;
        }

        // eWrc locks

        if (!_.isNil(message.data.eWrcEnableOnoff)) {
          updateData.eWrcEnableOnoff = message.data.eWrcEnableOnoff;
        }

        if (!_.isNil(message.data.eWrcEnableMode)) {
          updateData.eWrcEnableMode = message.data.eWrcEnableMode;
        }

        if (!_.isNil(message.data.eWrcEnableSetpoint)) {
          updateData.eWrcEnableSetpoint = message.data.eWrcEnableSetpoint;
        }


        if (!_.isNil(message.data.eWrcEnableCoolMode)) {
          updateData.eWrcEnableCoolMode = message.data.eWrcEnableCoolMode;
        }


        if (!_.isNil(message.data.eWrcEnableHeatMode)) {
          updateData.eWrcEnableHeatMode = message.data.eWrcEnableHeatMode;
        }


        if (!_.isNil(message.data.eWrcTemperatureLimits)) {
          updateData.eWrcTemperatureLimits = message.data.eWrcTemperatureLimits;
        }

        if (!_.isNil(message.data.eWrcDisable)) {
          updateData.eWrcDisable = message.data.eWrcDisable;
        }



        actions.units._storeUpdateUnit({
          id: message.data.unitId,
          data: updateData
        });
        break;
      case "UPDATE_SENSOR":
        let updateSensorData = {} as any;
        updateSensorData.readingValue = message.data.readingValue;
        updateSensorData.readingValueTimestamp = message.data.readingValueTimestamp;
        actions.sensors._storeUpdateSensor({
          id: message.data.sensorId,
          data: updateSensorData
        });
        break;
      case "UNIT_ADDED":
        if (_.isNil(getStoreState().units.allUnits[message.data.unitId])) {
          const newUnitData = await coolremoteSDK.Unit.fetch(message.data.unitId);

          actions.units._storeCreateUnit({
            id: message.data.unitId,
            data: newUnitData
          });
        }
        break;

      // TODO: why not UNIT_CONNECTED/DISCONNECTED ???
      case "UNIT_RECONNECTED":
        await actions.handleUnitReconnected({ id: message.data.unitId });
        break;

      case "UNIT_DISCONNECTED":
        await actions.handleUnitDisconnected({ id: message.data.unitId });
        break;

      case "DEVICE_CONNECTED":
      case "DEVICE_DISCONNECTED":
        await actions.handleDeviceConnection({ id: message.data.deviceId });
        break;

      case "WS_EVENT_CREATED":
        await actions.fetchNewAlert({ id: message.data.eventId });
        break;

      case "WS_EVENT_UPDATED":
        await actions.alerts.fetchUpdatedAlert({ id: message.data.eventId });
        break;

      default:
        break;
    }
  })
};
