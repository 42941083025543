import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import AddRule from "../../components/AddRule/AddRule";
import Delete from "../../components/Delete/Delete";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import { Lookup } from "../../components/Lookup";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import TableColumnSearch from "../../components/TableColumnSearch/TableColumnSearch";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./TrapsList.style";

const TrapsList: React.FC = (props: any) => {
    const classes = useStyles();
    const types = useStoreState((s) => s.types.allTypes);
    const isInitialized = useStoreState((s) => s.isInitialized);
    const getCustomerName = useStoreState((state) => state.customers.getCustomerName);
    const getTraps = useStoreActions((action) => action.traps.getTraps);
    const addTrap = useStoreActions((action) => action.traps.addTrap);
    const updateTrap = useStoreActions((action) => action.traps.updateTrap);
    const assignToTraps = useStoreActions((action) => action.traps.assignToTraps);
    const deleteTrap = useStoreActions((action) => action.traps.deleteTrap);
    const getTrapTemplates = useStoreActions((action) => action.anomalyTemplates.getTrapTemplates);
    const [openAddRule, setOpenAddRule] = useState<boolean>(false);
    const [traps, setTraps] = useState<any>({});
    const [editTrap, setEditTrap] = useState<any>(null);
    const [trapTemplatesToBrands, setTrapTemplatesTobrands] = useState<any>({});
    const [trapTemplatesMap, setTrapTemplatesMap] = useState<any>({});
    const [selectedFilters, setSelectedFilters] = useState<any>({ brands: [] });
    const [lookupAnchor, setAnchor] = useState(null);
    const [searchAnchor, setSearchAnchor] = useState(null);
    const [filteredTraps, setFilteredTraps] = useState<any>({});
    const [brandsArray, setBrandsArray] = useState<any>([]);
    const [searchValues, setSearchValues] = useState<any>({ name: "", description: "" });
    const [searchColumn, setSearchColumn] = useState<string>("");
    const [searchValue, setSearchValue] = useState<string>("");
    const { hvacBrands = [] } = types;
    const sortedTraps = _.orderBy(Object.values(filteredTraps), [(trap: any) => trap.name?.toLowerCase()], ["asc"]);
    const tableHasFilters = selectedFilters["brands"]?.length;
    useEffect(() => {
        Promise.all([getTraps({ type: types.applications.management }), getTrapTemplates()]).then((response: any) => {
            const templatesToBrands: any = {};
            Object.values(response[1] || {}).forEach((template: any) => {
                const brandNum = template?.userSelections?.brand || null;
                if (!brandNum) {
                    return;
                }
                if (templatesToBrands[brandNum]) {
                    templatesToBrands[brandNum].push(template);
                    return;
                }

                templatesToBrands[brandNum] = [template];
            });
            setTraps(response[0]);
            setTrapTemplatesMap(response[1]);
            setTrapTemplatesTobrands(templatesToBrands);
        });
    }, []);

    useEffect(() => {
        setFilteredTraps(getFilteredTraps(traps));
    }, [selectedFilters, searchValue]);

    useEffect(() => {
      if (_.isEmpty(traps)) {
            return;
        }
      setFilteredTraps(traps);
      updateFiltersVals(Object.values(traps));
    }, [traps]);

    const getBrandName = (value: any) => {
        return value === "all" ? "General" : hvacBrands?.filter((item: any) => item.value === value)[0]?.name;
    };
    const addNewTrap = (data: any, customerId: string) => {
        return addTrap({ data, customerId })
            .then((resp: any) => {
                setTraps({ ...traps, [resp.id]: { ...resp } });
            })
            .catch((error: any) => {

            });
    };
    const trapDelete = (id: string) => {
      deleteTrap({id})
      .then(() => {
        delete traps[id];
        setTraps({ ...traps });
      });

    };

    const updateExistingTrap = (data: any, trapId: string, selectedSystems: any, selectedUnits: any, removeFromList: boolean) => {
        const trapPermissions = traps[trapId].permissions;
        const canUpdate = true;

        const applyTo: any = {};
        if (selectedSystems?.length) {
            applyTo["systemIds"] = selectedSystems;
        }
        if (selectedUnits?.length) {
            applyTo["unitIds"] = selectedUnits;
        }

        const apiCall = canUpdate ? updateTrap({ data, trapId }) : assignToTraps({ id: trapId, data: applyTo });

        return apiCall
            .then((resp: any) => {
                if (removeFromList) {
                    const { [trapId]: _, ...rest } = traps;
                    setTraps(rest);
                }
                else {
                    setTraps({ ...traps, [trapId]: { ...traps[trapId], ...data } });
                }
            })
            .catch((error: any) => {

            });
    };

    const closeDialog = () => {
        setOpenAddRule(false);
        setEditTrap(null);
    };

    const handleSearch = (event: any, column: string) => {
        setSearchColumn(column);
        setSearchAnchor(event.currentTarget);
    };

    const openFilterbrands = (event: any, traps: any) => {
        setAnchor(event.currentTarget);
    };

    const onApply = (appliedFilters: any) => {
        if (typeof appliedFilters === "object" && appliedFilters !== null) {
            setSelectedFilters({ ...selectedFilters, ["brands"]: appliedFilters });
        }
        else {
            setSelectedFilters([]);
        }
        setAnchor(null);
        setSearchAnchor(null);
    };

    const clearAllFilters = () => {
        setSelectedFilters({ brands: [] });
    };

    const updateFiltersVals = (rows: any) => {
        const brandsArray: any = [];
        for (let rowIndex in rows) {
            const { userSelections: { brand } } = rows[rowIndex];
            if (!!brand) { brandsArray.push(getBrandName(brand)); }
        }

        const uniqueBrands = brandsArray.filter((value: any, index: any, self: any) => {
            return self.indexOf(value) === index;
        });
        setBrandsArray(uniqueBrands);
    };

    const getFilteredTraps = (traps: any[]) => {
        function applyFilters(traps: any[]) {
            return _(traps)
                .filter((trap) => {
                    return selectedFilters.brands.length
                        ? selectedFilters.brands.indexOf(getBrandName(trap.userSelections.brand)) > -1
                        : true;
                }).filter((trap) => {
                    return searchValue?.length
                        ? (trap["name"].toLowerCase().indexOf(searchValue.toLowerCase()) > -1 || trap["description"].toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
                        : true;
                })
                .value();
        }
        const filteredRows = applyFilters(traps);
        updateFiltersVals(filteredRows);
        return filteredRows;
    };

    if (!isInitialized) { return <Loading />; }
    return (
        <div className={classes.view}>
            <ServiceNavigationBar {...props} />
            <div className={classes.contentArea}>
                <Header hideSiteSelection hideSystemSelection hideUnitSelection path={["Anomaly Rules"]}
                    onSearch={(value: string) => setSearchValue(value)} onClear={() => setSearchValue("")} searchValue={searchValue} />
                <div className={classes.titleBar}>
                    <Button
                        onClick={() => setOpenAddRule(true)}
                    >
                        {t`Add Rule`}
                    </Button>
                </div>
                <Paper elevation={0} className={classes.paperTableContainer}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                        onClick={(e: any) => handleSearch(e, t`name`)}
                                    >
                                        {t`Rule Name`}
                                    </TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`Customer`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                        onClick={(e: any) => openFilterbrands(e, sortedTraps)}
                                    ><div className={classes.filterIconContainer}>{t`BRAND`}
                                            <FilterList
                                                className={clsx(classes.filterStyle, {
                                                    [classes.blueFilter]: !_.isEmpty(selectedFilters.brands)
                                                })}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                        onClick={(e: any) => handleSearch(e, t`description`)}
                                    >
                                        {t`Description`}
                                    </TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`EDIT`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`REMOVE`}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedTraps.map((trap: any) => {
                                    const { id, name, customer, userSelections: { brand = -99 } = {}, description, permissions: { canDelete = true } = {} } = trap;
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={id}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                                align="left"
                                            >
                                                {name}
                                            </TableCell>
                                            <TableCell
                                                classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                                align="left"
                                            >
                                                {getCustomerName(customer)}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                                align="left"
                                            >
                                                {getBrandName(brand)}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                                align="left"
                                            >
                                                {description}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                                <LightTooltip title="Edit anomaly">
                                                    <IconButton
                                                        disableRipple
                                                        className={classes.editIcon}
                                                        onClick={() => setEditTrap(trap)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                                <Delete
                                                    disabled={!canDelete}
                                                    type={t`anomaly`}
                                                    object={trap}
                                                    detach={() => trapDelete(id)}
                                                    buttonClass={classes.deleteIcon}
                                                ></Delete>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                {(openAddRule || editTrap) &&
                    <AddRule
                        close={closeDialog}
                        createRule={addNewTrap}
                        editTrap={editTrap}
                        updateRule={updateExistingTrap}
                        trapTemplatesToBrands={trapTemplatesToBrands}
                        trapTemplatesMap={trapTemplatesMap}
                    />
                }
            </div>
    {lookupAnchor && (
                <Lookup
                    filtersList={brandsArray}
                    appliedFilters={selectedFilters.brands}
                    onApply={onApply}
                    lookupAnchor={lookupAnchor}
                    onClose={() => setAnchor(null)}
                    tableHasFilters={tableHasFilters}
                    clearAllFilters={clearAllFilters}
                />
            )}
    {searchAnchor && (
                <TableColumnSearch
                    searchColumn={searchColumn}
                    searchValues={searchValues}
                    anchor={searchAnchor}
                    setSearchAnchor={setSearchAnchor}
                    setSearchValues={setSearchValues}
                />
            )}
        </div>
    );
};

export default TrapsList;
