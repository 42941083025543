import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { t } from "ttag";
import daysListStyles from "./daysList.style";

const daysS: any = {
    Friday: "Fri",
    Monday: "Mon",
    Saturday: "Sat",
    Sunday: "Sun",
    Thursday: "Thu",
    Tuesday: "Tue",
    Wednesday: "Wed"
  };

const DaysList = (props: any) => {
  const { days, activeDays, action, disablePointer = false, isMobile = false} = props;
  const useStyles = makeStyles(daysListStyles);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {days.map((day: any, index: number) => {
        const isActive = activeDays.indexOf(day) > -1;
        return (
          <div
            className={clsx(classes.dayStyle, {[classes.mobileDayStyle]: isMobile,
                                               [classes.activeDayStyle]: isActive && !isMobile,
                                               [classes.mobileActiveDay]: isActive && isMobile,
                                               [classes.disableClickPointer]: disablePointer})}
            key={index}
            onClick={() => action(day)}
          >
            <Typography className={clsx({[classes.dayTypo]: isMobile})}>{t`${daysS[day]}`}</Typography>
          </div>
        );
      })}
    </div>
  );
};

export default DaysList;
