import {
    Dialog,
    FormControl,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import ArrowIcon from "../../icons/BigArrowUp";
import { EditIcon } from "../../logos";
import { IGroup } from "../../models/Groups";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnit } from "../../models/Units";
import { IZone } from "../../models/Zones";
import NewApplySettings from "../Settings/NewApplySettings";
import useStyle from "./OneTenant.style";

const TenantList: React.FC = (props: any) => {
    const classes = useStyle();
    const customers = useStoreState((s) => s.customers.allCustomers);
    const sites = useStoreState((s) => s.sites.allSites);
    const getUnitsBy = useStoreState((s) => s.units.getUnitsBy);
    const saveNewZone = useStoreActions((action) => action.zones.createZone);
    const allZones = useStoreState((s) => s.zones.allZones);
    const delZone = useStoreActions((action) => action.zones.deleteZone);
    const updateZone = useStoreActions((action) => action.zones.updateZone);
    const updateUnitZones = useStoreActions((action) => action.units.storeUpdateUnitsZones);
    const getZonesByCustomerId = useStoreActions((action) => action.zones.getZonesBySiteId);
    const getZones = useStoreActions((action) => action.zones.getZones);
    const [allTenants, setAllTenants] = useState<any>({});
    const [tenants, setTenants] = useState<any>([]);
    const [tenantName, setTenantName] = useState("");
    const [tenantId, setTenantId] = useState("");
    const [showOneTenant, setShowOneTenant] = useState(false);
    const [chosenCustomer, setChosenCustomer] = useState<any>("");
    const [chosenSite, setChosenSite] = useState<string>("");
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isMissingName, setIsMissingName] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);

    const isInitialized = useStoreState((s) => s.isInitialized);
    const [unitsToSave, setUnitsToSave] = useState<any>([]);
    const [oneCustomers, setOneCustomers] = useState<any>(null);
    const selections = useStoreState((s) => s.selections.selections);
    const oneTenantCallBack = useCallback(() => oneTenant(), [unitsToSave, tenantName, showOneTenant, isMissingName]);

    useEffect(() => {
        (async function() {
            if (refresh) {
                const zones = await getZones();
                setAllTenants(zones);
                if (selections.siteId) {
                    onFilterSelect(selections.siteId, "site");
                }
                setRefresh(false);
            }
        })();
    }, [refresh]);
    // Load site if was already chosen elsewhere
    useEffect(() => {
        (async function() {
            const zones = await getZones();
            setAllTenants(zones);
            if (selections.siteId) {
                onFilterSelect(selections.siteId, "site");
            }
            return () => {
                setChosenSite("");
            };
        })();
    }, [chosenSite]);

    if (!isInitialized) { return <Loading />; }

    // new \edit Tenant unit lists and titles
    const createCheckedUnitList = (units: any) => {
        setUnitsToSave([]);
    };

    // Set the headers in the tenant page && the units to be shown
    const setOneTenantsData = (queryType: any, id: any) => {

        // set subtitle - by site
        if (queryType === "site") {
            // set customer on chosen site
            if (sites[id].customer !== chosenCustomer) {
                setChosenCustomer(sites[id].customer);
                getZonesByCustomerId(id);
                setOneCustomers(customers[sites[id].customer]);
            }
            setChosenSite(id);
        }

        // All units tenant can connect to
        const filterUnits = getUnitsBy(queryType, id, {
            type: "all"
        });

        // checked unit state on edit\new
        createCheckedUnitList(filterUnits);

    };

    // Filter logic and tenant list
    const getTenantsByFilter = () => {
        if (allZones) {
            const tenantGroups = _.filter(allZones, (tenant: any) => tenant.site === selections.siteId);
            setTenants(tenantGroups);
        }

    };

    const onFilterSelect = (id: string | null, queryType: any) => {
        if (!id) {
            getTenantsByFilter();
            setChosenSite("");
            return;
        }

        if (queryType === "customer") {
            setChosenSite("");
            return;
        }

        getTenantsByFilter();
        setOneTenantsData("site", id);

    };

    const onSave = async () => {
        if (!tenantName) {
            setIsMissingName(true);
            return;
        }
        setIsMissingName(false);

        const data = {
            name: tenantName,
            units: [...unitsToSave],
            description: ""
        };

        if (!isEditing) {
            const newZone = await saveNewZone({ siteId: chosenSite, data });
            // Update all units' groups data
            _.forEach(unitsToSave, (unitId) => {
                updateUnitZones({ unitId, zone: newZone, add: true });
            });
        } else {
            const updatedZone = await updateZone({ id: tenantId, data });
            _.forEach(unitsToSave, (unitId) => {
                updateUnitZones({ unitId, zone: updatedZone, add: true });
            });
            setIsEditing(!isEditing);
        }

        setShowOneTenant(false);
        // reseting one Tenant page
        setTenantName("");

        // Reset checked units, while leaving selected units on tenant edit\new page
        setUnitsToSave([]);
        setRefresh(true);
    };

    const onDelete = async (payload: any) => {
        const unitsToUpdate = [..._.find(allTenants, { id: payload.id }).units];
        let message = null;
        await delZone(payload.id)
            .then(() => { setRefresh(true); })
            .catch((e: any) => {
                // console.log('something went wrong', e.message);
                message = e.message;
            });

        // Update all units' groups data
        _.forEach(unitsToUpdate, (unitId) => {
            updateUnitZones({ unitId, zone: { id: payload.id }, add: false });
        });

        return message;
    };

    const onEdit = (tenant: IZone) => {
        setIsEditing(!isEditing);
        setTenantId(tenant.id);
        const siteId = chosenSite;
        setTenantName(tenant.name ? tenant.name : "");
        setChosenCustomer(sites[siteId].customer);

        setOneCustomers(customers[sites[siteId].customer]);
        setUnitsToSave(tenant.units);
        setShowOneTenant(true);
    };

    const onCancle = () => {
        if (isMissingName) {
            setIsMissingName((false));
        }

        if (isEditing) {
            setIsEditing(false);
        }

        setShowOneTenant(false);
        setTenantName("");
        // Reset checked units, while leaving selected units on tenant edit\new page
        setUnitsToSave([]);
    };

    const tenantList = () => {
        return (
            <Grid container={true} className={classes.usersContainer}>
                <TableContainer className={classes.tableContainer}>
                    <Paper elevation={0} className={classes.paperTableContainer}>
                        <Table stickyHeader={true} className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow className={classes.tableHeadRow}>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`ZONE NAME`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`UNITS`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`EDIT`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`REMOVE`}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(Object.values(tenants), (zone: IZone, index: number) => (
                                    <TableRow
                                        hover={true}
                                        tabIndex={-1}
                                        key={index}
                                        classes={{ root: classes.overWritePadding }}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            classes={{ root: classes.overWritePadding }}
                                            align="left"
                                        >
                                            {zone.name}
                                        </TableCell>
                                        <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                            {zone?.units.length}
                                        </TableCell>
                                        <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                            <IconButton disableRipple onClick={() => { onEdit(zone); }} className={classes.overWriteIcon}>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                            <Delete
                                                type={t`Tenant`}
                                                object={zone}
                                                detach={onDelete}
                                                buttonClass={classes.deleteIcon}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </TableContainer>
            </Grid>
        );
    };

    const handleAgree = (units: any) => {
        setUnitsToSave(units);
    };

    const oneTenant = () => {
        return (
            <Dialog
                open={showOneTenant}
                onClose={onCancle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                classes={{ paper: classes.dialog }}
            >
            <div className={classes.dialogHeader}>
              <Typography className={classes.headerTitle}>{t`Zone`}</Typography>
              <IconButton disableRipple className={classes.iconBtnStyle} onClick={onCancle}><Close color="#7f7692" /></IconButton>
            </div>

                <div className={classes.dialogContentZone}>
                    <Grid item={true} xs={4} className={classes.nameContainer}>
                        <Typography
                            className={classes.headerStyle}
                        >{t`Zones`}</Typography>
                        <TextField id="outlined-basic"
                            label="Zones Name"
                            variant="outlined"
                            classes={{root: classes.textFieldValue}}
                            value={tenantName}
                            error={isMissingName}
                            helperText={!isMissingName ? "" : "need zone name to save"}
                            onChange={(e: any) => {
                                if (isMissingName) {
                                    setIsMissingName((false));
                                }
                                setTenantName(e.target.value);
                            }} />
                    </Grid>
                    <Grid item={true} xs={8}>
                        <Paper className={classes.rightCard} elevation={0}>
                            <Typography className={classes.headerStyle}>{t`Unit selection`}</Typography>
                            <FormControl component="fieldset" className={classes.unitListContainer}>
                                <div className={classes.tenantUnitLists} >
                                    <NewApplySettings
                                        save={handleAgree}
                                        units={unitsToSave}
                                        noPopup={true}
                                        oneCustomer={oneCustomers}
                                        oneSite={chosenSite}
                                        allTenants={allTenants}
                                        tenantId={tenantId}
                                        useServiceUnits
                                        showServiceUnits
                                    />
                                </div>

                            </FormControl>
                        </Paper>
                    </Grid>

                    </div>

                        <div className={classes.actionsHolder}>
                        <Button
                        white
                        uppercase
                        width={150}
                        marginRight
                        onClick={onCancle}>
                        {t`Cancel`}
                        </Button>
                            <Button
                            uppercase
                            width={150}
                                onClick={() => {
                                    onSave();
                                }}>
                                {t`Save`}
                            </Button>
                        </div>
            </Dialog>
        );
    };

    const onNoFilters = () => {
        return (
            <Grid container={true} direction={"column"} className={classes.noContentContainer}>
                <div className={classes.grayOval}>
                    <ArrowIcon className={classes.arrowIcon} />
                </div>
                <Typography className={classes.noUnitText}>
                    {t`Please select a site using the above filters.`}
                </Typography>
            </Grid>
        );
    };

    const btnTitle = !showOneTenant ? t`Add New Zone` : t`Back to Zones List`;

    return (
        <div className={classes.view}>
            <ServiceNavigationBar {...props} />
            <div className={classes.contentArea}>
                <Header
                    path={["Power Distribution"]}
                    onSiteSelect={(id) => onFilterSelect(id, "site")}
                    onCustomerSelect={(id) => onFilterSelect(id, "customer")}
                    customGeneralNames={{ site: "select Site" }}
                    hideSystemSelection={true}
                    hideUnitSelection={true}
                    btn={btnTitle}
                    canClickBtn={!!chosenSite}
                    btnFc={() => {
                        setShowOneTenant(true);
                        if (isMissingName) {
                            setIsMissingName((false));
                        }
                    }}
                />

                {!showOneTenant && !!tenants.length && chosenSite && tenantList()}
                {showOneTenant && oneTenantCallBack()}
                {!chosenSite && onNoFilters()}
            </div>
        </div>
    );
};

export default TenantList;
