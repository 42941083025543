import { Button, Typography } from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { t } from "ttag";
import packageJSON from "../../../package.json";
import { Anomalies as SvgAnomalies, SupportTicket } from "../../icons/";
import SvgAudit from "../../icons/Audit";
import SvgControl from "../../icons/Control";
import SvgDashboard from "../../icons/Dashboard";
import SvgExpandLesse from "../../icons/ExpandLess";
import SvgExpandMore from "../../icons/ExpandMore";
import SvgAlerts from "../../icons/Notifications";
import SvgOfflineBoltOutlined from "../../icons/OfflineBoltOutlined";
import SvgSite from "../../icons/Site";
import SvgStatistics from "../../icons/Statistics";
import SvgUser from "../../icons/User";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ReportProblem from "../../screens/ReportProblem/ReportProblem";
import assetsService from "../../services/assetsService";
import NavigationMenuItem from "./NavigationMenuItem";
import useStyles from "./ServiceNavigationBar.style";

const ExpandMore = () => <SvgExpandMore key="icon-expand-1" style={{ marginTop: -2 }} />;
const ExpandLess = () => <SvgExpandLesse key="icon-expand-2" style={{ marginTop: -2 }} />;

const ServiceNavigationBar: React.FC<RouteComponentProps & any> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dropDownNavigation = useStoreState((s) => s.isDropDownOpen);
  const setDropDownNavigation = useStoreActions((a) => a.setDropDownNavigation);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const selectedUnitId = useStoreState((s) => s.selections.selections.unitId);
  const defaultUnitId = useStoreState(
    (s) => s.units.allUnits[Object.keys(s.units.allUnits)[0]]?.id
  );
  const displayFlags = useStoreState((state) => state.users.displayFlags);
  const [openReport, setOpenReport] = useState<boolean>(false);

  const goToDashboardLogo = () => {
    history.push("/dashboard");
  };

  const Play = () => <PlayArrow className={classes.playIcon} />;
  return (
    <div className={classes.navigationBarContainer}>
      <Button
        aria-label="app logo"
        className={classes.navLogo}
        onClick={goToDashboardLogo}
        classes={{ label: classes.label }}
      >
        <img src={assetsService.getResourcePath("logo.png")} className={classes.navIcon} />
      </Button>
      <div className={classes.itemsContainer}>

        <NavigationMenuItem
          Icon={SvgDashboard}
          name={t`Dashboard`}
          path={"/dashboard"}
          onNavigate={() => {
            goToDashboardLogo();
          }}
          disabled={!displayFlags.enableDashboard}
        />
        <NavigationMenuItem
          Icon={SvgControl}
          name={t`Control`}
          path={"/control"}
          disabled={!displayFlags.enableControl}
        />
        <NavigationMenuItem
          Icon={SvgStatistics}
          name={t`Unit Diagnostics`}
          path={`/unit-diag/${selectedUnitId ?? selectedUnitId ?? ""}`}
          disabled={false}//!displayFlags.enableUnitDiagnostics}
        />

        <NavigationMenuItem
          Icon={SvgStatistics}
          name={t`Unit Statistics`}
          path={`/unit-stats/${selectedUnitId ?? selectedUnitId ?? ""}`}
          disabled={!displayFlags.enableUnitStats}//!displayFlags.enableUnitDiagnostics}
        />

        <NavigationMenuItem
          Icon={SvgAnomalies}
          name={t`Anomaly Rules`}
          path={"/anomalies-settings"}
        // disabled={!displayFlags.enableAnomalies}
        />
        <NavigationMenuItem
          Icon={SvgSite}
          name={t`Site Management`}
          path={"/site-management"}
          disabled={!displayFlags.enableSiteManagement}
        />
        <NavigationMenuItem
          Icon={SvgUser}
          name={t`User Management`}
          path={"/user"}
          disabled={!displayFlags.enableUserManagement}
        />
        <NavigationMenuItem Icon={SvgAlerts} name={t`Alert Log`} path={"/alerts"} disabled={!displayFlags.enableAlertLog} />
        <NavigationMenuItem Icon={SvgAudit} name={t`Audit Log`} path={"/audits"} disabled={!displayFlags.enableAuditLog} />

        <NavigationMenuItem
          disabled={!displayFlags.enableSettings}
          Icon={SvgStatistics}
          name={t`Settings`}
          path={`/settings/restricts`}
          endIcon={[ExpandMore, ExpandLess]}
          dropdown={[
            {
              name: `Unit App Controls`,
              path: "/settings/unit"
            },
            {
              name: "WRC Restrictions",
              path: "/settings/restricts"
              //isDisabled: "true"
            },
            {
              name: `Groups`,
              path: "/settings/groups"
            },
            {
              name: `Notifications`,
              path: "/settings/alerts"
            }
          ]}
          showDropDown={dropDownNavigation.settings}
          setShowDropDown={() => setDropDownNavigation("settings")}

        />
        {
          displayFlags.enablePowerDistribution ? <NavigationMenuItem
            disabled={!displayFlags.enablePowerDistribution}
            Icon={SvgOfflineBoltOutlined}
            name={t`Power Distribution`}
            path={`/PowerDistributionFalse`}
            endIcon={[SvgExpandMore, SvgExpandLesse]}
            dropdown={[
              {
                name: "Usage Analysis",
                path: "/tenantReports"
              },
              {
                name: `Zones Setting`,
                path: "/PowerDistribution"
              },
              {
                name: "Report Scheduler",
                path: "/scheduler"
              }]}
            showDropDown={dropDownNavigation.powerDistribution}
            setShowDropDown={() => setDropDownNavigation("powerDistribution")}

          /> : <></>
        }
        <NavigationMenuItem
          Icon={SupportTicket}
          name={t`Open Support Ticket`}
          path={""}
          onClick={() => setOpenReport(true)}
        />

        {openReport && (
          <ReportProblem openReport onClose={() => setOpenReport(false)} />
        )}
      </div>
      {/*<NavigationMenuItem
        Icon={Play}
        name={t`Commissioning`}
        path={`/commissioning`}
        // disabled={!displayFlags.enableSettings}
      /> */}

      <Typography variant="caption" className={classes.versionText}>
        Version {packageJSON.version}
      </Typography>
    </div>
  );
};

export default ServiceNavigationBar;
