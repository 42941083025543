import _ from 'lodash';
import { Action, action, Thunk, thunk } from 'easy-peasy';
import coolremoteSDK from 'coolremote-sdk';
import { IRootStoreModel } from '../RootStore';
import { ISite } from '../Sites';
import { IDevice } from '../Devices';

export interface IDeviceActions {
  createDevice: Thunk<
    IRootStoreModel,
    { siteId: string; newDevicePin: string; newDeviceId: string },
    any,
    IRootStoreModel
  >;
  deleteDevice: Thunk<IRootStoreModel, { id: string }, any, IRootStoreModel>;
  refreshDeviceTree: Thunk<IRootStoreModel, { id: string }>;
  handleDeviceConnection: Thunk<IRootStoreModel, { id: string }>;
  refreshDevice: Thunk<IRootStoreModel, { id: string }>;
  _storeCreateDevice: Action<IRootStoreModel, any>;
  _storeDeleteDevice: Action<IRootStoreModel, { id: string }>;
  _storeRefreshDeviceTree: Action<IRootStoreModel, { deviceTree: any }>;

  //   _storeUpdateDeviceConnection: Action<
  //   IRootStoreModel,
  //   { deviceId: string; connectionStatus: any }
  // >;
}

export const deviceActions: IDeviceActions = {
  createDevice: thunk(async (actions, payload, store) => {
    const deviceData = {
      pin: payload.newDevicePin,
      serial: payload.newDeviceId
    };
    const newDeviceData = await coolremoteSDK.Site.createDevice(payload.siteId, deviceData);

    actions._storeCreateDevice(newDeviceData);

    return newDeviceData;
  }),

  deleteDevice: thunk(async (actions, payload, store) => {
    await coolremoteSDK.Device.delete(payload.id);

    actions._storeDeleteDevice(payload);
  }),

  refreshDeviceTree: thunk(async (actions, payload) => {
    const deviceTree = await coolremoteSDK.Device.fetchTree(payload.id);

    actions._storeRefreshDeviceTree({ deviceTree });
  }),
  handleDeviceConnection: thunk(async (actions, payload) => {
    // Update unit from DB - it should already have isConnected set to true
    const data = await coolremoteSDK.Device.fetchTree(payload.id);

    actions._storeRefreshDeviceTree({ deviceTree: data });
  }),
  refreshDevice: thunk(async (actions, payload) => {
    // Update unit from DB - it should already have isConnected set to true
    const data = { hard: false };
    await coolremoteSDK.Device.refresh(payload.id, data);
    const device = await coolremoteSDK.Device.fetchTree(payload.id);
    // return device;
    actions._storeRefreshDeviceTree({ deviceTree: device });
  }),
  // _storeUpdateDeviceConnection: action((state, payload) => {
  //   if (state.devices.allDevices[payload.deviceId]) {
  //     // state.allDevices[payload.deviceId] = payload.updatedSystemData;
  //   }
  // }),
  _storeCreateDevice: action((state, payload) => {
    const newDeviceData: any = payload;
    const units: string[] = newDeviceData.units ? Object.keys(newDeviceData.units) : [];
    const systems: string[] = newDeviceData.systems ? Object.keys(newDeviceData.systems) : [];
    const newDevice: IDevice = { ...newDeviceData, units, systems };

    _.forEach(newDeviceData.units, (value, key) => {
      state.units.allUnits[key] = value;
    });

    _.forEach(newDeviceData.systems, (value, key) => {
      state.systems.allSystems[key] = value;
    });

    state.devices.allDevices[newDevice.id] = newDevice;

    const site: ISite | undefined = state.sites.allSites[newDeviceData.site];

    if (!_.isUndefined(site)) {
      if (site.devices.indexOf(newDeviceData.id) === -1) {
        site.devices.push(newDeviceData.id);
      }
    }
  }),

  _storeDeleteDevice: action((state, payload) => {
    const device: IDevice | undefined = state.devices.allDevices[payload.id];

    if (!_.isUndefined(device)) {
      if (state.sites.allSites[device.site]) {
        _.pull(state.sites.allSites[device.site].devices, device.id);
      }

      delete state.devices.allDevices[device.id];

      _.forEach(device.systems, systemId => {
        delete state.systems.allSystems[systemId];

        // Reset systems selection if needed
        if (state.selections.selections.systemId === systemId)
          state.selections.selections.systemId = null;
      });

      _.forEach(device.units, unitId => delete state.units.allUnits[unitId]);
    }
  }),

  _storeRefreshDeviceTree: action((state, payload) => {
    // Assign new systems or system changes.
    _.forEach(state.systems.allSystems, (value, key) => {
      if (value.device === payload.deviceTree.id) {
        delete state.systems.allSystems[key];
      }
    });
    _.forEach(payload.deviceTree.systems, (value, key) => (state.systems.allSystems[key] = value));

    // Assign new units or unit changes.
    _.forEach(state.units.allUnits, (value, key) => {
      if (value.device === payload.deviceTree.id) {
        delete state.units.allUnits[key];
      }
    });
    _.forEach(payload.deviceTree.units, (value, key) => {
      // Assign new unit compressors or unit compressor changes.
      _.forEach(state.compressors.allCompressors, (compressor, compressorId) => {
        if (compressor.unit === key) {
          delete state.compressors.allCompressors[compressorId];
        }
      });
      if (value.compressors) {
        _.forEach(
          value.compressors,
          (_value, _key) => (state.compressors.allCompressors[_key] = _value)
        );
        value.compressors = Object.keys(value.compressors);
      }

      state.units.allUnits[key] = value;
    });
    // Assign device changes
    const units = payload.deviceTree.units ? Object.keys(payload.deviceTree.units) : [];
    const systems = payload.deviceTree.systems ? Object.keys(payload.deviceTree.systems) : [];
    const newDevice: IDevice = { ...payload.deviceTree, units, systems };

    state.devices.allDevices[payload.deviceTree.id] = newDevice;
  })
};
