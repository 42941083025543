import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((darkTheme: Theme) =>
  createStyles({
    buttonLogin: {
      minHeight: "53px",
      width: "100%",
      marginBottom: "10px"
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "90%",
      margin: "1rem"
    },
    resetButtonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "1rem 0"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100vw",
      alignItems: "center",
      maxWidth: "500px"
    },
    fieldContainer: {
      display: "flex",
      flexDirection: "row",
      width: "90%",
      "& >div": {
        width: "100%"
      },
      "& >div>div": {
        width: "100%"
      }
    },
    content: {
      width: "640px",
      borderRadius: "6px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },

    Login: {
      background: "white",
      MozFlexDirection: "column",
      flexDirection: "column",
      height: "100vh",
      display: "flex",
      fontSize: "1.5rem",
      color: "#29abe2"
    },

    logos: {
      justifyContent: "center",
      display: "flex",
      MozFlexDirection: "column",
      flexDirection: "column",
      alignItems: "center"
    },

    title: {
      fontSize: "22px",
      color: "rgb(255, 255, 255)",
      paddingBottom: "20px",
      paddingTop: "40px",
      lineHeight: "normal",
      letterSpacing: "normal"
    },

    // input: {
    //   height: "53px",
    //   width: "354px",
    //   borderRadius: "4px",
    //   fontFamily: "roboto",
    //   // fontSize: "15px",
    //   marginBottom: "10px",
    //   backgroundColor: "rgb(96, 56, 94)",
    //   color: "rgba(255, 255, 255, 0.7)"
    // },

    bottom: {
      height: "48px",
      opacity: 0.6,
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.5",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff"
    },

    mailto: {
      color: "rgba(255, 255, 255, 0.9)"
    },

    button: {
      height: "53px",
      width: "100%",
      borderRadius: "4px",
      fontFamily: "roboto",
      fontWeight: "bold",
      fontSize: "15px",
      margin: "0",
      marginBottom: "10px",
      backgroundColor: "rgb(240, 83, 71)",
      color: "rgb(255, 255, 255)",
      "&:hover": {
        backgroundColor: "rgb(240, 83, 71)"
      }
    },

    splashScreen: {
      width: "100%",
      height: "100%",
      position: "fixed",
      background: "rgba(41, 19, 46, 0.95)",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      zIndex: 100
    },

    logo: {
      paddingBottom: "2rem",
      paddingTop: "2rem",
      width: "80%",
      maxWidth: "600px"
    },

    logoTitle: {
      height: "32px",
      width: "206px",
      paddingBottom: "40px"
    },

    forgotPassLink: {
      boxSizing: "border-box",
      color: "rgb(31, 44, 50)",
      cursor: "pointer",
      display: "inline",
      fontSize: "15px",
      fontWeight: 300,
      listStyleImage: "none, outside, none",
      outline: "rgb(31, 44, 50), none, 0px",
      padding: "5px, 12.5px, 12.5px, 12.5px",
      textAlign: "left",
      textDecoration: "none",

      textSizeAdjust: "100%",
      userSelect: "none",
      WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
      "&:hover": {
        textShadow: "0px 0px 0.5px rgb(31, 44, 50)"
      }
    },

    CoolAutomationName: {
      fontSize: "50px",
      color: "white",
      marginTop: "8rem"
    },

    splashScreenImg: {
      marginTop: "10rem"
    },

    interactionSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      paddingBottom: "15rem",
      "& input": {
        color: "#fff !important"
      }
    },

    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      MozFlex: "1",
      flex: "1",
      backgroundImage: "linear-gradient(rgb(58, 24, 58), rgb(22, 18, 32))"
    },

    forgotPassBtn: {
      color: "rgba(255, 255, 255, 0.7)",
      backgroundColor: "inherit"
    },

    hidden: {
      display: "none"
    },

    newPasswordForm: {
      display: "inline-flex",
      flexDirection: "column"
    },
    buttonReset: {
      minHeight: "53px",
      width: "100%",
      marginBottom: "10px"
    },
    buttonReset1: {
      minHeight: "53px",
      width: "167px",
      marginBottom: "10px"
    },
    buttonBack: {
      minHeight: "53px",
      width: "167px",
      marginBottom: "10px",
      borderRadius: "4px",
      border: "1px solid rgba(255, 255, 255, 0.6)",
      fontFamily: "roboto",
      fontWeight: "bold",
      margin: "0",
      color: "rgb(255, 255, 255)",
      "& span": { fontSize: "14px" }
    },

    resetSuccessContainer: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& p": {
        width: "50%",
        textAlign: "center",
        marginBottom: "40px"
      },
      "& button": {
        backgroundColor: "#f05347",
        color: "#fff",
        fontWeight: 500,
        fontSize: "15px",
        width: "40%",
        "&:hover": {
          backgroundColor: "#f05347"
        }
      }
    },
    reportProblemButton: {
      position: "absolute",
      zIndex: 99999,
      left: "15px",
      bottom: "40px",
      borderRadius: "2rem",
      height: "3.5rem",
      width: "3.5rem",
      minWidth: "3rem",
      "& span": {
        "& svg": {
          width: "3rem",
          height: "3rem"
        }
      }
    }
  })
);
export default useStyles;
