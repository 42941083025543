import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
navBar: {
  position: "absolute",
  bottom: 0,
  width: "100%",
  justifyContent: "space-between",
  height: 73,
  backgroundColor: "#fafafc",
  borderTop: "solid 1px #d5d2d5",
  paddingTop: 5
}
});
export default useStyles;
