import { Action, action, actionOn, ActionOn, debug } from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface ICompressor {
  id: string;
  title: string;
  unit: string;
  workingMinutes: number;
}

export interface ICompressorMap {
  [key: string]: ICompressor;
}

export interface ICompressorsModel {
  allCompressors: ICompressorMap;
  initialize: Action<ICompressorsModel, any>;
  onInitialized: ActionOn<ICompressorsModel, IRootStoreModel>;
}

export const compressorsModel: ICompressorsModel = {
  allCompressors: {},
  initialize: action((state, payload) => {
    state.allCompressors = payload;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      // console.log(target.resolvedTargets);
      // console.log('Got compressors: ', debug(state.allCompressors));
    }
  )
};
