import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    marginTop: "10px",
    marginBottom: "10px",
    border: "1px solid #d5d2d5",
    borderRadius: "6px",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.05)",

    "& div": {
      height: "100%",
      margin: 0,

      boxSizing: "border-box",
      padding: "5px"
    },
    "& div:before": {
      border: "none"
    }
  },
  tablesContainer: {
    maxHeight: "417px",
    minHeight: "fit-content",
    marginBottom: "10px",
    "& label": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "inline-block",
      whiteSpace: "nowrap",
      minWidth: "276px",
      width: "276px",
      minHeight: "unset",
      fontSize: "14px",
      fontFamily: "Roboto",
      fontWeight: "normal",
      lineHeight: "15px"
    }
  },
  arrowIcon: {
    fontSize: "30px",
    top: "unset"
  },
  permissionsContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px"
  },
  tableCellStyle: {
    padding: "0 15px",
    height: "49px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "auto",
    minWidth: "auto",
    maxWidth: "1ch",
    fontSize: "13px"
  },
  tableHeadStyle: {
    padding: "0 15px",
    fontSize: "13px",
    color: "#79797d",
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "normal",
    backgroundColor: "#efeff2",
    "& tr": {
      "& th": {
        padding: "10px"
      }
    },
    border: "none",
    height: "40px"
  },
  sitesContainer: {
    maxHeight: "417px",
    overflow: "auto",
    paddingLeft: "15px"
  },
  grayCheckbox: {
    "&$checked": {
      color: "#736973"
    }
  },
  checkboxCell: {
    padding: "0 15px",
    paddingLeft: "3px",
    height: "49px",
    width: "73px",
    maxWidth: "73px",
    minWidth: "73px"
  },
  collapseHeader: {
    display: "flex",
    flexFlow: "row nowrap",
    minWidth: "100%",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "15px",
    height: "40px"
  },
  collapseTitle: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingRight: "10px",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: "normal",
    lineHeight: "15px"
  },
  tableContainer: {
    display: "flex",
    flex: 1,
    height: "fit-content",
    maxHeight: "377px",
    paddingRight: "15px"
  },
  largerTableContainer: {
    maxHeight: "417px",
    paddingRight: 0
  },
  spanHeight: { height: "16px" },
  matchHeight: { maxHeight: "506px" },
  matchTableHeight: { maxHeight: "414px" }
}));
export default useStyles;
