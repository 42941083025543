import { makeStyles, Theme } from "@material-ui/core";
import {
  Button,
  Checkbox,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListProps,
  MuiMediaQueryList,
  Paper,
  PaperProps,
  Tooltip,
  Typography
} from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import _ from "lodash";
import React, { useState } from "react";

export interface IAuditsFilter {
  [key: string]: boolean;
}

export interface IAuditsFilterProps {
  title: string;
  filters: IAuditsFilter;
  setFilter: (newFilter: IAuditsFilter) => void;
  getFilterName?: (filter: string) => string;
  usersFilter?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  filterHeaderContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "0.5rem",
    borderBottom: "2px solid rgba(60, 60, 60, 0.2)"
  },
  filterHeader: {
    paddingLeft: "0.5rem",
    color: "#4b1c46"
  },
  filterList: {
    height: "100%",
    overflow: "auto"
  },
  limitShownText: {
    "& span": {
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  grayCheckbox: {
    "&$checked": {
      color: "#736973"
    }
  }
}));

export const AuditsFilter: React.FC<IAuditsFilterProps & Partial<PaperProps>> = ({
  title,
  filters,
  setFilter,
  getFilterName,
  usersFilter,
  ...rest
}) => {
  const styles = useStyles();
  const [allToggled, setAllToggled] = useState(true);

  const handleToggle = (filterId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilter: IAuditsFilter = { ...filters, [filterId]: !!event.target.checked };
    setFilter(newFilter);
  };

  const handleToggleUser = (filterId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const {target: {checked}} = event;
    let newFilter: IAuditsFilter = {};
    if (filters.all && !checked){
      newFilter = { ...filters, [filterId]: !!event.target.checked, all: false };
    } else {
      newFilter = { ...filters, [filterId]: !!event.target.checked };
    }
    setFilter(newFilter);
  };

  const handleToggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilter: IAuditsFilter = {};
    for (let key of Object.keys(filters)) {
      if (key !== "all"){
        newFilter[key] = !allToggled;
      }
    }
    setFilter(newFilter);
    setAllToggled(!allToggled);
  };

  const handleToggleAllInUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {target: {checked}} = event;
    const newFilter: IAuditsFilter = {};
    for (let key of Object.keys(filters)) {
        newFilter[key] = checked;
    }
    setFilter(newFilter);
  };

  const filterKeys = Object.keys(filters);
  const sortedFilterKeys = filterKeys.sort((a: string, b: string) => {
    if (getFilterName) {
      a = getFilterName(a);
      b = getFilterName(b);
    }
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });

  let isIndeterminate = false;
  let sameKey: boolean | undefined;
  for (let key of filterKeys) {
    if (_.isUndefined(sameKey)) {
      sameKey = filters[key];
    } else if (sameKey !== filters[key]) {
      isIndeterminate = true;
      break;
    }
  }

  return (
    <Paper {...rest}>
      <div className={styles.filterHeaderContainer}>
        <Typography variant="h6" className={styles.filterHeader}>
          {title}
        </Typography>
      </div>
      <List disablePadding={true} className={styles.filterList}>
        {!usersFilter && <ListItem dense autoFocus divider key={"all"}>
          <ListItemText id={"all"} primary={` All ${title}`} />
          <ListItemSecondaryAction>
            <Checkbox
              color="default"
              edge="end"
              onChange={handleToggleAll}
              checked={allToggled}
              indeterminate={isIndeterminate}
              inputProps={{ "aria-labelledby": `toggle all ${title}` }}
              classes={{root: styles.grayCheckbox}}
            />
          </ListItemSecondaryAction>
        </ListItem>}
        {_.map(sortedFilterKeys, (key, id) => {
          if (key === "all"){
            return (
              <ListItem dense autoFocus divider key={"all"}>
                <ListItemText id={"all"} primary={` All Users`} />
                <ListItemSecondaryAction>
                  <Checkbox
                    color="default"
                    edge="end"
                    onChange={handleToggleAllInUsers}
                    checked={filters[key]}
                    indeterminate={isIndeterminate}
                    inputProps={{ "aria-labelledby": `toggle all ${title}` }}
                    classes={{root: styles.grayCheckbox}}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          }
          return (
          <ListItem dense autoFocus divider key={id}>
            <Tooltip title={getFilterName ? getFilterName(key) : key} placement="top-start">
              <ListItemText className={styles.limitShownText} id={`${id}`} primary={getFilterName ? getFilterName(key) : key} />
            </Tooltip>
            <ListItemSecondaryAction>
              <Checkbox
                color="default"
                edge="end"
                onChange={usersFilter ? handleToggleUser(key) : handleToggle(key)}
                checked={filters[key]}
                inputProps={{ "aria-labelledby": key }}
                classes={{root: styles.grayCheckbox}}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ); })}
      </List>
    </Paper>
  );
};
