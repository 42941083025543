const roundTo2Places = (num: number) => {
  return Math.round(num * 100) / 100;
};

export const toC = (tempInF: number) => {
  const temp = (tempInF - 32) * (5 / 9);
  return roundTo2Places(temp);
};

export const toF = (tempInC: number) => {
  const temp = tempInC * 9 / 5 + 32;
  return roundTo2Places(temp);
};

export const toPSI = (presInKgPCm: number) => {
  const pres = presInKgPCm * 14.2233;
  return roundTo2Places(pres);
};

export const toKgPerCm2 = (presInPSI: number) => {
  const pres = presInPSI * 0.070307;
  return roundTo2Places(pres);
};
