import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    // height: '-webkit-fill-available',
    width: "100vw",
    height: "100vh",
    background: "rgb(236, 235, 239)"
    // overflow: 'auto'
  },
  // // ----
  // headMenu: {
  //   backgroundColor: '#3c2840'
  // },
  // headMenuTitle: {
  //   fontSize: '1.5rem',
  //   fontWeight: 'bold',
  //   color: 'white',
  //   // marginBottom: '1.5rem',
  //   width: '100%',
  //   // lineHeight: '3rem',
  //   // borderBottom: '1px rgb(87, 69, 92) solid
  //   padding: '1.5rem',
  //   boxSizing: 'border-box'
  // },
  // headMenuContent: {},
  // ----
  content: {
    padding: "1rem 1rem",
    height: "100%",
    overflow: "hidden",
    maxHeight: "calc(100vh - 160px)"
  },
  contentArea: {
    // overflow: 'auto',
    display: "flex",
    flexDirection: "column",
    width: "100%"
    // height: '-webkit-fill-available'
  },
  // ---
  mapArea: {
    maxHeight: "calc(100vh - 154px)"
  },
  // ---
  tablesArea: {
    maxHeight: "calc(100vh - 85px)"
  },
  tablesContainer: {
    // width: '100%',
    // height: '100%',
    height: "calc(100vh - 100px)",
    flexWrap: "nowrap"
    // overflow: 'hidden'
  },
  // ---
  HVACContainer: {
    width: "100%",
    height: "45%",
    maxHeight: "45%",
    flexGrow: 1
    // paddingBottom: "2.5rem"
  },
  HVACCard: {
    height: "100%",
    padding: "1.25rem",
    display: "flex",
    position: "relative"
  },
  // ---
  alertsContainer: {
    width: "100%",
    height: "55%",
    maxHeight: "55%",
    flexGrow: 2,
    overflow: "hidden",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column"
  },
  errorLogsCard: {
    boxSizing: "border-box",
    maxHeight: "calc(100% - 220px)",
    // maxHeight: '65%',
    // height: '65%',
    // fallbacks: [
    //   { height: '-moz-available' }
    // ],
    height: "100%",
    padding: "0.85rem 1.25rem 0"
    // paddingBottom: '160px'
  },
  googleCard: {
    padding: "1.25rem"
  },
  listItem: {
    display: "block",
    fontSize: "0.75rem",
    color: "rgb(84, 89, 100)",
    paddingLeft: 0
  },
  detailsSectionSecondTitle: {
    display: "inline-block",
    fontSize: "0.875rem",
    fontWeight: "bold",
    padding: "0 0 1rem 0",
    color: "rgb(75, 28, 70)"
  },

  HVAC: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY : "auto"
  },
  operationalView: {
    paddingLeft: "30px",
    height: "100%",
    overflow : "auto"
  },
  fullHeight: {
    height: "100%"
  }
}));

export default useStyles;
