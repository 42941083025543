import * as React from "react";

function SvgControlMobile(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect
          width={16}
          height={24}
          x={5}
          y={1}
          stroke={props.color || "#AAA2AA"}
          strokeWidth={2}
          rx={3}
        />
        <path
          d="M14.095 20.96c0 .62-.5 1.118-1.116 1.118a1.116 1.116 0 111.115-1.118"
          fill={props.color || "#AAA2AA"}
        />
      </g>
    </svg>
  );
}

export default SvgControlMobile;
