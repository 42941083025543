import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  view: {
    display: "flex",
    height: "100%",
    flex: 1,
    minHeight: "100%",
    backgroundColor: "#fff",
    flexFlow: "column nowrap",
    justifyContent: "space-between"
  },
  contentArea: {
    width: "100%",
    maxWidth: "calc(100% - 240px)",
    height: "calc(100% - 95px)",
    maxHeight: "calc(100% - 95px)",
    minWidth: "100%",
    background: "white"
  },

  loadingContainer: {
    height: "100%",
    background: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },

  headerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 1rem",
    height: "70px",
    background: theme.palette.background.default
  },

  wrapper: {
    /* 130px = headerMenu(70px) + headerContainer(60px) */
    height: "calc(100vh - 220px)",
    padding: "0 1rem 1rem",
    background: "rgb(236, 235, 239)",
    flexFlow: "row nowrap"
    // display: 'grid'
    // width: 'auto'
  },
  // buttonContainer: {
  //   justifySelf: 'flex-end',
  //   padding: '0 0 1rem'
  // },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#29132e"
  },

  // ---- Audits filters
  filterContainer: {
    height: "100%",
    paddingRight: "1rem"
  },

  filterGridItem: {
    height: "50%",
    background: theme.palette.background.paper,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    "&:first-child": {
      borderBottom: "1rem solid #efeff2"
    }
  },

  filter: {
    height: "100%",
    overflow: "auto",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column"
  },

  // ---- Audits table
  tableWrapper: {
    // width: '100%',
    height: "100%"
    // marginLeft: '1rem',
    // flexGrow: 1,
  },

  tableContainer: {
    height: "100%",
    width: "100%"
    // padding: '0 1rem'
  },
  table: {
    // padding: '0 1rem',
    background: theme.palette.background.paper
  },
  tableHeadRow: {
    height: "3rem"
  },
  overWritePadding: {
    // fontSize: '0.8rem',
    // padding: '0 0.8rem'
  },
  tableHeadCell: {
    background: "#efeff2",
    color: "#79797d",
    textTransform: "uppercase",
    height: 45,
    border: "none",
    padding: "0 15px",
    fontSize: "13px",
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  tableCell__icon: {
    width: 30
  },
  tableCell__date: {
    width: "24ch",
    paddingLeft: 0
  },
  tableCell__actor: {
    width: "20ch"
  },
  tableCell__actionOnSubject: {
    width: "25ch"
  },
  tableCell__subject: {
    width: "15ch"
  },
  tableCell__data: {
    width: "auto",
    minWidth: "17ch"
  },
  editIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "green"
    }
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "red"
    }
  },

  title: {
    borderBottom: "1px gray solid",
    marginBottom: "2rem",
    width: "100%",
    lineHeight: "50px"
  },
  addNewUser: {
    borderRadius: "4px",
    width: "15%",
    alignSelf: "flex-end",
    color: "#fff",
    background:
      "linear-gradient(-60deg, rgb(66, 26, 64) 50%, rgb(41, 19, 46) 50%)",
    fontFamily: "RobotoMedium",
    fontSize: "0.875rem",
    textAlign: "center",
    marginRight: "1rem"
  },

  mainTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  iconButton: {
    minWidth: "fit-content",
    padding: "0"
  },

  headMenuTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "white",
    marginBottom: "1.5rem",
    width: "100%",
    lineHeight: "6rem",
    borderBottom: "1px rgb(87, 69, 92) solid",
    padding: "0 10px 0 10px",
    boxSizing: "border-box"
  },
  "[class^=\"NavigationBar\"]": {
    padding: 0
  },
  body: {
    background: "#fafafa"
  },
  emailColor: {
    color: "#0079ff"
  },
  button: {
    maxHeight: "23px"
  },
  itemButton: {
    display: "flex",
    alignItems: "center"
  },
  dialogTitle: {
    backgroundColor: "#f6f6f7",
    // color: "#29132e",
    color: "#4b1c46",
    borderBottom: "1px solid #d5d2d5",
    padding: "4px 24px"
  },
  titleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  container: {
    border: "1px solid #d5d2d5",
    borderRadius: "4px",
    marginBottom: "1.5rem",
    marginTop: "1rem"
  },
  auditContent: {
    padding: ".5rem"
  },
  rowTitle: {
    fontWeight: "bold",
    textTransform: "capitalize",
    width: "170px"
  },
  mainRow: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    padding: "5px 10px"
  },
  grayRow: { backgroundColor: "#efeff2" },
  shareButton: {
    minHeight: 50,
    maxHeight: 50,
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize",
    borderRadius: "6px",
    background: "linear-gradient(to left, #421a40 100%, #29132e 0%)",
    width: "90%",
    // marginTop: 20,
    marginLeft: "5%",
    // marginBottom: 25,
    "&:disabled": {
       color: "#fff"
     },
    "&:hover": {
      boxShadow: "none"
    },
    "&:focus": {
      boxShadow: "none"
    }
  },
  scheduleIcon: {
    fontSize: "15px",
    marginLeft: "5px",
    "&>g": {
      fill: "grey",
      width: "10px"
    }
  },
  tableCell__unit: {
    width: "36ch",
    minWidth: "36ch",
    maxWidth: "36ch"
  },
  tableHeadCell__icon: {
    width: "71px",
    minWidth: "71px",
    maxWidth: "71px"
  },
  tableCell__customer: {
    width: "21ch",
    minWidth: "21ch",
    maxWidth: "21ch"
  },
  homeIcon: {
    fontSize: "10px",
    // marginLeft: "5px",
    "&>g": {
      fill: "#E8E6E7",
      width: "10px",
      height: "10px"
    }
  },
  userIcon: {fontSize: 23, marginLeft: "1px"},
  hvacIcon: {fontSize: 21, marginLeft: "2px"},
  iconWrapper: { display: "flex" },
  noIcon: {width: 25, textAlign: "center"},
  iconBtn: {
  borderRadius: "6px", height: 30, width: 30, padding: 0, marginBottom: -4,
  "&:disabled": {
    opacity: 0.5
  }
},
  filterItem: {
display: "flex",
alignItems: "center",
backgroundColor: "#efeff2",
borderRadius: 6,
marginRight: 15,
padding: "0 8px",
height: 32,
maxWidth: "100%"
},
  filterItemText: {
fontFamily: "Roboto",
fontSize: 14,
fontWeight: "normal",
lineHeight: "normal",
letterSpacing: "normal",
color: "#545964",
whiteSpace: "nowrap",
marginRight: 8,
maxWidth: "calc(100% - 26px)",
overflow: "hidden",
textOverflow: "ellipsis"
},
  filterItemCloseIcon: {
transform: "scale(0.5)",
marginLeft: "-0.5px"
},
  filterItemIconBtn: {
backgroundColor: "#aaa2aa",
borderRadius: "50%",
width: 18,
height: 18,
padding: 0
},
  filterItemsContainer: {
display: "flex",
alignItems: "center",
maxWidth: "calc(100% - 120px)",
overflow: "auto",
height: "100%",
width: "100%"
}

}));

export default useStyles;
