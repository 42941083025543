import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "-webkit-fill-available",
    width: "100%",
    background: "rgb(236, 235, 239)",
    overflowY: "auto",
    overflowX: "auto"
  },
  container: {
    flex: "1"
    // textAlign: "center"
    // width: "100%",
    // background: "rgb(236, 235, 239)",
    // alignContent: "flex-start"
  },
  card: {
    padding: "15px",
    marginBottom: "15px",
    // marginLeft: "15px",
    marginTop: "15px"
  },
  header: {
    padding: "15px",
    marginBottom: "15px",
    marginLeft: "15px",
    marginTop: "15px"
  },
  rightCard: {
    padding: "15px",
    marginBottom: "15px",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "15px"
  },

  submitButton: {
    width: "100%"
  },
  dialog: {
    padding: "10px"
  },
  titleContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignContent: "center"
  },
  title: {},
  headerStyle: {
    fontWeight: "bold",
    color: "#5c465d",
    marginBottom: "5px"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  dialogHeader: {
     paddingLeft: 20,
     paddingRight: 13,
     backgroundColor: "#f6f6f7",
     borderBottom: "1px solid #d5d2d5",
     display: "flex",
     justifyContent: "space-between",
     alignItems: "center",
     height: 60,
     minHeight: 60
   },
  headerTitle: {
   fontFamily: "RobotoMedium",
   color: "#29132e",
   lineHeight: "normal",
   fontSize: 18,
   fontWeight: 500,
   letterSpacing: "normal"

   },
  dialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    overflow: "hidden"
   },
  actionsHolder: {
     padding: 20,
     display: "flex",
     justifyContent: "flex-end"
  },
  dialogSubContent: {
     flex: "1 1 auto",
     padding: 20,
     overflowY: "auto",
     paddingBottom: 20,
     flexFlow: "column nowrap"
   },
  dropDownItem: {
     width: "100%",
     padding: "0 16px",
     height: "100%",
     display: "flex",
     alignItems: "center"
   }
}));

export default useStyles;
