import * as React from 'react';

function SvgExport(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 21" {...props}>
      <g fill="none" fillRule="evenodd" stroke="#AAA2AA" strokeLinecap="round" strokeWidth={1.6}>
        <path d="M17.698 8.868V2.677h-6.321M17.697 2.676L8 12.373m.919-7.632L1 4.676v15h15l-.033-7.852" />
      </g>
    </svg>
  );
}

export default SvgExport;
