import { Theme, Tooltip, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { Delete as DeleteIcon } from "../../svgComponents";
import ErrorBox from "../ErrorBox/ErrorBox";
import LightTooltip from "../Tooltip/LightTooltip";
import useStyles from "./Delete.style";

export default function Delete(props: any) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState(null);
  const objectToDelete = props.object;
  const type = props.type;
  const detach = props.detach;
  const { buttonClass = "", iconStyle = "", iconBtnStyle = "" } = props;
  const fullName = !objectToDelete.name ? `${objectToDelete.firstName} ${objectToDelete.lastName}` : "";

  const classes = useStyles();

  const handleClickOpen = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (!props.disable){
    setOpen(true);
    }
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(false);
  };
  const handleAgree = async () => {
    const id = objectToDelete.id || objectToDelete._id ;
    const result = await detach({ id });
    setError(result);
    !result && setOpen(false);
  };

  return (
    <>
      <LightTooltip title={t`Delete ${type}`}>
        <IconButton disabled={props.disabled} onClick={handleClickOpen} classes={{ root: clsx(classes.overWriteIcon, iconBtnStyle) }}>
          <DeleteIcon className={iconStyle} />
        </IconButton>
      </LightTooltip>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
      >
        <div
          className={classes.titleContent}
        >
          <Typography className={classes.headerTitle}>{t`Delete ${type}`}</Typography>
          <IconButton disableRipple className={classes.iconBtnStyle} onClick={handleClose}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          {error && <ErrorBox error={error} onClose={() => { setError(null); setOpen(false); }} />}
          <Typography className={classes.DialogContentText}>
            {t`Are you sure you want to delete ${objectToDelete.name || fullName}?`}

          </Typography>
        </div>
        <div className={classes.dialogActions}>
          <Button white uppercase marginRight width={130} onClick={handleAgree}>
            {t`Yes`}
          </Button>

          <Button
            autoFocus={true}
            onClick={handleClose}
            uppercase
            width={130}
          >
            {t`No`}
          </Button>
        </div>
      </Dialog>
    </>
  );
}
