import { Button, Collapse, FormControl, Grid, IconButton, MenuItem, Select, Switch, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { Field, FormikProps, yupToFormErrors } from "formik";
import React from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { useStoreState } from "../../models/RootStore";
import { IUser } from "../../models/Users";
import PhoneField from "./PhoneField";
import useStyles from "./UserDetails.style";
import UserDetailsField from "./UserDetailsField";
import { IUserFormValues } from "./UserHelpers";

const InviteSchema =
  Yup.object().shape({
    firstName: Yup.string()
      .max(20, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .max(20, "Too Long!")
      .required("Required"),
    email: Yup.string().required("Required")
      .email("Invalid email")
  });

const UserSchema =
  Yup.object().shape({
    firstName: Yup.string()
      .max(20, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .max(20, "Too Long!")
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required"),
    username: Yup.string()
      .min(6, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    phone: Yup.string()
      .test(
        "phone validation",
        t`Phone number should be 10 chars minimum.`,
        function(value) {
          if (!value) {
            return true;
          }

          let newValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
          newValue = newValue.replace(/\s/g, "");

          if (newValue.length > 9) {
            return true;
          }
          return false;
        }
      )

    ,
    password: Yup.string().matches(
      /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      "must be at least 1 capital letter and 1 digit"
    )

  });

export { UserSchema, InviteSchema };

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
interface IProps extends Omit<FormikProps<IUserFormValues>, "handleSubmit"> {
  user: IUser;
  errors: any;
  touched: any;
  withPasswordField?: boolean;
  withChangePasswordField?: boolean;
  temperatureScale: any;
  pressureScale: any;
  invite?: boolean;
  inviteEdit?: boolean;
}

const UserDetails: React.FC<IProps> = ({
  user,
  errors,
  touched,
  withPasswordField,
  withChangePasswordField,
  values,
  setFieldValue,
  temperatureScale,
  pressureScale,
  invite = false,
  inviteEdit = false,
  ...props
}) => {
  const classes = useStyles();
  const types = useStoreState((state) => state.types.allTypes);
  const [expanded, setExpanded] = React.useState(false);
  withPasswordField && (UserSchema.fields.password = Yup.string().required("Required"));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Grid className={classes.personalDetailsContainer}>
      <Field
        disabled={inviteEdit}
        label={t`First name`}
        value={values.firstName}
        name="firstName"
        component={UserDetailsField}
        error={errors.firstName && touched.firstName ? true : false}
        helperText={
          errors.firstName && touched.firstName ? errors.firstName : "Up to 20 characters"
        }
      />
      <Field
        disabled={inviteEdit}
        label={t`Last name`}
        value={values.lastName}
        name="lastName"
        component={UserDetailsField}
        error={errors.lastName && touched.lastName ? true : false}
        helperText={errors.lastName && touched.lastName ? errors.lastName : "Up to 20 characters"}
      />
      <Field
        disabled={inviteEdit}
        label={t`Email Address`}
        value={values.email}
        name="email"
        component={UserDetailsField}
        error={errors.email && touched.email ? true : false}
        helperText={errors.email && touched.email ? errors.email : "Valid email address"}
      />
      {!invite &&
        <Field
          label={t`Username`}
          value={values.username}
          name="username"
          component={UserDetailsField}
          error={errors.username && touched.username ? true : false}
          helperText={
            errors.username && touched.username ? errors.username : "6-20 characters, must be unique"
          }
        />}
      {withPasswordField && (
        <Field
          label={t`Password`}
          value={values.password}
          name="password"
          component={UserDetailsField}

          error={withPasswordField && errors.password && touched.password ? true : false}
          helperText={
            errors.password && touched.password
              ? errors.password
              : "8+ characters, 1 capital, 1 number, 1 special characters"
          }
        />
      )}
      {!invite && <>
        <Field
          label={t`Telephone`}
          value={values.phone}
          name="phone"
          component={PhoneField}
          onChange={(e: any) => setFieldValue("phone", e)}
          error={errors.phone && touched.phone ? true : false}
          helperText={
            errors.phone && touched.phone ? errors.phone : "International format telephone number"
          }
        />
        <div className={classes.unitsMeasurementContainer}>
          <Grid container component="label" direction="row" alignItems="center">
            <Grid item className={classes.itemTitle}>
              <div style={{ alignSelf: "center" }}>
                <Typography>{t`Temperature Units`}</Typography>
              </div>
            </Grid>
            <Grid item className={classes.itemSwtich}>
              <Grid container direction="row" className={classes.switchContainer}>
                <Grid item style={{ alignSelf: "center" }}>
                  <Typography variant="body2" className={classes.switchOptionText}>{t`°C`}</Typography>
                </Grid>
                <Grid item className={classes.tempFontStyle}>
                  <Field
                    name="temperatureScale"
                    value={values.temperatureScale}
                    as={() => (
                      <Switch
                        color={"default"}
                        onChange={() => {
                          setFieldValue("temperatureScale", values.temperatureScale !== 2 ? 2 : 1);
                        }}
                        checked={temperatureScale[values.temperatureScale] === "fahrenheit"}
                        name="temperatureScale"
                        classes={{
                          // root: classes.root,
                          // switchBase: classes.switchBase,
                          thumb: classes.thumb,
                          track: classes.track
                          // checked: classes.checked
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item style={{ alignSelf: "center" }}>
                  <Typography variant="body2" className={classes.switchOptionText}>{t`°F`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.unitsMeasurementContainer}>
          <Grid container component="label" direction="row" alignItems="center">
            <Grid item className={classes.itemTitle}>
              <div style={{ alignSelf: "center" }}>
                <Typography>{t`Pressure Units`}</Typography>
              </div>
            </Grid>
            <Grid item className={classes.itemSwtich}>
              <Grid container direction="row" className={classes.switchContainer}>
                <Grid item style={{ alignSelf: "center" }}>
                  <Typography variant="body2" className={classes.switchOptionText}>{t`kg/cm2`}</Typography>
                </Grid>
                <Grid item className={classes.tempFontStyle}>
                  <Field
                    name="measurementUnits"
                    value={values.measurementUnits}
                    as={() => (
                      <Switch
                        color={"default"}
                        onChange={() => {
                          setFieldValue("measurementUnits", values.measurementUnits !== 2 ? 2 : 1);
                        }}
                        checked={pressureScale[values.measurementUnits] === "PSI"}
                        name="measurementUnits"
                        classes={{
                          // root: classes.root,
                          // switchBase: classes.switchBase,
                          thumb: classes.thumb,
                          track: classes.track
                          // checked: classes.checked
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item style={{ alignSelf: "center" }}>
                  <Typography variant="body2" className={classes.switchOptionText}>{t`PSI`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/* ////////////////////// */}

        <div className={classes.unitsMeasurementContainer}>
          <Grid container component="label" direction="row" alignItems="center">
            <Grid item className={classes.itemTitle}>
              <div style={{ alignSelf: "center" }}>
                <Typography>{t`Time Format`}</Typography>
              </div>
            </Grid>
            <Grid item className={classes.itemSwtich}>
              <Grid container direction="row" className={classes.switchContainer}>
                <Grid item style={{ alignSelf: "center" }}>
                  <Typography variant="body2" className={classes.switchOptionText}>{types.timeFormat[0]?.text}</Typography>
                </Grid>
                <Grid item className={classes.tempFontStyle}>
                  <Field
                    name="timeFormat"
                    value={values.timeFormat}
                    as={() => (
                      <Switch
                        color={"default"}
                        onChange={() => {
                          setFieldValue("timeFormat", values.timeFormat !== types.timeFormat[0]?.value ? types.timeFormat[0]?.value : types.timeFormat[1]?.value);
                        }}
                        checked={values.timeFormat === types.timeFormat[1]?.value}
                        name="timeFormat"
                        classes={{
                          // root: classes.root,
                          // switchBase: classes.switchBase,
                          thumb: classes.thumb,
                          track: classes.track
                          // checked: classes.checked
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item style={{ alignSelf: "center" }}>
                  <Typography variant="body2" className={classes.switchOptionText}>{types.timeFormat[1]?.text}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.unitsMeasurementContainer}>
          <Grid container component="label" direction="row" alignItems="center">
            <Grid item className={classes.itemTitle}>
              <div style={{ alignSelf: "center" }}>
                <Typography>{t`Date Format`}</Typography>
              </div>
            </Grid>
            <Grid item className={classes.itemSwtich}>
              <Grid container direction="row" className={classes.switchContainer}>
                <Grid item style={{ alignSelf: "center" }}>
                  <Typography variant="body2" className={classes.switchOptionText}>{types.dateFormat[0]?.text}</Typography>
                </Grid>
                <Grid item className={classes.tempFontStyle}>
                  <Field
                    name="dateFormat"
                    value={values.dateFormat}
                    as={() => (
                      <Switch
                        color={"default"}
                        onChange={() => {
                          setFieldValue("dateFormat", values.dateFormat !== types.dateFormat[0].value ? types.dateFormat[0].value : types.dateFormat[1]?.value);
                        }}
                        checked={values.dateFormat === types.dateFormat[1].value}
                        name="dateFormat"
                        classes={{
                          // root: classes.root,
                          // switchBase: classes.switchBase,
                          thumb: classes.thumb,
                          track: classes.track
                          // checked: classes.checked
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item style={{ alignSelf: "center" }}>
                  <Typography variant="body2" className={classes.switchOptionText}>{types.dateFormat[1]?.text}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>}

      {withChangePasswordField && (
        <>
          <div>
            <Typography className={classes.passwordTitle}>
              {t`Change Password`}
              <IconButton
                disableRipple
                className={clsx(classes.expand, classes.iconBtnStyle, {
                  [classes.expandOpen]: expanded
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </Typography>
          </div>
          <Collapse in={expanded}>
            <Field
              label={t`Current Password`}
              value={values.password}
              name="password"
              component={UserDetailsField}
              error={withPasswordField && errors.password && touched.password ? true : false}
              helperText={
                errors.password && touched.password
                  ? errors.password
                  : "8+ characters, 1 capital, 1 number, 1 special characters"
              }
            />
            <Field
              label={t`New Password`}
              value={values.newPassword}
              name="newPassword"
              component={UserDetailsField}
              error={withPasswordField && errors.newPassword && touched.newPassword ? true : false}
              helperText={
                errors.newPassword && touched.newPassword
                  ? errors.newPassword
                  : "8+ characters, 1 capital, 1 number, 1 special characters"
              }
            />
            <Field
              label={t` Repeat Password`}
              value={values.repeatPassword}
              name="repeatPassword"
              component={UserDetailsField}
              error={
                withPasswordField && errors.repeatPassword && touched.repeatPassword ? true : false
              }
              helperText={
                errors.repeatPassword && touched.repeatPassword
                  ? errors.repeatPassword
                  : "8+ characters, 1 capital, 1 number, 1 special characters"
              }
            />
          </Collapse>
        </>
      )}
    </Grid>
  );
};

export default UserDetails;
