import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  Typography
} from "@material-ui/core/";
import { Check, Clear, Reply } from "@material-ui/icons";
import { ToggleButton } from "@material-ui/lab";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { t } from "ttag";
import {BottomNav} from "../../components";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import {AlertBell} from "../../icons";
import { Delete as DeleteIcon, Email as EmailIcon } from "../../icons/";
import { alertsModel } from "../../models/Alerts";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import useStyles from "./AlertInfo.style";

const AlertInfo: React.FC<any> = (props: any) => {
  const [brand, setBrand] = useState<string>("");
  const getSystem = useStoreState((state) => state.systems.getSystem);
  const setAcknowledgedEvent = useStoreActions((actions) => actions.alerts.setAcknowledgedEvent);

  const { alert, open, selectUnit, itemGotDeleted, addResolvedDescription, setAcknowledged } = props,
    classes = useStyles(),
    cleanFilterUnit = useStoreActions((action) => action.units.cleanFilterUnit),
    [isOpen, setIsOpen] = React.useState(false),
    [isEmailDialogOpen, setEmailDialogOpen] = React.useState(false),
    [isDeleteDialogOpen, handleDeleteDialog] = React.useState(false),
    [emails, setEmails] = React.useState<string[]>([]),
    [btnIsActive, activateBtn] = React.useState<boolean>(false),
    [error, setError] = useState(null),
    [resolvedAlertDescription, setResolvedAlertDescription] = React.useState<any>(""),
    [selectedUnit, setSelectedUnit] = React.useState<string>(""),
    sendAlertEmail = useStoreActions((actions) => actions.alerts.sendAlertEmail),
    allUnits = useStoreState((state) => state.units.allUnits),
    types = useStoreState((state) => state.types.allTypes),
    { eventTypes } = types,
    deleteAlert = useStoreActions((actions) => actions.alerts.deleteAlert),
    updateAlert = useStoreActions((actions) => actions.alerts.updateAlert),
    displayFlags = useStoreState((state) => state.users.displayFlags),
    handleClose = () => {
      setIsOpen(false);
      setSelectedUnit("");
    },
    handleOpen = (id: string) => {
      setSelectedUnit(id);
      setIsOpen(true);
    },
    handleAgree = () => {
      if (selectedUnit) {
        cleanFilterUnit({ id: selectedUnit });
      }
      handleClose();
    },
    confrimDeleteAlert = () => {
      deleteAlert(alert.id)
        .then(() => {
          handleDeleteDialog(false);
          itemGotDeleted();
        })
        .catch((error: any) => console.log("error", error));
    },
    handleEmailDialog = () => {
      setEmails([]);
      setEmailDialogOpen(!isEmailDialogOpen);
    },
    handleEmails = (emails: string[]) => {
      setEmails(emails);
    },
    sendAlertByEmail = () => {
      sendAlertEmail({ emails, id: alert.id })
        .then(() => setEmailDialogOpen(false))
        .catch((error: any) => console.log("error", error));
    },
    validateEmail = (email: any) => {
      return isEmail(email);
    },
    addLabeledEmail = (email: string, index: number, removeEmail: (index: number) => void) => {
      return (
        <div data-tag className={classes.emailPart} key={index}>
          {email}
          <span data-tag-handle onClick={() => removeEmail(index)}>
            ×
          </span>
        </div>
      );
    },
    handleInputsChange = (event: any) => {
      const {
        target: { value }
      } = event;
      setResolvedAlertDescription(value);
    },
    save = () => {
      updateAlert({ id: alert.id, data: { userText: resolvedAlertDescription } })
        .then(() => {
          activateBtn(false);
          addResolvedDescription(resolvedAlertDescription, alert.id);
        })
        .catch((err: any) => {
          setError(err.message);
        });
    };
  useEffect(() => {
    if (!brand){
      const system = getSystem(alert.systemId);
      const brand = system?.brandNum || "";

      setBrand(brand);

    }
    setResolvedAlertDescription("");
    activateBtn(false);
    if (!alert || alert.userText === undefined) { return; }
    setResolvedAlertDescription(alert.userText);
  }, [alert]);

  useEffect(() => {
    if (
      !alert ||
      (alert && alert.status !== "Closed") ||
      (alert && alert.userText === resolvedAlertDescription) ||
      !resolvedAlertDescription
    ) {
      return;
    }

    activateBtn(true);
  }, [resolvedAlertDescription]);
  const errorBox = error ? <ErrorBox error={error} onClose={() => setError(null)} /> : null;
  const disableDelete = !displayFlags.enableAlertDelete;
  const isAckAlert = alert?.status === "Acknowledged";
  const isOpenAlert = alert?.status === "Open";

  return (
    <>
        <div  className={classes.infoDialogStyle}>
          <div>
          <div id="title" style={{display: "flex", alignItems: "center", minHeight: "60px",
                                  borderBottom: "solid 1px #e5e2e5",
                                  padding: "0 20px"}}>
          <AlertBell style={{width: 25, height: 25}}/>
          <Typography style={{marginLeft: "15px",
                              fontFamily: "Roboto",
                              fontSize: 20,
                              fontWeight: 600,
                              color: "#15111f"
        }}>{t`Alert`}{` ${alert.shortId}`}</Typography>
          </div>
          <div className={classes.row} style={{minHeight: 60}}>
            <Typography className={classes.alertDetails}>{t`Alert Details`}</Typography>
            {(isOpenAlert || isAckAlert) &&
                  <ToggleButton
                    value="ack"
                    selected={isAckAlert}
                    classes={{ root: classes.ackButton, label: classes.ackButtonLable }}
                    onChange={() => {
                      const data = alert.status === "Acknowledged" ? false : true;
                      // if (toAck) {
                      //   setToAck(true);
                      //   return;
                      // }
                      setAcknowledgedEvent({ id: alert.id, data })
                      .then(() => {
                        setAcknowledged({ id: alert.id, data });
                      });
                    }}
                  >
                    <Check className={classes.ackButtonIcon} />
                    <Typography className={classes.ackButtonText}>{t`Acknowledge`}</Typography>
                  </ToggleButton>
            }
          </div>
          <div className={classes.row}>
            <Typography className={classes.title}>{t`Unit Name`}</Typography>
            <Typography className={classes.data}>{alert.unitName}</Typography>
          </div>
          <div className={classes.row}>
            <Typography className={classes.title}>{t`Brand`}</Typography>
            <img
                width={100}
                height={25}
                src={assetsService.getBrandLogo(`${brand}-grey.svg`)}
                onError={(event: any) =>
                  (event.target.src = assetsService.getBrandLogo("empty.png"))
                }
                alt={brand}
                // className={brandLogo}
              />
          </div>
          <div className={classes.row}>
            <Typography className={classes.title}>{t`Open Date`}</Typography>
            <Typography className={classes.data}>{alert.time}</Typography>
          </div>
          <div className={classes.row}>
            <Typography className={classes.title}>{t`Close Date`}</Typography>
            <Typography className={classes.data}> {alert.status === "Closed" ? alert.clearTime || "" : ""}</Typography>
          </div>
          <div className={classes.row}>
            <Typography className={classes.title}>{t`Error Code`}</Typography>
            <Typography className={classes.data}>{alert.errorCode}</Typography>
          </div>
          <div style={{padding: 20, borderBottom: "1px solid #efeff2"}}>
            <Typography className={classes.title}>{t`Description`}</Typography>
            <Typography className={classes.description}>{alert.description}</Typography>
          </div>
          </div>
          <div>
          <Button
                variant="contained"
                className={classes.shareButton}
                style={{width: "90%", marginLeft: "5%", marginTop: 20}}
                startIcon={
                  <Reply style={{ transform: "rotateY(180deg)", marginTop: "-3px" }} />
                  // <Icon style={{ transform: "rotateY(180deg)", marginTop: "-3px" }}></Icon>
                }
                onClick={() => setEmailDialogOpen(true)}
              >
                {t`Share Report`}
              </Button>
              </div>
        </div>
        <Dialog
           open={isEmailDialogOpen}
           aria-labelledby="email-alert-dialog-title"
           aria-describedby="email-alert-dialog-description"
           maxWidth="sm"
           classes={{ paper: classes.emailDialogStyle }}
         >
           <div className={classes.dialogTitle}>
             <DialogTitle id="simple-dialog-title">{t`Share alert`}</DialogTitle>
           </div>
           <div className={classes.emailDialogContent}>
             <Typography>Recipients</Typography>
             <ReactMultiEmail
               placeholder="Email Address/es"
               emails={emails}
               onChange={handleEmails}
               validateEmail={validateEmail}
               getLabel={addLabeledEmail}
             />
             <div className={classes.emailDialogActions}>
               <Button
                 white
                 width={110}
                 uppercase
                 marginRight
                 onClick={handleEmailDialog}
               >{t`Cancel`}</Button>
               <Button
                 type="submit"
                 width={110}
                 uppercase
                 disabled={_.isEmpty(emails)}
                 onClick={sendAlertByEmail}
               >{t`Share`}</Button>
             </div>
           </div>
         </Dialog>
    </>
  );
};
export default AlertInfo;
