import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { Formik } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { t } from "ttag";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Loading from "../../components/Loading/Loading";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUser } from "../../models/Users";
import UserDetails from "./UserDetails";
import { UserSchema } from "./UserDetails";
import useStyles from "./UserEdit.style";
import { buildPermissions, IUserFormValues } from "./UserHelpers";
import UserSettings from "./UserSettings";

const UserEdit: React.FC<RouteComponentProps<any> & any> = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState<any>(null);

  const users = useStoreState((state) => state.users.users);
  const updateUser = useStoreActions((action) => action.users.updateUser);
  const types = useStoreState((state) => state.types.allTypes);

  const { temperatureScale, pressureScale } = types;
  const [open, setOpen] = React.useState(true);

  const user: IUser | undefined = _.find(
    Object.values(users),
    (user: IUser) => user.id === props.id
  );

  if (!user) { return <Loading />; }
  const initialValues: IUserFormValues = {
    firstName: user.firstName ? user.firstName : "",
    lastName: user.lastName ? user.lastName : "",
    username: user.username ? user.username : "",
    password: "",
    email: user.email ? user.email : "",
    phone: user.phone ? user.phone : "",
    temperatureScale: user.temperatureScale ? user.temperatureScale : 1,
    adminRole:
      user.customer &&
        user?.permissions?.customers &&
        user?.permissions?.customers[user.customer] === "customerAdmin"
        ? 0
        : user?.permissions?.sites && Object.values(user?.permissions?.sites).length && Object.values(user?.permissions?.sites)[0] === "siteAdmin"
          ? 1
          : 2,
    checkedSites: {},
    checkedSystems: {},
    measurementUnits: user.measurementUnits ?? 1,
    timeFormat: user.timeFormat,
    dateFormat: user.dateFormat
  };

  const onUserEditSubmit = (values: IUserFormValues) => {
    values.phone = values.phone.replace(/[^a-zA-Z0-9 ]/g, "");
    values.phone = values.phone.replace(/\s/g, "");

    if (values.adminRole !== 0
      && !_.find(values.checkedSites, (value) => value)
      && !_.find(values.checkedSystems, (value) => value)
    ) {
      setSubmitError("Please choose site or system permissions for user");
      return;
    }

    const payload = {
      ...values,
      permissions: user.customer
        ? buildPermissions(
          user.customer,
          values.adminRole,
          values.checkedSites,
          values.checkedSystems
        )
        : {}
    };

    // Remove fields we don't send on update.
    delete payload.password;
    delete payload.adminRole;
    delete payload.checkedSites;
    delete payload.checkedSystems;
    updateUser({ userId: user.id, updatedData: payload })
      .then(() => handleClose())
      .catch((e: any) => setSubmitError(e.message));
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };
  const errorBox = submitError ? (
    <ErrorBox
      error={submitError}
      title={_.includes(submitError, "permissions for user") ? "Missing users permissions" : ""}
      onClose={() => setSubmitError(null)} />
  ) : null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      fullWidth
      classes={{ paper: classes.dialog }}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{t`Edit user`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={handleClose}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={onUserEditSubmit}
        enableReinitialize={true}
        validationSchema={UserSchema}
      >
        {({ handleSubmit, errors, touched, ...restFormikProps }) => (
          <form onSubmit={handleSubmit} className={classes.dialogContent}>
            <Grid container className={classes.dialogSubContent}>
              <Grid item xs={7}>
                <Paper className={classes.leftCard} elevation={0}>
                  <Typography
                    className={classes.headerStyle}
                  >{t`Personal Details`}</Typography>
                  <Divider orientation="horizontal" />
                  <UserDetails
                    inviteEdit={props.inviteEdit}
                    errors={errors}
                    touched={touched}
                    user={user}
                    temperatureScale={temperatureScale}
                    pressureScale={pressureScale}
                    {...restFormikProps}
                    {...props}
                  />
                </Paper>
              </Grid>
              <Divider orientation="vertical" />
              <Grid item xs={5}>
                <Paper className={classes.rightCard} elevation={0}>
                  <Typography className={classes.headerStyle}>{t`Permissions`}</Typography>
                  <Divider orientation="horizontal" />
                  <UserSettings user={user} inviteEdit={props.inviteEdit} {...restFormikProps} {...props} />
                </Paper>
              </Grid>
            </Grid>
            <div className={classes.actionsHolder}>
              {errorBox}
              <Button
                uppercase
                width={150}
                white
                marginRight
                onClick={handleClose}
                onMouseUp={handleClose}
              >
                {t`Cancel`}
              </Button>
              <Button
                uppercase
                width={150}
                onMouseUp={() => onUserEditSubmit(restFormikProps.values)}
                onClick={() => onUserEditSubmit(restFormikProps.values)}
              >
                {props.inviteEdit ? t`Send invite` : t`Submit`}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
export default UserEdit;
