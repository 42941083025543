import { action, Action, computed, Computed, Thunk, thunk } from "easy-peasy";

export interface IServiceTypes {
  temperatureScale?: any;
  actionSources?: any;
  operationStatuses?: any;
  operationModes?: any;
  operationModesWithTemperatures?: any;
  fanModes?: any;
  swingModes?: any;
  weekDays?: any;
  roles?: any;
  permissions?: any;
  resources?: any;
  hvacBrands?: any[];
  outdoorUnitTasks?: any;
  systemTypes?: string[];
  alertClasses?: any;
  alertClearTypes?: any;
  proParsingCodes?: any;
  capacityMeasurementUnitTypes?: any;
  unitTypes?: any;
  unitSubTypes?: any;
  systemUnitTypes?: any;
  eventClearTypes?: any;
  eventTypes?: any;
  eventStatusTypes?: any;
  measurementUnitTypes?: any;
  pressureScale?: any;
  procedureStepTypes?: any;
  procedureDeviceCommands?: any;
  procedureRunningStates?: any;
  procedureStateCommands?: any;
  trapTypes?: any;
  applications?: any;
  timeFormat?: any;
  dateFormat?: any;
  endActionTypes?: any;
  sensorTypes?: any;
  sensorMeasurementUnits?: any;
  siteStatuses?: any;
  calculatedMode?: any;
}
export interface ITypesModel {
  allTypes: IServiceTypes;
  powerOnVal: number;
  powerOffVal: number;
  unitTypes: Computed<ITypesModel>;
  setTypes: Action<ITypesModel, any>;
  setPowerVal: Action<ITypesModel, any>;
  operationModesMirror: Computed<ITypesModel>;
  fanModesMirror: Computed<ITypesModel>;
  swingModesMirror: Computed<ITypesModel>;
  operationStatusesMirror: Computed<ITypesModel>;
  temperatureScaleMirror: Computed<ITypesModel>;
  brandsMap: Computed<ITypesModel>;
  eventClearTypesMirror: Computed<ITypesModel>;
  unitTypesMirrror: Computed<ITypesModel>;
  eventTypesMirror: Computed<ITypesModel>;
  eventStatusTypesMirror: Computed<ITypesModel>;
  unitTypesOptions: Computed<ITypesModel>;
  procedureRunningStatesMirror: Computed<ITypesModel>;
  setpsOptions: Computed<ITypesModel>;
  commandsOptions: Computed<ITypesModel>;
  getUnitTypes: Computed<ITypesModel>;

}

export const typesModel: ITypesModel = {
  allTypes: {},
  powerOffVal: 0,
  powerOnVal: 0,
  unitTypes: computed((state) => {
    const { unitTypes } = state.allTypes;

    if (!unitTypes) {
      return null;
    }

    const keys = Object.keys(unitTypes);
    const object: { [index: string]: any } = {};

    const unitType = keys.reduce((allTypes, key) => {
      const name = unitTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return unitType;
  }),
  operationModesMirror: computed((state) => {
    const { allTypes } = state;
    const { operationModes } = allTypes;

    if (!operationModes) {
      return null;
    }

    const keys = Object.keys(operationModes);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((allTypes, key) => {
      const name = operationModes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return modes;
  }),
  operationStatusesMirror: computed((state) => {
    const { allTypes } = state;
    const { operationStatuses } = allTypes;

    if (!operationStatuses) {
      return null;
    }

    const keys = Object.keys(operationStatuses);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((allTypes, key) => {
      const name = operationStatuses[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return modes;
  }),
  fanModesMirror: computed((state) => {
    const { allTypes } = state;
    const { fanModes } = allTypes;

    if (!fanModes) {
      return null;
    }

    const keys = Object.keys(fanModes);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((allTypes, key) => {
      const name = fanModes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return modes;
  }),
  swingModesMirror: computed((state) => {
    const { allTypes } = state;
    const { swingModes } = allTypes;

    if (!swingModes) {
      return null;
    }

    const keys = Object.keys(swingModes);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((allTypes, key) => {
      const name = swingModes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return modes;
  }),
  brandsMap: computed((state) => {
    let brandsObj: any = {};
    if (!state || !state.allTypes || !state.allTypes.hvacBrands) {
      return {};
    }

    const brandsArr = state.allTypes.hvacBrands;

    for (let brandIndex in brandsArr) {
      const brand: any = brandsArr[brandIndex];
      brandsObj[brand.type] = brand.name;
    }
    return brandsObj;
  }),
  setTypes: action((state, payload) => {
    state.allTypes = payload;
  }),
  setPowerVal: action((state, payload) => {
    state.powerOffVal = payload.powerOffVal;
    state.powerOnVal = payload.powerOnVal;
  }),
  temperatureScaleMirror: computed((state) => {
    const { allTypes } = state;
    const { temperatureScale } = allTypes;

    if (!temperatureScale) {
      return null;
    }

    const keys = Object.keys(temperatureScale);
    const object: { [index: string]: any } = {};

    const temperatureScaleType = keys.reduce((allTypes, key) => {
      const name = temperatureScale[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return temperatureScaleType;
  }),

  unitTypesMirrror: computed((state) => {

    const { unitTypes } = state?.allTypes;

    if (!unitTypes) {
      return null;
    }

    const keys = Object.keys(unitTypes);
    const object: { [index: string]: any } = {};

    const unitType = keys.reduce((types, key) => {
      const name = unitTypes[key];
      types[name as string] = key;

      return types;
    }, object);

    return unitType;
  }),
  eventClearTypesMirror: computed((state) => {
    const { allTypes } = state;
    const { eventClearTypes } = allTypes;

    if (!eventClearTypes) {
      return null;
    }

    const keys = Object.keys(eventClearTypes);
    const object: { [index: string]: any } = {};

    const eventClearType = keys.reduce((allTypes, key) => {
      const name = eventClearTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventClearType;
  }),
  eventTypesMirror: computed((state) => {
    const { allTypes } = state;
    const { eventTypes } = allTypes;

    if (!eventTypes) {
      return {};
    }

    const keys = Object.keys(eventTypes);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((allTypes, key) => {
      const name = eventTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventType;
  }),
  eventStatusTypesMirror: computed((state) => {
    const { allTypes } = state;
    const { eventStatusTypes } = allTypes;

    if (!eventStatusTypes) {
      return {};
    }

    const keys = Object.keys(eventStatusTypes);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((allTypes, key) => {
      const name = eventStatusTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventType;
  }),
  getUnitTypes: computed((state) => {
    const { unitTypes } = state.allTypes;

    if (!unitTypes) {
      return null;
    }

    return unitTypes;
  }),
  unitTypesOptions: computed((state) => {
    const { allTypes } = state;
    const { unitTypes } = allTypes;

    if (!unitTypes) {
      return null;
    }

    const keys = Object.keys(unitTypes);
    const object: { [index: string]: any } = {};

    const options = keys.map((key) => ({ value: unitTypes[key], name: key }));

    return [...options, { value: "mixed", name: "Mixed" }];
  }),
  procedureRunningStatesMirror: computed((state) => {
    const { allTypes } = state;
    const { procedureRunningStates } = allTypes;

    if (!procedureRunningStates) {
      return {};
    }

    const keys = Object.keys(procedureRunningStates);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((allTypes, key) => {
      const name = procedureRunningStates[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventType;
  }),
  setpsOptions: computed((state) => {
    const { allTypes } = state;
    const { procedureStepTypes } = allTypes;

    if (!procedureStepTypes) {
      return [];
    }

    return [
      { value: procedureStepTypes.command, name: "Action" },
      { value: procedureStepTypes.wait, name: "Wait" },
      { value: procedureStepTypes.condition, name: "Check", disabled: true }];

  }),
  commandsOptions: computed((state) => {
    const { allTypes } = state;
    const { procedureDeviceCommands } = allTypes;

    if (!procedureDeviceCommands) {
      return [];
    }

    return {
      [procedureDeviceCommands.setUnitOperationMode]: { value: procedureDeviceCommands.setUnitOperationMode, name: "Mode", enum: "operationModes" },
      [procedureDeviceCommands.setUnitPowerState]: { value: procedureDeviceCommands.setUnitPowerState, name: "Power", enum: "operationStatuses" },
      [procedureDeviceCommands.setUnitFanMode]: { value: procedureDeviceCommands.setUnitFanMode, name: "Fan", enum: "fanModes" },
      [procedureDeviceCommands.setUnitSwingMode]: { value: procedureDeviceCommands.setUnitSwingMode, name: "Swing", enum: "swingModes" },
      [procedureDeviceCommands.setUnitSetpoint]: { value: procedureDeviceCommands.setUnitSetpoint, name: "Setpoint" }
    };

  })
};
