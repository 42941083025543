import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  List,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography
} from "@material-ui/core";
import { FilterList , Reply} from "@material-ui/icons";
import AcUnit from "@material-ui/icons/AcUnit";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import CoolTable, {
  ICoolTablePage,
  IHeadCell,
} from "../../components/CoolTable/CoolTable";
import DatePicker from "../../components/MobileDatePicker/MobileDatePicker";
import { Lookup } from "../../components/MobileLookup";

import { useHistory } from "react-router-dom";
import {BottomNav, TopBar} from "../../components";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import {
  ArrowDown,
  Home as HomeIcon,
  HVACIcon,
  MobileAudit,
  Timer as ScheduleIcon,
  User1 as UserIcon,
} from "../../icons";
import {ArrowBack, Close, ControlMobile, Dashboard as MDashboard, MobileLogo, MobileMenu, NotitifactionMobile, ReportsMobile, SettingsMobile} from "../../icons/";
import { IAudit, IAuditMap } from "../../models/Audits";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ISite } from "../../models/Sites";
import { IServiceTypes } from "../../models/Types";
import { checkDateInRange } from "../../services/timeService";
import ExportUtils from "../../utils/ExportUtils";
import {
  auditActionMap,
  formattedActionText,
  showAuditAction,
  showAuditData
} from "./AuditsActionMap";
import useStyle from "./AuditsList.style";

export interface IAuditRow {
  icon: any;
  date: string;
  actorId: string;
  actionId: string;
  action: string;
  // actionOnSubject: { actionId: string; action: string; subject: string };
  data: any;
  // optional fields
  unitId?: string;
  deviceId?: string;
  siteId?: string;
  customerId?: string;
  unitName?: string;
  deviceName?: string;
  siteName?: string;
  customerName?: string;
  user?: string;
  source?: number;
  line?: number;
  sourceType?: string;
  timezone?: string;
  iconText?: string;
  formattedDate?: string;
  id: string;
}
type IAuditRowField = keyof IAuditRow;
type Order = "asc" | "desc";

const sourceMatching: any = {
  1: {icon: <UserIcon />, text: "Application User"},
  2: {icon: <ScheduleIcon color="#aaa2aa" style={{height: 21, width: "auto"}} />, text: "Scheduled Operation"},
  3: {icon: <HomeIcon style={{height: "auto", width: 24}}/>, text: "Home Automation / BMS"},
  4: {icon: <HVACIcon style={{height: 21, width: "auto"}}/>, text: "HVAC"}
};

const dataPropertiesReplacement: { [key: string]: any } = {
  isRegistered: "Connected",
  isExpired: "Expired"
},
  notReadable = [
    "customer",
    "isAcceptedTOU",
    "_id",
    "__v",
    "user",
    "userLocationsMetadata",
    "password",
    "randId",
    "id",
    "unit",
    "site"
  ];

const AuditsList: React.FC = (props: any) => {
  const classes = useStyle();
  const history = useHistory();

  const getMyAudits = useStoreActions((s) => s.audits.getMyAudits);
  const isInitialized = useStoreState((s) => s.isInitialized);
  const allUsers = useStoreState((s) => s.users.users);
  const allSites = useStoreState((s) => s.sites.allSites);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const getCustomerName = useStoreState((s) => s.customers.getCustomerName);
  const getDeviceName = useStoreState((s) => s.devices.getDeviceName);
  const getSiteName = useStoreState((s) => s.sites.getSiteName);
  const getFullName = useStoreState((s) => s.users.getFullName);
  const getUsername = useStoreState((s) => s.users.getUsername);
  const types = useStoreState((s) => s.types.allTypes);
  const selections = useStoreState((s) => s.selections.mobileSelections);
  const {dateRange} = useStoreState((s) => s.selections.selections);
  const updateSelections = useStoreActions((s) => s.selections.updateSelections);
  const getSite = useStoreState((s) => s.sites.getSite);
  const getUnitName = useStoreState((s) => s.units.getUnitName);
  const temperatureScaleDisplay = useStoreState((s) => s.users.getTemperatureScaleDisplayPlainText);

  const [loading, setLoading] = useState(false);
  const [audits, setAudits] = useState<IAuditRow[]>([]);
  const { timeFormat, dateFormat } = useStoreState((state) => state.users);
  const  [opendatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [openFilters, setOpenedFilters] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<any>({ users: {}, source: {}, actions: {} });
  const [usersFilters, setUsersFilters] = useState<any>({});
  const [actionsFilter, setActoinsFilter] = useState<any>({});
  const [sourcesFilters, setSourcesFilters] = useState<any>({});
  const [filteredAudtis, setFilteredAudits] = useState<IAuditRow[]>([]);
  const [pagingData, setPagingData] = useState<any>({rowsNum: 0, pagesNum: 0, currentPageNum: 0});

  const sourceMap = useCallback((source: any, sourceType: any) => {
    if (source === 3 && sourceType){
      return {icon: <HomeIcon />, text: sourceType};
    }

    return sourceMatching[source] || {icon: "-", text: "-"};
  }, []);

  useEffect(() => {
      if (!dateRange?.endDate || !dateRange?.startDate) {
        updateSelections({
          type: "time",
          data: {
            startDate: new Date(new Date().setHours(0, 0, 0) - 2 * 24 * 60 * 60 * 1000),
            endDate: new Date()
          }
        });
      }

      const users: any = {};
      Object.values(allUsers).forEach((user: any) => {
            users[user?.username] = true;
          });

      setUsersFilters(users);
    }, []);

  useEffect(() => {
    if (!dateRange) {
        return;
      }

    setLoading(true);
    const startTime = Date.UTC(dateRange?.startDate.getFullYear(), dateRange?.startDate.getMonth(), dateRange?.startDate.getDate()) - 54000000;
    const endTime = Date.UTC(dateRange?.endDate.getFullYear(), dateRange?.endDate.getMonth(), dateRange?.endDate.getDate(), 23, 59, 59) + 54000000;

    getMyAudits({ params: { startTime, endTime } })
      .then((auditMap: any) => {
        const auditsArr = parseAudits(auditMap);
        const sources: any = {};
        const actions: any = {};

        auditsArr.forEach((audit: any) => {
              sources[audit?.iconText] = true;
              actions[audit?.action] = true;
            });

        setSourcesFilters(sources);
        setActoinsFilter(actions);
        setAudits((_.orderBy(auditsArr, ["formattedDate"], ["desc"])));
      })
      .catch(() => {
        setAudits([]);
        setLoading(false);
      });

    }, [dateRange]);

  useEffect(() => {
      //for filtering the auidts based on selections and selected filters
      if (audits.length === 0){
        setPagingData({rowsNum: 0, pagesNum: 0, currentPageNum: 0});
        loading && setLoading(false);
        setFilteredAudits([]);
      }

      const {customerId, siteId, unitId} = selections;
      const {users, source, actions} = selectedFilters;
      const noUsersSelected =  _.isEmpty(users) ;
      const noSourcesSelected = _.isEmpty(source) ;
      const noActionsSelected =  _.isEmpty(actions);

      const filteredRows =  _(audits)
        .filter((audit) => {
          return noUsersSelected ? true : users[audit.user || ""];
        })
        .filter((audit) => {
          return noSourcesSelected ? true : source[audit.iconText || ""];
        })
        .filter((audit) => {
          return noActionsSelected ? true : actions[audit.action || ""];
        })
        .filter((audit: any) =>
          unitId ? audit.unitId === unitId : true
        )
        .filter((audit: any) =>
          siteId ? audit.siteId === siteId : true
        )
        .filter((audit: any) =>
          customerId ? audit.customerId === customerId : true
        )
        .value();

      const rowsNum = filteredRows.length;
      const pagesNum = Math.ceil(rowsNum / 50);

      setPagingData({rowsNum, pagesNum, currentPageNum: 0, firstIndexInPage: 0, lastIndexInPage: rowsNum > 49 ?  50 : rowsNum});
      setFilteredAudits(filteredRows);
      loading && setLoading(false);
    }, [selectedFilters, selections, audits]);

  const parseAudits = (auditMap: IAuditMap) => {
      const auditsArr = Object.values(auditMap);
      const filteredAudtis: any = [];

      for (let i = 0; i < auditsArr.length; i++) {
        const auditAPI: any = auditsArr[i];

        if (auditAPI.unit && !allUnits[auditAPI.unit]) {
          continue;
        }

        const timezone = getSite(auditAPI.site)?.timezone || moment.tz.guess();
        if (!checkDateInRange(moment(dateRange?.startDate).format("MM/DD/YYYY"),
            moment(dateRange?.endDate).format("MM/DD/YYYY"),
            moment(auditAPI.timestamp).tz(timezone).format("MM/DD/YYYY"))
          ) {
            continue;
          }

        const {icon, text} = sourceMap(auditAPI.source, auditAPI?.sourceType);

        const row: IAuditRow = {
            icon,
            iconText: text,
            id: auditAPI.id,
            date: auditAPI.timestamp,
            actorId: auditAPI.actor,
            actionId: auditAPI.action ,
            action: formattedActionText(auditAPI.action),
            data: auditAPI.value ? { value: auditAPI.value } : auditAPI.data,
            unitId: auditAPI.unit,
            unitName: getUnitName(auditAPI.unit),
            siteId: auditAPI.site,
            siteName: getSiteName(auditAPI.site),
            deviceId: auditAPI.device,
            deviceName: getDeviceName(auditAPI.device),
            customerId: auditAPI.customer ? auditAPI.customer : getSite(auditAPI.site)?.customer,
            customerName: getCustomerName(
              auditAPI.customer ? auditAPI.customer : getSite(auditAPI.site)?.customer
            ),
            user: auditAPI?.user ? getUsername(auditAPI.user) : "-",
            line: auditAPI.line,
            source: auditAPI.source,
            sourceType: auditAPI?.sourceType,
            timezone,
            formattedDate: timezone ? moment(auditAPI.timestamp).tz(timezone).format(`${dateFormat} ${timeFormat}`) : moment(auditAPI.timestamp).format(`${dateFormat} ${timeFormat}`)
          };
        filteredAudtis.push(row);

      }
      return filteredAudtis;
    };

  const getReadableData = useCallback((data: any, actionId: any) => {
      if (_.isEmpty(data)) {
        return "";
      }
      const lowerCaseAction = actionId.toLowerCase(),
      { value } = data;

      if (lowerCaseAction.includes("setpoint")) {
        return `setpoint value: ${value} ${temperatureScaleDisplay()}`;
      }
      if (lowerCaseAction.includes("operationstatus")) {
        return `operation status: ${types?.operationStatuses[value]}`;
      }
      if (lowerCaseAction.includes("operationmode")) {
        return `operation mode: ${types?.operationModes[value]}`;
      }
      if (lowerCaseAction.includes("fanmode")) {
        return `fan mode: ${types?.fanModes[value]}`;
      }
      if (lowerCaseAction.includes("swingmode")) {
        return `swing mode: ${types?.swingModes[value]}`;
      }

      let dataString = "";
      Object.keys(data).forEach((key: any) => {
        if (typeof data[key] !== "string" || notReadable.includes(key)) {
          return;
        }

        const replacement = dataPropertiesReplacement[key];
        dataString += `${dataString ? "| " : ""}${replacement ? replacement : key}: ${data[key]}`;
      });
      return dataString;
    }, []);

  const handleAuditShare = useCallback((audits: any) => {
      const headers = "SOURCE,UNIT,SITE,CUSTOMER,ACTION,USER,DATE/TIME,DATA";
      let rows = "";
      audits.forEach((audit: any) => {
        const formatedData = getReadableData(audit.data, audit.actionId);
        const row: any = [
          audit.iconText,
          audit.unitName,
          audit.siteName,
          audit.customerName,
          audit.action,
          audit.user,
          audit.dateFormated,
          formatedData
        ];
        rows += row.join(",") + "\r\n";
      });

      const csvContent = "data:text/plain;charset=utf-8," + headers + "\r\n" + rows;
      ExportUtils.downloadFile(csvContent, "Audits");
    }, []);

  const onApply = useCallback((selectedFilters: any) => {
      setSelectedFilters({...selectedFilters});
      setOpenedFilters(false);
    }, []);

  if (!isInitialized) { return <Loading />; }

  const filterValues: any = {
      users: usersFilters,
      source: sourcesFilters,
      actions: actionsFilter
    };

  const moveToPage = (page: string) => {
    let {currentPageNum, rowsNum, firstIndexInPage, lastIndexInPage} = pagingData;

    if (page === "next"){
      ++currentPageNum;
    }else{
      --currentPageNum;
    }

    firstIndexInPage = currentPageNum * 50;
    lastIndexInPage = (currentPageNum * 50) + 50;
    lastIndexInPage = rowsNum > lastIndexInPage ? lastIndexInPage : rowsNum;
    setPagingData({...pagingData, currentPageNum, firstIndexInPage, lastIndexInPage});
};

  const {firstIndexInPage, lastIndexInPage, rowsNum, currentPageNum, pagesNum} = pagingData;

  return (
    <>
    <div className={classes.view}>
      <div className={classes.contentArea}>
      <TopBar  leftAction={() => history.push("/dashboard")} leftIconComponent={<MobileLogo />} />
      <div id="title" style={{display: "flex", alignItems: "center", minHeight: "53px",
                              borderBottom: "solid 1px #e5e2e5",
                              padding: "0 20px"}}>
      <MobileAudit style={{width: 25, height: 25}}/>
      <Typography style={{marginLeft: "15px",
                          fontFamily: "Roboto",
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#15111f"
    }}>{t`Audit Log`}</Typography>
    </div>

    <div onClick={() => setOpenDatePicker(true)} style={{display: "flex", flexFlow: "row nowrap", justifyContent: "space-between", alignItems: "center", padding: "0 20px", height: 50, borderBottom: "solid 1px #e5e2e5"  }}>
    <Typography style={{
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 600,
      color: "#29132e"
    }}
    >{`${moment(dateRange?.startDate || new Date()).format("MMM DD")} - ${moment(dateRange?.endDate || new Date()).format("MMM DD")}`}</Typography>
    <ArrowDown
     style={{height: 8, width: 12}}
    />
    </div>

    <div onClick={() => setOpenedFilters(true)} style={{display: "flex", flexFlow: "row nowrap", justifyContent: "space-between", alignItems: "center", padding: "0 20px", minHeight: 50, borderBottom: "solid 1px #e5e2e5"  }}>
    <Typography style={{
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 600,
      color: "#29132e",
      marginRight: 18,
      whiteSpace: "nowrap"
    }}
    >{t`Filter By`}</Typography>
    <div className={classes.filterItemsContainer}>
    {Object.keys(selectedFilters.users).map((filterName: string) =>
      <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
        <Typography className={classes.filterItemText}>{filterName}</Typography>
        <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => {event.stopPropagation(); event.preventDefault(); delete selectedFilters.users[filterName]; setSelectedFilters({...selectedFilters}); }}>
          <Close className={classes.filterItemCloseIcon}/>
        </IconButton>
      </div>
     )
    }
    {Object.keys(selectedFilters.source).map((filterName: string) =>
      <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
        <Typography className={classes.filterItemText}>{filterName}</Typography>
        <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => {event.stopPropagation(); event.preventDefault(); delete selectedFilters.source[filterName]; setSelectedFilters({...selectedFilters}); }}>
          <Close className={classes.filterItemCloseIcon}/>
        </IconButton>
      </div>
     )
    }
    {Object.keys(selectedFilters.actions).map((filterName: string) =>
      <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
        <Typography className={classes.filterItemText}>{filterName}</Typography>
        <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => {event.stopPropagation(); event.preventDefault(); delete selectedFilters.actions[filterName]; setSelectedFilters({...selectedFilters}); }}>
          <Close className={classes.filterItemCloseIcon}/>
        </IconButton>
      </div>
     )
    }
    </div>
    <FilterList />
    </div>
      <div style={{display: "flex", justifyContent: "flex-end", padding: "0 9px", paddingTop: 15, height: 50}}>
      {(rowsNum !== 0 && !loading) &&
        <>
      <Typography>
      <span>{firstIndexInPage + 1}</span>
      <span>-</span>
      <span>{lastIndexInPage}</span>
      <span>{" "}of{" "}</span>
      <span>{rowsNum}</span>
      </Typography>
      <div style={{marginLeft: 20}}>
      <IconButton className={classes.iconBtn} disabled={currentPageNum === 0} onClick={() => moveToPage("prev")}><ArrowDown style={{transform: "rotate(90deg)", height: 8, width: 12}}/></IconButton>
      <IconButton className={classes.iconBtn} disabled={currentPageNum === pagesNum - 1} onClick={() => moveToPage("next")}><ArrowDown style={{transform: "rotate(270deg)", height: 8, width: 12}}/></IconButton>
      </div>
      </>
      }
      </div>
  <div id="audits rows" style={{height: "calc(100% - 290px )", minHeight: "calc(100% - 290px )", maxHeight: "calc(100% - 290px )", overflow: "auto", display: "flex", justifyContent: "center", flexFlow: "row wrap"}}>
      { loading ? <CircularProgress style={{alignSelf: "center"}} /> :
        (<div style={{width: "100%"}}>
          {filteredAudtis.slice(firstIndexInPage, lastIndexInPage).map((audit: any, i: any) =>
        <div  key={`audit-row-${audit.id}`} style={{width: "100%", display: "flex",
                                                    flexFlow: "row nowrap",
                                                    alignItems: "center",
                                                    height: "60px",
                                                    padding: "0 12px", borderBottom: "solid 1px #e5e2e5"}}>
                              <div style={{height: "100%", minWidth: 24, display: "flex", alignItems: "center", justifyContent: "center"}}>
        {audit.icon}
        </div>
        <div style={{paddingLeft: 15, display: "flex", flexFlow: "column nowrap", flex: 1, maxWidth: "100%"}}>

        <div style={{display: "flex", flexFlow: "row nowrap", height: 19, alignItems: "center", maxWidth: "calc(100%)", justifyContent: "space-between"}}>
        <Typography style={{flex: 1,
                            fontFamily: "Roboto",
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#545964",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: "calc(100vw - 220px)",
                            paddingRight: 5
                            // width: "calc(100% - 177px)"
        }}>{audit.action}</Typography>
        <div style={{display: "flex", alignItems: "center", flexWrap: "nowrap"}}>
        <Typography style={{width: "120px",
                            fontFamily: "Roboto",
                            fontSize: 14,
                            color: "#15111f"
      }}>{audit.formattedDate}</Typography>
        <IconButton>
        <ArrowDown style={{height: 8, width: 12, transform: "rotate(-90deg)" }}/>
        </IconButton>
        </div>
        </div>

        <Typography style={{fontFamily: "Roboto",
                            fontSize: 14,
                            color: "#545964"}}
                            >
                            {`${audit.unitName !== "-" ? audit.unitName + "," : ""} ${audit.siteName !== "-" ? audit.siteName : ""}`}</Typography>
        </div>
        </div>
      )}
      </div>
    )
      }
      </div>
      </div>
      <div style={{paddingTop: 20, paddingBottom: 30}}>
      <Button
      disabled={loading}
      variant="contained"
      className={classes.shareButton}
      startIcon={<Reply style={{ transform: "rotateY(180deg)", marginTop: "-3px" }} />}
      onClick={() => handleAuditShare(filteredAudtis)}
      >
      {t`Share Report`}
      </Button>
      </div>
      </div>
      {opendatePicker && <DatePicker close={() => setOpenDatePicker(false)}/>}
      {openFilters &&
        <Lookup
        filtersList={filterValues}
        appliedFilters={selectedFilters}
        onApply={onApply}
        onClose={() => setOpenedFilters(false)}
        title={t`Audit Log Filters`}
        />
      }
      </>
  );
};

export default AuditsList;
