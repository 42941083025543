import {
    Card,
    Checkbox,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import ErrorBox from "../../components/WarningBox/ErrorBox";
import {
    Add as SvgAdd,
    SwitchOff as SvgSwitchOff,
    SwitchOn as SvgSwitchOn,
    User1 as SvgUser
} from "../../icons/";
import { EditIcon } from "../../logos";
import { IAlertGroup } from "../../models/AlertGroups";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AlertGroup from "./AlertGroup";
import ContactsList from "./ContactsList";
import useStyle from "./Settings.style";

interface IColumn {
    id: string;
    label: string;
}
const AlertGroupsSetting: React.FC<any> = (props: any) => {
    const classes = useStyle();
    const isInitialized = useStoreState((s) => s.isInitialized);
    const allAlertGroups = useStoreState((s) => s.alertGroups.allAlertGroups);
    const getSelectedAlertGroup = useStoreState((state) => state.alertGroups.getSelectedAlertGroup);
    const allSites = useStoreState((state) => state.sites.allSites);
    const me = useStoreState((s) => s.users.me);
    const customerId = me.customer ? me.customer : "";

    const { applications } = useStoreState((state) => state.types.allTypes);
    const getAlertGroupContactData = useStoreState((state) => state.alertGroups.getAlertGroupContactData);
    const alertGroupContacts = useStoreState((state) => state.alertGroups.getAlertGroupContacts);

    const removeContactFromAlertGroup = useStoreActions((action) => action.removeContactFromAlertGroup);
    const updateContactAlertGroup = useStoreActions((action) => action.alertGroups.updateContactAlertGroup);
    const getUserContacts = useStoreActions((action) => action.contacts.getAllContacts);
    const { addMessage } = useStoreActions((action) => action.errorMessage);
    const setAlertGroupSwitch = useStoreActions((action) => action.alertGroups.setAlertGroupSwitch);
    const updateAlertGroup = useStoreActions((action) => action.alertGroups.updateAlertGroup);
    const createAlertGroup = useStoreActions((action) => action.alertGroups.createAlertGroup);
    const deleteAlertGroup = useStoreActions((action) => action.deleteAlertGroup);
    const getTraps = useStoreActions((action) => action.traps.getTraps);
    const [selectedAlertGroupId, setSelectedAlertGroupId] = useState<string>(Object.values(allAlertGroups)[0] ? Object.values(allAlertGroups)[0].id : ""); //values?
    const [editAlertGroup, setEditAlertGroup] = useState<any>(null);
    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const [traps, setTraps] = useState<any>(null);
    const [userContacts, setUserContacts] = useState<any>(null);
    const errorBox = error ? <ErrorBox error={error} onClose={() => setError(null)} /> : null;
    useEffect(() => {
        getTraps({ type: applications.management })
            .then((res: any) => {
                setTraps(res);
            })
            .catch((error: any) => {
                addMessage({ message: error.message });
            });

        getUserContacts()
            .then((res: any) => {
                setUserContacts(res);
            })
            .catch((error: any) => {
                addMessage({ message: error.message });
            });
    }, []);

    if (!isInitialized) { return <Loading />; }

    const columns: IColumn[] = [
        { id: "contact", label: t`Contact` },
        { id: "sms", label: t`SMS` },
        { id: "email", label: t`Email` },
        { id: "delete", label: "" }
    ];
    const handleAlertSwitch = (alertGroup: IAlertGroup) => {
        setAlertGroupSwitch({ id: alertGroup.id });
        updateAlertGroup({ id: alertGroup.id, updatedData: { enable: !alertGroup.enable } });
    };

    const closeDialog = () => {
        setEditAlertGroup(null);
        setOpenAdd(false);
    };

    const handleDelete = (item: any) => {
        setSelectedAlertGroupId(_.values(allAlertGroups)[0] ? _.values(allAlertGroups)[0].id : "");
        deleteAlertGroup({ id: item.id });
    };
    const handleAddNewAlertGroup = (data: any) => {
        const {
            name,
            enablesObject,
            traps,
            allUserTrapsEnable,
            sites
        } = data;

        const alertGroupNew = {
            name,
            enable: true,
            ...enablesObject,
            traps,
            allUserTrapsEnable,
            sites
        };
        createAlertGroup({ id: customerId, alertGroup: alertGroupNew }).catch((err: any) => {
            setError(err.message);
        });
    };
    const handleEditAlertGroup = (data: any) => {
        const {
            name,
            enablesObject,
            traps,
            allUserTrapsEnable,
            sites
        } = data;
        const alertGroup = {
            name,
            ...enablesObject,
            traps,
            allUserTrapsEnable,
            sites
        };
        updateAlertGroup({ id: data.id, updatedData: alertGroup }).catch((err: any) => {
            setError(err.message);
        });
    };
    const handleAddContactToService = (contact: any, service: string) => {
        const selectedAlertGroup = getSelectedAlertGroup(selectedAlertGroupId);
        if (selectedAlertGroup) {
            const contactToEdit = _.find(selectedAlertGroup.contacts, { contact: contact._id });
            if (contactToEdit) {
                let data: any = {
                    sendEmail: getAlertGroupContactData(selectedAlertGroupId, contact._id, "email"),
                    sendSms: getAlertGroupContactData(selectedAlertGroupId, contact._id, "sms")
                };
                if (service === "sms") {
                    data.sendSms = !getAlertGroupContactData(selectedAlertGroupId, contact._id, "sms");
                }
                if (service === "email") {
                    data.sendEmail = !getAlertGroupContactData(selectedAlertGroupId, contact._id, "email");
                }
                updateContactAlertGroup({
                    id: selectedAlertGroupId,
                    contactId: contact._id,
                    updatedData: data
                })
                    .catch((err: any) => {
                        setError(err.message);
                    });
            }
        }
    };
    const deleteContact = (id: any) => {
        if (selectedAlertGroupId) {
            removeContactFromAlertGroup({ id: selectedAlertGroupId, contactId: id.id })
                .catch((err: any) => {
                    setError(err.message);
                });
        }
    };

    return (
        <div className={classes.view}>
            <ServiceNavigationBar {...props} />
            <div className={classes.contentArea}>
                <Header
                    path={["Alert Groups"]}
                    hideFilterArea
                />
                <div style={{ width: "100%", minHeight: "30px" }}></div>
                <Grid container spacing={3} className={classes.container}>
                    <Grid item xs={4}>
                        <Card>
                            {errorBox}
                            <div className={classes.alertGroupHeader}>
                                <Typography variant="h6">{t`Alert Groups`}</Typography>
                                <LightTooltip title={t`Add alert group`}>
                                    <IconButton
                                        className={classes.plusIcon}
                                        aria-label="add icon"
                                        onClick={() => setOpenAdd(true)}
                                    >
                                        <SvgAdd />
                                    </IconButton>
                                </LightTooltip>
                            </div>
                            <TableContainer className={classes.tableContainer}>
                                <div className={classes.innerTableContainer}>
                                    {_.sortBy(Object.values(allAlertGroups), [(alertGroup) => alertGroup.name.toUpperCase()], ["asc"]).map((alertGroup: any) => (
                                        <Card
                                            className={clsx(
                                                classes.alertTypesItem,
                                                selectedAlertGroupId === alertGroup.id ? classes.selectedUnit : ""
                                            )}
                                            key={alertGroup.id}
                                            onClick={() => setSelectedAlertGroupId(alertGroup.id)}
                                        >
                                            <Grid
                                                container
                                                xs={12}
                                                spacing={3}
                                                direction="row"
                                                className={classes.rowContainer}
                                            >
                                                <Grid item xs={8}>
                                                    <Typography key={alertGroup.id} className={classes.alertGroup}>
                                                        {alertGroup.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} className={classes.switch}>
                                                    <IconButton
                                                        classes={{ root: classes.overrideFontSize }}
                                                        aria-label="user icon"
                                                        onClick={() => handleAlertSwitch(alertGroup)}
                                                        style={{ backgroundColor: "transparent" }}
                                                    >
                                                        {alertGroup.enable ? (
                                                            <SvgSwitchOn style={{ color: "white" }} />
                                                        ) : (
                                                                <SvgSwitchOff style={{ color: "white" }} />
                                                            )}
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={2} classes={{ item: classes.icons }}>
                                                    <IconButton onClick={() => setEditAlertGroup(alertGroup)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <Delete
                                                        type={t`Alert Group`}
                                                        object={alertGroup}
                                                        detach={handleDelete}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    ))}
                                </div>
                            </TableContainer>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            <div>
                                <div className={classes.alertGroupHeader}>
                                    <Typography variant="h6">{t`Notification Recipients`}</Typography>
                                </div>
                                <TableContainer className={classes.tableContainer1}>
                                    <div className={classes.innerTableContainer}>
                                        <Table
                                            size="small"
                                            style={{ overflow: "auto" }}
                                            stickyHeader
                                            aria-label="customized table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            classes={{ root: classes.tableHeadCell }}
                                                            key={column.id}
                                                            align={"left"}
                                                            size="small"
                                                        >
                                                            <Typography variant="body2">{column.label}</Typography>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {userContacts &&
                                                    _.sortBy(alertGroupContacts(selectedAlertGroupId), [(contact) => contact.firstName.toUpperCase()], ["asc"])
                                                        .map((contact: any) => {
                                                            const { _id: id, firstName, lastName } = contact;
                                                            return (
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={id}>
                                                                    <TableCell
                                                                        align={"left"}
                                                                        size="small"
                                                                        style={{ width: "40ch", minWidth: "40px" }}
                                                                    >
                                                                        <Typography variant="body2" className={classes.userName}>
                                                                            <SvgUser />
                                                                            {`${firstName} ${lastName}`}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align={"left"} size="small">
                                                                        <Checkbox
                                                                            checked={getAlertGroupContactData(
                                                                                selectedAlertGroupId,
                                                                                id,
                                                                                "sms"
                                                                            )}
                                                                            onChange={() => handleAddContactToService(contact, "sms")}
                                                                            color="default"
                                                                            classes={{ root: classes.grayCheckbox }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align={"left"} size="small">
                                                                        <Checkbox
                                                                            checked={getAlertGroupContactData(
                                                                                selectedAlertGroupId,
                                                                                id,
                                                                                "email"
                                                                            )}
                                                                            onChange={() => handleAddContactToService(contact, "email")}
                                                                            color="default"
                                                                            classes={{ root: classes.grayCheckbox }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align={"left"} size="small">
                                                                        <Delete type={t`Recipient`} object={contact} detach={deleteContact} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </TableContainer>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            <ContactsList
                                selectedAlertGroupId={selectedAlertGroupId}
                                userContacts={userContacts}
                            />
                        </Card>
                    </Grid>
                </Grid>
                {(editAlertGroup || openAdd) && (
                    <AlertGroup
                        close={closeDialog}
                        saveAlert={openAdd ? handleAddNewAlertGroup : handleEditAlertGroup}
                        editAlertGroup={editAlertGroup}
                        allTraps={traps}
                        allSites={_.sortBy(allSites, ["name"], ["asc"])}
                    />
                )}
            </div>
        </div>
    );
};

export default AlertGroupsSetting;
