import React from 'react';

function SvgSwitchOff(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 45 24" {...props}>
      <defs>
        <path id="switch-off_svg__a" d="M.8.769H25V24H.8z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#D5D2D5"
          fillOpacity={0.6}
          d="M36.213 4C40.513 4 44 7.358 44 11.5c0 4.142-3.486 7.5-7.787 7.5H13.788C9.486 19 6 15.642 6 11.5 6 7.358 9.486 4 13.788 4h22.425z"
        />
        <g transform="rotate(-180 12.5 12)">
          <mask id="switch-off_svg__b" fill="currentColor">
            <use xlinkHref="#switch-off_svg__a" />
          </mask>
          <path
            fill="#D5D2D5"
            d="M25 12.384C25 18.8 19.583 24 12.9 24 6.218 24 .8 18.8.8 12.384.8 5.97 6.219.768 12.9.768c6.683 0 12.1 5.2 12.1 11.616"
            mask="url(#switch-off_svg__b)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSwitchOff;
