import { Paper } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { IDateRange } from "../../models/Selections";
import useStyles from "./CoolDateRangePicker.style";
import { DateRange, DateRangePicker } from "./DateRangePicker";
import { DateRangePicker as MobileDateRangePicker } from "./MobileDateRangePicker";

type IHumanDateRange = "last hour" | "last 24 hours" | "last 7 days" | "last 30 days" | "last year";

export interface ICoolDateRangePickerProps {
  initialRange: IDateRange | IHumanDateRange | null;
  handleSubmit: (range: IDateRange | null) => void;
  options?: ICoolDateRangePickerOptions;
  mobile?: boolean;
  customRange?: string;
  clearCustomRange?: any;
}

export interface ICoolDateRangePickerOptions {
  classNameFor?: string;
}
const defaultCoolDateRangePickerOptions = {};

const getRangeFromHDR = (hdr: string) => {
  const range = { begin: new Date(), end: new Date() };
  switch (hdr) {
    case "last hour":
      range.begin = new Date(new Date().getTime() - 1000 * 60 * 60);
      range.end = new Date();
      break;
    case "last 24 hours":
      range.begin = new Date(new Date().setHours(0, 0, 0) - 24 * 60 * 60 * 1000);
      range.end = new Date();
      break;
    case "last 7 days":
      range.begin = new Date(new Date().setHours(0, 0, 0) - 7 * 24 * 60 * 60 * 1000);
      range.end = new Date();
      break;
    case "last 30 days":
      range.begin = new Date(new Date().setHours(0, 0, 0) - 30 * 24 * 60 * 60 * 1000);
      range.end = new Date();
      break;
      case "last year":
        range.begin = new Date(new Date().setHours(0, 0, 0) - 364 * 24 * 60 * 60 * 1000);
        range.end = new Date();
        break;
  }

  return range;
};

export default function CoolDateRangePicker({
  initialRange,
  handleSubmit,
  options,
  mobile = false,
  customRange = "",
  clearCustomRange
}: ICoolDateRangePickerProps) {
  const classes = useStyles();
  const [range, setRange] = useState<DateRange>(
    initialRange === null
      ? getRangeFromHDR("last 24 hours")
      : typeof initialRange === "string"
        ? getRangeFromHDR(initialRange)
        : { begin: initialRange.startDate, end: initialRange.endDate }
  );
  const [customizedRange, setCustomRange] = useState<any>(null);

  options = { ...defaultCoolDateRangePickerOptions, ...options };

  useEffect(() => {
    if (!customRange){
      return;
    }

    const newRange = getRangeFromHDR(customRange);
    setRange(newRange);
    setCustomRange(newRange);

  }, [customRange]);

  const handleSelect = (range: DateRange) => {
    setRange(range);
    if (customRange && clearCustomRange){
      setCustomRange(null);
      clearCustomRange();
    }
  };

  const handleUserSubmit = () => {
    // Make sure the range conver whole days
    handleSubmit({
      // startDate: new Date(range.begin as Date),
      // endDate: new Date(range.end as Date)
      startDate: (range && range.begin) ? new Date(new Date(range.begin.getTime()).setHours(0, 0, 0)) :
        new Date(range.begin as Date),
      endDate: (range && range.end) ? new Date(new Date(range.end?.getTime()).setHours(23, 59, 59)) :
        new Date(range.end as Date)
    });
  };

  return (
    <Paper className={clsx(classes.drpContainer, {[classes.drpContainerMobile]: mobile})}>
      {mobile ?
        <MobileDateRangePicker
          disableFuture
          orientation="portrait"
          variant="static"
          value={range}
          open={true}
          customRange={customizedRange}
          // placeholder="Select a date range"
          onChange={handleSelect}
        /> :
        <DateRangePicker
        disableFuture
        orientation="portrait"
        variant="static"
        value={range}
        open={true}
        customRange={customizedRange}
        // placeholder="Select a date range"
        onChange={handleSelect}
      />}
      <div className={mobile ? classes.drpButtonsMobile : classes.drpButtons}>
        <Button uppercase white width={mobile ? 100 : 90} onClick={() => handleSubmit(null)}>{t`CANCEL`}</Button>
        <Button uppercase width={mobile ? 100 : 70} onClick={handleUserSubmit}>{mobile ? t`Done` : t`OK`}</Button>
      </div>
    </Paper>
  );
}

// On production:
//
// admin
// Abcd@1234
