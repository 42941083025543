import {
    IconButton,
    InputAdornment,
    Menu,
    TextField,
    Typography
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";

import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import useStyles from "./TableColumnSearch.style";

const TableColumnSearch = (props: any) => {
    const { searchColumn, searchValues, anchor, setSearchAnchor, setSearchValues } = props;
    const [columnSearchValues, setColumnSearchValues] = useState<any>(searchValues);
    const classes = useStyles();

    const handleSearchValueChange = (event: any) => {
        const {target: { value }} = event;
        setColumnSearchValues({...columnSearchValues, [searchColumn]: value});
    };

    const stopPropagation = (e: any) => {
        switch (e.key) {
          case "ArrowDown":
          case "ArrowUp":
          case "Home":
          case "End":
            break;
          default:
            e.stopPropagation();
        }
    };
    const onApply = () => {
        setSearchValues(columnSearchValues);
        setSearchAnchor (null);
    };
    return (
        <Menu
        id="filters--menu"
        anchorEl={anchor}
        keepMounted
        open={true}
        onClose={() => setSearchAnchor(null)}
        PaperProps={{ className: classes.searchPaperStyle }}
        >
            <div className={classes.menuHeader}>
                <Typography>{searchColumn}</Typography>
                <Clear onClick={() => setSearchAnchor(null)} />
            </div>
            <TextField
                placeholder="Search"
                variant="outlined"
                value={columnSearchValues[searchColumn]}
                onChange={handleSearchValueChange}
                onKeyDown={stopPropagation}
                className={classes.textField}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton>
                            <Clear
                            onClick={() => setColumnSearchValues({...columnSearchValues, [searchColumn]: ""})} />
                        </IconButton>
                        </InputAdornment>
                    )
                    }}
            />
                <Button
                    className={classes.applyBtn}
                    variant="contained"
                    color="primary"
                    onClick={() => onApply()}
                >{t`Apply`}</Button>
        </Menu >
    );
};

export default TableColumnSearch;
