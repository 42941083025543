import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
  optionStyle: {
    color: "#fff",
    fontSize: "0.8125rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%"
    },
  optionText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    opacity: 0.7,
    fontFamily: "Roboto",
    fontSize: 17,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#fdfdfe"
  },
  selectContainer: {
    height: "50px",
    width: "100%"
  },
  selectStyle: {
    width: "100%",
    color: "#fdfdfe",
    backgroundColor: "#523b53",
    borderRadius: "4px",
    padding: "0 9px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    },
    margin: 0
  },
  iconStyle: {
    right: "0"
  },
  focusStyle: {
    "&:focus": {
      backgroundColor: "#523b53",
      borderRadius: "4px"
    }
  },
  arrowIcon: {
    color: "#fff",
    fontSize: "2.5rem !important",
    top: "0",
    height: "50px"
  },
  paperStyle: {backgroundColor: "#331e38"},
  selected: {backgroundColor: "#523b53 !important"}
}));

export default useStyles;
