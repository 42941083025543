/*global H*/
import React from 'react';

function SvgDashboard(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <defs>
        <path id="dashboard_svg__a" d="M0 0h22v22H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="currentColor"
          strokeWidth={1.417}
          d="M3.843 18.31c1.838-1.76 4.393-2.852 7.22-2.852 2.794 0 5.324 1.069 7.158 2.794"
        />
        <path
          fill="currentColor"
          d="M11.634 8.94l2.547-2.546a1.095 1.095 0 111.55 1.55l-2.546 2.546a2.205 2.205 0 01-2.136 2.778 2.204 2.204 0 11.585-4.329"
        />
        <mask id="dashboard_svg__b" fill="currentColor">
          <use xlinkHref="#dashboard_svg__a" />
        </mask>
        <path
          fill="currentColor"
          d="M11 0C4.925 0 0 4.926 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0m0 1.56c5.206 0 9.44 4.234 9.44 9.44 0 5.206-4.234 9.44-9.44 9.44-5.206 0-9.44-4.234-9.44-9.44 0-5.206 4.234-9.44 9.44-9.44"
          mask="url(#dashboard_svg__b)"
        />
      </g>
    </svg>
  );
}

export default SvgDashboard;
