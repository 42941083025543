import { createStyles, fade } from "@material-ui/core";

export default (theme: any) =>
  createStyles({
    selected: { background: fade("#AAA2AA", 0.3) },
    mainIconsStyle: {
      padding: "11px",
      background: "#4b1c46",
      width: "62px",
      height: "62px",
      color: fade("#fdfdfe", 0.6),
      "&:hover": {
        background: "#5d3358"
      }
    },
    unitView: {
      display: "flex",
      flexFlow: "column nowrap",
      height: "100%",
      width: "calc(100% - 7px)",
      marginTop: "43px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        backgroundColor: "transparent"
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(255,255,255,0.26)"
      }
    },
    firstRowContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      justifyItems: "center",
      alignContent: "center",
      boxSizing: "border-box",
      padding: "0 20px",
      maxWidth: 730,
      alignSelf: "center"
    },
    siteTemp: {
      width: "55px",
      opacity: 0.5,
      fontSize: 26,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff"
    },
    scaleStyle: {
      fontSize: 14
    },
    nameSection: {
      display: "flex",
      flexFlow: "column nowrap",
      maxWidth: "calc(100% - 140px)",
      padding: "0 20px",
      alignItems: "center",
      minHeight: "60px",
      justifyContent: "space-between",
      "& p": {
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        color: "#fff",
        fontFamily: "Roboto",
        fontSize: "26px",
        lineHeight: "26px"
        // fontWeight: "bold",
      }
    },
    loaderContainer: {
      width: "40px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    loaderStyle: { color: fade("#fff", 0.7) },
    powerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: 77,
      height: 74
    },
    powerIconStyle: { padding: 0, zIndex: 100 },
    powerOffButtonClick: {
      "&:active": {
        transition: "all 1s",
        "& circle": {
          fill: "#fff"
        },
        "& path": {
          fill: "#000"
        }
      }
    },
    powerOnButtonClick: {
      "&:active": {
        transition: "all 1s",
        "& circle": {
          fill: "#fff",
          opacity: 1
        },
        "& path": {
          stroke: "#f05146"
        }
      }
    },
    powerTextStyle: {
      marginTop: "8px",
      color: "white",
      height: "16px",
      opacity: 0.5,
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal"
    },
    redErrorContainer: {
      width: "155px",
      height: "155px",
      backgroundColor: "rgba(170, 162, 170, 0.1)",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      flexFlow: "column nowrap",
      marginTop: "122px"
    },
    bigAlertIcon: {
      transform: "scale(1.5)",
      marginBottom: "21px"
    },
    alertBigMessageStyle: {
      lineHeight: "29px",
      fontSize: "22px",
      letterSpacing: "normal",
      color: "#f05146"
    },
    secondRowContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexFlow: "column nowrap",
      paddingBottom: "30px",
      minHeight: "457px",
      justifyContent: "flex-end"
    },
    sensorWrapperStyle: {
      height: "100%",
      width: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      borderRadius: "50%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    iconStyle: {
      fontSize: "17.5px"
    },
    modeIcon: {
      margin: "0 5px"
    },
    smallIcons: {
      transform: "scale(0.5)",
      margin: 0
    },
    alertContainer: {
      display: "flex",
      flexDirection: "column",
      justifyItems: "center",
      alignContent: "center",
      alignItems: "center",
      minHeight: "70px",
      marginBottom: "24px !important"
    },
    alertIcon: { marginBottom: "6px" },
    alertMessageStyle: {
      fontSize: "16px",
      color: "#f05146",
      lineHeight: "16px"
    },

    filterAlert: {
      color: "#f7b500"
    },
    controlDiv: {
      maxHeight: "326px",
      maxWidth: "336px",
      padding: 0,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "326px",
      minWidth: "336px",
      "@media (max-width: 328px)": {
        minWidth: "312px"
      }
    },
    controlWrapperStyle: {
      position: "absolute",
      transform: "scale(1.19)",
      "@media (max-width: 328px)": {
        transform: "scale(0.93)"
      }
    },
    sliderContainer: {
      marginTop: "-20px",
      maxWidth: "320px",
      maxHeight: "320px",
      zIndex: 1
    },
    innerControls: {
      width: "100%",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      marginTop: "-3%",
      alignItems: "center"
    },
    controlArrow: {
      background: "transparent !important",
      zIndex: 100,
      width: "60px",
      height: "60px",
      margin: "-10px 0",

      "&:active": {
        "& path": {
          stroke: `#f05146 !important`
        },
        transition: "all 1s"
      }
    },
    setPointStyle: {
      fontSize: "65px",
      color: "#fff",
      height: 65,
      lineHeight: "normal"
    },
    setPointDiscription: {
      fontSize: "20px",
      color: "#fff",
      height: 65,
      lineHeight: "normal"
    },
    timeDateStyle: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      marginTop: 20
    },
    ampTempContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%"
    },
    ampTempStyle: {
      fontSize: "24px",
      color: "#fff"
    },
    modeTitle: {
      fontSize: "50px",
      color: "#fff",
      fontWeight: "bold",
      position: "absolute"
    },
    lastRowContainer: {
      display: "flex",
      height: "62px",
      alignItems: "flex-end",
      justifyContent: "center",
      width: "100%",
      marginTop: "49px",
      marginBottom: "10px"
    },
    iconContainer: { objectFit: "contain", zIndex: 8 },
    rightSpace: { marginRight: "10px" },
    showStyle: { visibility: "visible" },
    paper: {
      padding: "10px 0px",
      borderRadius: "31px",
      width: "62px",
      boxShadow: `0 2px 4px 0 ${fade("#000", 0.5)}`,
      backgroundColor: "#fdfdfe",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "10px",
      "& li": {
        display: "flex",
        justifyContent: "center",
        borderRadius: "25px",
        padding: 0,
        width: "50px",
        height: "50px"
      }
    },
    removeElements: {
      display: "none"
    },
    innerControlsSensor: {
      width: "100%",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      // marginTop: "-4%",
      alignItems: "center",
      height: "100%"
    },
    measurementUnitsStyle: {
      fontFamily: "Roboto",
      fontSize: 40,
      fontWeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: " #ffffff",
      height: 80,
      lineHeight: "80px",
      position: "absolute",
      right: -5,
      width: 0,
      bottom: 0
    },
    valueContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 94,
      position: "relative"
    },
    valueContainerColumn: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      height: 94
    },
    sensorSliderContainer: {
      marginTop: "11px",
      maxWidth: "320px",
      maxHeight: "152px",
      zIndex: 1
    },
    sensorValues: {
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff"
    },
    sensorValue: {
       fontFamily: "Roboto",
       fontSize: 60,
       fontWeight: "normal",
       fontStretch: "normal",
       fontStyle: "normal",
       lineHeight: "normal",
       letterSpacing: "normal",
       color: "#ffffff"
    },
    sensorValuesContainer: {
      position: "absolute",
      top: "134px",
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      width: "200px",
      right: "68px"
    },
    sideArrowStyle: {
      height: 40
    },
    oppositeSideArrowStyle: {
      height: 40,
      transform: "rotate(180deg)"
    },
    iconBtn: {
      background: "transparent"
    },
    valueControl: {
      position: "absolute",
      top: "145px",
      width: "270px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    disabledIcon: {
    opacity: 0.5
  },
    tempUnit: {
    top: -6
  },
    sensorIcon: {
    height: 80,
    width: 80,
    marginBottom: 17,
    marginTop: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
    dataTimeFont: {
    height: 20,
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: 1.11,
    letterSpacing: "normal",
    color: "#ffffff"
  },
    timeStyle: {
    marginRight: 20
  },
    sensorValueStyle: {
    fontFamily: "Roboto",
    fontSize: 80,
    fontWeight: "normal",
    lineHeight: "80px",
    letterSpacing: "normal",
    color: "#ffffff"
  },
    offText: {
  color: fade("#fff", 0.4)
},
    addTopMargin: {
  marginTop: 75
},
    percentStyle: {
  bottom: "unset",
  height: 40
},
    measurementUnit: {
  fontFamily: "Roboto",
  fontSize: 40,
  fontWeight: "normal",
  letterSpacing: "normal",
  textAlign: "center",
  color: " #ffffff",
  height: 80,
  lineHeight: "80px"
}
  });
