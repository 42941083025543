import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    // margin: '1rem',
    height: "100%",
    marginTop: 0
  },
  wrapper: {
    height: "calc(100vh - 220px)",
    padding: "0 1rem 1rem",
    flexFlow: "row nowrap",
    background: "rgb(236, 235, 239)"
  },
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available",
    maxWidth: "calc(100vw - 240px)"
  },
  container: {
    padding: "3rem"
  },
  tableContainer: {
    height: "calc(100% - 53px)",
    // padding: "1rem",
    width: "auto"
  },
  processingAnimationContainer: {
    display: "flex",
    flexDirection: "column"
  },
  progressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  progress: {
    height: "calc(100vh - 125px)",
    padding: "1rem",
    width: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  hidden: {
    display: "none"
  },
  tableHeadCell: {
    borderBottom: "none"
  },

  alertInfoContainer: {
    height: "100%"
  },
  selected: {
    backgroundColor: "#f4f4f4"
  },
  headContainer: { display: "flex", alignItems: "center", maxWidth: "200px" },
  blueFilter: { fill: "#1976d2" },
  filterStyle: { marginLeft: "10px" },
  dateColumn: { minWidth: "135px" },
  tablePaper: {
    background: "#fefefe",
    padding: "20px",
    paddingTop: 0,
    borderRadius: "4px",
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    border: "solid 1px #ebebeb",
    height: "100%"
  },
  tablePagesHeader: { display: "flex", justifyContent: "flex-end" },
  columnsTitles: {
    border: "none",
    padding: "0 15px",
    fontSize: "13px",
    color: "#79797d",
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "normal",
    backgroundColor: "#efeff2",
    height: 45
  },
  pagingStyle: { border: "none" },
  cellStyle: {
    fontSize: "13px",
    height: "35px",
    padding: "0 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1rem",
    minHeight: "70px",
    background: theme.palette.background.default
  },
  contentHeaderContainer: {
    display: "flex",
    padding: "16px",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "70px"
  },
  shareButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize",
    borderRadius: "6px",
    background: "linear-gradient(to left, #421a40 100%, #29132e 0%)",
    "&:hover": {
      boxShadow: "none"
    },
    "&:focus": {
      boxShadow: "none"
    }
  },
  showWhiteSpace: {
    whiteSpace: "pre"
  },
  limitWidth: {
     minWidth: "23ch",
     maxWidth: "23ch",
     width: "23ch"
   },
  iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    }
}));
export default useStyles;
