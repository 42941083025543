import _ from "lodash";
import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import {t} from "ttag";
import {useStoreState} from "../../models/RootStore";
import graphOptions from "./PowerGraphOptions";

const milliSecondsInHour = 60 * 60 * 1000;
export default function PowerGraph({units, allUnits, filterByState, graphFiltere, graphType, timeForGraph, tenants, numberOfTimeUnits, chosenName, canSeeGraphData, zones}: any) {
    const allTenants = !zones || !zones.length
        ? {}
        : _.reduce(zones, (obj: any, item: any) => {
            obj[item.id] = item;
            return obj;
        }, {});
    const [modesData, setModesData] = useState<any>({
        heat: [], cool: [], other: [],
        heatTime: [], coolTime: [], otherTime: []});
    const [options, setOptions] = useState<any>(graphOptions);

    const seriesMap: any = {
        0: "heat",
        1: "cool",
        2: "other",

        3: "heat",
        4: "cool",
        5: "other"
    };

    const getToolTip = (columnsNames: any, series: any, dataPointIndex: any, toolTipTenantNames: string[]= []) => {
        const type = dataPointIndex % 3 === 0 || dataPointIndex === 0 ? "power" : "time";
        let heat: number = 0;
        let cool: number = 0;
        let other: number = 0;
        let total: number = 0;
        let title: string = columnsNames[dataPointIndex];
        //3 === number of columns between itmes on graph
        const index =  Math.floor(dataPointIndex / 3);
        let totalLabel = type === "time" ? "wHrs" : type;

        if (graphType === "time"){
            // title = numberOfTimeUnits[index]
            title = chosenName;
        }

        if (graphType === "tenant"){
            title = toolTipTenantNames[index];
        }

        //dataPointIndex - is what column i am on - each unit has 3 - power\time\spce
        // series - is the data for all columns. there's an arr of 3 items. each one stores min 3 items for power, time, name
        // first is heat, cool and other then - heatTime, coolTime, otherTime
        if (type === "power"){
            heat = series[0][dataPointIndex] || 0;
            cool = series[1][dataPointIndex] || 0;
            other = series[2][dataPointIndex] || 0;
            total =  heat + cool + other;
        } else{
            heat = series[3][dataPointIndex] || 0;
            cool = series[4][dataPointIndex] || 0;
            other = series[5][dataPointIndex] || 0;
            total =  heat + cool + other;
        }

        // For only Total power tool tip - wrapper div's height is 50px and title div's height is 50%
        return (
            `<div
                    className="arrow_box"
                    style="  border-radius: 5px; height: 120px"
                    >
                    <div style="padding: 4px 2px 2px 2px; display: inline-block; background: #efeff2; height: 20% ; width: 100%">${title}</div>

                    <div style="padding: 0 2px 10px 2px; font-family: RobotoLight""> Total ${totalLabel}: <span style="font-family: RobotoBold">${total.toFixed(1)}</span></div>

                    <div style="padding: 0 2px 10px 2px; font-family: RobotoLight"> Cool: <span style="font-family: RobotoBold">${cool.toFixed(1)}</span></div>
                    <div style="padding: 0 2px 10px 2px; font-family: RobotoLight""> Heat: <span style="font-family: RobotoBold">${heat.toFixed(1)}</span></div>
                    <div style="padding: 0 2px 10px 2px; font-family: RobotoLight""> other: <span style="font-family: RobotoBold">${other.toFixed(1)}</span></div>
                </div>`
        );
    };

    // generate coluns to show total label data - on top of each column
    const labelFormater = () => {
        let onlyOnceCheck: any = {};

        // dataPointIndex === column number
        //seriesIndex === mode (heat, cool, other, other time, cool time, heat time)
        return function(value: any, { seriesIndex, dataPointIndex, w }: any) {
            const type = dataPointIndex % 3 === 0 || dataPointIndex === 0 ? "power" : "time";
            /**
             *
             * @param type {String} : power\time
             * @param index { Number } : column number
             */
            const getSum = (index: number) => {
                let sum: number = 0;
                const start = type === "power" ? 0 : 3;

                for (let i = start; i < start + 3; i++){
                    const newVal = w.config.series[i].data[index] ? _.toNumber(w.config.series[i].data[index]) : 0;
                    sum +=  newVal;
                }
                return Number(sum.toFixed(1));
            };

            // Ignore the top labels (are mirror of the xaxis.categories) as we didn't give them values when are labels
            if (_.isNaN(value)){
                //  to use this use only this and return at bottom "value"
                // const type = dataPointIndex % 3 === 0 || dataPointIndex === 0 ? 'power' : 'time'
                // if(type === 'power'){
                //     const sum =  getSum("power", dataPointIndex);
                //     if(sum === '0.0'){
                //         return;
                //     }
                //     return sum;
                // } else{
                //     const sum =  getSum("time", dataPointIndex);
                //     if(sum === '0.0'){
                //         return;
                //     }
                //     return sum;
                // }
                return;
            }

            /**
             * Check on which mode to place the label - as we show it only on the most top mode
             * @param type {String} : power\time
             * @param index { Number } : column number
             */
            const markLabelPlace = (type: string, index: number) => {
                // 0-3 is power, 4-6 is time :startIndex will let you know what type of data you're generating
                const startIndex = type === "power" ? 0 : 3;

                // Generate the obj holding the check
                if (!onlyOnceCheck[dataPointIndex]){
                    onlyOnceCheck[dataPointIndex] = {};
                }

                // Chose over what power mode\ time mode to show the labels-> as long as it's not 0 : show it
                // and bigger then 0.3 - smaller then this will not show label
                if (w.config.series[startIndex + 2].data[index]
                    && Number(w.config.series[startIndex + 2].data[index]) !== 0.0){
                    onlyOnceCheck[dataPointIndex].other = true;
                    return;
                } else if (w.config.series[startIndex + 1].data[index]
                    && Number(w.config.series[startIndex + 1].data[index]) !== 0.0){
                    onlyOnceCheck[dataPointIndex].cool = true;
                    return;
                }    else if (w.config.series[startIndex].data[index]
                    && Number(w.config.series[startIndex].data[index]) !== 0.0){
                    onlyOnceCheck[dataPointIndex].heat = true;
                    return;
                }

            };

            // Check on which mode to place the label
            markLabelPlace(type, dataPointIndex);

            // Will only set the value to the last colum in the stack
            if (onlyOnceCheck[dataPointIndex].other && seriesMap[seriesIndex] === "other"){
                return getSum(dataPointIndex);
            }

            if (onlyOnceCheck[dataPointIndex].cool && seriesMap[seriesIndex] === "cool"){
                return getSum(dataPointIndex);
            }

            if (onlyOnceCheck[dataPointIndex].heat && seriesMap[seriesIndex] === "heat"){
                return getSum(dataPointIndex);
            }

            return ;

        };

    };

    const unitView = () => {

        const unitsNames: any = [];
        const heatValues: any = [];
        const coolValues: any = [];
        const otherValues: any = [];
        const columnsNames: any = [];

        const heatTime: any = [];
        const coolTime: any = [];
        const otherTime: any = [];

        let maxPower = 0;
        let maxTime = 0;

        if (_.isEmpty(units)){
            unitsNames.push("no units to show");
        }

        Object.keys(units).forEach((unitId: any) => {
            let name = "";
            const getMaxTimeAndPower = () => {

                const tempMaxPower = _.parseInt((units[unitId].heatPowerUsed || 0))
                    + _.parseInt((units[unitId].coolPowerUsed || 0))
                    + _.parseInt((units[unitId].otherPowerUsed || 0));

                if (maxPower <= tempMaxPower){
                    maxPower = tempMaxPower;
                }

                const tempMaxTime = (Math.round((units[unitId].heatModeTime / milliSecondsInHour) * 10) / 10 || 0)
                    + (Math.round((units[unitId].coolModeTime / milliSecondsInHour) * 10) / 10 || 0)
                    + (Math.round((units[unitId].otherModeTime / milliSecondsInHour) * 10) / 10 || 0);

                if (maxTime <= tempMaxTime){
                    maxTime = tempMaxTime;
                }

            };

            // values and times each have 3 items to align graph right
            heatValues.push((units[unitId].heatPowerUsed || 0).toFixed(1), "", null);
            coolValues.push((units[unitId].coolPowerUsed || 0).toFixed(1), "", null);
            otherValues.push((units[unitId].otherPowerUsed || 0).toFixed(1), "", null);

            heatTime.push("",  (Math.round((units[unitId].heatModeTime / milliSecondsInHour) * 10) / 10 || 0).toFixed(1), null);
            coolTime.push("", (Math.round((units[unitId].coolModeTime / milliSecondsInHour) * 10) / 10 || 0).toFixed(1), null);
            otherTime.push("",  (Math.round((units[unitId].otherModeTime / milliSecondsInHour) * 10) / 10 || 0).toFixed(1), null);

            // Power meta data
            name = allUnits[unitId] && allUnits[unitId].name  || "";
            unitsNames.push(name);
            columnsNames.push(name);

            // time meta data
            name = allUnits[unitId] && allUnits[unitId].name  || "";
            unitsNames.push("");
            columnsNames.push(name);
            // adding space between the graphs names
            unitsNames.push("");
            columnsNames.push("");

            // Get max time and power for graph Yaxis
            getMaxTimeAndPower();

        });

        // Were i add the categories into the option object
        const {xaxis, yaxis, tooltip, dataLabels, labels, plotOptions} = options;
        xaxis.categories = [...unitsNames];

        // Don't show text in column if...
        if (units.length > 15){
            xaxis.labels.rotate = -90;
            // xaxis.offsetX = 40;
        }

        // To just change the text itself in the pre-made tooltip of the chart
        // tooltip.y.formatter = function (val:any) {
        //     return "$ " + val + " thousands"
        // }

        tooltip.custom = function({series, seriesIndex, dataPointIndex, w}: any) {
            return getToolTip(columnsNames, series, dataPointIndex);
        };

        // place labels over the columns
        dataLabels.formatter =  labelFormater();

        // set yAxis number of markers
        if (maxTime){
            yaxis[3].max = Math.round(maxTime) + 1;
            yaxis[4].max = Math.round(maxTime) + 1;
            yaxis[5].max = Math.round(maxTime) + 1;
        }
        if (maxPower){
            yaxis[0].max = Math.round(maxPower) + 1;
            yaxis[1].max = Math.round(maxPower) + 1;
            yaxis[2].max = Math.round(maxPower) + 1;
        }

        const theOptions = !_.isEmpty(units) ? options : {...options, ...{noData: {text: ""}}};
        setOptions({...theOptions});

        setModesData({heat: [...heatValues], cool: [...coolValues], other: [...otherValues], heatTime: [...heatTime], coolTime: [...coolTime], otherTime: [...otherTime]});
    };

    const timeView = () => {
        let divideBy = 0;
        const columnsNames: any = [];
        const heatValues: any = [];
        const coolValues: any = [];
        const otherValues: any = [];
        const columnsNamesForToolTime: any = [];

        const heatTime: any = [];
        const coolTime: any = [];
        const otherTime: any = [];

        let maxPower = 0;
        let maxTime = 0;

        if (_.isEmpty(units)){
            return;
        }

        const getMaxTimeAndPower = (oneChunk: any) => {

            const tempMaxPower = _.parseInt((oneChunk.heatValues || 0))
                + _.parseInt((oneChunk.coolValues || 0))
                + _.parseInt((oneChunk.otherValues || 0));

            if (maxPower <= tempMaxPower){
                maxPower = tempMaxPower;
            }

            const tempMaxTime = Number(oneChunk.heatModeTime)
                + Number(oneChunk.coolModeTime)
                + Number(oneChunk.otherModeTime);

            if (maxTime <= tempMaxTime){
                maxTime = tempMaxTime;
            }

        };

        let powerPerChunk = new Array(numberOfTimeUnits.length);

        Object.keys(units).forEach((unitId: any) => {
            // in-case received "unit is not is associated system"
            if (!units[unitId].buckets){
                for (let i = 0; i < powerPerChunk.length; i++){
                    powerPerChunk[i] = {
                        heatValues : 0,
                        coolValues : 0,
                        otherValues : 0,
                        heatModeTime: 0,
                        coolModeTime: 0,
                        otherModeTime: 0
                    };
                }
            }

            // sum all units power usage per timeUnit
            _.forEach(units[unitId].buckets, (chunk: any, i: number) => {
                // Add the obj to an empty arr item
                if (!powerPerChunk[i]){
                    powerPerChunk[i] = {
                        heatValues : 0,
                        coolValues : 0,
                        otherValues : 0,
                        heatModeTime: 0,
                        coolModeTime: 0,
                        otherModeTime: 0
                    };
                }

                powerPerChunk[i].heatValues += chunk.heatPowerUsed || 0;
                powerPerChunk[i].coolValues += chunk.coolPowerUsed || 0;
                powerPerChunk[i].otherValues += chunk.otherPowerUsed || 0;

                powerPerChunk[i].heatModeTime += Math.round((chunk.unitHeatTime / milliSecondsInHour) * 10) / 10 || 0;
                powerPerChunk[i].coolModeTime += Math.round((chunk.unitCoolTime / milliSecondsInHour) * 10) / 10 || 0;
                powerPerChunk[i].otherModeTime += Math.round((chunk.unitOtherTime / milliSecondsInHour) * 10) / 10 || 0;

            });
        });

        // Create the chart - pushing each time unit into the table
        _.forEach(powerPerChunk, (oneChunk, i) => {
            heatValues.push(oneChunk.heatValues.toFixed(1), "", null);
            coolValues.push(oneChunk.coolValues.toFixed(1), "", null);
            otherValues.push(oneChunk.otherValues.toFixed(1), "", null);

            heatTime.push("", oneChunk.heatModeTime.toFixed(1), null);
            coolTime.push("", oneChunk.coolModeTime.toFixed(1), null);
            otherTime.push("", oneChunk.otherModeTime.toFixed(1), null);

            // build xAxis titles (hours format/days)
            let lableTitle = numberOfTimeUnits[i];
            if (_.includes(lableTitle, ":")){
                const [, hourTitle, min] = lableTitle.split(" ");
                if (hourTitle[0] === "0"){
                    lableTitle = `${hourTitle}00`;
                } else{
                    lableTitle = hourTitle;
                }
            } else {
                const [day, month] = numberOfTimeUnits[i].split("/");
                lableTitle = day + "/" + month;
            }

            // starting day/hour count from 1
            columnsNames.push(`${lableTitle}`);
            columnsNames.push(``);
            columnsNames.push(" ");

            columnsNamesForToolTime.push(`${numberOfTimeUnits[i]}`);
            columnsNamesForToolTime.push(`${numberOfTimeUnits[i]}`);
            columnsNamesForToolTime.push(``);

            // Get max time and power for graph Yaxis
            getMaxTimeAndPower(oneChunk);
        });

        const {xaxis, yaxis, tooltip, plotOptions, dataLabels} = options;
        xaxis.categories = [...columnsNames];
        // place labels over the columns
        dataLabels.formatter =  labelFormater();

        // Don't show text in column if...
        if (numberOfTimeUnits.length > 15){
                xaxis.labels.rotate = -90;
            }

        tooltip.custom = function({series, seriesIndex, dataPointIndex, w}: any) {
            return getToolTip(columnsNames, series, dataPointIndex);
        };

        // set yAxis number of markers
        if (maxTime){
            yaxis[3].max = Math.round(maxTime) + 1;
            yaxis[4].max = Math.round(maxTime) + 1;
            yaxis[5].max = Math.round(maxTime) + 1;
        }
        if (maxPower){
            yaxis[0].max = Math.round(maxPower) + 1;
            yaxis[1].max = Math.round(maxPower) + 1;
            yaxis[2].max = Math.round(maxPower) + 1;
        }

        setOptions({...options});

        setModesData({heat: [...heatValues], cool: [...coolValues], other: [...otherValues], heatTime: [...heatTime], coolTime: [...coolTime], otherTime: [...otherTime]});

    };

    const tenantView = () => {
        const tenantNames: any = [];
        const heatValues: any = [];
        const coolValues: any = [];
        const otherValues: any = [];
        const columnsNames: any = [];

        const heatTime: any = [];
        const coolTime: any = [];
        const otherTime: any = [];

        let maxPower = 0;
        let maxTime = 0;

        // create list of tenants (with thier units) of the system
        const systemsUnits = Object.keys(units);
        const tenantsBySystem: any = [];
        const toolTipTenantNames: string[] = [];
        _.forEach(tenants, (tenant, tenantId) => {
            const tenantUnits = Object.keys(tenant.units);
            const systemsTenant = _.intersection(tenantUnits, systemsUnits);
            if (systemsTenant.length){
                let heatPowerUsed: number = 0;
                let heatModeTime: number = 0;
                let coolPowerUsed: number = 0;
                let coolModeTime: number = 0;
                let otherPowerUsed: number = 0;
                let otherModeTime: number = 0;

                _.forEach(systemsTenant, (unitId) => {
                    heatPowerUsed += units[unitId].heatPowerUsed || 0;
                    coolPowerUsed += units[unitId].coolPowerUsed || 0;
                    otherPowerUsed += units[unitId].otherPowerUsed || 0;

                    heatModeTime +=  Math.round((units[unitId].heatModeTime / milliSecondsInHour) * 10) / 10 || 0;
                    coolModeTime += Math.round((units[unitId].coolModeTime / milliSecondsInHour) * 10) / 10 || 0;
                    otherModeTime += Math.round((units[unitId].otherModeTime / milliSecondsInHour) * 10) / 10 || 0;
                });

                tenantsBySystem.push({
                    id: tenantId,
                    unitsData: {
                        heatPowerUsed: heatPowerUsed.toFixed(1),
                        heatModeTime: heatModeTime.toFixed(1),
                        coolPowerUsed: coolPowerUsed.toFixed(1),
                        coolModeTime: coolModeTime.toFixed(1),
                        otherPowerUsed: otherPowerUsed.toFixed(1),
                        otherModeTime: otherModeTime.toFixed(1)
                    }
                    });
            }
        });

        const tenantsIObj = _.reduce(allTenants, (obj: any, tenant: any) => {
                obj[tenant.id] = tenant;
                return obj;
            }, {});

        // populate the graph with tenants
        _.forEach(tenantsBySystem, (tenant) => {
            if (!tenantsIObj[tenant.id]){
                return;
            }

            const getMaxTimeAndPower = () => {
                const tempMaxPower = _.parseInt(tenant.unitsData.heatPowerUsed)
                    + _.parseInt(tenant.unitsData.coolPowerUsed)
                    + _.parseInt(tenant.unitsData.otherPowerUsed);

                if (maxPower <= tempMaxPower){
                    maxPower = tempMaxPower;
                }

                const tempMaxTime = _.parseInt(tenant.unitsData.heatModeTime)
                    + _.parseInt(tenant.unitsData.coolModeTime)
                    + _.parseInt(tenant.unitsData.otherModeTime);

                if (maxTime <= tempMaxTime){
                    maxTime = tempMaxTime;
                }

            };

            // values and times each have 3 items to align graph right
            heatValues.push(tenant.unitsData.heatPowerUsed || 0, "", null);
            coolValues.push(tenant.unitsData.coolPowerUsed || 0, "", null);
            otherValues.push(tenant.unitsData.otherPowerUsed || 0, "", null);

            heatTime.push("", tenant.unitsData.heatModeTime, null);
            coolTime.push("", tenant.unitsData.coolModeTime, null);
            otherTime.push("", tenant.unitsData.otherModeTime , null);

            const power = `${tenantsIObj[tenant.id].name}` || "unknown tenant power";
            const time = `${tenantsIObj[tenant.id].name}` || "unknown tenant time";
            tenantNames.push(power);
            tenantNames.push("");
            tenantNames.push("");

            columnsNames.push(power);
            columnsNames.push(time);
            columnsNames.push("");

            // For tooltip
            toolTipTenantNames.push(tenantsIObj[tenant.id].name);

            // Get max time and power for graph Yaxis
            getMaxTimeAndPower();

        });

        const {xaxis, yaxis, tooltip, plotOptions, dataLabels} = options;
        xaxis.categories = [...tenantNames];

        // Rotate bottom lables when too many labels
        if (tenantsBySystem.length > 10){
            xaxis.labels.rotate = -90;
        }

        tooltip.custom = function({series, seriesIndex, dataPointIndex, w}: any) {
            return getToolTip(columnsNames, series, dataPointIndex, toolTipTenantNames);
        };

        // place labels over the columns
        dataLabels.formatter =  labelFormater();

        // set yAxis number of markers
        if (maxTime){
            yaxis[3].max = Math.round(maxTime) + 1;
            yaxis[4].max = Math.round(maxTime) + 1;
            yaxis[5].max = Math.round(maxTime) + 1;
        }
        if (maxPower){
            yaxis[0].max = Math.round(maxPower) + 1;
            yaxis[1].max = Math.round(maxPower) + 1;
            yaxis[2].max = Math.round(maxPower) + 1;
        }

        setOptions({...options});

        setModesData({heat: [...heatValues], cool: [...coolValues], other: [...otherValues], heatTime: [...heatTime], coolTime: [...coolTime], otherTime: [...otherTime]});

    };

    useEffect(() => {
       if (graphType === "unit"){
           unitView();
       }

       if (graphType === "time"){
           timeView();
       }

       if (graphType === "tenant"){
            tenantView();
        }

    }, [units, allUnits, timeForGraph, graphType]);

    // create chart based on other\cool\heat\total
    const createSeries = () => {
        const filterBy = [];
        const heat = {
            name: t`Heat mode`,
            data: []
        };
        const cool = {
            name: t`Cool mode`,
            data: []
        };
        const other = {
            name: t`Other mode`,
            data: []
        } ;
        const heatTime = {
            name: t`Heat time`,
            data: []
        };
        const coolTime = {
            name: t`Cool time`,
            data: []
        };
        const otherTime = {
            name: t`Other time`,
            data: []
        } ;

        // when not knowing system capacity don't show system data in the graph
        if (!canSeeGraphData){
            // return []
            return [heat, cool, other, heatTime, coolTime, otherTime];
        }

        // Set data by mode
        if (filterByState === "cool"){
            filterBy.push(heat);
            filterBy.push({...cool, ...{data: [...modesData.cool]}});
            filterBy.push(other);

            filterBy.push(heatTime);
            filterBy.push({...coolTime, ...{data: [...modesData.coolTime]}});
            filterBy.push(otherTime);

        }
        if (filterByState === "heat"){
            filterBy.push({...heat, ...{data: [...modesData.heat]}});
            filterBy.push(cool);
            filterBy.push(other);

            filterBy.push({...heatTime, ...{data: [...modesData.heatTime]}});
            filterBy.push(coolTime);
            filterBy.push(otherTime);
        }
        if (filterByState === "other"){
            filterBy.push(heat);
            filterBy.push(cool);
            filterBy.push({...other, ...{data: [...modesData.other]}});

            filterBy.push(heatTime);
            filterBy.push(coolTime);
            filterBy.push({...otherTime, ...{data: [...modesData.otherTime]}});
        }

        if (filterByState === "total"){
            filterBy.push({...heat, ...{data: [...modesData.heat]}});
            filterBy.push({...cool, ...{data: [...modesData.cool]}});
            filterBy.push({...other, ...{data: [...modesData.other]}});

            filterBy.push({...heatTime, ...{data: [...modesData.heatTime]}});
            filterBy.push({...coolTime, ...{data: [...modesData.coolTime]}});
            filterBy.push({...otherTime, ...{data: [...modesData.otherTime]}});
        }

        return filterBy;
    };

    const series = createSeries();

    return (
            <Chart
                options={options}
                series={series}
                width="100%"
                height="100%"
                type="bar"
                key={JSON.stringify(series)}
            />
    );

}
