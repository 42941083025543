import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  thunk,
  Thunk,
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface IGroup {
  id: string;
  units: string[];
  name?: string;
  site: string;
  customer: string;
  description?: string;
  role: any;
  type?: string;
}

export interface IGroupMap {
  [key: string]: IGroup;
}

interface IPowerPayload {
  groupId: string;
  state: number;
}

interface ISetPointPayload {
  groupId: string;
  setPoint: number;
}

interface ICreateGroup {
  customerId: string;
  data: any;
}

interface IUpdateGroup {
  groupId: string;
  data: any;
}

export interface IGroupsModel {
  allGroups: IGroupMap;
  getGroup: Thunk<IGroupsModel, string, any>;
  selectedGroups: IGroupMap;
  initialize: Action<IGroupsModel, any>;
  setSelectedGroups: Action<IGroupsModel, any>;
  getGroupsBySiteId: Thunk<IGroupsModel, string>;
  onInitialized: ActionOn<IGroupsModel, IRootStoreModel>;
  getTenants: Computed<IGroupsModel>;
  getSelectedTenants: Computed<IGroupsModel>;
  createGroup: Thunk<IGroupsModel, { siteId: string; data: any }>;
  updateGroup: Thunk<IGroupsModel, { id: string; data: any }>;
  updateGroupsState: Action<IGroupsModel, IGroup>;
  addGroup: Action<IGroupsModel, IGroup>;
  deleteGroup: Thunk<IGroupsModel, string>;
  removeGroup: Action<IGroupsModel, string>;
  changePowerState: Thunk<IGroupsModel, IPowerPayload>;
  changeSetPoint: Thunk<IGroupsModel, ISetPointPayload>;
  getFullGroups: Thunk<IGroupsModel, void>;
  createCustomerGroup: Thunk<IGroupsModel, ICreateGroup, any>;
  deleteGroupAPI: Thunk<IGroupsModel, string>;
  updateGroupAPI: Thunk<IGroupsModel, IUpdateGroup>;
  getCustomerGroups: Thunk<IGroupsModel, string>;
  getMygroups: Thunk<IGroupsModel>;
}

export const groupsModel: IGroupsModel = {
  allGroups: {},
  selectedGroups: {},
  initialize: action((state, payload) => {
    const newGroups: IGroupMap = _(Object.values(payload))
      .map((group: any) => {
        const newGroup: IGroup = { ...group };
        return newGroup;
      })
      .keyBy("id")
      .value();

    state.allGroups = newGroups;
  }),
  getGroup: thunk(async (actions, payload) => {
    return coolremoteSDK.Group.getGroupById(payload);
  }),
  setSelectedGroups: action((state, payload) => {
    const newGroups: IGroupMap = _(Object.values(payload))
      .map((group: any) => {
        const newGroup: IGroup = { ...group };
        return newGroup;
      })
      .keyBy("id")
      .value();

    state.selectedGroups = newGroups;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {}
  ),

  getTenants: computed((state) => {
    const tenants = _.filter(state.allGroups, (group) => group.type === "2");
    return tenants || [];
  }),

  getSelectedTenants: computed((state) => {
    const tenants = _.filter(state.selectedGroups, (group) => group.type === "2");
    return tenants || [];
  }),

  getGroupsBySiteId: thunk(async (actions, payload) => {
    const response = await coolremoteSDK.Site.getGroups(payload);
    // actions.setSelectedGroups(response);
    actions.initialize(response);
    return response;
  }),

  deleteGroup: thunk(async (actions, payload) => {
    const response = await coolremoteSDK.Group.delete(payload);
    actions.removeGroup(payload);
    return response;
  }),

  createGroup: thunk(async (actions, payload) => {
    const newGroup = await coolremoteSDK.Site.createGroup(
      payload.siteId,
      payload.data
    );
    actions.addGroup(newGroup);
    return newGroup;
  }),

  updateGroup: thunk(async (actions, payload) => {
    const updatedGroup = await coolremoteSDK.Group.update(
      payload.id,
      payload.data
    );
    actions.updateGroupsState(updatedGroup);
    return updatedGroup;
  }),

  addGroup: action((state, payload) => {
    state.allGroups[payload.id] = payload;
  }),

  removeGroup: action((state, payload) => {
    delete state.allGroups[payload];
  }),

  updateGroupsState: action((state, payload) => {
    state.allGroups[payload.id] = payload;
  }),
  changeSetPoint: thunk((actions, payload) => {
    const { groupId, setPoint } = payload;

    return coolremoteSDK.Group.setActiveSetpoint(groupId, setPoint);
  }),
  changePowerState: thunk((actions, payload) => {
    const { groupId, state } = payload;

    return coolremoteSDK.Group.setActiveOperationStatus(groupId, {
      operationStatus: state
    });
  }),
  getFullGroups: thunk(async (actions, payload) => {
    return coolremoteSDK.Group.getFullGroups();

    // actions.setGroups(groups);
    // actions.setGroupUnitsMap(unitsMap);
    // actions.setGroupSiteMapping(sitesMap);
    // actions.setGroupsCalled(true);
  }),
  createCustomerGroup: thunk(async (actions, payload) => {
    const { customerId, data } = payload;
    return coolremoteSDK.Customer.createGroup(customerId, data);
  }),
  deleteGroupAPI: thunk(async (actions, payload) => {
    return coolremoteSDK.Group.delete(payload);
  }),
  updateGroupAPI: thunk(async (actions, payload) => {
    const { groupId, data } = payload;
    return coolremoteSDK.Group.update(groupId, data);
  }),
  getCustomerGroups: thunk(async (actions, payload) => {
   return coolremoteSDK.Customer.getGroups(payload);
 }),
  getMygroups: thunk(async (actions, payload) => {
  return coolremoteSDK.Group.getGroups();
})
};
