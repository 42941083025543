const options = {
  noData: {
text: "Loading..."
},
  subtitle: {
        text: undefined,
        align: "left",
        offsetX: 0,
        style: {
            fontSize:  "22px", }
    },
  chart: {
    fontFamily: "Roboto",
    redrawOnParentResize: true,
    redrawOnWindowResize: true,
    height: "100%",
    width: "100%",
    type: "bar",
    stacked: true,
    toolbar: {
            show: false
          },
    zoom: {
            enabled: true
          }
        },
  responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
  plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "70%",
            dataLabels: {
                  position: "top",
                  hideOverflowingLabels: false
              }
              // rangeBarOverlap: true
          }
        },
  xaxis: {
          type: "category",
          categories: [],
          labels: {}
        },
  legend: {
      show: false,
      position: "bottom",
      offsetX: -20,
      offsetY: 10,
      horizontalAlign: "left"
        },
  fill: {
          opacity: 1
        },
  colors: ["#f05347", "#35a8e0", "#aaa2aa", "#5e5764", "#5e5764", "#5e5764"],
  tooltip: {
        // function to change values in tootip
        // y: {
        //     formatter: {}
        // },
        // // Show the title
        // x: {
        //     show: true,
        // },
        // // The samll colors to see to what mode the tooltip belongs
        // marker: {
        //     show: false,
        // },
    },
  dataLabels: {
        enabled: true,
        offsetY: -20,
        // enabledOnSeries: [1],
        style: {
            colors: ["#000000"],
            fontWeight: 300
        },
        formatter: {}

    },
  grid: {
      show: true,
      // borderColor: "#90A4AE",
      // strokeDashArray: 0,
      position: "back",
      padding: {
            top: 30,
            right: 20,
            bottom: 10,
            left: 20
        }
    },

    // One object for each series - calculates automatically min and mac
  yaxis: [
        {
            seriesName: "Heat mode",
           // show:false
            title: {
                text: "Power (kWh)"
            }

            // max: 5,
        },
        {
            seriesName: "Heat mode",
            show: false
            // max: 5
        },
        {
            seriesName: "Heat mode",
            show: false
            // max: 5
        },
        {
            seriesName: "Heat time",
            opposite: true,
            title: {
                text: "Working Hrs"
            }
            //     min: 0,
            //     max: 16
        },
        {
            seriesName: "Heat time",
            show: false
            // max: 16
        },
        {
            seriesName: "Heat mode",
            show: false
            // max: 16
        }
    ]

};
export default options;
