import {
  Button as MuiButton,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  TextField,
  Typography
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import { isIOS } from "react-device-detect";
import { t } from "ttag";
import Switch from "../../../components/Switch/Switch";
import TimePicker from "../../../components/TimePicker/TimePicker";
import Button from "../../../cool_widgets/Button";
import Checkbox from "../../../cool_widgets/CoolCheckbox";
import DaysList from "../../../cool_widgets/DaysList/DaysList";
import {Check, Close} from "../../../icons";
import { useStoreActions, useStoreState } from "../../../models/RootStore";
import {
  isEndStampLaterThanStartStamp,
  minsToTime,
  stringTimeToUTCMins
} from "../../../services/timeService";
import { ArrowDownControl , ArrowUp, SearchIcon} from "../../../svgComponents";
import useStyles from "./AddEditSchedulePopup.style";

export default function ErrorBox(props: any) {
  const classes = useStyles();
  const { onClose, scheduleId, itemId, itemType, schedule, updateLocalSchedules, item, updateStoreUnit} = props;
  const types = useStoreState((state) => state.types.allTypes);
  const { weekDays = [""] } = types;
  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const me = useStoreState((state) => state.users.me);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay());
  const { temperatureScale, timeFormat: timeFormatEnum } = me;

  const [scheduleDisabled, setScheduleStatus] = useState<boolean>(false);
  const [powerOnTime, setPowerOnTime] = useState<string>("");
  const [powerOffTime, setPowerOfftime] = useState<string>("");
  const [setpoint, setSetpoint] = useState<number>(temperatureScale === 0 ? 24 : 75); //check if f also 75
  const [days, setDays] = useState<any>([]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [setpointEnabled, enableSetpoint] = useState<boolean>(false);
  const [openPicker, setOpenPicker] = useState<string>("");
  const [weekDaysOptions] = useState<any>(Object.keys(weekDays));

  const addMessage = useStoreActions((actions) => actions.errorMessage.addMessage);
  const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);
  const createObjectSchedules = useStoreActions((actions) => actions.schedules.createObjectSchedules);

  const isGroup = false;
  const is12Hours = timeFormatEnum === 1 ? true : false;

  const passScheduleInfo = (schedule: any) => {
  const { powerOnTime, powerOffTime, setpoint, days = [], isDisabled } = schedule;

  setScheduleStatus(isDisabled);
  setPowerOnTime(hasValue(powerOnTime) ? minsToTime(powerOnTime, timeFormat) : "");
  setPowerOfftime(hasValue(powerOffTime) ? minsToTime(powerOffTime, timeFormat) : "");

  setpoint && setSetpoint(setpoint);
  setpoint && enableSetpoint(true);
  setDays(days as []);
};

  const hasValue = (value: any) => {
  return !!value || value === 0;
};

  useEffect(() => {
  if (scheduleId === "new"){
    return;
  }

  passScheduleInfo(schedule);
}, []);

  const checkRequiredFields = () => {
  setErrorMsg("");

  if (!powerOnTime && !powerOffTime) {
    setErrorMsg("Add at least start or end hour");
    return false;
  }

  if (days.length === 0) {
    setErrorMsg("Pick one day at least");
    return false;
  }

  if (!(powerOnTime && powerOffTime)) {
    return true;
  }

  if (!isEndStampLaterThanStartStamp(powerOnTime, powerOffTime, is12Hours)) {
    setErrorMsg("End hour must be later than start hour");
    return false;
  }

  return true;
};

  const editSchedule = () => {
  const {
    powerOnTime: defaultPowerOff,
    powerOffTime: defaultPowerOn,
    setpoint: defaultSetpoint
  } = schedule;

  const startHour =
    powerOnTime === ""
      ? defaultPowerOn === 0
        ? undefined
        : null
      : stringTimeToUTCMins(powerOnTime, is12Hours);

  const endHour =
    powerOffTime === ""
      ? defaultPowerOff === 0
        ? undefined
        : null
      : stringTimeToUTCMins(powerOffTime, is12Hours);

  let setpointValue: any;
  if (setpointEnabled) {
    setpointValue = setpoint;
  } else {
    if (defaultSetpoint) {
      setpointValue = null;
    } else {
      setpointValue = undefined;
    }
  }

  updateSchedule({
    id: scheduleId,
    data: {
      isDisabled: scheduleDisabled,
      name: "New Schedule",
      powerOnTime: startHour,
      powerOffTime: endHour,
      setpoint: setpointValue,
      days
    }
  })
  .then((schedule: any) => {
    updateLocalSchedules(schedule);
    onClose();
  })
    .catch((err: any) => {
      addMessage({
        message: err.message
      });
    });
};

  const createSchedule = () => {
  const startHour = powerOnTime  ? stringTimeToUTCMins(powerOnTime, is12Hours) : undefined;
  const endHour = powerOffTime ? stringTimeToUTCMins(powerOffTime, is12Hours) : undefined;
  const setpointValue = setpointEnabled ? setpoint : undefined;

  createObjectSchedules({
    data: {
      isDisabled: scheduleDisabled,
      days,
      powerOnTime: startHour,
      powerOffTime: endHour,
      setpoint: setpointValue,
      name: "new Schedule"
    },
    objId: itemId,
    objectType:  itemType
  })
    .then((schedule: any) => {
      if (itemType === "unit"){
        const {schedules, id} = item;
        schedules.push(schedule.id);
        updateStoreUnit({id, data: {schedules}});
      }
      updateLocalSchedules(schedule);
      onClose();
    })
    .catch((err: any) => {
      addMessage({
        message: err.message
      });
    });
};

  const save = () => {
  const allRequiredNotEmpty = checkRequiredFields();

  if (!allRequiredNotEmpty) {
    return;
  }

  if (scheduleId === "new") {
    createSchedule();
    return;
  }

  editSchedule();
};

  const decreaseSetpoint = () => {
  setSetpoint(setpoint - 1);
};

  const increaseSetpoint = () => {
  setSetpoint(setpoint + 1);
};

  const addRemoveDay = (selectedDay: string) => {
  let currentDays: any = [...days];

  currentDays.includes(selectedDay)
    ? (currentDays = days.filter((day: string) => day !== selectedDay))
    : currentDays.push(selectedDay);

  setDays(currentDays);
};

  const handleSetpoint = () => {
  enableSetpoint(!setpointEnabled);
};

  const changeScheduleStatus = () => {
  setScheduleStatus(!scheduleDisabled);
};

  const onClear = () => {
  setOpenPicker("");
  openPicker === "start" ? setPowerOnTime("") : setPowerOfftime("");
};

  const onSetTime = (time: string) => {
  openPicker === "start" ? setPowerOnTime(time) : setPowerOfftime(time);
  setOpenPicker("");
};

  const hasTime = (time: any) => {
  return time !== "" && time !== null && time >= 0;
};

  const onInput = (event: any, powerTime: "start" | "end") => {
  const { target } = event.nativeEvent;

  if (target.defaultValue && target.defaultValue === target.value) {
    powerTime === "start" ? setPowerOnTime("") : setPowerOfftime("");
  }

  function iosClearDefault() {
    target.defaultValue = "";
  }
  window.setTimeout(iosClearDefault, 0);
};

  const handlePowerOffTime = (event: any) => {
  const {
    target: { value }
  } = event;

  setPowerOfftime(value || "");
};

  const handlePowerOnTime = (event: any) => {
  const {
    target: { value }
  } = event;

  setPowerOnTime(value || "");
};

  const hasTimeIOS = (time: number | string) => {
  return time || time === 0;
};

  return (
    <div className={classes.filtersPopup}>
    <div className={classes.filtersContainer}>
    <div className={classes.menuHeader}>
    <Typography className={classes.mainTitle}>{scheduleId  === "new" ? t`Add Schedule` : t`Edit Schedule`}</Typography>
    <IconButton className={classes.iconBtn} onClick={onClose}><Clear /></IconButton>
    </div>
      <div style={{maxHeight: "100%", overflow: "auto", padding: "0 10px"}}>
                <Typography
                className={clsx(classes.textStyle, classes.statusSectioning)}
                >
                  {t`Status:` + scheduleDisabled ? t`Inactive` : t`Active`}
                  <Switch
                  checked={!scheduleDisabled}
                  disableRipple={true}
                  onChange={changeScheduleStatus}
                  value={true}
                  />
                </Typography>

              <div className={classes.timeSection}>
              {isIOS ? (
                  <>
                    <div
                      className={clsx(classes.iosHourButton, {
                        [classes.timeSelected]: !!powerOnTime
                      })}
                    >
                      <InputLabel
                      className={classes.iosTimeLabel}
                      >
                        {powerOnTime
                          ? powerOnTime
                          : "Start Hour"}
                      </InputLabel>
                      <Input
                        className={classes.iosTimeInput}
                        disableUnderline
                        type="time"
                        value={powerOnTime}
                        onChange={handlePowerOnTime}
                        onInput={(event: any) => onInput(event, "start")}
                      />
                    </div>
                    <div
                      className={clsx(classes.iosHourButton, {
                        [classes.timeSelected]: !!powerOffTime
                      })}
                    >
                      <InputLabel
                      className={classes.iosTimeLabel}
                      >
                        {powerOffTime ? powerOffTime : "End Hour"}
                      </InputLabel>
                      <Input
                        className={classes.iosTimeInput}
                        disableUnderline
                        type="time"
                        value={powerOffTime}
                        onChange={handlePowerOffTime}
                        onInput={(event: any) => onInput(event, "end")}
                      />
                    </div>
                  </>
                ) : (
                    <>
                      <MuiButton
                        variant="contained"
                        disableRipple
                        disableElevation
                        className={clsx(classes.timeContainer, {
                          [classes.timeSelected]: !!powerOnTime
                        })}
                        onClick={() => setOpenPicker("start")}
                      >
                        {powerOnTime ? powerOnTime : "Start Hour"}
                      </MuiButton>
                      <MuiButton
                        disableElevation
                        variant="contained"
                        disableRipple
                        className={clsx(classes.timeContainer, {
                          [classes.timeSelected]: !!powerOffTime
                        })}
                        onClick={() => setOpenPicker("end")}
                      >
                        {powerOffTime ? powerOffTime : "End Hour"}
                      </MuiButton>
                    </>
                  )}
                  </div>

                <div className={classes.daysSection}>
                  <Typography  className={clsx(classes.textStyle, classes.addBtmMargin)} >{t`Choose Days`}</Typography>
                  <DaysList
                    days={weekDaysOptions}
                    activeDays={days}
                    action={addRemoveDay}
                    isMobile
                  />
               </div>

    <div className={classes.setpointBox}>
          <Typography
            className={clsx(classes.textStyle, classes.addBtmMargin)}
          >{t`Select Mode`}</Typography>

          <div
          className={classes.selectModeContainer}
          >
            <InputLabel htmlFor="setpoint-input"
             className={classes.textStyle}
             >
              {t`Setpoint`}
              <Checkbox
                onChange={handleSetpoint}
                checked={setpointEnabled}
                className={classes.checkboxStyle}
              />
            </InputLabel>
            <div
            className={classes.setpointContainer}
            >
              <MuiButton
                disabled={!setpointEnabled}
                onClick={increaseSetpoint}
                className={classes.controlArrowButton}
              >
                <ArrowUp />
              </MuiButton>
              <Typography
                className={clsx(classes.setpointStyle, {
                  [classes.setpointNotSet]: !setpointEnabled
                })}
              >
                {setpointEnabled ? setpoint : temperatureScale === 1 ? "24" : "75"}
                <span
                className={classes.tempSymbolStyle}
                >
                  {temperatureSymbol}
                </span>
              </Typography>
              <MuiButton
                disabled={!setpointEnabled}
                onClick={decreaseSetpoint}
                className={classes.controlArrowButton}
              >
                <ArrowDownControl />
              </MuiButton>
            </div>
            </div>
            </div>
      </div>
      </div>
      {errorMsg && (
        <InputLabel
          className={classes.errorLabelStyle}
        >{errorMsg}</InputLabel>
      )}
      <Button
        className={classes.applyBtn}
        onClick={save}
        uppercase
      >{t`Save`}</Button>
      {!isIOS && (
        <TimePicker
          show={!!openPicker}
          onSet={onSetTime}
          time={openPicker === "start" ? powerOnTime : powerOffTime}
          onDismiss={() => setOpenPicker("")}
          onClear={onClear}
          is12Hours={is12Hours}
        />
      )}
    </div>
  );
}
