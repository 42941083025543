import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { Formik } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { t } from "ttag";
import * as Yup from "yup";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import UserDetails from "../../screens/UserManagement/UserDetails";
import { IUserFormValues } from "../../screens/UserManagement/UserHelpers";
import useStyles from "./ProfileEdit.style";

const UserSchema = Yup.object().shape({
  firstName: Yup.string().max(20, "Too Long!"),
  lastName: Yup.string().max(20, "Too Long!"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  username: Yup.string()
    .min(6, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phone: Yup.string().matches(
    /^[+\s]*[0-9\s]{0,4}[\s(\s]{0,1}[0-9]{1,4}[\s)\s]{0,1}[-\s\./0-9]*$/,
    "invalid phone number"
  ),
  // .required('Required'),
  password: Yup.string().matches(
    /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    "must be at least 1 capital letter and 1 digit"
  ),
  newPassword: Yup.string()
    .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, "must be at least 1 capital letter and 1 digit")
    .notRequired()
    .when("password", {
      is: (val) => val !== undefined,
      then: Yup.string().required("Required")
    }),
  repeatPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "Passwords must match")
});
const ProfileEdit: React.FC<RouteComponentProps<any> & any> = (props) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = useState(null);

  const user = useStoreState((state) => state.users.me);
  const updateUser = useStoreActions((action) => action.users.updateUser);
  const updatePassword = useStoreActions((action) => action.users.updatePassword);
  const types = useStoreState((state) => state.types.allTypes);
  const getAllUnits = useStoreActions((action) => action.units.getAndReplaceUnits);

  const { temperatureScale, pressureScale } = types;

  const [open, setOpen] = React.useState(false);

  const initialValues: any = {
    firstName: user.firstName ? user.firstName : "",
    lastName: user.lastName ? user.lastName : "",
    username: user.username ? user.username : "",
    email: user.email ? user.email : "",
    phone: user.phone ? user.phone : "",
    temperatureScale: user.temperatureScale ? user.temperatureScale : 1,
    password: "",
    newPassword: "",
    repeatPassword: "",
    timeFormat: user.timeFormat,
    dateFormat: user.dateFormat

  };

  const onUserEditSubmit = (values: IUserFormValues, actions: any) => {
    values.phone = values.phone.replace(/[^a-zA-Z0-9 ]/g, "");
    values.phone = values.phone.replace(/\s/g, "");
    const isTempScaleChanged = values.temperatureScale !== user.temperatureScale;
    const payload = {
      ...values
    };
    if (payload.password && payload.newPassword && payload.repeatPassword) {
      updatePassword({
        userId: user.id,
        oldPassword: payload.password,
        newPassword: payload.newPassword
      })
        .then(() => {
          delete payload.password;
          delete payload.newPassword;
          delete payload.repeatPassword;
          updateUser({ userId: user.id, updatedData: payload })
            .then(() => { isTempScaleChanged && getAllUnits();
                          handleClose();
            })
            .catch((e: any) => setSubmitError(e.message));
        })
        .catch((e: any) => setSubmitError(e.message));
    } else {
      delete payload.password;
      delete payload.newPassword;
      delete payload.repeatPassword;
      updateUser({ userId: user.id, updatedData: payload })
        .then(() => {
          isTempScaleChanged && getAllUnits();
          handleClose();
        })
        .catch((e: any) => setSubmitError(e.message));
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const errorBox = submitError ? (
    <ErrorBox error={submitError} onClose={() => setSubmitError(null)} />
  ) : null;

  return (
    <>
      <div className={classes.dropDownItem} onClick={handleClickOpen}>{t`My Profile`}</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        className={classes.dialog}
      >
            <div className={classes.dialogHeader}>
              <Typography className={classes.headerTitle}>{t`My Profile`}</Typography>
              <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => setOpen(false)}>
                <Close color="#7f7692" />
              </IconButton>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={onUserEditSubmit}
              enableReinitialize={true}
              validationSchema={UserSchema}
            >
              {({ handleSubmit, errors, touched, ...restFormikProps }) => (
                <form onSubmit={handleSubmit} className={classes.dialogContent}>
                  <Grid container className={classes.dialogSubContent}>
                        <Typography
                          className={classes.headerStyle}
                        >{t`Personal Details`}</Typography>
                        <Divider orientation="horizontal" />
                        <UserDetails
                          withChangePasswordField={true}
                          errors={errors}
                          touched={touched}
                          user={user}
                          pressureScale={pressureScale}
                          temperatureScale={temperatureScale}
                          {...restFormikProps}
                          {...props}
                        />
                  </Grid>
                  <div className={classes.actionsHolder}>
                    {errorBox}
                    <Button
                    white
                    uppercase
                    width={150}
                    marginRight
                    onClick={handleClose}
                    >
                    {t`Cancel`}
                    </Button>
                      <Button
                      uppercase
                      width={150}
                        type="submit"
                      >
                        {t`Submit`}
                      </Button>
                  </div>
                </form>
              )}
            </Formik>
      </Dialog>
    </>
  );
};
export default ProfileEdit;
