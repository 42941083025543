import {
  Card,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  InputLabel,
  Typography
} from "@material-ui/core";
import { Field, FormikProps } from "formik";
import _ from "lodash";
import { Select } from "material-ui-formik-components/Select";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import CoolButton from "../../../cool_widgets/Button";
import { Arrow as SvgArrow, Close } from "../../../icons/";
import { useStoreActions, useStoreState } from "../../../models/RootStore";
import { ISystem } from "../../../models/Systems";
import { IUnit } from "../../../models/Units";
import InputField from "../InputField";
import useStyles from "./SystemDetails.style";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
interface IProps extends Omit<FormikProps<any>, "handleSubmit"> {
  errors: any;
  touched: any;
  classes: any;
  isDisabled: boolean;
  mode: any;
  deviceUnits: IUnit[];
  system: ISystem | null;
  isSystemDaikin: (system: ISystem | null | undefined) => boolean;
  deviceLines?: any;
}

const SystemDetails: React.FC<IProps> = ({
  errors,
  touched,
  values,
  isDisabled,
  mode,
  setFieldValue,
  deviceUnits,
  system,
  isSystemDaikin,
  deviceLines
}) => {
  const classes = useStyles();

  const isCreate = mode === 1;
  const types = useStoreState((state) => state.types.allTypes);
  const { hvacBrands: allBrands, systemTypes: allSystemTypes, unitTypes, capacityMeasurementUnitTypes } = types;
  const systemTypes = [
    {
      value: "other",
      label: "Other"
    }
  ];
  _.map(allSystemTypes, (key, value) => {
    systemTypes.push({
      value: key,
      label: key
    });
  });
  const brands = [
    {
      value: 999,
      label: "Other"
    }
  ];
  _.map(allBrands, (brand) => {
    brands.push({
      value: brand.value,
      label: brand.name
    });
  });

  const mapLineIndoors = useStoreActions((actions) => actions.devices.mapLineIndoors);
  const [isAutoMappingDialogOpen, setIsAutoMappingDialogOpen] = React.useState(false);
  const [IsLinesLoading, setIsLinesLoading] = useState<boolean>(true);
  const [lines, setLines] = useState<any>(null);

  const getBrandName = (value: any) => {
    return allBrands?.filter((item) => item.value === value)[0]?.name;
  };

  useEffect(() => {
    if (isCreate) {
      if (_.isEmpty(deviceLines)) {
        return;
      }

      const linesArr = deviceLines.reduce((linesObj: any, line: any) => {
        const brand = getBrandName(line.brand);
        linesObj.push({
          value: line.id,
          label: `${line.id} ${line.brand !== 999 ? " - " + brand : ""}`
        });
        return linesObj;
      }, []);
      if (linesArr.length === 1) {
        setFieldValue("line", linesArr[0].value);
      }
      setLines(_.orderBy(linesArr, ["value"], ["asc"]));
      setIsLinesLoading(false);
    }
  }, [deviceLines]);

  const openAutoMappingDialog = () => {
    setIsAutoMappingDialogOpen(true);
  };
  const closeAutoMappingDialog = () => {
    setIsAutoMappingDialogOpen(false);
  };
  const startAutoMapping = async () => {
    if (system) {
      await mapLineIndoors({ deviceId: system.device, lineId: system.line.toString() });
    }
    setIsAutoMappingDialogOpen(false);
  };
  const autoMappingEnabled = isSystemDaikin(system);
  const numberOfLineServiceUnits = system && autoMappingEnabled
    ? _.filter(deviceUnits, (unit: IUnit) => ((unit.line === system.line) && (unit.type === unitTypes.service))).length
    : 0;
  const estimatedTime = numberOfLineServiceUnits * 0.5;

  if (isCreate && IsLinesLoading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Card className={classes.systemContainer}>
        <Typography className={classes.systemInfoTitle}> {t`System Information`}</Typography>
        <div className={classes.systemControlsContainer}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            style={{ padding: "14px" }}
          >
            <InputLabel className={classes.labelStyle} style={{ width: "34ch", minWidth: "34ch" }}>{t`Name`}
              <Field
                value={values.name}
                name="name"
                component={InputField}
                error={errors.name && touched.name ? true : false}
                helperText={errors.name && touched.name ? errors.name : ""}
                className={classes.addMargin}
              />
            </InputLabel>
            <InputLabel className={classes.labelStyle} style={{ width: "27ch", minWidth: "27ch" }}>{t`Brand`}
              <Field
                value={values.brand}
                name="brand"
                options={brands}
                error={errors.brand && touched.brand ? true : false}
                helperText={errors.brand && touched.brand ? errors.brand : ""}
                component={Select}
                classes={{ icon: classes.arrowIcon }}
                IconComponent={SvgArrow}
                variant="outlined"
                margin="none"
                className={classes.addMargin}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              // style={{ maxHeight: "44px", height: "44px" }}
              />
            </InputLabel>
            <InputLabel className={classes.labelStyle} style={{ width: "22ch", minWidth: "22ch" }}>{t`Type`}

              <Field
                label={""}
                name={"type"}
                options={systemTypes}
                component={Select}
                variant="outlined"
                // value={values.type ? values.type : (systemTypes ? systemTypes[0]: '')}
                error={errors.type && touched.type ? true : false}
                helperText={errors.type && touched.type ? errors.type : ""}
                className={classes.addMargin}
                classes={{ icon: classes.arrowIcon }}
                IconComponent={SvgArrow}
                margin="none"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              // style={{ maxHeight: "44px", height: "44px" }}
              />
            </InputLabel>
            {/* <Autocomplete
              // label={t`Series`}
              name='type'
              suggestions={systemTypes}
              value={values.type}
              className={classes.autocomplete}
              /> */}
            {/* <Field
                // label={t`Types`}
                value={values.type}
                name="type"
                options={systemTypes}
                component={AutocompleteField}
                onChange={(e: any, value: any) => setFieldValue("type", value)}
                variant="outlined"
                error={((errors.type && touched.type)) ? true : false}
                helperText={(errors.type && touched.type) ? errors.type : ""}
                /> */}
            <InputLabel className={classes.labelStyle} style={{ width: "22ch", minWidth: "22ch" }}>{t`Series`}
              <Field
                name={"series"}
                value={values.series}
                component={InputField}
                variant="outlined"
                error={errors.series && touched.series ? true : false}
                helperText={errors.series && touched.series ? errors.series : ""}
                className={classes.addMargin}
              />
            </InputLabel>
            <InputLabel className={classes.labelStyle} style={{ width: "22ch", minWidth: "22ch" }}>{t`Capacity`}
              {/* <div style={{ display: "flex", flexFlow: "row nowrap", justifyContent: "space-between" }} className={classes.addMargin}> */}
              {/* <div style={{ width: "100%", marginRight: "14px" }} > */}
              <Field
                value={values.capacity}
                component={InputField}
                // label={t`Capacity`}
                name="capacity"
                variant="outlined"
                error={errors.capacity && touched.capacity ? true : false}
                helperText={errors.capacity && touched.capacity ? errors.capacity : ""}
                className={classes.addMargin}

              />
              {/* </div> */}

              {/* </div> */}
            </InputLabel>
            <InputLabel className={classes.labelStyle} style={{ width: "22ch", minWidth: "22ch" }}>
              <Field
                label={""}
                name="capacityMeasurementUnits"
                options={_.map(capacityMeasurementUnitTypes, (key, value) => ({
                  value: key,
                  label: value
                }))}
                component={Select}
                variant="outlined"
                value={
                  values.capacityMeasurementUnits ??
                  capacityMeasurementUnitTypes[Object.keys(capacityMeasurementUnitTypes)[0]]
                }
                error={
                  errors.capacityMeasurementUnits && touched.capacityMeasurementUnits ? true : false
                }
                helperText={
                  errors.capacityMeasurementUnits && touched.capacityMeasurementUnits
                    ? errors.capacityMeasurementUnits
                    : ""
                }
                classes={{ icon: classes.arrowIcon }}
                IconComponent={SvgArrow}
                margin="none"
                // className={classes.addMargin}

                style={{ marginTop: "22px" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              />

            </InputLabel>
            <InputLabel className={classes.labelStyle} style={{ width: "7ch", minWidth: "7ch" }}>{t`Line`}
              <Field
                name="line"
                options={isCreate ? lines : [{ value: values.line, label: values.line }]}
                component={Select}
                value={!isCreate ? values.line : lines?.length === 1 ? lines[0].value : values.line}
                variant="outlined"
                error={errors.line && touched.line ? true : false}
                helperText={errors.line && touched.line ? errors.line : ""}
                disabled={isDisabled || !isCreate}
                classes={{ icon: classes.arrowIcon }}
                IconComponent={SvgArrow}
                margin="none"
                className={classes.addMargin}
                style={{
                  minWidth: isDisabled || !isCreate ? "6ch" : "14ch"// height: "44px", maxHeight: "44px"
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              />
            </InputLabel>
          </Grid>
        </div>
      </Card>
      <Dialog
        open={isAutoMappingDialogOpen}
        onClose={closeAutoMappingDialog}
        aria-labelledby="mapping-dialog-title"
        aria-describedby="mapping-dialog-description"
        maxWidth="sm"
      >
        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>{t`Automatic unit address mapping`}</Typography>
          <IconButton disableRipple onClick={closeAutoMappingDialog} className={classes.clearButton}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <Typography>{t`Start automatic unit address mapping? This will take ${estimatedTime} minutes to map ${numberOfLineServiceUnits} units. All units will be moved to Fan mode for the whole period. During this time do not make any changes to the units settings. Upon completion, all units will return to their original status (on/off, setpoint, mode).`}</Typography>
        </div>
        <div className={classes.actionsHolder}>
          <CoolButton
            onClick={startAutoMapping}
            white
            width={100}
            uppercase
            marginRight
          >
            {t`Yes`}
          </CoolButton>
          <CoolButton
            onClick={closeAutoMappingDialog}
            autoFocus={true}
            width={100}
            uppercase
            className={classes.cancelMapping}
          >{t`No`}</CoolButton>
        </div>
      </Dialog>
    </>
  );
};

export default SystemDetails;
