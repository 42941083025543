import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { t } from "ttag";
import {
  AutoMode,
  ColdBlue,
  DryIcon,
  Fan1Icon,
  Fan2Icon,
  Fan3Icon,
  Fan4Icon,
  FanMode,
  HotRed,
  Swing,
  Swing30,
  Swing45,
  Swing60,
  SwingHor,
  SwingVer
} from "../../../svgComponents";

import { useStoreState } from "../../../models/RootStore";
import modesListStyle from "./modesList.style";

const icons: any = {
  operationModes: {
    COOL: <ColdBlue style={{transform: "scale(1.889)"}}/>,
    HEAT: <HotRed style={{transform: "scale(1.889)"}} />,
    AUTO: <AutoMode maincolor="#aaa2aa"/>,
    DRY: <DryIcon maincolor="#aaa2aa"/>,
    FAN: <FanMode maincolor="#aaa2aa" />
  },
  fanModes: {
    LOW: <Fan1Icon color1="#4b1c46"/>,
    MEDIUM: <Fan2Icon color1="#4b1c46"/>,
    HIGH: <Fan3Icon color1="#4b1c46"/>,
    TOP: <Fan4Icon color1="#4b1c46"/>,
    AUTO: <Typography style={{fontFamily: "Roboto", fontSize: 18, fontWeight: "normal", lineHeight: "normal", letterSpacing: "normal", color: "#4b1c46"}}>{t`Auto`}</Typography>
  },
  swingModes: {
    vertical: <Typography style={{fontFamily: "Roboto", fontSize: 18, fontWeight: "normal", lineHeight: "normal", letterSpacing: "normal", color: "#545964"}}>{t`Vertical`}</Typography>,
    horizontal: <Typography style={{fontFamily: "Roboto", fontSize: 18, fontWeight: "normal", lineHeight: "normal", letterSpacing: "normal", color: "#545964"}}>{t`Horizontal`}</Typography>,
    30: <Typography style={{fontFamily: "Roboto", fontSize: 18, fontWeight: "normal", lineHeight: "normal", letterSpacing: "normal", color: "#545964"}}>30°</Typography>,
    45: <Typography style={{fontFamily: "Roboto", fontSize: 18, fontWeight: "normal", lineHeight: "normal", letterSpacing: "normal", color: "#545964"}}>45°</Typography>,
    60: <Typography style={{fontFamily: "Roboto", fontSize: 18, fontWeight: "normal", lineHeight: "normal", letterSpacing: "normal", color: "#545964"}}>60°</Typography>,
    auto: <Typography style={{fontFamily: "Roboto", fontSize: 18, fontWeight: "normal", lineHeight: "normal", letterSpacing: "normal", color: "#545964"}}>{t`Swing`}</Typography>
  }
};
const ModesList = (props: any) => {
  const { activeModes = [], action, customClass, modeName, disabled = false } = props;
  const useStyle = makeStyles(modesListStyle);
  const classes = useStyle();
  const { container, modeStyle, activeModeStyle } = classes;

  const types: any = useStoreState((states) => states.types.allTypes);

  const mode: any = types[modeName];
  if (!mode) {
    return <div>{t`pending...`}</div>;
  }
  return (
    <div className={container}>
      {Object.keys(mode).map((modeKey: any, index: number) => {
        const stateName = mode[modeKey];
        const isSelected = activeModes.indexOf(+modeKey) > -1;
        const modeIcon = icons[modeName][stateName] || (
          <Typography className={clsx(classes.typoIconStyle, {[classes.typoPurpleColor]: modeName === "fanModes"})}>{stateName}</Typography>
        );
        return (
          <div
            className={clsx(
              modeStyle,
              {
                [activeModeStyle]: isSelected,
                [classes.inactiveText]: !isSelected
              },
              customClass
            )}
            key={index}
            onClick={() => disabled ? {} : action(modeName, modeKey)}
          >
            {modeIcon}
          </div>
        );
      })}
    </div>
  );
};

export default ModesList;
