import { makeStyles } from "@material-ui/core";

export const systemOverviewStyle = makeStyles({
  elevation: {
    boxShadow: "none"
  },

  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },

  title: {
    fontSize: "1.25rem",
    fontFamily: "RobotoMedium",
    color: "#4b1c46"
  },

  compressorsContainer: {
    overflow: "auto",
    flexWrap: "nowrap"
  }
});
