import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  Thunk,
  thunk
} from "easy-peasy";
import _ from "lodash";
import { t } from "ttag";
import { IRootStoreModel } from "./RootStore";

export interface ITriggerTemplate {
  id: string;
  name: string;
  description?: string;
  brand?: number;
  unitType?: number;
  resources: string[];
  template: string;
  templateData: any;
}

export interface ITriggerTemplateLight {
  id: string;
  name: string;
  description?: string;
  brand?: number;
  unitType?: number;
}

export interface ITriggerTemplateMap {
  [key: string]: ITriggerTemplate;
}

export interface ITriggerTemplateLightMap {
  [key: string]: ITriggerTemplateLight;
}

export interface ITriggerTemplatesModel {
  allTriggerTemplates: ITriggerTemplateMap;
  myTriggerTemplates: ITriggerTemplateLightMap;
  createTriggerTemplate: Thunk<ITriggerTemplatesModel, { data: any }>;
  updateTriggerTemplate: Thunk<
    ITriggerTemplatesModel,
    { triggerTemplateId: string; updatedData: any }
  >;
  deleteTriggerTemplate: Thunk<ITriggerTemplatesModel, { id: string }>;
  initialize: Action<ITriggerTemplatesModel, any>;
  onInitialized: ActionOn<ITriggerTemplatesModel, IRootStoreModel>;
  initializeLight: Action<ITriggerTemplatesModel, any>;
  _storeCreateTriggerTemplate: Action<
    ITriggerTemplatesModel,
    { triggerTemplateId: string; data: ITriggerTemplate }
  >;
  _storeUpdateTriggerTemplate: Action<
    ITriggerTemplatesModel,
    { triggerTemplateId: string; updatedTriggerTemplateData: any }
  >;
  _storeDeleteTriggerTemplate: Action<ITriggerTemplatesModel, { id: string }>;
  getTriggerTemplateParsedData: Computed<
    ITriggerTemplatesModel,
    (id: string) => any
  >;
}

export const triggerTemplatesModel: ITriggerTemplatesModel = {
  allTriggerTemplates: {},
  myTriggerTemplates: {},

  createTriggerTemplate: thunk(async (actions, payload) => {
    const data: ITriggerTemplate = await coolremoteSDK.Admin.createTriggerTemplate(
      payload.data
    );
    actions._storeCreateTriggerTemplate({
      triggerTemplateId: data.id,
      data: data
    });
    return data;
  }),

  updateTriggerTemplate: thunk(async (actions, payload) => {
    const updatedTriggerTemplateData = await coolremoteSDK.TriggerTemplate.update(
      payload.triggerTemplateId,
      payload.updatedData
    );

    actions._storeUpdateTriggerTemplate({
      triggerTemplateId: payload.triggerTemplateId,
      updatedTriggerTemplateData
    });
  }),

  deleteTriggerTemplate: thunk(async (actions, payload) => {
    await coolremoteSDK.TriggerTemplate.delete(payload.id);

    actions._storeDeleteTriggerTemplate({ id: payload.id });
  }),

  initialize: action((state, payload) => {
    const newTriggerTemplates: ITriggerTemplateMap = _(payload)
      .map((triggerTemplate: any) => {
        const newTriggerTemplate: ITriggerTemplate = { ...triggerTemplate };

        return newTriggerTemplate;
      })
      .keyBy("id")
      .value();
    state.allTriggerTemplates = newTriggerTemplates;
  }),
  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      // console.log(target.resolvedTargets);
      // console.log("Got trigger templates: ", debug(state.allTriggerTemplates));
    }
  ),

  initializeLight: action((state, payload) => {
    const newMyTriggerTemplates: ITriggerTemplateLightMap = _(payload)
      .map((triggerTemplateLight: any) => {
        const newTriggerTemplateLight: ITriggerTemplateLight = {
          ...triggerTemplateLight
        };

        return newTriggerTemplateLight;
      })
      .keyBy("id")
      .value();
    state.myTriggerTemplates = newMyTriggerTemplates;
  }),

  _storeCreateTriggerTemplate: action((state, payload) => {
    state.allTriggerTemplates[payload.triggerTemplateId] = payload.data;
  }),

  _storeUpdateTriggerTemplate: action((state, payload) => {
    if (state.allTriggerTemplates[payload.triggerTemplateId]) {
      state.allTriggerTemplates[payload.triggerTemplateId] =
        payload.updatedTriggerTemplateData;
    }
  }),

  _storeDeleteTriggerTemplate: action((state, payload) => {
    delete state.allTriggerTemplates[payload.id];
  }),

  getTriggerTemplateParsedData: computed((state) => (id) => {
    const trigger: ITriggerTemplate = state.allTriggerTemplates[id];

    // TODO: From here on it's a copy-paste from Triggers model and it will be fixed.
    let triggerParsedData = {
      typeText: ""
    };

    if (!trigger.template) {
      return triggerParsedData;
    }

    if (trigger.template.indexOf("UNIT_ERROR") !== -1) {
      triggerParsedData = {
        typeText: t`Unit error`
      };
    } else if (trigger.template.indexOf("UNIT_LIMITS") !== -1) {
      triggerParsedData = {
        typeText: t`Safety limits exceeded`
      };
    } else if (trigger.template.indexOf("UNIT_EXCEEDED") !== -1) {
      triggerParsedData = {
        typeText: t`Threshold exceeded`
      };
    } else if (trigger.template.indexOf("UNIT_CLEAN_FILTER") !== -1) {
      triggerParsedData = {
        typeText: t`Clean filter`
      };
    } else if (trigger.template.indexOf("SCHEDULED_EVENT") !== -1) {
      triggerParsedData = {
        typeText: t`Scheduled maintenance`
      };
    } else if (trigger.template.indexOf("DEVICE_DISCONNECT") !== -1) {
      triggerParsedData = {
        typeText: t`Device disconnected`
      };
    } else if (
      trigger.template.indexOf("ISTAT_BREACH") !== -1 ||
      trigger.template.indexOf("OSTAT_BREACH") !== -1
    ) {
      if (
        !_.isNil(trigger.templateData.biggerThan) &&
        _.isNil(trigger.templateData.smallerThan)
      ) {
        triggerParsedData = {
          typeText: t`System Parameter > Value`
        };
      } else if (
        _.isNil(trigger.templateData.biggerThan) &&
        !_.isNil(trigger.templateData.smallerThan)
      ) {
        triggerParsedData = {
          typeText: t`System Parameter < Value`
        };
      } else if (
        !_.isNil(trigger.templateData.biggerThan) &&
        !_.isNil(trigger.templateData.smallerThan)
      ) {
        triggerParsedData = {
          typeText: t`System Parameter < Value1 OR system Parameter > Value2`
        };
      }
    } else if (
      trigger.template.indexOf("ISTAT_EQUALS") !== -1 ||
      trigger.template.indexOf("OSTAT_EQUALS") !== -1
    ) {
      triggerParsedData = {
        typeText: t`System Parameter = Value`
      };
    } else if (
      trigger.template.indexOf("ISTAT_THRESHOLD_EXCEEDED") !== -1 ||
      trigger.template.indexOf("OSTAT_THRESHOLD_EXCEEDED") !== -1
    ) {
      triggerParsedData = {
        typeText: t`Difference between system-Parameter-1 and system-Parameter-2 > Value`
      };
    }

    return triggerParsedData;
  })
};
