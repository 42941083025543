import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab/";
import { xhr, xhr as SdkXhr } from "coolremote-sdk";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import Dashboard from "../Dashboard/Dashboard";
import DashboardUnit from "../Dashboard/DashboardUnit";
import Impersonate from "../Impersonate/Impersonate";
import Login from "../Login/Login";
import TenantReports from "../PowerDistribution/reports";
import ReportsScheduler from "../PowerDistribution/ReportsScheduler";
import PowerDistribution from "../PowerDistribution/Tenants";
import SiteManagement from "../SiteManagement/SiteManagement";
import UserList from "../UserManagement/UserList";

// Non 'JS Styles' CSS imports.
import queryString from "query-string";
import "rc-slider/assets/index.css";
import "react-flags-select/css/react-flags-select.css";
import { StaticContext } from "react-router";
import "react-table/react-table.css";
import {BottomNav, ErrorStoreMessage, TopBar} from "../../components";
import AddEditSchedule from "../../components/AddEditSchedule/AddEditSchedule";
import { Loader } from "../../components/Loader";
import Loading from "../../components/Loading/Loading";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import MobileAlerts from "../../MobileScreens/MobileAlerts/Alerts";
import MobileAudits from "../../MobileScreens/MobileAudits/AuditsList";
import MobileControl from "../../MobileScreens/MobileControl/Control";
import MobileDashboard from "../../MobileScreens/MobileDashboard/Dashboard";
import MobileLogin from "../../MobileScreens/MobileLogin/Login";
import MobileSchedulesList from "../../MobileScreens/MobileScheduleList/ScheduleList";
import MobileSettingsMenu from "../../MobileScreens/MobileSettings/SettingsMenuScreen";
import MobileUnitControlRestrict from "../../MobileScreens/MobileUnitControlRestrict/UnitManagement";
import MobileUnitManagement from "../../MobileScreens/MobileUnitManagement/UnitManagement";
import MobileWRCUnitSettings from "../../MobileScreens/MobileWRCUnitSettings/UnitManagement";

import { isMobile } from "react-device-detect";
import Alerts from "../Alerts/Alerts";
import Audits from "../Audits/AuditsList";
import CommissioningList from "../Commissioning/CommissioningList";
import Control from "../Control/Control";
import Groups from "../Groups/Groups";
import ForgotPassword from "../Login/ForgotPassword";
import ResetPassword from "../Login/ResetPassword";
import ResetPasswordWithToken from "../Login/ResetPasswordWithToken";
import ResetSuccess from "../Login/ResetSuccess";
import AlertGroupsSetting from "../Settings/AlertGroupsSetting";
import Settings from "../Settings/Settings";
import UnitSetting from "../Settings/UnitSetting";
import TrapsList from "../Traps/TrapsList";
import UnitStats from "../UnitStats/UnitStats";
import UserEdit from "../UserManagement/UserEdit";
import UserNew from "../UserManagement/UserNew";

const PrivateRoute: any = (props: any) => {
  const {
    children,
    ...rest
  } = props;
  const isLoggedIn = useStoreState((s) => s.isLoggedIn);
  const isInitializesd = useStoreState((s) => s.isInitialized);
  const location = useLocation();
  const doLogout = useStoreActions((a) => a.doLogout);
  const startLoader = useStoreActions((a) => a.loader.startLoader);
  const finishLoader = useStoreActions((a) => a.loader.finishLoader);

  // const adminLogin = useStoreActions((a) => a.adminLogin);

  const validToken = xhr.getToken();
  const localStorageToken = localStorage.getItem("token");
  const values = queryString.parse(rest.location.SearchIcon);
  const username: any = values && values.username || "";
  const pathname = rest.location.pathname;

  useEffect(() => {
    if (isInitializesd || !isLoggedIn) {
      finishLoader();
    }

    if (!isInitializesd && isLoggedIn) {
      startLoader();
    }
  }, [isInitializesd, isLoggedIn]);

  // useEffect(() => {
  //   if (pathname === "/dashboard" && username && isLoggedIn && isInitializesd) {
  //     adminLogin(username).catch(() => {
  //       doLogout();
  //       finishLoader();
  //     });
  //   }
  // }, [isLoggedIn, isInitializesd]);

  if (isInitializesd && (!validToken || !localStorageToken)) {
    doLogout();
    finishLoader();
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location }
        }}
      />
    );
  }

  return isLoggedIn ? (
    isInitializesd ? (
      <Route {...rest}>{children}</Route>
    ) : (
        <Loading mobile/>
      )
  ) : (
      <Redirect
        // from={props.location.pathname}
        // exact
        // to="/"
        to={{
          pathname: "/",
          state: { from: location }
        }}
      />
    );
};

export default function Routes() {
  const getUserTree = useStoreActions((action) => action.getUserTree);
  const setLoggedIn = useStoreActions((action) => action.setLoggedIn);
  const isLoggedIn = useStoreState((s) => s.isLoggedIn);
  const isLoaderShowing = useStoreState((s) => s.loader.isLoading);
  const finishLoader = useStoreActions((a) => a.loader.finishLoader);
  const messageObj = useStoreState((s) => s.errorMessage.message);
  const setUnitUpdateStatus = useStoreActions(
    (action) => action.setUnitUpdateStatus
  );

  const theme = useTheme();
  const displayFlags = useStoreState((state) => state.users.displayFlags);

  const initialize = async () => {
    const token = localStorage.getItem("token");

    if (isLoggedIn && token) {
      SdkXhr.setToken(token);

      try {
        await getUserTree();
      } catch (error) {
        // localStorage.removeItem('token');
        setLoggedIn(false);
        finishLoader();
        // history.push('/');
      }
    } else {
      setLoggedIn(false);
      finishLoader();
      // history.push('/');
    }
  };

  useEffect(() => {
    initialize();
  }, [isLoggedIn]);

  // if (!isInitializesd) return <Loading />;
  const unitUpdateStatusUpdate = (id: string) => {
    setUnitUpdateStatus({ status: id ?? "" });
  };

  if (isMobile){
  return  <div className="AppMobile">
      <Loader showLoader={isLoaderShowing} />
      <Router>
        <Switch>
        <Route exact={true}  path="/forgot-password/" component={ForgotPassword}/>
        <Route exact={true} path="/reset-password" component={ResetPassword}/>
        <Route exact={true} path="/reset-password/:token" component={ResetPasswordWithToken}/>
        <Route exact={true} path="/reset-success" component={ResetSuccess}/>
        <Route exact={true} path="/impersonate/:token" component={Impersonate}/>
        <Route exact={true} path="/" component={MobileLogin}/>
        <PrivateRoute exact path="/dashboard"><MobileDashboard /></PrivateRoute>
        <PrivateRoute exact path="/settings"><MobileSettingsMenu/></PrivateRoute>
        <PrivateRoute exact path="/settings/unit"> <MobileUnitControlRestrict/></PrivateRoute>
        <PrivateRoute exact path="/management/unit"><MobileUnitManagement/></PrivateRoute>
        <PrivateRoute exact path="/settings/restricts"><MobileWRCUnitSettings/></PrivateRoute>
        <PrivateRoute exact path="/alerts"><MobileAlerts /></PrivateRoute>
        <PrivateRoute exact path="/audits"><MobileAudits /></PrivateRoute>
        <PrivateRoute exact path="/control"><MobileControl /></PrivateRoute>
        <PrivateRoute exact path="/unit/:unitId/schedules"><MobileSchedulesList /></PrivateRoute>
        <PrivateRoute exact path="/group/:groupId/schedules"><MobileSchedulesList /></PrivateRoute>
        <Redirect to="/" />
        </Switch>
      </Router>
      {messageObj.message && (<ErrorStoreMessage message={messageObj}/>)}
    </div>;
  }

  return (
    <div className="App">
      <Loader showLoader={isLoaderShowing} />
      <Router>
        <Switch>
          <Route
            exact={true}
            path="/forgot-password/"
            component={ForgotPassword}
          />
          <Route
            exact={true}
            path="/reset-password"
            component={ResetPassword}
          />
          <Route
            exact={true}
            path="/reset-password/:token"
            component={ResetPasswordWithToken}
          />
          <Route exact={true} path="/reset-success" component={ResetSuccess}/>
          <Route exact={true} path="/impersonate/:token" component={Impersonate}/>
          <Route exact={true} path="/" component={Login}/>
          <PrivateRoute
            exact
            path="/dashboard"
          // render={(props: any) => <AuthorizeDashboard {...props} />}
          >
            <Dashboard />
          </PrivateRoute>
          {true && //displayFlags.enableUnitDiagnostics ||
            <PrivateRoute
              exact
              path="/unit-diag/:unitId?"
            // render={(props: any) => <AuthorizeDashboardUnit {...props} />}
            >
              <DashboardUnit unitUpdateStatusUpdate={unitUpdateStatusUpdate} />
            </PrivateRoute>}

          {displayFlags.enableUnitStats && <PrivateRoute exact path="/unit-stats/:unitId?">
            <UnitStats />
          </PrivateRoute>}
          <PrivateRoute exact path="/unit-stats/">
            <UnitStats />
          </PrivateRoute>

          <PrivateRoute exact path="/settings/alerts">
            <AlertGroupsSetting />
          </PrivateRoute>
        {  /*<PrivateRoute exact path="/commissioning">
            <CommissioningList />
          </PrivateRoute>*/}
          <PrivateRoute exact path="/settings/restricts">
            <Settings />
          </PrivateRoute>
          <PrivateRoute exact path="/settings/unit">
            <UnitSetting/>
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/site-management"
          // render={(props: any) => <AuthorizeSiteManagement {...props} />}
          >
            <SiteManagement unitUpdateStatusUpdate={unitUpdateStatusUpdate} />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/user"
          // component={AuthorizeUserList}
          // render={(props: any) => <AuthorizeUserList {...props} />}
          >
            <UserList />
          </PrivateRoute>
          <PrivateRoute exact path="/anomalies-settings">
            <TrapsList />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/user/edit/:id"
          // render={(props: any) => <AuthorizeUserEdit {...props} />}
          // component={AuthorizeUserEdit}
          >
            <UserEdit />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/user/new"
          // render={(props: any) => <AuthorizeUserNew {...props} />}
          >
            <UserNew />
          </PrivateRoute>
          <PrivateRoute exact path="/alerts">
            <Alerts />
          </PrivateRoute>
          <PrivateRoute exact path="/audits">
            <Audits />
          </PrivateRoute>
          <PrivateRoute exact path="/control">
            <Control />
          </PrivateRoute>
          <PrivateRoute exact path="/control/:unitId">
            <Control />
          </PrivateRoute>
          <PrivateRoute exact path="/PowerDistribution">
            <PowerDistribution />
          </PrivateRoute>
          <PrivateRoute exact path="/tenantReports">
            <TenantReports />
          </PrivateRoute>
          <PrivateRoute exact path="/scheduler">
            <ReportsScheduler />
          </PrivateRoute>
          <PrivateRoute exact path="/group/:groupId/schedules">
            <ScheduleList />
          </PrivateRoute>
          <PrivateRoute exact path="/unit/:unitId/schedules">
            <ScheduleList />
          </PrivateRoute>

          <PrivateRoute exact path="/unit/:unitId/schedule/:scheduleId?">
            <AddEditSchedule />
          </PrivateRoute>
          <PrivateRoute exact path="/group/:groupId/schedule/:scheduleId?">
            <AddEditSchedule />
          </PrivateRoute>
          {
            // true || displayFlags.enableGroupManagement &&
            (
              <PrivateRoute exact path="/settings/groups">
                <Groups />
              </PrivateRoute>
            )}
          <Redirect to="/" />
        </Switch>
      </Router>
      {messageObj.message && (<ErrorStoreMessage message={messageObj} />)}
    </div>
  );
}
