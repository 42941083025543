import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  inputName: {
    width: "25%",
    marginRight: "1rem"
  },
  inputBrand: {
    width: "20%",
    marginRight: "1rem"
  },
  inputModel: {
    width: "10%",
    marginRight: "1rem"
  },
  inputLine: { width: "9%" },
  inputCapacity: {
    width: "10%",
    marginRight: "1rem"
  },
  inputCapacityMeasurement: {
    width: "8%",
    marginTop: "1.4rem"
  },
  autocomplete: {
    width: "10rem",
    height: "3rem"
  },
  systemContainer: {
    borderRadius: "4px",
    border: "solid 1px #d5d2d5",
    minHeight: "159px"
    // padding: '1rem'
  },
  unitsContainer: {
    borderRadius: "4px",
    border: "solid 1px #d5d2d5",
    marginTop: "1rem"
  },

  systemInfoTitle: {
    backgroundColor: "#f6f5f6",
    borderBottom: "1px solid #d5d2d5",
    padding: 0,
    paddingLeft: "14px",
    marginBottom: "2rem",
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "50px",
    fontWeight: 500,
    color: "#4b1c46",
    height: "50px",
    margin: "0 !important"
  },
  systemControlsContainer: {
    display: "flex"
  },
  dialogTitle: {
    color: "#29132e",
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  clearButton: {
        width: 30,
        height: 30,
        borderRadius: 6,
        padding: 0
  },
  cancelMapping: {
    marginRight: "22px"
  },
  modalActions: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end"
  },
  container: {
    paddingRight: "2rem"
  },

  headCell: {
    backgroundColor: "whitesmoke"
  },

  systemAttributes: {
    margin: "1rem",
    width: "100%"
  },

  deviceSystemsContainer: {
    width: "50rem",
    marginBottom: "2rem"
  },
  arrowIcon: {
    right: 10,
    top: 19.5
  },
  labelStyle: {
    maxHeight: "100px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#7f7182",
    paddingRight: "14px"

  },
  addMargin: {
    marginTop: "5px"
  },
  iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    },
  dialogHeader: {
      paddingLeft: 20,
      paddingRight: 13,
      backgroundColor: "#f6f6f7",
      borderBottom: "1px solid #d5d2d5",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 60,
      minHeight: 60
    },
  headerTitle: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal"

    },
  dialogContent: {
      flex: "1 1 auto",
      padding: 20,
      overflowY: "auto",
      paddingBottom: 0
    },
  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  loaderContainer: {
    alignSelf: "center",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexFlow: "column nowrap",
    justifyContent: "center"
  }
}));
export default useStyles;
