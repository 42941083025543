import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { t } from "ttag";
import {BluePath, CoolMode, HotMode, ModeOval, RedPath} from "../../icons/";
import useStyles from "./SiteCountCard.style";

const SiteCountCard: React.FC<any> = (props) => {
  const classes = useStyles();
  const {critical, malfunction, notConnected, indoorUnits, onIndoorUnits, coolUnits, hotUnits, name} = props;

  return (
      <div id="site" className={classes.siteCard}>
      <div id="row1" className={classes.row1Holder}>
      <Typography className={classes.siteName}>{name}</Typography>
      <div className={classes.dataRow}>
      <Typography className={classes.dataTitle}>{t`System/Indoor`}</Typography>
      <Typography className={classes.dataCount}>{critical}</Typography>
      </div>
      <div className={classes.dataRow}>
      <Typography className={classes.dataTitle}>{t`Communications`}</Typography>
      <Typography className={classes.dataCount}>{malfunction}</Typography>
      </div>
      <div className={clsx(classes.dataRow, classes.removeMarginAndBorder)}>
      <Typography className={classes.dataTitle}>{t`Anomalies`}</Typography>
      <Typography className={classes.dataCount}>{notConnected}</Typography>
      </div>
      </div>
      <div id="row2" className={classes.row2Holder}>
      <div className={classes.relativeContainer}>
      <Typography className={classes.onIndoorCountStyle}>{onIndoorUnits}</Typography>
      <Typography className={classes.indoorUnitsCountStyle}>{indoorUnits}</Typography>
      <CircularProgressbarWithChildren
            value={((hotUnits + coolUnits) / indoorUnits) * 100}
            strokeWidth={4}
            styles={buildStyles({
              pathColor: "#f00",
              trailColor: "#eee"
              // strokeLinecap: "butt"
            })}
          >
            {/* Foreground path */}
            <CircularProgressbar
              value={(coolUnits / indoorUnits) * 100}
              strokeWidth={4}
              styles={buildStyles({
                trailColor: "transparent"
                // strokeLinecap: "butt"
              })}
            />
          </CircularProgressbarWithChildren>
      </div>
      <Typography className={classes.indoorUnitsText}>Indoor Units On</Typography>
      </div>
      <div id="row3" className={classes.row3Holder}>
      <Typography className={classes.modesCount}><CoolMode className={classes.modesIcons}/> {coolUnits}{" "}{t`Units`}</Typography>
      <Typography className={classes.modesCount}><HotMode className={classes.modesIcons}/> {hotUnits}{" "}{t`Units`}</Typography>
      </div>
      </div>
  );
};

export default SiteCountCard;
