import {
    // Button,
    createStyles,
    Dialog,
    IconButton,
    makeStyles, MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Tooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { ArrowDownO, Close } from "../../icons";
import SvgOfflineBoltOutlined from "../../icons/OfflineBoltOutlined";
import { IDevice } from "../../models/Devices";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ISite } from "../../models/Sites";
import { ISystem } from "../../models/Systems";

const useStyles = makeStyles(() =>
    createStyles({
        errorText: {
            color: "rgb(240, 81, 70)"
        },
        dialogTitle: {
            backgroundColor: "#f6f6f7",
            borderBottom: "1px solid #d5d2d5",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "60px"
        },
        clearButton: {
            width: "40px",
            height: "40px"
        },
        powerMeterBtns: {
            display: "flex",
            flexDirection: "row-reverse"
        },
        powerMeterBtn: {
            width: "5rem",
            marginLeft: "12px"
        },
        // powerMeter-System table
        tableContainer: {
            height: "100%"
        },
        tableHeadCell: {
            color: "#79797d",
            height: "45px",
            padding: "0 15px",
            fontSize: "13px",
            fontWeight: "bold",
            lineHeight: "normal",
            borderBottom: "none",
            letterSpacing: "normal",
            textTransform: "uppercase",
            backgroundColor: "#efeff2",
            width: "36ch"
        },
        headCellName: {
            backgroundColor: "whitesmoke",
            width: "7rem"
        },
        headCellSystem: {
            backgroundColor: "whitesmoke",
            width: "6rem"
        },
        textFieldValue: {
            color: "#aaa2aa"
        },
        formControl: {
            width: "8rem"
        },
        modalActions: {
            padding: 20,
            display: "flex",
            justifyContent: "flex-end"
        },
        dialogStyle: {
            width: "fit-content",
            maxWidth: "95%",
            display: "flex",
            minHeight: "fit-content",
            maxHeight: "calc(100% - 140px)",
            height: "750px"
        },
        dialogText: {
            color: "#29132e",
            lineHeight: "normal",
            fontSize: "18px",
            fontWeight: 500
        },
        selectStyle: {
            lineHeight: "44px",
            width: "100%",
            height: "44px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            "&>div": {
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: "32px",
                paddingLeft: "14px"
            }
        },
        arrowDownIcon: {
            top: 19.5,
            right: 9
        },
        iconBtnStyle: {
            width: 30,
            height: 30,
            borderRadius: 6,
            padding: 0
        },
        powerIcon: {
            paddingTop: 4,
            paddingLeft: 4
        },
        dialogHeader: {
            paddingLeft: 20,
            paddingRight: 13,
            backgroundColor: "#f6f6f7",
            borderBottom: "1px solid #d5d2d5",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 60,
            minHeight: 60
        },
        headerTitle: {
            fontFamily: "RobotoMedium",
            color: "#29132e",
            lineHeight: "normal",
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: "normal"

        },
        dialogContent: {
            flex: "1 1 auto",
            padding: 20,
            overflowY: "auto",
            paddingBottom: 0
        }
    })
);

const PowerMetersControl: React.FC<{ device?: IDevice | null, site?: ISite | null }> = ({ device, site }) => {
    const powerMeters = useStoreState((s) => s.powerMeters.allPowerMeters);
    const updatePowerMeterSystem = useStoreActions((action) => action.powerMeters.updatePowerMeterState);
    const removePowerMeterFromSystem = useStoreActions((action) => action.systems.deletePowerMeter);
    const assignPowerMeterToSystem = useStoreActions((action) => action.systems.assignPowerMeter);
    const unAssignPowerMeter = useStoreActions((action) => action.systems.unAssignPowerMeter);
    const allSystemsState = useStoreState((s) => s.systems.allSystems);

    const getDeviceSystems = useStoreState((state) =>
        state.devices.getDeviceSystems);
    const getSiteSystems = useStoreState((state) =>
        state.sites.getSiteSystems);
    const getDevicePowerMeters = useStoreState((state) =>
        state.devices.getDevicePowerMeters);
    const getSitePowerMeters = useStoreState((state) =>
        state.sites.getSitePowerMeters);
    const [allSystems, setAllSystems] = useState<any>([]);
    const [allPowerMeters, setAllPowerMeters] = useState<any>([]);
    const [systemToPowerMeter, setSystemToPowerMeter] = useState<any>({});
    const [] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const classes = useStyles();
    useEffect(() => {
        if (isEdit) {
            const systems = device ? getDeviceSystems(device.id)
                : site ? getSiteSystems(site.id)
                    : [];
            const powerMeters = device ? getDevicePowerMeters(device?.id)
                : site ? getSitePowerMeters(site.id)
                    : [];
            setAllSystems(systems);
            setAllPowerMeters(powerMeters);
            const systemToPowerMeterMap: any = {};
            for (let system of systems) {
                if (system.powerMeters) {
                    const firstPowerMeter = system?.powerMeters[0];

                    systemToPowerMeterMap[system.id] = firstPowerMeter;

                }

            }

            setSystemToPowerMeter(systemToPowerMeterMap);
        }

    }, [site, device, powerMeters, isEdit]);

    const getOptions = () => {
        const options = _.map(allPowerMeters, (item: any) => ({
            name: item.name,
            value: item.id,
            key: item.id
        }));

        return [
            {
                name: "NA",
                value: 999,
                key: 999
            },
            ...options
        ];
    };

    const onClose = () => {
        setIsEdit(false);
    };

    const onSave = async () => {
        const systemIds = Object.keys(systemToPowerMeter);

        for (let systemId of systemIds) {
            const system = allSystemsState[systemId];
            if (system) {
                if (system.powerMeters.length && systemToPowerMeter[systemId] === 999) {
                    for (let oldPowerMeter of system.powerMeters) {
                        updatePowerMeterSystem({ ...powerMeters[oldPowerMeter], ...{ system: null } });
                        unAssignPowerMeter({ systemId: system.id, powerMeterId: oldPowerMeter });
                        removePowerMeterFromSystem({ systemId: system.id, powerMeterId: oldPowerMeter });
                    }
                }
                if (systemToPowerMeter[systemId] !== 999 && !_.includes(system?.powerMeters, systemToPowerMeter[systemId])) {
                    if (system?.powerMeters.length) {
                        for (let oldPowerMeter of system.powerMeters) {
                            updatePowerMeterSystem({ ...powerMeters[oldPowerMeter], ...{ system: null } });
                            unAssignPowerMeter({ systemId: system.id, powerMeterId: oldPowerMeter });
                            removePowerMeterFromSystem({ systemId: system.id, powerMeterId: systemToPowerMeter[system.id] });
                        }
                    }
                    assignPowerMeterToSystem({ systemId: system.id, powerMeterId: systemToPowerMeter[system.id] });
                    updatePowerMeterSystem({ ...powerMeters[systemToPowerMeter[system.id]], ...{ system: system.id } });
                }
            }
        }

        setIsEdit(false);
    };
    const assignPowerMeterPopUp = () => {
        const hasPowerMeter = Object.keys(powerMeters).length !== 0;
        return (
            <Dialog
                onClose={onClose}
                aria-labelledby="simple-dialog-title"
                open={true}
                maxWidth="lg"
                classes={{ paperWidthLg: classes.dialogStyle }}
            >
                <div className={classes.dialogHeader}>
                    <Typography className={classes.headerTitle}>{t`Map systems to Power meters`}</Typography>
                    <IconButton
                        disableRipple
                        onClick={onClose}
                        className={classes.iconBtnStyle}
                    >
                        <Close color={"#7f7692"} />
                    </IconButton>
                </div>
                <div className={classes.dialogContent}>
                    {hasPowerMeter &&
                        <TableContainer className={classes.tableContainer}>
                            <Table size="medium" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            classes={{ root: classes.tableHeadCell }}
                                            align="left"
                                        >
                                            {t`System`}
                                        </TableCell>
                                        <TableCell
                                            classes={{ root: classes.tableHeadCell }}
                                            align="left"
                                        >
                                            {t`Power Meter`}
                                        </TableCell>
                                        {/*
                                <TableCell
                                    classes={{ root: classes.tableHeadCell }}
                                    className={classes.headCellName}
                                    align="left"
                                >
                                    {t`Power Meter ID`}
                                </TableCell> */}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {_.map(allSystems, (system: ISystem, key: string) => {
                                        return (
                                            <TableRow
                                                key={key}
                                            >
                                                <TableCell align="left">
                                                    {system.name}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Select
                                                        displayEmpty
                                                        onChange={(event: any) => {
                                                            const { target: { value } } = event;
                                                            const clonePowerMeterToSystem = _.clone(systemToPowerMeter);
                                                            clonePowerMeterToSystem[system.id] = value;
                                                            setSystemToPowerMeter(clonePowerMeterToSystem);
                                                        }}
                                                        variant="outlined"
                                                        disableUnderline
                                                        classes={{ icon: classes.arrowDownIcon }}
                                                        IconComponent={ArrowDownO}
                                                        className={classes.selectStyle}
                                                        placeholder={t`Power Meter`}
                                                        value={systemToPowerMeter[system.id] ? systemToPowerMeter[system.id] : 999}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}
                                                    >
                                                        {_.isEmpty(getOptions()) ? <MenuItem value={"none"} disabled>{t`No Options`}</MenuItem> : <MenuItem value={""} disabled
                                                            style={{ display: "none" }}
                                                        ></MenuItem>}
                                                        {getOptions().map((option: any) => <MenuItem key={option.key} value={option.value}>
                                                            {option.name}
                                                        </MenuItem>
                                                        )}
                                                    </Select >
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {!hasPowerMeter &&
                        <Typography>
                            {t`No Power meters connected`}
                        </Typography>
                    }
                </div>
                <div className={classes.modalActions}>

                    <Button
                        title="close edit"
                        onClick={onClose}
                        uppercase="uppercase"
                        width={150}
                        white="white"
                        marginRight={true}
                    >
                        {t`Cancel`}
                    </Button>

                    <Button
                        title="save"
                        type="submit"
                        uppercase="uppercase"
                        width={150}
                        onClick={onSave}>
                        {t`Save`}
                    </Button>
                </div>
            </Dialog>
        );
    };

    return (
        <>
            <Tooltip arrow title={t`Map system to power meters`}>

                <IconButton
                    disableRipple
                    aria-label="lightning icon"
                    onClick={() => { setIsEdit(true); }}
                    className={clsx(classes.iconBtnStyle, classes.powerIcon)}
                >
                    <SvgOfflineBoltOutlined />
                </IconButton>
            </Tooltip>
            {isEdit && assignPowerMeterPopUp()}
        </>

    );
};

export default PowerMetersControl;
