import { t } from "ttag";

export default {
  SYSTEM_ERROR: {
    id: "SYSTEM_ERROR",
    name: t`System Error`,
    title: t`High`,
    color: "#ff0000",
    severity: 5
  },
  INDOOR_ERROR: {
    id: "INDOOR_ERROR",
    name: t`Indoor Error`,
    title: t`Medium`,
    color: "#ff78ff",
    severity: 4
  },
  MAINTENANCE: {
    id: "MAINTENANCE",
    name: t`Maintenance`,
    title: t`Medium/Low`,
    color: "#fcbc00",
    severity: 3
  },
  NOT_CONNECTED: {
    id: "NOT_CONNECTED",
    name: t`Communications`,
    title: t`Medium/Low`,
    color: "#35a8e0",
    severity: 2
  },
  ANOMALIES: {
    id: "ANOMALIES",
    name: t`Anomalies`,
    title: t`Medium/Low`,
    color: "#9f45e4",
    severity: 1
  },
  ALL_IS_WELL: {
    id: "ALL_IS_WELL",
    name: "",
    title: "",
    color: "#00b300",
    severity: 0
  },
  OPERATIONAL_TELEMETRY: {
    id: "OPERATIONAL_TELEMETRY",
    name: t`Operational telemetry`,
    title: t`High`,
    color: "#f05146",
    severity: 6
  }
};
