import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import useStyles from "./NavItem.style";

const NavItem = ({label, icon: Icon, path}: any) => {
  const classes = useStyles();
  const history = useHistory();

  const selected = history.location.pathname === path;
  return (
    <div>
      <Icon color={selected ? "#4b1c46" : ""} className={classes.icon} />
      <Typography className={clsx(classes.label, {[classes.selectedLabel]: selected})}>{label}</Typography>
    </div>
  );
};

export default NavItem;
