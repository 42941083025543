import { Site as SiteSdk } from "coolremote-sdk";
import { PowerReportSchedule as PowerReportScheduleSdk } from "coolremote-sdk";

import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  thunk,
  Thunk,
} from "easy-peasy";
import _ from "lodash";
import { IGroup } from "./Groups";
import { IRootStoreModel } from "./RootStore";

export interface IScheduledReport {
  name: string;
  id?: string;
  systemId?: string;
  users: any[];
  timeUnit: string;
  subTimeUnit: string;
  format: string;
  granualirity: string;
  systems?: any[];
  zones?: any[];
  siteId?: string;
}

export interface IScheduledReportMap {
  [key: string]: IScheduledReport;
}

export interface IScheduledReportsModel {
  allReports: IScheduledReportMap;
  initialize: Action<IScheduledReportsModel, any>;
  onInitialized: ActionOn<IScheduledReportsModel, IRootStoreModel>;
  getReport: Thunk<IScheduledReportsModel, string>;
  createReport: Thunk<IScheduledReportsModel, { siteId: string; data: any }>;
  updateReport: Thunk<IScheduledReportsModel, { data: any; reportId: string }>;
  removeReport: Thunk<IScheduledReportsModel, { reportId: string }>;
  updateReportsState: Action<IScheduledReportsModel, any>;
  updateRemovedReportsState: Action<IScheduledReportsModel, string>;
}

export const scheduledReportsModel: IScheduledReportsModel = {
  allReports: {},

  initialize: action((state, payload) => {
    const newReports: IScheduledReportMap = _(Object.values(payload))
      .map((group: any) => {
        const newPowerMeter: IScheduledReport = { ...group };
        return newPowerMeter;
      })
      .keyBy("id")
      .value();

    console.log("init reports:", debug(newReports));
    state.allReports = newReports;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {}
  ),
  getReport: thunk(async (actions, payload) => {
    const reports = await SiteSdk.getScheduledReports(payload);

    actions.initialize(reports);
    return reports;
  }),

  createReport: thunk(async (actions, payload) => {
    const updatedReport = await SiteSdk.createScheduledReports(
      payload.siteId,
      payload.data
    );

    actions.updateReportsState(updatedReport);
    return updatedReport;
  }),

  updateReport: thunk(async (actions, payload) => {
    const updatedReport = await PowerReportScheduleSdk.updateScheduledReport(
      payload.reportId,
      payload.data
    );
    actions.updateReportsState(updatedReport);
    return updatedReport;
  }),

  removeReport: thunk(async (actions, payload) => {
    const updatedReport = await PowerReportScheduleSdk.removeScheduledReport(
      payload.reportId
    );
    actions.updateRemovedReportsState(payload.reportId);

    return updatedReport;
  }),

  updateReportsState: action((state, payload) => {
    state.allReports[payload.id] = payload;
  }),

  updateRemovedReportsState: action((state, payload) => {
    delete state.allReports[payload];
  }),
};
