import { Typography } from "@material-ui/core";
import React from "react";
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { t } from "ttag";
import { CoolMode, HotMode } from "../../icons/";
import useStyles from "./SiteCountCard.style";

const SiteCountCard: React.FC<any> = (props) => {
  const { totalIndoorUnits, conditionIndoorUnits, blueIndoorUnits, redIndoorUnits, title, mode } = props;
  const classes = useStyles();

  const redAndBlueValue =  ((redIndoorUnits + blueIndoorUnits) / totalIndoorUnits) * 100;
  const blueValue =  (blueIndoorUnits / totalIndoorUnits) * 100;

  return (
    <div id="site" className={classes.siteCard}>
      <div className={classes.container}>
        <div className={classes.row}>
          <div id="column1" className={classes.column1Holder}>
            <div className={classes.relativeContainer}>
              <Typography className={classes.onIndoorCountStyle}>{conditionIndoorUnits}</Typography>
              <Typography className={classes.indoorUnitsCountStyle}>{totalIndoorUnits}</Typography>
              <CircularProgressbarWithChildren
                    value={totalIndoorUnits !== 0 ? redAndBlueValue : 0}
                    strokeWidth={4}
                    styles={buildStyles({
                      pathColor: "#f00",
                      trailColor: "#eee"
                    })}
                  >
                    <CircularProgressbar
                      value={totalIndoorUnits !== 0 ? blueValue : 0}
                      strokeWidth={4}
                      styles={buildStyles({
                        trailColor: "transparent"
                      })}
                    />
                </CircularProgressbarWithChildren>
            </div>
          </div>
          {mode === "heatcool" && <div id="column2" className={classes.column2Holder}>
            <Typography className={classes.modesCount}><CoolMode className={classes.modesIcons}/> {blueIndoorUnits}{" "}{t`Units`}</Typography>
            <Typography className={classes.modesCount}><HotMode className={classes.modesIcons}/> {redIndoorUnits}{" "}{t`Units`}</Typography>
          </div>}
        </div>
        <div className={classes.row}>
          <Typography className={classes.indoorUnitsText}>{title}</Typography>
        </div>
      </div>

    </div>
  );
};

export default SiteCountCard;
