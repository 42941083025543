import React from 'react';

function SvgCoolModeOff(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 40 35" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#D5D2D5"
          d="M39.5.5h-22A16.943 16.943 0 005.48 5.48C2.402 8.555.5 12.805.5 17.5s1.903 8.944 4.98 12.02A16.943 16.943 0 0017.5 34.5h22V.5z"
        />
        <g stroke="#35A8E0" strokeLinecap="round" strokeWidth={1.026}>
          <path d="M22.035 7.439V27.25m2.914-17.576l-2.914 1.78-2.913-1.78m0 15.503l2.912-1.78 2.915 1.78M30.583 12.34L13.488 22.351m16.639-6.368l-3.009-1.614.063-3.414m-13.377 7.834l3.009 1.615-.064 3.413M13.44 12.422l17.12 9.968M16.837 11.028l.073 3.414-3.006 1.623m13.398 7.8l-.072-3.413 3.005-1.623" />
        </g>
      </g>
    </svg>
  );
}

export default SvgCoolModeOff;
