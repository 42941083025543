import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },

  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },

  "@media (max-width: 970px)": {
    contentArea: {
      overflowX: "unset"
    }
  },

  siteViewContainer: {
    padding: "8px 12px",
    background: "white",
    marginBottom: "1rem !important",
    borderRadius: "4px",
    boxSizing: "border-box"
  },
  sitesContainer: {
    padding: "0 20px",
    display: "flex",
    overflow: "auto",
    marginTop: "20px"
  },

  editSiteDialog: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "30%"
  },

  headerButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "1rem 2rem"
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "-webkit-fill-available"
  },
  warnedSite: {
    backgroundColor: "rgba(248, 177, 51, 0.1)"
  },
  blockedSite: {
    backgroundColor: "rgba(239, 59, 47, 0.1)"
  }
});
export default useStyles;
