import {
  Button,
  Grid,
  IconButton,
  TableContainer,
  TextField,
  Typography
} from "@material-ui/core";

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import { Add as SvgAdd } from "../../icons";
import SvgUser from "../../icons/User1";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AddEditContact from "./AddEditContact";
import useStyles from "./Settings.style";

export interface IAlertGroupType {
  id: number;
  name: string;
  contacts: string[];
  enable: boolean;
  customer: string;
}

const ContactsList: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const getSelectedAlertGroup = useStoreState((state) => state.alertGroups.getSelectedAlertGroup);

  const { selectedAlertGroupId= "", userContacts } = props;
  const selectedAlertGroup = getSelectedAlertGroup(selectedAlertGroupId);
  let selectedAlertGroupContactIds = selectedAlertGroup?.contacts?.map((contact: any) => contact.contact) || [];

  const addContactToAlertGroup = useStoreActions((action) => action.addContactToAlertGroup);
  const deleteContact = useStoreActions((action) => action.contacts.deleteContact);
  const [error, setError] = useState<any>(null);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [editContact, setEditContact] = useState<any>(null);
  const [localContacts, setLocalContacts] = useState<any>(userContacts || {});
  const [filteredlocalContacts, setFilteredlocalContacts] = useState<any>(userContacts || {});
  const [searchValue, setSearchValue] = useState<string>("");
  useEffect(() => {
    setLocalContacts(userContacts);
    userContacts && filterContacts(userContacts, searchValue);
  }, [userContacts]);

  useEffect(() => {

    selectedAlertGroupContactIds = selectedAlertGroup?.contacts?.map((contact: any) => contact.contact) || [];
    setLocalContacts(localContacts);

    localContacts && filterContacts(localContacts, searchValue);
  }, [selectedAlertGroup, localContacts]);

  const filterContacts = (localContacts: any, searchValue: string) => {
    const filteredContacts = Object.values(localContacts).filter((contact: any) => {
      return selectedAlertGroupContactIds.indexOf(contact._id) < 0;
    }).filter((contact: any) => {
      const first = contact.firstName.toUpperCase();
      const last = contact.lastName.toUpperCase();
      const search = searchValue.toUpperCase();
      return (first.indexOf(search) >= 0 || last.indexOf(search) >= 0);
    });
    setFilteredlocalContacts(filteredContacts);
  };

  const errorBox = error ? (
    <ErrorBox error={error} onClose={() => setError(null)} />
  ) : null;
  const addContact = (contact: any) => {
    if (!selectedAlertGroup){
      setError("select an AlertGroup to add contact to it");
      return;
    }

    addContactToAlertGroup({
      id: selectedAlertGroup.id,
      contactId: contact._id,
      sendEmail: false,
      sendSms: false
       }).then(() => {
      selectedAlertGroupContactIds.push(contact._id);
    })
    .catch((err: any) => {
      setError(err.message);
    });
  };

  const handleSearchValueChange = (event: any) => {
    const {target: { value }} = event;
    setSearchValue(value);
    filterContacts(localContacts, value);
};

  const handleDelete = (item: any) => {
    deleteContact({id: item.id})
    .then(() => {
      delete localContacts[item.id];
      filterContacts(localContacts, searchValue);
    });
  };
  const closeDialog = () => {
    setEditContact(null);
    setOpenAdd(false);
  };

  return (
    <div>
      <div className={classes.alertGroupHeader}>
        <div className={classes.modeHeader}>
          <Typography variant="h6">{t`Contacts`}</Typography>
          <TextField
                placeholder="Search"
                variant="outlined"
                value={searchValue}
                onChange={handleSearchValueChange}
                className={classes.textField}
            />
        </div>
        <div className={classes.addContactIcon}>
          <LightTooltip title={t`Add New Contact`}>
              <IconButton
                  className={classes.plusIcon}
                  aria-label="add icon"
                  onClick={() => setOpenAdd(true)}
              >
                  <SvgAdd />
              </IconButton>
          </LightTooltip>
        </div>
      </div>

      {errorBox}
      <TableContainer className={classes.tableContainer}>
        <div className={classes.innerTableContainer}>
          {_.sortBy(filteredlocalContacts, [(contact) => contact.firstName.toUpperCase()], ["asc"]).map((contact: any) => (
            <div key={contact._id} className={classes.userContainer} onDoubleClick={() => setEditContact(contact)}>
              <Grid container direction="row" className={classes.user}>
                <Typography variant="body2" className={classes.userName}><SvgUser /> {`${contact.firstName} ${contact.lastName}`}</Typography>
                <div className={classes.contactActionsContainer}>
                  <LightTooltip title={t`Add contact`}>
                    <Button
                    onClick={() => addContact(contact)}
                    style={{ textTransform: "none" }}>
                      {t`Add`}
                    </Button>
                  </LightTooltip>
                    <Delete
                        type={t`Contact`}
                        object={contact}
                        detach={handleDelete}
                    />
                </div>
              </Grid>
            </div>
          ))}
        </div>
      </TableContainer>
      {(editContact || openAdd) && (
        <AddEditContact
          onClose={closeDialog}
          editContact={editContact}
          localContacts={localContacts}
          setLocalContacts={setLocalContacts}
        />
      )}
    </div>
  );
};
export default ContactsList;
