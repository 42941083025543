import { fade, makeStyles, Theme } from "@material-ui/core";

// @ts-ignore
const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "-webkit-fill-available",
    width: "100%",
    background: "rgb(236, 235, 239)",
    overflowY: "auto",
    overflowX: "auto"
  },

  nameContainer: {
    // borderRight: "1px solid #ebebeb",
    paddingRight: "30px"
    // height: '90%',
  },

  gridContainer: {
    // height: '100%'
  },

  headerStyle: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#4b1c46",
    marginBottom: "20px"
  },

  subHeaderStyle: {
    color: "#545964",
    fontSize: "20px"
  },
  subSubHeaderStyle: {
    color: "#4b1c46",
    fontSize: "15px"
  },

  textFieldValue: {
    color: "#aaa2aa",
    width: "100%",
    height: 50,
    "& div": {
      height: 50
    }
  },

  rightCard: {
    paddingLeft: "20px",
    borderLeft: "1px solid #d5d2d5",
    width: "100%"
  },

  unitListContainer: {
    width: "100%"
  },

  oneTenantContainer: {
    height: "75%",
    // display: "flex",
    overflow: "auto",
    paddingBottom: "1rem",
    padding: "0 20px",
    // display: "flex",
    // overflow: "auto",
    width: "97.5%",
    marginLeft: "1.2rem",
    marginTop: "30px",
    paddingTop: "15px",
    backgroundColor: "white"
  },

  tenantGridContainer: {
    padding: "0 20px",
    display: "flex",
    overflow: "auto",
    width: "97.5%",
    marginLeft: "1.2rem",
    marginTop: "30px",
    paddingTop: "15px"
  },

  oneTenantBtns: {
    display: "flex",
    flexDirection: "row-reverse"
  },

  oneTenantBtn: {
    width: "5rem",
    marginLeft: "12px"
  },

  tenantUnitLists: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height: "50vh",
    width: "100%"
  },

  /// *************** no filter screen
  noContentContainer: {
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    width: "calc(100% - 60px)",
    height: "calc(100% - 130px)",
    alignSelf: "center",
    marginTop: "30px"
  },

  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%"
  },
  arrowIcon: { fontSize: "91px", transform: "rotate(90deg)" },
  noUnitText: {
    fontSize: "26px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#545964",
    marginTop: "43px"
  },
  error: {
    color: "red"
  },
  dialog: {
    width: "100%",
    borderRadius: "8px"
  },

  // **************************************

  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "1rem 2rem"
  },

  // ************************
  usersContainer: {
    height: "100%",
    display: "flex",
    // overflow: 'auto',
    paddingBottom: "1rem",
    marginTop: "30px"
  },

  tableContainer: {
    padding: "20px",
    display: "flex",
    flexFlow: "row nowrap",
    overflow: "auto",
    flex: 1,
    maxHeight: "calc(100vh - 150px)",
    maxWidth: "calc(100vw - 240px)"
    // marginTop: '30px',
  },

  paperTableContainer: {
    width: "inherit"
    // overflow: 'auto',
    // height: '100%'
  },
  paperLeftContainer: {
    height: "100%",
    background: "white",
    display: "flex",
    flexFlow: "row nowrap",
    width: "30%",
    padding: 20,
    paddingRight: 0,
    maxWidth: 400
  },
  scrollContainer: {
    display: "flex",
    overflow: "auto",
    marginRight: 5,
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: "transparent",
      borderRadius: 2
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#dfdde1"
    },
    maxHeight: "100%",
    height: "100%",
    flexFlow: "column nowrap",
    paddingRight: "15px",
    flex: 1
  },
  rightContainer: {
    // width: "inherit",
    // overflow: "auto",
    // height: "71vh"
    height: "100%",
    maxHeight: "100%",
    background: "white",
    display: "flex",
    flexFlow: "column nowrap",
    flex: 1,
    width: "70%"
  },

  table: {
    padding: "1rem 1rem",
    background: theme.palette.background.paper
  },
  tableHeadRow: {
    height: 45,
    padding: 0,
    backgroundColor: fade("#efeff2", 0.48)
  },
  tableHeadCell: {
    fontSize: "0.8rem",
    padding: "0 0.8rem",
    borderBottom: "none"
  },

  overWritePadding: {
    fontSize: "0.8rem",
    padding: "0 0.8rem"
  },

  editIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "green"
    }
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "red"
    }
  },

  overWriteIcon: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },

  // ************************ reports
  reportContainer: {
    marginTop: "2%",
    marginLeft: ".5%",
    marginRight: ".5%",
    width: "100%",
    height: "77vh"
  },

  cardcontent: {
    height: "100%"
  },

  subSectionReports: {
    marginTop: "10px",
    marginLeft: "38px"
  },

  subSectionContanier: {
    // display: 'flex',
    // justifyContent: 'space-between'
  },

  datePickerContanier: {
    padding: "0 20px",
    display: "flex",
    overflow: "auto"
  },
  datePickerCardContainer: {
    marginTop: "1%",
    marginLeft: "2%",
    marginRight: "2%",
    width: "100%"
  },
  selectDateRangeButton: {
    width: "30ch",
    height: 36,
    justifyContent: "space-between",
    margin: "1rem .5rem",
    padding: "0 .4rem 0 .5rem",
    textTransform: "none",
    // backgroundColor: 'rgba(255, 255, 255, 0.15)',
    // backgroundColor: 'rgba(66, 26, 64, 1)',
    // color: theme.palette.common.white,
    // backgroundColor: theme.palette.primary.dark,
    // background: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white
    }
  },
  selectDateRangeButton__text: {
    fontSize: "0.8125rem",
    fontWeight: "normal"
  },
  selectDateRangeButton__icon: {
    "&>svg": {
      fontSize: "1.5rem"
    }
  },
  rangePickerContainer: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    // height: '100%',
    // width: '100%',
    justifyContent: "space-evenly",
    alignItems: "center",
    boxShadow: "5px 5px 10px 0px rgba(138,138,138,1)"
  },

  calendarButtons: {
    display: "flex",
    flexFlow: "row nowrap",
    width: "100%",
    justifyContent: "flex-end",
    paddingRight: "20px",
    alignItems: "center",
    marginTop: "-10px",
    paddingBottom: "10px"
  },

  // ************************* reports
  chipContainer: {
    width: "100%",
    display: "flex",
    overflow: "hidden",
    paddingTop: "15px",
    maxWidth: "100%",
    minHeight: 106,
    maxHeight: 106,
    flexFlow: "row nowrap",
    padding: "0 20px",
    justifyContent: "space-between"
  },
  powerBtns: {
    display: "flex",
    flexFlow: "row wrap",
    overflow: "auto"
  },

  tenantChips: {},

  btnUsage: {
    width: "fit-content",
    minWidth: 155,
    height: 40,
    marginRight: 16,
    borderRadius: 28,
    backgroundColor: "#efeff2",
    marginBottom: 10,
    textTransform: "none"

    // color: "#4b1c46",
    // marginRight: "15px",
    // borderRadius: "28px",
    // backgroundColor: "#efeff2",
    // minWidth: "167px",
    // marginBottom: 10,
    // "&:focus": {
    //   backgroundColor: "#dfdde1",
    //   boxShadow: "none"
    // },
    // "&:hover": {
    //   boxShadow: "none"
    // }
  },

  btnUsageTextState: {
    fontSize: "18px",
    marginRight: "2px",
    fontFamily: "Roboto",
    position: "relative",
    bottom: 0
  },
  btnUsageTextStateSmaller: {
    position: "relative",
    top: "-1px"
    // fontSize:'16px',
  },

  btnUsageTextDate: {
    // fontSize:'18px',
    marginRight: "4px",
    marginLeft: "4px",
    // position:'relative',
    // top: '0',
    // fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "500",
    fontFamily: "RobotoMedium",
    position: "relative",
    bottom: 0
  },

  btnUsageTextType: {
    fontSize: "12px",
    position: "relative",
    bottom: 0
    // top: '3px',
  },

  coolUsage: {

    color: "#35a8e0"
    // "&:focus": {  //check focus effects
    //   backgroundColor: "#35a8e0",
    //   boxShadow: "none",
    //   color: " white"
    // }
    // color: 'white',
    // backgroundColor: '#35a8e0',
    // marginRight: '15px',
  },

  heatUsage: {
    color: "#f05146",

    "&:focus": {
      backgroundColor: "#f05146",
      boxShadow: "none",
      color: " white"
    }
  },

  activeCoolUsage: {
    boxShadow: "none",
    backgroundColor: "#35a8e0",
    color: " white"
  },

  activeHeatUsage: {
    boxShadow: "none",
    backgroundColor: "#f05146",
    color: " white"
  },

  activeBtnUsage: {
    boxShadow: "none",
    backgroundColor: "#dfdde1"
  },

  reportSystemTitle: {
    color: "#4b1c46",
    fontSize: "20px",
    marginTop: "10px",
    marginBottom: "20px",
    fontFamily: "RobotoBold",
    fontWeight: "500"
  },

  listSeperator: {
    width: "100%",
    height: "2px",
    border: "solid 1px #e1e2ea",
    marginTop: "40px"
  },

  reportCounterStyle: {
    color: "#545964",
    fontSize: "20px",
    fontFamily: "RobotoLight",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginLeft: 15,
    fontWeight: 300
  },

  reportTitle: {
    fontFamily: "RobotoMedium",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46"
    // fontWeight: '500'
  },

  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "100%",
    minHeight: "65px",
    alignItems: "center",
    padding: "0 20px"
  },
  divider: {
    borderBottom: "solid 1px #e1e2ea",
    height: 1,
    width: "calc(100% - 40px)",
    alignSelf: "center"
  },

  listStyle: {
    width: "100%",
    minHeight: 50,
    marginTop: 10,
    boxShadow: "0 2px 0 -1px rgba(205, 205, 205, 0.5)", // try none
    border: "solid 1px rgba(183, 185, 203, 0.37)",
    backgroundColor: "#ffffff",
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#29132e",
    textTransform: "none" ,
    "&:hover": {
      boxShadow: "0 2px 0 -1px rgba(205, 205, 205, 0.5)",
      border: "solid 1px rgba(183, 185, 203, 0.37)",
      backgroundColor: fade("#edebed", 0.4)
    },
    "&:active": {
      boxShadow: "0 2px 0 -1px rgba(205, 205, 205, 0.5)",
      border: "solid 1px rgba(183, 185, 203, 0.37)",
      backgroundColor: fade("#edebed", 0.4)
    }
  },

  spinner: {
    zIndex: "999",
    // width: '100%',
    // height: '100%',
    position: "absolute",
    top: "50vh",
    left: "55vw"

  },

  graphDropDown: {
    width: 140,
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.05)",
    backgroundColor: "##fff",
    minWidth: 140,
    height: 40
  },

  graphDropDownStyle: {
    marginTop: "2px",
    height: 40,
    "&>svg": {
      fontSize: "2.5rem !important",
      top: "0",
      height: 40,
      width: "37px"
    }
  },

  graphContainer: {
    // display: "flex",
    // flex: 1,
    // height: "calc(100% - 40px)",
    // maxHeight: "calc(100% - 40px)"
    height: "calc(100% - 35px)",
    maxHeight: "calc(100% - 35px)"

  },
  graphSection: {
    display: "flex",
    flex: 1,
    flexFlow: "column nowrap",
    height: "100%",
    maxHeight: "100%"
  },

  legendContainer: {
    width: "50%",
    height: "7%",
    marginLeft: "68px"
  },
  graphLabelText: {
    fontFamily: "Roboto",
    fontSize: "12px",
    marginRight: "15px",
    display: "inline-block"
  },
  graphLabelIcon: {
    height: "12px",
    width: "12px",
    marginRight: "4px",
    backgroundColor: "#aaa2aa",
    display: "inline-block"
  },
  graphLabelIconHeat: {
    backgroundColor: "#f05347"
  },
  graphLabelIconCool: {
    backgroundColor: "#35a8e0"
  },
  graphLabelIconTime: {
    backgroundColor: "#5e5764"
  },
  subSectionReportsGraph: {
    position: "relative",
    bottom: "-4.5%",
    height: "80%",
    display: "flex",
    width: " 100%"
  },
  subSectionReportsGraphAfterFetch: {
    bottom: "1%"
  },

  summeryContainer: {
    minHeight: "5rem",
    maxHight: "125px"
  },
  selectedItem: {
    backgroundColor: fade("#dfdde1", 0.5)
  },
  capacityFlagged: {
    color: "red"
  },
  capacityFlaggedTitle: {
    color: "red",
    fontSize: "20px",
    display: "inline",
    fontFamily: "RobotoBold"
  },

  reportDropDown: {
    width: "9rem"
    // marginRight: '9px',
    // float: 'right'
  },

  selectTime: {
    marginTop: "15px"
  },

  // ______________________________________scheduelr __________________________________
  oneReportContainer: {
    height: "75%",
    // display: "flex",
    // padding: "0 20px",
    // display: "flex",
    overflow: "unset",
    width: "100%",
    // marginLeft: "1.2rem",
    backgroundColor: "white",
    maxHeight: "100%"
  },
  oneReportContent: {
    overflow: "unset"
  },

  headerColumnTitle: {
    fontFamily: "RobotoMedium",
    fontSize: "20px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46",
    borderBottom: "1px solid #ebebeb",
    paddingBottom: 20,
    height: 46,
    marginBottom: 30

  },
  oneSchedulerLeftHeader: {
    borderBottom: "none",
    marginBottom: "0",
    paddingBottom: "0"
  },
  oneSchedulerLeftHeaderSystem: {
    marginTop: "1rem",
    marginBottom: "15px",
    borderBottom: "1px solid #ebebeb",
    height: "35px"
  },
  btnAdd: {
    float: "right",
    marginTop: "5px"
  },
  addRecipients: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #ebebeb",
    marginTop: -3,
    paddingBottom: 17,
    marginBottom: 30
  },

  reportNameStyle: {
    width: "100%",
    height: "56px",
    borderRadius: "5px",
    backgroundColor: "#ffffff"
  },
  leftSideRecipients: {
    paddingLeft: "20px",
    flexDirection: "column",
    justifyContent: "start",
    flexWrap: "no-wrap"
  },
  middleSideRecipients: {
    paddingLeft: "20px",
    paddingRight: "20px",
    justifyContent: "start",
    borderRight: "1px solid #ebebeb",
    borderLeft: "1px solid #ebebeb",
    flexFlow: "column nowrap"
  },
  searchUserScheduler: {
    width: "100%",
    height: "52px",
    borderRadius: "4px",
    backgroundColor: "rgba(239, 239, 242, 0.48)",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b4850",
    border: 0,
    "& *": {
      border: 0
    },
    "& div": {
      height: 52
    }
  },

  reportsFormatWrtapper: {
    // marginTop: "15px"
    // float: 'left'
  },
  formatSelect: {
    width: "100%",
    height: "40px",
    borderRadius: "4px",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.05)",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    // For the text inside
    color: "#545964",
    fontSize: "13px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginTop: 7,
    paddingLeft: 10,
    "&>svg": {
      fontSize: "2.5rem !important",
      top: "0",
      height: 40,
      width: "37px"
    }
  },

  formatLabelSelect: {
    width: "66px",
    height: "15px",
    fontFamily: "Roboto",
    fontSize: "13px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#7f7182"
  },

  btnGroupTimeunits: {
    height: "50px",
    width: "100%",
    borderRadius: "8px",
    boxShadow: "0 3px 6px -6px #aaa2aa",
    border: 0,
    "& button": {
      flex: 1
    }
    // background-color: #fefefe,
  },

  btnGroupErr: {
    color: "red"
  },

  reportDialog: {
    width: "100%",
    borderRadius: "8px",
    height: "660px",
    overflow: "unset"
  },

  selectedBtn: {
    backgroundColor: "#edebed",
    color: "#736973"
  },

  newUserStyle: {
    marginBottom: "21px"
  },

  cancelBtnGlobalStyle: {
    height: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#4b1c46",
    borderRadius: "4px",
    background: "white",
    border: "solid 1px #413044",
    marginRight: "30px",
    "&:hover": {
      background: "rgba(75, 28, 70, 0.1)"
    },
    "&:focus": {
      background: "rgba(75, 28, 70, 0.1)"
    },
    "&:disabled": {
      background: "#fff",
      border: "solid 1px #aaa2aa",
      color: "#4b1c46"
    }
  },

  saveBtnGlobalStyle: {
    height: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    borderRadius: "4px",
    background: "linear-gradient(to left, #421a40 100%, #29132e 0%)",
    "&:hover": {
      background: "linear-gradient(to left, #421a40 100%, #29132e 0%)"
    },
    "&:focus": {
      background: "linear-gradient(to left, #421a40 100%, #29132e 0%)"
    },
    "&:disabled": {
      background: "linear-gradient(to left, #776a7b 99%, #876e86 0%)",
      color: "#fff"
    }
  },

  scheduledUserList: {
    height: "100%",
    width: "100%",
    display: "flex",
    overflow: "unset",
    // paddingBottom: '1rem',
    marginTop: "30px"
  },

  schedulerTableContainer: {
    display: "flex",
    overflow: "auto",
    height: "340px" // 22rem
    // marginTop: '30px',
  },

  schedulerTable: {
    backgroundColor: "rgba(239, 239, 242, 0.48)"
  },
  schedulerTableHeadRow: {
    fontFamily: "RobotoMedium",
    fontSize: "13px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#79797d",
    padding: "0 15px",
    border: "none"
  },
  userNameCell: {
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964"
  },
  userIcon: {
    marginRight: "12px",
    transform: "scale(1.3)"
  },
  delCell: {
    textAlign: "right"
  },
  delIconEditReport: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: 10

  },
  middleTitle: {
    marginBottom: "15px",
    borderBottom: "1px solid #ebebeb",
    height: "35px"
  },
  field: { height: "25px" },
  // tableHeadCell: {
  //     fontSize: '0.8rem',
  //     padding: '0 0.8rem',
  //     borderBottom: 'none',
  // },
  HeaderHolder: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    marginBottom: 11
  },
  unitsSection: {
    display: "flex",
    flexFlow: "column nowrap",
    // height: "33.33%",
    // maxHight: "33.33%",
    marginBottom: "20px"
  },
  unitBtnsContainer: {
    display: "flex",
    flexFlow: "column nowrap"
  },
  borderLine: {
    borderRight: "solid 1px #e1e2ea",
    height: "100%",
    width: 1
  },
  addTopMargin: {
    marginTop: 50
  },
  btnStyle: {
    width: 44,
    height: 40,
    marginRight: 10,
    borderRadius: 8,
    backgroundColor: "#F6F5F6",
    textAlign: "center",
    padding: "11px 0",
    marginBottom: 10,
    color: "#545964",
    fontSize: 16,
    fontFamily: "Roboto",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.39px"
  },
  selectedBtnDays: {
    backgroundColor: "#D5D2D5"
  },
  btnsContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  monthlyBtnsStyle: {
    padding: 11,
    width: "unset"
  },
  rowContainer: {
    display: "flex",
    flex: 1,
    marginTop: 30,
    justifyContent: "space-between"
  },
  inputLabelStyle: {
    flex: 0.47,
    display: "flex",
    flexFlow: "column"
  },
  timeSelectionsContainer: {
    marginTop: 30
  },
  iconBtnStyle: {
     width: 30,
     height: 30,
     borderRadius: 6,
     padding: 0
   },
  dialogHeader: {
     paddingLeft: 20,
     paddingRight: 13,
     backgroundColor: "#f6f6f7",
     borderBottom: "1px solid #d5d2d5",
     display: "flex",
     justifyContent: "space-between",
     alignItems: "center",
     height: 60,
     minHeight: 60
   },
  headerTitle: {
   fontFamily: "RobotoMedium",
   color: "#29132e",
   lineHeight: "normal",
   fontSize: 18,
   fontWeight: 500,
   letterSpacing: "normal"

   },
  dialogContent: {
     flex: "1 1 auto",
     padding: 20,
     overflowY: "auto",
     paddingBottom: 0
   },
  actionsHolder: { padding: 20,
                   display: "flex",
                   justifyContent: "flex-end"
 },
  dialogContentZone: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto",
    paddingBottom: 0,
    display: "flex",
    flexFlow: "row nowrap"
  },
  addIcon: {
    backgroundColor: "#ecebef"
  },
  headerTitleNoBorder: {
    fontFamily: "RobotoMedium",
    fontSize: "20px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46"
  },
  itemName: {
    fontFamily: "Roboto",
    fontSize: 15,
    fontweight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    marginBottom: 13
  },
  itemNameSelected: {
    fontFamily: "RobotoMedium",
    fontWeight: 500,
    color: "#4b1c46"
  },
  itemsPart: {
    marginBottom: 19
  },
  smallCheckbox: {
    marginRight: "15px",
    padding: 0,
    "& span": { width: "24px", height: "24px", borderRadius: "3px", backgroundColor: "#fff" }
  },
  cellStyle: {
    padding: 10
  },
  rowStyle: {
    background: "#fff"
  },
  userHolder: {
    display: "flex",
    alignItems: "center"
  },
  btnText: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#79797d",
    border: "solid 1px #d5d2d5 !important",
    textTransform: "unset"
  }

}));

export default useStyles;
