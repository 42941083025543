import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core/";
import { FilterList } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Lookup } from "../../components/Lookup";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import { useStoreState } from "../../models/RootStore";
import useStyles from "./Alerts.style";

const AlertsTable: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { appliedFilters, page, rowsPerPage, rows, setSelectedAlert, clearAllFilters, preSelectedAlert, setPreSelectedAlert } = props;
  const [clickedHead, setClickedHead] = useState("");
  const [lookupAnchor, setAnchor] = useState(null);
  const [alertsPerPage, setAlertsPerPage] = useState([]);
  const tableHasFilters = !!appliedFilters["statuses"].length || !!appliedFilters["codes"].length || !!appliedFilters["types"].length || !!appliedFilters["description"].length;
  const handleChangePage = (event: unknown, newPage: number) => {
    props.setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    props.setPage(0);
    props.setRowsPerPage(event.target.value);
  };
  const updateClickedHead = (event: any, columnHead: string) => {
    setClickedHead(columnHead);
    setAnchor(event.currentTarget);
  };
  const onApply = (selectedFilters: any) => {
    const selectedFiltersObject = { [clickedHead]: selectedFilters };
    props.getSelectedFilters(selectedFiltersObject);
    setClickedHead("");
  }; // Get one page out of alerts array
  const _getAlertsPerPage = ({ page, rowsPerPage, rows }: any) => {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };
  const errorTypes = useStoreState((state) => state.serviceErrorTypes);
  const getSystem = useStoreState(
    (state) => state.systems.getSystem
  );

  const getMoreInfo = (alert: any) => {
    if (alert.unitIds.length !== 1) {
      return;
    }
    const data: any = { time: alert.timestamp, alertCheck: true, unitId: alert.unitIds[0] };
    history.push({
      pathname: `/unit-diag/${alert.unitIds[0]}`,
      state: data
    });
  };

  useEffect(() => {
    const alertsPerPage = _getAlertsPerPage({ page, rowsPerPage, rows });
    setAlertsPerPage(alertsPerPage);
    preSelectedAlert && page !== 0
      ? setPreSelectedAlert(false)
      : setSelectedAlert(alertsPerPage[0]);
  }, [page, rowsPerPage, rows]);

  return (
    <div style={{ height: "100%" }}>
      {props.isDisabled ? (
        <div className={classes.progress}>
          <div className={classes.progressContainer}>
            <div className={clsx(classes.processingAnimationContainer)}>
              <CircularProgress />
            </div>
            <Typography variant="h5">{t`Loading Alerts`}</Typography>
          </div>
        </div>
      ) : (
          <div className={classes.tablePaper}>
            <div className={classes.tablePagesHeader}>
              <TablePagination
                colSpan={7}
                count={rows.length}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: false
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className={classes.pagingStyle}
              />
              {props.isDisabled && (
                <TablePagination
                  backIconButtonProps={{disableRipple: true, classes: {root: classes.iconBtnStyle}}}
                  nextIconButtonProps={{disableRipple: true, classes: {root: classes.iconBtnStyle}}}
                  colSpan={7}
                  count={0}
                  rowsPerPage={props.rowsPerPage}
                  page={props.page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: false
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  className={classes.pagingStyle}
                />
              )}
            </div>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={clsx(classes.columnsTitles, classes.dateColumn)}
                      align="left"
                      style={{ minWidth: "132px", maxWidth: "132px", width: "132px" }}
                    >{t`DATE/TIME`}</TableCell>
                    <TableCell
                      className={classes.columnsTitles}
                      align="left"
                    >{t`UNIT`}</TableCell>
                    <TableCell
                      className={classes.columnsTitles}
                      align="left"
                    >{t`SITE`}</TableCell>
                    <TableCell
                      className={classes.columnsTitles}
                      align="left"
                    >{t`SYSTEM`}</TableCell>
                    <TableCell
                      className={classes.columnsTitles}
                      align="left"
                      onClick={(e: any) => updateClickedHead(e, "description")}
                    >
                      <div className={classes.headContainer}>
                        {t`DESCRIPTION`}
                        <FilterList
                          className={clsx(classes.filterStyle, {
                            [classes.blueFilter]: !_.isEmpty(appliedFilters.description)
                          })}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.columnsTitles}
                      align="left"
                      onClick={(e: any) => updateClickedHead(e, "statuses")}
                      style={{ minWidth: "110px", maxWidth: "110px", width: "110px" }}
                    >
                      <div className={classes.headContainer}>
                        {t`STATUS`}
                        <FilterList
                          className={clsx(classes.filterStyle, {
                            [classes.blueFilter]: !_.isEmpty(appliedFilters.statuses)
                          })}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.columnsTitles}
                      align="left"
                      onClick={(e: any) => updateClickedHead(e, "codes")}
                      style={{ minWidth: "99px", maxWidth: "99px", width: "99px" }}
                    >
                      <div className={classes.headContainer}>
                        {t`CODE`}
                        <FilterList
                          className={clsx(classes.filterStyle, {
                            [classes.blueFilter]: !_.isEmpty(appliedFilters.codes)
                          })}
                        />
                      </div>
                    </TableCell>
                    {/* <TableCell
                    className={clsx(classes.columnsTitles, classes.dateColumn)}
                    align="left"
                  >{t`ALERT TYPE`}</TableCell> */}
                    <TableCell
                      className={classes.columnsTitles}
                      align="left"
                      onClick={(e: any) => updateClickedHead(e, "types")}
                      style={{ minWidth: "118px", maxWidth: "118px", width: "118px" }}
                    >
                      <div className={classes.headContainer}>
                        {t`TYPE`}
                        <FilterList
                          className={clsx(classes.filterStyle, {
                            [classes.blueFilter]: !_.isEmpty(appliedFilters.types)
                          })}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {alertsPerPage.map((alert: any, index: number) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      onClick={() => props.onRowSelect(alert)}
                      onDoubleClick={() => getMoreInfo(alert)}
                      className={alert.id === props.selectedAlert ? classes.selected : ""}
                    >
                      <TableCell className={clsx(classes.cellStyle, classes.showWhiteSpace)} align="left" >
                        {alert.time}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cellStyle, classes.limitWidth)}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {alert.unitName || "-"}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cellStyle, classes.limitWidth)}
                        align="left"
                      >
                        {alert.siteName || "-"}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cellStyle, classes.limitWidth)}
                        align="left"
                      >
                        {alert.systemName || "-"}
                      </TableCell>
                      <TableCell className={classes.cellStyle} align="left"
                        style={{ minWidth: "25ch", maxWidth: "25ch", width: "100%" }}>
                        {/* {alert.alertItemContainerIds ? getTitle(alert.alertItemContainerIds.systemId, alert.errorCode) : alert.description} */}
                        {alert.description}
                      </TableCell>

                      <TableCell className={classes.cellStyle} align="left">
                        {alert.status}
                      </TableCell>
                      <TableCell className={classes.cellStyle} align="left">
                        <LightTooltip
                          title={alert.errorDescription}
                        >
                          <Typography>
                            {alert.errorCode || ""}</Typography>
                        </LightTooltip>
                      </TableCell>
                      <TableCell className={classes.cellStyle} align="left">
                        {alert.alertType}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      {clickedHead && (
        <Lookup
          filtersList={props.filterValues[clickedHead]}
          appliedFilters={props.appliedFilters[clickedHead]}
          onApply={onApply}
          lookupAnchor={lookupAnchor}
          onClose={() => setClickedHead("")}
          tableHasFilters={tableHasFilters}
          clearAllFilters={clearAllFilters}
        />
      )}
    </div>
  );
};
export default AlertsTable;
