import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React , {useEffect, useState} from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { t } from "ttag";
import {BottomNav, TopBar} from "../../components";
import AlertsMap from "../../components/AlertsMap/AlertsMap";
import ErrorLogs from "../../components/ErrorLogs/ErrorLogs";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import SystemTable from "../../components/SystemTable/SystemTable";
import {ArrowBack, Close, ControlMobile, CoolMode, Dashboard as MDashboard, HotMode, MobileLogo, MobileMenu, NotitifactionMobile, ReportsMobile, SettingsMobile} from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AlertsBand from "./AlertsBand";
import useStyles from "./Dashboard.style";
import SiteCountCard from "./SiteCountCard";
import TOU from "./TOU";

const Dashboard: React.FC<RouteComponentProps & any> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const me = useStoreState((s) => s.users.me);
  const selections = useStoreState((s) => s.selections.mobileSelections);
  const acceptTOU = useStoreActions((a) => a.users.acceptTOU);
  const allSites = useStoreState((state) => state.sites.allSites);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const types = useStoreState((state) => state.types.allTypes);
  const openParsedAlerts = useStoreState((state) => state.alerts.openParsedAlerts);
  const { unitTypes, operationModes, operationStatuses } = types;

  const [sites, setSites] = useState<any>({});

  const accept = () => {
    acceptTOU();
  };

  useEffect(() => {
    const {customerId, siteId} = selections;
    if (!customerId){
      return;
    }

    const sites: any = allSites;
    const sitesCounts: any = {};
    Object.values(allUnits).forEach((unit: any) => {
      const {site, activeOperationMode, activeOperationStatus, type, customer} = unit;
      const siteObject = allSites[site];

      if (!siteObject || type !== unitTypes.indoor || (siteId && siteId !== site) || (customerId !== customer)){
        return;
      }

      if (!sitesCounts[site]){
        sitesCounts[site] = {id: site, name: siteObject.name, indoorUnits: 0, onIndoorUnits: 0, coolIndoorUnits: 0, heatIndoorUnits: 0, critical: 0, malfunction: 0, notConnected: 0};
        // delete sites[site];
      }

      let {indoorUnits, onIndoorUnits, coolIndoorUnits, heatIndoorUnits} = sitesCounts[site];

      sitesCounts[site].indoorUnits = ++indoorUnits;

      if (activeOperationStatus === 1){ //on
        sitesCounts[site].onIndoorUnits = ++onIndoorUnits;

        if (activeOperationMode === 0){ //cool
          sitesCounts[site].coolIndoorUnits = ++coolIndoorUnits;
        }

        if (activeOperationMode === 1){ //heat
          sitesCounts[site].heatIndoorUnits = ++heatIndoorUnits;
        }
      }

    });

    Object.values(openParsedAlerts).forEach((alert: any) => {
        const { severity = {}, siteId } = alert;
        const { id } = severity;

        if (sitesCounts[siteId]){
            let {critical, malfunction, notConnected} = sitesCounts[siteId];

            if (id === "SYSTEM_ERROR") {
              sitesCounts[siteId].critical = ++critical;
            } else if (id === "INDOOR_ERROR") {
              sitesCounts[siteId].critical = ++critical;
            } else if (id === "NOT_CONNECTED") {
              sitesCounts[siteId].malfunction = ++malfunction;
            } else if (id === "ANOMALIES") {
              sitesCounts[siteId].notConnected = ++notConnected;
            }
        }
    });

    setSites(sitesCounts);
  }, [allUnits, selections, openParsedAlerts]);

  return (
    <>
    <TopBar hideUnitsFilter leftIconComponent={<MobileLogo />}/>
    <div className={classes.view}>
      <div className={classes.contentArea} style={{background: "white"}}>
      <AlertsBand />

      <div id="sitesHolder" style={{display: "flex", flexFlow: "column nowrap", padding: "0 20px", overflow: "auto",
                                    maxHeight: "calc(100% - 306px)"}}>
      {Object.values(sites).map((site: any) =>
        <SiteCountCard
        key={`site-${site.id}`}
        critical={site.critical}
        malfunction={site.malfunction}
        notConnected={site.notConnected}
        name={site.name}
        indoorUnits={site.indoorUnits}
        onIndoorUnits={site.onIndoorUnits}
        coolUnits={site.coolIndoorUnits}
        hotUnits={site.heatIndoorUnits}
        />
      )

      }
      </div>

      </div>
    </div>
    <BottomNav />
    </>
  );
};

export default Dashboard;

// <Dialog
//   onClose={_.noop}
//   aria-labelledby="simple-dialog-title"
//   open={me.isAcceptedTOU ? false : true}
//   maxWidth="lg"
//   fullWidth
// >
//   <DialogContent>
//     <TOU />
//   </DialogContent>
//
//   <DialogActions>
//     <Button
//       onClick={accept}
//       variant="contained"
//       color="primary"
//     >
//       {t`Accept`}
//     </Button>
//   </DialogActions>
// </Dialog>
