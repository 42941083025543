import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  errorText: {
    color: "rgb(240, 83, 71)"
  },
  siteNameStyle: {
    fontFamily: "RobotoMedium",
    fontSize: "1.375rem",
    color: "rgb(41, 19, 46)"
  },
  siteAddressStyle: {
    fontFamily: "Roboto",
    fontSize: "0.875rem",
    color: "rgb(84, 89, 100)"
  },
  siteActionsContainer: {
    borderLeft: "1px solid rgb(213, 210, 213)",
    display: "flex",
    // alignSelf: 'flex-end',
    alignItems: "center",
    alignSelf: "stretch"
  },
  iteActions: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    borderLeft: "1px solid rgb(213, 210, 213)",
    width: "300",
    justifyContent: "center"
  },
  deleteSite: {
    color: "#bdb9b98a"
  },
  iconButtonStyle: {
    padding: "6px !important",
    color: "black !important"
  },
  card: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "1rem",
    fontSize: "1rem",
    border: "1px solid rgb(213, 210, 213)",
    borderTopRightRadius: "0",
    borderTopLeftRadius: "0",
    height: "fit-content",
    backgroundColor: "transparent"
  },
  systemsContainer: {
    display: "flex",
    padding: "10px 0 0 10px !important",
    flexWrap: "wrap"
    // marginBottom: '1rem'
  },
  addSystemContainer: {
    height: "10rem"
  },
  addIcon: {
    color: "#aaa2aa"
  },
  addNewSystem: {
    borderRadius: "4px !important",
    width: 60,
    background: "#f6f5f6 !important",
    border: "1px solid #d5d2d5 !important",
    height: "100%"
  },
  siteBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0",
    border: "1px solid rgb(213, 210, 213)",
    // borderRadius: '4px',
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    padding: "0 20px",
    paddingRight: "0"
  },
  siteActions: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    borderLeft: "1px solid rgb(213, 210, 213)",
    width: "6rem",
    justifyContent: "space-evenly"
  },
  powerMeter: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    borderRight: "1px solid rgb(213, 210, 213)",
    width: "3rem",
    justifyContent: "space-evenly"
  },
  addDeviceBtn: {
    "&:hover": {
      border: "1px solid #aaa2aa",
      boxShadow: "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    },
    "&:active": {
      border: "1px solid #aaa2aa",
      boxShadow: "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    }
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  headerTitle: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal"

  },
  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto",
    paddingBottom: 0
  }
});
export default useStyles;
