import * as React from "react";

function SvgReportsMobile(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <defs>
        <path id="ReportsMobile_svg__a" d="M0 .672h21.999V14.3H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 6.328)">
          <mask id="ReportsMobile_svg__b" fill="#fff">
            <use xlinkHref="#ReportsMobile_svg__a" />
          </mask>
          <path
            fill={props.color || "#AAA2AA"}
            d="M1.116 14.3a1.117 1.117 0 01-.851-1.839l7.266-8.548c.37-.434.909-.69 1.48-.7a2.076 2.076 0 011.504.646l3.607 3.933 5.923-6.74a1.116 1.116 0 111.677 1.473l-6.1 6.943c-.375.425-.912.67-1.478.677-.61-.024-1.106-.231-1.486-.646L9.053 5.568l-7.087 8.338c-.221.26-.534.394-.85.394"
            mask="url(#ReportsMobile_svg__b)"
          />
        </g>
        <path
          fill={props.color || "#AAA2AA"}
          d="M25.477 12.942l-9.42-9.42a.304.304 0 01.216-.52h9.421c.17 0 .306.136.306.305v9.42c0 .272-.33.408-.523.215"
        />
      </g>
    </svg>
  );
}

export default SvgReportsMobile;
