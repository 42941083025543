import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
  optionStyle: {
    color: "#000",
    fontSize: "0.8125rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  optionText: {
    fontSize: "0.8125rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  selectContainer: {
    height: "36px",
    width: "200px",
    marginRight: "10px"
  },
  specificSelectContainer: {

  },
  selectStyle: {
    width: "10rem",
    color: "#fff",
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    borderRadius: "4px",
    margin: "1rem .5rem",
    padding: "0 9px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },
  specificSelectStyle: {
    width: "10rem",
    height: "3.4rem",
    color: "#fff",
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    borderRadius: "4px",
    margin: "1rem .5rem",
    padding: "0 9px",
    // height: "36px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },
  inputStyle: {},
  iconStyle: {
    right: "0"
  },
  focusStyle: {
    padding: 0,
    margin: 0,
    color: "#545964",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.05)",
    border: "solid 1px #d5d2d5",
    paddingLeft: "12px",
    "&:hover": {
      border: "solid 1px #aaa2aa",
      backgroundColor: "#f6f6f7"
  },
    "&:focus": {
      border: "solid 1px #aaa2aa",
      backgroundColor: "#f6f6f7"
  }

  },
  arrowIcon: {
    color: "#fff",
    fontSize: "2.5rem !important",
    top: "0",
    height: "36px"
  }
}));

export default useStyles;
