import { Button, Card, CircularProgress, Grid, Icon , IconButton, Typography} from "@material-ui/core/";
import { FilterList, Reply } from "@material-ui/icons";
import {
  addDays,
  sub
} from "date-fns";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import {BottomNav, TopBar} from "../../components";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import DatePicker from "../../components/MobileDatePicker/MobileDatePicker";
import { Lookup } from "../../components/MobileLookup";
import alertSeverities from "../../constants/alertSeverities";
import {AlertBell, ArrowBack, ArrowDown, Close, ControlMobile, Dashboard as MDashboard, MobileLogo, MobileMenu, NotitifactionMobile, ReportsMobile, SettingsMobile} from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { checkDateInRange } from "../../services/timeService";
import ExportUtils from "../../utils/ExportUtils";
import AlertInfo from "./AlertInfo";
import useStyles from "./Alerts.style";

const severties: any = alertSeverities;
const eventTypesToSeverityMapping: any = {
  cleanFilter: "MAINTENANCE",
  customTelemetry: "ANOMALIES",
  deviceDisconnected: "NOT_CONNECTED",
  indoorUnitError: "SYSTEM_ERROR",
  outdoorUnitError: "INDOOR_ERROR",
  unitDisconnected: "NOT_CONNECTED",
  unitsDisconnected: "NOT_CONNECTED",
  unspecified: "ALL_IS_WELL",
  operationalTelemetry: "ANOMALIES" };

const eventUserTypes: any = {
  cleanFilter: "Maintenance",
  customTelemetry: "Anomalies",
  deviceDisconnected: "Communication",
  indoorUnitError: "System Error",
  outdoorUnitError: "Indoor Error",
  unitDisconnected: "Communication",
  unitsDisconnected: "Communication",
  ALL_IS_WELL: "ALL_IS_WELL",
  operationalTelemetry: "Anomalies"
};

const Alerts: React.FC<any> = (props: any) => {
  const history = useHistory();

  const selectedAlertObj = useStoreState((state) => state.selectedAlert);
  const allSites = useStoreState((state) => state.sites.allSites);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const getSystem = useStoreState((state) => state.systems.getSystem);
  const errorTypes = useStoreState((state) => state.serviceErrorTypes);

  const [descriptionArray, setDescriptionArray] = useState<any>({});
  const [selectedAlert, setSelectedAlert] = useState<any>(null),
    [open, setOpen] = useState<boolean>(false),
    [] = useState<boolean>(true),
    classes = useStyles(),
    { dateFormat , timeFormat } = useStoreState((state) => state.users),
    eventClearTypesMirror = useStoreState((state) => state.types.eventClearTypesMirror),
    types = useStoreState((state) => state.types.allTypes),
    eventTypesMirror = useStoreState((state) => state.types.eventTypesMirror),
    eventStatusTypesMirror = useStoreState((state) => state.types.eventStatusTypesMirror),
    getAlertFeildsNamesAndIds = useStoreState((state) => state.alerts.getAlertFeildsNamesAndIds),
    getAlertsByTime = useStoreActions((action) => action.alerts.getAlertsByTime),
    updateSelections = useStoreActions((a) => a.selections.updateSelections),
    allCustomers = useStoreState((state) => state.customers.allCustomers),
    allAlerts = useStoreState((state) => state.alerts.allAlerts),
   allOpenAlerts = useStoreState((state) => state.alerts.allOpenAlerts),
    [isDisabled, setIsDisabled] = useState(true),
    [selectedFilters, setSelectedFilters] = useState<any>({ code: {}, status: {}, type: {}, description: {} }),
    [deleteFlag, setDeleteFlag] = useState<boolean>(false),
    [firstload, setFirstLoad] = useState<string>("processing"),
    setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus),
    {dateRange} = useStoreState((s) => s.selections.selections),
    selections = useStoreState((s) => s.selections.mobileSelections),
    [resetFilters, setResetFilters] = useState<boolean>(false),
    [filteredAlerts, setFilteredAlerts] = useState<any>([]),
    [errorCodeArray, setErrorCodesArray] = useState<any>({}),
    [alertTypeArray, setAlertTypeArray] = useState<any>({}),
    [statusesArray, setStatusesArray] = useState<any>({}),
    [preSelectedAlert, setPreSelectedAlert] = useState<any>(true);
  const localStoredRowsPerPage = Number(localStorage.getItem("AlertsRowsPerPage")) || 10;
  const [rows, setRows] = useState<any>([]);
  const [page, setPage] = useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(localStoredRowsPerPage);
  const { eventClearTypes, eventTypes, trapTypes } = types;
  const  [opendatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [openFilters, setOpenedFilters] = useState<boolean>(false);

  useEffect(() => {
      if (!dateRange?.endDate || !dateRange?.startDate) {
        updateSelections({
          type: "time",
          data: {
            startDate: new Date(new Date().setHours(0, 0, 0) - 2 * 24 * 60 * 60 * 1000),
            endDate: new Date()
          }
        });
      }
    }, []);

  useEffect(() => {
    if (!dateRange) {
      return;
    }

    const startTime = Date.UTC(dateRange?.startDate.getFullYear(), dateRange?.startDate.getMonth(), dateRange?.startDate.getDate()) - 32400000;
    const endTime = Date.UTC(dateRange?.endDate.getFullYear(), dateRange?.endDate.getMonth(), dateRange?.endDate.getDate(), 23, 59, 59) + 32400000;

    (async function() {
      setIsDisabled(true);
      setRows([]);

      const allAlertsByTime = await getAlertsByTime({ startTime, endTime, type: types.applications.management });

      const sortedAlerts = _.orderBy({ ...allAlertsByTime, ...allOpenAlerts }, ["eventTime"], ["desc"]);
      const rowsToRender: any = [];
      const errorCodesArray: any = {};
      const alertTypeArray: any = {};
      const descriptionArray: any = {};
      const statusesArray: any = {};
      sortedAlerts.forEach((alert: any) => {
        // if (eventTypes.customTelemetry === alert.type){
        //   return;
        // }

        const isUnitsDisconnectedType = eventTypes.unitsDisconnected === alert.type;
        const isUnitDisconnectedType = eventTypes.unitDisconnected === alert.type;
        const isEntitiesNoDateType = eventTypes.entitiesHealthLowIstat === alert.type
          || eventTypes.entitiesHealthLowOstat === alert.type
          || eventTypes.entitiesHealthLowPstat === alert.type
          || eventTypes.entitiesHealthLowLqstat === alert.type
          || eventTypes.entitiesHealthLowSstat === alert.type;

        const isEntityNoDataType = eventTypes.entityHealthLowIstat === alert.type
          || eventTypes.entityHealthLowOstat === alert.type
          || eventTypes.entityHealthLowPstat === alert.type
          || eventTypes.entityHealthLowLqstat === alert.type
          || eventTypes.entityHealthLowSstat === alert.type;

        const namesAndIds = getAlertFeildsNamesAndIds({ type: alert.type, resources: alert.resources, status: alert.status });
        const { eventNames, eventIds } = namesAndIds;

        if (!eventIds.siteId || isUnitDisconnectedType || isEntityNoDataType || isEntitiesNoDateType) {
          return;
        }

        const timezone = allSites[eventIds.siteId]?.timezone || "";

        const status = (eventStatusTypesMirror[alert.status].split(/(?=[A-Z])/)).map((word: string) => {
          word = word[0].toUpperCase() + word.substr(1);
          return word;
        }).join(" ") || "";

        if (status !== "Open" && !checkDateInRange(moment(dateRange?.startDate).format("MM/DD/YYYY"),
          moment(dateRange?.endDate).format("MM/DD/YYYY"),
          moment(alert.eventTime).tz(timezone).format("MM/DD/YYYY"))
        ) {
          return;
        }

        const severity = severties[eventTypesToSeverityMapping[eventTypesMirror[alert.type]]];
        const alertType = severity.name;
        const errorCode = isUnitsDisconnectedType || isEntitiesNoDateType ? "" : alert.data;

        rowsToRender.push({
          type: alert.type,
          acknowledgedTime: alert.acknowledgedTimestamp ? moment(alert.acknowledgedTimestamp).tz(timezone).format(`${dateFormat}   ${timeFormat}`) : false,
          time: moment(alert.eventTime).tz(timezone).format(`${dateFormat}   ${timeFormat}`),
          clearTime: moment(alert.clearTime).tz(timezone).format(`${dateFormat}   ${timeFormat}`),
          status,
          alertType,
          siteName: eventNames.siteName,
          unitName: eventNames.unitName,
          deviceName: eventNames.deviceName,
          systemName: eventNames.systemName,
          unitIds: eventIds.unitIds,
          systemId: eventIds.systemId,
          customerName: allCustomers[alert.customer]?.name || "",
          clearReason: alert.clearReason ? (eventClearTypesMirror[alert.clearReason] || "").replace(/trap/g, "Anomaly").split(/(?=[A-Z])/).map((word: string) => {
            word = word[0]?.toUpperCase() + word.substr(1);
            return word;
          }).join(" ") : "",
          userText: !isEntitiesNoDateType ? alert.userText : "",
          shortId: alert.shortId,
          alertItemContainerIds: { ...eventIds, customerId: alert.customer },
          description: alert.trapDescription,
          errorCode,
          timestamp: alert.eventTime,
          severity,
          id: alert.id
        });
      });

      setErrorCodesArray(errorCodesArray);
      setStatusesArray(statusesArray);
      setAlertTypeArray(alertTypeArray);
      setDescriptionArray(descriptionArray);
      setRows(rowsToRender);
      setIsDisabled(false);
    })();
  }, [dateRange]);

  useEffect(() => {
    setPage(0);
    if (!rows.length) {
      setFilteredAlerts([]);
      return;
    }

    const filteredAlerts = getFilteredAlerts(rows);
    setFilteredAlerts(filteredAlerts);
  }, [selections, selectedFilters, rows]);

  useEffect(() => {
    if (preSelectedAlert && !_.isEmpty(selectedAlertObj) && !_.isEmpty(filteredAlerts)) {

      const alertIndex = _.findIndex(filteredAlerts, { id: selectedAlertObj.id });
      const pageNum = Math.ceil((alertIndex + 1) / rowsPerPage);
      setSelectedAlert(filteredAlerts[alertIndex]);
      setPage(pageNum - 1);
    }
  }, [filteredAlerts, rowsPerPage]);

  const getTitle = (systemId: string, errorCode: any) => {
    const system = getSystem(systemId);

    const brand = system?.brandNum || "";
    const error = _.isNaN(+errorCode) ? errorCode : +errorCode;

    return errorTypes.errorCodeTypes[brand] ? errorTypes.errorCodeTypes[brand][error] : "";
  };

  const clearAllFilters = () => {
    setSelectedFilters({ code: [], status: [], type: [], description: [] });
  };
  const selectUnit = (unitId: string) => {
    updateSelections({ type: "unit", data: unitId });
  },
    onRowSelect = (alert: any) => {
      setSelectedAlert(alert);
      setOpen(true);
    },
    setPageNum = (num: number) => {
      setPage(num);
    },
    setNumPerPage = (num: number) => {
      setRowsPerPage(num);
      localStorage.setItem("AlertsRowsPerPage", String(num));
    },
    updateFilters = (newFilters: any) => {
      setSelectedFilters({ ...selectedFilters, ...newFilters });
      setPage(0);
    },
    itemGotDeleted = () => {
      setSelectedAlert(null);
      setDeleteFlag(!deleteFlag);
    },
    addResolvedDescription = (resolvedDescription: string, id: string) => {
      const foundIndex = rows.findIndex((x: any) => x.id === id);
      rows[foundIndex] = { ...rows[foundIndex], userText: resolvedDescription };
      setRows([...rows]);
      setSelectedAlert(rows[foundIndex]);
    },
    setAcknowledged = (ackAlert: any) => {
      const changedAckAlerts = filteredAlerts.map((alert: any) => {
        let selectedAlert = null;
        if (alert.id === ackAlert.id) {
          alert.status = ackAlert.data ? "Acknowledged" : "Open";
          selectedAlert = alert;
        }
        return alert;
      });

      setFilteredAlerts(getFilteredAlerts(changedAckAlerts));
      setSelectedAlert(selectedAlert);
    },
    convertDataToCSV = () => {
      let csvRows: any = [];
      const headers =
        "DATE & TIME,CLOSE DATE & TIME,UNIT NAME,SITE NAME,SYSTEM NAME,DESCRIPTION,ALERT STATUS,ERROR CODE,ERROR DESCRIPTION,ALERT TYPE";
      for (const row of filteredAlerts) {
        const description = (row.description || "").replace(/(\r\n|\n|\r|,)/gm, " ");
        const unitName = (row.unitName || "").replace(/(\r\n|\n|\r|,)/gm, " ");
        const siteName = (row.siteName || "").replace(/(\r\n|\n|\r|,)/gm, " ");
        const systemName = (row.systemName || "").replace(/(\r\n|\n|\r|,)/gm, " ");
        const errorDescription = (row.errorCode && row?.alertItemContainerIds?.systemId) ?
          (getTitle(row.alertItemContainerIds.systemId, row.errorCode) || "").replace(/(\r\n|\n|\r|,)/gm, " ") : "";

        const customeRow: any = [
          row.time,
          row.status !== "Open" ? row.clearTime || "" : "",
          unitName,
          siteName,
          systemName,
          description,
          row.status,
          row.errorCode,
          errorDescription,
          row.alertType
        ];

        csvRows.push(customeRow.join(","));
      }

      const csvContent = "data:text/csv;charset=utf-8," + headers + "\n" + csvRows.join("\n");
      ExportUtils.downloadFile(csvContent, `Alerts Log`);
    };

  // Apply filters and selections to alerts
  const getFilteredAlerts = (alerts: any[]) => {
    function applyFilters(alerts: any[]) {
      return _(alerts)
        .filter((alert) => {
          return _.isEmpty(selectedFilters.code) ? true : selectedFilters.code[alert.errorCode];
        })
        .filter((alert) => {
          return _.isEmpty(selectedFilters.type) ? true : selectedFilters.type[alert.alertType];
        })
        .filter((alert) => {
          return _.isEmpty(selectedFilters.status) ? true : selectedFilters.status[alert.status];
        })
        .filter((alert) => {
          return _.isEmpty(selectedFilters.description) ? true : selectedFilters.description[alert.description];
        })
        .value();
    }
    function applySelections(alerts: any[]) {
      return _(alerts)
        .filter((alert: any) =>
          selections.unitId ? alert.alertItemContainerIds.unitId ? alert.alertItemContainerIds.unitId === selections.unitId : (alert.alertItemContainerIds.unitIds.length > 1 && alert.alertItemContainerIds.unitIds.includes(selections.unitId)) : true
        )
        .filter((alert: any) =>
          selections.siteId ? alert.alertItemContainerIds.siteId === selections.siteId : true
        )
        .filter((alert: any) =>
          selections.systemId ? alert.alertItemContainerIds.systemId ? alert.alertItemContainerIds.systemId === selections.systemId :
            (alert.alertItemContainerIds.systemIds.length > 1 && alert.alertItemContainerIds.systemIds.includes(selections.systemId)) : true
        )
        .filter((alert: any) =>
          selections.systemId && _.includes(selections.systemId, "_") ?
            allUnits[alert.alertItemContainerIds.unitId].line.toString() === selections.systemId.split("_")[0] && allUnits[alert.alertItemContainerIds.unitId].device === selections.systemId.split("_")[1] :
            true

        )
        .filter((alert: any) =>
          selections.customerId
            ? alert.alertItemContainerIds.customerId === selections.customerId
            : true
        )
        .value();
    }

    const updateFiltersVals = (rows: any) => {
      const errorCodeArr: any = {};
      const statusArr: any = {};
      const alertTypesArr: any = {};
      const descriptionArr: any = {};

      for (let rowIndex in rows) {
        const { status, errorCode, alertType, description } = rows[rowIndex];
        if (!!errorCode) { errorCodeArr[errorCode] = true; }
        if (!!status) { statusArr[status] = true ; }
        if (!!alertType) { alertTypesArr[alertType] = true; }
        if (!!description) { descriptionArr[description] = true; }
      }
      setErrorCodesArray(errorCodeArr);
      setStatusesArray(statusArr);
      setAlertTypeArray(alertTypesArr);
      setDescriptionArray(descriptionArr);
    };

    const filteredRows = applyFilters(applySelections(alerts));
    updateFiltersVals(filteredRows);
    return filteredRows;
  };

  const filterValues: any = {
    status: statusesArray,
    code: errorCodeArray,
    // description: descriptionArray,
    type: alertTypeArray
  };

  const onApply = (selectedFilters: any) => {
    setSelectedFilters({...selectedFilters});
    setOpenedFilters(false);
  };

  return (
    <>
    <TopBar
      leftIconComponent={selectedAlert ? <ArrowBack /> : <MobileLogo />}
      leftAction={selectedAlert ? () => setSelectedAlert(null) : () => history.push("/dashboard")}
    />
    <div className={classes.view}>
      <div className={classes.contentArea} style={{height: "calc(100% - 83px)", background: "white"}}>
      <div id="title" style={{display: "flex", alignItems: "center", minHeight: "53px",
                              borderBottom: "solid 1px #e5e2e5",
                              padding: "0 20px"}}>
      <AlertBell style={{width: 25, height: 25}}/>
      <Typography style={{marginLeft: "15px",
                          fontFamily: "Roboto",
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#15111f"
    }}>{t`Alerts Log`}</Typography>
      </div>
      <div style={{display: "flex", flexFlow: "row nowrap", justifyContent: "space-between", alignItems: "center", padding: "0 20px", minHeight: 50, borderBottom: "solid 1px #e5e2e5"  }} onClick={() => setOpenDatePicker(true)}>
      <Typography style={{
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 600,
        color: "#29132e"
      }}
      >{`${moment(dateRange?.startDate || new Date()).format("MMM DD")} - ${moment(dateRange?.endDate || new Date()).format("MMM DD")}`}</Typography>
      <ArrowDown
       style={{height: 8, width: 12}}
      />
      </div>

      <div onClick={() => setOpenedFilters(true)} style={{display: "flex", flexFlow: "row nowrap", justifyContent: "space-between", alignItems: "center", padding: "0 20px", minHeight: 50, borderBottom: "solid 1px #e5e2e5"  }}>
      <Typography style={{
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 600,
        color: "#29132e",
        marginRight: 18,
        whiteSpace: "nowrap"
      }}
      >{t`Filter By`}</Typography>
      <div className={classes.filterItemsContainer}>
      {Object.keys(selectedFilters.code).map((filterName: string) =>
        <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
          <Typography className={classes.filterItemText}>{filterName}</Typography>
          <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => {event.stopPropagation(); event.preventDefault(); delete selectedFilters.code[filterName]; setSelectedFilters({...selectedFilters}); }}>
            <Close className={classes.filterItemCloseIcon}/>
          </IconButton>
        </div>
       )
      }
      {Object.keys(selectedFilters.description).map((filterName: string) =>
        <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
          <Typography className={classes.filterItemText}>{filterName}</Typography>
          <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => {event.stopPropagation(); event.preventDefault(); delete selectedFilters.description[filterName]; setSelectedFilters({...selectedFilters}); }}>
            <Close className={classes.filterItemCloseIcon}/>
          </IconButton>
        </div>
       )
      }
      {Object.keys(selectedFilters.status).map((filterName: string) =>
        <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
          <Typography className={classes.filterItemText}>{filterName}</Typography>
          <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => {event.stopPropagation(); event.preventDefault(); delete selectedFilters.status[filterName]; setSelectedFilters({...selectedFilters}); }}>
            <Close className={classes.filterItemCloseIcon}/>
          </IconButton>
        </div>
       )
      }
      {Object.keys(selectedFilters.type).map((filterName: string) =>
        <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
          <Typography className={classes.filterItemText}>{filterName}</Typography>
          <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => {event.stopPropagation(); event.preventDefault(); delete selectedFilters.type[filterName]; setSelectedFilters({...selectedFilters}); }}>
            <Close className={classes.filterItemCloseIcon}/>
          </IconButton>
        </div>
       )
      }
      </div>
      <FilterList />
      </div>
      <div id="content" style={{overflow: "auto", height: "calc(100% - 230px)", maxHeight: "calc(100% - 230px)", display: "flex", justifyContent: "center", flexFlow: "row wrap"}}>
    {isDisabled ? <CircularProgress style={{alignSelf: "center"}} />  : <div style={{width: "100%"}}> {filteredAlerts.map((alert: any) =>
      <div id="topRow" style={{width: "100%", display: "flex", flexFlow: "row nowrap", minHeight: "120px", borderBottom: "solid 1px #e5e2e5", paddingLeft: 15, paddingRight: 5, paddingTop: 20 }}>
      <div /* column 1 */ style={{height: 19, display: "flex", alignItems: "center"}}>
      <div className={classes.typeKey} style={{ backgroundColor: alert.severity.color }} />
      </div>
      <div /*sec column */ style={{flex: 1, paddingLeft: 10}}>
      <div style={{display: "flex", flexFlow: "row nowrap", height: 19, alignItems: "center"}}>
      <Typography style={{flex: 1,
                          fontFamily: "Roboto",
                          fontSize: 16,
                          fontWeight: 600,
                          color: "#545964"
      }}>{alert.alertType}</Typography>
      <Typography style={{width: "120px",
                          fontFamily: "Roboto",
                          fontSize: 14,
                          color: "#15111f"
    }}>{alert.time}</Typography>
      <IconButton className={classes.iconBtn} onClick={() => onRowSelect(alert)} >
      <ArrowDown style={{height: 8, width: 12, transform: "rotate(-90deg)" }}/>
      </IconButton>
      </div>
      <div id="middleRows" style={{display: "flex", flexFlow: "column nowrap"}}>
      <Typography style={{fontFamily: "Roboto",   fontSize: 14, color: "#545964"}}>
          {`${!alert.unitName ? "-- ," : alert.unitName + "," } ${alert.siteName !== "-" ? alert.siteName : ""}`}
        </Typography>

      <Typography style={{
        fontFamily: "Roboto",
        fontSize: 14,
        // fontWeight: 500,
        color: "#545964"
      }}>{alert.description}</Typography>
      </div>
      <div id="bottomRow" style={{display: "flex", flexFlow: "row nowrap"}}>
      {alert.status === "Open" &&  <> <Typography style={{
          fontFamily: "Roboto",
          fontSize: 14,
          color: "#f5833c",
          width: "110px"
        }}>{alert.status}</Typography>
        <Typography style={{
            fontFamily: "Roboto",
            fontSize: 14,
            color: "#545964"
          }}>{alert.clearTime}</Typography>
        </>
      }
    {alert.status === "Closed" &&  <> <Typography style={{
        fontFamily: "Roboto",
        fontSize: 14,
        color: "#00b259",
        width: "110px"
      }}>{alert.status}</Typography>
      <Typography style={{
          fontFamily: "Roboto",
          fontSize: 14,
          color: "#545964"
        }}>{alert.clearTime}</Typography>
      </>
    }
      </div>
      </div>
      </div>
    )}
    </div>
  }
      </div>
      {selectedAlert && <AlertInfo
        open={true}
        alert={selectedAlert}
        onClose={() => setSelectedAlert(null)}
        itemGotDeleted={itemGotDeleted}
        addResolvedDescription={() => {}}
        setAcknowledged={setAcknowledged}
      />}
      {opendatePicker && <DatePicker close={() => setOpenDatePicker(false)}/>}
      {openFilters &&
        <Lookup
          filtersList={filterValues}
          appliedFilters={selectedFilters}
          onApply={onApply}
          onClose={() => setOpenedFilters(false)}
          title={t`Alert Log Filters`}
        />
      }
      </div>
    {(!selectedAlert &&  !opendatePicker && !openFilters) &&  < BottomNav  / >}
    </div>
    </>
  );
};
export default Alerts;
