import {
  Button,
  Grid,
  Radio,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import { Redirect } from "react-router";
import { t } from "ttag";
import * as Yup from "yup";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import CButton from "../../cool_widgets/Button";
import "../../icons.css";
import { useStoreActions } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import useStyles from "./ForgotPassword.style";
import InputField from "./InputField";

interface ILoginState {
  username: string;
  email: string;
  error?: string;
  oneField?: any;
}
const ForgotPasswordSchema = Yup.object({
  email: Yup.string().email("Invalid email"),
  username: Yup.string()
    .min(6, "Too Short!")
    .max(50, "Too Long!")
}).test("oneField", "", (value: any) => {
  if (value.email || value.username) {
    return true; // everything is fine
  }
  return new Yup.ValidationError(
    "you must provide at least one field",
    null,
    "oneField"
  );
});

const ForgotPassword: React.FC<any> = (props) => {
  const classes = useStyles();
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [isLoginBack, setIsLoginBack] = useState(false);
  const getRecoveryToken = useStoreActions(
    (action) => action.users.getRecoveryToken
  );
  const initialValues: ILoginState = {
    username: "",
    email: ""
  };
  const [selectedValue, setSelectedValue] = React.useState("username");
  const [error, setError] = useState<any>(null);
  const errorBox = error ? (
    <ErrorBox error={error} onClose={() => setError(null)} />
  ) : null;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleBack = () => {
    setIsLoginBack(true);
  };

  const handleSetPassword = (values: ILoginState) => {
    if (values.username || values.email) {
      const payload: any = selectedValue === "username" ? { username: values.username } : { email: values.email };

      getRecoveryToken(payload)
        .then((data: any) => {
          if (data) {
            setIsPasswordSet(true);

          } else {
            selectedValue === "username" ? setError("User not found") : setError("Email not found");
          }
        })
        .catch((err: any) => {
          setError(err.message);
        });
    }
  };

  if (isLoginBack) {
    return <Redirect to={"/"} />;
  }
  if (isPasswordSet) {
    return <Redirect to={"/reset-password"} />;
  }
  return (
    <div className={classes.Login}>
      <div className={classes.main}>
        <div className={classes.logos}>
          <img
            alt="logo"
            src={assetsService.getResourcePath("logo.png")}
            className={classes.logo}
          />
        </div>

        <div className={classes.interactionSection}>
          <div className={classes.content}>
            <Typography
              className={classes.title}
            >{t`Password Recovery`}</Typography>
            {errorBox}
            <Formik
              initialValues={initialValues}
              onSubmit={handleSetPassword}
              enableReinitialize={true}
              validationSchema={ForgotPasswordSchema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                handleSubmit,
                errors,
                touched,
                values,
                ...restFormikProps
              }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container className={classes.container}>
                      <Grid item className={classes.fieldContainer}>
                        <Radio
                          checked={selectedValue === "username"}
                          onChange={handleChange}
                          value="username"
                          color="default"
                          name="radio-button-demo"
                          className={classes.radio}
                        />
                        <Field
                          classes={{
                            root: classes.overrideField
                          }}
                          label={t`Your username`}
                          value={
                            selectedValue === "username"
                              ? values.username
                              : initialValues.username
                          }
                          name="username"
                          component={InputField}
                          error={
                            errors.username &&
                              touched.username &&
                              selectedValue === "username"
                              ? true
                              : false
                          }
                          helperText={
                            errors.username &&
                              touched.username &&
                              selectedValue === "username"
                              ? errors.username
                              : ""
                          }
                          onClick={() => setSelectedValue("username")}
                        />
                      </Grid>
                      <Grid item className={classes.fieldContainer}>
                        <Radio
                          checked={selectedValue === "email"}
                          onChange={handleChange}
                          value="email"
                          color="default"
                          name="radio-button-demo"
                          className={classes.radio}
                        />
                        <Field
                          classes={{
                            root: classes.overrideField
                          }}
                          label={t`Your email`}
                          value={
                            selectedValue === "email"
                              ? values.email
                              : initialValues.email
                          }
                          name="email"
                          component={InputField}
                          error={
                            errors.email &&
                              touched.email &&
                              selectedValue === "email"
                              ? true
                              : false
                          }
                          helperText={
                            errors.email &&
                              touched.email &&
                              selectedValue === "email"
                              ? errors.email
                              : ""
                          }
                          onClick={() => setSelectedValue("email")}
                        />
                      </Grid>
                      {errors.email || errors.username || errors.oneField ? (
                        <Typography style={{ color: "red" }}>
                          {" "}
                          {errors.oneField}
                        </Typography>
                      ) : null}
                      <Grid item className={classes.buttonContainer}>
                        <Button
                          disableTouchRipple
                          disableFocusRipple
                          title="Back to Login"
                          className={classes.buttonBack}
                          onClick={handleBack}
                        >
                          {t`Back to Login`}
                        </Button>
                        <CButton
                          title="Reset Password"
                          className={classes.buttonReset}
                          type="submit"
                          red
                        >
                          {t`Reset Password`}
                        </CButton>
                      </Grid>
                    </Grid>
                  </form>
                )}
            </Formik>
            <Typography className={classes.bottom}>
              {t`Forgot your username or email ?`}
              <br />
              {t`Please contact `}
              <a className={classes.mailto} href="mailto:support@coolremote.com">
                support@coolremote.com
              </a>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
