import {
    Button,
    Card,
    CardActions,
    Dialog,
    Grid,
    IconButton,
    Slider,
    Tooltip, Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { ModesList } from "../../components/ModesList";
import Switch from "../../components/SmallSwitch/Switch";
import SwitchList from "../../components/SwitchList/SwitchList";
import { modeIcons } from "../../constants/modes";
import scheduletypes from "../../constants/scheduletypes";
import StyledButton from "../../cool_widgets/Button";
import { Close, Plus } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IServiceTypes } from "../../models/Types";
import { IUnit } from "../../models/Units";
import assetsService from "../../services/assetsService";
import {
    FanMode,
} from "../../svgComponents";
import ApplySettings from "./ApplySettings";
import NewApplySettings from "./NewApplySettings";
import ScheduleIUnitItem from "./ScheduleIUnitItem";
import useStyles from "./Settings.style";

interface Props {
    children: React.ReactElement;
    open: boolean;
    value: any;
}
function ValueLabelComponent(props: Props) {
    const classes = useStyles();
    const { children, open, value } = props;

    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            placement="bottom"
            title={value}
            classes={{ popper: classes.tooltip, tooltip: classes.tooltipBox }}
        >
            {children}
        </Tooltip>
    );
}
const Settings: React.FC<any> = (props: any) => {
    const classes = useStyles();
    const isInitialized = useStoreState((s) => s.isInitialized);
    const temperatureScale = useStoreState((state) => state.users.me.temperatureScale);
    const selections = useStoreState((s) => s.selections.selections);
    const updateSelections = useStoreActions((a) => a.selections.updateSelections);
    const [selectedUnit, setSelectedUnit] = useState<IUnit | null>(null);
    const [unitsToSave, setUnitsToSave] = useState<any>([]);
    const allUnits = useStoreState((state) => state.units.allUnits);
    const getEWrcLock = useStoreActions((a) => a.units.getEWrcLock);
    const setManyEWrcLocks = useStoreActions((a) => a.units.setManyEWrcLocks);
    const setEWrcLock = useStoreActions((a) => a.units.setEWrcLock);
    const toggleEWrcLock = useStoreActions((a) => a.units.toggleEWrcLock);
    const allDevices = useStoreState((state) => state.devices.allDevices);
    const allSites = useStoreState((state) => state.sites.allSites);
    const [indoorUnits, setIndoorUnits] = useState<any>([]);
    const getUnitInfo = useStoreState((state) => state.units.getUnitInfo);
    const types = useStoreState((states) => states.types.allTypes);
    const getCustomerSchedules = useStoreActions((a) => a.schedules.getCustomerSchedules);
    const deleteSchedule = useStoreActions((actions) => actions.schedules.deleteSchedule);
    const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);
    const createScheduleAPI = useStoreActions((actions) => actions.schedules.createObjectSchedules);

    const [useTemperatureScale, setUseTemperatureScale] = useState(temperatureScale);
    const [helperMessgae, setHelperMessgae] = useState<string>("");
    const [supportedValues, setSupportedValues] = useState<any>({});
    const [disableAllSettings, setDisableAllSettings] = useState<boolean>(false);
    const [tempLimitFlags, setTempLimitFlags] = useState<any>({
        enableCoolMode: false,
        enableHeatMode: false,
        enableAutoMode: false
    });
    const [tStatFlags, setTStatFlags] = useState<any>({
        enableOnoff: false,
        enableMode: false,
        enableSetpoint: false
    });
    const [createNewSchedule, setCreateNewSchedule] = useState<any>(false);
    const [selectedSchedule, setSelectedSchedule] = useState<any>(null);
    const [allCustomerSchedules, setSllCustomerSchedules] = useState<any>({});
    const [isEWrcDisabled, setIsEWrcDisabled] = useState<boolean>(false);
    const updateUnits = useStoreActions((action) => action.units.updateUnits);
    const updateUnitsAndSchedules = useStoreActions((action) => action.units.updateUnitsAndSchedules);
    const onUnitSelected = useCallback(() => unitSelected(), [selections.unitId, selectedUnit, tStatFlags, unitsToSave]);
    const memoSideMenu = useCallback(() => sideMenu(), [selections, unitsToSave, disableAllSettings]);
    const schedulesMemo = useCallback(
        () => schedules(), [allCustomerSchedules, selectedUnit, createNewSchedule, allCustomerSchedules, isEWrcDisabled]);
    const getSite = useStoreState((state) => state.sites.getSite);
    const [siteOffset, setSiteOffset] = useState<number>(0);
    const [isApply, setIsApply] = useState<boolean>(false);
    const updateStoreUnit = useStoreActions(
        (a) => a.units._storeUpdateUnit
    );

    useEffect(() => {
        if (!selections.siteId) {
            return;
        }

        const timezone = getSite(selections.siteId)?.timezone || "";
        const offset = moment().tz(timezone).utcOffset();
        setSiteOffset(offset);
    }, [selections.siteId]);

    const getSchedules = () => {
        getCustomerSchedules({ id: selections.customerId, type: scheduletypes.eDeviceLimits }).then((res: any) => {
            setSllCustomerSchedules(res);
        });
    };

    useEffect(() => {
        if (!selections.customerId) {
            return;
        }
        getSchedules();
    }, [selections.customerId, selectedUnit]);

    useEffect(() => {
        const filterType = 1;

        const indoorUnitsOnly = Object.values(allUnits).filter((unit: any) => {
            const unitSystemId = unit.system;
            const unitDevice = allDevices[unit.device];
            if (_.isUndefined(unitDevice)) { return false; }
            const unitSite = allSites[unitDevice.site];
            if (_.isUndefined(unitSite)) { return false; }
            const unitCustomerId = unitSite.customer;
            if (_.isUndefined(unitCustomerId)) { return false; }

            return (
                unit.type === filterType &&
                (selections.customerId ? unitCustomerId === selections.customerId : true) &&
                (selections.siteId ? unitSite.id === selections.siteId : true) &&
                (selections.systemId ? unitSystemId === selections.systemId : true)
            );
        });

        if (!_.isEqual(indoorUnits, indoorUnitsOnly)) {
            setIndoorUnits(indoorUnitsOnly);
            setUseTemperatureScale(temperatureScale);
        }
    }, [allUnits, selections.siteId, selections.customerId, temperatureScale]);

    // When entering the page - if unit was selected already - show it
    useEffect(() => {
        if (selections.unitId) {
            const unit = _.find(indoorUnits, (unit) => unit.id === selections.unitId);

            if (!unit || _.isEqual(selectedUnit, unit)) {
                return;
            }

            if (wasControlChange(unit)) {
                return;
            }

            setSelectedUnit(unit);

        }
    }, [selections.unitId]);

    useEffect(() => {
        const values: any = {};
        const unit = _.cloneDeep(selectedUnit);

        if (unit) {
            values.temperatureLimits = unit.eWrcTemperatureLimits ? unit.eWrcTemperatureLimits : unit.temperatureLimits;
            setTempLimitFlags({
                enableCoolMode: unit.eWrcEnableCoolMode || false,
                enableHeatMode: unit.eWrcEnableHeatMode || false,
                enableAutoMode: unit.eWrcEnableAutoMode || false
            });
            setTStatFlags({
                enableOnoff: unit.eWrcEnableOnoff,
                enableMode: unit.eWrcEnableMode,
                enableSetpoint: unit.eWrcEnableSetpoint
                // enableOnState: unit.eWrcEnableOnState
            });
            setIsEWrcDisabled(unit.eWrcDisable || false);
        }

        setSupportedValues({ ...supportedValues, ...values });
        // })();
    }, [selectedUnit]);

    useEffect(() => {
        if (!selectedUnit) {
            return;
        }

        const values: any = {};
        const unit = _.cloneDeep(allUnits[selectedUnit.id]);
        if (unit) {
            // need the alternative in case unit doesn't support EwrcLimits
            values.temperatureLimits = unit.eWrcTemperatureLimits ? unit.eWrcTemperatureLimits : unit.temperatureLimits;
            setTStatFlags({
                enableOnoff: unit.eWrcEnableOnoff || false,
                enableMode: unit.eWrcEnableMode || false,
                enableSetpoint: unit.eWrcEnableSetpoint || false
                // enableOnState: unit.enableOnState || false
            });
            setTempLimitFlags({
                enableCoolMode: unit.eWrcEnableCoolMode || false,
                enableHeatMode: unit.eWrcEnableHeatMode || false,
                enableAutoMode: unit.eWrcEnableAutoMode || false
            });

        }

        setSupportedValues({ ...supportedValues, ...values });

    }, [
        selectedUnit && allUnits[selectedUnit.id].eWrcEnableOnoff, selectedUnit && allUnits[selectedUnit.id].eWrcEnableMode,
        selectedUnit && allUnits[selectedUnit.id].eWrcEnableSetpoint, selectedUnit && allUnits[selectedUnit.id].eWrcEnableOnState,
        selectedUnit && allUnits[selectedUnit.id].eWrcEnableCoolMode, selectedUnit && allUnits[selectedUnit.id].eWrcEnableHeatMode

    ]);

    // Update tStatLock when it changes
    useEffect(() => {
        if (selectedUnit) {
            setTStatFlags({
                enableOnoff: selectedUnit.eWrcEnableOnoff,
                enableMode: selectedUnit.eWrcEnableMode,
                enableSetpoint: selectedUnit.eWrcEnableSetpoint
                // enableOnState: selectedUnit.eWrcEnableOnState
            });
        }

    }, [
        selectedUnit && selectedUnit.eWrcEnableOnoff, selectedUnit && selectedUnit.eWrcEnableMode,
        selectedUnit && selectedUnit.eWrcEnableSetpoint
        // selectedUnit && selectedUnit.enableOnState
    ]);

    // Update tempLimits flags when it changes
    useEffect(() => {
        if (selectedUnit) {
            setTempLimitFlags({
                enableCoolMode: selectedUnit.eWrcEnableCoolMode,
                enableHeatMode: selectedUnit.eWrcEnableHeatMode,
                enableAutoMode: selectedUnit.eWrcEnableAutoMode
            });
        }

    }, [selectedUnit && selectedUnit.eWrcEnableCoolMode, selectedUnit && selectedUnit.eWrcEnableHeatMode, selectedUnit && selectedUnit.eWrcEnableAutoMode]);

    useEffect(() => {
        if (!selections.unitId) {
            return;
        }

        const unit = _.find(indoorUnits, (unit) => unit.id === selections.unitId);
        if (!unit) {
            return;
        }
        const unitDevice = allDevices[unit.device];
        if (unitDevice && unitDevice.protocolVersion < 4) {
            setDisableAllSettings(true);
            setHelperMessgae(t`You need to upgrade your device firmware to use this feature`);

        } else if (unitDevice && unitDevice.protocolVersion >= 4) {
            if (unitDevice && !unitDevice.isConnected) {
                setDisableAllSettings(true);
                setHelperMessgae("Device is disconnected");
            } else {
                setDisableAllSettings(false);
                setHelperMessgae("");
            }

        }

        if (wasControlChange(unit)) {
            return;
        }

        setSelectedUnit(unit);

    }, [selections.unitId, indoorUnits, allDevices]);

    const wasControlChange = (unit: any) => {
        if (!selectedUnit || !unit) {
            return false;
        }

        const checkSelectedUnit: any = _.clone(selectedUnit);
        const checkUpdatedSelectedUnit: any = _.clone(unit);

        delete checkSelectedUnit.activeSetpoint;
        delete checkSelectedUnit.activeOperationMode;
        delete checkSelectedUnit.activeOperationStatus;
        delete checkSelectedUnit.activeFanMode;
        delete checkSelectedUnit.activeSwingMode;

        delete checkUpdatedSelectedUnit.activeSetpoint;
        delete checkUpdatedSelectedUnit.activeOperationMode;
        delete checkUpdatedSelectedUnit.activeOperationStatus;
        delete checkUpdatedSelectedUnit.activeFanMode;
        delete checkUpdatedSelectedUnit.activeSwingMode;

        if (_.isEqual(checkSelectedUnit, checkUpdatedSelectedUnit)) {
            return true;
        }

        return false;
    };

    const getUnitKeyOfMode = (typesMode: string) => {
        let unitModeName: any = "";
        switch (typesMode) {
            case "operationModes":
                unitModeName = "supportedOperationModes";
                break;
            case "fanModes":
                unitModeName = "supportedFanModes";
                break;
            case "swingModes":
                unitModeName = "supportedSwingModes";
                break;
            case "cooling":
                unitModeName = 0;
                break;
            case "heat":
                unitModeName = 1;
                break;
            case "auto":
                unitModeName = 2;
                break;
            default:
                unitModeName = null;
                break;
        }
        return unitModeName;
    };

    const valueLabelFormat = (value: number) => {
        const valueToShow = value + (useTemperatureScale === 1 ? " °C" : " °F");
        return `${valueToShow}`;
    };

    if (!isInitialized) { return <Loading />; }
    const updateValues = (modeName: string, modeNumber: any) => {
        const locValues: any = { ...supportedValues };
        const unitModeName = getUnitKeyOfMode(modeName);
        if (!unitModeName) {
            return;
        }

        const checkIndex = locValues[unitModeName].indexOf(+modeNumber);
        if (checkIndex > -1) {
            locValues[unitModeName].splice(checkIndex, 1);
        } else {
            locValues[unitModeName] = [...locValues[unitModeName], +modeNumber];
        }
        setSupportedValues(locValues);
    };
    const handleSetCooling = (event: any, newValue: number | number[]) => {
        const locValues: any = { ...supportedValues };
        locValues.temperatureLimits[0] = newValue;
        setSupportedValues(locValues);
    };
    const handleSetHeat = (event: any, newValue: number | number[]) => {
        const locValues: any = { ...supportedValues };
        locValues.temperatureLimits[1] = newValue;
        setSupportedValues(locValues);
    };
    const handleSetAuto = (event: any, newValue: number | number[]) => {
        const locValues: any = { ...supportedValues };
        locValues.temperatureLimits[2] = newValue;
        setSupportedValues(locValues);
    };

    const handleSetTempLimitRange = (flagName: string) => {
        const nextFlagState = _.clone(tempLimitFlags);
        nextFlagState[flagName] = !nextFlagState[flagName];
        setTempLimitFlags(nextFlagState);
    };

    const getUnit = (unitId: string) => {
        const res = getUnitInfo(unitId);
        return (
            <div>{t`${res.customer.name}, ${res.site.name}, ${res.system ? res.system.name : "not assigned"},
             ${res.unit.name}`} </div>
        );
    };
    const handleAgree = (units: any) => {
        setUnitsToSave(units);
    };
    const handleSave = (unitId: any = "") => {
        const data: any = {
            unitIds: unitId ? [unitId] : unitsToSave,
            // supportedOperationModes: supportedValues.supportedOperationModes,
            // supportedFanModes: supportedValues.supportedFanModes,
            // supportedSwingModes: supportedValues.supportedSwingModes,
            eWrcTemperatureLimits: supportedValues.temperatureLimits,
            eWrcEnableOnoff: tStatFlags.enableOnoff || false,
            eWrcEnableMode: tStatFlags.enableMode || false,
            eWrcEnableSetpoint: tStatFlags.enableSetpoint || false,
            // eWrcEnableOnState: tStatFlags.enableOnState || false,
            eWrcEnableCoolMode: tempLimitFlags.enableCoolMode || false,
            eWrcEnableHeatMode: tempLimitFlags.enableHeatMode || false
            // eWrcEnableAutoMode: tempLimitFlags.enableAutoMode || false
        };

        if (!unitId) {
            data.sourceUnit = selections.unitId;
            data.eWrcDisable = isEWrcDisabled;
            updateUnitsAndSchedules({ updatedData: data });
            for (let i = 0; i < unitsToSave.length; i++) {
                updateStoreUnit({
                    id: unitsToSave[i],
                    data
                });
            }

            return;
        }

        setEWrcLock({ id: unitId, data });

        updateStoreUnit({
            id: unitId,
            data
        });
    };

    const handleCancel = () => {
        const values: any = {};
        const unitOrigin = _.find(indoorUnits, (unit) => unit.id === selections.unitId);
        const unit = _.cloneDeep(unitOrigin);
        if (unit) {
            values.temperatureLimits = unit.eWrcTemperatureLimits ? unit.eWrcTemperatureLimits : unit.temperatureLimits;
        }

        setTStatFlags({
            enableOnoff: unit.eWrcEnableOnoff,
            enableMode: unit.eWrcEnableMode,
            enableSetpoint: unit.eWrcEnableSetpoint
            // enableOnState: unit.eWrcEnableOnState
        });

        setTempLimitFlags({
            enableCoolMode: unit.eWrcEnableCoolMode || false,
            enableHeatMode: unit.eWrcEnableHeatMode || false,
            enableAutoMode: unit.eWrcEnableAutoMode || false
        });
        setSupportedValues(values);
    };

    const removeUnit = (unit: IUnit) => {
        const updatedUnits: any = [...unitsToSave];
        if (_.includes(unitsToSave, unit.id)) {
            const checkIndex = updatedUnits.indexOf(unit.id);
            if (checkIndex > -1) {
                updatedUnits.splice(checkIndex, 1);
            }
        }
        setUnitsToSave(updatedUnits);
    };

    const noUnitSelect = () => {
        return <div />;
    };
    const sideMenu = () => {
        return <NewApplySettings
            save={handleAgree}
            units={unitsToSave}
            noPopup={true}
            oneCustomer={{ id: selections.customerId }}
            oneSite={selections.siteId}
            allTenants={[]}
            tenantId="9999"
            noDisable={true}
            useControlUnits={true}
            disabled={disableAllSettings}
            useServiceUnits={false}
        />;
    };

    const convertToWrcParams = (data: any) => {
        data.eWrcTemperatureLimits = data.temperatureLimits;
        data.eWrcEnableOnoff = data.enableOnoff;
        data.eWrcEnableMode = data.enableMode;
        data.eWrcEnableSetpoint = data.enableSetpoint;
        // data.eWrcEnableOnState = data.enableOnState;
        data.eWrcEnableCoolMode = data.enableCoolMode;
        data.eWrcEnableHeatMode = data.enableHeatMode;
        // data.eWrcEnableAutoMode = data.enableAutoMode;

        delete data.temperatureLimits;
        delete data.enableOnoff;
        delete data.enableMode;
        delete data.enableSetpoint;
        // delete data.enableOnState;
        delete data.enableCoolMode;
        delete data.enableHeatMode;
        delete data.enableAutoMode;
    };

    const saveSchedule = async (data: any) => {
        data.type = scheduletypes.eDeviceLimits;
        convertToWrcParams(data);
        await createScheduleAPI({
            data,
            objId: selectedUnit ? selectedUnit.id : "",
            objectType: "unit"
        });
        setSelectedSchedule(null);
        getSchedules();
    };

    const onUpdateSchedule = async (data: any) => {

        data.type = scheduletypes.eDeviceLimits;
        convertToWrcParams(data);
        await updateSchedule({ id: selectedSchedule.id, data });
        setSelectedSchedule(null);
        getSchedules();
    };

    const onUpdateDisableState = async (data: any) => {

        data.type = scheduletypes.unitLimits;
        await updateSchedule({ id: data.id, data: { isDisabled: data.isDisabled } });
        // setSelectedSchedule(null)
        // getSchedules();
    };

    const onDelete = async (idObj: any) => {
        await deleteSchedule(idObj.id);
        getSchedules();
    };

    const onToggleAllLocks = () => {
        toggleEWrcLock({
            units: [selections.unitId],
            isEWrcDisable: !isEWrcDisabled
        });

        setIsEWrcDisabled(!isEWrcDisabled);
    };

    const schedules = () => {
        const unitSchedules = _.reduce(allCustomerSchedules, (arr: any[], item: any) => {
            if (item.unit === selections.unitId) {
                arr.push(item);
            }

            return arr;
        }, []);

        return (
            <Grid item xs={12} className={classes.scheduleWrapper}>

                <Dialog
                    onClose={_.noop}
                    aria-labelledby="simple-dialog-title"
                    open={createNewSchedule}
                    maxWidth="lg"
                    fullWidth
                // classes={{ paperWidthLg: clsx(classes.dialogStyle, { [classes.systemsDialogStyle]: props.onStep === 2 }) }}
                >
                    <ScheduleIUnitItem
                        selectedSchedule={selectedSchedule}
                        onSave={selectedSchedule ? onUpdateSchedule : saveSchedule}
                        onCancle={() => {
                            setCreateNewSchedule(!createNewSchedule);
                            setSelectedSchedule(null);
                        }}
                        canEdit={true}
                        idDeviceLimit={true}
                        wrcLimits={true}
                        title={!selectedSchedule ? "Add Schedule " : "Edit Schedule"}
                        useTemperatureScale={useTemperatureScale}
                    />
                </Dialog>

                <Card
                    className={clsx(classes.modeContent, classes.modeContentUnitSettings, classes.scheduleContainer)}
                >
                    <Grid item xs={12} className={classes.newScheduleBtn} >
                        <Typography className={classes.topTitle}>Schedules</Typography>
                        <Button
                            disableRipple
                            disableElevation
                            disabled={disableAllSettings || isEWrcDisabled}
                            onClick={() => setCreateNewSchedule(!createNewSchedule)}
                            variant="contained"
                            className={classes.cancelActionBtn}
                            startIcon={<Plus className={classes.plusIcon} />}
                        >{t`Add Schedule`}

                        </Button>
                    </Grid>

                    <Grid item xs={12}  >
                        {_.map(unitSchedules, (schedule) => {

                            return (
                                <ScheduleIUnitItem
                                    selectedSchedule={schedule}
                                    onSave={updateSchedule}
                                    onCancle={() => setCreateNewSchedule(!createNewSchedule)}
                                    canEdit={false}
                                    key={schedule.id}
                                    onDelete={onDelete}
                                    onEditAction={() => {
                                        setCreateNewSchedule(!createNewSchedule);
                                        setSelectedSchedule(schedule);
                                    }}
                                    onDisableUpdate={onUpdateDisableState}
                                    idDeviceLimit={true}
                                    wrcLimits={true}
                                    useTemperatureScale={useTemperatureScale}
                                    disableAllSettings={disableAllSettings}
                                />
                            );
                        })}
                    </Grid>
                </Card>
            </Grid>
        );
    };

    const disabledView = () => {
        return (
            <div
                className={classes.disabledView}
            >
                <Typography className={classes.title}>
                    {t`Restrictions Are Disabled`}</Typography>
            </div>

        );

    };

    const unitWrcLocks = () => {
        return (<Card className={classes.tStatContainer}>
            <SwitchList
                disabled={disableAllSettings}
                unit={selectedUnit} tStatFlags={tStatFlags} setTStatFlags={setTStatFlags} />
        </Card>
        );
    };
    const unitWrcTempLimits = () => {
        return (
            <div
                className={classes.modesContainer}
            >
                <Card
                    className={clsx(classes.modeContainer, classes.wrcModeContainer)}
                >
                    <div className={classes.modeHeader}>
                        <div className={classes.modeTitle}>
                            <img className={classes.modeImg}
                                src={assetsService.getImagesPath(`${modeIcons[0]}.svg`)}
                            ></img>
                            <Typography className={classes.title}>
                                {t`WRC Limit cooling setpoint range`}</Typography>
                        </div>
                        <div className={classes.modeSlider}>
                            <Switch
                                disabled={disableAllSettings}
                                checked={tempLimitFlags.enableCoolMode}
                                switchChange={() =>
                                    handleSetTempLimitRange("enableCoolMode")
                                }
                            />
                        </div>
                    </div>
                    {tempLimitFlags.enableCoolMode &&
                        <Slider
                            onChange={handleSetCooling}
                            className={classes.coolSlider}
                            // defaultValue={supportedValues.temperatureLimits[0][temperatureScale]}
                            aria-labelledby="discrete-slider-always"
                            min={useTemperatureScale === 1 ? 0 : 32}
                            max={useTemperatureScale === 1 ? 50 : 122}
                            value={supportedValues.temperatureLimits[0]}
                            valueLabelDisplay="on"
                            valueLabelFormat={valueLabelFormat}
                            // ValueLabelComponent={ValueLabelComponent}
                            disabled={disableAllSettings || !tempLimitFlags.enableCoolMode}
                        />
                    }

                </Card>
                <Card
                    className={clsx(classes.modeContainer, classes.wrcModeContainer)}
                >
                    <div className={classes.modeHeader}>
                        <div className={classes.modeTitle}>
                            <img
                                src={assetsService.getImagesPath(`${modeIcons[1]}.svg`)}
                                className={classes.modeImg}
                            >
                            </img>
                            <Typography className={classes.title}>
                                {t`WRC Limit heating setpoint range`}</Typography>
                        </div>
                        <div className={classes.modeSlider}>
                            <Switch
                                disabled={disableAllSettings}
                                checked={tempLimitFlags.enableHeatMode}
                                switchChange={() =>
                                    handleSetTempLimitRange("enableHeatMode")
                                }
                            />
                        </div>
                    </div>
                    {tempLimitFlags.enableHeatMode &&
                        <Slider
                            onChange={handleSetHeat}
                            className={classes.heatSlider}
                            // defaultValue={supportedValues.temperatureLimits[1][temperatureScale]}
                            aria-labelledby="discrete-slider-always"
                            min={useTemperatureScale === 1 ? 0 : 32}
                            max={useTemperatureScale === 1 ? 50 : 122}
                            value={supportedValues.temperatureLimits[1]}
                            valueLabelDisplay="on"
                            valueLabelFormat={valueLabelFormat}
                            // ValueLabelComponent={ValueLabelComponent}
                            disabled={disableAllSettings || !tempLimitFlags.enableHeatMode}
                        />
                    }
                </Card>

            </div>
        );
    };

    const unitSelected = () => {
        const canSee = true;

        return (
            <Grid
                container
                className={classes.container}
                spacing={3}
            >
                <Grid item xs={9} className={classes.gridLeft} >

                    <Card
                        className={clsx(classes.modeContent, classes.modeContentUnitSettings)}
                    >
                        {supportedValues.temperatureLimits && selectedUnit &&
                            <>

                                <div className={clsx(classes.settingsHeaderContainer)}>
                                    <div className={classes.settingsHeaderTitles} >
                                        <Typography className={classes.topTitle}>{selectedUnit && selectedUnit.name}</Typography>
                                        <Typography className={classes.errorTitle}>{disableAllSettings ? helperMessgae : ""}</Typography>
                                    </div>

                                    <Card className={classes.eWrcDisableContainer}>
                                        <Switch
                                            checked={!isEWrcDisabled}
                                            disableRipple={true}
                                            switchChange={() => {
                                                onToggleAllLocks();
                                            }}
                                            disabled={disableAllSettings}
                                        />
                                        <Typography className={classes.switchTitle}>{t`Enable Restrictions`}</Typography>
                                    </Card>
                                </div>

                                {isEWrcDisabled && disabledView()}
                                {!isEWrcDisabled && unitWrcLocks()}
                                {!isEWrcDisabled && unitWrcTempLimits()}

                            </>
                        }

                        {!isEWrcDisabled &&
                            <CardActions className={classes.unitActions}>
                                <div className={clsx(classes.saveBtn, classes.saveLocksBtn)}>
                                    <Button
                                        // disableRipple
                                        disableElevation
                                        disabled={disableAllSettings}
                                        onClick={() => handleSave(selections.unitId)}
                                        variant="contained"
                                        className={clsx(classes.saveActionBtn,
                                            { [classes.disabledSaveBtn]: disableAllSettings })}
                                    >{t`SAVE`}
                                    </Button>
                                </div>
                                <div className={classes.saveBtn}>
                                    <Button
                                        // disableRipple
                                        disableElevation
                                        disabled={disableAllSettings} onClick={() => handleCancel()} variant="contained"
                                        className={classes.cancelActionBtn}>{t`CANCEL`}
                                    </Button>
                                </div>
                            </CardActions>
                        }
                    </Card>
                    {canSee && !isEWrcDisabled && supportedValues.temperatureLimits && selectedUnit && schedulesMemo()}
                </Grid>
                {canSee &&
                    <Grid item xs={3}>
                        <Card
                            className={classes.sideMenuContent}
                        >
                            <div className={classes.headerApplyContainer}>
                                <Typography variant="h6" className={classes.headerTitle}>
                                    {t`Copy Settings To`}
                                </Typography>
                            </div>
                            <Grid className={classes.unitsContainer}>
                                {memoSideMenu()}
                            </Grid>
                            <div className={classes.saveBtn}>
                                <Button
                                    disableRipple
                                    disableElevation
                                    onClick={() =>
                                        setIsApply(true)}
                                    disabled={disableAllSettings || !unitsToSave.length}
                                    variant="contained"
                                    color="primary"
                                    className={classes.applyBtn}
                                >{t`Apply`}
                                </Button>
                            </div>
                        </Card>
                    </Grid>
                }
            </Grid >
        );
    };

    const getOnApplyConfirmation = () => {
        return (<Dialog
            open={isApply}
            onClose={() => setIsApply(false)}
            aria-labelledby="delete-alert-dialog-title"
            aria-describedby="delete-alert-dialog-description"
            classes={{ paper: classes.confirmationDialog }}
        >
            <div
                className={classes.dialogHeader}
            >
                <Typography className={classes.headerTitle}>{t`Apply all setting to destination units`}</Typography>
                <IconButton onClick={() => setIsApply(false)} className={classes.iconBtnStyle}>
                    <Close color="#7f7692" />
                </IconButton>
            </div>
            <div
                className={classes.dialogContent}
            >
                <Typography
                    className={classes.confirmationContentText}>
                    {t`Are you sure? This operation will delete schedules in the destination units`}
                </Typography>
            </div>
            <div
                className={classes.actionsHolder}
            >
                <StyledButton
                    autoFocus
                    onClick={() => setIsApply(false)}
                    width={140}
                    uppercase
                    white
                    marginRight
                >
                    {t`Cancel`}
                </StyledButton>
                <StyledButton
                    onClick={() => {
                        setIsApply(false);
                        handleSave();
                    }}
                    width={140}
                    uppercase
                >
                    {t`Ok`}
                </StyledButton>

            </div>
        </Dialog>);
    };

    return (
        <div className={classes.view}>
            {getOnApplyConfirmation()}
            <ServiceNavigationBar {...props} />
            <div className={classes.contentArea}>
                <Header
                    hideSystemSelection={true} disableSystemUpdate={true}
                    path={["Restrict Local Controls"]}
                    ignoreSystemFilterForUnits={true}

                />
                {selections.unitId && unitSelected()}
                {!selections.unitId && noUnitSelect()}
            </div>
        </div >
    );
};

export default Settings;
