import {
  makeStyles,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import CircularSlider from "react-circular-slider-svg";
import { t } from "ttag";
import symbols from "../../../../constants/sensorsUnitsSymbols";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import style from "../UnitPanel/UnitPanel.style";

const hasValue = (value: any) => {
  return !!value || value === 0;
};

const SensorValue: React.FC<any> = ({ id, value, unit, enableRatio, valueMax, valueMin, userMax, userMin,
  enableNormal, userNormal, isCelsius, isWritable, setLoading, type }) => {
  const { updateSensorValue, _storeUpdateSensor } = useStoreActions((actions) => actions.sensors);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const [saveValue, setSaveValue] = useState<number>(+value || 0);
  const useStyles = makeStyles(style);
  const classes = useStyles();

  const delayedCall = useCallback(_.debounce((newValue: any) => {
    setLoading(true);
    updateSensorValue({ id, data: { value: newValue } })
      .then(() => {
        _storeUpdateSensor({ id, data: { readingValue: newValue } });
      })
      .catch((err: any) => {
        setSaveValue(value);
        addMessage({ message: err.message });
      }).finally(() => setLoading(false));
  }, 1000), []);

  const updateValue = (newValue: any) => {
    setSaveValue(newValue);
    delayedCall(newValue);

  };

  if (type === 130 && isWritable) { //analog type check later
    const hasUserValues = hasValue(userMax) && hasValue(userMin);
    const doConvert = enableRatio && hasUserValues;

    return <div className={classes.sensorSliderContainer}>
      <CircularSlider
        size={275}
        minValue={valueMin}
        maxValue={valueMax}
        startAngle={0}
        endAngle={180}
        coerceToInt={true}
        angleType={{ direction: "cw", axis: "-x" }}
        handle1={{ value: saveValue, onChange: updateValue }}
        arcColor={"#FFF"}
        arcBackgroundColor={"#FFF"}
      />
      <div className={classes.sensorValuesContainer}>
        <Typography className={classes.sensorValues}>{hasUserValues ? userMin : valueMin}</Typography>
        <Typography className={classes.sensorValues}>{hasUserValues ? userMax : valueMax}</Typography>
      </div>
      <div className={classes.valueControl}>
        <Typography className={classes.sensorValue}>{doConvert ? ((((saveValue - valueMin) * (+userMax - +userMin)) / (valueMax - valueMin)) + +userMin).toFixed(1) : saveValue.toFixed(1)}</Typography>
        <Typography className={classes.measurementUnit}>{symbols[unit || ""] || ""}</Typography>
      </div>

    </div>;
  }

  if (type === 7) {
    let convertedValue = (enableRatio ?
      hasValue(userMax) && hasValue(userMin) ? (((value - valueMin) * (+userMax - +userMin)) / (valueMax - valueMin)) + +userMin
        : value : value).toFixed(1);

    return <div className={classes.valueContainer}>
      <Typography className={clsx(classes.sensorValueStyle)}>{convertedValue}</Typography>
      <Typography className={clsx(classes.measurementUnitsStyle, { [classes.percentStyle]: unit === "Percent" })}>{symbols[unit || ""] || ""}</Typography>
    </div>;
  }

  let Item = null;
  switch (unit) {
    case "Temperature":
      Item = <div className={classes.valueContainer}>
        <Typography className={classes.sensorValueStyle}>{isCelsius ? value : (Math.round(((value * 9 / 5) + 32) * 10) / 10)}</Typography>
        <Typography className={clsx(classes.measurementUnitsStyle, classes.tempUnit)}>{symbols[unit]}</Typography>
      </div>;
      break;
    case "Open/Close":
      Item = <div className={classes.valueContainerColumn}>
        <Typography className={clsx(classes.sensorValueStyle, { [classes.offText]: type === 129 && +value === 0 })}>
          {type === 129 ? +value === 0 ? t`OFF` : t`ON` : +value === 0 ? t`OPEN` : t`CLOSE`}
        </Typography>
        {(enableNormal && hasValue(userNormal)) && <Typography className={classes.setPointDiscription}>{userNormal === value ? t`(Normal)` : ""}</Typography>}
      </div>;
      break;
    case undefined:
      let convertedValue = enableRatio ?
        hasValue(userMax) && hasValue(userMin) ? (((value - valueMin) * (+userMax - +userMin)) / (valueMax - valueMin)) + +userMin
          : value : value;
      Item = <Typography className={clsx(classes.sensorValueStyle)}>{convertedValue}</Typography>;
      break;

    default:
      Item = <div className={classes.valueContainer}>
        <Typography className={clsx(classes.sensorValueStyle)}>{value}</Typography>
        <Typography className={classes.measurementUnitsStyle}>{symbols[unit]}</Typography>
      </div>;
      break;
  }

  return Item;
};

export default SensorValue;
