import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import SvgAlert from "../../icons/Alert";
import SvgDisconnect from "../../icons/Disconnect";
import SvgRefresh from "../../icons/Refresh";
import { IDevice } from "../../models/Devices";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import styles from "./DeviceCard.module.css";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    deviceInfo: {
      padding: "1rem 1rem 0",
      display: "flex",
      flexDirection: "column",
      borderRadius: 4,
      maxWidth: "22%",
      minWidth: "22.7rem",
      paddingLeft: 20
    },
    deviceInfoRow: {
      borderBottom: "1px solid rgb(213, 210, 213)",
      padding: "5px 0",
      justifyContent: "space-between"
    },
    deviceSubTitle: {
      fontFamily: "RobotoMedium",
      fontSize: "0.875rem",
      color: "rgb(84, 89, 100)",
      marginRight: ".5rem"
    },
    deviceSubTitleNotAssigned: {
      "fontFamily": "RobotoMedium",
      "fontSize": "0.875rem",
      "width": "70%",
      "display": "flex",
      "color": "rgb(240, 81, 70)",
      "& span": {
        marginLeft: ".5rem"
      }
    },
    serialStyle: {
      fontWeight: "bold",
      color: "#29132e",
      fontFamily: "RobotoMedium",
      fontSize: "0.875rem",
      width: "50%",
      margin: "auto"
    },
    deleteSite: {
      color: "#bdb9b98a"
    },
    iconButtonStyleDeviceBox: {
      paddingRight: 0,
      padding: 5
    },
    alertIcon: {
      color: "rgb(240, 81, 70)",
      paddingRight: 5
    },
    errorText: {
      color: "rgb(240, 81, 70)"
    },
    cmntTitle: {
      display: "flex",
      alignItems: "center",
      minWidth: "50%"
    },
    cmntIcons: {
      display: "grid",
      gridAutoFlow: "column",
      gridGap: 8
    },
    cmnetContainer: {
      alignItems: "center",
      justifyContent: "space-between"
    },
    refreshIcon: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    },
    dialogTitle: {
      color: "#29132e",
      backgroundColor: "#f6f6f7",
      borderBottom: "1px solid #d5d2d5",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    clearButton: {
      width: "40px",
      height: "40px"
    },
    powerMeterBtns: {
      display: "flex",
      flexDirection: "row-reverse"
    },
    powerMeterBtn: {
      width: "5rem",
      marginLeft: "12px"
    },
    // powerMeter-System table
    tableContainer: {},
    tableHeadCell: {
      borderBottom: "none"
    },
    headCellName: {
      backgroundColor: "whitesmoke",
      width: "7rem"
    },
    headCellSystem: {
      backgroundColor: "whitesmoke",
      width: "6rem"
    },
    textFieldValue: {
      color: "#aaa2aa"
    },
    formControl: {
      width: "8rem"
    }

  })
);

const DeviceCard: React.FC<{ device: IDevice, allPowerMeters: any }> = ({ device, allPowerMeters }) => {
  const getDeviceUnits = useStoreState((state) => state.devices.getDeviceUnits);
  const deleteDevice = useStoreActions((action) => action.deleteDevice);
  const refreshDevice = useStoreActions((action) => action.refreshDevice);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const classes = useStyles();

  const numUnassigedUnits = getDeviceUnits(device.id, "unassigned", "all")
    .length;
  const numOutdoorUnits = getDeviceUnits(device.id, "all", "outdoor").length;
  const numIndoorUnits = getDeviceUnits(device.id, "all", "indoor").length;
  const numDisconnectedIndoorUnits = getDeviceUnits(
    device.id,
    "disconnected",
    "indoor"
  ).length;
  const numDisconnectedOutdoorUnits = getDeviceUnits(
    device.id,
    "disconnected",
    "outdoor"
  ).length;

  const fetchData = async () => {
    setIsLoading(true);

    try {
      await refreshDevice({ id: device.id });
    } catch (err) {
      // console.log('fetch err', err);
    }
  };

  const ppd = Object.values(allPowerMeters).filter(
    (pm: any) => pm.device === device.id
  );

  return (
    <Grid item={true} xs={3} className={classes.deviceInfo}>
      <Grid container={true} direction="column" className={classes.deviceInfoRow}>
        <Grid container={true} wrap={"nowrap"} className={classes.cmnetContainer}>
          <div className={classes.cmntTitle}>
            <Typography
              className={classes.deviceSubTitle}
              style={{ color: "#545964 !important" }}
            >{t`CoolMasterNet `}</Typography>
            <Typography className={classes.serialStyle}>
              {device.serial}
            </Typography>
          </div>
          <div className={classes.cmntIcons}>
            {!device.isConnected ? (
              <SvgDisconnect style={{ fontSize: "1.8rem" }} />
            ) : (
                <></>
              )}

            <IconButton
              disableRipple
              aria-label="refresh icon"
              onClick={fetchData}
              className={classes.refreshIcon}
            >
              <SvgRefresh
                className={
                  isLoading ? styles.refreshStyleLoading : styles.refreshStyle
                }
              />
            </IconButton>
            <Delete
              type={t`Device`}
              object={device}
              detach={deleteDevice}
              className={classes.deleteSite}
              buttonClass={classes.iconButtonStyleDeviceBox}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container={true} alignItems="center" className={classes.deviceInfoRow}>
        <Typography
          className={classes.deviceSubTitle}
        >{t`Outdoor Units:`}</Typography>
        <Typography>
          {numOutdoorUnits}
          {numDisconnectedOutdoorUnits !== 0 && (
            <span className={classes.errorText}>
              {" "}
              ({numDisconnectedOutdoorUnits} disconnected)
            </span>
          )}
        </Typography>
      </Grid>
      <Grid container={true} alignItems="center" className={classes.deviceInfoRow}>
        <Typography
          className={classes.deviceSubTitle}
        >{t`Indoor Units:`}</Typography>
        <Typography>
          {numIndoorUnits}
          {numDisconnectedIndoorUnits !== 0 && (
            <span className={classes.errorText}>
              {" "}
              ({numDisconnectedIndoorUnits} disconnected)
            </span>
          )}
        </Typography>
      </Grid>
      {
        <Grid
          container={true}
          alignItems="center"
          className={classes.deviceInfoRow}
          style={{ opacity: numUnassigedUnits === 0 ? 0 : 1 }}
        >
          <Typography className={classes.deviceSubTitleNotAssigned}>
            <SvgAlert />
            <span>{t`Units Not Assigned:`}</span>
          </Typography>
          <Typography>{numUnassigedUnits}</Typography>
        </Grid>
      }
    </Grid>
  );
};

export default DeviceCard;
