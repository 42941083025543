import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  row: {
    borderBottom: "1px solid lightgrey",
    display: "flex",
    alignItems: "center",
    height: 38
  },
  rowInfo: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "calc(100% - 116px)"
  },
  rowTitle: {
    fontFamily: "RobotoMedium",
    fontSize: "0.875rem",
    color: "rgb(84, 89, 100)",
    width: "5.8rem",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  infoContainer: {
    height: "100%",
    padding: "1rem",
    width: "auto",
    overflow: "auto",
    flexFlow: "column nowrap"
  },
  detailsContainer: {
    marginTop: "2rem",
    marginBottom: "1rem"
  },
  description: {
    backgroundColor: "#f4f4f4",
    marginTop: "2rem",
    height: "75px",
    color: "#545964",
    fontFamily: "Roboto",
    overflow: "auto",
    padding: "0 7px"
  },
  descriptionTitle: {
    fontWeight: 500,
    margin: "3px 0"
  },
  resolveDescription: {
    marginLeft: "1rem",
    marginTop: "1rem",
    fontFamily: "RobotoMedium",
    fontSize: "0.875rem",
    color: "rgb(84, 89, 100)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  resolvedInfo: {
    border: "1px solid grey",
    borderRadius: "3px",
    marginBottom: "5px",
    width: "100%",
    padding: "5px"
  },
  saveBtn: { alignSelf: "flex-end", width: "40%", marginTop: "5px" },
  resolveTitle: {
    fontWeight: 500,
    alignSelf: "flex-start"
  },
  btn: {
    marginLeft: "1rem",
    marginTop: "2rem"
  },
  dialogTitle: {
    color: "#29132e",
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  clearButton: {
    width: "40px",
    height: "40px"
  },
  alertHeader: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center"
  },
  emailDialogStyle: {
    padding: 0,
    minWidth: "400px",
    width: "400px",
    minHeight: "300px",
    height: "auto",
    display: "flex",
    flexFlow: "column nowrap",
    maxHeight: "400px"
  },
  emailDialogActions: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "30px"
  },
  emailDialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: "20px",
    maxHeight: "300px",
    overflow: "auto",
    minHeight: "235px"
  },
  cancelBtn: {
    color: "#4b1c46",
    backgroundColor: "#ffffff",
    width: "100px",
    marginRight: "20px"
  },
  sendBtn: {
    width: "100px"
  },
  emailPart: {
    display: "flex",
    width: "100%",
    flexFlow: "row nowrap",
    justifyContent: "space-between !important",
    alignItems: "center"
  },
  deleteTitleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #d5d2d5",
    padding: "16px 9px 16px 24px",
    color: "#29132e"
  },
  deleteDialog: {
    borderRadius: "0.5rem",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: " 1px solid #d5d2d5"
  },

  deleteDialogContent: {
    padding: 20,
    paddingTop: 30
  },
  deleteContentText: {
    fontFamily: "Roboto",
    color: "#545964"
  },
  deleteNoBtn: {
    width: "9rem",
    height: "2.625rem",
    border: "none"
  },
  deleteYesBtn: {
    color: "#4b1c46",
    width: "9rem",
    height: "2.625rem",
    border: "1px solid #421a40"
  },
  deleteDialogActions: {
    display: "flex",
    justifyContent: "center",
    padding: 20
  },
  title: {
    backgroundColor: "#f6f6f7",
    color: "#4b1c46",
    borderBottom: "1px solid #d5d2d5",
    padding: "4px 24px"
  },
  titleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  alertInfoDialogPaper: {
    width: "540px"
  },
  cancelFilter: { marginRight: "22px" },
  ackButtonContainer: {
    marginTop: "20px",
    marginBottom: "40px"
  },
  ackButton: {
    height: "40px !important",
    borderRadius: "6px !important",
    border: "solid 1px #413044 !important"
  },
  ackButtonLable: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center"
  },
  ackButtonText: {
    fontSize: "16px",
    color: "#545964",
    textTransform: "capitalize"
  },
  ackButtonIcon: {
    fontSize: "19px",
    marginRight: "7px",
    color: "#00b259"
  },
  dialogHeader: {
   paddingLeft: 20,
   paddingRight: 13,
   backgroundColor: "#f6f6f7",
   borderBottom: "1px solid #d5d2d5",
   display: "flex",
   justifyContent: "space-between",
   alignItems: "center",
   height: 60,
   minHeight: 60
 },
  headerTitle: {
 fontFamily: "RobotoMedium",
 color: "#29132e",
 lineHeight: "normal",
 fontSize: 18,
 fontWeight: 500,
 letterSpacing: "normal"

 },
  dialogContent: {
   flex: "1 1 auto",
   padding: 20,
   overflowY: "auto",
   paddingBottom: 0
 },
  iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    },
  actionsHolder: {
      padding: 20,
      display: "flex",
      justifyContent: "flex-end"
    }
});
export default useStyles;
