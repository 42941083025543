import { Device as DeviceSdk } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  memo,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { object } from "yup";
import { IPowerMeter } from "./powerMeters";
import { IRootStoreModel } from "./RootStore";
import { ISite } from "./Sites";
import { ISystem } from "./Systems";
import { IUnit, IUnitType } from "./Units";

export interface IDevice {
  id: string;
  serial: string;
  pin?: string;
  name: string;
  isConnected: boolean;
  site: string;
  units: string[];
  systems: string[];
  role: any;
  powerMeter?: string[];
  protocolVersion?: any;
}

export interface IDeviceMap {
  [key: string]: IDevice;
}

export interface IDevicesModel {
  allDevices: IDeviceMap;
  initialize: Action<IDevicesModel, any>;
  onInitialized: ActionOn<IDevicesModel, IRootStoreModel>;
  getDeviceName: Computed<
    IDevicesModel,
    (id: string | undefined) => string | undefined
  >;
  getDeviceNameBySerial: Computed<
    IDevicesModel,
    (serial: string | undefined) => string | undefined
  >;
  getLineQuality: Thunk<
    IDevicesModel,
    { deviceId: string, lineId: string, startTime: number, endTime: number }
  >;
  getDeviceLines: Thunk<IDevicesModel, string>;

  // TODO: Why computed?
  getDeviceDaikinIndoorMapping: Computed<
    IDevicesModel,
    (deviceId: string, lineId: string) => any | undefined
  >;
  getDeviceDaikinOutdoorMapping: Computed<
    IDevicesModel,
    (deviceId: string, lineId: string) => any | undefined
  >;
  mapLineIndoors: Thunk<
    IDevicesModel,
    { deviceId: string, lineId: string }
  >;
  getDeviceById: Computed<IDevicesModel, (id: string) => IDevice | null>;
  getDeviceSystems: Computed<
    IDevicesModel,
    (deviceId: string) => ISystem[],
    IRootStoreModel
  >;
  getDeviceSite: Computed<
    IDevicesModel,
    (deviceId: string) => ISite[],
    IRootStoreModel
  >;
  getDevicePowerMeters: Computed<
    IDevicesModel,
    (deviceId: string) => IPowerMeter[],
    IRootStoreModel
  >;
  getDeviceUnits: Computed<
    IDevicesModel,
    (
      deviceId: string,
      systemId: "all" | "unassigned" | "disconnected" | string,
      type: IUnitType
    ) => IUnit[],
    IRootStoreModel
  >;
  getDeviceBySerial: Computed<
    IDevicesModel,
    (serial: string) => IDevice | null
  >;
  getDevicesBySite: Computed<IDevicesModel, (siteId: string) => IDevice[]>;
  refreshSystems: Thunk<IDevicesModel, { deviceId: string }>;
  _storeUpdateDeviceConnection: Action<
    IDevicesModel,
    { deviceId: string; data: any }
  >;
  getEWrcLocks: Thunk<
    IDevicesModel,
    { deviceId: string }
  >;
}

export const devicesModel: IDevicesModel = {
  allDevices: {},

  initialize: action((state, payload) => {
    state.allDevices = payload;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      // console.log(target.resolvedTargets);
      // console.log("Got devices: ", debug(state.allDevices));
    }
  ),
  _storeUpdateDeviceConnection: action((state, payload) => {
    if (state.allDevices[payload.deviceId]) {
      state.allDevices[payload.deviceId] = payload.data;
    }
  }),
  getDeviceDaikinIndoorMapping: computed(
    (state: any) => async (deviceId, lineId) => {
      const data = await DeviceSdk.getDeviceDaikinIndoorMapping(
        deviceId,
        lineId
      );

      return data;
    }
  ),
  getDeviceById: computed((state) => (id) => {
    const device = state.allDevices[id];

    return device ? device : null;
  }),
  getDeviceDaikinOutdoorMapping: computed(
    (state) => async (deviceId, lineId) => {
      const data = await DeviceSdk.getDeviceDaikinOutdoorMapping(
        deviceId,
        lineId
      );
      return data;
    }
  ),

  getDeviceNameBySerial: computed((state) => (serial) => {
    const noName = "-";

    if (!serial) {
      return noName;
    }
    const device = Object.values(state.allDevices).filter(
      (device) => device.serial === serial
    )[0];
    if (!device) {
      return noName;
    }

    return device.name ?? noName;
  }),
  getLineQuality: thunk(async (actions, payload) => {
    const params = { startTime: payload.startTime, endTime: payload.endTime };
    const data = await DeviceSdk.getLineQuality(payload.deviceId, payload.lineId, params);
    return data;
  }),
  getDeviceLines: thunk(async (actions, payload) => {
    const data = await DeviceSdk.getDeviceLines(payload);
    return data;
  }),
  getEWrcLocks: thunk(async (actions, payload) => {
    const data = await DeviceSdk.getEWrcLocks(payload.deviceId);
    return data;
  }),
  mapLineIndoors: thunk(async (actions, payload) => {
    await DeviceSdk.mapLineIndoors(payload.deviceId, payload.lineId);
  }),
  getDeviceName: computed((state) => (id) => {
    const noName = "-";

    if (!id) {
      return noName;
    }

    if (!state.allDevices[id]) {
      return noName;
    }

    return state.allDevices[id].name ? state.allDevices[id].name : noName;
  }),

  getDeviceSystems: computed(
    [(state, storeState) => storeState.systems.allSystems],
    (allSystems) =>
      memo((deviceId) => {
        return Object.values(allSystems).filter(
          (system) => system.device === deviceId
        );
      }, 100)
  ),

  getDeviceSite: computed(
    [(state, storeState) => storeState.sites.allSites],
    (allSites) =>
      memo((deviceId) => {
        return Object.values(allSites).filter(
          (site) => {
            const foundSite = _.find(site.devices, (oneDeviceId) => {
              return oneDeviceId === deviceId;
            });
            return foundSite;

          }
        );
      }, 100)
  ),

  getDevicePowerMeters: computed(
    [(state, storeState) => storeState.powerMeters.allPowerMeters],
    (allPowerMeters) =>
      memo((deviceId) => {
        return Object.values(allPowerMeters).filter(
          (powerMeter) => powerMeter.device === deviceId
        );
      }, 100)
  ),

  // Returns an array of device's units
  // deviceId is required, optional filters can be applied:
  // 'systemId' and/or 'indoor/outdoor'
  getDeviceUnits: computed([(state, storeState) => storeState.units.allUnits], (allUnits) =>
    memo((deviceId, systemId = "all", type = "all") => {
      return Object.values(allUnits).filter(
        (unit) =>
          unit.device === deviceId &&
          (systemId === "all" ||
            (systemId === "unassigned" && _.isNil(unit.system)) ||
            (systemId === "disconnected" && (!_.has(unit, "isConnected") || !unit.isConnected)) ||
            unit.system === systemId) &&
          ((type === "all" && (unit.type === 1 || unit.type === 2 /*|| unit.type === 3*/)) ||
            (type === "indoor" && unit.type === 1) ||
            (type === "outdoor" && unit.type === 2) ||
            (type === "service" && unit.type === 3))
      );
    }, 100)
  ),

  getDeviceBySerial: computed((state) => (serial) => {
    const foundDevices = _.filter(
      _.values(state.allDevices),
      (device: IDevice) => device.serial === serial
    );

    if (foundDevices.length > 0) {
      if (foundDevices.length > 1) {
        // console.log(`More than one device with serial: [${serial}]`);
      }

      return foundDevices[0];
    }

    return null;
  }),

  getDevicesBySite: computed((state) => (siteId) => {
    const foundDevices = _.filter(
      _.values(state.allDevices),
      (device: IDevice) => device.site === siteId
    );

    return foundDevices;
  }),

  refreshSystems: thunk(async (actions, payload) => {
    await DeviceSdk.refreshSystems(payload.deviceId);
  })
};
