import { Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import {
    Customer as sdkCustomer
} from "coolremote-sdk";
import { Field, Formik } from "formik";
import _ from "lodash";
import { Select } from "material-ui-formik-components/Select";
import moment from "moment";
import { tz } from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import SvgArrow from "../../icons/Arrow";
import { EditIcon } from "../../logos";
import { useStoreState } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import { countries } from "./countryList";
import InputField from "./InputField";
import useStyles from "./Site.style";
import { stateList } from "./stateList";

interface ICountry {
    value: string;
    label: string;
}

export default function Site(props: any) {
    const [open, setOpen] = React.useState(false);
    const [error, setError] = useState(null);
    const { customerId } = useStoreState((state) => state.selections.selections);

    const allCustomers = useStoreState((state) => state.customers.allCustomers);
    const { site, save } = props;
    const classes = useStyles();

    const SiteSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        customer: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        address: Yup.string().required("Required"),
        postalCode: Yup.string().required("Required"),
        country: Yup.string().required("Required"),
        state: Yup.string(),
        timezone: Yup.string().required("Required")
    });

    const initialValues: any = {
        name: site ? site.name : "",
        customer: site ? site.customer : "",
        city: site ? site.city : "",
        address: site ? site.address : "",
        postalCode: site ? site.postalCode : "",
        country: site ? site.country : "",
        state: site ? site.state : "",
        timezone: site ? site.timezone : ""
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleAgree = (values: any, actions: any) => {
        save(values);
        setOpen(false);
    };
    const errorBox = error ? (
        <ErrorBox error={error} onClose={() => setError(null)} />
    ) : null;

    const countryOptions = countries.map((country: any) => ({
        value: country.code,
        label: country.name
    }));
    const timeZones = tz.names().map((timezone: any) => ({
        value: timezone,
        label: `${timezone}  ${tz(timezone).format("Z")}`
    }));
    const stateOptions = stateList.map((state: any) => ({
        value: state.value,
        label: state.label
    }));
    const customers = Object.values(allCustomers).map((customer: any) => ({
        value: customer.id,
        label: customer.name
    }));

    return (
        <div>
            {site ?
                <LightTooltip title="Edit Site">
                    <IconButton disableRipple onClick={handleClickOpen} className={classes.iconBtnStyle}>
                        <EditIcon className={classes.svgIcon} />
                    </IconButton>
                </LightTooltip> :
                <Button
                    color="primary"
                    onClick={handleClickOpen}
                >
                    {t`Add New Site`}
                </Button>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <div className={classes.dialogHeader}>
                    <Typography className={classes.headerTitle}>{site ? t`Edit site` : t`Add new site`}</Typography>
                    <IconButton disableRipple onClick={() => setOpen(false)} className={classes.iconBtnStyle}>
                        <Close color="#7f7692" />
                    </IconButton>
                </div>
                <div className={classes.dialogContent}>
                    {errorBox}
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={6}>
                            <div className={classes.imgContainer}>
                                <img
                                    src={assetsService.getImagesPath("cmnet.png")}
                                    className={classes.imgStyle}
                                ></img>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleAgree}
                                enableReinitialize={true}
                                validationSchema={SiteSchema}
                                validateOnChange={false}
                                validateOnBlur={false}
                            >
                                {({ handleSubmit, errors, touched, values, ...restFormikProps }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className={classes.inputFieldWithLabel}>
                                            <Typography>{t`Customer`}</Typography>
                                            <Field
                                                name="customer"
                                                options={customers}
                                                component={Select}
                                                variant="outlined"
                                                error={((errors.customer && touched.customer)) ? true : false}
                                                helperText={(errors.customer && touched.customer) ? errors.customer : ""}
                                                classes={{ icon: classes.arrowIcon }}
                                                IconComponent={SvgArrow}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    },
                                                    getContentAnchorEl: null
                                                }}
                                            />
                                        </div>
                                        <div className={classes.inputFieldWithLabel}>
                                            <Typography>{t`Name`}</Typography>
                                            <Field
                                                value={values.name}
                                                name="name"
                                                component={InputField}
                                                error={((errors.name && touched.name)) ? true : false}
                                                helperText={(errors.name && touched.name) ? errors.name : ""}
                                            />
                                        </div>
                                        <div className={classes.inputFieldWithLabel}>
                                            <Typography>{t`City`}</Typography>
                                            <Field
                                                value={values.city}
                                                name="city"
                                                component={InputField}
                                                error={((errors.city && touched.city)) ? true : false}
                                                helperText={(errors.city && touched.city) ? errors.city : ""}
                                            />
                                        </div>
                                        <div className={classes.inputFieldWithLabel}>
                                            <Typography>{t`Address`}</Typography>
                                            <Field
                                                value={values.address}
                                                name="address"
                                                component={InputField}
                                                error={((errors.address && touched.address)) ? true : false}
                                                helperText={(errors.address && touched.address) ? errors.address : ""}
                                            />
                                        </div>
                                        <div className={classes.inputFieldWithLabel}>
                                            <Typography>{t`Postal Code`}</Typography>
                                            <Field
                                                value={values.postalCode}
                                                name="postalCode"
                                                component={InputField}
                                                error={((errors.postalCode && touched.postalCode)) ? true : false}
                                                helperText={(errors.postalCode && touched.postalCode) ? errors.postalCode : ""}
                                            />
                                        </div>
                                        <div className={classes.inputFieldWithLabel}>
                                            <Typography>{t`Country`}</Typography>
                                            <Field
                                                // value={values.country}
                                                options={countryOptions}
                                                name="country"
                                                component={Select}
                                                variant="outlined"
                                                error={((errors.country && touched.country)) ? true : false}
                                                helperText={(errors.country && touched.country) ? errors.country : ""}
                                                classes={{ icon: classes.arrowIcon }}
                                                IconComponent={SvgArrow}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    },
                                                    getContentAnchorEl: null
                                                }}
                                            />
                                        </div>
                                        <div className={classes.inputFieldWithLabel}>
                                            <Typography>{t`State`}</Typography>
                                            <Field
                                                // value={values.state}
                                                options={stateOptions}
                                                name="state"
                                                component={Select}
                                                variant="outlined"
                                                error={((errors.state && touched.state)) ? true : false}
                                                helperText={(errors.state && touched.state) ? errors.state : ""}
                                                classes={{ icon: classes.arrowIcon }}
                                                IconComponent={SvgArrow}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    },
                                                    getContentAnchorEl: null
                                                }}
                                            />
                                        </div>
                                        <div className={classes.inputFieldWithLabel}>
                                            <Typography>{t`Time zone`}</Typography>
                                            <Field
                                                options={timeZones}
                                                name="timezone"
                                                component={Select}
                                                variant="outlined"
                                                placeholder=""
                                                error={((errors.timezone && touched.timezone)) ? true : false}
                                                helperText={(errors.timezone && touched.timezone) ? errors.timezone : ""}
                                                className={classes.select}
                                                classes={{ icon: classes.arrowIcon }}
                                                IconComponent={SvgArrow}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    },
                                                    getContentAnchorEl: null
                                                }}
                                            />
                                        </div>
                                        <div className={classes.actionsHolder}>
                                            <Button
                                                title="close edit"
                                                onClick={handleClose}
                                                uppercase
                                                white
                                                marginRight
                                                width={150}
                                            >
                                                {t`Cancel`}
                                            </Button>
                                            <Button
                                                title="save site"
                                                type="submit"
                                                width={150}
                                                uppercase
                                            >
                                                {t`Save`}
                                            </Button>
                                        </div>

                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        </div>
    );
}
