import React from "react";

const Svg30 = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" {...props}>
      <g fill="none" fill-rule="evenodd" stroke-linecap="round">
          <g fill="#35A8E0" fill-rule="nonzero" stroke="#FFF" stroke-width="2.02">
              <g>
                  <g>
                      <path d="M13 .5L0 .5M20 8.5L0 8.5M20 17.5L7 17.5" transform="translate(-372 -41) translate(372 41) translate(0 1)"/>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);

export default Svg30;
