import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme
} from "@material-ui/core";
import { FormikProps } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { useStoreState } from "../../../models/RootStore";
import UnitTableRow from "./UnitTableRow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    tableContainer: {},
    headCell: {
      backgroundColor: "whitesmoke"
      // width: '40px'
    },
    headCellName: {
      backgroundColor: "whitesmoke"
    },
    headCellDelete: {
      backgroundColor: "whitesmoke",
      width: "5ch !important"
    },
    unitName: {
      display: "flex",
      "flex-direction": "row"
    },
    inputText: {
      width: "100%"
    },
    disabledTrue: {
      color: "grey"
    },
    systemActionsContainer: {
      display: "flex",
      "align-items": "flex-start"
    },
    dropDown: {
      width: "10rem"
    },
    tableWrapper: {
      // height: "470px",
      overflow: "auto",
      height: "calc(100%  - -7px)"
    },
    headCellType: {
      backgroundColor: "whitesmoke",
      width: "3rem"
    },
    headCellSystem: {
      backgroundColor: "whitesmoke",
      width: "6rem"
    },
    tableHeadCell: {
      borderBottom: "none",
      // width: "0ch",
      padding: "0 15px",

      color: "#79797d",
      fontWeight: "bold",
      lineHeight: "normal",
      letterSpacing: "normal",
      backgroundColor: "#efeff2",
      textTransform: "uppercase",
      height: 45,
      fontSize: 13
    }
  })
);

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
interface IProps extends Omit<FormikProps<any>, "handleSubmit"> {
  touched: any;
  values: any;
  deviceId: string;
  isDaikin: boolean;
  systemLine: number | null | string;
  lineIndex: number;
  handleUnitDeletion: (lineIndex: number, unitIndex: any) => void;
  removeHeight?: number | string;
  deviceSystems?: any;
  deviceControlUnits?: any;
  import?: boolean | undefined;
}

const UnitsToSystemsMapTable: React.FC<IProps> = ({
  errors,
  touched,
  values,
  deviceId,
  isDaikin,
  // import,
  systemLine,
  setFieldValue,
  lineIndex,
  handleUnitDeletion,
  removeHeight = "",
  deviceSystems,
  deviceControlUnits,
  ...restFormikProps
}) => {
  const classes = useStyles();
  // const allSystems = useStoreState(state => state.systems.allSystems);

  const types = useStoreState((s) => s.types.allTypes);

  const [forceReRender, setForceReRender] = useState<boolean>(false);
  const isRemoved =
    _.filter(values.unitsChanges[lineIndex], (unit: any) => unit && !unit.isConnected).length > 0
      ? true
      : false;
  const { unitTypes } = types;

  useEffect(() => {
    if (forceReRender) {
      setForceReRender(false);
    }
  }, [forceReRender]);

  //
  //   const getDaikinMapping = async () => {
  //     if (!isDaikin) {
  //       return;
  //     }
  //     const mapping: any = {};
  //     const mappingOutdoor: any = {};
  //     if (systemLine && deviceId) {
  //       console.log("Im heere")
  //       const mappingAnswer = await getDeviceDaikinIndoorMapping({deviceId, lineId: systemLine.toString()});
  //       const outdoorMappingAnswer = await getDeviceDaikinOutdoorMapping(
  //       { deviceId: deviceId,
  //         lineId: systemLine.toString()}
  //       );
  //       console.log("alalalla", mappingAnswer, "outdoor", outdoorMappingAnswer)
  //
  //       if (mappingAnswer.length) {
  //         for (const mappingEntry of mappingAnswer) {
  //           mapping[mappingEntry.proId] = mappingEntry.airNet;
  //         }
  //         setQueryProIdToAirNetMap(false);
  //         setProIdToAirNetMap(mapping);
  //         console.log("mapping useeffect", mapping, proIdToAirNetMap)
  //       }
  //       if (outdoorMappingAnswer.length) {
  //         for (const mappingOutdoorEntry of outdoorMappingAnswer) {
  //           let temp1 = mappingOutdoorEntry.proId.substr(0, 2);
  //           temp1 = parseInt(String.fromCharCode(parseInt(temp1, 16))).toString();
  //           let temp2 = mappingOutdoorEntry.proId.substr(2, 2);
  //           temp2 = parseInt(String.fromCharCode(parseInt(temp2, 16))).toString();
  //           let temp3 = mappingOutdoorEntry.proId.substr(4, 2);
  //           temp3 = parseInt(String.fromCharCode(parseInt(temp3, 16))).toString();
  //           const temp = temp1 + temp2 + temp3;
  //           mappingOutdoor[temp] = mappingOutdoorEntry.airNet;
  //         }
  //         setQueryOutdoorProIdToAirNetMap(false);
  //         setProOutdoorIdToAirNetMap(mappingOutdoor);
  //         console.log("mapping useeffect", mappingOutdoor)
  //       }
  //     }
  //   };

  const handleUnitDeletionProxy = async (lineIndex: number, unitIndex: any) => {
    await handleUnitDeletion(lineIndex, unitIndex);

    // Because we update an internal Formik state object in a non-Formik way, React needs to know to re-render.
    setForceReRender(true);
  };

  // useEffect(() => {
  //   if (queryProIdToAirNetMap || queryOutdoorProIdToAirNetMap) {
  //     getDaikinMapping();
  //   }
  // }, [queryProIdToAirNetMap, getDaikinMapping, queryOutdoorProIdToAirNetMap]);

  return (
    <div className={classes.tableWrapper} style={{ height: removeHeight ? `calc(100% - ${removeHeight}px)` : "calc(100%  - -7px)" }}>
      <Table
        size="small"
        // stickyHeader
        // aria-label="sticky table"
        className={classes.tableContainer}
        stickyHeader
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            {isRemoved && (
              <TableCell
                classes={{ root: classes.tableHeadCell }}
                className={classes.headCellDelete}
                align="left"
              />
            )}
            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCellName}
              align="left"
              style={{ width: "37ch", minWidth: "37px" }}
            >
              {t`Unit name`}
            </TableCell>
            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCell}
              align="left"
              style={{ width: "18ch", minWidth: "18ch" }}
            >
              {t`SN`}
            </TableCell>
            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCell}
              align="left"
              style={{ width: "18ch", minWidth: "18ch" }}
            >
              {t`Model`}
            </TableCell>
            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCell}
              align="left"
              style={{ width: "9ch", minWidth: "9ch" }}
            >
              {t`Type`}
            </TableCell>
            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCellType}
              align="center"
              style={{ width: "16ch", minWidth: "16ch" }}
            >
              {t`Role`}
            </TableCell>

            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCell}
              align="left"
              style={{ width: "31ch", minWidth: "31ch" }}
            >
              {t`Capacity`}
            </TableCell>

            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCell}
              align="left"
              style={{ width: "31ch", minWidth: "31ch" }}
            >
              {t`Control ID`}
            </TableCell>

            <TableCell
              classes={{ root: classes.tableHeadCell }}
              className={classes.headCellSystem}
              align="left"
              style={{ width: "42ch", minWidth: "42ch", paddingLeft: "0 15px" }}
            >
              {t`System`}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.unitsChanges[lineIndex].map((unit: any, index: any) => {
            return unit && ((unit.type === unitTypes.outdoor) || (unit.type === unitTypes.indoor))
              ? (
                <UnitTableRow
                  key={index}
                  lineIndex={lineIndex}
                  index={index}
                  errors={errors}
                  touched={touched}
                  values={values}
                  isRemoved={isRemoved}
                  isDaikin={isDaikin}
                  unit={unit}
                  setFieldValue={setFieldValue}
                  deviceId={deviceId}

                  deleteUnitFromContainer={handleUnitDeletionProxy}
                  deviceSystems={deviceSystems}
                  deviceControlUnits={deviceControlUnits}
                  {...restFormikProps}
                />
              ) : null;
            // )
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default UnitsToSystemsMapTable;
