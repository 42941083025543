import {
  Dialog,
  Drawer,
  IconButton,
  makeStyles,
  TextareaAutosize,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import Button from "../../cool_widgets/Button";
import {
  Close, SwitchOff as SvgSwitchOff,
  SwitchOn as SvgSwitchOn,
} from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import {
  toC,
  toF,
  toKgPerCm2,
  toPSI
} from "../../services/converter";
import ErrorBox from "../WarnningBox/ErrorBox";
import styles from "./AddRule.style";
import Condition from "./Condition";
import ConditionsGroup from "./ConditionsGroup";
import conditionUtils from "./conditionUtils";
import { Box, CustomedTextField, CustomSelect, SystemWrapper, UnitWrapper } from "./CustomedComponents";
import ItemsTree from "./ItemsTree";

const ruleTypes = [{ id: "Custom Rule", name: "Custom Rule" }];
const newRule = { relation: "and", unitType: "", parameter: "", operator: "", thresholdOperator: "", value: "", duration: "", parameter2: "" };

export default (props: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { trapTemplatesToBrands = {}, trapTemplatesMap = {}, template, close, createRule, editTrap, updateRule } = props;
  const { permissions: { canUpdate = true, canApply = true } = {} } = { ...editTrap };

  const types = useStoreState((s) => s.types.allTypes);
  const getUnitTypes = useStoreState((s) => s.types.getUnitTypes);
  const allSystems = useStoreState((s) => s.systems.allSystems);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const allDevices = useStoreState((s) => s.devices.allDevices);
  const allSites = useStoreState((state) => state.sites.allSites);
  const selections = useStoreState((state) => state.selections.selections);
  const getServiceParams = useStoreActions((action) => action.traps.getServiceParams);
  const allCustomers = useStoreState((s) => s.customers.allCustomers);
  const user = useStoreState((s) => s.users.me);
  const serviceParamTypes = useStoreState((s) => s.serviceParamTypes);
  const [ruleName, setRuleName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedBrand, setSelectedBrand] = useState<any>("");
  const [ruleType, setRuleType] = useState<any>("Custom Rule");
  const [selectedConditions, setSelectedConditions] = useState<any>([]);
  const [indoorParamsMap, setIndoorParams] = useState<any>([]);
  const [conditions, setConditions] = useState<any>([]);
  const [groups, setGroups] = useState<any>({});
  const [brandSystems, setBrandSystems] = useState<any>({});
  const [openTree, setOpenTree] = useState<string>("");
  const [selectedUnits, setSelectedUnits] = useState<any>([]);
  const [selectedSystems, setSelectedSystems] = useState<any>([]);
  const [brandToBeChange, setBrandToBeChange] = useState<any>(null);
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  const [systemsToSitesMap, setSystemsToSitesMap] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [enumParams, setEnumParams] = useState<any>([]);
  const [ruleTypeTobeChanged, setRuleTypeTobeChanged] = useState<any>(null);
  const [cancelClicked, setCancelClicked] = useState<boolean>(false);
  const [selectedBrandCustomers, setSelectedBrandCustomers] = useState<any>({});
  const { trapTypes } = types;
  const descriptionRef: any = useRef(null);
  const { temperatureScale: userTempScale = 1, measurementUnits: userPressureScale = 2 } = user;

  const { hvacBrands = [], unitTypes, measurementUnitTypes, temperatureScale, pressureScale } = types;
  const allbrandsOption = [{ value: "all", name: "General" }, ...hvacBrands];
  const customerId: any = selections.customerId;

  const getAllParams = (brand: number | string) => {
    getServiceParams({ brand: brand === "all" ? undefined : brand, unitType: unitTypes.service })
      .then((res: any) => {
        const enumParamsValues: any = [];
        Object.values(res).forEach((param: any) => param.enum && enumParamsValues.push(param.code));
        setIndoorParams(res);
        setEnumParams(enumParamsValues);
      });
  };

  useEffect(() => {
    if (!selectedBrand) {
      return;
    }

    setIndoorParams({});
    setEnumParams([]);
    getAllParams(selectedBrand);
    getBrandSystems(selectedBrand);
  }, [selectedBrand]);

  useEffect(() => { conditionUtils.generateId.reset(); }, []);

  const getBrandSystems = (brand: number | string) => {
    let systemsMappedToSites: any = {};
    let systems: any = {};
    let brandSites: any = {};
    let brandCustomers: any = {};

    for (const system in allSystems) {
      const { brandNum, device } = allSystems[system];
      if (
        !_.isUndefined(brandNum) &&
        brandNum === brand || brand === "all"
      ) {
        const siteId = allDevices[device].site;

        if (!allSites[siteId]) {
          continue;
        }

        if (systemsMappedToSites[siteId]) {
          systemsMappedToSites[siteId].push(system);

        } else {
          systemsMappedToSites[siteId] = [system];
        }

        brandSites[siteId] = allSites[siteId];

        const fullSystem: any = { ...allSystems[system], indoorUnits: [], outdoorUnits: [] };
        if (fullSystem.units.length === 0) {
          systems[system] = fullSystem;
          break;
        }
        const allSystemUnits: any[] = _.filter(allUnits, (unit) => unit.system === system);
        const units: any[] = [];
        for (let serviceUnit of allSystemUnits) {
          if (serviceUnit.controlUnit) {
            units.push(serviceUnit.controlUnit);
          }
        }

        for (const i in units) {
          if (!allUnits[units[i]]) {
            continue;
          }
          if (allUnits[units[i]].type === getUnitTypes.indoor) {
            fullSystem.indoorUnits.push(units[i]);
          }

          systems[system] = fullSystem;
        }
      }
    }

    Object.values(brandSites).forEach((site: any) => {
      const { customer, id } = site;
      if (brandCustomers[customer]) {
        brandCustomers[customer].sites.push(id);
      } else {
        brandCustomers[customer] = allCustomers[customer];
        brandCustomers[customer].sites = [id];
      }
    });

    setBrandSystems(systems);
    setSystemsToSitesMap(systemsMappedToSites);
    setSelectedBrandCustomers(brandCustomers);
  };

  const handleBrandChange = (brand: number, name: string, setValue: any, ruleName: string) => {

    if (!brand || selectedUnits.length < 1 || (_.isEmpty(conditions) && _.isEmpty(groups))) {
      setRuleType("Custom Rule");
      setRuleName(ruleName);
      setSelectedBrand(brand);
      setSelectedSystems([]);
      setSelectedUnits([]);
      getAllParams(brand);
      getBrandSystems(brand);
      return;
    }

    setBrandToBeChange({ brand, ruleName });
  };

  const handleWraningBrandChanging = () => {
    setRuleType("Custom Rule");
    setRuleName(brandToBeChange.ruleName);
    setSelectedBrand(brandToBeChange.brand);
    setBrandToBeChange(null);
    setSelectedSystems([]);
    setSelectedUnits([]);

  };

  const handleRuleTypeChange = (newRuleType: string, name: string, setValue: any, currentrRuleType: string, ruleName: string, selectedBrand: string) => {
    if (_.isEmpty(conditions) && _.isEmpty(groups)) {
      if (newRuleType !== "Custom Rule") {
        trapTemplatesMap[newRuleType] && extractConditions(trapTemplatesMap[newRuleType], true);
        setRuleType(newRuleType);
        setRuleName(ruleName);
        setSelectedBrand(selectedBrand);
      } else {
        setValue(name, newRuleType);
      }
      return;
    }

    setRuleTypeTobeChanged({ ruleType: newRuleType, selectedBrand, ruleName });
  };

  const handleRuleTypeWarning = () => {
    setRuleType(ruleTypeTobeChanged.ruleType);
    setRuleName(ruleTypeTobeChanged.ruleName);
    setSelectedBrand(ruleTypeTobeChanged.selectedBrand);

    if (ruleTypeTobeChanged.ruleType === "Custom Rule") {
      setConditions([]);
      setGroups({});
    } else {
      extractConditions(trapTemplatesMap[ruleTypeTobeChanged.ruleType], true);
    }

    setRuleTypeTobeChanged(null);
  };

  const addCondition = (conditions: any, groups: any, ruleName: string, brand: number | null) => {
    const id = conditionUtils.generateId();
    setConditions([...conditions, { id, ...newRule }]);
    setGroups({ ...groups });
    setRuleName(ruleName);
    setSelectedBrand(brand);
  };

  const onSelect = (conditionId: number, status: boolean) => {
    if (status) {
      selectedConditions.push(conditionId);
      setSelectedConditions([...selectedConditions]);
      return;
    }

    setSelectedConditions(selectedConditions.filter((id: number) => id !== conditionId));
  };

  const deleteCondition = (conditions: any, index: number) => {

    if (conditions.length === 1) {

      if (Object.values(groups).length < 1) {
        setSelectedUnits([]);
        setSelectedSystems([]);
      }

      setConditions([]);
      setSelectedConditions([]);
      return;
    }

    selectedConditions.includes(index) && setSelectedConditions(selectedConditions.filter((id: number) => id !== index));
    setConditions(conditions.filter((condition: any) => condition.id !== index));
  };

  const addGroup = (conditions: any, ruleName: string, brand: number | null) => {
    const id = "group" + conditionUtils.generateId();
    const group: { id: string; relation: string; conditions: any } = { id, relation: "and", conditions: [] };

    conditions.forEach((condition: any) => selectedConditions.includes(condition.id) && group.conditions.push(condition));

    setConditions([...Object.values(conditions).filter((condition: any) => !selectedConditions.includes(condition.id))]);
    setSelectedConditions([]);
    setGroups({ ...groups, [id]: group });
    setRuleName(ruleName);
    setSelectedBrand(brand);
  };

  const parseNode = (condition: any) => {
    const { duration, operator, parameter, parameter2, value, thresholdOperator } = condition;

    let uType1: any, uType2: any;
    //unitTypes
    uType1 = unitTypes.indoor;
    uType2 = unitTypes.indoor;

    const paramObj = indoorParamsMap[parameter];
    const paramUnit = paramObj.data_unit_of_measurement.toUpperCase();

    const paramUnitType = paramUnit === "°C" ?
      measurementUnitTypes["temperature"] :
      ((paramUnit === "KG/CM2" || paramUnit === "PSI") ? measurementUnitTypes["pressure"] : measurementUnitTypes["unspecified"]);
    const scale = measurementUnitTypes["temperature"] === paramUnitType ? userTempScale : (measurementUnitTypes["pressure"] === paramUnitType ? userPressureScale : null);

    const valMeasureObj = {
      scaleType: paramUnitType,
      scale
    };

    const obj: any = operator.includes("threshold") ?
      {
        relation: thresholdOperator,
        thresholdValue: +value,
        ...valMeasureObj,
        "rules": [
          {
            code: parameter,
            operator: "self",
            unitType: uType1
          },
          {
            code: parameter2,
            operator: "self",
            unitType: uType2
          }
        ]
      }
      : operator.includes("parameter") ? {
        relation: "comparison",
        comparisonOperator: operator.charAt(0),
        "rules": [
          {
            code: parameter,
            operator: "self",
            unitType: uType1
          },
          {
            code: parameter2,
            operator: "self",
            unitType: uType2
          }
        ]
      } :
        {
          rules: [
            {
              ...valMeasureObj,
              code: parameter,
              operator,
              value: +value,
              unitType: uType1
            }
          ]
        };

    if (duration) {
      obj["triggerDelayTimeInMinutes"] = +duration;
    }

    return { ...obj, isCondition: true };
  };

  const extractRelations = (nodes: any[]) => {
    if (!nodes.length || nodes.length === 1) {
      return [];
    }
    const relations = nodes.map((node: any) => node.relation);
    relations.pop();
    return relations;
  };

  const isBasicNode = (node: any) => {
    return "operator" in node;
  };

  const groupNodes = (index: number, nodes: any[], relations: string[]) => {

    const node1 = isBasicNode(nodes[index]) ? parseNode(nodes[index]) : nodes[index];
    const node2 = isBasicNode(nodes[index + 1]) ? parseNode(nodes[index + 1]) : nodes[index + 1];

    const newNode = {
      relation: relations[index],
      ruleSets: [
        node1,
        node2
      ]
    };

    relations.splice(index, 1);
    nodes.splice(index + 1, 1);
    nodes[index] = newNode;

    return { relations, nodes };
  };

  const groupByRelation = (relations: any[], nodes: any[], relation: string) => {

    let result: any = { relations, nodes };
    let firstAndIndex = result.relations.indexOf(relation);

    while (firstAndIndex !== -1) {
      result = groupNodes(firstAndIndex, nodes, result.relations);
      firstAndIndex = result.relations.indexOf(relation);
    }
    return result;
  };

  const arrayToTree = (conditionsArray: any[]) => {

    const relations = extractRelations(conditionsArray);
    const groupedAnds = groupByRelation(relations, conditionsArray, "and");
    const tree = groupByRelation(groupedAnds.relations, groupedAnds.nodes, "or");
    return tree.nodes;
  };

  const onSubmit = (values: any) => {

    if (!customerId) {
      setErrorMessage("Please select at least one unit");
      return;
    }
    const { groups, conditions1: conditions, selectedBrand, ruleName } = values;

    const lastGroupElemnt = Object.keys(groups)[Object.keys(groups).length - 1];

    if (lastGroupElemnt) {
      groups[lastGroupElemnt] = { ...groups[lastGroupElemnt], relation: "and" };
    }

    if (_.isEmpty(groups) && _.isEmpty(conditions)) {
      setErrorMessage("One conidition at least is required");
      return;
    }

    const groupsArr = Object.values(groups);
    const hasConditions = !!conditions.length;

    const pageNodes = [];
    const pageRelations = [];

    for (let x in groupsArr) {
      const group: any = groupsArr[x];
      pageRelations.push(group.relation);
      const groupTree = arrayToTree(group.conditions)[0];
      groupTree.isGroup = true;
      pageNodes.push(groupTree);
    }

    if (!hasConditions) {
      pageRelations.pop();
    }

    if (conditions.length) {
      const conditionsTree = conditions.length > 1 ? arrayToTree(conditions)[0] : parseNode(conditions[0]);
      pageNodes.push(conditionsTree);
    }

    const groupPageByAnds = groupByRelation(pageRelations, pageNodes, "and");
    const pageTree = groupByRelation(groupPageByAnds.relations, groupPageByAnds.nodes, "or").nodes;

    const mainRuleSet: any = {
      ruleSets: pageTree
    };
    if (selectedSystems) {
      mainRuleSet["systemIds"] = [...selectedSystems];
      mainRuleSet["systemUnitType"] = types.unitTypes.indoor;
    }

    if (selectedUnits) {
      mainRuleSet["unitIds"] = [...selectedUnits];
    }

    const trapBody: any = {
      name: ruleName,
      description: descriptionRef?.current?.value || "",
      type: trapTypes.operationalTelemetry,
      isEnabled: template ? undefined : isEnabled,
      userSelections: {
        brand: selectedBrand
      },
      trapSets: [
        {
          ruleSets: [
            mainRuleSet
          ]
        }
      ]
    };
    if (!template) {
      if (editTrap) {
        delete trapBody.type;
        const shouldKeepInList = selectedUnits?.length || selectedSystems?.length;

        updateRule(trapBody, editTrap.id, selectedSystems, selectedUnits, !shouldKeepInList)
          .finally(() => close());
      }
      else {
        createRule(trapBody, customerId)
          .finally(() => close());
      }
      return;
    }

    if (editTrap) {
      delete trapBody.type;
      updateRule(trapBody, editTrap.id)
        .finally(() => close());
    }
    else {
      createRule(trapBody)
        .finally(() => close());
    }
  };

  const saveSelectedItems = (units: any) => {
    setSelectedUnits(units);
    setOpenTree("");
  };

  const openTreeDiag = (conditions: any, groups: any, mode: string) => {
    setConditions([...conditions]);
    setGroups({ ...groups });
    setOpenTree(mode);
  };

  const unGroup = (key: any, conditions: any, groups: any) => {
    setConditions([...conditions, ...groups[key].conditions]);
    delete groups[key];
    setGroups({ ...groups });
  };

  const deleteGroupCondition = (conditions: any, groups: any, key: any, conditionIndex: any) => {
    if (groups[key].conditions.length === 2) {
      setConditions([groups[key].conditions[conditionIndex === 0 ? 1 : 0], ...conditions]);
      delete groups[key];
      setGroups({ ...groups });
      return;
    }

    const newConditions = groups[key].conditions.filter((condition: any, index: number) => index !== conditionIndex);
    setGroups({ ...groups, [key]: { ...groups[key], conditions: newConditions } });
  };

  const schema = Yup.object().shape({
    ruleName: Yup.string()
      .required("Required"),
    selectedBrand: Yup.string()
      .required("Required")
  });

  //__________________________________

  const inOrder = (tree: any) => {
    let relations: any = [];
    let nodes: any = [];
    let current = tree;
    if (current) {
      if (current.isCondition || current.isGroup) {
        return { relations: [], nodes: [current] };
      }

      let traverse = (node: any) => {
        if (node.ruleSets && node.ruleSets[0]?.relation && !node.ruleSets[0]?.isGroup && !node.ruleSets[0]?.isCondition) {
          traverse(node.ruleSets[0]);
        }
        else {
          nodes.push(node.ruleSets[0]);
        }

        relations.push(node.relation);

        if (node.ruleSets && node.ruleSets[1]?.relation && !node.ruleSets[1]?.isGroup && !node.ruleSets[1]?.isCondition) {
          traverse(node.ruleSets[1]);
        }
        else {
          nodes.push(node.ruleSets[1]);
        }
      };

      traverse(current);
    }
    return { relations, nodes };
  };

  const reCreateCondition = (node: any, relation: string) => {
    const conditionId = conditionUtils.generateId();
    const nodeData: any = { id: conditionId };
    const subNode = "thresholdValue" in node ? node : node.rules[0];
    const { scaleType = -10, scale } = subNode;

    const scaleTypeString = +scaleType === +measurementUnitTypes["temperature"] ? "temp" : (+scaleType === +measurementUnitTypes["pressure"] ? "pressure" : "other");
    const needConvert = scaleTypeString !== "other" && ((scaleTypeString === "temp" && +scale !== +userTempScale) || (scaleTypeString === "pressure" && +scale !== +userPressureScale));

    let value: any = "thresholdValue" in node ? node.thresholdValue : node.rules[0].value;

    if (scaleTypeString !== "other" && needConvert) {
      let method: any = "";
      if (scaleTypeString === "temp") {
        if (temperatureScale[userTempScale] === "celsius") {
          method = toC;
        }
        else {
          method = toF;
        }
      }
      else {
        if (pressureScale[userPressureScale] === "PSI") {
          method = toPSI;
        }
        else {
          method = toKgPerCm2;
        }
      }

      if (method) {
        value = method(value);
      }
    }

    if ("thresholdValue" in node) {
      nodeData.operator = "threshold";
      nodeData.thresholdOperator = node.relation;
      nodeData.value = value;
      nodeData.parameter = "" + node.rules[0].code;
      nodeData.parameter2 = "" + node.rules[1].code;
      nodeData.unitType = node.rules[0].unitType;
    }
    else if ("comparisonOperator" in node) {
      nodeData.operator = node.comparisonOperator + " parameter";
      nodeData.value = value;
      nodeData.parameter = "" + node.rules[0].code;
      nodeData.parameter2 = "" + node.rules[1].code;
      nodeData.unitType = node.rules[0].unitType;
    }
    else {
      nodeData.operator = node.rules[0].operator;
      nodeData.value = value;
      nodeData.parameter = "" + node.rules[0].code;
      nodeData.unitType = node.rules[0].unitType || "";
    }
    nodeData.relation = relation;

    if (node.triggerDelayTimeInMinutes + "") {
      nodeData.duration = node.triggerDelayTimeInMinutes + "" || "";
    }

    let type: any;
    if (nodeData.unitType) {
      type = +unitTypes["service"] === +nodeData.unitType ? "indoor" : "outdoor";
    }
    return { data: nodeData, type };
  };

  useEffect(() => {

    if (!editTrap) {
      return;
    }

    extractConditions(editTrap, false);

  }, [editTrap]);

  const extractConditions = (editTrap: any, onlyConditions: boolean) => {

    const mainBody = editTrap.trapSets[0].ruleSets[0];

    if (!onlyConditions) {
      setDescription(editTrap.description);
      setSelectedBrand(editTrap.userSelections?.brand);
      setRuleName(editTrap.name);
      !template && setIsEnabled(editTrap.isEnabled);

      if (mainBody.systemIds) {
        setSelectedSystems(mainBody.systemIds);
      }

      if (mainBody.unitIds) {
        setSelectedUnits(mainBody.unitIds);
      }
    }

    const treeRoot = mainBody.ruleSets[0];
    const data = inOrder(treeRoot);

    data.relations.push("and");
    const newConds: any = [];
    const newGroups: any = {};

    const { nodes, relations } = data;
    for (let x in nodes) {
      const node = nodes[x];
      const relation = relations[x];

      if (node.isCondition) {
        const nodeResult = reCreateCondition(node, relation);
        newConds.push({ ...newRule, ...nodeResult.data });
      }

      if (node.isGroup) {
        delete node.isGroup;
        const groupData = inOrder(node);
        groupData.relations.push("and");
        const groupConditions: any = [];
        const { relations: gRelations, nodes: gNodes } = groupData;
        const groupId = conditionUtils.generateId();

        for (let i in gNodes) {
          const gNode = gNodes[i];
          const gRelation = gRelations[i];

          if (gNode.isCondition) {
            const nodeResult = reCreateCondition(gNode, gRelation);
            groupConditions.push({ ...newRule, ...nodeResult.data });
          }

        }

        newGroups[groupId] = { relation, conditions: groupConditions, id: groupId };
      }
    }

    setConditions(newConds);
    setGroups(newGroups);
  };

  const checkEmptyType = (values: any) => {
    let allConditions = values.conditions1;
    Object.values(values.groups).forEach((group: any) => allConditions = [...allConditions, ...group.conditions]);
    let hasEmptyFields = false;

    allConditions.forEach((condition: any) => {
      if (condition.unitType === "") {
        hasEmptyFields = true;
        return;
      }
    });
    return hasEmptyFields;
  };

  //__________________________________

  return (
    <Dialog
      disableEnforceFocus
      fullScreen={true}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{editTrap ? t`Edit Rule` : t`Add New Rule`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => setCancelClicked(true)}><Close color="#7f7692" /></IconButton>
      </div>

      <Formik
        initialValues={{ ruleName, selectedBrand, ruleType, conditions1: conditions, ...{ groups } }}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={schema}
        render={({ values, setFieldValue, errors, touched
        }) => {
          const hasEmptyFields = checkEmptyType(values);
          const unitType = "indoorUnits";
          const ruleTypeOptions = !template && values.selectedBrand && trapTemplatesToBrands[values.selectedBrand] ? [...ruleTypes, ...trapTemplatesToBrands[values.selectedBrand]] : ruleTypes;
          return (
            <Form translate="yes" className={classes.dialogContent}>
              <main
                className={clsx(classes.content, {
                  [classes.contentShift]: !!openTree
                })}
              >
                {!!openTree && <div className={classes.opacityStyle} />}
                <div className={classes.boxesHolder}>
                  <div id="basic-info" className={classes.basicInfoContainer}>
                    <div className={classes.AddRightMargin15}>
                      <CustomedTextField
                        disabled={!canUpdate}
                        value={values.ruleName}
                        name="ruleName"
                        className={classes.removeTopMargin}
                        onChange={(event: any) => setFieldValue("ruleName", event.target.value)}
                        label={t`Rule Name`}
                        error={errors.ruleName && touched.ruleName}
                      />
                      <div>
                        <CustomSelect disabled={!canUpdate} className={classes.mainSelect} error={errors.selectedBrand && touched.selectedBrand} placeholder="Select Brand" name="selectedBrand" value={values.selectedBrand} options={allbrandsOption} onChange={(event: any) => handleBrandChange(event.target.value, "selectedBrand", setFieldValue, values.ruleName)} />
                        {/* <CustomSelect label="name" optionValue="id" className={clsx(classes.mainSelect, classes.removeRightMargin)} disabled={(template ? true : ruleTypeOptions.length === 1) || !canUpdate} placeholder="Rule Type" name="ruleType" value={values.ruleType} options={ruleTypeOptions} onChange={(event: any) => template ? {} : handleRuleTypeChange(event.target.value, "ruleType", setFieldValue, values.ruleType, values.ruleName, values.selectedBrand)} /> */}
                      </div>
                    </div>
                    <TextareaAutosize
                      disabled={!!openTree || !canUpdate}
                      className={classes.textArea}
                      aria-label="minimum height"
                      rowsMin={4}
                      rowsMax={4}
                      placeholder="Insert Description / Automatic Rule Description…"
                      defaultValue={description}
                      ref={descriptionRef}
                    />
                    {!template && <IconButton
                      onClick={() => setIsEnabled(!isEnabled)}
                      className={classes.switchButton}
                      disabled={!canUpdate}
                    >
                      {isEnabled ? (
                        <SvgSwitchOn className={classes.switchSvg} />
                      ) : (
                          <SvgSwitchOff className={classes.switchSvg} />
                        )}
                    </IconButton>}
                  </div>
                  <Box
                    title="Conditions"
                    bLabel1="Add Condition"
                    bLabel2="Group"
                    bDisabled1={openTree || !selectedBrand}
                    bDisabled2={selectedConditions.length < 2 || openTree || !canUpdate}
                    action1={() => addCondition(values.conditions1, values.groups, values.ruleName, values.selectedBrand)}
                    action2={() => addGroup(values.conditions1, values.ruleName, values.selectedBrand)}
                    addBottomMargin
                  >
                    {!_.isEmpty(values.groups) && Object.keys(values.groups).map((key: any, index: number) => {
                      if (!groups[key] || !groups[key].id) {
                        return null;
                      }
                      return <FieldArray
                        name={`groups.${key}.conditions`}
                        key={`array-group-${groups[key].id}`}
                        render={() =>
                          <ConditionsGroup
                            enumParams={enumParams}
                            serviceParamTypes={serviceParamTypes}
                            errors={errors}
                            touched={touched}
                            noSingleConditions={values.conditions1.length === 0}
                            groupsLastIndex={Object.keys(values.groups).length - 1}
                            groupIndex={index}
                            isUnitsSelected={selectedUnits.length > 0}
                            disabled={openTree || !canUpdate}
                            key={`group-${groups[key].id}`}
                            indexKey={key}
                            unGroup={() => unGroup(key, values.conditions1, values.groups)}
                            conditions={values.groups[key].conditions}
                            allParamsMap={indoorParamsMap}
                            relation={values.groups[key].relation}
                            deleteCondition={(index: any) => deleteGroupCondition(values.conditions1, values.groups, key, index)}
                            onSelect={onSelect}
                            name={`groups.${key}.conditions`}
                            setFieldValue={setFieldValue}
                          />
                        }
                      />;
                    })}
                    <FieldArray
                      name="conditions1"
                      render={() => (values.conditions1.map((condition: any, index: number) =>

                        <div key={index}>
                          <Condition
                            enumParams={enumParams}
                            serviceParamTypes={serviceParamTypes}
                            conditions={values.conditions1}
                            errors={errors}
                            touched={touched}
                            disabled={openTree || !canUpdate}
                            key={condition.id}
                            index={index}
                            condition={condition}
                            setFieldValue={setFieldValue}
                            allParamsMap={indoorParamsMap}
                            deleteCondition={() => deleteCondition(values.conditions1, condition.id)}
                            onSelect={onSelect}
                            name={"conditions1"}
                            lastCondition={index !== values.conditions1.length - 1}
                          />
                        </div>
                      ))}
                    />
                  </Box>
                  {!template && <Box
                    small
                    title="Apply to"
                    bLabel2="Add Units"
                    bDisabled2={!canApply || !values.selectedBrand || openTree || (_.isEmpty(conditions) && _.isEmpty(groups))}
                    action2={() => openTreeDiag(values.conditions1, values.groups, hasEmptyFields ? "systems" : unitType)}
                  >
                    <div className={classes.unitsSystemsContainer}>
                      {selectedSystems.map((id: any) => !allSystems[id] ? null : <SystemWrapper key={`selected-system-${id}`} disabled={!canApply} systemName={allSystems[id]?.name} siteName={allSites[allDevices[allSystems[id]?.device]?.site]?.name} onRemove={() => setSelectedSystems(selectedSystems.filter((systemId: string) => systemId !== id))} />)}
                      {selectedUnits.map((id: any) => !allUnits[id] ? null :
                        <UnitWrapper key={`selected-unit-${id}`} disabled={!canApply}
                          unitName={allUnits[id]?.name}
                          siteName={allSites[allDevices[allUnits[id]?.device]?.site]?.name}
                          onRemove={() => setSelectedUnits(selectedUnits.filter((unitId: string) => unitId !== id))} />)}
                    </div>
                  </Box>}
                </div>

                <div className={classes.actionsContainer}>
                  {!!errorMessage && <Typography className={classes.errorMessage}>{errorMessage}</Typography>}
                  <Button onClick={() => setCancelClicked(true)} onMouseDown={(event: any) => event.preventDefault()} marginRight white uppercase width={150}> {t`cancel`}</Button>
                  <Button type="submit" onMouseDown={(event: any) => event.preventDefault()} disabled={openTree || !canApply} uppercase width={150}> {t`save`}</Button>
                </div>
              </main>
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={!!openTree}
                classes={{
                  paper: classes.drawerPaper
                }}
              >
                {openTree && <ItemsTree
                  mode={openTree}
                  brand={selectedBrand}
                  brandSystems={brandSystems}
                  systemsToSites={systemsToSitesMap}
                  allSites={allSites}
                  customers={allCustomers}
                  allUnits={allUnits}
                  selectedUnits={selectedUnits}
                  selectedSystems={selectedSystems}
                  onClose={() => setOpenTree("")}
                  onSave={saveSelectedItems}
                  brandCustomers={selectedBrandCustomers}
                />}
              </Drawer>
            </Form>
          );
        }}
      />

      {brandToBeChange && <ErrorBox error={"All Units will be deleted"} onAccept={handleWraningBrandChanging} onClose={() => setBrandToBeChange(null)} />}
      {ruleTypeTobeChanged && <ErrorBox error={"Are you sure? All Conditions will be deleted"} onAccept={handleRuleTypeWarning} onClose={() => setRuleTypeTobeChanged(null)} />}
      {cancelClicked && <ErrorBox error={"Do you want to discard changes?"} onAccept={close} onClose={() => setCancelClicked(false)} />}
    </Dialog>
  );
};
