import { fade } from "@material-ui/core/styles/colorManipulator";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    root: {
      width: "100%",
      height: "calc(100% - 156px)",
      display: "flex",
      background: "#f2f2f7",
      flexFlow: "column nowrap",
      overflow: "auto"
      // justifyContent: "space-between"
      // backgroundColor: theme.palette.colors.blackFontShadow
    },
    pageContent: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "100%",
      flex: 1,
      overflow: "auto",
      padding: "0 10px",
      alignItems: "center"
      // height: "calc(100% - 83px)",
      // background: "white"
    },
    section: {
      // background: theme.gradient.default,
      width: "100%",
      boxSizing: "border-box",
      borderBottom: "solid 1px #e5e2e5"
    },
    nameSection: {
      minHeight: 80,
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
      width: "100%",
      boxSizing: "border-box",
      borderBottom: "solid 1px #e5e2e5"
    },
    moreInfo: {
      minHeight: "max-content",
      // paddingTop: "20px",
      paddingBottom: "6px",
      display: "flex",
      justifyContent: "center",
      // background: theme.gradient.default,
      width: "100%"
    },
    controlSection: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      minHeight: "max-content",
      padding: "0 10px"
    },
    controlSectionHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      boxSizing: "border-box",
      // backgroundColor: fade(theme.palette.colors.lightWhite, 0.1),
      height: "60px"
    },
    controlSectionTitle: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#545964",
      display: "flex",
      alignItems: "center"
    },
    controlSectionBody: {
      display: "flex",
      flexWrap: "wrap",
      // padding: "30px 20px",
      boxSizing: "border-box"
    },
    saveButton: {
      margin: "25px auto",
      width: "calc(100% - 20px) !important",
      minHeight: 50
    },
    textStyle: {
      width: "100%",
      height: 50,
      "& div": {
        height: 50
      }
    },
    unitListVisibility: {
      // background: theme.gradient.default,
      display: "flex",
      padding: "0 20px 23px 20px",
      justifyContent: "space-between"
      // color: theme.palette.colors.white
    },
    normalSection: {
      width: "100%",
      boxSizing: "border-box",
      borderBottom: "solid 1px #e5e2e5",
      minHeight: 60,
      padding: "0 10px"
    },
    noContentContainer: {
      boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
      backgroundColor: "#fefefe",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      width: "100%",
      height: "100%",
      padding: 20
    },
    grayOval: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "136px",
      height: "136px",
      backgroundColor: "rgba(170, 162, 170, 0.1)",
      borderRadius: "50%"
    },
    arrowIcon: { fontSize: "91px", transform: "rotate(90deg)" },
    noUnitText: {
      fontSize: "26px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#545964",
      marginTop: "43px"
    },
    titleSection: {
      borderBottom: "solid 1px #e5e2e5"
    },
    mainSectionTitle: {
      fontFamily: "'RobotoMedium'",
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#15111f",
      borderBottom: "solid 1px #e5e2e5",
      // width: "calc(100% + 20px)",
      display: "flex",
      alignItems: "center",
      padding: "0 20px",
      minHeight: 60,
      minWidth: "100%"
    },
    alertIcon: {
      marginRight: 15,
      width: 25,
      height: 25
    },
    rowSectionTitle: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#545964",
      borderBottom: "solid 1px #e5e2e5",
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
      minHeight: 60,
      justifyContent: "space-between",
      minWidth: "100%"
    },
    checkboxSection: {
      fontFamily: "Roboto",
      fontSize: 15,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#545964",
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
      minHeight: 60
    },
    checkboxStyle: {
      marginRight: "10px",
      padding: 0,
      "& span": {
        width: "24px",
        height: "24px",
        borderRadius: "3px",
        backgroundColor: "#fff"
      }
    },
    controlsSection: {
      boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fdfdfe",
      marginBottom: 7
    },
    coolSlider: {
      // width: "calc(100% - 40px)",
      color: "#35a8e0",
      // marginBottom: 32,
      // left: "10px",
      "& input": {
        fontSize: "8px"
      },
      "& .MuiSlider-root": {
        // left: "10px",
        color: "#35a8e0"
      },
      "& .MuiSlider-valueLabel": {
        left: "calc(-50% - 8px)",
        color: "inherit",
        top: 24,
        fontSize: "1rem",
        "& *": {
          background: "transparent",
          color: "rgba(97, 97, 97, 0.9)",
          fontSize: "1rem",
          width: "50px"
        }
      },
      "& .MuiSlider-rail": {
        color: "rgba(180, 175, 203, 0.63)"
      }
    },
    controlSectionContainer: {
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      borderBottom: "solid 1px #e5e2e5",
      minWidth: "100%"
    },
    sliderHolder: {
      width: "calc(100% - 40px)",
      minHeight: "45px",
      marginBottom: "15px"
    },
    titleContainer: {
      display: "flex",
      minHeight: 60,
      justifyContent: "space-between",
      padding: "0 10px",
      alignItems: "center",
      marginBottom: 15,
      width: "100%"
    },
    coldIcon: {
      transform: "scale(2)",
      marginRight: "21px",
      marginLeft: 10
    },
    heatSlider: {
      // width: "calc(100% - 40px)",
      color: "#f05146",
      // marginBottom: 32,
      // left: "10px",
      "& input": {
        fontSize: "8px"
      },
      "& .MuiSlider-root": {
        // left: "10px",
        color: "#f05146"
      },
      "& .MuiSlider-valueLabel": {
        left: "calc(-50% - 8px)",
        color: "inherit",
        top: 24,
        fontSize: "1rem",
        "& *": {
          background: "transparent",
          color: "rgba(97, 97, 97, 0.9)",
          fontSize: "1rem",
          width: "50px"
        }
      },
      "& .MuiSlider-rail": {
        color: "rgba(180, 175, 203, 0.63)"
      }
    },
    autoSlider: {
      // width: "calc(100% - 40px)",
      color: "#4b1c46",
      // marginBottom: 32,
      // left: "10px",
      "& input": {
        fontSize: "8px"
      },
      "& .MuiSlider-root": {
        // left: "10px",
        color: "#4b1c46"
      },
      "& .MuiSlider-valueLabel": {
        left: "calc(-50% - 8px)",
        color: "inherit",
        top: 24,
        fontSize: "1rem",
        "& *": {
          background: "transparent",
          color: "rgba(97, 97, 97, 0.9)",
          fontSize: "1rem",
          width: "50px"
        }
      },
      "& .MuiSlider-rail": {
        color: "rgba(180, 175, 203, 0.63)"
      }
    },
    autoIcon: {
      width: 32,
      marginRight: 15
    },
    scheduleName: {
      fontFamily: "RobotoMedium",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#4b1c46",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "calc(100% - 170px)"
    },
    enablesText: {
      borderRadius: 6,
      backgroundColor: "#efeff2",
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#545964",
      width: "fit-content",
      padding: 10,
      marginRight: 10,
      marginBottom: 15
    },
    modesContainer: {
      borderRadius: 6,
      border: "solid 1px #d5d2d5",
      marginBottom: 15,
      display: "flex",
      minWidth: "100%",
      flexFlow: "column nowrap"
    },
    enablesContainer: {
      display: "flex",
      flexFlow: "row wrap"
    },
    actionsContainer: {
      display: "flex",
      alignItems: "center"
    },
    timeLabel: {
      // width: 126,
      height: 38,
      marginBottom: 11,
      // padding: 10px 22px 9px 23px;
      borderRadius: 6,
      border: "solid 1px #d5d2d5",
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: "38px",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#545964",
      padding: "0 20px",
      width: "fit-content"
    },
    degreeLabel: {
      paddingLeft: 57,
      marginTop: -11,
      fontFamily: "Roboto",
      fontSize: 15,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#545964",
      marginBottom: 15
    },
    scheduleContainer: {
      minHeight: 50,
      padding: 10,
      borderBottom: "solid 1px #e5e2e5",
      width: "calc(100% - 20px)",
      marginTop: 15
    },
    scheduleTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 15
    },
    addIcon: {
      transform: "scale(1.5)"
    },
    errorLabelStyle: {
      color: "red",
      fontSize: "15px",
      fontWeight: "bold",
      textAlign: "center",
      marginTop: "10px",
      minHeight: "15px"
    },
    btnIcon: {
      margin: "auto",
      marginRight: 0
    }
  });
