import {
  Button as MuiButton,
  ButtonGroup,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Input ,
  InputLabel,
  Typography
} from "@material-ui/core/";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import {ArrowDown, MobileCalendar} from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import CoolDateRangePicker from "../CoolDateRangePicker/CoolDateRangePicker";
import useStyles from "./MobileDatePicker.style";

const MobileDatePicker: React.FC<any> = (props: any) => {
  const {  close } = props;
  const classes = useStyles();
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const selections = useStoreState((s) => s.selections.selections);
  const [customRange, setCustomRange] = useState<string>("");

  const handleNewDateRange = (range: any) => {
    if (range) {
      updateSelections({ type: "time", data: range });
    }
    close();
  };
  return (
    <>
        <div  className={classes.datePickerContainer}>
        <div style={{
          borderBottom: "solid 1px #d5d2d5",
          backgroundColor: "#f6f6f7",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          height: 65,
          alignItems: "center",
          paddingLeft: 20,
          paddingRight: 10
        }}>
        <Typography style={{fontFamily: "Roboto",
                            fontSize: 20,
                            fontWeight: 600,
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            color: "#29132e"}}>{t`Select Date`}</Typography>
        <IconButton disableRipple style={{width: 30, height: 30, padding: 0, borderRadius: 6}} onClick={close}><ArrowDown style={{height: 8, width: 12}} color="#7f7692"/></IconButton>
        </div>
        <div style={{display: "flex", flexFlow: "column nowrap", height: "calc(100% - 61px)",
                     minHeight: "calc(100% - 61px)",
                     overflow: "auto",
                     width: "100%",
                     maxWidth: 380,
                     alignItems: "center",
                     padding: 20}}>
        <Typography style={{marginBottom: "20px",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            color: "#29132e",
                            width: "100%"
      }}>{t`Custom Date`}</Typography>
      {/* background: "#fefefe"*/}
        <ButtonGroup  classes={{groupedOutlined: classes.groupButtons, root: classes.groupsWidth}}>
  <MuiButton style={{flex: 1}} className={clsx({[classes.selectedBtn]: customRange === "last 24 hrs"})} onClick={() => setCustomRange("last 24 hrs")}>Day</MuiButton>
  <MuiButton style={{flex: 1}} className={clsx({[classes.selectedBtn]: customRange === "last 7 days"})} onClick={() => setCustomRange("last 7 days")}>Week</MuiButton>
  <MuiButton style={{flex: 1}} className={clsx({[classes.selectedBtn]: customRange === "last 30 days"})} onClick={() => setCustomRange("last 30 days")}>Month</MuiButton>
  {/*<MuiButton style={{flex: 1}} className={clsx({[classes.selectedBtn]: customRange === "last year"})} onClick={() => setCustomRange("last year")}>Year</MuiButton>*/}
</ButtonGroup>
<div style={{
  display: "flex",
  margin: "20px 0px",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%"
}}>
<InputLabel style={{
  width: "calc(50% - 13px)",
  height: "46px",
  padding: "10px 28px 13px 14px",
  borderRadius: "6px",
  border: "solid 1px #d5d2d5",
  backgroundColor: "#ffffff",
  display: "flex",
  alignItems: "center"
}}><MobileCalendar style={{minHeight: 23, minWidth: 23}} /> <span style={{paddingLeft: 15}}> {moment(selections?.dateRange?.startDate || new Date()).format("DD/MM/YY")}</span></InputLabel>
<Typography style={{fontFamily: "Roboto", fontSize: 18,
                    fontWeight: 100,
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    color: "#545964",
                    padding: "0 10px"}}>-</Typography>
<InputLabel style={{
  width: "calc(50% - 13px)",
  height: "46px",
  padding: "10px 28px 13px 14px",
  borderRadius: "6px",
  border: "solid 1px #d5d2d5",
  backgroundColor: "#ffffff",
  display: "flex",
  alignItems: "center"
}}><MobileCalendar style={{minHeight: 23, minWidth: 23}} /> <span style={{paddingLeft: 15}}> {moment(selections?.dateRange?.endDate || new Date()).format("DD/MM/YY")}</span></InputLabel>

</div>
              <CoolDateRangePicker
                handleSubmit={handleNewDateRange}
                initialRange={selections.dateRange}
                mobile
                customRange={customRange}
                clearCustomRange={() => setCustomRange("")}
              />
          </div>
        </div>
    </>
  );
};
export default MobileDatePicker;
