import { Grid, Link } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Field, Formik } from "formik";
import * as queryString from "query-string";
import React, { useState } from "react";
import { Redirect, useLocation } from "react-router";
import { t } from "ttag";
import * as Yup from "yup";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import "../../icons.css";
import { SupportTicket } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ReportProblem from "../../screens/ReportProblem/ReportProblem";
import assetsService from "../../services/assetsService";
import InputField from "./InputField";
import useStyles from "./Login.style";

interface ILoginState {
  username: string;
  password: string;
  splashScreenShow: boolean;
  error?: string;
}

const LoginSchema = Yup.object({
  password: Yup.string().required(),
  username: Yup.string().required().min(6, "Too Short!").max(50, "Too Long!")
});

const Login: React.FC<any> = (props) => {
  const isSplashScreen = useStoreState((s) => s.config.isSplashScreen);
  const doLogin = useStoreActions((a) => a.doLogin);
  const isLoggedIn = useStoreState((s) => s.isLoggedIn);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const location = useLocation();
  const [openReport, setOpenReport] = useState<boolean>(false);
  const [loginState, setLoginState] = useState<ILoginState>({
    username: "",
    password: "",
    splashScreenShow: true
  });
  const classes = useStyles();
  const [error, setError] = useState<any>(null);
  const errorBox = error ? <ErrorBox error={error} onClose={() => setError(null)} /> : null;

  const handleLogin = (values: ILoginState) => {
    doLogin({
      username: values.username,
      password: values.password
    }).then((response: any) => {
      if (response) {
        setLoginState({ ...loginState, error: response.message });
      }
    });
  };
  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };
  if (isForgotPassword) {
    return <Redirect to={"/forgot-password"} />;
  }

  if (isLoggedIn) {
    const params = queryString.parse(location.search);

    return (
      <div>
        <p>{t`user already logged in`}</p>
        <Redirect to={(params.redirect as string) || "/dashboard"} />
      </div>
    );
  }

  return (
    <div className={classes.Login}>
      <div className={classes.main}>
        <div className={classes.logos}>
          <img
            alt="logo"
            src={assetsService.getResourcePath("logo.png")}
            className={classes.logo}
          />
        </div>
        {!isSplashScreen || !loginState.splashScreenShow ? (
          <div className={classes.interactionSection}>
            <Typography className={classes.title}>{t`Welcome! Please login`}</Typography>
            {errorBox}

            <Formik
              initialValues={loginState}
              onSubmit={handleLogin}
              enableReinitialize={true}
              validationSchema={LoginSchema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ handleSubmit, errors, touched, values, ...restFormikProps }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container className={classes.container}>
                    <Grid item className={classes.fieldContainer}>
                      <Field
                        tabIndex={1}
                        label={t`Username`}
                        value={values.username}
                        name="username"
                        component={InputField}
                        error={errors.username && touched.username ? true : false}
                        helperText={errors.username && touched.username ? errors.username : ""}
                      />
                      {errors.username ? <Typography style={{ color: "red" }}> </Typography> : null}
                    </Grid>
                    <Grid item className={classes.fieldContainer}>
                      <Field
                        type="password"
                        tabIndex={2}
                        label={t`Password`}
                        value={values.password}
                        name="password"
                        component={InputField}
                        error={errors.password && touched.password ? true : false}
                        helperText={errors.password && touched.password ? errors.password : ""}
                      />
                      {errors.password ? <Typography style={{ color: "red" }}> </Typography> : null}
                    </Grid>
                    <Grid item className={classes.fieldContainer}>
                      {loginState.error ? (
                        <span
                          style={{ color: "rgb(240, 83, 71)", margin: "10px" }}
                        >{loginState.error}</span>
                      ) : null}
                    </Grid>
                    <Grid item className={classes.buttonContainer}>
                      <Button
                        title="LOGIN"
                        className={classes.buttonLogin}
                        uppercase
                        bold
                        red
                        onMouseUp={handleSubmit}
                        onClick={handleSubmit}
                      >
                        {t`LOGIN`}
                      </Button>
                      <button type="submit" style={{ display: "none" }}>login2</button>
                      {/**this second button is only to capture the "enter" button.. the first button uses on mouse up due to bug in MUI */}
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
            <Link
              component="button"
              variant="body2"
              onClick={handleForgotPassword}
              className={classes.forgotPassBtn}
            >
              {t`Forgot Password?`}
            </Link>
          </div>
        ) : null}
      </div>
      <Button
        disableRipple
        variant="contained"
        className={classes.reportProblemButton}
        onClick={() => setOpenReport(true)}
        title={t`Open Support Ticket`}
        red
      >
        <SupportTicket width={"2rem"} height={"2rem"}/>
      </Button>
      {openReport && (
      <ReportProblem openReport onClose={() => setOpenReport(false)}/>
        )}
    </div>
  );
};

export default Login;
