import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Typography
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { Formik } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { t } from "ttag";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { Close } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUser } from "../../models/Users";
import UserDetails, { InviteSchema, UserSchema } from "./UserDetails";
import useStyles from "./UserEdit.style";
import { buildPermissions, IUserFormValues } from "./UserHelpers";
import UserSettings from "./UserSettings";

const UserNew: React.FC<RouteComponentProps<any> & any> = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const [submitError, setSubmitError] = useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const [controlUser, setControlUser] = useState<boolean>(true);
  const [mgmtUser, setMgmtUser] = useState<boolean>(true);
  const user = useStoreState((s) => s.users.me);
  const createCustomerUser = useStoreActions((a) => a.users.createCustomerUser);
  const types = useStoreState((state) => state.types.allTypes);

  const createInvite = useStoreActions((a) => a.invites.createInvite);
  const sendInviteEmail = useStoreActions((a) => a.invites.sendInviteEmail);
  const addNewInvite = useStoreActions((a) => a.invites.addNewInvite);

  const { temperatureScale, pressureScale } = types;

  const newUser: Record<string, any> = {
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    email: "",
    phone: "",
    temperatureScale: 1,
    measurementUnits: 1,
    timeFormat: 0,
    dateFormat: 0
  };

  const initialValues: IUserFormValues = {
    firstName: newUser.firstName,
    lastName: newUser.lastName,
    username: newUser.username,
    password: newUser.password,
    email: newUser.email,
    phone: newUser.phone,
    temperatureScale: newUser.temperatureScale,
    // form only
    adminRole: 0,
    checkedSites: {},
    checkedSystems: {},
    measurementUnits: user.measurementUnits ?? 1,
    timeFormat: newUser.timeFormat,
    dateFormat: newUser.dateFormat
  };

  const sendInvite = (values: any) => {
    const { firstName, lastName, email, timeFormat, dateFormat } = values;
    const payload = {
      appId: "coolAutomationControl",
      firstName,
      lastName,
      email,
      timeFormat,
      dateFormat,
      permissions: buildPermissions(
        user.customer || "",
        values.adminRole,
        values.checkedSites,
        values.checkedSystems
      )
    };
    createInvite({ id: user.customer || "", data: payload })
      .then((invite: any) => {
        addNewInvite(invite);
        sendInviteEmail({ email, inviteId: invite.id })
          .then(() => handleClose()).catch((e: any) => setSubmitError(e.message));
      })
      .catch((e: any) => setSubmitError(e.message));
  };

  const onUserNewSubmit = (values: IUserFormValues, actions: any) => {
    values.phone = values.phone.replace(/[^a-zA-Z0-9 ]/g, "");
    values.phone = values.phone.replace(/\s/g, "");

    if (
      values.adminRole !== 0 &&
      !_.find(values.checkedSites, (value) => value) &&
      !_.find(values.checkedSystems, (value) => value)
    ) {
      setSubmitError("Please chose site or system permissions for user");
      return;
    }

    if (!user.customer) {
      return;
    }
    if (!mgmtUser) {
      sendInvite(values);
      return;
    }

    values.phone = values.phone.replace(/[^a-zA-Z0-9 ]/g, "");
    values.phone = values.phone.replace(/\s/g, "");

    const payload = {
      ..._.assign(newUser, _.pick(values, _.keys(newUser))),
      permissions: buildPermissions(
        user.customer || "",
        values.adminRole,
        values.checkedSites,
        values.checkedSystems
      )
    } as IUser;

    if (!user.customer) {
      return;
    }
    createCustomerUser({ id: user.customer as string, user: payload })
      .then(() => handleClose())
      .catch((e: any) => setSubmitError(e.message));
  };
  const errorBox = submitError ? (
    <ErrorBox
      error={submitError}
      title={_.includes(submitError, "permissions for user") ? "Missing users permissions" : ""}
      onClose={() => setSubmitError(null)}
    />
  ) : null;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen}>
        {t`Add New User`}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        classes={{ paper: classes.dialog }}
      >
            <div className={classes.dialogHeader}>
              <Typography className={classes.headerTitle}>{t`Add new user`}</Typography>
              <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => setOpen(false)}>
                <Close color="#7f7692" />
              </IconButton>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={onUserNewSubmit}
              enableReinitialize={true}
              validationSchema={!mgmtUser ? InviteSchema : UserSchema}

            >
              {({ handleSubmit, errors, touched, ...restFormikProps }) => (
                <form onSubmit={handleSubmit} className={classes.dialogContent}>
                  <Grid container className={classes.dialogSubContent}>
                    <Grid item xs={6}>
                      <Paper className={classes.leftCard} elevation={0}>
                        <Typography
                          className={classes.headerStyle}
                        >{t`Application/s Access`}</Typography>
                        <Divider orientation="horizontal" />
                        <div style={{
                          display: "flex", marginLeft: "-9px",
                          width: "60%",
                          justifyContent: "space-between",
                          padding: "8px 0px"
                        }}>
                          <InputLabel><Checkbox
                            color="default"
                            onChange={() => setMgmtUser(!mgmtUser)}
                            checked={mgmtUser}
                            inputProps={{ "aria-labelledby": `toggle all` }}
                          />{t`Managment`}
                          </InputLabel>
                          <InputLabel><Checkbox
                            color="default"
                            onChange={() => setControlUser(!controlUser)}
                            checked={controlUser}
                            inputProps={{ "aria-labelledby": `toggle all` }}
                            disabled={true}
                          />{t`Control`}
                          </InputLabel>
                        </div>
                        <Typography
                          className={classes.headerStyle}
                        >{t`Personal Details`}</Typography>
                        <Divider orientation="horizontal" />
                        <UserDetails
                          user={{ ...newUser, customer: user.customer }}
                          errors={errors}
                          touched={touched}
                          withPasswordField={!mgmtUser ? false : true}
                          invite={!mgmtUser}
                          temperatureScale={temperatureScale}
                          pressureScale={pressureScale}
                          {...restFormikProps}
                          {...props}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper className={classes.rightCard} elevation={0}>
                        <Typography className={classes.headerStyle}>{t`Permissions`}</Typography>
                        <Divider orientation="horizontal" />
                        <UserSettings
                          user={{ ...newUser, customer: user.customer }}
                          newUser={true}
                          {...restFormikProps}
                          {...props}
                          inviteToControl={!mgmtUser}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                  <div className={classes.actionsHolder}>
                    {errorBox}
                    <Button
                    white
                    onClick={handleClose}
                    uppercase
                    width={150}
                    marginRight
                    >
                    {t`Cancel`}
                    </Button>
                    <Button
                      uppercase
                      width={150}
                      type="submit"
                    >
                      {!mgmtUser ? t`Send invite` : t`Submit`}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
      </Dialog>
    </>
  );
};

export default UserNew;
