import {
    Button,
    Button as MuiButton,
    Card,
    Checkbox,
    IconButton,
    MenuItem,
    Select,
    Slider,
    TextField,
    Tooltip, Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import { ModesList } from "../../components/ModesList";
import Switch from "../../components/SmallSwitch/Switch";
import SwitchList from "../../components/SwitchList/SwitchList";
import TimePicker from "../../components/TimePicker/TimePicker";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import { modeIcons } from "../../constants/modes";
import SButton from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IServiceTypes } from "../../models/Types";
import assetsService from "../../services/assetsService";
import { minsToTime, stringTimeToUTCMins } from "../../services/timeService";
import useStyles from "./Settings.style";

interface Props {
    children: React.ReactElement;
    open: boolean;
    value: any;
}
function ValueLabelComponent(props: Props) {
    const classes = useStyles();
    const { children, open, value } = props;

    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            placement="bottom"
            title={value}
            classes={{ popper: classes.tooltip, tooltip: classes.tooltipBox }}
        >
            {children}
        </Tooltip>
    );
}
const UnitSetting: React.FC<any> = (props: any) => {
    const classes = useStyles();
    const temperatureScale = props.useTemperatureScale;
    const user = useStoreState((state) => state.users.me);
    const timeFormat = useStoreState((state) => state.users.timeFormat);
    const types = useStoreState((states) => states.types.allTypes);
    const [scheduleName, setScheduleName] = useState<any>("");
    const endActionTypesArr: any = Object.values(types.endActionTypes);
    const [endActionType, setEndActionType] = useState<any>(endActionTypesArr[1] ? endActionTypesArr[1].value : "");
    const [openPicker, setOpenPicker] = useState<string>("");
    const [powerOnTime, setPowerOnTime] = useState<string>("");
    const [powerOffTime, setPowerOfftime] = useState<string>("");
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [weekDays, setWeekDays] = useState<any>([]);
    const [error, setError] = useState<any>("");
    const [allDay, setAllDay] = useState<boolean>(false);
    const { timeFormat: timeFormatTypes } = types;
    const { timeFormat: myTimeFormat } = user;

    const timeFormatObject = myTimeFormat ? timeFormatTypes[myTimeFormat] : timeFormatTypes[0]; //default 24 hours
    const is12Hours = timeFormatObject.text === "12 hours" ? true : false;

    const [supportedValues, setSupportedValues] = useState<any>({
        supportedOperationModes: [],
        supportedFanModes: [],
        supportedSwingModes: [],
        temperatureLimits: {
            0: temperatureScale === 1 ? [5, 28] : [41, 82],
            1: temperatureScale === 1 ? [15, 34] : [59, 93],
            2: temperatureScale === 1 ? [0, 28] : [32, 82]
        }
    });
    const [tempLimitFlags, setTempLimitFlags] = useState<any>({
        enableCoolMode: false,
        enableHeatMode: false,
        enableAutoMode: false
    });
    const [tStatFlags, setTStatFlags] = useState<any>({
        enableOnoff: false,
        enableMode: false,
        enableSetpoint: false
    });

    const hasValue = (value: any) => {
        return !!value || value === 0;
    };

    useEffect(() => {
        if (props.selectedSchedule) {
            const values: any = {};
            const schedule = _.cloneDeep(props.selectedSchedule);
            if (schedule) {
                setEndActionType(schedule.endActionType || Object.values(types.endActionTypes)[1]);
                if (!props.idDeviceLimit) {
                    // values.supportedOperationModes = schedule.supportedOperationModes;
                    // values.supportedFanModes = schedule.supportedFanModes;
                    // values.supportedSwingModes = schedule.supportedSwingModes;
                    values.temperatureLimits = schedule.temperatureLimits;
                    setTStatFlags({
                        enableOnoff: schedule.enableOnoff || false,
                        enableMode: schedule.enableMode || false,
                        enableSetpoint: schedule.enableSetpoint || false
                        // enableOnState: schedule.enableOnState || false
                    });
                    setTempLimitFlags({
                        enableCoolMode: schedule.enableCoolMode || false,
                        enableHeatMode: schedule.enableHeatMode || false,
                        enableAutoMode: schedule.enableAutoMode || false
                    });
                    setScheduleName(schedule.name);
                    setWeekDays(schedule.days);
                    const { powerOnTime, powerOffTime } = schedule;
                    setPowerOnTime(hasValue(powerOnTime) ? minsToTime(powerOnTime, timeFormat) : "");
                    setPowerOfftime(hasValue(powerOffTime) ? minsToTime(powerOffTime, timeFormat) : "");
                    setIsDisabled(schedule.isDisabled);
                }

                if (props.idDeviceLimit) {
                    values.temperatureLimits = schedule.eWrcTemperatureLimits;
                    setTStatFlags({
                        enableOnoff: schedule.eWrcEnableOnoff || false,
                        enableMode: schedule.eWrcEnableMode || false,
                        enableSetpoint: schedule.eWrcEnableSetpoint || false
                        // enableOnState: schedule.eWrcEnableOnState || false
                    });
                    setTempLimitFlags({
                        enableCoolMode: schedule.eWrcEnableCoolMode || false,
                        enableHeatMode: schedule.eWrcEnableHeatMode || false,
                        enableAutoMode: schedule.eWrcEnableAutoMode || false
                    });
                    setScheduleName(schedule.name);
                    setWeekDays(schedule.days);
                    const { powerOnTime, powerOffTime } = schedule;
                    setPowerOnTime(hasValue(powerOnTime) ? minsToTime(powerOnTime, timeFormat) : "");
                    setPowerOfftime(hasValue(powerOffTime) ? minsToTime(powerOffTime, timeFormat) : "");
                    setIsDisabled(schedule.isDisabled);
                }

            }
            setSupportedValues(values);
        }

    }, [props.selectedSchedule, is12Hours]);
    useEffect(() => {
        let startHour;
        let endHour;
        if (is12Hours) {
            startHour = "12".padStart(2, "0") + ":" + "00".padStart(2, "0") + "am";
            endHour = "11".padStart(2, "0") + ":" + "59".padStart(2, "0") + "pm";
        } else {
            startHour = "00".padStart(2, "0") + ":" + "00".padStart(2, "0");
            endHour = "23".padStart(2, "0") + ":" + "59".padStart(2, "0");
        }
        if (startHour === powerOnTime && endHour === powerOffTime) {
            setAllDay(true);
        }
        else {
            setAllDay(false);
        }

    }, [powerOnTime, powerOffTime]);

    const getUnitKeyOfMode = (typesMode: string) => {
        let unitModeName: any = "";
        switch (typesMode) {
            case "operationModes":
                unitModeName = "supportedOperationModes";
                break;
            case "fanModes":
                unitModeName = "supportedFanModes";
                break;
            case "swingModes":
                unitModeName = "supportedSwingModes";
                break;
            case "cooling":
                unitModeName = 0;
                break;
            case "heat":
                unitModeName = 1;
                break;
            case "auto":
                unitModeName = 2;
                break;
            default:
                unitModeName = null;
                break;
        }
        return unitModeName;
    };

    const errorBox = error ? <ErrorBox error={error} onClose={() => {
        setError(null);
        //  setOpen(false);
    }} /> : null;

    const changeSupportedValues = (e: any, mode: string) => {
        const isChecked = e.target.checked;
        const modeName = getUnitKeyOfMode(mode);
        const updatedValues: any = { ...supportedValues };

        if (!modeName) {
            return;
        }
        updatedValues[modeName] = !isChecked
            ? []
            : Object.keys(types[mode as keyof IServiceTypes]).map((key: any) => +key);
        setSupportedValues(updatedValues);
    };

    const valueLabelFormat = (value: number) => {
        const valueToShow = value + (temperatureScale === 1 ? " °C" : " °F");
        return `${valueToShow}`;
    };
    const getOnEnd = () => {
        const options: any = {
            "noAction": "no action",
            "revert": "Return to previous settings",
            "doNothing": "Keep schedule settings",
            "allowAll": "Allow all"
        };
        const actionTypesOptions = Object.values(types.endActionTypes).filter((type: any) => {
            return type.text !== "noAction";
        });
        return <Select
            labelId="endActionType"
            value={endActionType}
            className={classes.endActionTypeSelect}
            onChange={(event: any) => handleSelectEndActionType(event.target.value)}
            // IconComponent={SvgArrow}
            variant="outlined"
            disableUnderline
        >
            {actionTypesOptions.map((type: any) => (
                <MenuItem value={type.value}>{options[type.text]}</MenuItem>
            ))}
            {/* {graphFiltere === "system" && <MenuItem value={"tenant"}>Zones View</MenuItem>}
            <MenuItem value={"unit"}>Units View</MenuItem>
            <MenuItem value={"time"}>Time View</MenuItem> */}
        </Select>;

        // <CustomSelect
        // label="text"
        // error={error} placeholder=""
        // name="endActionType" value={endActionType}
        // options={Object.values(types.endActionTypes)} onChange={(event: any) => handleSelectEndActionType(event.target.value)} />
    };

    const toggleAllWeekDays = () => {
        if (weekDays.length) {
            setWeekDays([]);
        } else {
            setWeekDays([
                "Friday",
                "Monday",
                "Saturday",
                "Sunday",
                "Thursday",
                "Tuesday",
                "Wednesday"]);
        }
    };
    const getTime = (selectedHour: any, selectedMinutes: any = 0) => {
        let exactHour = selectedHour; //0;
        const local = new Date();
        local.setHours(exactHour);
        local.setMinutes(selectedMinutes);

        const utcHours = local.getUTCHours();
        const utcMin = local.getUTCMinutes();
        const currentSelectedTime = +utcHours * 60 + utcMin;
        return currentSelectedTime;
    };
    const toggleAllDay = () => {
        if (allDay) {
            setAllDay(false);
        }
        else {
            setAllDay(true);
            let startHour, endHour;
            if (is12Hours) {
                startHour = "12".padStart(2, "0") + ":" + "00".padStart(2, "0") + "am";
                endHour = "11".padStart(2, "0") + ":" + "59".padStart(2, "0") + "pm";
            } else {
                startHour = "00".padStart(2, "0") + ":" + "00".padStart(2, "0");
                endHour = "23".padStart(2, "0") + ":" + "59".padStart(2, "0");
            }
            setPowerOnTime(startHour);
            setPowerOfftime(endHour);
        }

    };

    const updateWeekDays = (modeName: string, modeNumber: any) => {
        if (!props.canEdit) {
            return;
        }
        const weekDaysClone = [...weekDays];
        if (weekDaysClone.indexOf(modeNumber) > -1) {
            const index = weekDaysClone.indexOf(modeNumber);
            weekDaysClone.splice(index, 1);
        } else {
            weekDaysClone.push(modeNumber);
        }
        setWeekDays(weekDaysClone);
    };

    const updateValues = (modeName: string, modeNumber: any) => {
        const locValues: any = { ...supportedValues };
        const unitModeName = getUnitKeyOfMode(modeName);
        if (!unitModeName) {
            return;
        }

        const checkIndex = locValues[unitModeName].indexOf(+modeNumber);
        if (checkIndex > -1) {
            locValues[unitModeName].splice(checkIndex, 1);
        } else {
            locValues[unitModeName] = [...locValues[unitModeName], +modeNumber];
        }
        setSupportedValues(locValues);
    };

    const handleSetCooling = (event: any, newValue: number | number[]) => {
        const locValues: any = { ...supportedValues };
        locValues.temperatureLimits[0] = newValue;
        setSupportedValues(locValues);
    };

    const handleSetHeat = (event: any, newValue: number | number[]) => {
        const locValues: any = { ...supportedValues };
        locValues.temperatureLimits[1] = newValue;
        setSupportedValues(locValues);
    };

    const handleSetAuto = (event: any, newValue: number | number[]) => {
        const locValues: any = { ...supportedValues };
        locValues.temperatureLimits[2] = newValue;
        setSupportedValues(locValues);
    };

    const handleSetTempLimitRange = (flagName: string) => {
        const nextFlagState = _.clone(tempLimitFlags);
        nextFlagState[flagName] = !nextFlagState[flagName];
        setTempLimitFlags(nextFlagState);
    };

    const handleSave = () => {
        let name = scheduleName;
        if (!scheduleName) {
            setError("Please enter name");
            // name = weekDays.join('-');
            return;
        }
        if (!weekDays.length) {
            setError("Please select at least one day");
            return;
        }

        if (_.isNil(powerOnTime) || powerOnTime === "" || _.isNil(powerOffTime) || powerOffTime === "") {
            setError("Add start and end hour");
            return;
        }
        const onTime = stringTimeToUTCMins(powerOnTime, is12Hours);
        const offTime = stringTimeToUTCMins(powerOffTime, is12Hours);

        if (onTime > offTime) {
            setError("start time is after end time, please enter valid time");
            return;
        }

        // if (_.isNil(powerOffTime)) {
        //     setError('please select off time')
        //     return;
        // }
        // if (!tStatFlags.enableOnoff && !tStatFlags.enableMode && !tStatFlags.enableSetpoint &&
        //     !tStatFlags.enableOnState && !tempLimitFlags.enableCoolMode && !tempLimitFlags.enableHeatMode &&
        //     !tempLimitFlags.enableAutoMode) {
        //     setError('please select at least one mode')
        //     return;
        // }
        //

        const defaultEndActionToSave = endActionType || types.endActionTypes.revert.value;

        const data = {
            // supportedOperationModes: supportedValues.supportedOperationModes,
            // supportedFanModes: supportedValues.supportedFanModes,
            // supportedSwingModes: supportedValues.supportedSwingModes,
            temperatureLimits: supportedValues.temperatureLimits,
            // isVisible: isUnitVisible,
            enableOnoff: tStatFlags.enableOnoff || false,
            enableMode: tStatFlags.enableMode || false,
            enableSetpoint: tStatFlags.enableSetpoint || false,
            enableOnState: tStatFlags.enableOnState || false,
            enableCoolMode: tempLimitFlags.enableCoolMode || false,
            enableHeatMode: tempLimitFlags.enableHeatMode || false,
            enableAutoMode: tempLimitFlags.enableAutoMode || false,
            name,
            days: weekDays,
            powerOnTime: onTime,
            powerOffTime: offTime,
            endActionType: defaultEndActionToSave
            // isDisabled: !isDisabled,
        };
        props.onSave(data);
        props.onCancle();

    };
    const updateDisableState = () => {
        setIsDisabled(!isDisabled);
        props.onDisableUpdate({ isDisabled: !isDisabled, id: props.selectedSchedule.id });
    };

    const handleCancel = () => {
        props.onCancle();
    };

    const getHeatTempRange = () => {
        const limitsTitleText = props.canEdit ? t`Limit heating setpoint range` : t`no heat temp limits`;
        const wrcTitleText = props.canEdit ? t`WRC Limit heating setpoint range` : t`No heating restrictions`;

        return (
            <Card
                className={clsx(classes.modeContainer, classes.schedulerContainer, classes.wrcScheduleModeContainer, {
                    [classes.viewModeContainer]: !props.canEdit
                }, {
                    [classes.editModeContainer]: props.canEdit,
                    [classes.isWrc]: props.wrcLimits
                })}
            >
                <div className={clsx(classes.modeHeader, classes.tempRangSlider)}>
                    <div className={clsx(classes.modeTitle, classes.unitModeTitle)}>
                        <img
                            src={assetsService.getImagesPath(`${modeIcons[1]}.svg`)}
                        ></img>
                        {!props.idDeviceLimit &&
                            <Typography className={classes.unitTitle}>
                                {limitsTitleText}</Typography>
                        }

                        {props.idDeviceLimit &&
                            <Typography className={classes.unitTitle}>
                                {wrcTitleText}</Typography>
                        }

                    </div>

                    {props.canEdit &&
                        <Switch
                            checked={tempLimitFlags.enableHeatMode}
                            switchChange={() =>
                                handleSetTempLimitRange("enableHeatMode")
                            }
                        />
                    }
                </div>
                {props.canEdit &&
                    <Slider
                        className={classes.heatSlider}
                        onChange={handleSetHeat}
                        // defaultValue={supportedValues.temperatureLimits[1][temperatureScale]}
                        aria-labelledby="discrete-slider-always"
                        min={temperatureScale === 1 ? 0 : 32}
                        max={temperatureScale === 1 ? 50 : 122}
                        value={supportedValues.temperatureLimits[1]}
                        valueLabelDisplay="on"
                        valueLabelFormat={valueLabelFormat}
                        step={1}
                        // ValueLabelComponent={ValueLabelComponent}
                        disabled={!tempLimitFlags.enableHeatMode}

                    />
                }

            </Card>
        );
    };

    const getCoolTempRange = () => {
        const limitsTitleText = props.canEdit ? t`Limit cooling setpoint range` : t`no cool temp limits`;
        const wrcTitleText = props.canEdit ? t`WRC Limit cooling setpoint range` : t`No cooling restrictions`;

        return (
            <Card
                className={clsx(classes.modeContainer, classes.schedulerContainer, classes.wrcScheduleModeContainer, {
                    [classes.viewModeContainer]: !props.canEdit
                }, {
                    [classes.editModeContainer]: props.canEdit,
                    [classes.isWrc]: props.wrcLimits
                })}
            >
                <div className={clsx(classes.modeHeader, classes.tempRangSlider)}>
                    <div className={clsx(classes.modeTitle, classes.unitModeTitle)}>
                        <img
                            src={assetsService.getImagesPath(`${modeIcons[0]}.svg`)}
                        ></img>
                        {!props.idDeviceLimit &&
                            <Typography className={classes.unitTitle}>
                                {limitsTitleText}</Typography>
                        }

                        {props.idDeviceLimit &&
                            <Typography className={classes.unitTitle}>
                                {wrcTitleText}</Typography>
                        }
                    </div>
                    {props.canEdit &&
                        <Switch
                            checked={tempLimitFlags.enableCoolMode}
                            switchChange={() =>
                                handleSetTempLimitRange("enableCoolMode")
                            }
                        />
                    }
                </div>
                {props.canEdit &&
                    <Slider
                        className={classes.coolSlider}
                        onChange={handleSetCooling}
                        // defaultValue={supportedValues.temperatureLimits[0][temperatureScale]}
                        aria-labelledby="discrete-slider-always"
                        min={temperatureScale === 1 ? 0 : 32}
                        max={temperatureScale === 1 ? 50 : 122}
                        value={supportedValues.temperatureLimits[0]}
                        valueLabelDisplay="on"
                        valueLabelFormat={valueLabelFormat}
                        step={1}
                        // ValueLabelComponent={ValueLabelComponent}
                        disabled={!tempLimitFlags.enableCoolMode}
                    />
                }

            </Card>

        );
    };

    const getAutoTempRange = () => {
        const limitsTitleText = props.canEdit ? t`Limit auto setpoint range` : t`no Auto temp limits`;

        return (
            <Card
                className={clsx(classes.modeContainer, classes.schedulerContainer, {
                    [classes.viewModeContainer]: !props.canEdit
                }, {
                    [classes.editModeContainer]: props.canEdit,
                    [classes.isWrc]: props.wrcLimits
                })}
            >
                <div className={clsx(classes.modeHeader, classes.tempRangSlider)}>
                    <div className={clsx(classes.modeTitle, classes.unitModeTitle)}>

                        <img
                            src={assetsService.getImagesPath(`auto.svg`)}
                        ></img>
                        <Typography className={classes.unitTitle}>
                            {limitsTitleText}</Typography>
                    </div>
                    {props.canEdit &&
                        <Switch
                            checked={tempLimitFlags.enableAutoMode}
                            switchChange={() =>
                                handleSetTempLimitRange("enableAutoMode")
                            }
                        />
                    }

                </div>
                {props.canEdit &&
                    <Slider
                        className={classes.autoSlider}
                        onChange={handleSetAuto}
                        // defaultValue={supportedValues.temperatureLimits[2][temperatureScale]}
                        aria-labelledby="discrete-slider-always"
                        min={temperatureScale === 1 ? 0 : 32}
                        max={temperatureScale === 1 ? 50 : 122}
                        value={supportedValues.temperatureLimits[2]}
                        valueLabelDisplay="on"
                        valueLabelFormat={valueLabelFormat}
                        step={1}
                        // ValueLabelComponent={ValueLabelComponent}
                        disabled={!tempLimitFlags.enableAutoMode}
                    />
                }

            </Card>
        );
    };

    const getEditLimits = () => {
        if (!props.canEdit) {
            let enabledModesLength = tempLimitFlags.enableHeatMode ? 1 : 0;
            enabledModesLength = tempLimitFlags.enableCoolMode ? enabledModesLength + 1 : enabledModesLength;
            enabledModesLength = tempLimitFlags.enableAutoMode ? enabledModesLength + 1 : enabledModesLength;

            return (
                < div className={classes.modeCardUnitScheduleSettings}>

                    <div className={clsx(classes.viewLimitsSchedulerLineGroup,
                        { [classes.viewLimitsSchedulerLineGroup]: enabledModesLength >= 2 },
                        { [classes.limitsSchedulerLineGroup]: enabledModesLength < 2 }
                    )}>
                        {!tempLimitFlags.enableCoolMode && getCoolTempRange()}
                        {!tempLimitFlags.enableHeatMode && getHeatTempRange()}
                        {!props.idDeviceLimit && !tempLimitFlags.enableAutoMode && getAutoTempRange()}
                    </div>

                </div>
            );
        }

        return (
            < div className={classes.modeCardUnitScheduleSettings}>

                <div className={classes.limitsSchedulerLineGroup}>
                    {getCoolTempRange()}
                    {getHeatTempRange()}
                    {!props.idDeviceLimit && getAutoTempRange()}
                </div>

            </div>
        );
    };

    const getEditTStats = () => {

        let items = tStatFlags;
        if (!props.canEdit) {
            items = _.reduce(tStatFlags, (obj: any, value: any, key: any) => {
                if (!tStatFlags[key]) {
                    obj[key] = value;
                }
                return obj;
            }, {});
        }
        return <SwitchList tStatFlags={items} setTStatFlags={setTStatFlags} isViewOnly={!props.canEdit} unitItem={true} />;
    };

    const handleSelectEndActionType = (type: any) => {
        setEndActionType(type);
    };
    const onSetTime = (time: string) => {
        openPicker === "start" ? setPowerOnTime(time) : setPowerOfftime(time);
        setOpenPicker("");
    };

    const onClear = () => {
        setOpenPicker("");
        openPicker === "start" ? setPowerOnTime("") : setPowerOfftime("");
    };

    const hasTime = (time: any) => {
        return time !== "" && time !== null && time >= 0;
    };

    const getTimeSettingsView = () => {
        let time = `${powerOnTime && powerOnTime} ${powerOnTime && powerOffTime ? "-" : ""} ${powerOffTime}`;
        return (
            <div>
                <div className={classes.weekDaysConatiner}>
                    {!!time.trim().length &&
                        <Button
                            disableRipple
                            disableElevation
                            disabled
                            style={{ backgroundColor: "transparent", color: "black" }}
                            onClick={() => { }}
                            variant="contained"
                            className={clsx(classes.cancelActionBtn, classes.rightTimeBtn, classes.timeBtns, classes.viewTimeBtns)}
                        >
                            {time}
                        </Button>

                    }
                    <div className={classes.weekDaysConatiner}>
                        <ModesList
                            modeName={"weekDays"}
                            activeModes={weekDays}
                            action={updateWeekDays}

                        />
                    </div>
                </div>
            </div>
        );
    };

    const getTimeSettings = () => {
        return (
            <div className={classes.timeContainer}>
                <Checkbox
                    checked={allDay}
                    onChange={toggleAllDay}
                    name="checkedB"
                    color="default"
                />

                <Typography
                    className={classes.timeCheckbox}
                >
                    {t`All Day`}
                </Typography>

                <Button
                    disableRipple
                    disableElevation
                    onClick={() => setOpenPicker("start")}
                    variant="contained"
                    className={clsx(classes.cancelActionBtn, classes.timeBtns)}
                >
                    {powerOnTime ? powerOnTime : "START HOUR"}
                </Button>

                <Button
                    disableRipple
                    disableElevation
                    onClick={() => setOpenPicker("end")}
                    variant="contained"
                    className={clsx(classes.cancelActionBtn, classes.rightTimeBtn, classes.timeBtns)}
                >
                    {powerOffTime ? powerOffTime : "END HOUR"}
                </Button>

                <div className={classes.weekDaysConatiner}>
                    <Typography
                        className={classes.timeSettingsTitle}
                    >
                        {t`Days`}
                    </Typography>

                    <ModesList
                        modeName={"weekDays"}
                        activeModes={weekDays}
                        action={updateWeekDays}

                    />
                </div>

                { !!openPicker &&
                    <div className={classes.localTimePicker}>
                        <TimePicker
                            show={!!openPicker}
                            onSet={onSetTime}
                            time={openPicker === "start" ? powerOnTime : powerOffTime}
                            onDismiss={() => setOpenPicker("")}
                            onClear={onClear}
                            overWriteCss={true}
                            is12Hours={is12Hours}
                            black={true}
                        />
                    </div>
                }
            </div>
        );
    };

    if (!props.canEdit) {
        return (
            <Card
                className={clsx(classes.modeContent, classes.modeContentUnitScheduleSettings, classes.viewModeContent)}
            >

                <Card className={clsx(classes.nameSettingContainer, classes.viewNameSettingContainer)}>
                    <Typography className={clsx(classes.topTitle, classes.topTitlePosition, classes.viewTopTitle)}>{scheduleName}</Typography>
                    <div>
                        <LightTooltip title="Edit Site">
                            <IconButton
                                disableRipple
                                onClick={props.onEditAction}
                                className={classes.iconBtnStyle}
                                disabled={props.disableAllSettings}
                            >
                                <EditIcon
                                />
                            </IconButton>
                        </LightTooltip>

                        <Delete
                            type={t`Site`}
                            object={props.selectedSchedule}
                            detach={props.onDelete}
                            disable={props.disableAllSettings}
                        />

                        <Switch
                            checked={!isDisabled}
                            switchChange={updateDisableState}
                        />
                    </div>
                </Card>

                <Card className={clsx(classes.timeSettingContainer, classes.viewTimeSettingContainer)}>
                    {getTimeSettingsView()}
                </Card>

                <Card className={clsx(classes.tStatContainer, classes.tStatScheduleContainer)}>
                    {getEditTStats()}
                </Card>

                {getEditLimits()}

            </Card>
        );
    }

    return (
        <>
            <div className={classes.dialogHeader}>
                <Typography className={classes.headerTitle}>{props.title}</Typography>
                <IconButton disableRipple onClick={handleCancel} className={classes.iconBtnStyle}>
                    <Close color="#7f7692" />
                </IconButton>
            </div>
            <div className={classes.dialogContent}>
                <Card className={clsx(classes.nameSettingContainer, classes.editNameSettingContainer)}>
                    <Typography className={clsx(classes.topTitle, classes.topTitlePosition)}>{t`Name`}</Typography>
                    <TextField
                        InputProps={{ classes: { root: classes.nameInput }, disableUnderline: true }}
                        value={scheduleName}
                        onChange={(e) => {
                            setScheduleName(e.target.value);
                        }}

                    />
                </Card>

                <Card className={clsx(classes.timeSettingContainer, classes.editTimeSettingContainer)}>
                    {getTimeSettings()}
                </Card>

                <Card className={clsx(classes.tStatContainer, classes.tStatScheduleContainer)}>
                    {getEditTStats()}
                </Card>
                {errorBox}
                {getEditLimits()}
                <Card className={classes.onEndSelect}>
                    <div className={classes.endTypeLabel}>{t`On End:`}</div>
                    {getOnEnd()}
                </Card>
            </div>
            <div className={classes.actionsHolder}>
                <div>
                    <SButton white width={150} uppercase marginRight onClick={handleCancel}>
                        {t`CANCEL`}
                    </SButton>
                    <SButton width={150} uppercase onClick={handleSave}>
                        {t`SAVE`}
                    </SButton>
                </div>

            </div>
        </>

    );

};

export default UnitSetting;
