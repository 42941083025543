import {
  Collapse,
  Dialog,
  IconButton,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import AddRule from "../../components/AddRule/AddRule";
import Delete from "../../components/Delete/Delete";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import { Lookup } from "../../components/Lookup";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import TableColumnSearch from "../../components/TableColumnSearch/TableColumnSearch";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { ArrowDown, Close } from "../../icons";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./AddEditGroupsPopup.style";
import ClickableRow from "./ClickableRow";
import Row from "./Row";

const AddEditGroup = (props: any) => {
  const classes = useStyles();
  const {group, sites, units, sensors, unitTypes , onClose, createGroup, updateGroup} = props;

  const [sitesSensors, setSitesSensors] = useState<any>({});
  const [sitesUnits, setSitesUnits] = useState<any>({});
  const [selectedUnits, setSelectedUnits] = useState<any>({});
  const [selectedSensors, setSelectedSensors] = useState<any>({});
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [groupName, setGroupName] = useState<string>("");
  const [errors, setErrors] = useState<any>({name: false, items: false});
  const [lastSiteIndex, setLastSiteIndex] = useState<number>(0);
  const [selectedSitesUnits, setSelectedSitesUnits] = useState<any>({});
  const [selectedSitesSensors, setSelectedSitesSensors] = useState<any>({});

  useEffect(() => {
    if (group){
      const {name = "", units: groupUnits = [], sensors: groupSensors = []} = group;
      const mappedUnitsToSites: any = {};
      const mappedSensorsToSites: any = {};
      const unitsObject: any = {};
      const sensorsObject: any = {};

      groupUnits.forEach((unitId: string) => {
        const {site} = units[unitId];
        unitsObject[unitId] = true;

        if (!mappedUnitsToSites[site]){
          mappedUnitsToSites[site] = {[unitId]: true};
        }else{
          mappedUnitsToSites[site] = {...mappedUnitsToSites[site], [unitId]: true};
        }
      });
      groupSensors.forEach((sensorId: string) => {
        const {site} = sensors[sensorId];
        sensorsObject[sensorId] = true;

        if (!mappedSensorsToSites[site]){
          mappedSensorsToSites[site] = {[sensorId]: true};
        }else{
          mappedSensorsToSites[site] = {...mappedSensorsToSites[site], [sensorId]: true};
        }
      });

      setGroupName(name);
      setSelectedSitesUnits(mappedUnitsToSites);
      setSelectedSitesSensors(mappedSensorsToSites);
      setSelectedUnits(unitsObject);
      setSelectedSensors(sensorsObject);
    }
  }, []);

  useEffect(() => {
    const sensorsMappedToSites: any = {};
    const unitsMappedToSites: any = {};

    Object.values(sensors).forEach((sensor: any) => {
      const {site= "", id} = sensor;

      if (!sensorsMappedToSites[site]){
        sensorsMappedToSites[site] = [id];
      }else{
        sensorsMappedToSites[site]?.push(id);
      }
    });

    Object.values(units).forEach((unit: any) => {
      const {site= "", id, type} = unit;
      if (type !== unitTypes?.indoor){
        return;
      }

      if (!unitsMappedToSites[site]){
        unitsMappedToSites[site] = [id];
      }else{
        unitsMappedToSites[site]?.push(id);
      }
    });

    Object.keys(unitsMappedToSites).forEach((siteId: any) => {
      unitsMappedToSites[siteId] = unitsMappedToSites[siteId].sort((a: any, b: any) => {
              return units[a].line - units[b].line;
            });
    });

    Object.keys(sensorsMappedToSites).forEach((siteId: any) => {
      sensorsMappedToSites[siteId] = sensorsMappedToSites[siteId].sort((a: any, b: any) => {
              let nameA = sensors[a].name.toUpperCase();
              let nameB = sensors[b].name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
    });

    setLastSiteIndex(Object.values(sites).length - 1);
    setSitesSensors(sensorsMappedToSites);
    setSitesUnits(unitsMappedToSites);
  }, []);

  const handleSiteSelect = (siteId: string) => {
    setSelectedSite(selectedSite === siteId ? "" : siteId);
  };

  const handleSave = () => {
    const units = Object.keys(selectedUnits);
    const sensors = Object.keys(selectedSensors);
    const selectedItemsLength = units.length + sensors.length;

    if (!groupName && !selectedItemsLength){
      setErrors({name: true, items: true});
      return;
    }
    if (!groupName){
      setErrors({name: true, items: false});
      return;
    }
    if (!selectedItemsLength){
      setErrors({name: false, items: true});
      return;
    }

    setErrors({name: false, items: false});
    const data = {name: groupName, units, sensors};
    if (group){
      updateGroup(group.id, data);
      return;
    }
    createGroup(data);
  };

  const onUnitSelect = (event: any, siteId: string, unitId: string) => {
    const {target: {checked}} = event;
    if (selectedSitesUnits[siteId]){

      if (checked){
      selectedSitesUnits[siteId] = {...selectedSitesUnits[siteId], [unitId]: true};
      selectedUnits[unitId] = true;
    }else{
      delete selectedSitesUnits[siteId][unitId];
      delete selectedUnits[unitId];
    }

    }else{
      selectedSitesUnits[siteId] = {[unitId]: true};
      selectedUnits[unitId] = true;
    }

    setSelectedSitesUnits(selectedSitesUnits);
    setSelectedSensors({...selectedSensors});
  };

  const onSensorSelect = (event: any, siteId: string, sensorId: string) => {
    const {target: {checked}} = event;
    if (selectedSitesSensors[siteId]){

      if (checked){
      selectedSitesSensors[siteId] = {...selectedSitesSensors[siteId], [sensorId]: true};
      selectedSensors[sensorId] = true;
    }else{
      delete selectedSitesSensors[siteId][sensorId];
      delete selectedSensors[sensorId];
    }

    }else{
      selectedSitesSensors[siteId] = {[sensorId]: true};
      selectedSensors[sensorId] = true;
    }

    setSelectedSitesSensors({...selectedSitesSensors});
    setSelectedSensors({...selectedSensors});
  };

  return (
    <Dialog classes={{ paperWidthLg: classes.dialogStyle }} aria-labelledby="add-edit-groups-popup" open={true} maxWidth="lg">
      <div className={classes.dialogTitle}>
      <Typography className={classes.dialogText}>{group ? t`Edit Group` : t`Add New Group`}</Typography>
      <IconButton onClick={onClose} className={classes.clearButton}>
          <Clear style={{ color: "#7f7692", fontSize:  25 }} />
      </IconButton>
      </div>
      <div className={classes.dialogContent}>
      <InputLabel className={classes.labelStyle}>
         {t`Group Name`}
      <TextField
        variant="outlined"
        placeholder="Create name for the group"
        margin="none"
        value={groupName}
        onChange={(event: any) => setGroupName(event.target.value)}
        InputProps={{ classes: {root: classes.outlinedInputRoot, notchedOutline: classes.notchedOutline}}}
        error={errors.name && !groupName}
      />
      </InputLabel>
      <div className={classes.selectUnitContainer}>
        <Typography className={classes.selectUnitText}>{t`Select Units`}</Typography>
        {errors.items && <Typography className={classes.errorText}>{t`Please select one unit or sensor at least`}</Typography>}
      </div>

      <div className={classes.itemsContainer}>
      {Object.values(sites).map((siteObj: any, index: number) => {
        const {id, name} = siteObj;
        const siteSensors = sitesSensors[id] || [];
        const siteUnits = sitesUnits[id] || [];
        const selected = id === selectedSite;
        const selectedUnits = selectedSitesUnits[id] || {} ;
        const selectedSensors = selectedSitesSensors[id] || {};
        const selectedUnitsLength = Object.values(selectedUnits).length;
        const selectedSensorsLength = Object.values(selectedSensors).length;

        return (<>
                  <ClickableRow
                    key={id}
                    name={name}
                    selected={selected}
                    unitsNumber={selectedUnitsLength + selectedSensorsLength}
                    collapse={true}
                    onClick={() => handleSiteSelect(id)}
                    removeMargin={lastSiteIndex === index || selected}
                  />
                {selected &&
                  <div className={classes.unitsHolder}>
                  {(siteUnits.length + siteSensors.length === 0) && <Row name={t`this site has no units`} hideLine/>}
                  {(siteUnits).map((unitId: any, index: number) =>
                    <Row
                      key={`${id}-${unitId}`}
                      name={units[unitId]?.name}
                      checked={selectedUnits[unitId]}
                      onCheck={(event: any) => onUnitSelect(event, id , unitId)}
                      hideLine={(siteSensors.length === 0 && siteUnits.length - 1 === index)}
                    />
                  )}
                  {siteSensors.map((sensorId: any, index: number) =>
                    <Row
                      key={`${id}-${sensorId}`}
                      name={sensors[sensorId]?.name}
                      checked={selectedSensors[sensorId]}
                      onCheck={(event: any) => onSensorSelect(event, id , sensorId)}
                      hideLine={siteSensors.length - 1 === index}
                    />
                  )}
                  </div>}
                </>);
      })}
      </div>
      </div>
      <div className={classes.actionsHolder}>
      <Button white uppercase width={150} marginRight onClick={onClose}>{t`Cancel`}</Button>
      <Button uppercase width={150} onClick={handleSave}>{t`Save`}</Button>
      </div>
    </Dialog>
  );
};

export default AddEditGroup;
