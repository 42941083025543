import { Card, Divider, makeStyles, Typography } from "@material-ui/core";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import clsx from "clsx";
import _ from "lodash";
import React, { Fragment } from "react";
import { t } from "ttag";
import alertSeverities from "../../constants/alertSeverities";
import { useStoreState } from "../../models/RootStore";

const useStyles = makeStyles((theme) => ({
  alerts: {
    margin: "0.8rem 0 0",
    padding: "0.6875rem 1.25rem 0",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    height: "8rem",
    minHeight: "8rem",
    maxHeight: "8rem"
  },

  alertsTitle: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "0.5rem 0",
    fontSize: "33px",
    color: "#fff"
  },

  alertIcon: {
    paddingRight: "0.5rem"
  },

  alertsHeaderTitle: {
    minWidth: "200px",
    fontSize: "0.9375rem",
    fontFamily: "RobotoMedium",
    color: "rgb(75, 28, 70)",
    paddingRight: "10%"
  },

  alertsStatistics: {
    display: "flex",
    margin: "auto 0",
    overflowX: "auto",
    overflowY: "hidden",
    width: "100%",
    justifyContent: "space-between"
  },

  singleAlertStatisticsBlock: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-start",
    padding: "10px",
    margin: "5px",
    width: "100%",
    opacity: 0.8,
    borderRadius: "10px",
    flexDirection: "row",
    backgroundColor: "#f6f6f7",
    cursor: "pointer",
    "&:last-child": {
      border: "none"
    }
  },
  iconStyle: {
    color: "#aaa2aa",
    fontSize: "12px"
  },
  hideIcon: {
    visibility: "hidden"
  },
  singleAlertStatisticsText: {
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
    color: "rgb(41, 19, 46)",
    paddingRight: "3px",
    whiteSpace: "nowrap"
  },

  singleAlertStatisticsCount: {
    fontSize: "32px",
    fontFamily: "RobotoLight",
    paddingRight: "2px"
  },
  selectedStyle: {
    backgroundColor: "#e9e7e9"
  }
}));

const AlertsBand = (props: any) => {
  const { setAlertFilterType, filterType } = props;
  const classes = useStyles();
  const getAlertsByFilter = useStoreState((state) => state.alerts.getAlertsByFilter);
  const selections = useStoreState((state) => state.selections.selections);

  // TODO: Move someplace else.
  let countSystemError = 0;
  let countIndoorError = 0;
  let countMaintenance = 0;
  let countNotConnected = 0;
  let countAnomalies = 0;

  const { customerId, siteId } = selections;
  getAlertsByFilter({ customerId, siteId }).forEach(
    (alert: any) => {
      const { severity } = alert;
      if (!severity) {
        return;
      }
      const { id } = severity;

      if (id === "SYSTEM_ERROR") {
        ++countSystemError;
      } else if (id === "INDOOR_ERROR") {
        ++countIndoorError;
      } else if (id === "MAINTENANCE") {
        ++countMaintenance;
      } else if (id === "NOT_CONNECTED") {
        ++countNotConnected;
      } else if (id === "ANOMALIES") {
        ++countAnomalies;
      }

    }


  );

  const alertMapping: any = {
    systemErrors: {
      id: "SYSTEM_ERROR",
      text: alertSeverities.SYSTEM_ERROR.name,
      color: alertSeverities.SYSTEM_ERROR.color,
      count: countSystemError
    },
    indoorErrors: {
      id: "INDOOR_ERROR",
      text: alertSeverities.INDOOR_ERROR.name,
      color: alertSeverities.INDOOR_ERROR.color,
      count: countIndoorError
    },
    notConnected: {
      id: "NOT_CONNECTED",
      text: alertSeverities.NOT_CONNECTED.name,
      color: alertSeverities.NOT_CONNECTED.color,
      count: countNotConnected
    },
    anomalies: {
      id: "ANOMALIES",
      text: alertSeverities.ANOMALIES.name,
      color: alertSeverities.ANOMALIES.color,
      count: countAnomalies
    },
    maintenance: {
      id: "MAINTENANCE",
      text: alertSeverities.MAINTENANCE.name,
      color: alertSeverities.MAINTENANCE.color,
      count: countMaintenance
    }
  };

  return (
    <Card elevation={0} className={classes.alerts}>
      <div className={classes.alertsTitle}>
        <Typography className={classes.alertsHeaderTitle}>{t`Open Alerts`}</Typography>
      </div>
      <div className={classes.alertsStatistics}>
        {_.map(alertMapping, (alertType, typeKey) => {
          const isSelected = filterType === alertType.id;
          return (
            <Fragment key={typeKey}>
              <div className={clsx(classes.singleAlertStatisticsBlock, isSelected && classes.selectedStyle)}
                onClick={() => setAlertFilterType(isSelected ? null : alertType.id)}
              >
                <span
                  className={classes.singleAlertStatisticsCount}
                  style={{ color: alertType.color }}
                >
                  {alertType.count < 10 ? "0" + alertType.count : alertType.count}
                </span>
                <span className={classes.singleAlertStatisticsText}>{alertType.text}</span>
                <FilterListOutlinedIcon className={clsx(classes.iconStyle, !isSelected && classes.hideIcon)} />
              </div>
            </Fragment>
          );
        })}
      </div>
    </Card>
  );
};

export default AlertsBand;
