import { TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import LightTooltip from "../Tooltip/LightTooltip";
import useStyles from "./ErrorPopUp.style";

export default function ErrorMouseOverPopover({
  columns,
  hideColumns,
  row,
  unit,
  showPointer,
  onAlertClick,
  dashboardUnit
}: any) {
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const cleanFilterUnit = useStoreActions((action) => action.units.cleanFilterUnit);
  const displayFlags = useStoreState((state) => state.users.displayFlags);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null),
    history = useHistory(),
    classes = useStyles(),
    getMoreInfo = (unitId: string) => {
      if (unitId) {
        updateSelections({ type: "unit", data: unitId });
        history.push(`/unit-diag/${unitId}`);
      }
    },
    handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    handlePopoverClose = () => {
      setAnchorEl(null);
    },
    handelCleanFilterClick = (unitId: string) => {
      cleanFilterUnit({ id: unitId });
    },
    open = Boolean(anchorEl);
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        className={showPointer ? classes.pointerCursor : ""}
        onClick={onAlertClick && (() => onAlertClick(row))}
      >
        {columns.map((column: any) => {
          let value = column.id === "alertType1" ?  row.severity.name : row[column.id];

          if (column.id === "alertType") {
            value = <div className={classes.typeKey} style={{ backgroundColor: row.severity.color }} />;  /// cehck severity here
          }
          return (column.hideInSingleUnitMode && unit) ||
            hideColumns.indexOf(column.id) !== -1 ? null :

            (column.id === "errorCode" && row.errorDescription) ?
            <LightTooltip title={row.errorDescription}>
            <TableCell
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              key={column.id}
              style={{
                minWidth: column.width,
                maxWidth: column.width,
                width: column.notFixed ? "auto" : column.width, padding: "0 15px"
              }}
              className={clsx(classes.tableCell, { [classes.multiLine]: column.multiLine, [classes.showWhiteSpace]: column.id === "time" })}
              align={"left"}
              // size="small"
              onDoubleClick={() => getMoreInfo(row.alertUnitId)}
            >
              {row.errorCode}
            </TableCell>
            </LightTooltip>
            :
            (
              <TableCell
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                key={column.id}
                style={{
                  minWidth: column.id === "systemName" && dashboardUnit ? column.dashboardUnitWidth : column.width,
                  maxWidth: column.width,
                  width: column.notFixed ? "auto" : column.width, padding: "0 15px"
                }}
                className={clsx(classes.tableCell, { [classes.multiLine]: column.multiLine, [classes.showWhiteSpace]: column.id === "time" })}
                align={"left"}
                // size="small"
                onDoubleClick={displayFlags.enableUnitDiagnostics ? () => getMoreInfo(row.alertUnitId) : () => { }}
              >
                {column.multiLine
                  ? value && value.length > 30
                    ? value.substr(0, 30) +
                    "\u2026"
                    : value
                  : // : column.id === 'unit' && value.length > 10
                  // 	? value.slice(0, 10)
                  column.format && typeof value === "number"
                    ? column.format(value)
                    : value}
              </TableCell>
            );
        })}
      </TableRow>
    </>
  );
}
