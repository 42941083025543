import { Action, action, actionOn, ActionOn, debug, thunk, Thunk } from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface IFeaturesModel {
  canViewManagementApp: boolean;
  canViewManagementAppSettings: boolean;
  initialize: Thunk<IFeaturesModel, any /*payload*/, any /*injections*/, IRootStoreModel>;
  onInitialized: ActionOn<IFeaturesModel, IRootStoreModel>;
}

export const featuresModel: IFeaturesModel = {
  canViewManagementApp: false,
  canViewManagementAppSettings: false,
  initialize: thunk(async (actions, payload, store) => {
    // SDK API call
    // console.log(
    //   'store.',
    //   debug(store.getState().canViewManagementApp),
    //   debug(store.getStoreState().sites)
    // );
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      // console.log(target.resolvedTargets);
      // console.log('Got features: ', debug(state));
    }
  )
};
