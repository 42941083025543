import {
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  TextField,
  Typography
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import {Check, Close} from "../../icons";
import { SearchIcon } from "../../svgComponents";
import useStyles, { paperStyle } from "./lookup.style";

const alertTypesItems: any = {
"System Error": <div style={{background: "#ff0000", height: 9, width: 9, borderRadius: "50%", marginRight: 15}}></div>,
"Indoor Error": <div style={{background: "#ff78ff", height: 9, width: 9, borderRadius: "50%", marginRight: 15}}></div>,
Maintenance: <div style={{background: "#fcbc00", height: 9, width: 9, borderRadius: "50%", marginRight: 15}}></div>,
Communications: <div style={{background: "#35a8e0", height: 9, width: 9, borderRadius: "50%", marginRight: 15}}></div>,
Anomalies: <div style={{background: "#9f45e4", height: 9, width: 9, borderRadius: "50%", marginRight: 15}}></div>,
"": <div style={{background: "green", height: 9, width: 9, borderRadius: "50%", marginRight: 15}}></div>
};

export default function ErrorBox(props: any) {
  const classes = useStyles();
  const { onApply, filtersList = {}, onClose, appliedFilters = {}, title = t`Filters`} = props;

  const [selectedTab, setSelectedTab] = useState<string>(Object.keys(filtersList)[0] || "");
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = React.useState<any>(appliedFilters);
  const [selectAll, setSelectAll] = useState<boolean>(selectedFilters.length === 0);
  const [filteredOutValues, setFilteredOutValues] = useState<any>({});

  const handleSearchValueChange = (event: any) => {
      const {target: {value}} = event;
      setSearchValue(value);
      if (!searchValue){
        setFilteredOutValues({});
      }
      updateFilterOutValues(value, selectedTab);
    };

  const updateFilterOutValues = (value: string, selectedTab: string) => {
    const selectedTabData = filtersList[selectedTab];
    const filteredOutValues: any = {};
    const toLowerCaseSearchValue = value.toLowerCase();

    Object.keys(selectedTabData).forEach((key: string) => {
      const toLowerCaseKey = key.toLowerCase();
      if (!toLowerCaseKey.includes(toLowerCaseSearchValue)){
          filteredOutValues[key] = true;
        }
      });

    setFilteredOutValues(filteredOutValues);
  };

  const cancelSearch = () => {
      setSearchValue("");
      setFilteredOutValues({});
    };

  const handleFilters = (value: string) => {

      if (selectedFilters[selectedTab][value]){
        delete selectedFilters[selectedTab][value];
      }else {
        selectedFilters[selectedTab][value] = true;
      }

      setSelectedFilters({...selectedFilters});
    };

  const handleTab = (tabName: string) => {
    setSelectedTab(tabName);
    if (!searchValue){
        return;
      }

    updateFilterOutValues(searchValue, tabName);
};

  return (
    <div className={classes.filtersPopup}>
    <div className={classes.filtersContainer}>
    <div className={classes.menuHeader}>
    <Typography className={classes.mainTitle}>{title}</Typography>
    <IconButton className={classes.iconBtn} onClick={onClose}><Clear /></IconButton>
    </div>
    <div className={classes.tabContainer}>
    {Object.keys(filtersList).map((tabName: string) =>
      <Typography
      key={`tab-name-${tabName}`}
      onClick={() => handleTab(tabName)}
      className={clsx(classes.tabTitle, {[classes.selectedTab]: selectedTab === tabName})}>
      {tabName}
      </Typography>
    )}
    </div>
    <div className={classes.searchBox}>
                    <Input
                      placeholder="Search"
                      value={searchValue}
                      onChange={handleSearchValueChange}
                      disableUnderline={true}
                      classes={{ root: classes.inputRoot }}
                      endAdornment={
                        searchValue ?  (
                          <IconButton disableRipple onClick={cancelSearch} className={classes.closeIconStyle}>
                            <Close color="#7f7692" />
                          </IconButton>) : (
                            <InputAdornment position="start">
                              <SearchIcon className={classes.searchIcon} />
                            </InputAdornment>
                          )
                      }
                    />
      </div>
      <List disablePadding={true} className={classes.optionsContainer}>
        {_.isEmpty(filtersList[selectedTab]) && (<Typography className={classes.noValuesText}>{t`No Values to select`}</Typography>)}
        {Object.keys(filtersList[selectedTab] || []).map((option: string, index: number) => (
          filteredOutValues[option] ? null : <ListItem className={classes.listItemStyle} dense autoFocus divider key={index} onClick={() => handleFilters(option)}>
            {selectedTab === "type" && alertTypesItems[option]}
            <ListItemText id={`${index}`} primary={option} className={classes.optionTextStyle}/>
          {selectedFilters[selectedTab][option] && <Check />}
          </ListItem>
        ))}
      </List>
      </div>
      <Button
        className={classes.applyBtn}
        onClick={() => onApply(appliedFilters)}
        uppercase
      >{t`Apply Filters`}</Button>
    </div>
  );
}
