import { Unit as UnitSdk } from "coolremote-sdk";
import { differenceInDays } from "date-fns/esm";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  memo,
  thunk,
  Thunk,
} from "easy-peasy";
import _ from "lodash";
import { SetStateAction } from "react";
import { IAlert } from "./Alerts";
import { IRootStoreModel } from "./RootStore";

export interface IUnit {
  id: string;
  type: number;
  name: string;
  ambientTemperature?: number | 0;
  activeOperationMode: number | -1;
  activeOperationStatus: number | -1;
  activeSetpoint?: number | 0;
  activeFanMode?: number | -1;
  activeLouverModes?: number | -1;
  device: string;
  zone: string | null;
  system: string | null;
  internalId: string;
  proId: string | null;
  line: number /* from the first 2 chars of unit name: L1, L2, etc */;
  privateId: string /* second part of unit name, after '.': '000', '003' etc */;
  role: any;
  isConnected?: boolean;
  supportedFanModes: number[];
  supportedSwingModes: number[];
  supportedOperationModes: number[];
  supportedOperationStatuses: number[];
  temperatureLimits: any;
  compressors?: string[];
  task: number;
  capacity?: number;
  capacityMeasurementUnits?: number;
  airnet?: number;
  model?: string;
  serialNumber?: string;
  site?: string;
  controlUnit?: string;
  serviceUnits?: string[];
  address?: string;
  activeSwingMode: any;
  groups: any;
  zones: any;
  schedules: string[];
  isVisible?: boolean;
  enableOnoff?: boolean;
  enableMode?: boolean;
  enableSetpoint?: boolean;
  enableOnState?: boolean;
  enableCoolMode?: boolean;
  enableHeatMode?: boolean;
  enableAutoMode?: boolean;
  eWrcTemperatureLimits?: any;
  eWrcEnableOnoff?: boolean;
  eWrcEnableMode?: boolean;
  eWrcEnableSetpoint?: boolean;
  eWrcEnableCoolMode?: boolean;
  eWrcEnableHeatMode?: boolean;
  eWrcEnableAutoMode?: boolean;
  eWrcEnableOnState?: boolean;
  eWrcDisable?: boolean;
}

export interface IUnitMap {
  [key: string]: IUnit;
}

export interface IUnitAffiliation {
  customerId?: string;
  siteId?: string;
  systemId?: string;
  deviceId?: string;
}

export interface IGetUnitStatsParams {
  unitId: string;
  startTime: number;
  endTime: number;
  withQuality?: boolean;
}

export interface IGetUnitStatsResult {
  unitSupportedParams: any[];
  unitProStats: { ranges: any; results: any[] };
  unitBasicStats: any[];
  updateTime: any;
}
interface IUpdatePayload {
  unitId: string;
  data: any;
}
interface IPowerPayload {
  unitId: string;
  state: number;
}
export type IEntityType = "customer" | "site" | "system";
export type IUnitType = "all" | "indoor" | "outdoor" | "service";
export interface IUnitsModel {
  allUnits: IUnitMap;
  changePowerState: Thunk<IUnitsModel, IPowerPayload>;
  setUnitActiveSetpoint: Thunk<IUnitsModel, IUpdatePayload>;
  getUnitAffiliation: Computed<
    IUnitsModel,
    (unitId: string) => IUnitAffiliation,
    IRootStoreModel
  >;
  getUnitsBy: Computed<
    IUnitsModel,
    (
      entityType: IEntityType,
      entityId: string | null,
      options?: { type: IUnitType }
    ) => IUnit[],
    IRootStoreModel
  >;
  getUnitsByWithHiddenUnits: Computed<
    IUnitsModel,
    (
      entityType: IEntityType,
      entityId: string | null,
      options?: { type: IUnitType }
    ) => IUnit[],
    IRootStoreModel
  >;
  getServiceUnitsBy: Computed<
    IUnitsModel,
    (
      entityType: IEntityType,
      entityId: string | null,
      options?: { type: IUnitType }
    ) => IUnit[],
    IRootStoreModel
  >;
  updateLocalSetpoint: Action<IUnitsModel, { id: string; setpoint: any }>;

  countUnitsFor: Computed<
    IUnitsModel,
    (entityType: IEntityType, entityId: string) => number,
    IRootStoreModel
  >;
  getUnit: Computed<
    IUnitsModel,
    (unitId: string | null | undefined) => IUnit | undefined,
    IRootStoreModel
  >;
  getUnitAlerts: Computed<
    IUnitsModel,
    (unitId: string) => IAlert[],
    IRootStoreModel
  >;
  getUnitSystemBrandName: Computed<
    IUnitsModel,
    (unitId: string) => string | null,
    IRootStoreModel
  >;
  getUnitSystemBrand: Computed<
    IUnitsModel,
    (unitId: string) => number | null,
    IRootStoreModel
  >;
  getUnitByInternalOrProId: Computed<
    IUnitsModel,
    (internalOrProId: string) => IUnit | null
  >;
  isItMe: Computed<
    IUnitsModel,
    (unit: IUnit, internalOrProId: string) => boolean
  >;
  getUnitName: Computed<
    IUnitsModel,
    (unitId: string | null | undefined) => string,
    IRootStoreModel
  >;
  getUnitType: Computed<
    IUnitsModel,
    (unitId: string | null | undefined) => "Indoor" | "Outdoor" | "-",
    IRootStoreModel
  >;
  // getUnitProSupportedServiceParams: Thunk<
  //   IUnitsModel, /* types for actions */
  //   string, /* payload : unitId */
  //   any, /* injections */
  //   IRootStoreModel, /* types for getStoreState/getStoreActions */
  //   any /* result */
  // >;
  getUnitStats: Thunk<
    IUnitsModel,
    IGetUnitStatsParams /* payload : unitId */,
    any /* injections */,
    IRootStoreModel /* types for getStoreState/getStoreActions */,
    any /* result */
  >;

  initialize: Action<IUnitsModel, any>;
  onInitialized: ActionOn<IUnitsModel, IRootStoreModel>;

  updateUnit: Thunk<IUnitsModel, { id: string; updatedData: any }>;
  getAndReplaceUnits: Thunk<IUnitsModel, any>;
  updateUnits: Thunk<IUnitsModel, { updatedData: any }>;
  updateUnitsAndSchedules: Thunk<IUnitsModel, { updatedData: any }>;

  getUnits: Thunk<
    IUnitsModel,
    any /* payload : unitId */,
    any /* injections */,
    IRootStoreModel /* types for getStoreState/getStoreActions */,
    any
  >;
  cleanFilterUnit: Thunk<IUnitsModel, { id: string }>;
  refreshUnit: Thunk<IUnitsModel, { id: string }>;
  storeUpdateUnitsGroup: Action<IUnitsModel, { unitId: string; group: any, add: boolean }>;
  storeUpdateUnitsZones: Action<IUnitsModel, { unitId: string; zone: any, add: boolean }>;
  _storeCreateUnit: Action<IUnitsModel, { id: string; data: IUnit }>;
  _storeUpdateUnit: Action<IUnitsModel, { id: string; data: Partial<IUnit> }>;
  _storeDeleteUnit: Action<IUnitsModel, { id: string }>;

  setUnitSystem: Action<IUnitsModel, { id: string; systemId: any }>;
  setUnitName: Action<IUnitsModel, { id: string; unitName: any }>;

  requestStatsRefresh: Thunk<IUnitsModel, { id: string }>;
  setActiveSetpoint: Thunk<IUnitsModel, { id: string; setpoint: any }>;
  setActiveOperationMode: Thunk<IUnitsModel, { id: string; mode: any }>;
  setUnitActiveOperationMode: Thunk<IUnitsModel, IUpdatePayload>;
  togglePower: Thunk<IUnitsModel, { id: string; activeOperationStatus: any }>;
  setActiveFanMode: Thunk<IUnitsModel, IUpdatePayload>;
  setUnitActiveFanMode: Thunk<IUnitsModel, IUpdatePayload>;
  setActiveSwingMode: Thunk<IUnitsModel, IUpdatePayload>;
  setUnitActiveSwingMode: Thunk<IUnitsModel, IUpdatePayload>;
  setActiveOperationStatus: Thunk<IUnitsModel, IUpdatePayload>;
  setUnitActiveOperationStatus: Thunk<IUnitsModel, IUpdatePayload>;
  getUnitInfo: Computed<IUnitsModel, (id: string) => any, IRootStoreModel>;
  setParamValue: Thunk<IUnitsModel, { id: string; serviceParamCode: any; value: any; }>;
  getUnitTypeByTypes: Computed<
    any,
    (unitId: string | null | undefined) => string,
    IRootStoreModel
  >;
  setEWrcLock: Thunk<IUnitsModel, { id: string; data: any }>;
  toggleEWrcLock: Thunk<IUnitsModel, { units: any; isEWrcDisable: boolean }>;
  setManyEWrcLocks: Thunk<IUnitsModel, { data: any }>;
  getEWrcLock: Thunk<
    IUnitsModel,
    { id: string },
    any /* injections */,
    IRootStoreModel /* types for getStoreState/getStoreActions */,
    any /* result */
  >;
  fetchUnitStats: Thunk<IUnitsModel, { unitId: string; startTime: number; endTime: number; size: number; }>;
  fetchUnitDiagByParams: Thunk<IUnitsModel, { unitId: string; startTime: number, endTime: number, params: string[] }>;
}

export const unitsModel: IUnitsModel = {
  allUnits: {},

  initialize: action((state, payload) => {
    state.allUnits = payload;
  }),

  getUnits: thunk(async (actions, state) => {
    const allUnits = await UnitSdk.getUnits();
    actions.initialize(allUnits);
  }),
  getAndReplaceUnits: thunk(async (actions, state) => {
    const allUnits = await UnitSdk.getUnits();
    actions.initialize(allUnits);
  }),
  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
    }
  ),
  setParamValue: thunk(async (actions, payload) => {
    const data = await UnitSdk.setParamValue(payload.id, payload.value, payload.serviceParamCode);
    return data;
  }),
  setEWrcLock: thunk(async (actions, payload) => {
    const data = await UnitSdk.setEWrcLock(payload.id, payload.data);
    return data;
  }),
  toggleEWrcLock: thunk(async (actions, payload) => {
    const data = await UnitSdk.toggleEWrcLocks(payload);
    return data;
  }),
  setManyEWrcLocks: thunk(async (actions, payload) => {
    const data = await UnitSdk.setManyEWrcLocks(payload.data);
    return data;
  }),
  getEWrcLock: thunk(async (actions, payload) => {
    const data = await UnitSdk.getEWrcLock(payload.id);
    return data;
  }),
  getUnitTypeByTypes: computed([(state) => state.allUnits, (state, storeState: any) => storeState.types.unitTypes], (allUnits, unitTypesMirrror) =>
    memo((unitId) => {
      if (_.isNil(unitId)) { return "-"; }
      return unitTypesMirrror[allUnits[unitId]?.type || ""] || "-";
    }, 100)
  ),
  getUnitInfo: computed(
    [
      (state) => state.allUnits,
      (state, storeState) => storeState.systems.allSystems,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.customers.allCustomers
    ],
    (allUnits, allSystems, allDevices, allSites, allCustomers) =>
      memo((id) => {
        const unit = allUnits[id];
        let system;
        if (unit.system) {
          system = allSystems[unit.system];
        }
        const unitDevice = allDevices[unit.device];
        const unitSite = allSites[unitDevice.site];
        const unitCustomer = allCustomers[unitSite.customer];

        return {
          unit,
          system,
          device: unitDevice,
          site: unitSite,
          customer: unitCustomer
        };
      }, 100)
  ),

  getUnitAffiliation: computed(
    [
      (state) => state.allUnits,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.devices.allDevices
    ],
    (allUnits, allSites, allDevices) =>
      memo((unitId) => {
        const emptyObj = {
          customerId: "",
          siteId: "",
          systemId: "",
          deviceId: ""
        };
        const aff = {} as IUnitAffiliation;
        if (!unitId) {
          return emptyObj;
        }
        const unit = allUnits[unitId];
        if (!unit) {
          return emptyObj;
        }
        aff.systemId = unit?.system || undefined;

        const unitDevice = allDevices[unit.device];
        if (_.isUndefined(unitDevice)) { return aff; }
        aff.deviceId = unitDevice.id;

        const unitSite = allSites[unitDevice.site];
        if (!_.isUndefined(unitSite)) {
          aff.siteId = unitSite.id;
          aff.customerId = unitSite.customer;
        }

        return aff;
      }, 100)
  ),
  getUnit: computed([(state) => state.allUnits], (allUnits) =>
    memo((unitId) => {
      if (_.isNil(unitId)) { return undefined; }
      return allUnits[unitId];
    }, 100)
  ),
  getUnitsBy: computed(
    [(state) => state.allUnits, (state) => state.getUnitAffiliation],
    (allUnits, getUnitAffiliation) =>
      memo((entityType, entityId, options = { type: "all" }) => {
        // entityId of undefined, null or '' gets all units
        if (_.isNil(entityId) || entityId.length === 0) {
          return Object.values(allUnits);
        } else {
          return Object.values(allUnits).filter((unit) => {
            // Apply filter from options
            // Unit types: 2 - indoor service, 2 - outdoor
            if (!unit.isVisible) {
              return false;
            }
            if (
              options?.type &&
              ((options.type === "indoor" && unit.type !== 1) ||
                (options.type === "outdoor" && unit.type !== 2) ||
                (options.type === "service" && unit.type !== 3))
            ) {

              return false;
            }
            // Apply filter by entity type
            const unitAff = getUnitAffiliation(unit.id);

            switch (entityType) {
              case "customer":
                return entityId === unitAff.customerId;
              case "site":
                return entityId === unitAff.siteId;
              case "system":
                return entityId === unitAff.systemId;
            }
          });
        }
      }, 100)
  ),

  getUnitsByWithHiddenUnits: computed(
    [(state) => state.allUnits, (state) => state.getUnitAffiliation],
    (allUnits, getUnitAffiliation) =>
      memo((entityType, entityId, options = { type: "all" }) => {
        // entityId of undefined, null or '' gets all units
        if (_.isNil(entityId) || entityId.length === 0) {
          return Object.values(allUnits);
        } else {
          return Object.values(allUnits).filter((unit) => {
            // Apply filter from options
            // Unit types: 2 - indoor service, 2 - outdoor
            if (
              options?.type &&
              ((options.type === "indoor" && unit.type !== 1) ||
                (options.type === "outdoor" && unit.type !== 2) ||
                (options.type === "service" && unit.type !== 3))
            ) {

              return false;
            }
            // Apply filter by entity type
            const unitAff = getUnitAffiliation(unit.id);

            switch (entityType) {
              case "customer":
                return entityId === unitAff.customerId;
              case "site":
                return entityId === unitAff.siteId;
              case "system":
                return entityId === unitAff.systemId;
            }
          });
        }
      }, 100)
  ),

  getServiceUnitsBy: computed(
    [(state) => state.allUnits, (state) => state.getUnitAffiliation],
    (allUnits, getUnitAffiliation) =>
      memo((entityType, entityId, options = { type: "all" }) => {
        // entityId of undefined, null or '' gets all units
        if (_.isNil(entityId) || entityId.length === 0) {
          return Object.values(allUnits);
        } else {
          return Object.values(allUnits).filter((unit) => {
            // Apply filter from options
            // Unit types: 2 - indoor service, 2 - outdoor
            if (
              options?.type &&
              ((options.type === "indoor" && unit.type !== 1) ||
                (options.type === "outdoor" && unit.type !== 2))
            ) {

              return false;
            }
            // Apply filter by entity type
            const unitAff = getUnitAffiliation(unit.id);
            switch (entityType) {
              case "customer":
                return entityId === unitAff.customerId;
              case "site":
                return entityId === unitAff.siteId;
              case "system":
                return entityId === unitAff.systemId;
            }
          });
        }
      }, 100)
  ),

  countUnitsFor: computed(
    [
      (state) => state.allUnits,
      (state) => state.getUnitAffiliation,
      (state, storeState) => storeState.types.allTypes
    ],
    (allUnits, getUnitAffiliation, { unitTypes }) =>
      memo((entityType, entityId) => {
        const controlType = unitTypes["indoor"];
        return Object.values(allUnits).filter((unit) => {
          const unitAff = getUnitAffiliation(unit.id);
          switch (entityType) {
            case "customer":
              return unit.type === controlType && entityId === unitAff.customerId;
            case "site":
              return unit.type === controlType && entityId === unitAff.siteId;
            case "system":
              return unit.type === controlType && entityId === unitAff.systemId;
          }
        }).length;
      }, 100)
  ),

  getUnitAlerts: computed(
    [
      (state) => state.allUnits,
      (state) => state.isItMe,
      (state, storeState) => storeState.alerts.allAlerts
    ],
    (allUnits, isItMe, allAlerts) =>
      memo((unitId) => {
        const unit = allUnits[unitId];
        return Object.values(allAlerts).filter(
          (alert) =>
            _.has(alert, "data.eventData.unitId") &&
            alert.data.eventData.unitId &&
            isItMe(unit, alert.data.eventData.unitId)
        );
      }, 100)
  ),

  getUnitSystemBrandName: computed(
    [
      (state) => state.allUnits,
      (state, storeState) => storeState.systems.allSystems
    ],
    (allUnits, allSystems) =>
      memo((unitId) => {
        const unit = allUnits[unitId];

        if (_.isUndefined(unit)) {
          return null;
        }
        if (_.isNil(unit.system)) {
          return null;
        }

        const system = allSystems[unit.system];

        if (_.isNil(system)) {
          return null;
        }
        if (_.isNil(system.name)) {
          return null;
        }

        return system.name;
      }, 100)
  ),
  getUnitSystemBrand: computed(
    [
      (state) => state.allUnits,
      (state, storeState) => storeState.systems.allSystems
    ],
    (allUnits, allSystems) =>
      memo((unitId) => {
        const unit = allUnits[unitId];
        if (_.isUndefined(unit)) {
          return null;
        }
        if (_.isNil(unit.system)) {
          return null;
        }

        const system = allSystems[unit.system];

        if (_.isNil(system)) {
          return null;
        }
        if (_.isNil(system.brandNum)) {
          return null;
        }
        return system.brandNum;
      }, 100)
  ),

  getUnitByInternalOrProId: computed(
    [(state) => state.allUnits],
    (allUnits) =>
      memo((internalOrProId) => {
        let foundUnit: IUnit | null = null;

        // Internal ID first!
        for (let unitId in allUnits) {
          const unit: IUnit = allUnits[unitId];

          if (unit.internalId === internalOrProId) {
            foundUnit = unit;
            break;
          }
        }

        // If not found by internal ID, search by pro ID.
        if (_.isNull(foundUnit)) {
          for (let unitId in allUnits) {
            const unit: IUnit = allUnits[unitId];

            if (
              !_.isNil(unit.proId) &&
              !_.isEmpty(unit.proId) &&
              internalOrProId &&
              internalOrProId.indexOf(":") !== -1 &&
              internalOrProId.split(":")[1].substr(0, 6) === unit.proId
            ) {
              foundUnit = unit;
              break;
            }
          }
        }

        return foundUnit;
      }, 100) // 10000
  ),

  isItMe: computed([(state) => state.allUnits], (allUnits) =>
    memo((unit, internalOrProId) => {
      if (unit.internalId === internalOrProId) {
        return true;
      }

      if (
        !_.isNil(unit.proId) &&
        !_.isEmpty(unit.proId) &&
        internalOrProId.indexOf(":") !== -1 &&
        internalOrProId.split(":")[1].substr(0, 6) === unit.proId
      ) {
        return true;
      }

      return false;
    }, 100)
  ),

  getUnitStats: thunk(async (actions, { unitId, startTime, endTime, withQuality = false }, state) => {
    let unitSupportedParams: any = [];
    let unitProStats = { ranges: {}, results: [] };
    let updateTime: any;
    const serviceParams = state.getStoreState().serviceParams;

    let promises: any = [];

    // unitSupportedParams = res.supportedColumns;
    // updateTime = res.updateTimestamp;
    return UnitSdk.getProSupportedServiceParams(unitId)
      .then((res: any) => {
        unitSupportedParams = res.supportedColumns;
        updateTime = res.updateTimestamp;

        if (unitSupportedParams && unitSupportedParams.length) {
          // const unitSupportedParamsCodes = _.map(unitSupportedParams, (param) => param.code);

          const unitSupportedParamsCodes = unitSupportedParams.reduce((filtered: any, param: any) => {
            if (param?.code && serviceParams[param.code]?.plotable) {
              filtered.push(param.code);
            }
            //plotable
            return filtered;
          }, []);

          // Period to get stats for
          let granularity = "minute";
          if (unitSupportedParamsCodes.length) {
            promises.push(
              UnitSdk.getProStats(unitId, startTime, endTime, granularity, withQuality, unitSupportedParamsCodes)
            );
          }
        }

        return Promise.all(promises).then((resp: any) => {
          if (resp[0]) {
            unitProStats = resp[0];
          }
        });
      })
      .then(() => {
        return {
          unitSupportedParams,
          unitProStats,
          updateTime
        };
      });
  }),

  setUnitName: action((state, payload) => {
    state.allUnits[payload.id].name = payload.unitName;
  }),

  setUnitSystem: action((state, payload) => {
    state.allUnits[payload.id].system = payload.systemId;
  }),

  requestStatsRefresh: thunk(async (actions, payload) => {
    await UnitSdk.requestStatsRefresh(payload.id);
  }),
  updateLocalSetpoint: action((state, payload) => {
    state.allUnits = { ...state.allUnits, [payload.id]: { ...state.allUnits[payload.id], activeSetpoint: payload.setpoint } };
  }),
  setActiveSetpoint: thunk((actions, payload) => {
    UnitSdk.setActiveSetpoint(payload.id, payload.setpoint)
      .then(() => {
        actions.updateLocalSetpoint(payload);
      });
  }),

  setActiveOperationMode: thunk(async (actions, payload) => {
    await UnitSdk.setActiveOperationMode(payload.id, payload.mode);
    actions._storeUpdateUnit({
      id: payload.id,
      data: { activeOperationMode: payload.mode }
    });
  }),
  setUnitActiveOperationMode: thunk((actions, payload) => {
    const { unitId, data: mode } = payload;
    return UnitSdk.setActiveOperationMode(unitId, +mode as number);
  }),
  setActiveOperationStatus: thunk(async (actions, payload) => {
    const { unitId, data: operationStatus } = payload;
    actions._storeUpdateUnit({
      id: unitId,
      data: { activeOperationStatus: operationStatus }
    });
    return UnitSdk.setActiveOperationStatus(
      unitId,
      +operationStatus as number
    );
  }),
  setUnitActiveOperationStatus: thunk(async (actions, payload) => {
    const { unitId, data: operationStatus } = payload;
    return UnitSdk.setActiveOperationStatus(
      unitId,
      +operationStatus as number
    );
  }),
  setActiveSwingMode: thunk(async (actions, payload) => {
    const { unitId, data: mode } = payload;
    actions._storeUpdateUnit({
      id: unitId,
      data: { activeSwingMode: mode }
    });
    return UnitSdk.setActiveSwingMode(unitId, +mode as number);
  }),
  setUnitActiveSwingMode: thunk(async (actions, payload) => {
    const { unitId, data: mode } = payload;
    return UnitSdk.setActiveSwingMode(unitId, +mode as number);
  }),
  setActiveFanMode: thunk(async (actions, payload) => {
    const { unitId, data: mode } = payload;
    actions._storeUpdateUnit({
      id: unitId,
      data: { activeFanMode: mode }
    });
    return UnitSdk.setActiveFanMode(unitId, +mode as number);
  }),
  setUnitActiveFanMode: thunk(async (actions, payload) => {
    const { unitId, data: mode } = payload;
    return UnitSdk.setActiveFanMode(unitId, +mode as number);
  }),
  changePowerState: thunk((actions, payload, { injections }) => {
    const { unitId, state } = payload;
    return UnitSdk.setActiveOperationStatus(unitId, state);
  }),
  setUnitActiveSetpoint: thunk((actions, payload) => {
    const { unitId, data: setpoint } = payload;
    return UnitSdk.setActiveSetpoint(unitId, +setpoint as number);
  }),
  togglePower: thunk(async (actions, payload) => {
    const toggleStates = [0, 2, 1];
    await UnitSdk.setActiveOperationStatus(
      payload.id,
      toggleStates[payload.activeOperationStatus]
    );
  }),

  updateUnit: thunk(async (actions, payload) => {
    const data = await UnitSdk.update(payload.id, payload.updatedData);

    // Why call _storeCreateUnit()? Because it assigns the answer as-is.
    actions._storeCreateUnit({ id: data.id, data });
  }),
  updateUnits: thunk(async (actions, payload) => {
    if (payload.updatedData.unitIds.length) {
      const data = await UnitSdk.updateUnits(payload.updatedData);
      const units: IUnit[] = Object.values(data);
      for (let unit of units) {
        actions._storeCreateUnit({ id: unit.id, data: unit });
      }
    }

  }),

  updateUnitsAndSchedules: thunk(async (actions, payload) => {
    if (payload.updatedData.unitIds.length) {
      const data = await UnitSdk.updateUnitsAndSchedules(payload.updatedData);
      const units: IUnit[] = Object.values(data);
      for (let unit of units) {
        actions._storeCreateUnit({ id: unit.id, data: unit });
      }
    }

  }),
  refreshUnit: thunk(async (actions, payload) => {
    const data = await UnitSdk.fetch(payload.id);
    actions._storeCreateUnit({ id: data.id, data });
    return data;
  }),

  cleanFilterUnit: thunk(async (actions, payload) => {
    await UnitSdk.clearFilter(payload.id);
  }),

  // update groups (used by groups.ts)
  storeUpdateUnitsGroup: action((state, payload) => {
    const unit = state.allUnits[payload.unitId];
    if (payload.add) {

      if (unit.groups[payload.group.id]) {
        delete unit.groups[payload.group.id];
      }

      unit.groups[payload.group.id] = payload.group;
    } else {
      delete unit.groups[payload.group.id];
    }
  }),
  storeUpdateUnitsZones: action((state, payload) => {
    const unit = state.allUnits[payload.unitId];
    if (payload.add) {

      if (unit.zones[payload.zone.id]) {
        delete unit.zones[payload.zone.id];
      }

      unit.zones[payload.zone.id] = payload.zone;
    } else {
      delete unit.zones[payload.zone.id];
    }
  }),
  _storeCreateUnit: action((state, payload) => {
    if (state.allUnits[payload.id]){
      state.allUnits[payload.id] = {...state.allUnits[payload.id], ...payload.data};
      return;
    }
    state.allUnits[payload.id] = payload.data;
  }),

  _storeUpdateUnit: action((state, payload) => {
    if (state.allUnits[payload.id]) {
      _.assign(state.allUnits[payload.id], payload.data);
    }
  }),

  _storeDeleteUnit: action((state, payload) => {
    delete state.allUnits[payload.id];
  }),

  getUnitName: computed([(state) => state.allUnits], (allUnits) =>
    memo((unitId) => {
      if (_.isNil(unitId)) { return "-"; }
      return allUnits[unitId]?.name;
    }, 100)
  ),

  getUnitType: computed([(state) => state.allUnits], (allUnits) =>
    memo((unitId) => {
      if (_.isNil(unitId)) { return "-"; }
      return allUnits[unitId]?.type === 2 ? "Outdoor" : "Indoor";
    }, 100)
  ),
  fetchUnitStats: thunk((actions, payload) => {
    const { unitId, startTime, endTime, size } = payload;
    return UnitSdk.getUnitStats(unitId, startTime, endTime, size);
  }),
  fetchUnitDiagByParams: thunk((actions, payload) => {
    const { unitId, startTime, endTime, params } = payload;
    return UnitSdk.getUnitStatsByParams(unitId, startTime, endTime, params);
  })
};
