import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import { Redirect } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { t } from "ttag";
import * as Yup from "yup";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import "../../icons.css";
import { useStoreActions } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import InputFieldPassword from "./InputFieldPassword";
import useStyles from "./Login.style";

interface IResetState {
    token: string;
    password: string;
    verifyPassword: string;
    error?: string;
}

const ResetPasswordSchema = Yup.object().shape({
    token: Yup.string().required("Required"),
    password: Yup.string()
        .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, "must be at least 1 capital letter and 1 digit")
        .required("Required"),
    verifyPassword: Yup.string()
        .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, "must be at least 1 capital letter and 1 digit")
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required")
});
const ResetPassword: React.FC<any> = (props) => {
    const [isPasswordSet, setIsPasswordSet] = useState(false);
    const [isLoginBack, setIsLoginBack] = useState(false);
    const resetPassword = useStoreActions((action) => action.users.resetPassword);
    const [error, setError] = useState<any>(null);
    const errorBox = error ? <ErrorBox error={error} onClose={() => setError(null)} /> : null;
    const classes = useStyles();
    const match = useRouteMatch<{ token: string }>();
    let token: string = match.params.token;
    //console.log(token);
    //console.log(match);

    const initialValues: IResetState = {
        token,
        password: "",
        verifyPassword: ""
    };
    const handleBack = () => {
        setIsLoginBack(true);
    };

    // const handleEnter = (event: any) => {
    //     const code = event.keyCode || event.which;
    //     if (code === 13) {
    //         handleSetPassword();
    //     }
    // };
    const handleSetPassword = (values: IResetState, actions: any) => {
        resetPassword({ token: values.token, password: values.password })
            .then(() => setIsPasswordSet(true))
            .catch((err: any) => {
                setError(err.message);
            });
    };
    if (isLoginBack) {
        return <Redirect to={"/"} />;
    }
    if (isPasswordSet) {
        return <Redirect to={"/reset-success"} />;
    }

    return (
        <div className={classes.Login}>
            <div className={classes.main}>
                <div className={classes.logos}>
                    <img
                        alt="logo"
                        src={assetsService.getResourcePath("logo.png")}
                        className={classes.logo}
                    />
                </div>
                {
                    <div className={classes.interactionSection}>
                        <div className={classes.content}>
                            <Typography className={classes.title}>{t`Password Recovery`}</Typography>
                            {errorBox}
                            <Typography
                                variant="body2"
                                style={{ color: "rgb(255, 255, 255)", marginBottom: "1rem" }}
                            >{t`An email was sent to you containing your password reset details`}</Typography>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleSetPassword}
                                enableReinitialize={true}
                                validationSchema={ResetPasswordSchema}
                            >
                                {({ handleSubmit, errors, touched, values, ...restFormikProps }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container className={classes.container}>
                                            <Grid item className={classes.resetButtonContainer}>
                                                <Button
                                                    title="Back to Login"
                                                    className={classes.buttonReset}
                                                    onClick={handleBack}
                                                    uppercase
                                                    red
                                                >
                                                    {t`Back to Login`}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ResetPassword;
