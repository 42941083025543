import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel, Grid,
    IconButton, InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TableRow, TextField, Tooltip,
    Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import Delete from "../../components/Delete/Delete";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import SButton from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import SvgArrow from "../../icons/Arrow";
import ArrowIcon from "../../icons/BigArrowUp";
import { Add, CheckboxChecked, Close, Search } from "../../icons/index";
import SvgUser from "../../icons/User1";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IScheduledReport } from "../../models/ScheduledReports";
import NewApplySettings from "../Settings/NewApplySettings";
import useStyle from "./OneTenant.style";

const TenantList: React.FC = (props: any) => {
    const classes = useStyle();
    const getZonesBySiteId = useStoreActions((action) => action.zones.getZonesBySiteId);
    // const getSystemsBySiteId = useStoreActions((action) => action.systems.g);
    const getSystemsBySiteId = useStoreState((s) => s.systems.getSystemsBySite);

    const allCustomers = useStoreState((s) => s.customers.allCustomers);
    const allSites = useStoreState((s) => s.sites.allSites);
    const allTenants = useStoreState((s) => s.groups.getTenants);
    const allUnits = useStoreState((s) => s.units.allUnits);
    const scheduledReports = useStoreState((s) => s.scheduledReports.allReports);
    const createReport = useStoreActions((action) => action.scheduledReports.createReport);
    const updateReport = useStoreActions((action) => action.scheduledReports.updateReport);
    const getReport = useStoreActions((action) => action.scheduledReports.getReport);
    const removeReport = useStoreActions((action) => action.scheduledReports.removeReport);
    const getUnitsBy = useStoreState((s) => s.units.getUnitsBy);

    const selections = useStoreState((s) => s.selections.selections);
    const [siteZones, setSiteZones] = useState<any>([]);
    const [siteSystems, setSiteSystems] = useState<any>([]);

    const [zoneUnits, setZoneUnits] = useState<any>([]);
    const [chosenSite, setChosenSite] = useState<string>("");
    const [selectedReports, setSelectedReports] = useState<any>({});
    const [selectedReportUsers, setSelectedReportUsers] = useState<any[]>([]);

    const [allToggled, setAllToggled] = useState(false);

    const [showOneReport, setShowOneReport] = useState(false);
    const [oneReportName, setOneReportName] = useState("");
    const [format, setFormat] = useState<string>("csv");
    // const [selectedZone, setSelectedZone] = useState<string>('');
    const [granualirity, setGranualirity] = useState<string>("hour");
    const [searchVal, setSearchVal] = useState<string>("");
    const [timeUnit, setTimeUnit] = useState<string>("");
    const [timeUnitSubSelect, setTimeUnitSubSelect] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [userEmail, setUserEmail] = useState<string>("");
    const [showAddUser, setShowAddUser] = useState<boolean>(false);
    const [selectedReport, setSelectedReport] = useState<any>(null);
    const [showDeleteReport, setShowDeleteReport] = useState<boolean>(false);
    const [reportError, setReportError] = useState<string>("");
    const [reportUserErr, setReportUserErr] = useState<string>("");
    const [isMissingName, setIsMissingName] = useState<boolean>(false);
    const isInitialized = useStoreState((s) => s.isInitialized);
    const [zonesToSave, setZonesToSave] = useState<any>({});
    const [systemsToSave, setSystemsToSave] = useState<any>({});
    const [error, setError] = useState<string>("");

    // const [oneCustomers, setOneCustomers] = useState<any>(null);
    const [tenantId, setTenantId] = useState("");

    // Prevent unwanted renders - any new var in this comopnent - needs to be added her to get component to render
    const oneReportViewCallBack = useCallback(() => oneReportView(), [
        oneReportName,
        showOneReport,
        isMissingName,
        timeUnit,
        searchVal,
        granualirity,
        format,
        siteZones,
        timeUnitSubSelect,
        showAddUser,
        userEmail,
        selectedReport,
        selectedReportUsers,
        reportUserErr,
        reportError,
        zonesToSave,
        zoneUnits,
        siteSystems,
        systemsToSave,
        zonesToSave,
        // selectedZone,
        allToggled,
        error,
        userName]);

    let reportSchema = Yup.object().shape({
        name: Yup.string().required(),
        format: Yup.string().required(),
        granualirity: Yup.string().required(),
        timeUnit: Yup.string().required(),
        subTimeUnit: Yup.string().required()
        // siteId: Yup.string().required(),
    });

    let reportSchemaOnlyDay = Yup.object().shape({
        name: Yup.string().required(),
        format: Yup.string().required(),
        granualirity: Yup.string().required(),
        timeUnit: Yup.string().required()
        // siteId: Yup.string().required(),
    });

    let userSchema = Yup.object().shape({
        userName: Yup.string().required(),
        userEmail: Yup.string().email().required()
    });

    const fetchReports = async () => {
        if (!chosenSite) {
            return;
        }
        const reports = await getReport(chosenSite);
        setSelectedReports(reports);
    };

    // get reports on system selection
    useEffect(() => {
        fetchReports();
    }, [chosenSite]);

    //refresh the list on add report
    useEffect(() => {
        setSelectedReports(scheduledReports);
    }, [scheduledReports]);

    useEffect(() => {

        (async function() {
            if (selections.siteId) {
                setChosenSite(selections.siteId);
                const zones = await getZonesBySiteId(selections.siteId);
                setSiteZones(Object.values(zones));
                const systems = await getSystemsBySiteId(selections.siteId);
                setSiteSystems(Object.values(systems));

            }

        })();
    }, []);

    // useEffect(() => {
    //     let units: any = [];
    //     if (siteZones) {
    //         for (let zone of siteZones) {
    //             units = units.concat(zone.units);
    //         }
    //     }
    //     else {
    //         const siteUnits = getUnitsBy("site", selections.siteId, {
    //             type: "all"
    //         });
    //         if (siteUnits) {
    //             for (let unit of siteUnits) {
    //                 units.push(unit.id);
    //             }
    //         }
    //     }
    //     console.log(units)
    //     setZoneUnits(units);

    // }, [selectedZone]);

    // Load tenant list when list is ready
    useEffect(() => {
        (async function() {
            if (chosenSite) {
                const zones = await getZonesBySiteId(chosenSite);
                setSiteZones(Object.values(zones));
                const systems = await getSystemsBySiteId(chosenSite);
                setSiteSystems(Object.values(systems));
            }

        })();
    }, [selections.siteId]);

    if (!isInitialized) { return <Loading />; }

    const onFilterSelect = async (id: string | null, queryType: any) => {

        if (!id || queryType === "customer") {
            setChosenSite("");
            return;
        }

        if (queryType === "site") {
            setChosenSite(id);
            await getReport(id);
        }

    };
    const onSave = async () => {
        let systemsSelected: any = [];
        _.forEach(systemsToSave, function(value, key) {
            if (value) {
                systemsSelected.push(key);
            }
        });
        let zonesSelected: any = [];
        _.forEach(zonesToSave, function(value, key) {
            if (value) {
                zonesSelected.push(key);
            }
        });

        const data = {
            name: oneReportName,
            // siteId: chosenSite,
            users: selectedReportUsers,
            timeUnit,
            subTimeUnit: timeUnitSubSelect,
            format,
            granualirity,
            zones: zonesSelected,
            systems: systemsSelected
        };

        const isValid = timeUnit !== "day" ? await reportSchema.isValid(data) : await reportSchemaOnlyDay.isValid(data);

        if (!isValid) {
            try {
                if (timeUnit !== "day") {
                    await reportSchema.validate(data);
                } else {
                    await reportSchemaOnlyDay.validate(data);
                }
            } catch (e) {
                setReportError(e.path);
            }
            return;
        }
        if (!Object.keys(zonesToSave).length && !Object.keys(systemsToSave).length) {
            setError("Please select at least one system or zone");
            return;
        }

        if (!selectedReport) {
            createReport({ data, siteId: chosenSite });

        } else {
            updateReport({ data, reportId: selectedReport.id });
        }

        onCleanUp();
        setShowOneReport(false);

    };

    const onDelete = (reportId: any) => {
        // need to use 'then' as the <Delete> creates memory leak using async\await
        removeReport({ reportId: reportId.id }).then(() => {
            setShowDeleteReport(false);
            onCleanUp();
        });
    };

    const onCancle = () => {
        onCleanUp();
    };

    const onSelectReport = (report: IScheduledReport) => {
        setOneReportName(report.name);
        setFormat(report.format);
        setGranualirity(report.granualirity);
        setTimeUnit(report.timeUnit);
        setTimeUnitSubSelect(report.subTimeUnit);
        setSelectedReportUsers(report.users);
        if (report.zones) {
            let zones: any = {};
            for (let zone of report.zones) {
                zones[zone] = true;
            }
            setZonesToSave(zones);
        }
        if (report.systems) {
            let systems: any = {};
            for (let system of report.systems) {
                systems[system] = true;
            }
            setSystemsToSave(systems);
        }

        setShowDeleteReport(true);
        // Last action - to avoid rendering on unmounted component
        setShowOneReport(true);
        setSelectedReport(report);

    };

    const onCleanUp = () => {
        setShowDeleteReport(false);
        setSelectedReport(null);
        setOneReportName("");
        setFormat("csv");
        setGranualirity("hour");
        setTimeUnit("");
        setTimeUnitSubSelect("");
        setSelectedReportUsers([]);
        setZonesToSave({});
        setSystemsToSave({});
        setError("");
        setReportError("");
        // Last action - to avoid rendering on unmounted component
        setShowOneReport(false);
    };

    const onSaveUser = async () => {
        const newUser = { userName, userEmail };
        const isValid = await userSchema.isValid(newUser);

        if (!isValid) {
            try {
                await userSchema.validate(newUser);

            } catch (e) {
                setReportUserErr(e.path);
            }

            return;
        }

        setSelectedReportUsers([...selectedReportUsers, newUser]);
        setShowAddUser(false);
        setUserEmail("");
        setUserName("");
    };

    const deleteUser = (user: any) => {
        const email = user.id;
        const userList = _.clone(selectedReportUsers);
        _.remove(userList, (user) => user.userEmail === email);
        setSelectedReportUsers(userList);
    };
    const handleCheckZone = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newZonesToSave: any = { ...zonesToSave, [id]: !!event.target.checked };
        setZonesToSave(newZonesToSave);
    };
    const handleCheckSystem = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {

        const newSystemsToSave: any = { ...systemsToSave, [id]: !!event.target.checked };
        setSystemsToSave(newSystemsToSave);
    };
    const handleToggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newZonesToSave: any = {};
        for (let key of Object.keys(zonesToSave)) {
            if (key !== "all") {
                newZonesToSave[key] = !allToggled;
            }
        }
        setZonesToSave(newZonesToSave);
        setAllToggled(!allToggled);
    };

    const reportList = () => {
        return (
            <Grid container={true} className={classes.usersContainer}>
                <TableContainer className={classes.tableContainer}>
                    <Paper elevation={0} className={classes.paperTableContainer}>
                        <Table stickyHeader={true} className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow className={classes.tableHeadRow}>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`REPORT NAME`}</TableCell>

                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`RECIPIENTS`}</TableCell>

                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`FREQUENCY`}</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!_.isEmpty(selectedReports) &&
                                    _.map(selectedReports, (report, Id) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={`reports-${Id}`}
                                                onDoubleClick={() => {
                                                    onSelectReport(report);
                                                }}>
                                                <TableCell align={"left"} size="small">
                                                    <Typography variant="body2" className={classes.userNameCell}>
                                                        {report.name}
                                                    </Typography>
                                                </TableCell>

                                                <Tooltip
                                                    title={report.users.length ? _.map(report.users, (user) => user.userName).join(", ") : "No Recipients"}
                                                    key={report.id}
                                                    enterDelay={500}>
                                                    <TableCell align={"left"} size="small">
                                                        <Typography variant="body2" className={classes.userNameCell}>
                                                            {report.users.length}
                                                        </Typography>
                                                    </TableCell>
                                                </Tooltip>

                                                <TableCell align={"left"} size="small">
                                                    <Typography variant="body2" className={classes.userNameCell}>
                                                        {report.subTimeUnit} every {report.timeUnit}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );

                                    })}
                            </TableBody>
                        </Table>
                    </Paper>
                </TableContainer>
            </Grid>
        );
    };

    // List of all users that will get the report
    const recipientsList = () => {
        const usersByFilter = !searchVal
            ? selectedReportUsers
            : _.filter(selectedReportUsers, (oneUser) => {
                return _.startsWith(oneUser.userName.toUpperCase(), searchVal.toUpperCase());
                // return oneUser.userName.includes(searchVal);
            });

        return (
            <Grid container={true} className={classes.scheduledUserList}>
                <TableContainer className={classes.schedulerTableContainer}>
                    <Paper elevation={0} className={classes.paperTableContainer}>
                        <Table stickyHeader={true} className={classes.schedulerTable} aria-label="customized table">
                            <TableHead>
                                <TableRow className={classes.tableHeadRow}>
                                    <TableCell
                                        classes={{ root: classes.schedulerTableHeadRow }}
                                        align="left"
                                    >{t`RECIPIENTS`}</TableCell>

                                    <TableCell
                                        classes={{ root: classes.schedulerTableHeadRow }}
                                        align="right"
                                    >{t`DEL`}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(usersByFilter, (oneUser, i) => {
                                    return (
                                        <Tooltip
                                            title={oneUser.userEmail}
                                            key={i}
                                            enterDelay={500}>
                                            <TableRow role="checkbox" tabIndex={-1} key={i} className={classes.rowStyle}>
                                                <TableCell align={"left"} size="small" className={classes.cellStyle}>
                                                    <div className={classes.userHolder}>
                                                        <SvgUser className={classes.userIcon} />
                                                        <Typography variant="body2" className={classes.userNameCell}>
                                                            {oneUser.userName}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell align={"left"} size="small">
                                                    <Typography variant="body2" className={classes.delCell}>
                                                        <Delete
                                                            type={t`Reports Users`}
                                                            object={{ name: oneUser.userName, id: oneUser.userEmail }}
                                                            detach={deleteUser}
                                                            buttonClass={classes.deleteIcon}
                                                        />
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </Tooltip>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                </TableContainer>
            </Grid>
        );
    };

    const addUserToReportModel = () => {
        return (
            <Dialog
                open={showOneReport}
                onClose={onCancle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                classes={{ paper: classes.dialog }}
            >
                <DialogContent>
                    <Grid container={true} className={classes.oneReportContainer}>

                        <Grid item={true} xs={12} className={classes.newUserStyle}>
                            <Typography
                                className={classes.formatLabelSelect}
                            >{t`User Name`}</Typography>
                            <TextField id="outlined-basic"
                                // label="Report Name"
                                variant="outlined"
                                className={classes.reportNameStyle}
                                value={userName}
                                error={!!reportUserErr}
                                helperText={reportUserErr === "userName" ? "missing  user name" : ""}
                                onChange={(e: any) => {

                                    setUserName(e.target.value);
                                }} />

                        </Grid>
                        <Grid item={true} xs={12} className={classes.newUserStyle}>
                            <Typography
                                className={classes.formatLabelSelect}
                            >{t`User Email`}</Typography>
                            <TextField id="outlined-basic"
                                // label="Report Name"
                                variant="outlined"
                                className={classes.reportNameStyle}
                                value={userEmail}
                                error={!!reportUserErr}
                                helperText={reportUserErr === "userEmail" ? "type in a valid Email" : ""}
                                onChange={(e: any) => {
                                    setUserEmail(e.target.value);
                                }} />

                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className={classes.oneTenantBtns}>
                        <SButton
                            width={150}
                            uppercase
                            white
                            marginRight
                            onClick={() => {
                                setUserEmail("");
                                setUserName("");
                                setShowAddUser(false);
                                // onCancle()
                            }}>
                            {t`Cancel`}
                        </SButton>
                        <SButton
                            uppercase
                            width={150}
                            onClick={() => {
                                onSaveUser();

                            }}>
                            {t`Save`}
                        </SButton>
                    </div>
                </DialogActions>
            </Dialog>
        );
    };

    const oneReportView = () => {
        const days = () => {
            return (
                <div className={classes.btnsContainer}>
                    <div onClick={() => setTimeUnitSubSelect("Sun")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Sun" })} >Sun</div>
                    <div onClick={() => setTimeUnitSubSelect("Mon")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Mon" })} >Mon</div>
                    <div onClick={() => setTimeUnitSubSelect("Tue")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Tue" })} >Tue</div>
                    <div onClick={() => setTimeUnitSubSelect("Wed")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Wed" })} >Wed</div>
                    <div onClick={() => setTimeUnitSubSelect("Thu")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Thu" })} >Thu</div>
                    <div onClick={() => setTimeUnitSubSelect("Fri")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Fri" })} >Fri</div>
                    <div onClick={() => setTimeUnitSubSelect("Sat")} className={clsx(classes.btnStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Sat" })} >Sat</div>
                </div>
            );
        };

        const monthly = () => {
            return (
                <div color="primary" aria-label="outlined primary button group" className={classes.btnsContainer}>
                    <div onClick={() => setTimeUnitSubSelect("Begining")} className={clsx(classes.btnStyle, classes.monthlyBtnsStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "Begining" })} >Begining</div>
                    <div onClick={() => setTimeUnitSubSelect("10th")} className={clsx(classes.btnStyle, classes.monthlyBtnsStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "10th" })}>10th</div>
                    <div onClick={() => setTimeUnitSubSelect("15th")} className={clsx(classes.btnStyle, classes.monthlyBtnsStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "15th" })} >15th</div>
                    <div onClick={() => setTimeUnitSubSelect("End")} className={clsx(classes.btnStyle, classes.monthlyBtnsStyle, { [classes.selectedBtnDays]: timeUnitSubSelect === "End" })}>End</div>
                </div>
            );
        };

        return (
            <Dialog
                open={showOneReport}
                onClose={onCancle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
                classes={{ paper: classes.reportDialog }}
            >
                <div className={classes.dialogHeader}>
                    <Typography className={classes.headerTitle}>{showDeleteReport ? t`Edit Report` : t`Add New Report`}</Typography>
                    <IconButton disableRipple className={classes.iconBtnStyle} onClick={onCancle}><Close color="#7f7692" /></IconButton>
                </div>
                <div className={classes.dialogContent}>
                    {showDeleteReport &&
                        <div className={classes.delIconEditReport}>
                            <Delete
                                type={t`Report Schedule`}
                                object={selectedReport}
                                detach={onDelete}
                                buttonClass={classes.deleteIcon}
                            />
                        </div>
                    }
                    <Grid container={true} className={classes.oneReportContainer}>

                        <Grid item={true} xs={4} className={classes.nameContainer}>
                            <Typography
                                className={classes.headerColumnTitle}
                            >{t`Report Name`}</Typography>
                            <TextField id="outlined-basic"
                                label="Report Name"
                                variant="outlined"
                                className={classes.reportNameStyle}
                                value={oneReportName}
                                error={!!reportUserErr}
                                helperText={reportUserErr === "name" ? "missing report name" : ""}
                                onChange={(e: any) => {
                                    if (isMissingName) {
                                        setIsMissingName((false));
                                    }
                                    setOneReportName(e.target.value);
                                }} />

                            <div className={classes.rowContainer}>
                                <InputLabel className={classes.inputLabelStyle}>{t`File Format`}
                                    <Select
                                        labelId="reportOptionsFormat"
                                        value={format}
                                        disableUnderline
                                        className={classes.formatSelect}
                                        onChange={(event: any) => setFormat(event.target.value)}
                                        IconComponent={SvgArrow}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value={"csv"}>PDF</MenuItem>
                                        <MenuItem value={"csv"}>CSV</MenuItem>
                                    </Select>
                                </InputLabel>
                                <InputLabel className={classes.inputLabelStyle}>{t`Granualirity`}
                                    <Select
                                        labelId="reportOptionsGranualirity"
                                        value={granualirity}
                                        className={classes.formatSelect}
                                        IconComponent={SvgArrow}
                                        disableUnderline
                                        onChange={(event: any) => setGranualirity(event.target.value)}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value={"hour"}>1 hour</MenuItem>
                                        <MenuItem value={"day"}>1 day</MenuItem>
                                    </Select>
                                </InputLabel>
                            </div>

                            <Grid container={true} className={classes.timeSelectionsContainer}>
                                <Typography
                                    className={classes.headerStyle}
                                >{t`Choose Frequency`}</Typography>

                                <ButtonGroup color="primary" aria-label="outlined primary button group" className={classes.btnGroupTimeunits}>
                                    <Button disableRipple disableElevation onClick={() => setTimeUnit("day")} className={clsx(classes.btnText, timeUnit === "day" && classes.selectedBtn)}>Daily</Button>
                                    <Button disableRipple disableElevation onClick={() => setTimeUnit("week")} className={clsx(classes.btnText, timeUnit === "week" && classes.selectedBtn)}>Weekly</Button>
                                    <Button disableRipple disableElevation onClick={() => setTimeUnit("month")} className={clsx(classes.btnText, timeUnit === "month" && classes.selectedBtn)}>Monthly</Button>
                                </ButtonGroup>
                                {reportError && (reportError === "timeUnit" || reportError === "subTimeUnit") &&
                                    <div className={classes.btnGroupErr}>Please Select Frequency</div>}

                                <Grid item={true} xs={12} className={classes.selectTime}>
                                    {timeUnit === "week" && days()}
                                    {timeUnit === "month" && monthly()}
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid container={true} xs={4} className={classes.middleSideRecipients}>
                            <Typography className={classes.headerColumnTitle}>{t`Select Zones`}</Typography>

                            {
                                siteZones.length ?
                                    _.map(siteZones, (zone) => (
                                        <InputLabel key={`zoneItem-${zone.id}`} className={clsx(classes.itemName, { [classes.itemNameSelected]: zonesToSave[zone.id] })}>
                                            <Checkbox
                                                color="default"
                                                // classes={{ root: clsx(classes.removePaddingRight, classes.grayCheckbox) }}
                                                checked={zonesToSave[zone.id]}
                                                // disabled={disableUpdateButtons}handleCheck
                                                onChange={handleCheckZone(zone.id)}
                                                value={`zone${zone.id}`}
                                                edge="end"
                                                variant="outlined"
                                                checkedIcon={<CheckboxChecked />}
                                                className={classes.smallCheckbox}
                                            />
                                            {zone.name}
                                        </InputLabel>

                                    ))
                                    :
                                    <Typography className={classes.itemName}>{t`No zones`}</Typography>
                            }

                            <Typography className={classes.headerColumnTitle}>{t`Select systems`}</Typography>

                            {siteSystems.length ?
                                _.map(siteSystems, (system) => {
                                    if (!system) {
                                        return null;
                                    }
                                    return (
                                        <InputLabel key={`systemItem-${system.id}`} className={clsx(classes.itemName, { [classes.itemNameSelected]: systemsToSave[system.id] })}>
                                            <Checkbox
                                                color="default"
                                                // classes={{ root: clsx(classes.removePaddingRight, classes.grayCheckbox) }}
                                                checked={systemsToSave[system.id]}
                                                // disabled={disableUpdateButtons}handleCheck
                                                onChange={handleCheckSystem(system.id)}
                                                value={`system${system.id}`}
                                                edge="end"
                                                variant="outlined"
                                                checkedIcon={<CheckboxChecked />}
                                                className={classes.smallCheckbox}
                                            />
                                            {system.name}
                                        </InputLabel>
                                    );
                                })
                                :
                                <Typography className={classes.itemName}>{t`No systems`}</Typography>
                            }
                            <Typography className={classes.error}>{error}</Typography>
                            {/* </Grid> */}
                        </Grid>

                        {/** Left side of the screen **/}
                        < Grid container={true} xs={4} className={classes.leftSideRecipients} >

                            <div className={classes.addRecipients}>
                                <Typography className={classes.headerTitleNoBorder}>{t`Choose Recipients`}</Typography>
                                <IconButton disableRipple className={clsx(classes.iconBtnStyle, classes.addIcon)} onClick={() => setShowAddUser(true)}>
                                    <AddIcon />
                                </IconButton>
                            </div>

                            <Grid container={true} className={classes.reportsFormatWrtapper}>
                                <TextField id="outlined-basic"
                                    placeholder="Search User"
                                    variant="outlined"
                                    className={classes.searchUserScheduler}
                                    value={searchVal}
                                    // error={isMissingName}
                                    // helperText={!isMissingName ? '' : 'need report name to save'}
                                    onChange={(e: any) => {
                                        setSearchVal(e.target.value);
                                    }}
                                    autoComplete="off"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search />
                                            </InputAdornment>
                                        )

                                    }}

                                />

                                {selectedReportUsers && recipientsList()}
                            </Grid>

                            <Grid container={true} className={classes.reportsFormatWrtapper}>
                            </Grid>

                        </Grid>

                        {showAddUser && addUserToReportModel()}
                    </Grid>
                </div>
                <div className={classes.actionsHolder}>
                    <SButton
                        white
                        uppercase
                        marginRight
                        width={150}
                        onClick={onCancle}>
                        {t`Cancel`}
                    </SButton>
                    <SButton
                        width={150}
                        uppercase
                        onClick={() => {
                            onSave();
                        }}>
                        {t`Save`}
                    </SButton>
                </div>
            </Dialog>
        );
    };

    const onNoFilters = () => {
        return (
            <Grid container={true} direction={"column"} className={classes.noContentContainer}>
                <div className={classes.grayOval}>
                    <ArrowIcon className={classes.arrowIcon} />
                </div>
                <Typography className={classes.noUnitText}>
                    {t`Please select a site using the above filters.`}
                </Typography>
            </Grid>
        );
    };

    const btnTitle = !showOneReport ? t`Add New` : t`Back to Reports`;

    return (
        <div className={classes.view}>
            <ServiceNavigationBar {...props} />
            <div className={classes.contentArea}>
                <Header
                    path={["Power Distribution"]}
                    onSiteSelect={(id) => onFilterSelect(id, "site")}
                    onCustomerSelect={(id) => onFilterSelect(id, "customer")}
                    onSystemSelect={(id) => onFilterSelect(id, "system")}
                    customGeneralNames={{ site: "select Site" }}
                    hideSystemSelection={true}
                    hideUnitSelection={true}
                    btn={btnTitle}
                    canClickBtn={!!chosenSite}
                    btnFc={() => {
                        setShowOneReport(true);
                        if (isMissingName) {
                            setIsMissingName((false));
                        }
                    }}
                />

                {_.isEmpty(selectedReports.length) && reportList()}
                {showOneReport && oneReportViewCallBack()}
                {!_.isEmpty(selectedReports.length) && onNoFilters()}

            </div>
        </div>
    );
};

export default TenantList;
