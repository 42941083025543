// / <reference types="googlemaps" />
/* global google */

import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@material-ui/core";
import { useLoadScript } from "@react-google-maps/api";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useRouteMatch } from "react-router";
import { t } from "ttag";
// import { NotesAndAttachments } from "../../components/";
import ErrorLogs from "../../components/ErrorLogs/ErrorLogs";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import SystemOverview from "../../components/SystemOverview/SystemOverview";
import TemperatureInfo from "../../components/TemperatureInfo/TemperatureInfo";
import UnitControl from "../../components/UnitControl/UnitControl";
import UnitInfo from "../../components/UnitInfo/UnitInfo";
import UnitStats from "../../components/UnitStats/UnitStats";
import ArrowIcon from "../../icons/ArrowLong";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnitAffiliation } from "../../models/Units";
// import { downloadAs } from "../../services/download";
import useStyles from "./DashboardUnit.style";

const DashboardUnit: React.FC<RouteComponentProps<any> & any> = (props) => {
  // Get history from hook
  const history = useHistory(),
    classes = useStyles(),
    // Get unit id from path
    match = useRouteMatch<{ unitId: string }>();
  let { unitId } = match.params;

  if (!unitId || unitId === "undefined") {
    unitId = "";
  }
  const getUnit = useStoreState((s) => s.units.getUnit);

  const { customerId = "", deviceId = "", siteId = "", systemId = "" }: IUnitAffiliation | any = useStoreState((s) =>
    s.units.getUnitAffiliation(unitId)
  );
  const getLineQuality = useStoreActions((action) => action.devices.getLineQuality);
  const refreshUnit = useStoreActions((a) => a.units.refreshUnit);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const unitCustomer = useStoreState((s) => (customerId ? s.customers.allCustomers[customerId] : null));
  const unitSite = useStoreState((s) => s.sites.getSite(siteId));
  const allDevices = useStoreState((s) => s.devices.allDevices); //.getDevice(deviceId));
  const allAlerts = useStoreState((state) => state.alerts.allAlerts);
  const alerts = useStoreState((state) => state.alerts.parsedAlerts);
  const unitSystem = useStoreState((s) => s.systems.getSystem(systemId));
  const allUnits = useStoreState((state) => state.units.allUnits);
  const currentUser = useStoreState((s) => s.users.me);
  const selections = useStoreState((s) => s.selections.selections);
  const allUsers = useStoreState((state) => state.users.users);
  const types = useStoreState((state) => state.types.allTypes);
  const [outdoorTemp, setOutdoorTemp] = useState("--");
  const [reFectUnit, setReFetchUnit] = useState<boolean>(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBJIN2UpLEwA0WENCrw5eahjzwq7aMzRBY",
    language: "en"
  });
  const [externalTempC, setExternalTempC] = useState<any>(null);
  const [externalTempF, setExternalTempF] = useState<any>(null);
  const [unitAlerts, setUnitAlerts] = useState<any>([]);
  const [unit, setUnit] = useState<any>(null);
  const getAlertsByFilters = useStoreActions((action) => action.alerts.getAlertsByFilters);
  const initializeAlerts = useStoreActions((action) => action.alerts.initialize);
  const [selectedTime, setSelectedTime] = useState<any>({}); //time picker on graph
  const [unitFiles, setUnitFiles] = useState<any>(null);

  const unitDevice = allDevices[deviceId];
  const { temperatureScale } = currentUser;
  const { eventTypes, unitTypes } = types;

  useEffect(() => {
    if (!reFectUnit) {
      return;
    }
    setReFetchUnit(false);
    setUnit(allUnits[unitId]);
  }, [reFectUnit]);

  useEffect(() => {
    if (unitId !== selections.unitId) {
      updateSelections({ type: "unit", data: unitId });
    }
    setUnit(null);
  }, [unitId]);

  useEffect(() => {
    if (selections.unitId && !unitId) {
      onUnitSelect(selections.unitId);
      return;
    }

    if (unitId && selections.unitId && unitId === selections.unitId) {
      refreshUnit({ id: unitId })
        .then((data: any) => {
          setUnit(data);
        });
      return;
    }
    setUnit(null);
  }, [unitId, selections.unitId]);

  useEffect(() => {
    if (!reFectUnit) {
      return;
    }
    setReFetchUnit(false);
    setUnit(allUnits[unitId]);
  }, [reFectUnit]);

  useEffect(() => {

    if (!unitId || _.isEmpty(allAlerts)) {
      return;
    }
    const filteredAlerts = Object.values(alerts).filter((alert: any) => {
      const isEntitiesNoDateType = eventTypes.entitiesHealthLowIstat === alert.type
        || eventTypes.entitiesHealthLowOstat === alert.type
        || eventTypes.entitiesHealthLowPstat === alert.type
        || eventTypes.entitiesHealthLowLqstat === alert.type
        || eventTypes.entitiesHealthLowSstat === alert.type;
      if (!alert?.unitIds?.length || alert.unitIds.indexOf(unitId) === -1) {
        return false;
      }
      if (isEntitiesNoDateType) {
        return false;
      }
      return true;
    });
    setUnitAlerts(filteredAlerts);
  }, [allAlerts, unitId]);

  //   useEffect(() => {
  //     if (!isLoaded || !unitSite) {
  //       return;
  //     }
  //     const { lat, lon } = unitSite;

  //     if (!lat || !lon) {
  //       return;
  //     }

  //     const weatherAPIUrl = `https://api.worldweatheronline.com/
  // premium/v1/weather.ashx?key=875fe1d8d3354a12b93211840200905&
  // q=${lat},${lon}&format=json&num_of_days=1`;

  //     fetch(weatherAPIUrl)
  //       .then((res) => res.json())
  //       .then((res) => {
  //         const {
  //           data: { current_condition }
  //         } = res;

  //         const today: any = current_condition && current_condition[0];

  //         if (!today || _.isEmpty(today)) {
  //           setExternalTempC(null);
  //           setExternalTempF(null);
  //           return;
  //         }
  //         setExternalTempC(today.temp_C || null);
  //         setExternalTempF(today.temp_F || null);
  //       })
  //       .catch(() => {
  //         setExternalTempC(null);
  //         setExternalTempF(null);

  //       });

  //   }, [unitSite, isLoaded]);

  // testing the app without this code,, might need it later
  // useEffect(() => {
  //   // Launch outdoor temperature updates
  //   if (unitId) {
  //     // Ask for USTAT for the unit we're showing to get most recent data for it
  //     (async () => {
  //       await requestStatsRefresh({ id: unitId });
  //     })();

  //     // Mark loaded unit as selected after refresh.
  //     if (_.isNull(selections.unitId)) {
  //       updateSelections({ type: "unit", data: unitId });
  //     }
  //     props.unitUpdateStatusUpdate(unitId);
  //   }
  //   ///////////////////////////
  // }, [unitId]);

  const onUnitSelect = (unitId: string | null) => {
    if (unitId) {
      history.push(`/unit-diag/${unitId}`);
    } else {
      history.push("/unit-diag");
    }
  };

  const addFileLocally = (file: any) => {
    setUnitFiles([...unitFiles, file]);
  };

  const isBsUnit = unit?.type === unitTypes["bsBox"];

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.rightSide}>
        <Header
          path={[t`Unit Diagnostics`]}
          // onCustomerSelect={onCustomerSelect}
          // onSiteSelect={onSiteSelect}
          // onSystemSelect={onSystemSelect}
          onUnitSelect={onUnitSelect}
          customGeneralNames={{ unit: "Select Unit" }}
          hideAllOptionsFromDropdown={["unit"]}
        />
        {unit &&
          unitAlerts &&
          !_.isNil(unitCustomer) &&
          !_.isNil(unitSite) &&
          !_.isNil(unitDevice) ? (
            <Grid container direction={"row"} className={classes.contentContainer}>
              {/* --- start of unitInfoContainer  */}
              <Grid container direction={"row"} className={classes.unitInfoContainer}>
                <Grid item xs={7} className={classes.halfPadding} style={{ maxHeight: "121px" }}>
                  <UnitInfo
                    unit={unit}
                    site={unitSite}
                    device={unitDevice}
                    system={unitSystem}
                  // setNotesAnch={setNotesAnch}
                  />
                </Grid>
                <Grid item xs={2} className={classes.halfPadding}>
                  {unit.type !== 2 && !isBsUnit ? (
                    <TemperatureInfo
                      unit={unit} site={unitSite}
                      externalTempC={externalTempC}
                      externalTempF={externalTempF}
                      temperatureScale={temperatureScale}
                      roomTemp={unit.ambientTemperature}
                    />
                  ) : (
                      <TemperatureInfo
                        unit={unit}
                        site={unitSite}
                        externalTempC={externalTempC}
                        externalTempF={externalTempF}
                        externalOnly
                        temperatureScale={temperatureScale}
                        outdoorTemp={outdoorTemp}
                        roomTemp={unit.ambientTemperature}
                      />
                    )}
                </Grid>
                <Grid item xs={3} className={classes.halfPadding}>
                  {!isBsUnit && unit ? (
                    <UnitControl unit={unit} temperatureScale={temperatureScale} device={unitDevice} />
                  ) : null}
                </Grid>
              </Grid>
              {/* --- end of unitInfoContainer */}
              {/* --- start of paramsContainer  */}
              <Grid container direction={"row"} className={classes.halfPadding}>
                <Grid item xs={12} className={classes.paramsContainer}>
                  <Card className={classes.statsRapper}>
                    {unit.type === 2 ? (
                      <UnitStats
                        site={unitSite}
                        unit={unit}
                        alerts={unitAlerts}
                        setOutdoorTemp={setOutdoorTemp}
                        getLineQuality={getLineQuality}
                        setSelectedTime={setSelectedTime}
                        setReFetchUnit={setReFetchUnit}
                      />
                    ) : (
                        <UnitStats
                          site={unitSite}
                          unit={unit}
                          alerts={unitAlerts}
                          getLineQuality={getLineQuality}
                          setSelectedTime={setSelectedTime}
                          setReFetchUnit={setReFetchUnit}
                        />
                      )}
                  </Card>
                </Grid>
                {/* --- end of paramsContainer */}
                {/* --- start of errorLogs and systemOverview */}
              </Grid>

              <Grid container direction={"row"} spacing={3} className={classes.bottomContainer}>
                <Grid item xs={5}>
                  <Card
                    classes={{
                      root: classes.errorLogsCard
                    }}
                  >
                    <ErrorLogs
                      unit={unit}
                      usePadding={true}
                      showTitle
                      hideColumns={["unitName", "errorCode", "customerName", "system", "siteName", "status", "alertType1"]}
                      hideAlertOfType={["2"]}
                    />
                  </Card>
                </Grid>

                <Grid item xs={7}>
                  {unitSystem && (
                    <Card className={classes.systemOverviewCard}>
                      <SystemOverview system={unitSystem} />
                    </Card>
                  )}
                </Grid>

                {/* --- end of errorLogs and systemOverview */}
              </Grid>
            </Grid>
          ) : (
            <Grid container direction={"column"} className={classes.noContentContainer}>
              <div className={classes.grayOval}>
                <ArrowIcon className={classes.arrowIcon} />
              </div>
              <Typography className={classes.noUnitText}>
                {t`Please select a unit using the above filters.`}
              </Typography>
            </Grid>
          )}
      </div>
      {/*notesAnch && unitId && systemId && (
        <NotesAndAttachments
          anchorEl={notesAnch}
          setAnchorEl={setNotesAnch}
          unitId={unitId}
          systemId={systemId}
          unitNotes={unitNotes}
          setUnitNotes={setUnitNotes}
          createNewNote={newNote}
          deleteNote={deleteNote}
          allUsers={{ [currentUser.id]: currentUser, ...allUsers }}
          unitFiles={unitFiles}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          uploadFile={uploadFile}
          updateFilesInfo={updateFilesInfo}
          addFileLocally={addFileLocally}
          siteId={unitSite?.id}
          timezone={unitSite?.timezone}
        />
      )*/}

      {/*selectedFile && (
        <Dialog
          open={selectedFile}
          keepMounted
          onClose={() => setSelectedFile(null)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          classes={{ paper: classes.dialogStyle }}
        >
          <DialogTitle id="alert-dialog-slide-title">{`${selectedFile.name} preview`}</DialogTitle>
          <DialogContent>
            {selectedFile.isPhoto ? <img src={selectedFile.url} className={classes.imagePreview} /> :
              (<iframe
                src={`${selectedFile.isPdf ? "https://docs.google.com/gview?url=" : "https://view.officeapps.live.com/op/embed.aspx?src="}${selectedFile.url}${selectedFile.isPdf ? "&embedded=true" : ""}`}
                width="100%"
                height="100%"
                frameBorder="0"
                className={classes.frameStyle}
              >
              </iframe>)}
          </DialogContent>
          <DialogActions>
            <Button onMouseUp={() => downloadAs(selectedFile.url, selectedFile.name)} color="primary">
              Download
          </Button>
            <Button onMouseUp={() => setSelectedFile(null)} color="primary">
              Close
          </Button>
          </DialogActions>
        </Dialog>
              )*/}
    </div>
  );
};

export default DashboardUnit;
