import { Button, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { PropsWithChildren, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import useStyles from "./NavigationMenuItem.style";

const tooltipProps: { placement: any; arrow: boolean } = {
  placement: "right",
  arrow: true
};

interface INavigationMenuItemProps {
  Icon: React.FC<any>;
  name: string;
  path: string;
  disabled?: boolean;
  dropdown?: any;
  endIcon?: any;
  showDropDown?: boolean;
  onNavigate?: () => void;
  setShowDropDown?: () => void;
  onClick?: () => void;
}

const NavigationMenuItem = ({
  Icon,
  name,
  path,
  disabled = false,
  onNavigate,
  dropdown,
  endIcon,
  setShowDropDown = _.noop,
  showDropDown = false,
  onClick: doAction
}: INavigationMenuItemProps) => {
  const classes = useStyles();
  const history = useHistory();
  const variants = {
    item: {
      default: classes.navMenuItem,
      selected: classes.navMenuItemSelected,
      disabled: classes.navMenuItemDisabled
    },
    subItem: {
      default: classes.navMenuSubItem,
      selected: classes.navMenuItemSelected,
      disabled: classes.navMenuItemDisabled
    },
    icon: {
      default: classes.navIcon,
      selected: classes.navIconSelected,
      disabled: classes.navIconDisabled
    }
  };

  // const [showSelect, setShowSelect] = React.useState(false);

  const getClassForPath = (path: string, variant: "item" | "icon" | "subItem", isDropDownItemDisabled = false) => {
    if (disabled || isDropDownItemDisabled)
      return clsx(variants[variant].default, variants[variant].disabled);
    return history.location.pathname === path
      ? clsx(variants[variant].default, variants[variant].selected)
      : variants[variant].default;
  };

  const handleClick = () => {
    if (!disabled) history.push(path);
    if (onNavigate) onNavigate();
  };

  if (dropdown && dropdown.length) {
    const EndIcon: any = !showDropDown ? endIcon[0] : endIcon[1];

    // highlight dropdown menu only if it's children are chosen && dropdown closed
    const highlightMenuItem = (history.location.pathname === dropdown[0].path || history.location.pathname === dropdown[1].path)
      && !showDropDown
      ? clsx(variants.item.default, variants.item.selected)
      : variants.item.default;

    return (
      <div className={classes.navMenuItemContainer}>
        <Button
          startIcon={<Icon className={getClassForPath(path, "icon")} />}
          endIcon={<EndIcon style={{ marginLeft: "10px" }} />}
          onClick={() => setShowDropDown()}
          className={highlightMenuItem}
        >
          <Typography variant="subtitle1" className={classes.navItemTitle}>
            {name}
          </Typography>
        </Button>
        {showDropDown &&
          _.map(dropdown, (dropdownData, i) => {
            return (
              <Button
                key={i}
                onClick={() => {
                  if (!dropdownData.isDisabled) {
                    history.push(dropdownData.path);
                  }
                }}
                className={getClassForPath(dropdownData.path, "subItem", dropdownData.isDisabled)}
              >
                <Typography variant="subtitle1" className={classes.navItemTitle}>
                  {dropdownData.name}
                </Typography>
              </Button>
            );
          })
        }
      </div>
    );
  }

  return (
    <Button
      startIcon={<Icon className={getClassForPath(path, "icon")} />}
      onClick={() => {path !== "" ? handleClick() : doAction && doAction(); }}
      className={getClassForPath(path, "item")}
    >
      <Typography variant="subtitle1" className={classes.navItemTitle}>
        {name}
      </Typography>
    </Button>
  );
};

export default NavigationMenuItem;
