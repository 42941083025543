import clsx from "clsx";
import React from "react";
import assetsService from "../../services/assetsService";
import useStyles from "./Loading.style";

export default function Loading(props: any) {
  const classes = useStyles();
  const {mobile = false} = props;
  return (
    <div className={classes.loading}>
      <div className={classes.main}>
        <div className={classes.logos}>
          <img
            alt="logo"
            src={assetsService.getResourcePath("logo_emblem.png")}
            className={clsx(classes.logo, {[classes.mobileLogo]: mobile})}
          />
          <img
            alt="logo title"
            src={assetsService.getResourcePath("logo_title.png")}
            className={classes.logoTitle}
          />
        </div>
      </div>
    </div>
  );
}
