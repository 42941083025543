import { CircularProgress, Divider, makeStyles, TextField, Typography } from "@material-ui/core";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
// import { ConfirmationDialog, Toolbar } from "../../components";
import { useHistory, useRouteMatch } from "react-router-dom";
import { t } from "ttag";
import {BottomNav, TopBar} from "../../components";

import Switch from "../../components/Switch/Switch";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import { MobileLogo, MobileMenu} from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { minsToTime } from "../../services/timeService";
import { AddIcon, ArrowBack, Delete, Edit } from "../../svgComponents";
import AddEditSchedule from "./AddEditSchedulePopup/AddEditSchedulePopup";
import styles from "./scheduleList.style";

const ScheduleList: React.FC<any> = (props: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const history = useHistory();
  const  match = useRouteMatch<{ unitId?: string, groupId?: string }>();
  const { params } = match;
  const { unitId, groupId } = params;
  const isGroup = !!groupId;

  const [selectedItem, setSelectedItem] = useState<string>("");
  const [currentItem, setCurrentItem] = useState<any>({});
  const [itemSchedules, setItemSchedules] = useState<any>({});
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selectedScheduleId, openAddEditSchedulePopup] = useState<string>("");

  const types = useStoreState((state) => state.types.allTypes);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay());
  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const getUnit = useStoreState((actions) => actions.units.getUnit);
  const _storeUpdateUnit = useStoreActions((actions) => actions.units._storeUpdateUnit);
  const getGroup = useStoreActions((actions) => actions.groups.getGroup);
  const {updateSchedule, getObjectSchedules} = useStoreActions(
    (actions) => actions.schedules
  );

  const {weekDays} = types;

  const addMessage = useStoreActions(
    (actions) => actions.errorMessage.addMessage
  );
  const deleteSchedule = useStoreActions(
    (actions) => actions.schedules.deleteSchedule
  );

  const goBack = () => {
    history.push("/site");
  };

  useEffect(() => {
    if (!unitId && !groupId){
      history.push("/");
      return;
    }

    if (isGroup){
        getGroup(groupId || "")
          .then((group: any) => {
              getObjectSchedules({ objectType: "group", id: groupId })
                    .then((schedules: any) => {
                      setCurrentItem(group);
                      setItemSchedules(schedules);
                    })
                    .catch((error: any) => {
                      addMessage({ message: error.message });
                    }).finally(() => setLoading(false));
            })
            .catch(() =>   history.push("/"));
        return;
    }

    const unit = getUnit(unitId);
    if (!unit){
      history.push("/");
      return;
    }

    getObjectSchedules({objectType: "unit", id: unitId })
          .then((schedules: any) => {
            setCurrentItem(unit);
            setItemSchedules(schedules);
          })
          .catch((error: any) => {
            addMessage({ message: error.message });
          }).finally(() => setLoading(false));
  }, []);

  const deleteSelectedSchedule = (scheduleId: string) => {
    deleteSchedule(scheduleId)
      .then(() => {
        if (!isGroup){
          const {schedules, id} = currentItem;
          const filteredSchedules = schedules.filter((id: string) => id !== scheduleId);
          _storeUpdateUnit({id, data: {schedules: filteredSchedules}});
        }
        delete itemSchedules[scheduleId];
        setItemSchedules({...itemSchedules});
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      );
  };

  const changeScheduleActiveState = (e: any, id: string) => {
    const isDisabled = !e.target.checked;
    updateSchedule({id, data: {isDisabled} }).then(() => {
      itemSchedules[id].isDisabled =  isDisabled;
      setItemSchedules({...itemSchedules});
    });
  };

  const updateLocalSchedules = (schedule: any) => {
    const {id} = schedule;
    itemSchedules[id] = schedule;
    setItemSchedules({...itemSchedules});
  };

  return <>
  <TopBar
    leftIconComponent={<MobileLogo />}
    leftAction={() => history.push("/dashboard")}
    rightIconComponent={<AddIcon color="#fff" style={{transform: "scale(1.7)"}} />}
    rightAction={() => openAddEditSchedulePopup("new")}
    title={t`Schedules`}
    />
      <div className={classes.screenContainer}>
        <div className={classes.gridStyle}>
          <div className={classes.schedulesContainer}>
            {isLoading ? <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%"}}><CircularProgress /></div> :
              Object.values(itemSchedules).length === 0 ? (
              <Typography
                className={classes.noSchedulesStyle}
              >{t`There are no schedules to show`}</Typography>
            ) : (
                Object.values(itemSchedules).map((schedule: any, i: number) => {
                  const {powerOnTime, powerOffTime, setpoint, id, days, isDisabled} = schedule;
                  const startHour = powerOnTime || powerOnTime === 0 ? minsToTime(powerOnTime, timeFormat) : "";
                  const endHour = powerOffTime || powerOffTime === 0 ? minsToTime(powerOffTime, timeFormat) : "";

                  return (
                    <div
                      className={classes.scheduleContainer}
                      key={`schedule-${id}`}
                    >
                      <div className={classes.scheduleHeader}>
                          <Typography className={classes.longNamestyle}>
                            {currentItem.name}
                          </Typography>
                        <div className={classes.scheduleControl}>
                          <Edit onClick={() => openAddEditSchedulePopup(id)} />
                          <Delete
                            onClick={() => deleteSelectedSchedule(id)}
                          />
                          <Switch
                            checked={!isDisabled}
                            disableRipple={true}
                            onChange={(e: any) =>
                              changeScheduleActiveState(e, id)
                            }
                            value={true}
                          />
                        </div>
                      </div>
                      <div className={classes.scheduleBody}>
                        <div className={classes.rowContainer}>
                          <div className={classes.bodyRow}>
                            <div className={classes.timeSection}>
                              <TextField
                                variant={"outlined"}
                                name={"Start Time"}
                                label={t`Start Time`}
                                placeholder={t`None`}
                                value={powerOnTime || powerOnTime === 0
                                  ? startHour
                                  : t`None`}
                                className={classes.inputClass}
                                disabled={true}
                              />
                              <TextField
                                variant={"outlined"}
                                name={"End Time"}
                                label={t`End Time`}
                                placeholder={t`None`}
                                value={powerOffTime || powerOffTime === 0
                                  ? endHour
                                  : t`None`}
                                className={classes.inputClass}
                                disabled={true}
                              />
                            </div>
                          </div>

                          {!!setpoint && (
                            <div className={classes.setpointContainer}>
                              <div className={classes.setPointSection}>
                                <Typography className={classes.setPointStyle}>
                                  {setpoint}
                                </Typography>
                                <Typography className={classes.tempSymbolStyle}>
                                  {temperatureSymbol}
                                </Typography>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={classes.bodyRow}>
                          <DaysList
                            days={Object.keys(weekDays)}
                            activeDays={days}
                            action={() => { }}
                            isMobile
                            disablePointer
                          />
                        </div>
                        <Divider className={classes.dividerStyle} />
                      </div>
                    </div>
                  );
                })
              )}
          </div>
        </div>
      </div>
      <BottomNav  />
      {(selectedScheduleId  === "new" ||  (selectedScheduleId && itemSchedules[selectedScheduleId])) &&
        <AddEditSchedule
          onClose={() => openAddEditSchedulePopup("")}
          schedule={itemSchedules[selectedScheduleId]}
          scheduleId={selectedScheduleId}
          itemType={isGroup ? "group" : "unit"}
          itemId={isGroup ? groupId : unitId}
          updateLocalSchedules={updateLocalSchedules}
          item={currentItem}
          updateStoreUnit={_storeUpdateUnit}
        />}
      </>;
};

export default ScheduleList;
