import * as React from 'react';

function SvgEmail(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 17" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#4B1C46"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.143}
      >
        <path d="M14.69 14.124H2.625a1.055 1.055 0 01-1.055-1.055V4.626c0-.583.472-1.055 1.055-1.055H14.69c.582 0 1.055.472 1.055 1.055v8.443c0 .582-.473 1.055-1.055 1.055z" />
        <path d="M3.602 5.975l5.036 3.95 5.125-3.95" />
      </g>
    </svg>
  );
}

export default SvgEmail;
