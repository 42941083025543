import { TableContainer } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Alert as SvgAlert } from "../../icons/";
import { useStoreState } from "../../models/RootStore";
import { ISystem } from "../../models/Systems";
import assetsService from "../../services/assetsService";
import { systemTableStyle } from "./SystemTable.style";

const columns = [
  { id: "brand", label: t`BRAND` /* minWidth: 170*/ },
  { id: "hasAlerts", label: t`ALERTS` /* minWidth: 100*/ },
  { id: "systems", label: t`SYSTEMS` /* minWidth: 170*/ },
  { id: "outdoor", label: t`ODU` /* minWidth: 100*/ },
  { id: "indoor", label: t`IDU` /* minWidth: 100*/ }
  // { id: "filler", lavel: "" }
];
interface IData {
  id: string;
  brand: number;
  hasAlerts: boolean;
  systems: number;
  indoor: number;
  outdoor: number;
}

const SystemTable: React.FC<any> = (props) => {
  const { classes } = props;
  const { alertIcon, tableWrapper, tableCell, tableRow, brandLogo } = classes;
  const [rows, setRows] = useState<any>({});
  const getUnitsBy = useStoreState((state) => state.units.getUnitsBy);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const selectedSystems = useStoreState((s) => s.selections.getSystemsBySelection);
  const getAlertsByFilter = useStoreState((state) => state.alerts.getAlertsByFilter);

  useEffect(() => {
    const systemsIndoorUnits: any = [];
    const brandRows: any = {};
    selectedSystems.forEach((system: ISystem) => {
      const { id } = system;
      const  outdoor = getUnitsBy("system", id, { type: "outdoor" }).length;
      const  indoorUnits = getUnitsBy("system", id, { type: "indoor" });
      const  indoor = indoorUnits.length;
      systemsIndoorUnits.push(...indoorUnits);

      const  brand = system.brandNum ? system.brandNum : 999;
      const hasAlerts = !!getAlertsByFilter({ systemId: system.id }).length;
      const totalSystems = brandRows[brand]?.systems ? brandRows[brand]?.systems : 0;
      const totalIndoor = brandRows[brand]?.indoor ? brandRows[brand]?.indoor : 0;
      const totalOutdoor = brandRows[brand]?.outdoor ? brandRows[brand]?.outdoor : 0;
      const brandHasAlerts = brandRows[brand]?.hasAlerts ? brandRows[brand]?.hasAlerts : false;
      brandRows[brand] = {brand, hasAlerts : brandHasAlerts || hasAlerts, systems: totalSystems + 1, indoor: totalIndoor + indoor, outdoor: totalOutdoor + outdoor };
    });
    setRows(brandRows);
  }, [selectedSystems]);

  return (
    <div className={classes.pageContainer}>
      <TableContainer className={tableWrapper}>
        <Table stickyHeader aria-label="a dense table">
          <colgroup>
            <col style={{ width: "150px" }} />
            <col style={{ width: "1rem" }} />
            <col style={{ width: "1rem" }} />
            <col style={{ width: "1rem" }} />
            <col style={{ width: "1rem" }} />
            <col style={{ width: "1rem" }} />
            <col style={{ width: "1rem" }} />
            <col style={{ width: "150px" }} />

          </colgroup>
          <TableHead>
            <TableRow>
              {columns.map((column: any, index) => (
                <TableCell
                  key={index}
                  align={column.id === "name" || "siteName" ? "left" : "center"}
                  classes={{ root: classes.tableHeadCell }}
                >
                  {column.label === "ALERTS" ? "" : column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(rows).map((row: any) => {
              return (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className={tableRow}
                >
                  {columns.map((column: any, index) => {
                    const rowVal = row[column.id],
                      value =
                        column.id === "brand" ? (
                          <img
                            width={60}
                            height={25}
                            src={assetsService.getBrandLogo(`${rowVal}-grey.svg`)}
                            onError={(event: any) =>
                              (event.target.src = assetsService.getBrandLogo("empty.png"))
                            }
                            alt={rowVal}
                            className={brandLogo}
                          />
                        ) : column.label === "ALERTS" && row.hasAlerts ? (
                          <SvgAlert className={alertIcon} />
                        ) : rowVal;

                    return (
                      <TableCell
                        size="small"
                        key={index}
                        className={clsx(tableCell, { [classes.minWidthStyle]: column.minWidth })}
                        align={column.id === "name" || "siteName" ? "left" : "center"}
                      >
                        {column.format && typeof value === "number" ? column.format(value) :
                          (column.minWidth && value && value.length > 30 ? value.substr(0, 30) + "\u2026"
                            : value)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default withStyles(systemTableStyle)(SystemTable);
