import { Divider, Grid, Typography } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { ICompressor } from "../../models/Compressors";
import { useStoreState } from "../../models/RootStore";
import { IUnit } from "../../models/Units";
import { outdoorUnitInfoPanelStyle } from "./OutdoorUnitInfoPanel.style";

interface IProps {
  unit: IUnit;
}

const OutdoorUnitInfoPanel: React.FC<IProps> = (props: IProps) => {
  const { unit } = props;
  const classes = outdoorUnitInfoPanelStyle();

  const allCompressors = useStoreState((state) => state.compressors.allCompressors);
  const outdoorUnitTasks = _.invertBy(useStoreState((state) => state.types.allTypes.outdoorUnitTasks));
  const capacityMeasurementUnitTypes = _.invertBy(
    useStoreState((state) => state.types.allTypes.capacityMeasurementUnitTypes)
  );

  const unitTaskText =
    !_.isNil(unit.task) && !_.isNil(outdoorUnitTasks) && !_.isUndefined(outdoorUnitTasks[unit.task])
      ? `${outdoorUnitTasks[unit.task][0]}`
      : "";

  const unitCapacityMeasurementUnitsText =
    !_.isNil(unit.capacityMeasurementUnits) &&
      !_.isNil(capacityMeasurementUnitTypes) &&
      !_.isUndefined(capacityMeasurementUnitTypes[unit.capacityMeasurementUnits])
      ? `${capacityMeasurementUnitTypes[unit.capacityMeasurementUnits]}`
      : "";

  const unitCompressors = unit.compressors
    ? _.map(
      _.filter(unit.compressors, (compressorId) => !_.isUndefined(allCompressors[compressorId])),
      (compressorId) => allCompressors[compressorId]
    )
    : [];

  const inHours = (min: number) => {
    if (!min) {
      return "--";
    }

    return `${Math.round(min / 60 * 2) / 2}h`;
  }

  return (
    <div className={classes.outdoorUnitPanel}>
      <Typography className={classes.outdoorUnitName}>{unit.name}</Typography>
      <Typography>{unitTaskText === "unknown" ? "M/S: unknown" : unitTaskText}</Typography>
      <Divider className={classes.outdoorUnitPanelDivider} />
      <Grid container spacing={3} justify="space-between" direction="row">
        <Grid item>
          <Typography className={classes.infoLabel}>{t`Capacity`}</Typography>
        </Grid>
        <Grid item style={{ paddingLeft: 0 }}>
          <Typography className={classes.infoLabel} style={{ maxWidth: "10ch", whiteSpace: "nowrap", overflow: "hidden" }}>
            {!_.isNil(unit.capacity) ? `${unit.capacity}` + unitCapacityMeasurementUnitsText : ""}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.outdoorUnitPanelDivider} />
      {_.map(unitCompressors, (compressor: ICompressor, index: number) => (
        <Grid key={index} container spacing={3} justify="space-between">
          <Grid item >
            <Typography key={compressor.id} noWrap className={classes.infoLabel}>
              {compressor.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography key={compressor.id} noWrap className={classes.infoLabel}>
              {inHours(compressor.workingMinutes)}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default OutdoorUnitInfoPanel;
