import moment from "moment-timezone";

export function minsToTime(mins: number, timeFormat: string) {
  return moment.utc(mins * 60 * 1000).format(timeFormat);
}

export function timeToSeconds(timeStr: string) {
  const timeStrArr = timeStr.split(" ");
  const localDate = new Date();
  const timeArray = timeStrArr[0].split(":");
  localDate.setHours(+timeArray[0]);
  localDate.setMinutes(+timeArray[1].substr(0, 2));
  const hours =
    timeStrArr[1] === "PM"
      ? +localDate.getUTCHours() + 12
      : +localDate.getUTCHours();
  const seconds = hours * 60 * 60 + +localDate.getUTCMinutes() * 60;
  return seconds;
}

export function utcTimestampTolocalTimestampMins(timestamp: string | number) {
  const utcHours = Math.floor(+timestamp / 60);
  const utcMins = Math.floor(+timestamp % 60);

  const date = new Date();

  date.setUTCHours(utcHours);
  date.setMinutes(utcMins);

  const localHours = date.getHours();
  const localMins = date.getMinutes();

  return localHours * 60 + localMins;
}

export function isEndStampLaterThanStartStamp(
  startTimeString: string,
  endTimeString: string,
  is12Hours: boolean
) {
  let formattedStartTimeString = startTimeString;
  let formattedEndTimeString = endTimeString;
  if (is12Hours) {
    const startSuffix = startTimeString.slice(-2);
    const endSuffix = endTimeString.slice(-2);
    let startingHour = startTimeString.substr(0, 2);
    let endingHour = endTimeString.substr(0, 2);

    if (startingHour === "12") {
      startingHour = "00";
    }
    if (endingHour === "12") {
      endingHour = "00";
    }

    if (startSuffix === "pm") {
      startingHour = String(+startingHour + 12);
    }
    if (endSuffix === "pm") {
      endingHour = String(+endingHour + 12);
    }
    formattedStartTimeString = startingHour + startTimeString.substring(2);
    formattedEndTimeString = endingHour + endTimeString.substring(2);
  }
  const startTime = stringToMins(formattedStartTimeString);
  const endTime = stringToMins(formattedEndTimeString);
  return endTime > startTime && endTime !== startTime;
}

export function stringToMins(timeString: string) {
  const timeArray = timeString.split(":");
  const timeInMins = +timeArray[0] * 60 + +timeArray[1].substr(0, 2);
  return timeInMins;
}

export function isEndHourLaterThanStart(startTime: string, endTime: string) {
  const timeStrArr1 = startTime.split(":");
  const hrs1 = +timeStrArr1[0] * 60;
  const mins1 = +timeStrArr1[1].substr(0, 2);

  const totalMins1 = hrs1 + mins1;

  const timeStrArr2 = endTime.split(":");
  const hrs2 = +timeStrArr2[0] * 60;
  const mins2 = +timeStrArr2[1].substr(0, 2);

  const totalMins2 = hrs2 + mins2;

  if (totalMins2 > totalMins1 && totalMins2 !== totalMins1) {
    return true;
  }

  return false;
}

export function stringTimeToUTCMins(timeStr: string, is12Hours: boolean) {
  if (!timeStr) {
    return "";
  }
  let formattedTime = timeStr;
  if (is12Hours) {
    const timeSuffix = timeStr.slice(-2);
    let hours = timeStr.substr(0, 2);
    if (hours === "12") {
      hours = "00";
    }
    if (timeSuffix === "pm") {
      hours = String(+hours + 12);
    }

    formattedTime = hours + timeStr.substring(2);
  }

  const timeArray = formattedTime.split(":");
  const utcTimestamp = +timeArray[0] * 60  + +timeArray[1].substr(0, 2);

  return utcTimestamp;
}

export function utcTimestampToStringTime24(timestamp: number) {
  const timeStr24 = utcTimestampToStringTime(timestamp);

  if (!timeStr24) {
    return "";
  }
  return timeStr24;
}

export function utcTimestampToStringTime(timestamp: number) {
  const utcHours = Math.floor(+timestamp / 60);
  const utcMins = Math.floor(+timestamp % 60);

  const date = new Date();
  date.setUTCHours(utcHours);
  date.setMinutes(utcMins);

  const localHours = date
    .getHours()
    .toString()
    .padStart(2, "0");
  const localMins = date
    .getMinutes()
    .toString()
    .padStart(2, "0");

  const timeString = localHours + ":" + localMins;

  return timeString;
}

export function checkDateInRange(date1: any, date2: any, dateToCheck: any) {
  const D_1 = date1.split("/");
  const D_2 = date2.split("/");
  const D_3 = dateToCheck.split("/");

  const d1 = new Date(D_1[2], parseInt(D_1[0]) - 1, D_1[1]);
  const d2 = new Date(D_2[2], parseInt(D_2[0]) - 1, D_2[1]);
  const d3 = new Date(D_3[2], parseInt(D_3[0]) - 1, D_3[1]);

  if (d3 >= d1 && d3 <= d2) {
    return true;
  } else {
    return false;
  }

}
