import { Grid , IconButton, Slider, TextField, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import {BottomNav, TopBar} from "../../components";
import Switch from "../../components/SmallSwitch/Switch";
import TimePicker from "../../components/TimePicker/TimePicker";
import scheduletypes from "../../constants/scheduletypes";
import Button from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import {  CheckboxChecked, Delete, MobileLogo, MobileMenu, SchedulesCalendar, SettingsAlert} from "../../icons";
import ArrowIcon from "../../icons/BigArrowUp";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import {
  minsToTime,
} from "../../services/timeService";
import {
   AddIcon,
   ArrowBack,
  AutoMode,
  ColdBlue,
  Edit,
  HotRed,
  Swing
} from "../../svgComponents";
import AddEditSchedule from "./AddEditSchedulePopup/AddEditSchedulePopup";
import unitManagementStyles from "./unitManagement.style";

const Checkbox1 = (props: any) => {
  const useStyles = makeStyles(unitManagementStyles);
  const classes = useStyles();

  return <Checkbox
    color="default"
    edge="end"
    variant="outlined"
    onChange={() => { }}
    onClick={(event: any) => event.stopPropagation()}
    checkedIcon={<CheckboxChecked />}
    className={classes.checkboxStyle}
    {...props}
  />;
};

const UnitManagement = (props: any) => {
  const useStyles = makeStyles(unitManagementStyles);
  const classes = useStyles();
  const history = useHistory();

  const types: any = useStoreState((states) => states.types.allTypes);
  const addMessage = useStoreActions((actions) => actions.errorMessage.addMessage);
  const setEWrcLock = useStoreActions((a) => a.units.setEWrcLock);
  const updateStoreUnit = useStoreActions((a) => a.units._storeUpdateUnit);
  const updateUnit = useStoreActions((actions) => actions.units.updateUnit);
  const getUnit = useStoreState((state) => state.units.getUnit);
  const getDeviceById = useStoreState((state) => state.devices.getDeviceById);
  const {unitId, customerId} = useStoreState((state) => state.selections.mobileSelections);
  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay());
  const me = useStoreState((state) => state.users.me);
  const { temperatureScale } = me;
  const getCustomerSchedules = useStoreActions((a) => a.schedules.getCustomerSchedules);
  const { weekDays = [""], timeFormat: timeFormatTypes  } = types;
  const deleteSchedule = useStoreActions((actions) => actions.schedules.deleteSchedule);
  const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);

  const [weekDaysOptions] = useState<any>(Object.keys(weekDays));
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [reloadUnitsInTopFilters, setReloadUnitsInTopFilters] = useState<boolean>(false);
  const [unit, setUnit] = useState<any>({});
  const [schedules, setSchedules] = useState<any>({});
  const [unitSchedules, setUnitSchedules] = useState<any>({});
  const [schedulesFetched, setSchedulesFetched] = useState<boolean>(false);
  const [selectedScheduleId, openAddEditSchedulePopup] = useState<string>("");
  const [disableMessage, setDisableMessage] = useState<string>("");

  const {
    eWrcEnableOnoff,
    eWrcEnableMode,
    eWrcEnableSetpoint,
    eWrcEnableCoolMode,
    eWrcEnableHeatMode,
    eWrcEnableAutoMode,
    eWrcTemperatureLimits,
    temperatureLimits
  } = unit;

  const tempLimits = eWrcTemperatureLimits ? eWrcTemperatureLimits : temperatureLimits;

  useEffect(() => {
    if (!customerId){
      return;
    }
    schedulesFetched &&  setSchedulesFetched(false);
    getCustomerSchedules({ id: customerId, type: scheduletypes.eDeviceLimits }).then((res: any) => {
            setSchedules(res);
            setSchedulesFetched(true);
          });
  }, [customerId]);

  useEffect(() => {
    if (!unitId) {
      return;
    }

    const unit = getUnit(unitId);
    setUnit(unit);
    const device: any = getDeviceById(unit?.device || "");
    const {isConnected, protocolVersion} = device;
    if (protocolVersion < 4){
      setDisableMessage(t`You need to upgrade your device firmware to use this feature`);
      return;
    }

    if (!isConnected){
      setDisableMessage(t`Device is disconnected`);
      return;
    }
    setDisableMessage("");
  }, [unitId]);

  useEffect(() => {
  if (!unitId || !schedulesFetched){
    return;
  }

  const filteredSchedules: any = {};
  Object.values(schedules).forEach((schedule: any) => {
    const {id, unit} = schedule;
    if (unit === unitId){
      filteredSchedules[id] = schedule;
    }
  });
  setUnitSchedules(filteredSchedules);

}, [unitId, schedulesFetched]);

  const saveUnit = () => {
    const data: any = {
        unitIds: [unitId],
        eWrcTemperatureLimits,
        eWrcEnableOnoff,
        eWrcEnableMode,
        eWrcEnableSetpoint,
        eWrcEnableCoolMode,
        eWrcEnableHeatMode
        // eWrcEnableAutoMode   // not allowed
    };

    setEWrcLock({ id: unitId, data })
    .then((unitA: any) => updateStoreUnit({id: unitId, data}))
    .catch((error: any) => {
      addMessage({ message: error.message });
    });
  };

  const valueLabelFormat = (value: number) => {
      const valueToShow = value + (temperatureScale === 1 ? " °C" : " °F");
      return `${valueToShow}`;
  };

  const handleCoolRange = (event: any, newValue: number | number[]) => {
      setUnit({...unit, eWrcTemperatureLimits: {...tempLimits, 0: newValue}});
  };

  const handleHeatRange = (event: any, newValue: number | number[]) => {
    setUnit({...unit, eWrcTemperatureLimits: {...tempLimits, 1: newValue}});
  };

  const updateLocalSchedules = (schedule: any) => {
    const {id} = schedule;
    unitSchedules[id] = schedule;
    setUnitSchedules({...unitSchedules});
    schedules[id] = schedule;
    setSchedules({...schedules});
  };

  const handleScheduleEnable = (schedule: any) => {
    const {id, isDisabled} = schedule;
    updateSchedule({
      id,
      data: {
        isDisabled: !isDisabled
      }
    })
      .then((updatedSchedule: any) => {
        updateLocalSchedules({...schedule, isDisabled: updatedSchedule.isDisabled});
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      });
  };

  const deleteSelectedSchedule = (scheduleId: string) => {
    deleteSchedule(scheduleId)
      .then(() => {
        delete unitSchedules[scheduleId];
        setUnitSchedules({...unitSchedules});
        delete schedules[scheduleId];
        setSchedules({...schedules});
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      );
  };

  const NoUnitSelected = () => (
      <Grid
      container={true}
      direction={"column"}
      className={classes.noContentContainer}
      >
      <div className={classes.grayOval}>
      <ArrowIcon className={classes.arrowIcon} />
      </div>
      <Typography className={classes.noUnitText}>
      {t`Please select a unit using the above filters.`}
      </Typography>
      </Grid>
    );

  return (
    <>
    <TopBar  leftAction={() => history.push("/dashboard")}
    leftIconComponent={<MobileLogo />}
    />
    <div className={classes.root}>
      {!unitId || _.isEmpty(unit) ? <NoUnitSelected /> :
        <>
        <div className={classes.controlsSection}>
        <Typography className={classes.mainSectionTitle}><SettingsAlert className={classes.alertIcon}/>{t`Restrict Local Controller`}</Typography>
        {disableMessage && <Typography className={classes.errorLabelStyle}>{disableMessage}</Typography>}
        <div style={{padding: "0 10px", display: "flex", flexFlow: "column nowrap", flex: 1, minWidth: "100%", alignItems: "center"}}>
        <Typography className={classes.rowSectionTitle}>{t`On & Off`}
            <Switch
            checked={eWrcEnableOnoff}
            disableRipple={true}
            switchChange={() => setUnit({...unit, eWrcEnableOnoff: !eWrcEnableOnoff})}
            value="checkedA"
            disabled={disableMessage}
            />
        </Typography>
      { /* <Typography className={classes.rowSectionTitle}>{t`Off Only`}
            <Switch
            // checked={}
            disableRipple={true}
            switchChange={(e: any) => {}}
            value="checkedA"
            disabled={disableMessage}
            />
        </Typography>*/}
        <Typography className={classes.rowSectionTitle}>{t`Operation Mode`}
            <Switch
            checked={eWrcEnableMode}
            disableRipple={true}
            switchChange={() => setUnit({...unit, eWrcEnableMode: !eWrcEnableMode})}
            value="checkedA"
            disabled={disableMessage}
            />
        </Typography>
        <Typography className={classes.rowSectionTitle}>{t`Setpoint Change`}
            <Switch
            checked={eWrcEnableSetpoint}
            disableRipple={true}
            switchChange={() => setUnit({...unit, eWrcEnableSetpoint: !eWrcEnableSetpoint})}
            value="checkedA"
            disabled={disableMessage}
            />
        </Typography>

        <div className={classes.controlSectionContainer}>
          <div className={classes.titleContainer}>
              <Typography className={classes.controlSectionTitle}>
                <ColdBlue className={classes.coldIcon}/>
                {t`Min/max Setpoint cooling`}
              </Typography>
            <Switch
            checked={eWrcEnableCoolMode}
            disableRipple={true}
            switchChange={() => setUnit({...unit, eWrcEnableCoolMode: !eWrcEnableCoolMode})}
            value="checkedA"
            disabled={disableMessage}
            />
          </div>
          <div className={classes.sliderHolder}>
          <Slider
              className={classes.coolSlider}
              onChange={handleCoolRange}
              aria-labelledby="discrete-slider-always"
              min={temperatureScale === 1 ? 0 : 32}
              max={temperatureScale === 1 ? 50 : 122}
              value={tempLimits[0]}
              valueLabelDisplay="on"
              valueLabelFormat={valueLabelFormat}
              step={1}
              disabled={!eWrcEnableCoolMode || !!disableMessage}
          />
          </div>
        </div>
        <div className={classes.controlSectionContainer}>
          <div className={classes.titleContainer}>
              <Typography className={classes.controlSectionTitle}>
                <HotRed className={classes.coldIcon} style={{transform: "scale(1.778)"}}/>
                {t`Min/max Setpoint heating`}
              </Typography>
            <Switch
            checked={eWrcEnableHeatMode}
            disableRipple={true}
            switchChange={() => setUnit({...unit, eWrcEnableHeatMode: !eWrcEnableHeatMode})}
            value="checkedA"
            disabled={disableMessage}
            />
          </div>
          <div className={classes.sliderHolder}>
          <Slider
              className={classes.heatSlider}
              onChange={handleHeatRange}
              aria-labelledby="discrete-slider-always"
              min={temperatureScale === 1 ? 0 : 32}
              max={temperatureScale === 1 ? 50 : 122}
              value={tempLimits[1]}
              valueLabelDisplay="on"
              valueLabelFormat={valueLabelFormat}
              step={1}
              disabled={!eWrcEnableHeatMode || !!disableMessage}
          />
          </div>
        </div>
        {/*<div className={classes.controlSectionContainer}>
          <div className={classes.titleContainer}>
              <Typography className={classes.controlSectionTitle}>
                <AutoMode maincolor="#aaa2aa" className={classes.autoIcon} />
                {t`Auto Mode Temp Settings`}
              </Typography>
            <Switch
            checked={eWrcEnableAutoMode}
            disableRipple={true}
            switchChange={(e: any) => {}}
            value="checkedA"
            />
          </div>
          <div className={classes.sliderHolder}>
          <Slider
              className={classes.autoSlider}
              // onChange={() => {}}
              // defaultValue={supportedValues.temperatureLimits[0][temperatureScale]}
              aria-labelledby="discrete-slider-always"
              min={temperatureScale === 1 ? 0 : 32}
              max={temperatureScale === 1 ? 50 : 122}
              value={tempLimits[2]}
              valueLabelDisplay="on"
              valueLabelFormat={valueLabelFormat}
              step={1}
              // ValueLabelComponent={ValueLabelComponent}
              disabled={!eWrcEnableAutoMode}
          />
          </div>
        </div>*/}
      { /* <Typography className={classes.checkboxSection}>
        <Checkbox1 />
        {t`Only if Supported by Indoor Unit`}
        </Typography>*/}
        <Button style={{minHeight: 50, margin: "20px 0px", width: "calc(100% - 20px)"}} disabled={disableMessage} onClick={saveUnit}>{t`Save`}</Button>
      </div>
      </div>
      <div style={{background: "white", display: "flex",
                   flexFlow: "column nowrap",
                   alignItems: "center"}}>
      <Typography className={classes.mainSectionTitle}>
        <SchedulesCalendar className={classes.alertIcon}/>
        {t`Schedules`}
        <IconButton className={classes.btnIcon} disableRipple disabled={!!disableMessage} onClick={() => openAddEditSchedulePopup("new")}>
        <AddIcon className={classes.addIcon}/>
        </IconButton>
      </Typography>
      {Object.values(unitSchedules).map((schedule: any) => {
        const {id, name, days, powerOnTime, powerOffTime, eWrcEnableMode, eWrcEnableOnoff, eWrcEnableSetpoint,
               eWrcEnableCoolMode, eWrcEnableHeatMode, eWrcTemperatureLimits, isDisabled} = schedule;

        return (
          <div key={t`schedule-${id}`} className={classes.scheduleContainer}>
            <div className={classes.scheduleTitleContainer}>
              <Typography className={classes.scheduleName}>{name}</Typography>
              <div className={classes.actionsContainer}>
              <Edit style={{marginRight: 30}} onClick={() => openAddEditSchedulePopup(id)}/>
              <Delete style={{marginRight: 25}} onClick={() => deleteSelectedSchedule(id)}/>
              <Switch
              checked={!isDisabled}
              disableRipple={true}
              switchChange={() => handleScheduleEnable(schedule)}
              value="checkedA"
              />
              </div>
            </div>
            <Typography className={classes.timeLabel}>
            {minsToTime(powerOnTime, timeFormat).replace(/am/g, "AM").replace(/pm/g, "PM")}
            {" "}-{" "}
            {minsToTime(powerOffTime, timeFormat).replace(/am/g, "AM").replace(/pm/g, "PM")}</Typography>
            <DaysList
              days={weekDaysOptions}
              activeDays={days}
              action={() => {}}
              isMobile
              disablePointer
            />
            <div style={{marginBottom: 11}}></div>
            <div className={classes.enablesContainer}>
            {eWrcEnableOnoff &&
              <Typography className={classes.enablesText}>{t`On & Off`}</Typography>
            }
            {eWrcEnableMode &&
              <Typography className={classes.enablesText}>{t`Operation Mode`}</Typography>
            }
            {eWrcEnableSetpoint &&
              <Typography className={classes.enablesText}>{t`Setpoint Change`}</Typography>
            }
            </div>

            {eWrcEnableCoolMode &&
              <div className={classes.modesContainer}>
              <div className={classes.titleContainer} style={{margin: 0}}>
                <Typography className={classes.controlSectionTitle}>
                <ColdBlue className={classes.coldIcon}/>
                {t`Min/max Setpoint cooling`}
                </Typography>
              </div>
              <Typography className={classes.degreeLabel}>
              {valueLabelFormat(eWrcTemperatureLimits[0][0])}
              {" "}-{" "}
              {valueLabelFormat(eWrcTemperatureLimits[0][1])}</Typography>
            </div>
          }

            { eWrcEnableHeatMode &&
              <div className={classes.modesContainer}>
              <div className={classes.titleContainer} style={{margin: 0}}>
                  <Typography className={classes.controlSectionTitle}>
                    <HotRed className={classes.coldIcon} style={{transform: "scale(1.778)"}}/>
                    {t`Min/max Setpoint heating`}
                  </Typography>
              </div>
              <Typography className={classes.degreeLabel}>
              {valueLabelFormat(eWrcTemperatureLimits[1][0])}
              {" "}-{" "}
              {valueLabelFormat(eWrcTemperatureLimits[1][1])}</Typography>
            </div>
          }

          </div>
        );
      })
      }
      </div>
      </>}
    </div>
    <BottomNav  />
    {(selectedScheduleId  === "new" ||  (selectedScheduleId && unitSchedules[selectedScheduleId])) &&
      <AddEditSchedule
      onClose={() => openAddEditSchedulePopup("")}
      schedule={unitSchedules[selectedScheduleId]}
      scheduleId={selectedScheduleId}
      itemType={"unit"}
      itemId={unitId}
      updateLocalSchedules={updateLocalSchedules}
      item={unit}
       />}
    </>
  );
};

export default UnitManagement;
