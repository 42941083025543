import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./AlertInfo.style";

const AlertInfoPopup = (props: any) => {
  const { open, alert, handleClose, updateSelections } = props;

  const classes = useStyles();
  const history = useHistory();
  const allUnits = useStoreState((state) => state.units.allUnits);
  const setSelectedAlertObj = useStoreActions((actions) => actions.setSelectedAlert);
  const types = useStoreState((state) => state.types.allTypes);
  const { eventTypes } = types;
  const isNoDataType = eventTypes.entitiesHealthLowIstat === alert.type
                              || eventTypes.entitiesHealthLowOstat === alert.type
                              || eventTypes.entitiesHealthLowPstat === alert.type
                              || eventTypes.entitiesHealthLowLqstat === alert.type
                              || eventTypes.entitiesHealthLowSstat === alert.type;
  const errorTypes = useStoreState((state) => state.serviceErrorTypes),
    getSystem = useStoreState(
      (state) => state.systems.getSystem
    );
  const getTitle = (systemId: string, errorCode: any) => {
    const system = getSystem(systemId);

    const brand = system?.brandNum || "";
    const error = _.isNaN(+errorCode) ? errorCode : +errorCode;

    return errorTypes?.errorCodeTypes[brand] ? errorTypes.errorCodeTypes[brand][error] : "";
  };
  const getMoreInfo = () => {
    setSelectedAlertObj(alert);
    history.push("/alerts");
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-info-dialog"
        aria-describedby="alert-description-dialog"
        maxWidth="md"
        classes={{ paper: classes.alertInfoDialogPaper }}
      >
          <div className={classes.dialogHeader}>
            <Typography className={classes.headerTitle}>{t`Alert Info`}</Typography>
            <IconButton disableRipple onClick={handleClose} className={classes.iconBtnStyle}>
              <Close color="#7f7692" />
            </IconButton>
          </div>
        <div className={classes.dialogContent}>
            <Card className={classes.detailsContainer} style={{ marginTop: "unset" }}>
              <Typography variant="h6">{t`General Details`}</Typography>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Customer`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {alert.customerName}
                </Typography>
              </Grid>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Site`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {alert.siteName}
                </Typography>
              </Grid>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`System`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {alert.systemName}
                </Typography>
              </Grid>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Device`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {alert.deviceName}
                </Typography>
              </Grid>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Unit`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {eventTypes.deviceDisconnected === alert.type ? "" : alert.unitName}
                </Typography>
              </Grid>
            </Card>
            <Card>
              <Typography variant="h6">{t`Alert Details`}</Typography>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Id`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {alert.shortId}
                </Typography>
              </Grid>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Date`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {alert.time}
                </Typography>
              </Grid>

              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Error Code`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {isNoDataType ? "" : alert.errorCode}
                </Typography>
              </Grid>
              {(alert.type === 7 && alert.unitIds) && (
                <Card>
                  <Typography className={classes.descriptionTitle}>{t`Current Status:`}</Typography>
                  {alert.unitIds.map((unitId: string) => {
                    const { name, isConnected } = allUnits[unitId];

                    return <Grid container key={`alert-${alert.id}-${alert.unitId}`} direction="row" className={classes.row}>
                      <Typography className={classes.rowTitle} style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "96px",
                        paddingRight: "16px"
                      }}> {name}</Typography>
                      <Typography variant="body2" className={classes.rowInfo}>
                        {isConnected ? "connected" : "disconnected"}
                      </Typography>
                    </Grid>;
                  })}
                </Card>
              )}
              {(eventTypes.customTelemetry === alert.type && alert.unitIds) && (
                <Card>
                  <Typography className={classes.descriptionTitle}>{t`Units:`}</Typography>
                  {alert.unitIds.map((unitId: string, index: number) => {
                    const { name } = allUnits[unitId];

                    return <Grid container key={`alert-unit-${index}`} direction="row" className={classes.row}>
                      <Typography className={classes.rowTitle} style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "90%",
                        width: "100%",
                        paddingRight: "16px"
                      }}> {name}</Typography>
                    </Grid>;
                  })}
                </Card>
              )}
              <Card className={classes.description}>
                <Typography className={classes.descriptionTitle}>{t`Description`}</Typography>
                <Typography>{alert.errorDescription  || alert.description}</Typography>
              </Card>
            </Card>
        </div>
        <div className={classes.actionsHolder}>
        <Button
        white
        uppercase
        onClick={getMoreInfo}
        >{t`ALERT LOG`}</Button>
        </div>
      </Dialog>
    </>
  );
};

export default AlertInfoPopup;
