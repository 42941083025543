import {
  Grid,
  Typography
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useLoadScript } from "@react-google-maps/api";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useRouteMatch } from "react-router";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import { t } from "ttag";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import ArrowIcon from "../../icons/ArrowLong";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnitAffiliation } from "../../models/Units";
import useStyles from "./unitStats.style";

const DashboardUnit: React.FC<RouteComponentProps<any> & any> = (props) => {
  // Get history from hook
  const history = useHistory(),
    classes = useStyles(),
    // Get unit id from path
    match = useRouteMatch<{ unitId: string }>();
  let { unitId } = match.params;

  if (!unitId || unitId === "undefined") {
    unitId = "";
  }
  const getUnit = useStoreState((s) => s.units.getUnit);

  const { siteId = "" }: IUnitAffiliation | any = useStoreState((s) =>
    s.units.getUnitAffiliation(unitId)
  );
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const fetchUnitStats = useStoreActions((a) => a.units.fetchUnitStats);
  const fetchUnitDiagByParams = useStoreActions((a) => a.units.fetchUnitDiagByParams);
  const unitSite: any = useStoreState((s) => s.sites.getSite(siteId));
  const selections = useStoreState((s) => s.selections.selections);
  const user = useStoreState((s) => s.users.me);
  const types = useStoreState((s) => s.types.allTypes);
  const [unit, setUnit] = useState<any>(null);
  const [stats, setStats] = useState<any>(null);
  const [paramsData, setParamsData] = useState<any>(null);
  const [range, setRange] = useState<any>(["auto", "auto"]);

  const { temperatureScale } = user;
  const { temperatureScale: temperatureScaleTypes } = types;
  const { dateRange } = selections;

  const getRanges = useCallback(() => {
    const { dateRange } = selections;
    if (!dateRange?.startDate) {
      return;
    }
    if (!siteId || !unitSite || !dateRange?.endDate || !dateRange.startDate) {
      return;
    }
    const { timezone } = unitSite;

    const isDayRange = moment(dateRange.startDate).dayOfYear() === moment(dateRange.endDate).dayOfYear();

    const startTime = moment(dateRange.startDate).tz(timezone).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endTime = moment(dateRange.endDate).tz(timezone).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).add(1, "second");

    const startTimeGMT = startTime.clone().tz("gmt");
    const endTimeGMT = endTime.clone().tz("gmt");

    return {
      bucketSizeMsec: isDayRange ? 60 * 60 * 1000 : 24 * 60 * 60 * 1000,
      startTimeGMT: startTimeGMT.valueOf(),
      endTimeGMT: endTimeGMT.valueOf()
    };

    return null;
  }, [unitSite, selections]);

  useEffect(() => {
    if (unitId !== selections.unitId) {
      updateSelections({ type: "unit", data: unitId });
      return;
    }
  }, [unitId]);

  useEffect(() => {
    if (!unitId || unitId !== selections.unitId) {
      return;
    }

    const range = getRanges();

    if (!range) {
      return;
    }

    const {
      bucketSizeMsec: size,
      startTimeGMT: startTime,
      endTimeGMT: endTime
    } = range;

    fetchUnitStats({ unitId, startTime, endTime, size })
      .then((resp: any) => {
        setStats(resp);
        return fetchUnitDiagByParams({ unitId, startTime, endTime, params: ["49", "50"] });
      })
      .then((diag: any) => {
        setParamsData(diag);
      })
      .catch(() => {
        setStats({});
        setParamsData([]);
      })
      .finally(() => {
        setRange([startTime, endTime]);
      });
  }, [unitId, selections.unitId, dateRange]);

  useEffect(() => {
    if (unitId && selections.unitId && unitId === selections.unitId) {
      setUnit(getUnit(unitId));
      return;
    }
    setUnit(null);
  }, [unitId, selections.unitId]);

  const onUnitSelect = (unitId: string | null) => {
    if (unitId) {
      history.push(`/unit-stats/${unitId}`);
    } else {
      history.push("/unit-stats");
    }
  };

  const CustomizedAxisTick = (props: any) => {
    const {
      x, y, stroke, payload, isDay
    } = props;

    const format = isDay ? "HH:mm" : "DD/MM";
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{moment(payload.value).format(format)}</text>
      </g>
    );
  };

  const isDayRange = moment(dateRange?.startDate).dayOfYear() === moment(dateRange?.endDate).dayOfYear();
  const onTime = stats?.onTime ? (stats.onTime / (1000 * 60 * 60)).toFixed(2) : 0;
  const ticksArr = stats?.buckets?.map((bucket: any) => bucket.timestamp) || [];
  if (ticksArr?.length) {
    if (isDayRange) {
      const last = ticksArr[ticksArr.length - 1];
      ticksArr.push(last + 60 * 60 * 1000);
    }
    else {
      const last = ticksArr[ticksArr.length - 1];
      ticksArr.push(last + 24 * 60 * 60 * 1000);
    }
  }

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.rightSide}>
        <Header
          path={[t`Unit Statistics`]}
          onUnitSelect={onUnitSelect}
          customGeneralNames={{ unit: "Select Unit" }}
          hideAllOptionsFromDropdown={["unit"]}
          showDateRangePicker={true}
        />
        {unit ? (
          <div className={classes.contentContainer}>
            <div className={classes.titleDiv}>
              <Typography className={classes.title}>{unit?.name || ""}</Typography>
            </div>
            <div className={classes.graphDiv}>
              {stats && (
                <ResponsiveContainer width="100%" height="100%">

                  <ComposedChart data={stats?.buckets || []} >
                    <XAxis
                      xAxisId={"mainX"}
                      dataKey="timestamp"
                      scale="time"
                      type="number"
                      height={40}
                      domain={range || ["auto", "auto"]}
                      tick={<CustomizedAxisTick isDay={isDayRange} />}
                      padding={{ left: 20, right: 20 }}
                      interval={0}
                      ticks={ticksArr}
                    />
                    <YAxis
                      yAxisId={"left"}
                      type={"number"}
                      unit={isDayRange ? "Mins" : "h"}
                      tickFormatter={(v: any) => isDayRange ? `${Math.round(v / (1000 * 60))}` : `${Math.round(v / (1000 * 60 * 60))}`}
                      domain={isDayRange ? [0, (1000 * 60 * 60)] : [0, (1000 * 60 * 60 * 24)]}
                      padding={{ top: 20, bottom: 20 }}
                      allowDecimals={false}
                    />
                    <YAxis
                      yAxisId={"right"}
                      type={"number"}
                      domain={["auto", "auto"]}
                      orientation={"right"}
                      padding={{ top: 20, bottom: 20 }}
                      unit={temperatureScaleTypes[temperatureScale] === "celsius" ? "°C" : "°F"}
                    />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Bar name="Cool" xAxisId={"mainX"} yAxisId={"left"} dataKey="unitBucketCoolTime" stackId={"a1"} barSize={20} fill="#35a8e0" />
                    <Bar name="Heat" xAxisId={"mainX"} yAxisId={"left"} dataKey="unitBucketHeatTime" stackId={"a1"} barSize={20} fill="#f05146" />
                    <Bar name="Other" xAxisId={"mainX"} yAxisId={"left"} dataKey="unitBucketOtherTime" stackId={"a1"} barSize={20} fill="#aaa2aa" />
                    {paramsData &&
                      (
                        <Line
                          name={t`Room Temp`}
                          xAxisId={"mainX"}
                          yAxisId={"right"}
                          data={paramsData}
                          dataKey={49}
                          type="monotone"
                          stroke={"#00b506"}
                          legendType="none"
                          dot={false}
                        />

                      )}

                    {paramsData &&
                      (
                        <Line
                          name={t`Set Point`}
                          xAxisId={"mainX"}
                          yAxisId={"right"}
                          data={paramsData}
                          dataKey={50}
                          type="monotone"
                          stroke={"#5e5764"}
                          legendType="none"
                          dot={false}
                        />

                      )}

                  </ComposedChart>
                </ResponsiveContainer>)}
            </div>

            <div className={classes.bottomDiv}>
              <div className={classes.bottomSection}>
                <div className={classes.legendItem}>
                  <div className={clsx(classes.legendIcon, classes.roomTempIcon)} />
                  <Typography>{t`Room Temp`}</Typography>
                </div>

                <div className={classes.legendItem}>
                  <div className={clsx(classes.legendIcon, classes.setPointIcon)} />
                  <Typography>{t`Set Point`}</Typography>
                </div>

                <div className={classes.legendItem}>
                  <div className={clsx(classes.legendIcon, classes.coolIcon)} />
                  <Typography>{t`Cool`}</Typography>
                </div>
                <div className={classes.legendItem}>
                  <div className={clsx(classes.legendIcon, classes.heatIcon)} />
                  <Typography>{t`Heat`}</Typography>
                </div>
              </div>

              <div className={clsx(classes.bottomSection, classes.summarySection)}>
                {/* <div className={classes.summaryBox}> */}
                <div className={classes.summaryInfoLine}>
                  <Typography className={classes.summaryText}>{t`Total Working Hours`}</Typography>
                  <Typography className={classes.summaryInfo}>{onTime}</Typography>
                  <Typography className={classes.summaryUnit}>{t` Hrs`}</Typography>
                </div>

                {/* </div> */}
              </div>

            </div>

          </div>
        ) : (
            <Grid container direction={"column"} className={classes.noContentContainer}>
              <div className={classes.grayOval}>
                <ArrowIcon className={classes.arrowIcon} />
              </div>
              <Typography className={classes.noUnitText}>
                {t`Please select a unit using the above filters.`}
              </Typography>
            </Grid>
          )
        }
      </div >
    </div >
  );
};

export default DashboardUnit;
