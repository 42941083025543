import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles({
  icon: {
    marginLeft: "10px",
    color: "rgba(255, 255, 255, 0.8)",
    width: "18px"
  },
  title: {
    color: "#ffffff",
    fontFamily: "Roboto",
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    lineHeight: "normal",
    letterSpacing: "normal",
    fontWeight: "normal",
    height: "70px"
  },
  menuItem: {
    display: "flex",
    alignSelf: "flex-end",
    height: 36,
    padding: 0
  },
  menu: {
    "& >div": {
      display: "flex",
      alignSelf: "flex-end",
      top: "60px !important"
    }
  },
  hiddenItem: {
    fontFamily: "Roboto",
    fontSize: "13px",
    fontWeight: "normal",
    height: "0px",
    visibility: "hidden",
    padding: "0px"
  },
  userIcon: {
    marginRight: "10px",
    "& > g > circle": {
      fillOpacity: 0.3
    }
  }
});
export default useStyles;
