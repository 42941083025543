import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: "100%",
    height: "100%",
    padding: "0 15px",
    boxSizing: "border-box"
  },
  systemOverviewCard: {
    padding: "1rem",
    height: "330px"
  },
  view: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    background: "rgb(236, 235, 239)",
    overflow: "hidden"
  },

  rightSide: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "calc(100% - 240px)"
  },

  contentContainer: {
    width: "100%",
    height: "100%",
    padding: "0.5rem",
    boxSizing: "border-box",
    overflowX: "hidden"
  },
  noContentContainer: {
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    width: "calc(100% - 60px)",
    height: "calc(100% - 130px)",
    alignSelf: "center",
    marginTop: "30px"
  },
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%"
  },
  arrowIcon: { fontSize: "91px", transform: "rotate(90deg)" },

  unitInfoContainer: {
    display: "flex"
    // paddingBottom: '0.5rem'
    // maxHeight: "15%"
  },

  halfPadding: {
    padding: "0.5rem"
  },

  paramsContainer: {
    // padding: '1.2rem 0.8rem 0.4rem !important',
    maxHeight: "660px"
  },

  viewBar: {
    background: "rgba(41, 19, 46, 0.95)"
  },

  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "white",
    marginBottom: "1.5rem",
    width: "100%",
    lineHeight: "6rem",
    borderBottom: "1px rgb(87, 69, 92) solid",
    padding: "0 10px 0 10px"
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1.5rem",
    fontWeight: "bold",
    padding: "0.2rem"
  },

  unitInfo: {
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    flex: "auto"
  },

  tempInfo: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flex: 3
  },

  unitControl: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flex: 4
  },

  errorLogsCard: {
    height: "100%",
    paddingBottom: "25px"
  },
  bottomContainer: {
    padding: "0.5rem",
    height: "40%"
  },
  statsRapper: {
    height: "100%"
  },
  midContainer: {},
  noUnitText: {
    fontSize: "26px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#545964",
    marginTop: "43px"
  },
  previewImg: {
    width: "100%",
    height: "100%"
  },
  dialogStyle: {
    minHeight: "90%",
    minWidth: "90%"
  },
  frameStyle: {
    height: "calc(100vh - 100vh * 0.1 - 135px)"
  },
  imagePreview: {
    maxWidth: "40%",
    maxHeight: "40%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block"
  }
}));

export default useStyles;
