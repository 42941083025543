import { t } from "ttag";

export const symbols: any = {
  "Temperature": "°",
  "PPM": "\u209A\u209A\u2098" ,
  "RPM": "\u1D63\u209A\u2098",
  "Open/Close": "",
  "Percent": "%"
  };

export default symbols;
