import { MenuItem, Select, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import SvgArrow from "../../icons/Arrow";
import useStyles from "./MenuDropDown.style";

const MenuDropDown = (props: any) => {
  const classes = useStyles();
  const {className, value, options, onChange, emptyOption, isEmptyOptionDisabled = false, setStyle= false, controlDisabled= false } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string);
  };

  const onExitedSelect = (node: HTMLElement): void => {
    (document.activeElement as HTMLElement).blur();
};

  const menuFilledStyle = setStyle ? "" : classes.selectStyle;
  return (
    <Select
      variant="filled"
      labelId="to-set-name"
      value={value}
      onChange={handleChange}
      className={clsx(classes.selectContainer, {[className]: className})}
      classes={{
        filled: menuFilledStyle,
        icon: classes.arrowIcon,
        select: classes.focusStyle,
        iconFilled: classes.iconStyle
      }}
      displayEmpty={!setStyle}
      disableUnderline
      MenuProps={{
        classes: { paper: classes.paperStyle },
        onExited: onExitedSelect,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      IconComponent={SvgArrow}
      disabled={controlDisabled ? false : options.length === 1}
    >
      {options &&
        options.map((option: any) => {
          return (
            <MenuItem key={option.key} value={option.value} classes={{ selected: classes.selected }} className={classes.optionStyle}>
              <Typography className={classes.optionText}>{option.name}</Typography>
            </MenuItem>
          );
        })}
    </Select>
  );
};

export default MenuDropDown;
