class AssetsService {
  getResourcePath(resourceName) {
    return `${process.env.PUBLIC_URL}/assets/${window.location.hostname}/${resourceName}`;
  }
  getImagesPath(resourceName) {
    return `${process.env.PUBLIC_URL}/assets/common/images/${resourceName}`;
  }
  getBrandLogo(resourceName) {
    return `${process.env.PUBLIC_URL}/assets/common/brands/logos/${resourceName}`;
  }
}

export default new AssetsService();
