import {
    Button,
    Card,
    Dialog,
    Grid,
    IconButton,
    Slider, Tooltip, Typography,
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { ModesList } from "../../components/ModesList";
import Switch from "../../components/SmallSwitch/Switch";
import SwitchList from "../../components/SwitchList/SwitchList";
import { modeIcons } from "../../constants/modes";
import scheduletypes from "../../constants/scheduletypes";
import StyledButton from "../../cool_widgets/Button";
import { Plus } from "../../icons";
import { Close } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IServiceTypes } from "../../models/Types";
import { IUnit } from "../../models/Units";
import assetsService from "../../services/assetsService";
import {
    FanMode,
} from "../../svgComponents";
import NewApplySettings from "./NewApplySettings";
import ScheduleIUnitItem from "./ScheduleIUnitItemA";
import useStyles from "./UnitSettings.style";

interface Props {
    children: React.ReactElement;
    open: boolean;
    value: any;
}
function ValueLabelComponent(props: Props) {
    const classes = useStyles();
    const { children, open, value } = props;

    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            placement="bottom"
            title={value}
            classes={{ popper: classes.tooltip, tooltip: classes.tooltipBox }}
        >
            {children}
        </Tooltip>
    );
}
const UnitSetting: React.FC<any> = (props: any) => {
    const classes = useStyles();
    const isInitialized = useStoreState((s) => s.isInitialized);
    const temperatureScale = useStoreState((state) => state.users.me.temperatureScale);
    const [useTemperatureScale, setUseTemperatureScale] = useState(temperatureScale);
    const selections = useStoreState((s) => s.selections.selections);
    const updateSelections = useStoreActions((a) => a.selections.updateSelections);
    const createScheduleAPI = useStoreActions((actions) => actions.schedules.createObjectSchedules);
    const [indoorUnits, setIndoorUnits] = useState<any>([]);
    const [selectedUnit, setSelectedUnit] = useState<IUnit | null>(null);
    const [unitsToSave, setUnitsToSave] = useState<any>([]);
    const allDevices = useStoreState((state) => state.devices.allDevices);
    const allSites = useStoreState((state) => state.sites.allSites);
    const allUnits = useStoreState((state) => state.units.allUnits);
    const getUnitInfo = useStoreState((state) => state.units.getUnitInfo);
    const types = useStoreState((states) => states.types.allTypes);
    const getCustomerSchedules = useStoreActions((a) => a.schedules.getCustomerSchedules);

    const deleteSchedule = useStoreActions((actions) => actions.schedules.deleteSchedule);
    const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);
    const updateStoreUnit = useStoreActions(
        (a) => a.units._storeUpdateUnit
    );

    const [allCustomerSchedules, setSllCustomerSchedules] = useState<any>({});
    const [supportedValues, setSupportedValues] = useState<any>({});
    const [tempLimitFlags, setTempLimitFlags] = useState<any>({
        enableCoolMode: false,
        enableHeatMode: false,
        enableAutoMode: false
    });
    const [tStatFlags, setTStatFlags] = useState<any>({
        enableOnoff: false,
        enableSetpoint: false
    });
    const [isUnitVisible, setIsUnitVisible] = useState<boolean | undefined>(true);
    const [createNewSchedule, setCreateNewSchedule] = useState<any>(false);
    const [selectedSchedule, setSelectedSchedule] = useState<any>(null);
    const updateUnits = useStoreActions((action) => action.units.updateUnits);
    const updateUnitsAndSchedules = useStoreActions((action) => action.units.updateUnitsAndSchedules);
    const memoSideMenu = useCallback(() => sideMenu(), [selections, unitsToSave]);
    const schedulesMemo = useCallback(() => schedules(), [allCustomerSchedules, selectedUnit, createNewSchedule, allCustomerSchedules]);
    const [isApply, setIsApply] = useState<boolean>(false);
    const [helperMessgae, setHelperMessgae] = useState<string>("");
    const [disableAllSettings, setDisableAllSettings] = useState<boolean>(false);

    const getSchedules = () => {
        getCustomerSchedules({ id: selections.customerId, type: scheduletypes.unitLimits }).then((res: any) => {
            setSllCustomerSchedules(res);
        });
    };

    // get schedules when customer or unit change
    useEffect(() => {
        if (!selections.customerId) {
            return;
        }
        getSchedules();
    }, [selections.customerId, selectedUnit]);

    // update indoor units when all units change or when site\customer\scale change
    useEffect(() => {
        const filterType = 1;

        const indoorUnitsOnly = Object.values(allUnits).filter((unit: any) => {
            const unitSystemId = unit.system;
            const unitDevice = allDevices[unit.device];
            if (_.isUndefined(unitDevice)) { return false; }
            const unitSite = allSites[unitDevice.site];
            if (_.isUndefined(unitSite)) { return false; }
            const unitCustomerId = unitSite.customer;
            if (_.isUndefined(unitCustomerId)) { return false; }

            return (
                unit.type === filterType &&
                (selections.customerId ? unitCustomerId === selections.customerId : true) &&
                (selections.siteId ? unitSite.id === selections.siteId : true) &&
                (selections.systemId ? unitSystemId === selections.systemId : true)
            );
        });

        if (!_.isEqual(indoorUnits, indoorUnitsOnly)) {
            setIndoorUnits(indoorUnitsOnly);
            setUseTemperatureScale(temperatureScale);
        }
    }, [allUnits, selections.siteId, selections.customerId, temperatureScale]);

    // When entering the page - if unit was selected already - show it
    useEffect(() => {
        if (selections.unitId) {

            const unit = _.find(indoorUnits, (unit) => unit.id === selections.unitId);

            if (!unit || (selectedUnit && unit.id === selectedUnit.id)) {
                return;
            }

            setSelectedUnit(unit);
        }
    }, [selections.unitId]);

    // update supported values when they change in the unit from all units
    useEffect(() => {
        if (!selectedUnit) {
            return;
        }

        const values: any = {};
        const unit = _.cloneDeep(allUnits[selectedUnit.id]);
        if (unit) {
            values.temperatureLimits = unit.temperatureLimits;
            setTStatFlags({
                enableOnoff: unit.enableOnoff || false,
                // enableMode: unit.enableMode || false,
                enableSetpoint: unit.enableSetpoint || false
                // enableOnState: unit.enableOnState || false
            });
            setTempLimitFlags({
                enableCoolMode: unit.enableCoolMode || false,
                enableHeatMode: unit.enableHeatMode || false,
                enableAutoMode: unit.enableAutoMode || false
            });

        }

        setSupportedValues({ ...supportedValues, ...values });

    }, [
        selectedUnit && allUnits[selectedUnit.id].enableOnoff, selectedUnit && allUnits[selectedUnit.id].enableMode,
        selectedUnit && allUnits[selectedUnit.id].enableSetpoint, selectedUnit && allUnits[selectedUnit.id].enableOnState,
        selectedUnit && allUnits[selectedUnit.id].enableCoolMode, selectedUnit && allUnits[selectedUnit.id].enableHeatMode,
        selectedUnit && allUnits[selectedUnit.id].enableAutoMode
    ]);

    // update supported values when selecting a unit
    useEffect(() => {
        const values: any = {};
        const unit = _.cloneDeep(selectedUnit);
        if (unit) {
            values.supportedOperationModes = unit.supportedOperationModes;
            values.supportedFanModes = unit.supportedFanModes;
            values.supportedSwingModes = unit.supportedSwingModes;
            values.temperatureLimits = unit.temperatureLimits;
            setTStatFlags({
                enableOnoff: unit.enableOnoff || false,
                // enableMode: unit.enableMode || false,
                enableSetpoint: unit.enableSetpoint || false
                // enableOnState: unit.enableOnState || false
            });
            setIsUnitVisible(unit.isVisible);
            setTempLimitFlags({
                enableCoolMode: unit.enableCoolMode || false,
                enableHeatMode: unit.enableHeatMode || false,
                enableAutoMode: unit.enableAutoMode || false
            });
        }

        setSupportedValues(values);

    }, [selectedUnit]);

    // Update tStatLock when it changes
    useEffect(() => {
        if (selectedUnit) {
            setTStatFlags({
                enableOnoff: selectedUnit.enableOnoff,
                // enableMode: selectedUnit.enableMode,
                enableSetpoint: selectedUnit.enableSetpoint
                // enableOnState: selectedUnit.enableOnState
            });
        }

    }, [
        selectedUnit && selectedUnit.enableOnoff, selectedUnit && selectedUnit.enableMode,
        selectedUnit && selectedUnit.enableSetpoint, selectedUnit && selectedUnit.enableOnState
    ]);

    // Update tempLimits flags when it changes
    useEffect(() => {
        if (selectedUnit) {
            setTempLimitFlags({
                enableCoolMode: selectedUnit.enableCoolMode,
                enableHeatMode: selectedUnit.enableHeatMode,
                enableAutoMode: selectedUnit.enableAutoMode
            });
        }

    }, [selectedUnit && selectedUnit.enableCoolMode, selectedUnit && selectedUnit.enableHeatMode, selectedUnit && selectedUnit.enableAutoMode
    ]);

    // update selected unit when user selected a unit or when indoor units change
    useEffect(() => {
        if (!selections.unitId) {
            return;
        }

        const unit = _.find(indoorUnits, (unit) => unit.id === selections.unitId);
        if (!unit) {
            return;
        }

        const unitDevice = allDevices[unit.device];
        if (unitDevice && !unitDevice.isConnected) {
            setDisableAllSettings(true);
            setHelperMessgae("Device is disconnected");
        }

        if (unitDevice && unitDevice.isConnected) {
            setDisableAllSettings(false);
        }

        if (wasControlChange(unit)) {
            return;
        }

        setSelectedUnit(unit);

    }, [selections.unitId, indoorUnits, allDevices]);

    const wasControlChange = (unit: any) => {
        if (!selectedUnit || !unit) {
            return false;
        }

        const checkSelectedUnit: any = _.clone(selectedUnit);
        const checkUpdatedSelectedUnit: any = _.clone(unit);

        delete checkSelectedUnit.activeSetpoint;
        delete checkSelectedUnit.activeOperationMode;
        delete checkSelectedUnit.activeOperationStatus;
        delete checkSelectedUnit.activeFanMode;
        delete checkSelectedUnit.activeSwingMode;

        delete checkUpdatedSelectedUnit.activeSetpoint;
        delete checkUpdatedSelectedUnit.activeOperationMode;
        delete checkUpdatedSelectedUnit.activeOperationStatus;
        delete checkUpdatedSelectedUnit.activeFanMode;
        delete checkUpdatedSelectedUnit.activeSwingMode;

        if (_.isEqual(checkSelectedUnit, checkUpdatedSelectedUnit)) {
            return true;
        }

        return false;
    };

    const getUnitKeyOfMode = (typesMode: string) => {
        let unitModeName: any = "";
        switch (typesMode) {
            case "operationModes":
                unitModeName = "supportedOperationModes";
                break;
            case "fanModes":
                unitModeName = "supportedFanModes";
                break;
            case "swingModes":
                unitModeName = "supportedSwingModes";
                break;
            case "cooling":
                unitModeName = 0;
                break;
            case "heat":
                unitModeName = 1;
                break;
            case "auto":
                unitModeName = 2;
                break;
            default:
                unitModeName = null;
                break;
        }
        return unitModeName;
    };
    const changeSupportedValues = (e: any, mode: string) => {
        const isChecked = e.target.checked;
        const modeName = getUnitKeyOfMode(mode);
        const updatedValues: any = { ...supportedValues };

        if (!modeName) {
            return;
        }
        updatedValues[modeName] = !isChecked
            ? []
            : Object.keys(types[mode as keyof IServiceTypes]).map((key: any) => +key);
        setSupportedValues(updatedValues);
    };
    const changeSupportedModes = (e: any, mode: string) => {
        const isChecked = e.target.checked;
        const modeNum = getUnitKeyOfMode(mode);
        const updatedValues: any = { ...supportedValues };
        if (isChecked && !_.includes(updatedValues.supportedOperationModes, modeNum)) {
            updatedValues.supportedOperationModes.push(modeNum);
        }
        if (!isChecked && _.includes(updatedValues.supportedOperationModes, modeNum)) {
            const checkIndex = updatedValues.supportedOperationModes.indexOf(modeNum);
            if (checkIndex > -1) {
                updatedValues.supportedOperationModes.splice(checkIndex, 1);
            }
        }
        setSupportedValues(updatedValues);
    };
    const valueLabelFormat = (value: number) => {
        const valueToShow = value + (useTemperatureScale === 1 ? " °C" : " °F");
        return `${valueToShow}`;
    };

    if (!isInitialized) { return <Loading />; }
    const updateValues = (modeName: string, modeNumber: any) => {
        const locValues: any = { ...supportedValues };
        const unitModeName = getUnitKeyOfMode(modeName);
        if (!unitModeName) {
            return;
        }

        const checkIndex = locValues[unitModeName].indexOf(+modeNumber);
        if (checkIndex > -1) {
            locValues[unitModeName].splice(checkIndex, 1);
        } else {
            locValues[unitModeName] = [...locValues[unitModeName], +modeNumber];
        }
        setSupportedValues(locValues);
    };
    const handleSetCooling = (event: any, newValue: number | number[]) => {
        const locValues: any = { ...supportedValues };
        locValues.temperatureLimits[0] = newValue;
        setSupportedValues(locValues);
    };
    const handleSetHeat = (event: any, newValue: number | number[]) => {
        const locValues: any = { ...supportedValues };
        locValues.temperatureLimits[1] = newValue;
        setSupportedValues(locValues);
    };
    const handleSetAuto = (event: any, newValue: number | number[]) => {
        const locValues: any = { ...supportedValues };
        locValues.temperatureLimits[2] = newValue;
        setSupportedValues(locValues);
    };

    const handleSetTempLimitRange = (flagName: string) => {
        const nextFlagState = _.clone(tempLimitFlags);
        nextFlagState[flagName] = !nextFlagState[flagName];
        setTempLimitFlags(nextFlagState);
    };
    const getUnit = (unitId: string) => {
        const res = getUnitInfo(unitId);
        return (
            <div>{t`${res.customer.name}, ${res.site.name}, ${res.system ? res.system.name : "not assigned"},
             ${res.unit.name}`} </div>
        );
    };
    const handleAgree = (units: any) => {
        setUnitsToSave(units);
    };
    const handleSave = (unitId: any = "") => {
        const data: any = {
            unitIds: unitId ? [unitId] : unitsToSave,
            supportedOperationModes: supportedValues.supportedOperationModes,
            supportedFanModes: supportedValues.supportedFanModes,
            supportedSwingModes: supportedValues.supportedSwingModes,
            temperatureLimits: supportedValues.temperatureLimits,
            isVisible: isUnitVisible,
            enableOnoff: tStatFlags.enableOnoff || false,
            enableMode: tStatFlags.enableMode || false,
            enableSetpoint: tStatFlags.enableSetpoint || false,
            enableOnState: tStatFlags.enableOnState || false,
            enableCoolMode: tempLimitFlags.enableCoolMode || false,
            enableHeatMode: tempLimitFlags.enableHeatMode || false,
            enableAutoMode: tempLimitFlags.enableAutoMode || false
        };

        if (!unitId) {
            data.sourceUnit = selections.unitId;
            updateUnitsAndSchedules({ updatedData: data });

            for (let i = 0; i < unitsToSave.length; i++) {
                updateStoreUnit({
                    id: unitsToSave[i],
                    data
                });
            }
            return;
        }

        updateUnits({ updatedData: data });

        updateStoreUnit({
            id: unitId,
            data
        });

    };

    const handleCancel = () => {
        const values: any = {};
        const unitOrigin = _.find(indoorUnits, (unit) => unit.id === selections.unitId);
        const unit = _.cloneDeep(unitOrigin);
        if (unit) {
            values.supportedOperationModes = unit.supportedOperationModes;
            values.supportedFanModes = unit.supportedFanModes;
            values.supportedSwingModes = unit.supportedSwingModes;
            values.temperatureLimits = unit.temperatureLimits; setTStatFlags({
                enableOnoff: unit.enableOnoff || false,
                // enableMode: unit.enableMode || false,
                enableSetpoint: unit.enableSetpoint || false
                // enableOnState: unit.enableOnState || false
            });
            setIsUnitVisible(unit.isVisible);
            setTempLimitFlags({
                enableCoolMode: unit.enableCoolMode || false,
                enableHeatMode: unit.enableHeatMode || false,
                enableAutoMode: unit.enableAutoMode || false
            });
        }

        setSupportedValues(values);
    };
    const removeUnit = (unit: IUnit) => {
        const updatedUnits: any = [...unitsToSave];
        if (_.includes(unitsToSave, unit.id)) {
            const checkIndex = updatedUnits.indexOf(unit.id);
            if (checkIndex > -1) {
                updatedUnits.splice(checkIndex, 1);
            }
        }
        setUnitsToSave(updatedUnits);
    };

    const noUnitSelect = () => {
        return <div />;
    };

    const sideMenu = () => {
        return <NewApplySettings
            save={handleAgree}
            units={unitsToSave}
            noPopup={true}
            oneCustomer={{ id: selections.customerId }}
            oneSite={selections.siteId}
            allTenants={[]}
            tenantId="9999"
            noDisable={true}
            useServiceUnits={false}
        />;
    };

    const saveSchedule = async (data: any) => {
        data.type = scheduletypes.unitLimits;
        await createScheduleAPI({
            data,
            objId: selectedUnit ? selectedUnit.id : "",
            objectType: "unit"
        });
        setSelectedSchedule(null);
        getSchedules();
    };

    const onUpdateSchedule = async (data: any) => {

        data.type = scheduletypes.unitLimits;
        await updateSchedule({ id: selectedSchedule.id, data });
        setSelectedSchedule(null);
        getSchedules();
    };

    const onUpdateDisableState = async (data: any) => {

        data.type = scheduletypes.unitLimits;
        await updateSchedule({ id: data.id, data: { isDisabled: data.isDisabled } });
        // setSelectedSchedule(null)
        // getSchedules();
    };

    const onDelete = async (idObj: any) => {
        await deleteSchedule(idObj.id);
        getSchedules();
    };

    const schedules = () => {
        const unitSchedules = _.reduce(allCustomerSchedules, (arr: any[], item: any) => {
            if (item.unit === selections.unitId) {
                arr.push(item);
            }

            return arr;
        }, []);

        return (
            <>
                <Dialog
                    aria-labelledby="add-edit-schedule-dialog"
                    open={createNewSchedule}
                    maxWidth="lg"
                    classes={{ paperWidthLg: classes.dialogStyle }}
                    fullWidth
                // classes={{ paperWidthLg: clsx(classes.dialogStyle, { [classes.systemsDialogStyle]: props.onStep === 2 }) }}
                >
                    <ScheduleIUnitItem
                        selectedSchedule={selectedSchedule}
                        onSave={selectedSchedule ? onUpdateSchedule : saveSchedule}
                        onCancle={() => {
                            setCreateNewSchedule(!createNewSchedule);
                            setSelectedSchedule(null);
                        }}
                        canEdit={true}
                        title={!selectedSchedule ? "Add Schedule " : "Edit Schedule"}
                        useTemperatureScale={useTemperatureScale}
                    />
                </Dialog>

                <div className={classes.scheduleContainer}>
                    <div className={classes.newScheduleBtn} >
                        <Typography className={classes.headerTitle}>Schedules</Typography>
                        <StyledButton
                            white
                            width={187}
                            onClick={() => setCreateNewSchedule(!createNewSchedule)}
                            startIcon={<Plus className={classes.plusIcon} />}
                        >{t`Add Schedule`}

                        </StyledButton>
                    </div>

                    < >
                        {_.map(unitSchedules, (schedule) => {

                            return (
                                <ScheduleIUnitItem
                                    selectedSchedule={schedule}
                                    onSave={updateSchedule}
                                    onCancle={() => setCreateNewSchedule(!createNewSchedule)}
                                    canEdit={false}
                                    key={schedule.id}
                                    onDelete={onDelete}
                                    onEditAction={() => {
                                        setCreateNewSchedule(!createNewSchedule);
                                        setSelectedSchedule(schedule);
                                    }}
                                    onDisableUpdate={onUpdateDisableState}
                                    useTemperatureScale={useTemperatureScale}
                                />
                            );
                        })}
                    </>
                </div>
            </>
        );
    };

    const getOnApplyConfirmation = () => {
        return (<Dialog
            open={isApply}
            onClose={() => setIsApply(false)}
            aria-labelledby="delete-alert-dialog-title"
            aria-describedby="delete-alert-dialog-description"
            classes={{ paper: classes.confirmationDialog }}
        >
            <div
                className={classes.dialogHeader}
            >
                <Typography className={classes.headerTitle}>{t`Apply all setting to destination units`}</Typography>
                <IconButton onClick={() => setIsApply(false)} className={classes.iconBtnStyle}>
                    <Close color="#7f7692" />
                </IconButton>
            </div>
            <div
                className={classes.dialogContent}
            >
                <Typography
                    className={classes.confirmationContentText}>
                    {t`Are you sure? This operation will delete schedules in the destination units`}
                </Typography>
            </div>
            <div
                className={classes.actionsHolder}
            >
                <StyledButton
                    autoFocus
                    onClick={() => setIsApply(false)}
                    width={140}
                    uppercase
                    white
                    marginRight
                >
                    {t`Cancel`}
                </StyledButton>
                <StyledButton
                    onClick={() => {
                        setIsApply(false);
                        handleSave();
                    }}
                    width={140}
                    uppercase
                >
                    {t`Ok`}
                </StyledButton>

            </div>
        </Dialog>);
    };

    const canSee = true;

    return (
        <div className={classes.view}>
            {getOnApplyConfirmation()}
            <ServiceNavigationBar {...props} />
            <div className={classes.contentArea}>
                <Header hideSystemSelection={true} disableSystemUpdate={true} path={["Unit App Controls"]} ignoreSystemFilterForUnits={true} />
                {!selections.unitId && noUnitSelect()}
                {selections.unitId &&
                    <Grid
                        container
                        className={classes.container}
                        spacing={3}
                    >
                        <Grid item xs={9} className={classes.gridLeft}>
                            {(supportedValues.temperatureLimits && selectedUnit) &&
                                <Card className={clsx(classes.modeContent, classes.modeContentUnitSettings)}>

                                    <>
                                        <div className={clsx(classes.settingsHeaderContainer, { [classes.withMessage]: disableAllSettings })}>
                                            <Typography className={classes.headerTitle}>{selectedUnit && selectedUnit?.name}</Typography>
                                            <Typography className={classes.errorTitle}>{disableAllSettings ? helperMessgae : ""}</Typography>
                                        </div>

                                        <div className={classes.tStatContainer}>
                                            <SwitchList unit={selectedUnit} tStatFlags={tStatFlags} setTStatFlags={setTStatFlags} unitLimits={true} />
                                            <div className={classes.switchContainer}>
                                                <Typography className={classes.switchTitle}>{t`Show unit in control unit list`}</Typography>
                                                <Switch
                                                    checked={isUnitVisible}
                                                    disableRipple={true}
                                                    switchChange={() => setIsUnitVisible(!isUnitVisible)}
                                                />
                                            </div>
                                        </div>

                                        < div className={classes.modeCardUnitSettings}>

                                            <div className={clsx(classes.limitsLineGroup, classes.addRightMargin)}>
                                                <Card className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}
                                                >
                                                    <div className={classes.modeHeader}>
                                                        <div className={classes.unitModeHeader}>
                                                            <Typography className={classes.unitTitle}>{t`Allow mode settings`}</Typography>
                                                        </div>
                                                        <Switch
                                                            checked={
                                                                !!(supportedValues.supportedOperationModes &&
                                                                    supportedValues.supportedOperationModes.length)
                                                            }
                                                            switchChange={(e: any) =>
                                                                changeSupportedValues(e, "operationModes")
                                                            }
                                                        />
                                                    </div>
                                                    <ModesList
                                                        modeName={"operationModes"}
                                                        activeModes={supportedValues.supportedOperationModes}
                                                        action={updateValues}
                                                    // customClass={classes.operationModes}
                                                    />
                                                </Card>
                                                <Card className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}
                                                >
                                                    <div className={classes.modeHeader}>
                                                        <div className={classes.unitModeHeader}>
                                                            <FanMode className={classes.fanIcon} />
                                                            <Typography className={clsx(classes.unitTitle, classes.modesTitle)}>{t`Allow fan speed control`}</Typography>
                                                        </div>
                                                        <Switch
                                                            checked={
                                                                !!(supportedValues.supportedFanModes &&
                                                                    supportedValues.supportedFanModes.length)
                                                            }
                                                            switchChange={(e: any) =>
                                                                changeSupportedValues(e, "fanModes")
                                                            }
                                                        />
                                                    </div>
                                                    <ModesList
                                                        modeName={"fanModes"}
                                                        activeModes={supportedValues.supportedFanModes}
                                                        action={updateValues}
                                                        customClass={classes.fanModes}
                                                    />
                                                </Card>
                                                <Card className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}>
                                                    <div className={classes.modeHeader}>
                                                        <div className={classes.unitModeHeader}>
                                                            <img
                                                                src={assetsService.getImagesPath(`louver.svg`)}
                                                                className={classes.imgIconStyle}
                                                            ></img>
                                                            <Typography className={classes.unitTitle}>{t`Allow swing control`}</Typography>
                                                        </div>
                                                        <Switch
                                                            checked={
                                                                !!(supportedValues.supportedSwingModes &&
                                                                    supportedValues.supportedSwingModes.length)
                                                            }
                                                            switchChange={(e: any) =>
                                                                changeSupportedValues(e, "swingModes")
                                                            }
                                                        />
                                                    </div>
                                                    <ModesList
                                                        modeName={"swingModes"}
                                                        activeModes={supportedValues.supportedSwingModes}
                                                        action={updateValues}

                                                    />
                                                </Card>
                                            </div>
                                            <div className={classes.limitsLineGroup}>
                                                <Card
                                                    className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}
                                                >
                                                    <div className={classes.modeHeader}>
                                                        <div className={classes.unitModeHeader}>
                                                            <img
                                                                src={assetsService.getImagesPath(`${modeIcons[1]}.svg`)}
                                                                className={classes.imgIconStyle}
                                                            ></img>
                                                            <Typography className={classes.unitTitle}>
                                                                {t`Limit heating setpoint range`}</Typography>
                                                        </div>

                                                        <Switch
                                                            checked={tempLimitFlags.enableHeatMode}
                                                            switchChange={() =>
                                                                handleSetTempLimitRange("enableHeatMode")
                                                            }
                                                        />
                                                    </div>
                                                    <div className={classes.sliderHolder}>
                                                        {tempLimitFlags.enableHeatMode &&
                                                            <Slider
                                                                className={classes.heatSlider}
                                                                onChange={handleSetHeat}
                                                                // defaultValue={supportedValues.temperatureLimits[1][useTemperatureScale]}
                                                                aria-labelledby="discrete-slider-always"
                                                                // aria-labelledby="continuous-slider"
                                                                min={useTemperatureScale === 1 ? 0 : 32}
                                                                max={useTemperatureScale === 1 ? 50 : 122}
                                                                value={supportedValues.temperatureLimits[1]}
                                                                valueLabelDisplay="on"
                                                                valueLabelFormat={valueLabelFormat}
                                                                step={1}
                                                                // ValueLabelComponent={ValueLabelComponent}
                                                                disabled={!tempLimitFlags.enableHeatMode}

                                                            />
                                                        }
                                                    </div>

                                                </Card>
                                                <Card
                                                    className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}
                                                >
                                                    <div className={classes.modeHeader}>
                                                        <div className={classes.unitModeHeader}>
                                                            <img
                                                                src={assetsService.getImagesPath(`${modeIcons[0]}.svg`)}
                                                                className={classes.imgIconStyle}
                                                            ></img>
                                                            <Typography className={classes.unitTitle}>
                                                                {t`Limit cooling setpoint range`}</Typography>
                                                        </div>
                                                        <Switch
                                                            checked={tempLimitFlags.enableCoolMode}
                                                            switchChange={() =>
                                                                handleSetTempLimitRange("enableCoolMode")
                                                            }
                                                        />
                                                    </div>
                                                    <div className={classes.sliderHolder}>
                                                        {tempLimitFlags.enableCoolMode &&
                                                            <Slider
                                                                className={classes.coolSlider}
                                                                onChange={handleSetCooling}
                                                                // defaultValue={supportedValues.temperatureLimits[0][useTemperatureScale]}
                                                                aria-labelledby="discrete-slider-always"
                                                                // aria-labelledby="continuous-slider"
                                                                min={useTemperatureScale === 1 ? 0 : 32}
                                                                max={useTemperatureScale === 1 ? 50 : 122}
                                                                value={supportedValues.temperatureLimits[0]}
                                                                valueLabelDisplay="on"
                                                                valueLabelFormat={valueLabelFormat}
                                                                step={1}
                                                                // ValueLabelComponent={ValueLabelComponent}
                                                                disabled={!tempLimitFlags.enableCoolMode}
                                                            />
                                                        }
                                                    </div>
                                                </Card>
                                                <Card
                                                    className={clsx(classes.modeCardScheculeView, classes.removeRightMargin)}
                                                >
                                                    <div className={classes.modeHeader}>
                                                        <div className={classes.unitModeHeader}>
                                                            <img
                                                                src={assetsService.getImagesPath(`auto.svg`)}
                                                                className={classes.imgIconStyle}
                                                            ></img>
                                                            <Typography className={classes.unitTitle}>
                                                                {t`Limit auto setpoint range`}</Typography>
                                                        </div>
                                                        <Switch
                                                            checked={tempLimitFlags.enableAutoMode}
                                                            switchChange={() =>
                                                                handleSetTempLimitRange("enableAutoMode")
                                                            }
                                                        />
                                                    </div>
                                                    <div className={classes.sliderHolder}>
                                                        {tempLimitFlags.enableAutoMode &&
                                                            <Slider
                                                                className={classes.autoSlider}
                                                                onChange={handleSetAuto}
                                                                // defaultValue={supportedValues.temperatureLimits[2][temperatureScale]}
                                                                aria-labelledby="discrete-slider-always"
                                                                step={1}
                                                                // aria-labelledby="discrete-slider"
                                                                min={temperatureScale === 1 ? 0 : 32}
                                                                max={temperatureScale === 1 ? 50 : 122}
                                                                value={supportedValues.temperatureLimits[2]}
                                                                valueLabelDisplay="on"
                                                                valueLabelFormat={valueLabelFormat}
                                                                // ValueLabelComponent={ValueLabelComponent}
                                                                disabled={!tempLimitFlags.enableAutoMode}

                                                            />
                                                        }
                                                    </div>

                                                </Card>
                                            </div>
                                        </div>
                                    </>

                                    <div className={classes.unitActions}>
                                        <div className={clsx(classes.saveBtn)}>
                                            <Button
                                                // disableRipple
                                                disableElevation
                                                onClick={() => handleSave(selections.unitId)}
                                                variant="contained"
                                                className={clsx(classes.saveActionBtn)}
                                            >{t`SAVE`}
                                            </Button>
                                        </div>
                                        <div className={classes.cancelBtn}>
                                            <Button
                                                // disableRipple
                                                disableElevation
                                                onClick={() => handleCancel()}
                                                variant="contained"
                                                className={classes.cancelActionBtn}>{t`CANCEL`}
                                            </Button>
                                        </div>

                                    </div>

                                </Card>
                            }

                            {canSee && (supportedValues.temperatureLimits && selectedUnit) && schedulesMemo()}

                        </Grid>
                        {canSee &&
                            <Grid item xs={3}>
                                <Card
                                    className={classes.sideMenuContent}
                                >
                                    <div className={classes.settingsHeaderContainer}>
                                        <Typography variant="h6" className={classes.headerTitle}>
                                            {t`Copy Settings To`}
                                        </Typography>
                                    </div>
                                    <Grid className={classes.unitsContainer}>
                                        {memoSideMenu()}
                                    </Grid>
                                    <div className={classes.saveBtn}>
                                        <StyledButton
                                            width={140}
                                            onClick={() => setIsApply(true)}
                                            disabled={!unitsToSave.length}
                                            className={classes.applyBtn}

                                        >{t`Apply`}
                                        </StyledButton>
                                    </div>
                                </Card>
                            </Grid>
                        }
                    </Grid>
                }

            </div>
        </div>
    );
};

export default UnitSetting;
