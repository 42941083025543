import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  withStyles
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import {
  Close,
  CoolModeOff as SvgCoolModeOff,
  CoolModeOn as SvgCoolModeOn,
  Disconnect as SvgDisconnect,
  HeatModeOff as SvgHeatModeOff,
  HeatModeOn as SvgHeatModeOn,
  OnGrey as SvgPowerOff,
  OnWhite as SvgPowerOn
} from "../../icons/";
import { EditIcon } from "../../logos";
import { IDevice } from "../../models/Devices";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ISystem } from "../../models/Systems";
import assetsService from "../../services/assetsService";
import ManuallySetSystems from "./Device/ManuallySetSystems";
import ManuallySetOther from "./ManuallySetOther";
import ManuallySetSensors from "./ManuallySetSensors";
import { systemViewStyle } from "./SystemView.style";

interface IProps {
  deviceId: string;
  classes?: any;
  allPowerMeters: any;
}

export default withStyles(systemViewStyle)(function SystemsView(props: IProps) {
  const { classes } = props;
  const {
    overWriteDisplay,
    iconButton,
    powerButton,
    powerButtonDisabled,
    PowerButtonOperationStatusContainer,
    ppdLowerHalfOfView,
    systemContainer,
    systemName,
    logo,
    groupIcons,
    deleteIcon,
    nameModelContainer,
    editIcon,
    powerButtonSVG,
    modeButton,
    leftButton,
    powerOff,
    powerOn,
    modeButtons,
    modeButtonDisabled,
    indoorOutdoorContainer,
    outdoor,
    indoor,
    controlContainer,
    overWriteIcon,
    minLogoHeight
  } = classes;

  const [isEdit, setEdit] = React.useState("");
  const [editOther, setEditOther] = React.useState(false);
  const [editSensors, setEditSensors] = React.useState(false);
  const [deviceSensors, setDeviceSensors] = React.useState<any>([]);
  const allSites = useStoreState((state) => state.sites.allSites);
  const allDevices = useStoreState((state) => state.devices.allDevices);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const deleteSystem = useStoreActions((action) => action.deleteSystem);
  const systemTogglePower = useStoreActions((action) => action.systems.systemTogglePower);
  const getSensorsByDevice = useStoreActions((action) => action.sensors.getSensorsByDevice);
  const changeSystemOperationMode = useStoreActions(
    (action) => action.systems.changeSystemOperationMode
  );
  const hasUnitsOn = useStoreState((action) => action.systems.hasUnitsOn);
  const hasControlUnits = useStoreState((action) => action.systems.hasControlUnits);
  const getSystemMode = useStoreState((action) => action.systems.getSystemMode);
  const hasDisconnectedUnits = useStoreState((action) => action.systems.hasDisconnectedUnits);
  const types = useStoreState((state) => state.types.allTypes);
  const { hvacBrands } = types;
  const altermaBrandNum = hvacBrands?.filter((brand: any) => brand?.type?.toUpperCase() === "ALTHERMA")[0]?.value || 10;
  const device = allDevices[props.deviceId];

  const systems = Object.values(allSystems).filter(
    (system: ISystem) => system.device === device.id
  );

  useEffect(() => {
    getSensorsByDevice(device.id)
      .then((res: any) => {
        setDeviceSensors(res);
      });
  }, []);

  // In case the rendered device is deleted, this component is rendered anyway so we need to check if the device still exists.
  if (_.isUndefined(device)) {
    return null;
  }

  const ppd = Object.values(props.allPowerMeters).filter(
    (pm: any) => pm.device === device.id
  );

  const togglePower = async (system: ISystem) => {
    const isSystemOn = hasUnitsOn(system.id);
    if (isSystemOn) {
      await systemTogglePower({ id: system.id, data: 2 });
    } else {
      await systemTogglePower({ id: system.id, data: 1 });
    }
  };
  const setColdMode = async (system: ISystem) => {
    await changeSystemOperationMode({ id: system.id, data: 0 });
  };
  const setHotMode = async (system: ISystem) => {
    await changeSystemOperationMode({ id: system.id, data: 1 });
  };
  const editSystem = (system: ISystem) => {
    setEdit(system.id);
  };

  const onEditOther = () => {
    setEditOther(true);
  };

  const getEditModal: any = (device: IDevice, system: ISystem) => {
    return (
      <Dialog classes={{ paperWidthLg: classes.dialogStyle }} onClose={_.noop} aria-labelledby="simple-dialog-title" open={true} maxWidth="lg">
        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>{t`Edit System`}</Typography>
          <IconButton disableRipple onClick={() => setEdit("")} className={classes.iconBtnStyle}>
            <Clear style={{ color: "#7f7692" }} />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <ManuallySetSystems
            mode={2}
            system={system}
            closeModal={() => setEdit("")}
            site={allSites[device.site]}
            deviceId={device.id}
          />
        </div>
      </Dialog>
    );
  };

  const getEditModalOther: any = () => {
    return (
      <Dialog classes={{ paperWidthLg: classes.dialogStyle }} onClose={_.noop} aria-labelledby="simple-dialog-title" open={true} maxWidth="lg">
        <div className={classes.dialogHeader}>
          <Typography className={classes.headerTitle}>{t`Edit Power Meters`}</Typography>
          <IconButton disableRipple onClick={() => setEditOther(false)} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <ManuallySetOther
          mode={2}
          ppds={ppd}
          sensors={[]}
          closeModal={() => setEditOther(false)}
        />
      </Dialog>
    );
  };

  const generatePPDSystem = () => {
    const ppdsArray = Object.keys(ppd).length;

    return (
      <Grid key={`ppd-details`} className={systemContainer}>

        <Grid item xs={8} classes={{ root: overWriteDisplay }}>
          <Typography className={systemName || classes.auxDeviceTitle}>
            <span> {t`Power Meters`}</span>
          </Typography>

          <div className={groupIcons}>
            <LightTooltip title="Edit System">
              <IconButton disableRipple onClick={() => onEditOther()} className={classes.iconBtnStyle}>
                <EditIcon />
              </IconButton>
            </LightTooltip>
          </div>

        </Grid>

        <div className={indoorOutdoorContainer || classes.ppdCounter}>
          <Grid item xs={6}>
            <Typography className={clsx("", outdoor)} variant="body1">
              <span>
                {ppdsArray}
                {t` Power Meters`}
              </span>
            </Typography>
          </Grid>
        </div>

        <Grid item xs={12} classes={{ root: ppdLowerHalfOfView }}>
        </Grid>
        {editOther && getEditModalOther()}

      </Grid>
    );
  };

  const generateSensorsAux = () => {
    const sensors = Object.keys(deviceSensors).length;

    return (
      <Grid key={`ppd-details`} className={systemContainer}>
        <Grid item xs={8} classes={{ root: overWriteDisplay }}>
          <Typography className={systemName || ""}>
            {t`Aux Devices`}
          </Typography>

          <div className={groupIcons}>
            <LightTooltip title="Edit System">
              <IconButton disableRipple onClick={() => setEditSensors(true)} className={classes.iconBtnStyle}>
                <EditIcon />
              </IconButton>
            </LightTooltip>
          </div>
        </Grid>
        <div className={indoorOutdoorContainer}>
          <Grid item xs={6}>
            <Typography className={clsx("", outdoor)} variant="body1">
              <span>
                {sensors}
                {t` Devices`}
              </span>
            </Typography>
          </Grid>
        </div>
        {editSensors && (
          <Dialog classes={{ paperWidthLg: classes.smallDialogStyle }} aria-labelledby="simple-dialog-title" open={true} maxWidth="lg">
            <div className={classes.dialogHeader}>
              <Typography className={classes.headerTitle}>{t`Edit Aux Devices`}</Typography>
              <IconButton disableRipple onClick={() => setEditSensors(false)} className={classes.iconBtnStyle}>
                <Close color="#7f7692" />
              </IconButton>
            </div>
            <div className={classes.dialogContent}>
              <ManuallySetSensors
                title={t`Auxiliary devices`}
                mode={2}
                sensors={{ ...deviceSensors }}
                closeModal={() => setEditSensors(false)}
                setDeviceSensors={setDeviceSensors}
                deviceId={device.id}
              />
            </div>
          </Dialog>
        )}
      </Grid>
    );
  };

  const generateSystem = (system: any, i: number) => {
    const systemId = system.id;
    const isPowerOn = hasUnitsOn(systemId);
    const isSystemInHeatMode = getSystemMode(systemId) === 1;
    const isSystemInCoolMode = getSystemMode(systemId) === 0;
    const systemHasControlUnits = hasControlUnits(systemId);
    const isAlterma = +system.brandNum === +altermaBrandNum;

    return (
      <Grid key={`system-details-${i}`} className={systemContainer}>
        <Grid item xs={8} classes={{ root: overWriteDisplay }}>
          <Typography className={systemName}>
            {system.name}
          </Typography>
          <div className={groupIcons}>
            <LightTooltip title="Edit System">
              <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => editSystem(system)}>
                <EditIcon />
              </IconButton>
            </LightTooltip>
            <Delete
              classes={{ root: iconButton }}
              type={t`System`}
              object={system}
              detach={deleteSystem}
            ></Delete>
          </div>
        </Grid>

        <div className={nameModelContainer}>
          <Grid item xs={5} className={classes.minLogoHeight}>
            {system.brandNum ? (
              <img
                height={25}
                src={assetsService.getBrandLogo(`${system.brandNum}-small.svg`)}
                className={logo}
                onError={(event: any) =>
                  (event.target.src = assetsService.getBrandLogo("empty.png"))
                }
                alt={
                  _.find(hvacBrands, { value: system.brandNum })
                    ? _.find(hvacBrands, { value: system.brandNum }).name
                    : ""
                }
              />
            ) : (
                ""
              )}
          </Grid>
          <Grid item xs={7} style={{ textAlign: "right" }}>
            {hasDisconnectedUnits(system.id) && <SvgDisconnect />}
          </Grid>
        </div>

        <div className={indoorOutdoorContainer}>
          {!isAlterma ? (
            <Grid item xs={5}>
              <Typography className={clsx("", outdoor)} variant="body1">
                <span>
                  {
                    Object.values(allUnits).filter(
                      (unit) =>
                        unit.system === system.id && unit.type === 2
                    ).length
                  }
                  {t` Outdoor`}
                </span>
              </Typography>
            </Grid>) : <span />}
          <Grid item xs={7}>
            <Typography className={clsx("", indoor)} variant="body1">
              <span>
                {
                  Object.values(allUnits).filter(
                    (unit) => unit.system === system.id && unit.type === 1
                  ).length
                }
                {t` Indoor `}
              </span>
            </Typography>
          </Grid>
        </div>

        <Grid item xs={6} classes={{ root: PowerButtonOperationStatusContainer }}>
          <Grid item>
            {/* <ButtonGroup
                  className={modeButtons}
                  variant="contained"
                  size="small"
                  aria-label="small contained button group"
                > */}
            {isSystemInCoolMode ? (
              <SvgCoolModeOn onClick={() => setColdMode(system)} className={clsx(modeButton, systemHasControlUnits ? "" : modeButtonDisabled)} />
            ) : (
                <SvgCoolModeOff
                  onClick={() => setColdMode(system)}
                  className={clsx(modeButton, systemHasControlUnits ? "" : modeButtonDisabled)}
                />
              )}
            {isSystemInHeatMode ? (
              <SvgHeatModeOn onClick={() => setHotMode(system)} className={clsx(modeButton, systemHasControlUnits ? "" : modeButtonDisabled)} />
            ) : (
                <SvgHeatModeOff onClick={() => setHotMode(system)} className={clsx(modeButton, systemHasControlUnits ? "" : modeButtonDisabled)} />
              )}
            {/* </ButtonGroup> */}
          </Grid>
          <div className={controlContainer}>
            <IconButton onClick={() => togglePower(system)} className={clsx(powerButton, systemHasControlUnits ? "" : powerButtonDisabled)}>
              {isPowerOn ? (
                <div className={powerOn}>
                  <SvgPowerOn className={powerButtonSVG} />
                </div>
              ) : (
                  <div className={powerOff}>
                    <SvgPowerOff className={powerButtonSVG} />
                  </div>
                )}
            </IconButton>
          </div>
        </Grid>

        {isEdit === system.id && getEditModal(device, system)}
      </Grid>
    );
  };
  return (
    <>
      {systems.map((system, i) => generateSystem(system, i))}
      {!_.isEmpty(ppd) && generatePPDSystem()}
      {!_.isEmpty(deviceSensors) && generateSensorsAux()}

    </>
  );
});
