import React from "react";
import { createStore, StoreProvider } from "easy-peasy";
import Routes from "./screens/Routes/Routes";
import RootStore from "./models/RootStore";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core";
import theme from "./themes/defaultTheme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
// import MomentUtils from '@date-io/moment';
// import LuxonUtils from '@date-io/luxon';

const store = createStore(RootStore);

export default function App() {
  return (
    <StoreProvider store={store}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Routes />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </StoreProvider>
  );
}
