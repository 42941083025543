import coolremoteSDK, {
  AlertGroup as sdkAlertGroup,
  Compressor as sdkCompressor,
  Customer as sdkCustomer,
  Device as sdkDevice,
  PowerMeter as sdkPowerMeter,
  Sensor as sdkSensor,
  Services as sdkService,
  Site as sdkSite,
  System as sdkSystem,
  Unit as sdkUnit,
  User as sdkUser,
  xhr as SdkXhr
} from "coolremote-sdk";
import {
  Action,
  action,
  Computed,
  computed,
  createTypedHooks,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { stateList } from "../screens/SiteManagement/stateList";
import { alertActions, IAlertActions } from "./actions/AlertActions";
import {
  alertGroupActions,
  IAlertGroupActions,
} from "./actions/AlertGroupActions";
import { deviceActions, IDeviceActions } from "./actions/DeviceActions";
import { ISiteActions, siteActions } from "./actions/SiteActions";
import { ISystemActions, systemActions } from "./actions/SystemActions";
import { ITriggerActions, triggerActions } from "./actions/TriggerActions";
import { IUnitActions, unitActions } from "./actions/UnitActions";
import { IUserActions, userActions } from "./actions/UserActions";
import { alertGroupsModel, IAlertGroupsModel } from "./AlertGroups";
import { alertsModel, IAlertsModel } from "./Alerts";
import {
  anomalyTemplatesModel,
  IAnomalyTemplatesModel,
} from "./AnomalyTemplates";
import { auditsModel, IAuditsModel } from "./Audits";
import { compressorsModel, ICompressorsModel } from "./Compressors";
import { contactsModel, IContactsModel } from "./Contacts";
import { customersModel, ICustomersModel } from "./Customers";
import { devicesModel, IDevice, IDevicesModel } from "./Devices";
import { IMessagesModel, messageModel } from "./ErrorMessage";
import { featuresModel, IFeaturesModel } from "./Features";
import { groupsModel, IGroupsModel } from "./Groups";
import { IInvitesModel, invitesModel } from "./Invites";
import { ILoaderModel, loaderModel } from "./loader";
import { IMessageHandlers, messageHandlers } from "./MessageHandlers";
import { IPowerMetersModel, powerMetersModel } from "./powerMeters";
import {
  IScheduledReportsModel,
  scheduledReportsModel,
} from "./ScheduledReports";
import { IScheduleModel, scheduleModel } from "./Schedules";
import { IScriptModel, scriptModel } from "./Scripts";
import { ISelectionsModel, selectionsModel } from "./Selections";
import { ISensorsModel, sensorsModel } from "./Sensors";
import { ISite, ISitesModel, sitesModel } from "./Sites";
import { ISystemsModel, systemsModel } from "./Systems";
import { ITrapsModel, trapsModel } from "./traps";
import { ITriggersModel, triggersModel } from "./Triggers";
import {
  ITriggerTemplatesModel,
  triggerTemplatesModel,
} from "./TriggerTemplates";
import { ITypesModel, typesModel } from "./Types";
import { IUnitsModel, unitsModel } from "./Units";
import { IUser, IUsersModel, usersModel } from "./Users";
import { IZonesModel, zonesModel } from "./Zones";

// TODO: move IServiceParams and IServiceTypes into separate models?
// Or make one IServices model for all of them?
export interface IServiceParams {
  [key: string]: any;
}

// TODO: move IConfig to separate model?
// where do we load it from? How do we save it? Is it per user/customer, etc?
export interface IConfig {
  isSplashScreen: boolean;
  splashScreenTimeout: number;
}

export interface IRootStoreModel
  extends IUserActions,
  ISiteActions,
  IDeviceActions,
  IUnitActions,
  ISystemActions,
  IAlertGroupActions,
  ITriggerActions,
  IAlertActions,
  IMessageHandlers {
  invites: IInvitesModel;
  customers: ICustomersModel;
  loader: ILoaderModel;
  sites: ISitesModel;
  devices: IDevicesModel;
  alerts: IAlertsModel;
  groups: IGroupsModel;
  powerMeters: IPowerMetersModel;
  scheduledReports: IScheduledReportsModel;
  triggers: ITriggersModel;
  triggerTemplates: ITriggerTemplatesModel;
  zones: IZonesModel;
  units: IUnitsModel;
  sensors: ISensorsModel;
  contacts: IContactsModel;
  compressors: ICompressorsModel;
  systems: ISystemsModel;
  users: IUsersModel;
  features: IFeaturesModel;
  alertGroups: IAlertGroupsModel;
  audits: IAuditsModel;
  scripts: IScriptModel;
  selections: ISelectionsModel;
  serviceParams: IServiceParams;
  types: ITypesModel;
  config: IConfig;
  errorMessage: IMessagesModel;
  anomalyTemplates: IAnomalyTemplatesModel;
  isLoggedIn: boolean;
  isDropDownOpen: any;
  isInitialized: boolean;
  unitUpdateStatus: string;
  doStatsUpdate: boolean;
  schedules: IScheduleModel;
  doLogin: Thunk<IRootStoreModel, { username: string; password: string }>;
  doLogout: Thunk<IRootStoreModel>;
  getUserTree: Thunk<IRootStoreModel>;
  setLoggedIn: Action<IRootStoreModel, boolean>;
  setLogOut: Action<IRootStoreModel>;
  setDropDownNavigation: Action<IRootStoreModel, string>;
  setUnitUpdateStatus: Action<IRootStoreModel, { status: string }>;
  fetchedUserTree: Action<IRootStoreModel>;
  setServiceParams: Action<IRootStoreModel, IServiceParams>;
  setStatsUpdate: Action<IRootStoreModel, boolean>;
  traps: ITrapsModel;
  parentsMap: {
    sites: any;
    systems: any;
    devices: any;
    units: any;
  };
  setParentsMap: Action<IRootStoreModel, any>;
  appVersion: string;
  compareAppVersion: Action<IRootStoreModel, string>;
  serviceParamTypes: any;
  setServiceParamTypes: Action<IRootStoreModel, any>;
  trapOperatorsOptions: any;
  unitTypesOptions: Computed<IRootStoreModel>;
  ruleParameters: any;
  serviceErrorTypes: any;
  setServiceErrorTypes: Action<IRootStoreModel, any>;
  selectedAlert: any;
  setSelectedAlert: Action<IRootStoreModel, any>;
}

const RootStoreModel: IRootStoreModel = {
  invites: invitesModel,
  customers: customersModel,
  traps: trapsModel,
  sites: sitesModel,
  devices: devicesModel,
  alerts: alertsModel,
  groups: groupsModel,
  powerMeters: powerMetersModel,
  scheduledReports: scheduledReportsModel,
  triggers: triggersModel,
  triggerTemplates: triggerTemplatesModel,
  zones: zonesModel,
  units: unitsModel,
  sensors: sensorsModel,
  contacts: contactsModel,
  compressors: compressorsModel,
  loader: loaderModel,
  systems: systemsModel,
  anomalyTemplates: anomalyTemplatesModel,
  users: usersModel,
  scripts: scriptModel,
  features: featuresModel,
  alertGroups: alertGroupsModel,
  audits: auditsModel,
  selections: selectionsModel,
  serviceParams: [],
  types: typesModel,
  errorMessage: messageModel,
  schedules: scheduleModel,
  serviceErrorTypes: {},
  config: {
    // TODO: bring those defaults from somewhere?
    isSplashScreen: false,
    splashScreenTimeout: 3000
  },
  isLoggedIn: true, // Optimistic default
  isDropDownOpen: { settings: false, powerDistribution: false },
  isInitialized: false,
  unitUpdateStatus: "",
  doStatsUpdate: false,
  serviceParamTypes: {},
  setServiceErrorTypes: action((state, payload) => {
    state.serviceErrorTypes = payload;
  }),
  parentsMap: { sites: {}, devices: {}, systems: {}, units: {} },
  setParentsMap: action((state, payload) => {
    state.parentsMap = payload;
  }),
  appVersion: "",
  compareAppVersion: action((state, payload) => {
    if (!payload) {
      return;
    }
    if (!state.appVersion) {
      state.appVersion = payload;
      return;
    }
    if (state.appVersion && state.appVersion !== payload) {
      window.location.reload();
    }
  }),

  // Imported actions
  ...userActions,
  ...siteActions,
  ...deviceActions,
  ...unitActions,
  ...systemActions,
  ...alertGroupActions,
  ...triggerActions,
  ...alertActions,
  ...messageHandlers,

  setStatsUpdate: action((state, payload) => {
    state.doStatsUpdate = payload;
  }),

  // try to login the user
  doLogin: thunk(async (actions, payload) => {
    // localStorage.removeItem('token');
    coolremoteSDK.User.logout();

    const { data, message } = await coolremoteSDK.User.connect(
      payload.username,
      payload.password,
      "CoolManagement"
    );

    if (message) {
      return { message };
    }
    const token = data.token;

    if (!token) {
      return { message: "your session has been expired please login again" };
    }
    localStorage.setItem("token", token);
    SdkXhr.setToken(token);
    actions.setLoggedIn(true);
  }),

  // logout the user
  doLogout: thunk(async (actions, payload) => {
    actions.setLogOut();
    localStorage.removeItem("token");
    actions.setLoggedIn(false);
    await coolremoteSDK.User.closeWebSocket();
    return coolremoteSDK.User.logout();
  }),

  // get all initial data for the user
  getUserTree: thunk(async (actions) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }
    actions.users.initialize();

    const initAPIs = [
      sdkCustomer.getCustomers(),
      sdkSite.getSites(),
      sdkSensor.getMySensors(),
      sdkDevice.getDevices(),
      sdkSystem.getSystems(),
      sdkUnit.getUnits(),
      sdkService.getTypes(),
      sdkService.getServiceParamTypes(),
      sdkService.getServiceErrorTypes(),
      sdkService.getServiceParams(),
      sdkAlertGroup.getOperationalAlertGroups(),
      sdkCompressor.getCompressors(),
      sdkPowerMeter.find(),
      sdkUser.getUsers()
    ];

    Promise.all(initAPIs)
      .then((resp: any) => {
        const [
          customers,
          sites,
          sensors,
          devices,
          systems,
          units,
          types,
          paramsTypes,
          errorTypes,
          params,
          alertGroups,
          compressors,
          powerMeters,
          users
        ] = resp;

        actions.customers.initialize(customers);
        actions.sites.initialize(sites);
        actions.sensors.initialize(sensors);
        actions.devices.initialize(devices);
        actions.units.initialize(units);
        actions.compressors.initialize(compressors);
        actions.systems.initialize(systems);
        actions.types.setTypes(types);
        actions.setServiceParamTypes(paramsTypes);
        actions.setServiceErrorTypes(errorTypes);
        actions.setServiceParams(params);
        actions.alertGroups.initialize(alertGroups);
        actions.features.initialize();
        actions.users.setUsers(users);
        actions.powerMeters.initialize(powerMeters);

        // const openAlertVal = types.eventStatusTypes["open"];
        return types;
      })
      .then((types) => {
        return actions.alerts.getAlertsByFilters({ status: types.eventStatusTypes["open"], type: types.applications.service });
      })
      .then((events: any) => {
        actions.alerts.initialize({ events, openAlertsOnly: true });
      })
      .then(() => {
        actions.fetchedUserTree();
      })
      .catch(() => {
        actions.doLogout();
      });

    await coolremoteSDK.User.openWebSocket(actions._handleSocketMessage);
  }),
  setLoggedIn: action((state, payload) => {
    state.isLoggedIn = payload;
  }),
  setLogOut: action((state, payload) => {
    state.selections.selections.customerId = null;
    state.selections.selections.siteId = null;
    state.selections.selections.systemId = null;
    state.selections.selections.unitId = null;
    state.selections.selections.lastSelectedUnitId = null;
    state.isInitialized = false;
  }),
  setServiceParamTypes: action((state, payload) => {
    state.serviceParamTypes = payload;
  }),
  setDropDownNavigation: action((state, payload) => {
    state.isDropDownOpen[payload] = !state.isDropDownOpen[payload];
  }),

  setUnitUpdateStatus: action((state, payload) => {
    state.unitUpdateStatus = payload.status;
  }),

  fetchedUserTree: action((state) => {
    state.isInitialized = true;
  }),

  setServiceParams: action((state, payload) => {
    state.serviceParams = payload;
  }),
  trapOperatorsOptions: [
    {
      value: ">",
      label: ">"
    },
    {
      value: "<",
      label: "<"
    },
    { value: "=", label: "=" },
    {
      value: "!=",
      label: "≠"
    },
    {
      value: "threshold",
      label: "Difference"
    }
  ],
  unitTypesOptions: computed((state) => {
    const { types } = state;
    const { getUnitTypes } = types;
    if (!getUnitTypes) {
      return null;
    }
    return [{ value: getUnitTypes.indoor, name: "Indoor" }];
  }),
  ruleParameters: [
    {
      allBrands: true,
      code: 48,
      data_unit_of_measurement: "",
      enabledInTriggers: true,
      enum: "unitOperationStatuses",
      hvac_param_name: "IndoorOnOffStatus",
      max: 1,
      min: 0,
      plotable: true,
      showInGraph: false,
      title: "ON/OFF"
    },
    {
      allBrands: true,
      code: 49,
      data_unit_of_measurement: "°C",
      enabledInTriggers: true,
      hvac_param_name: "RoomTemp",
      max: 0,
      min: 100,
      plotable: true,
      showInGraph: false,
      title: "Room Temp"
    },
    {
      allBrands: true,
      code: 50,
      data_unit_of_measurement: "°C",
      enabledInTriggers: true,
      hvac_param_name: "SetTemp",
      max: 0,
      min: 100,
      plotable: true,
      showInGraph: false,
      title: "Set Temp"
    },
    {
      allBrands: true,
      code: 51,
      data_unit_of_measurement: "",
      enabledInTriggers: true,
      enum: "unitOperationModes",
      hvac_param_name: "Mode",
      max: 10,
      min: 0,
      plotable: true,
      showInGraph: true,
      title: "Mode"
    },
    {
      allBrands: true,
      code: 52,
      data_unit_of_measurement: "",
      enabledInTriggers: false,
      enum: "unitFanModes",
      hvac_param_name: "FanSpeed",
      max: 10,
      min: 0,
      plotable: true,
      showInGraph: true,
      title: "Fan Speed"
    },
    {
      allBrands: true,
      code: 57,
      data_unit_of_measurement: "",
      enabledInTriggers: false,
      enum: "",
      hvac_param_name: "SiteTemp",
      max: 150,
      min: -50,
      plotable: true,
      showInGraph: true,
      title: "Site Temperature"
    }
  ],
  selectedAlert: null,
  setSelectedAlert: action((state, payload) => {
    state.selectedAlert = payload;
  })
};

const typedHooks = createTypedHooks<IRootStoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export default RootStoreModel;
