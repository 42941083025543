import * as React from "react";

function SvgNotificationMobile(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <defs>
        <path id="NotificationMobile_svg__a" d="M0 0h22.647v26H0z" />
      </defs>
      <g transform="translate(1)" fill="none" fillRule="evenodd">
        <mask id="NotificationMobile_svg__b" fill="#fff">
          <use xlinkHref="#NotificationMobile_svg__a" />
        </mask>
        <path
          fill={props.color || "#AAA2AA"}
          d="M20.656 20.634H1.991v-1.202a3.086 3.086 0 011.169-2.42l.49-.397a3.114 3.114 0 001.172-2.438V9.604c0-3.584 2.917-6.502 6.502-6.502s6.502 2.918 6.502 6.502v4.573a3.11 3.11 0 001.17 2.443l.494.398a3.083 3.083 0 011.166 2.412v1.204zm-7.142 2.403a2.548 2.548 0 01-2.19 1.268h-.002a2.55 2.55 0 01-2.182-1.268l-.238-.412h4.849l-.237.412zm7.22-7.58l-.492-.396a1.127 1.127 0 01-.426-.884V9.604a8.492 8.492 0 00-6.845-8.33 1.698 1.698 0 00-3.289 0 8.495 8.495 0 00-6.851 8.33v4.573c0 .345-.156.67-.425.884l-.492.397A5.098 5.098 0 000 19.42v.94a2.266 2.266 0 002.266 2.265h4.676a4.527 4.527 0 008.758 0h4.684a2.265 2.265 0 002.265-2.265v-.928a5.1 5.1 0 00-1.914-3.974z"
          mask="url(#NotificationMobile_svg__b)"
        />
      </g>
    </svg>
  );
}

export default SvgNotificationMobile;
