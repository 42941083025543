import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  thunk,
  Thunk
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";
import { ISite } from "./Sites";
import { ITrigger } from "./Triggers";

export interface ICustomer {
  id: string;
  name?: string;
  description?: string;
  enableTriggers?: boolean;
  sites: string[];
  triggers: string[];
  role: any;
}

export interface ICustomerMap {
  [key: string]: ICustomer;
}

export interface ICustomersModel {
  allCustomers: ICustomerMap;
  initialize: Action<ICustomersModel, any>;
  onInitialized: ActionOn<ICustomersModel, IRootStoreModel>;
  getCustomerName: Computed<ICustomersModel, (id: string | undefined) => string | undefined>;
  getCustomerSites: Computed<ICustomersModel, (id: string) => ISite[], IRootStoreModel>;
  getCustomerTraps: Thunk<IRootStoreModel, { customerId: string }>;
  getCustomerTriggers: Thunk<IRootStoreModel, { customerId: string }>;
}

export const customersModel: ICustomersModel = {
  allCustomers: {},
  initialize: action((state, payload) => {
    state.allCustomers = payload;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      // console.log(target.resolvedTargets);
      // console.log('Got customers: ', debug(state.allCustomers));
    }
  ),

  getCustomerName: computed((state) => (id) => {
    const noName = "-";

    if (!id || !state.allCustomers[id] || !state.allCustomers[id].name) {
      return noName;
    }

    return state.allCustomers[id].name;
  }),

  getCustomerSites: computed(
    [(state) => state.allCustomers, (state, storeState) => storeState.sites.allSites],
    (allCustomers, allSites) => (id) => {
      if (!allCustomers[id]) { return []; }
      return _.map(allCustomers[id].sites, (siteId) => allSites[siteId]);
    }
  ),
  getCustomerTraps: thunk(async (actions, payload) => {
    const data: any = await coolremoteSDK.Customer.getTraps(payload.customerId);
    return data;
  }),
  getCustomerTriggers: thunk(async (actions, payload) => {
    const data: any = await coolremoteSDK.Customer.getTriggers(payload.customerId);
    return data;
  })
};
