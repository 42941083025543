import { createStyles, fade } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    root: {
      width: 60,
      height: 30,
      padding: "6px 7px"
    },
    switchBase: {
      left: 6,
      padding: 1,
      color: "#D8D8D8",
      "&$checked": {
        left: 0,
        transform: "translateX(26px)",
        color: "#4b1c46",
        "& + $track": {
          backgroundColor: "rgba(75, 28, 70, 0.5)",
          border: "none",
          opacity: 1
        }
      },
      "&$focusVisible $thumb": {
        color: "#4b1c46"
      }
    },
    thumb: {
      width: 28,
      height: 28
    },
    track: {
      borderRadius: 50 / 2,
      backgroundColor: fade("#4b1c46", 0.25),
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
  });
