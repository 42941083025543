import { Button, Card, CircularProgress, Grid, Icon , IconButton, Typography} from "@material-ui/core/";
import { ArrowForwardIos } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import {BottomNav, TopBar} from "../../components";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import {Arrow, MobileLogo, MobileMenu} from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./SettingsMenuScreen.style";

const MenuItem = ({onClick, title, disabled = false}: any) => {
return <div onClick={disabled ? () => {} : onClick} style={{opacity: disabled ? 0.6 : 1 , cursor: "pointer", marginBottom: 3, boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fff", width: "100%", height: 52, paddingLeft: 20, display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 12}}>
         <Typography style={{fontFamily: "Roboto",
                             fontSize: 16,
                             fontWeight: "normal",
                             lineHeight: "normal",
                             letterSpacing: "normal",
                             color: "#15111f"}}>{title}</Typography>
         <Arrow style={{transform: "rotate(-90deg) scale(2.189)"}}/>
       </div>;
};

const MenuScreen: React.FC<any> = (props: any) => {
  const history = useHistory();
  const classes = useStyles();
  const [logoutPopupOpen, handleLogoutPopup] = useState<boolean>(false);
  const doLogout = useStoreActions((a) => a.doLogout);

  const logout = () => {
    handleLogoutPopup(false);
    doLogout();
  };

  return (
    <>
    <TopBar
      leftIconComponent={<MobileLogo />}
      leftAction={() => history.push("/dashboard")}
      rightIconComponent={<MobileMenu />}
      title={t`Settings`}
      />
    <div className={classes.screenContainer}>
      <MenuItem  onClick={() => history.push("/settings/unit")} title={t`Unit Control Restrictions`}/>
      <MenuItem  onClick={() => history.push("/settings/restricts")} title={t`Restrict Local Controller `}/>
      <MenuItem onClick={() => history.push("/management/unit")} title={t`Unit Management`}/>
      <MenuItem onClick={() => handleLogoutPopup(true)} title={t`Logout`}/>

    </div>
    < BottomNav  / >
    {logoutPopupOpen &&
       <ConfirmationDialog
        onCancel={logout}
        onConfrim={() => handleLogoutPopup(false)}
        onClose={() => handleLogoutPopup(false)}
        title="Log out"
        text="Are you sure?"
        confrimLabel="No"
        cancelLabel="logout"
        dialogType="normal"
        openDialog={logoutPopupOpen}
      />}
    </>
  );
};
export default MenuScreen;
