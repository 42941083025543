import { fade, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  unitStyle: {
    width: "100%",
    height: "50px",
    marginBottom: "6px",
    borderRadius: "6px",
    padding: "3px 9px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: fade("#fff", 0.1),
    color: "#fff",
    paddingLeft: 0
    // cursor: "move !important"
  },
  selected: { border: "solid 2px rgba(255, 255, 255, 0.6)" },
  tempSec: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    fontSize: "26px",
    height: "100%",
    width: "25%"
    // maxWidth: "92px"
  },
  tempInfo: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    justifyContent: "flex-start",
    marginLeft: "5px",
    minWidth: 70
  },
  iconDiv: {
    display: "flex",
    justifyContent: "center",
    width: 28,
    maxWidth: 28,
    marginRight: 5,
    minWidth: 28,
    alignItems: "center"
  },
  roomTemp: {
    fontSize: "13px",
    fontWeight: 300,
    color: "#fff"

  },
  tempText: {
    fontSize: "26px",
    fontWeight: 300,
    fontFamily: "RobotoLight",
    width: "60px",
    lineHeight: "unset"
  },
  locSec: {
    // minWidth: "40ch",
    display: "flex",
    paddingLeft: "32px",
    justifyContent: "space-between",
    alignItems: "center",
    width: "calc(25% + 24px)",
    overflow: "hidden"
  },
  notesSec: {
    display: "flex",
    height: "100%",
    width: "42%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  iconStyle: {
    fontSize: "17.5px",
    transform: "scale(1.389)"
  },
  modeIcon: {
    margin: "0 5px"
  },
  noteIcon: {
    fontSize: "20px"
  },
  powerAlertsSchedulesContainer: {
    alignItems: "center",
    width: "34%",
    justifyContent: "space-between",
    height: "100%"
    // maxWidth: "204px"
  },
  smallIcons: {
    transform: "scale(0.5)",
    margin: 0
  },
  notVisibile: {
    visibility: "hidden"
  },
  removeElements: {
    display: "none"
  },
  removedStyle: {
    opacity: 0.5
  },
  errorColor: {
    color: "#f05146"
  },
  nameStyle: {
    fontSize: "20px",
    fontWeight: 300,
    fontFamily: "RobotoLight"
  },
  alertIcon: { width: "20px", height: "20px" },
  centerWithMargin: {
    marginLeft: "5px"
  },
  tempTextSensor: {
    fontSize: "26px",
    fontWeight: 300,
    fontFamily: "RobotoLight",
    width: "60px",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginLeft: 5
  },
  offText: {
  color: fade("#fff", 0.4)
},
  boldText: {
    fontFamily: "RobotoMedium",
    fontWeight: 500
  },
  onOffSensor: {
    display: "flex",
    alignItems: "center",
    width: "34%",
    justifyContent: "space-between",
    height: "100%"
  }
}));

export default useStyles;
