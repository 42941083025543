import { IconButton, Input, InputAdornment, Typography } from "@material-ui/core";
import { ChevronRightOutlined, Close } from "@material-ui/icons";
import _ from "lodash";
import React from "react";
import Button from "../../cool_widgets/Button";
import { useStoreState } from "../../models/RootStore";
import { SearchIcon } from "../../svgComponents";
import {TopBar} from "../TopBar";
import UserMenu from "../UserMenu/UserMenu";
import useStyles from "./Header.style";
import SelectionsMenu, { ISelectionsMenuProps } from "./SelectionsMenu";

interface IHeaderProps extends ISelectionsMenuProps {
  path?: [string];
  hideFilterArea?: boolean;
  btn?: string;
  canClickBtn?: boolean;
  btnFc?: any;
  btnElement?: any;
  // Accomodation for restProps
  [key: string]: any;
}

const Header = ({
  path = [""],
  btn = "",
  canClickBtn = true,
  btnFc = _.noop,
  hideFilterArea = false,
  btnElement,
  onSearch,
  onClear = {},
  searchValue = "",
  ...restProps
}: IHeaderProps) => {
  const c = useStyles();
  const userFullName = useStoreState((state) => state.users.myFullName);
  const showSearchBar = !!onSearch;

  const getPath = () => {
    const length = path.length;
    const items = [];

    for (let x = 0; x < length; x++) {
      items.push(
        <span key={`title-item-${x}`}>
          {path[x]}
        </span>
      );

      if (x < length - 1) {
        items.push(<ChevronRightOutlined key={`arrow-item-${x}`} className={c.pathSeparator} />);
      }
    }

    return items;
  };
  return (
    <div className={c.headerContainer}>
      <div className={c.userMenuContainer}>
        <Typography className={c.pathStyle}>{getPath()}</Typography>
        <div className={c.rightSideHolder}>
          {!!showSearchBar &&
            <Input
              placeholder="Search..."
              value={searchValue}
              onChange={(event: any) => onSearch(event.target.value)}
              disableUnderline={true}
              classes={{ root: c.inputRoot }}
              startAdornment={
                <InputAdornment position="start" className={c.searchAdorment}>
                  <SearchIcon className={c.searchIcon} />
                </InputAdornment>
              }
              endAdornment={
                searchValue && (
                  <IconButton
                    disableRipple
                    onClick={onClear}
                    className={c.closeIconStyle}
                  >
                    <Close />
                  </IconButton>
                )
              }
            />
          }
          <UserMenu userName={userFullName} />
        </div>
      </div>
      {!hideFilterArea && <div className={c.selectionMenuContainer}>
        <SelectionsMenu {...restProps} />
        {btnElement ? btnElement :
          btn ? <Button onClick={btnFc} disabled={!canClickBtn}>{btn}</Button> : null
        }
      </div>}
    </div>
  );
};

export default Header;
