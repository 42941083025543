import { createStyles, Theme } from "@material-ui/core";

export const manuallySetSystemViewStyle = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    inputName: {
      width: "25%",
      marginRight: "1rem"
    },
    inputBrand: {
      width: "20%",
      marginRight: "1rem"
    },
    inputModel: {
      width: "10%",
      marginRight: "1rem"
    },
    inputLine: { width: "9%" },
    inputCapacity: {
      width: "10%",
      marginRight: "1rem"
    },
    inputCapacityMeasurement: {
      width: "8%",
      marginTop: "1.4rem"
    },
    autocomplete: {
      width: "10rem",
      height: "3rem"
    },
    systemContainer: {
      borderRadius: "4px",
      border: "solid 1px #d5d2d5"
      // padding: '1rem'
    },
    unitsContainer: {
      borderRadius: "4px",
      border: "solid 1px #d5d2d5",
      marginTop: "1rem",
      display: "flex",
      flexFlow: "column",
      overflow: "hidden",
      maxHeight: "calc(100vh - 478px)",
      paddingBottom: "14px"
    },
    unitsInfoTitle: {
      backgroundColor: "#f6f5f6",
      borderBottom: "1px solid #d5d2d5",
      padding: 0,
      paddingLeft: "14px",
      marginBottom: "14px",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      color: "#4b1c46",
      height: "50px",
      lineHeight: "50px"
    },
    modalActions: {
      marginTop: "1rem",
      display: "flex",
      justifyContent: "flex-end",
      position: "sticky",
      bottom: 0,
      right: 0,
      height: "80px",
      background: "white",
      width: "100%",
      padding: "20px 0",
      zIndex: 7
    },
    container: {
      paddingRight: "2rem"
    },

    headCell: {
      backgroundColor: "whitesmoke"
    },
    scrollableTable: {
      overflow: "auto",
      height: "500px",
      maxHeight: "500px",
      padding: "0 14px", display: "flex",
      width: "1235px"
    },
    systemAttributes: {
      width: "100%",
      margin: 0,
      padding: "20px",
      // display: "flex",
      flex: 1,
      height: "100%",
      paddingBottom: 0
    },

    deviceSystemsContainer: {
      width: "50rem",
      marginBottom: "2rem"
    },

      //********************************************* other style ************************
    inputFieldStyle: {
          minWidth: "10ch",
          height: "44px",
          width: "100%",
          "& label": {
              color: "rgba(255, 255, 255, 0.7)",
              "&focus": {
                  color: "rgba(255, 255, 255, 0.7)"
              }
          },
          "& div": {
              backgroundColor: "rgba(255, 255, 255, 0.1)"
          },
          "& MuiInputBase-root": {
              color: "white"
          }
      },
    inputFormWrapper: {
          margin: "1rem 0 1rem 0"
      },

    textField: {
          width: "100%"
      },

    textFieldCa: {
          width: "22%"
      },

    caRow : {
        paddingRight: "10%"
        // width: '20px',
        //   border: '1px solid gray'
      },

    ppdUnitsContainer: {
        height: "100%",
        width: "100%"
      },
    otherFormWarpper: {
        border: "1px solid #D5D2D5"
      },

    otherFormWarpperTitle: {
           color: "#4b1c46",
           backgroundColor: "#f6f5f6",
           height: "50px",
           padding: "0",
           fontSize: "16px",
           fontFamily: "Roboto",
           fontWeight: 500,
           lineHeight: "50px",
           paddingLeft: "14px",
           borderBottom: "1px solid #d5d2d5",
           marginBottom: "14px"

       },
    wideCell: {
          // width: "15%",
          boxSizing: "border-box"
      },
    mediumCell: {
      width: "20ch",
      minWidth: "20px"
    },
    rangeError: {
      color: "red"
    },
    unFocusedSelect: {
      "& .MuiSelect-select:focus": {
        backgroundColor: "white"
      }
    },
    loader: {
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%"
     },
    message: {
      fontSize: "26px",
      color: "#545964"
    }
  });
