import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  actionIcon: {
    "&hover": {
      backgroundColor: "white"
    }
  },
  // title: {
  //   backgroundColor: "#f6f6f7",
  //   color: "#29132e",
  //   borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
  //   padding: "4px 24px"
  // },
  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  dialog: {
    // border: '1px solid  rgba(0, 0, 0, 0.54)',
    borderRadius: "0.5rem",

    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: " 1px solid #d5d2d5"
  },
  closeIcon: {
    width: "24px",
    height: "24px"
  },
  dialogContent: {
    flex: "1 1 auto",
    padding: "20px",
    paddingTop: "30px"
  },
  DialogContentText: {
    fontFamily: "Roboto",
    color: "#545964"
  },
  noBtn: {
    width: "9rem",
    height: "2.625rem",
    border: "none"
    // marginRight: '1rem'
  },
  yesBtn: {
    color: "#4b1c46",
    width: "9rem",
    height: "2.625rem",
    border: "1px solid #421a40"
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
    padding: 20
  },
  // btn: {
  //   padding: '1rem'
  // },
  hover: {},
  "MuiIconButton-root": {
    "&:hover": {
      backgroundColor: "inherit"
    }
  },
  "MuiDialogTitle-root": {
    padding: "4px 24px"
  },
  overWriteIcon: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  headerTitle: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal"

    },
  iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    }
});

export default useStyles;
