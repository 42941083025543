import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  memo,
  Thunk,
  thunk
} from "easy-peasy";
import _ from "lodash";
import moment from "moment-timezone";
import { t } from "ttag";
import alertSeverities from "../constants/alertSeverities";
import { IRootStoreModel } from "./RootStore";
import { unitsModel } from "./Units";
const severties: any = alertSeverities;
export interface IAlert {
  id: string;
  type: string;
  eventTime: number;
  site: string;
  trigger: string | null;
  isRead: boolean;
  data: any;
  initiator: string | null;
  clearTime?: number;
  clearReason?: number;
  shortId: string;
}

export interface IAlertMap {
  [key: string]: IAlert;
}

export interface IAlertsModel {
  allAlerts: IAlertMap;
  initialize: Action<IAlertsModel, any>;
  onInitialized: ActionOn<IAlertsModel, IRootStoreModel>;
  fetchUpdatedAlert: Thunk<IAlertsModel, { id: string }>;
  sendAlertEmail: Thunk<IAlertsModel, { id: string; emails: string[] }>;
  deleteAlert: Thunk<IAlertsModel, string>;
  _storeDeleteAlert: Action<IAlertsModel, string>;
  getAlertsPaginated: Thunk<
    IAlertsModel,
    {
      filter: string;
      id: string;
      page: number;
      rowsPerPage: number;
      startDate: any;
      endDate: any;
      types: any;
      codes: any;
      statuses: any;
    }
  >;
  _storeFetchUpdatedAlert: Action<IAlertsModel, { alertId: string; alert: IAlert }>;
  isResolved: Computed<IAlertsModel, (id: string) => boolean>;
  timeResolved: Computed<IAlertsModel, (id: string) => number | undefined>;
  getAlertsByFilter: Computed<
    IAlertsModel,
    (
      filter: {
        customerId?: string | null | undefined;
        siteId?: string | null | undefined;
        systemId?: string | null | undefined;
        unitId?: string | null | undefined;
      },
      showOpenAlertsOnly?: boolean,
      hideUnitDisconnected?: boolean
    ) => any,
    IRootStoreModel
  >;
  getAlertFeildsNamesAndIds: Computed<IAlertsModel, any>;
  openParsedAlerts: Computed<IAlertsModel>;
  parsedAlerts: Computed<IAlertsModel>;

  updateAlert: Thunk<IAlertsModel, { id: string; data: any }>;
  getAlertsByTime: Thunk<IAlertsModel, { startTime?: any; endTime?: any, type?: any, status?: any }>;
  setAcknowledgedEvent: Thunk<IAlertsModel, { id: string; data: any }>;
  allOpenAlerts: Computed<IAlertsModel>;
  getAlertsByFilters: Thunk<IAlertsModel, { startTime?: any; endTime?: any, status?: any, type?: any }>;
}

const eventTypesToSeverityMapping: any = {
  cleanFilter: "MAINTENANCE",
  customTelemetry: "ANOMALIES",
  deviceDisconnected: "NOT_CONNECTED",
  indoorUnitError: "INDOOR_ERROR" ,
  outdoorUnitError: "SYSTEM_ERROR",
  unitDisconnected: "NOT_CONNECTED",
  unitsDisconnected: "NOT_CONNECTED",
  ALL_IS_WELL: "ALL_IS_WELL",
  operationalTelemetry: "ANOMALIES"
};

export const alertsModel: IAlertsModel = {
  allAlerts: {},
  allOpenAlerts: computed([(state) => state.allAlerts, (state, storeState: any) => storeState.types.allTypes], (allAlerts, types) => {
    const openAlerts: any = {};
    const { eventStatusTypes } = types;
    Object.values(allAlerts).forEach((alert: any) => {
      if (alert.status === eventStatusTypes?.open) {
        openAlerts[alert.id] = alert;
      }
    });
    return openAlerts;
  }),
  parsedAlerts: computed([
    (state, storeState: any) => state.allAlerts,
    (state, storeState: any) => storeState.serviceErrorTypes,
    (state, storeState: any) => storeState.customers.allCustomers,
    (state, storeState: any) => storeState.sites.allSites,
    (state, storeState: any) => storeState.units.allUnits,
    (state, storeState: any) => storeState.devices.allDevices,
    (state, storeState: any) => storeState.systems.allSystems,
    (state, storeState: any) => storeState.types.allTypes.eventTypes,
    (state, storeState: any) => storeState.types.allTypes.eventStatusTypes,
    (state, storeState: any) => storeState.types.eventTypesMirror,
    (state, storeState: any) => storeState.types.eventStatusTypesMirror,
    (state, storeState: any) => storeState.types.eventClearTypesMirror,
    (state, storeState: any) => storeState.alerts.getAlertFeildsNamesAndIds,
    (state, storeState: any) => storeState.users.timeFormat,
    (state, storeState: any) => storeState.users.dateFormat
  ],
    (allAlerts, errorTypes, allCustomers, allSites, allUnits, allDevices, allSystems,
     eventTypes, eventStatusTypes, eventTypesMirror, eventStatusTypesMirror, eventClearTypesMirror,
     getAlertFeildsNamesAndIds, timeFormat, dateFormat) => {
      const parsedAlerts: any = Object.create(allAlerts);
      Object.values(allAlerts).forEach((alert: any) => {

        const { eventNames, eventIds } = getAlertFeildsNamesAndIds(alert);
        const severity = eventTypesToSeverityMapping[eventTypesMirror[alert.type]] || "ALL_IS_WELL";

        if (!eventIds.siteId) {
          return;
        }
        const timezone = allSites[eventIds.siteId]?.timezone || "";
        const { data, type } = alert;
        const errorCode = eventTypes.indoorUnitError === type ||
          eventTypes.outdoorUnitError === type ? data : "";
        let errorDescription = "";

        if ((errorCode || errorCode === 0) && eventIds.systemId){
          const { brandNum } =  allSystems[eventIds.systemId] || {};
          errorDescription = getErrorDescription(errorTypes.errorCodeTypes, brandNum, errorCode);
          errorDescription = errorDescription;
        }

        parsedAlerts[alert.id] = {
          ...alert,
          time: moment(alert.eventTime).tz(timezone).format(`${dateFormat}   ${timeFormat}`),
          clearTime: moment(alert.clearTime).tz(timezone).format(`${dateFormat}   ${timeFormat}`),
          status: eventStatusTypesMirror[alert.status] || "",
          alertType: (eventTypesMirror[alert.type].split(/(?=[A-Z])/)).map((word: string) => {
            word = word[0].toUpperCase() + word.substr(1);
            return word;
          }).join(" "),
          siteName: eventNames.siteName,
          unitName: eventNames.unitName || "-",
          deviceName: eventNames.deviceName,
          systemName: eventNames.systemName || "-",
          customerName: allCustomers[alert.customer]?.name || "",
          clearReason: eventClearTypesMirror[alert.clearReason],
          alertItemContainerIds: { ...eventIds, customerId: alert.customer },
          description: alert.trapDescription,
          errorCode: eventTypes.unitsDisconnected === alert.type ? "" : alert.data,
          timestamp: alert.eventTime,
          severity: severties[eventTypesToSeverityMapping[eventTypesMirror[alert.type]]],
          timezone,
          errorDescription,
          ...eventIds
        };

      });
      return parsedAlerts;

    }),

  initialize: action((state, payload) => {
    const newAlerts: IAlertMap = _(Object.values(payload.events))
      .map((alert: any) => {
        const newAlert: IAlert = { ...alert };

        return newAlert;
      })
      .keyBy("id")
      .value();
    state.allAlerts = newAlerts;
  }),
  getAlertsByFilters: thunk(async (actions, payload: any = "") => {
    const alerts = coolremoteSDK.Event.getEvents(payload);
    return alerts;
  }),
  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => { }
  ),
  sendAlertEmail: thunk((actions, payload) => {
    return coolremoteSDK.Services.sendEventEmail({ mailList: payload.emails }, payload.id);
  }),
  _storeDeleteAlert: action((state, payload) => {
    delete state.allAlerts[payload];

    state.allAlerts = { ...state.allAlerts };
  }),
  deleteAlert: thunk(async (actions, payload) => {
    await coolremoteSDK.Event.delete(payload);

    actions._storeDeleteAlert(payload);
  }),
  updateAlert: thunk(async (actions, payload) => {
    const { id, data } = payload;
    return coolremoteSDK.Event.update(id, data);
  }),
  getAlertsPaginated: thunk(async (actions, payload) => {
    const res = await coolremoteSDK.Event.getEventsPaginated(payload),
      alerts = res.retAlertObj,
      { length } = res,
      data = { alerts, length };
    return data;
  }),

  fetchUpdatedAlert: thunk(async (actions, payload) => {
    const alert = await coolremoteSDK.Event.fetch(payload.id);

    actions._storeFetchUpdatedAlert({ alertId: payload.id, alert });
  }),

  _storeFetchUpdatedAlert: action((state, payload) => {
    state.allAlerts[payload.alertId] = payload.alert;
  }),
  isResolved: computed([(state) => state.allAlerts], (allAlerts) => (id) => {
    if (_.isUndefined(allAlerts[id]) || !_.isUndefined(allAlerts[id].clearTime)) {
      return true;
    }
    else {
      return false;
    }
  }),

  timeResolved: computed([(state) => state.allAlerts], (allAlerts) => (id) => {
    if (_.isUndefined(allAlerts[id])) {
      return undefined;
    }
    else {
      return allAlerts[id].clearTime;
    }
  }),
  openParsedAlerts: computed([
    (state, storeState: any) => state.parsedAlerts],
    (parsedAlerts) => Object.values(parsedAlerts).filter((alert: any) => alert.status === "open")),
  getAlertsByFilter: computed([
    (state) => state.parsedAlerts, (state) => state.openParsedAlerts, (state, storeState: any) => storeState.types.allTypes.eventTypes], (parsedAlerts, openParsedAlerts, eventTypes) => (filter, showOpenAlertsOnly = true, hideUnitDisconnected = true) => {

      const alertsToFilter = showOpenAlertsOnly ? openParsedAlerts : Object.values(parsedAlerts);
      const { customerId, siteId, systemId, unitId } = filter;

      return _.filter(_.values(alertsToFilter), (alert: any) => {
        if (hideUnitDisconnected && alert.type === eventTypes.unitDisconnected) {
          return false;
        }

        if (!hideUnitDisconnected && alert.type === eventTypes.unitsDisconnected) {
          return false;
        }

        if (!!systemId && !alert.systemId) {
          return false;
        }

        if (!!unitId && alert.unitId !== unitId) {
          return false;
        }

        if (!!systemId && (alert.systemId ? alert.systemId !== systemId : (alert.systemIds.length > 1 && !alert.systemIds.includes(systemId)))) {
          return false;
        }

        if (!!siteId && alert.siteId !== siteId) {
          return false;
        }

        if (!!customerId && alert.customer !== customerId) {
          return false;
        }

        return true;
      });

    }),
  getAlertFeildsNamesAndIds: computed(
    [(state, storeState: any) => storeState.sites.allSites,
    (state, storeState: any) => storeState.units.allUnits,
    (state, storeState: any) => storeState.devices.allDevices,
    (state, storeState: any) => storeState.systems.allSystems,
    (state, storeState: any) => storeState.types.allTypes.eventTypes],
    (allSites, allUnits, allDevices, allSystems, eventTypes) => (alert: any) => {
      return mapAlertFields(allSites, allDevices, allSystems, allUnits, eventTypes, alert);

    }),
  getAlertsByTime: thunk(async (actions, payload: any = "") => {
    return coolremoteSDK.Event.getEvents(payload);
  }),
  setAcknowledgedEvent: thunk(async (actions, payload) => {
    const { id, data } = payload;
    return coolremoteSDK.Event.setAcknowledgedEvent(id, { value: data });
  })
};

const mapAlertFields = (sites: any, devices: any, systems: any, units: any, eventTypes: any, alert: any) => {

  const { type, resources = [], status } = alert;
  const fieldsObj: any = { eventNames: { siteName: "", deviceName: "", systemName: "", unitName: "" }, eventIds: { siteId: "", systemId: "", unitId: "", unitIds: [], systemIds: [] } };
  if (resources.length === 0) { return fieldsObj; }

  const { eventNames, eventIds } = fieldsObj;

  //device disconnected
  if (eventTypes.deviceDisconnected === type) {
    const device = devices[resources[0].id];
    eventNames.deviceName = device ? device.name : (resources[0].name || "");
    eventNames.siteName = (device && sites[device.site].name) || "";
    eventIds.siteId = device ? device.site : "";
    if (status === 1) { eventNames.unitName = eventNames.deviceName; }
    return fieldsObj;
  }

  let siteId = "";
  let deviceName = "";
  for (let i in resources) {
    const { id = "", name = "" } = resources[i];
    const storeUnit: any = units[id];

    if (storeUnit) {
      const { site = "", system = "", device = "" } = storeUnit;
      if (!siteId) {
        siteId = site;
      }

      if (!deviceName) {
        deviceName = devices[device]?.name || "";
      }

      (system && !eventIds.systemIds.includes(system)) && eventIds.systemIds.push(system);
      eventIds.unitIds.push(id);
    }
  }

  if (_.isEmpty(eventIds.unitIds)) {
    return fieldsObj;
  }

  const unitId = eventIds.unitIds.length === 1 ? eventIds.unitIds[0] : "";
  const systemId = eventIds.systemIds.length === 1 ? eventIds.systemIds[0] : "";
  eventNames.unitName = unitId ? units[unitId]?.name : "multiple";
  eventNames.systemName = systemId ? systems[systemId]?.name : _.isEmpty(eventIds.systemIds) ? "" : "multiple";
  eventIds.unitId = unitId;
  eventIds.systemId = systemId;
  eventNames.siteName = sites[siteId]?.name || "";
  eventNames.deviceName = deviceName;
  eventIds.siteId = siteId;

  return fieldsObj;

};

const getErrorDescription = (errorCodeTypes: any = {}, brand: any, errorCode: any) => {
      if (!brand && brand !== 0){
        return "";
      }

      const brandsErrors = errorCodeTypes[brand] || {};
      if (_.isEmpty(brandsErrors)){
        return "";
      }

      return brandsErrors[errorCode] || brandsErrors[+errorCode] || "";
    };
