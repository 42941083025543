import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectionsContainer: {
    whiteSpace: "nowrap",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    width: "100%",
    maxWidth: "calc(100% - 169px)",
    height: "100%"
  },
  selectUnitButton: {
    width: "200px",
    maxWidth: "calc(20% - 10px)",
    height: 36,
    justifyContent: "space-between",
    margin: 0,
    marginRight: "10px",
    padding: "0 .4rem 0 .5rem",
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.05)",
    border: "solid 1px #d5d2d5",
    color: "#545964",
    "&:hover": {
      border: "solid 1px #aaa2aa",
      backgroundColor: "#f6f6f7"
    },
    "&>svg": {
      fontSize: "2.5rem"
    }
  },
  selectUnitButton__text: {
    fontSize: "0.8125rem",
    fontWeight: "normal"
  },
  selectUnitButton__icon: {
    fontSize: "36px !important"
  },

  selectDateRangeButton: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.05)",
    border: "solid 1px #d5d2d5",
    color: "#545964",
    width: "30ch",
    maxWidth: "calc(20% - 10px)",
    height: 36,
    justifyContent: "space-between",
    padding: "0 .4rem 0 .5rem",
    marginRight: "10px",
    textTransform: "none",
    path: {
      color: "#545964"
    },
    rect: {
      color: "#545964"
    },
    svg: {
      color: "#545964"
    },
    stroke: "#545964",

    "&>path": {
      stroke: "red"
    },
    "&:hover": {
      border: "solid 1px #aaa2aa",
      backgroundColor: "#f6f6f7"
    },
    minWidth: 242
  },
  selectDateRangeButton__text: {
    fontSize: "0.8125rem",
    fontWeight: "normal"
  },
  selectDateRangeButton__icon: {
    "&>svg": {
      fontSize: "1.5rem"
    }
  },
  rightButtonContainer: {
    paddingLeft: "20px"
  },
  selectedUnitStyle: {
    fontSize: "0.8125rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  dropdownStyle: {
    maxWidth: "calc(20% - 10px)"
  },
  paperBg: { background: "transparent" },
  datePicker: {
    input: {
      color: "white"
    }
  },
  btnDisabled: {
    cursor: "default",
    pointerEvents: "none"
  },
  flipIcon: {
    transform: "scaleY(-1)"
  }
}));
export default useStyles;
