import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    backgroundColor: "#f6f6f7",
    // color: "#29132e",
    color: "#4b1c46",
    borderBottom: "1px solid #d5d2d5",
    padding: "4px 24px"
  },
  titleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  container: {
    border: "1px solid #d5d2d5",
    borderRadius: "4px",
    marginBottom: "20px"
  },
  systemOverviewContent: {
    padding: ".5rem"
  },

  btn: {
    marginBottom: "1.5rem",
    marginRight: "1.5rem"
  },
  dialogWidth: {
    width: "1394px",
    maxWidth: "95%"
  },
  dialogHeader: {
   paddingLeft: 20,
   paddingRight: 13,
   backgroundColor: "#f6f6f7",
   borderBottom: "1px solid #d5d2d5",
   display: "flex",
   justifyContent: "space-between",
   alignItems: "center",
   height: 60,
   minHeight: 60
 },
  headerTitle: {
 fontFamily: "RobotoMedium",
 color: "#29132e",
 lineHeight: "normal",
 fontSize: 18,
 fontWeight: 500,
 letterSpacing: "normal"

 },
  dialogContent: {
   flex: "1 1 auto",
   padding: 20,
   overflowY: "auto",
   paddingBottom: 0
 },
  iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    }
});

export default useStyles;
