import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import moment from "moment-timezone";
import React from "react";
import { t } from "ttag";
import { Close } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import useStyles from "./AuditsList.style";

const dataPropertiesReplacement: { [key: string]: any } = {
  isRegistered: "Connected",
  isExpired: "Expired",
  eWrcEnableOnoff: "Disable on off actions",
  eWrcEnableMode: "Disable mode change actions",
  eWrcEnableSetpoint: "Disable setpoint control",
  eWrcEnableCoolMode: "Enable cool limits",
  eWrcEnableHeatMode: "Enable heat limits",
  enableOnoff: "Disable on off actions",
  enableMode: "Disable mode change actions",
  enableSetpoint: "Disable setpoint control",
  enableCoolMode: "Enable cool limits",
  enableHeatMode: "Enable heat limits",
  enableAutoMode: "Enable auto limits",

},
  notReadable = [
    "customer",
    "isAcceptedTOU",
    "_id",
    "__v",
    "user",
    "userLocationsMetadata",
    "password",
    "randId",
    "id",
    "device",
    "admin",
    "tzOffsetMinutes",
    "type",
    "unit",
    "enableOnState"
  ];

export default function AuditsDataPopUp(props: any) {
  const { audit } = props,
    types = useStoreState((s) => s.types.allTypes),
    {operationStatuses, operationModes, fanModes, swingModes, temperatureScale, actionSources} = types,

    classes = useStyles(),
    timeFormat = useStoreState((state) => state.users.timeFormat),

    valueMapping = (value: number, action: string) => {
      const lowerCaseAction = action.toLowerCase();
      if (lowerCaseAction.includes("setpoint")) { return value; }
      if (lowerCaseAction.includes("operationstatus")) { return operationStatuses[value] || value; }
      if (lowerCaseAction.includes("operationmode")) { return operationModes[value] || value; }
      if (lowerCaseAction.includes("fanmode")) { return fanModes[value] || value; }
      if (lowerCaseAction.includes("swingmode")) { return swingModes[value] || value; }
    },
    valueTitleMapping = (action: string) => {
      const lowerCaseAction = action.toLowerCase();
      if (lowerCaseAction.includes("setpoint")) { return "Setpoint"; }
      if (lowerCaseAction.includes("operationstatus")) { return "Operation Status"; }
      if (lowerCaseAction.includes("operationmode")) { return "Operation Mode"; }
      if (lowerCaseAction.includes("fanmode")) { return "Fan Mode"; }
      if (lowerCaseAction.includes("swingmode")) { return "Swing Mode"; }
    },
    replaceTitle = (property: string) => {
      const replacement = dataPropertiesReplacement[property];
      if (replacement) { return replacement; }
      const title = property
        .split(/(?=[A-Z])/)
        .join(" ")
        .trim();
      return title;
    },
    getActionText = (actionId: string) => {
      const actionArray = actionId.split(/(?=[A-Z])/);
      if (["update", "create", "accept", "delete"].includes(actionArray[0])) {
        actionArray[0] = actionArray[0] + "d";
      }

      actionArray[0] = actionArray[0].charAt(0).toUpperCase() + actionArray[0].substring(1);
      return actionArray.join(" ").trim();
    },
    convertDate = (stringDate: string) => {
      const {timezone} = audit;
      const date = timezone ? moment(stringDate).tz(timezone).format(`ll ${timeFormat}`) :  moment(stringDate).format(`ll ${timeFormat}`);
      return date;
    },
    DataRow = ({ property, content, action, index }: any) => {
      const contentText = typeof content === "boolean" ? (content ? "yes" : "no") : content;

      return (
        <div className={clsx(classes.mainRow, { [classes.grayRow]: index % 2 })}>
          <Typography className={classes.rowTitle}>
            {property === "value" ? t`${valueTitleMapping(action)}` : t`${replaceTitle(property)}`}
          </Typography>
          <Typography>
            {property === "value"
              ? t`${valueMapping(contentText, action)}`
              : property === "temperatureScale"
                ? t`${temperatureScale[contentText]}`
                : t`${contentText}`}
          </Typography>
        </div>
      );
    },
    MainDataRow = ({ title, content, index }: any) => (
      <div className={clsx(classes.mainRow, { [classes.grayRow]: index % 2 })}>
        <Typography className={classes.rowTitle}>{t`${title}`}</Typography>
        <Typography>{t`${content}`}</Typography>
      </div>
    ),
    getTimeFromMins = (mins: number) => {

      const h = mins / 60 | 0,
        m = mins % 60 | 0;
      return moment.utc().hours(h).minutes(m).format("hh:mm A");
    };
  let countedIndex = 0;
  const auditSource = (source: number) => {
    switch (source){
        case +actionSources.user: return t`Application User`;
        case +actionSources.schedule: return t`Scheduled Operation`;
        case +actionSources.homeAutomation: return t`Home automation / BMS`;
        case +actionSources.hvac: return t`HVAC`;
        default: return t`Unknown source`;
    }
  };

  return (
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
          <div className={classes.titleContainer}>
            <Typography className={classes.headerTitle}>{t`Audit Data`}</Typography>
            <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => props.setIsSeen(null)}>
              <Close color="#7f7692" />
            </IconButton>
          </div>
        <div className={classes.dialogContent}>
          <Card className={classes.container}>
            <Card className={classes.auditContent}>
              <MainDataRow title="Source" content={auditSource(+audit.source)} index={1} />
              <MainDataRow title="Customer" content={audit.customerName} index={0} />
              <MainDataRow title="Action" content={getActionText(audit.actionId).replace(/Trap/g, "Trigger")} index={1} />
              <MainDataRow title="Date" content={convertDate(audit.date)} index={0} />
              <MainDataRow title="User" content={audit.user} index={1} />
              <MainDataRow title="Site" content={audit.siteName} index={0} />
              <MainDataRow title="Device" content={audit.deviceName} index={1} />
              <MainDataRow title="Unit" content={audit.unitName} index={0} />
              {audit.line &&
                <MainDataRow title="Line" content={audit.line} index={0} />
              }
              {audit.data &&
                Object.keys(audit.data).map((key: any) => {
                  let content = audit.data[key];

                  if (
                    typeof content !== "string" &&
                    typeof content !== "number" &&
                    typeof content !== "boolean"
                  ) {
                    return null;
                  }

                  if (notReadable.includes(key)) {
                    return null;
                  }
                  if (typeof content === "number" && (key === "powerOffTime" || key === "powerOnTime")) {
                    content = getTimeFromMins(content);
                  }
                  countedIndex++;
                  return (
                    <DataRow
                      key={`audit-data-${countedIndex}`}
                      property={key}
                      content={content}
                      action={audit.actionId}
                      index={countedIndex}
                    />
                  );
                })}
            </Card>
          </Card>
        </div>
      </Dialog>
  );
}
