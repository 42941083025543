import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  card: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    border: 'solid 1px #ebebeb',
    fontSize: '1.25rem'
  },

  // card: {
  //   fontSize: '1.25rem',
  // },

  text: {
    fontSize: '1.2rem'
  },

  unitInfoHeader: {
    display: 'flex'
  },

  unitInfoTitle: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    color: '#421a40',
    padding: '0.8rem 0.8rem 0',
    textTransform: "capitalize"
  },

  unitInfoDisconnectedText: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    color: 'red',
    padding: '0.8rem 0.8rem 0'
  },

  itemsWrapper: {
    display: 'flex',
    marginLeft: '0.4rem',
    overflowX: "hidden"
  },

  item: {
    borderLeft: '1px solid #ebebeb',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingRight: '1%',
    paddingLeft: '1%',
    marginBottom: '0.7rem',
    marginTop: '0.7rem',
  },
  firstItem: {
    borderLeft: 'none',
  },

  '@media (min-width: 1270px)': {
    item: {
      padding: '0 auto 0 0.8rem'
    }
  },

  'item:last-child': {
    borderRightWidth: '0px',
    paddingRight: '1rem'
  },

  infoTitle: {
    fontSize: '0.8125rem',
    color: '#545964',
    fontFamily: 'RobotoLight',
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  info: {
    fontSize: '0.8125rem',
    color: '#29132e',
    fontFamily: 'RobotoMedium',
    width: "100%"
  },
  unitName: {
    // display: -webkit-box;
    // overflow: hidden;
    // /* font-size: 16px; */
    // max-height: 60px;
    // /* align-items: center; */
    // /* font-weight: bold; */
    // /* padding-left: 10px; */
    // justify-content: space-between;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 2;
  },
  multiLine: {
    // width: 21ch;
    display: "-webkit-box",
    height: "38px",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    whiteSpace: "unset"
  },
  limited20: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  oneLineNoEllipse: {
    overflow: "hidden",
    whiteSpace: "nowrap"
  }
});

export default useStyles;
