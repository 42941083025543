import { Grid, Typography } from "@material-ui/core/";
import clsx from "clsx";
import React, { Fragment, useState } from "react";
import { PulseLoader } from "react-spinners";
import { t } from "ttag";
import { symbols} from "../../../../constants/sensorsUnitsSymbols";
import { Switch } from "../../../../cool_widgets/Switch";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import { ClosedSensor, CO2, Humidity, OpenSensor, SwitcherOffIcon as SvgPowerOff, SwitcherOnIcon as SvgPowerOn, TempSensor} from "../../../../svgComponents";
import useStyle from "../UnitItem/UnitItem.style";

const icons: any = {
    1: <TempSensor style={{height: 24}}/>,
    2: <CO2 />,
    3: "",
    4: "",
    5: <Humidity style={{width: 22}} />
    };

interface IObject {
  [key: string]: any;
}

const hasValue = (value: any) => {
    return !!value || value === 0;
  };

const SensorItem = (props: any) => {

    const types = useStoreState((state) => state.types.allTypes);

    const allSensors = useStoreState((state) => state.sensors.allSensors);
    const {updateSensorValue, _storeUpdateSensor} = useStoreActions((actions) => actions.sensors);
    const { addMessage } = useStoreActions((action) => action.errorMessage);
    const { sensorId, siteId, selectItem, groupId, selectedItem, isCelsius } = props;
    const { sensorTypes, sensorMeasurementUnits  } = types;
    const item = allSensors[sensorId] || {};

    const classes = useStyle();
    const [loading, setLoading] = useState<boolean>(false);

    const {
      name,
      type,
      readingValue = 0,
      userData = {},
      id
    } = item;

    const sensorType = sensorTypes[type] || {};
    const {enableMinMaxSelection, measurementUnits, enableMeasurementUnitSelection, valueMax, valueMin} = sensorType;
    const activeMeasurementUnit = enableMeasurementUnitSelection && userData?.measurementUnitsType ? userData?.measurementUnitsType : measurementUnits;
    const sensorUnit = sensorMeasurementUnits[activeMeasurementUnit]?.name;
    const {rangeMax : userMax, rangeMin: userMin} = userData;
    const value = +readingValue;

    const displayValue = !sensorUnit || type === 130 || type === 7 ? (enableMinMaxSelection ? hasValue(userMax) && hasValue(userMin) ?
  (((value - valueMin) * (+userMax - +userMin)) / (valueMax - valueMin)) + +userMin : value :  value).toFixed(1)  :
  sensorUnit === "Temperature" ? (isCelsius || type === 7 ? value : (Math.round(((value * 9 / 5) + 32) * 10) / 10)) :
  sensorUnit === "Open/Close" ?  type === 129 ? +value === 0 ? t`OFF` : t`ON` : (+value === 0 ? t`OPEN` : t`CLOSE`) : value;

    const selected = !selectedItem.isGroup && selectedItem.sensorId === sensorId && selectedItem.groupId === groupId;

    const handleOnOff = (event: any) => {
      event.stopPropagation();
      event.preventDefault();

      setLoading(true);
      const updateValueTo = +value === 0 ? 1 : 0;
      updateSensorValue({id, data: {value: updateValueTo}})
      .then(() => {
        _storeUpdateSensor({id, data: {readingValue: updateValueTo}});
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      }).finally(() => setLoading(false));

    };

    const switchClick = (event: any) => {
      event.stopPropagation();
      event.preventDefault();
    };

    return (
    <div
      className={clsx(classes.unitStyle, {[classes.selected]: selected})}
      onClick={() =>
         selectItem(siteId, {isGroup: false, sensorId, groupId, type: "sensor"})
      }
    >
        <Fragment>
          <div className={classes.locSec}>
            <Typography noWrap className={clsx(classes.nameStyle, {[classes.boldText]: selected})}>{name}</Typography>
          </div>
              <div className={classes.tempSec}>
              <div className={classes.iconDiv}>
                { type !== 6 ?
                icons[activeMeasurementUnit] || ""
                : value === 0 ? <OpenSensor  height="70" width="70" className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)}/>
                                : <ClosedSensor height="70" width="70" className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)} />}
                </div>
                <Typography className={clsx(classes.tempTextSensor, {[classes.boldText]: selected, [classes.offText] : t`OFF` === displayValue})}>
                  {displayValue}{symbols[sensorUnit || ""] || ""}
                </Typography>
              </div>
                {/* this grid is to keep icon and reading value in middle instead of right */}
              <Grid className={classes.onOffSensor}>
              {type === 129 && <>
                <div>
                {loading && (
                  <PulseLoader size={5} color="white" margin="3px" />
                )}
                </div>
                <Switch
                checked={value === 1}
                disableRipple={true}
                onClick={(e: any) => switchClick(e)}
                switchChange={(e: any) => handleOnOff(e)}
                value="checkedA"
                />
                </>}

              </Grid>
        </Fragment>
    </div>
  );
};

export default SensorItem;
