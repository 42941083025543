export default (permissions: any) => {
  const { customers = {}, sites } = permissions;

  if (!customers && permissions === "globalAdmin") {
    return "Global Admin";
  }

  const customersKeys = Object.keys(customers);

  if (customers[customersKeys[0]] === "customerAdmin") {
    return "Admin";
  }

  if (customers[customersKeys[0]] === "customerManager") {
    return "Manager";
  }

  if (customers[customersKeys[0]] === "customerGuest") {
    const mainPermissionKeys = Object.keys(permissions);
    const isUserSiteViewer =
      mainPermissionKeys.length === 2 &&
      mainPermissionKeys.includes("customers") &&
      mainPermissionKeys.includes("sites");
    const isUserSystemViewer =
      mainPermissionKeys.length === 2 &&
      mainPermissionKeys.includes("customers") &&
      mainPermissionKeys.includes("systems");
    const isSiteAdmin =
      mainPermissionKeys.includes("sites") &&
      Object.values(sites).includes("siteAdmin");
    return isSiteAdmin
      ? "siteAdmin"
      : isUserSiteViewer
        ? "Site Viewer"
        : isUserSystemViewer ? "System Viewer" : "Guest";
  }
  return "Unknown";
};
