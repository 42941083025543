import * as React from "react";

function SvgHotMode(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 19" {...props}>
      <g stroke="#F05146" fill="none" fillRule="evenodd" strokeLinecap="round">
        <path
          strokeWidth={0.775}
          d="M13.971 9.472a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z"
        />
        <path
          strokeWidth={0.944}
          d="M9.471 18.472v-2.381m0-13.238V.472m4.5 16.844l-1.212-2.098M6.139 3.751L4.972 1.729m10.209 11.09h.002l2.083 1.204M3.717 6.199l-2.04-1.178m16.795 4.502h-2.383M2.854 9.522H.472m16.794-4.5L15.226 6.2M1.677 14.023l2.083-1.204h.001m10.21-11.09l-1.167 2.022M6.183 15.218l-1.211 2.098"
        />
      </g>
    </svg>
  );
}

export default SvgHotMode;
