import {
  Button as MuiButton,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Slider,
  TextField,
  Typography
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import { isIOS } from "react-device-detect";
import { t } from "ttag";
import Switch from "../../../components/Switch/Switch";
import TimePicker from "../../../components/TimePicker/TimePicker";
import scheduletypes from "../../../constants/scheduletypes";
import Button from "../../../cool_widgets/Button";
import Checkbox from "../../../cool_widgets/CoolCheckbox";
import DaysList from "../../../cool_widgets/DaysList/DaysList";
import {Check, CheckboxChecked, Close} from "../../../icons";
import { useStoreActions, useStoreState } from "../../../models/RootStore";
import {
  isEndStampLaterThanStartStamp,
  minsToTime,
  stringTimeToUTCMins
} from "../../../services/timeService";
import { ArrowDownControl , ArrowUp, AutoMode, ColdBlue, HotRed, SearchIcon} from "../../../svgComponents";
import useStyles from "./AddEditSchedulePopup.style";

const startHours: any = {
  "12:00am": true,
  "00:00": true,
  "00:00am": true
};
//ask about 12:01111 pm? and 00 -- 12am
const endHours: any = {
  "11:59pm": true,
  "23:59": true
};

export default function ErrorBox(props: any) {
  const classes = useStyles();
  const { onClose, schedule = "new", itemId, scheduleId, item, updateLocalSchedules } = props;
  const {temperatureLimits} = item;

  const [powerOnTime, setPowerOnTime] = useState<string>("");
  const [powerOffTime, setPowerOfftime] = useState<string>("");
  const [days, setDays] = useState<[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [openPicker, setOpenPicker] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [tempLimits, setTempLimits] = useState<any>(temperatureLimits);
  const [eWrcEnableOnoff, setEnableOnOff] = useState<boolean>(false);
  const [eWrcEnableMode, setEnableMode] = useState<boolean>(false);
  const [eWrcEnableSetpoint, setEnableSetpoint] = useState<boolean>(false);
  const [eWrcEnableCoolMode, setEnableCoolMode] = useState<boolean>(false);
  const [eWrcEnableHeatMode, setEnableHeatMode] = useState<boolean>(false);
  const [eWrcEnableAutoMode, setEnableAutoMode] = useState<boolean>(false);
  const [allDay, setAllDay] = useState<boolean>(false);

  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const types = useStoreState((state) => state.types.allTypes);
  const me = useStoreState((state) => state.users.me);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay());
  const { temperatureScale, timeFormat: timeFormatEnum } = me;
  const { weekDays = [""] } = types;

  const addMessage = useStoreActions((actions) => actions.errorMessage.addMessage);
  const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);
  const createObjectSchedules = useStoreActions((actions) => actions.schedules.createObjectSchedules);

  const is12Hours = timeFormatEnum === 1 ?  true : false;
  const [weekDaysOptions] = useState<any>(Object.keys(weekDays));

  const passScheduleInfo = (schedule: any) => {
  const { powerOnTime, powerOffTime, name, days = [], eWrcEnableOnoff, eWrcEnableSetpoint,
          eWrcEnableMode, eWrcEnableCoolMode, eWrcEnableHeatMode, eWrcTemperatureLimits } = schedule;
  const powerOn = minsToTime(powerOnTime, timeFormat);
  const powerOff = minsToTime(powerOffTime, timeFormat);

  if (startHours[powerOn] && endHours[powerOff]) {setAllDay(true); }
  else {setAllDay(false); }

  setPowerOnTime(powerOn);
  setPowerOfftime(powerOff);
  setName(name);
  setDays(days as []);
  setEnableOnOff(!!eWrcEnableOnoff);
  setEnableMode(!!eWrcEnableMode);
  setEnableSetpoint(!!eWrcEnableSetpoint);
  setEnableCoolMode(!!eWrcEnableCoolMode);
  setEnableHeatMode(!!eWrcEnableHeatMode);
};

  const hasValue = (value: any) => {
  return !!value || value === 0;
};

  useEffect(() => {
  if (schedule === "new"){
    return;
  }

  passScheduleInfo(schedule);
}, []);

  const checkRequiredFields = () => {
  setErrorMsg("");

  if (!name){
      setErrorMsg("Name is required");
      return false;
  }

  if (!powerOnTime || !powerOffTime) {
    setErrorMsg("Add start And end hour");
    return false;
  }

  if (days.length === 0) {
    setErrorMsg("Pick one day at least");
    return false;
  }

  if (!isEndStampLaterThanStartStamp(powerOnTime, powerOffTime, is12Hours)) {
    setErrorMsg("End hour must be later than start hour");
    return false;
  }

  return true;
};

  const editSchedule = () => {

  const startHour = stringTimeToUTCMins(powerOnTime, is12Hours);
  const endHour =  stringTimeToUTCMins(powerOffTime, is12Hours);

  updateSchedule({
    id: scheduleId,
    data: {
      days,
      powerOnTime: startHour,
      powerOffTime: endHour,
      name,
      type: scheduletypes.eDeviceLimits,
      eWrcTemperatureLimits: tempLimits,
      eWrcEnableOnoff,
      eWrcEnableMode,
      eWrcEnableSetpoint,
      eWrcEnableCoolMode,
      eWrcEnableHeatMode,
      eWrcEnableAutoMode
    }
  })
    .then((schedule: any) => {
      updateLocalSchedules({...schedule, eWrcTemperatureLimits: tempLimits });
      onClose();
    })
    .catch((err: any) => {
      addMessage({
        message: err.message
      });
    });
};

  const createSchedule = () => {
  const startHour = stringTimeToUTCMins(powerOnTime, is12Hours);
  const endHour =  stringTimeToUTCMins(powerOffTime, is12Hours);

  createObjectSchedules({
    data: {
      days,
      powerOnTime: startHour,
      powerOffTime: endHour,
      name,
      type: scheduletypes.eDeviceLimits,
      eWrcTemperatureLimits: tempLimits,
      eWrcEnableOnoff,
      eWrcEnableMode,
      eWrcEnableSetpoint,
      eWrcEnableCoolMode,
      eWrcEnableHeatMode,
      eWrcEnableAutoMode
      // endActionType: defaultEndActionToSave
    },
    objId: itemId,
    objectType: "unit"
  })
    .then((schedule: any) => {
      updateLocalSchedules(schedule);
      onClose();
    })
    .catch((err: any) => {
      addMessage({
        message: err.message
      });
    });
};

  const save = () => {
const allRequiredNotEmpty = checkRequiredFields();

if (!allRequiredNotEmpty) {
  return;
}

if (scheduleId === "new") {
  createSchedule();
  return;
}

editSchedule();
};

  const addRemoveDay = (selectedDay: string) => {
    let currentDays: any = [...days];

    currentDays.includes(selectedDay)
      ? (currentDays = days.filter((day: string) => day !== selectedDay))
      : currentDays.push(selectedDay);

    setDays(currentDays);
};

  const currentDays: string[] = days;

  const weekDaysArray = Object.keys(weekDays);

  const onClear = () => {
  setOpenPicker("");
  openPicker === "start" ? setPowerOnTime("") : setPowerOfftime("");
  allDay && setAllDay(false);
};

  const onSetTime = (time: string) => {
  openPicker === "start" ? setPowerOnTime(time) : setPowerOfftime(time);
  setOpenPicker("");

  if ((openPicker === "start" && startHours[time] && endHours[powerOffTime]) ||
      (openPicker !== "start" && endHours[time] && startHours[powerOnTime])) {
              setAllDay(true);
  } else { setAllDay(false); }
};

  const hasTime = (time: any) => {
  return time !== "" && time !== null && time >= 0;
};

  const onInput = (event: any, powerTime: "start" | "end") => {
  const { target } = event.nativeEvent;

  if (target.defaultValue && target.defaultValue === target.value) {
    powerTime === "start" ? setPowerOnTime("") : setPowerOfftime("");
  }

  function iosClearDefault() {
    target.defaultValue = "";
  }
  window.setTimeout(iosClearDefault, 0);
};

  const handlePowerOffTime = (event: any) => {
  const {
    target: { value }
  } = event;

  setPowerOfftime(value || "");
};

  const handlePowerOnTime = (event: any) => {
  const {
    target: { value }
  } = event;

  setPowerOnTime(value || "");
};

  const hasTimeIOS = (time: number | string) => {
  return time || time === 0;
};

  const valueLabelFormat = (value: number) => {
    const valueToShow = value + temperatureSymbol;
    return `${valueToShow}`;
};

  const handleCoolRange = (event: any, newValue: number | number[]) => {
    setTempLimits({...tempLimits, 0: newValue});
};

  const handleHeatRange = (event: any, newValue: number | number[]) => {
  setTempLimits({...tempLimits, 1: newValue});
};

  const handleAllDay = (event: any) => {
    const {target: {checked}} = event;
    if (checked){
      setPowerOnTime(is12Hours ? "12:00am" : "00:00");
      setPowerOfftime(is12Hours ? "11:59pm" : "23:59");
      setAllDay(true);
    } else{
      setPowerOnTime("");
      setPowerOfftime("");
      setAllDay(false);
  }
};

  return (
    <div className={classes.filtersPopup}>
    <div className={classes.filtersContainer}>
    <div className={classes.menuHeader}>
    <Typography className={classes.mainTitle}>{t`Add / Edit WRC Schedule`}</Typography>
    <IconButton className={classes.iconBtn} onClick={onClose}><Clear /></IconButton>
    </div>
    <div style={{maxHeight: "calc(100% - 100px)", overflow: "auto", padding: "0 10px"}}>
    <div className={classes.flexContainer}>
      <Typography className={classes.label}>{t`Schedule Name`}</Typography>
      <TextField
        value={name}
        onChange={(event: any) => setName(event.target.value)}
        className={classes.textFieldStyle}
        placeholder={t`Schedule Name`}
        variant="outlined"
      />
    </div>
    <div className={classes.flexContainer}>
    <Typography className={classes.checkboxSection}>
      <Checkbox
      color="default"
      edge="end"
      variant="outlined"
      onChange={handleAllDay}
      checked={allDay}
      onClick={(event: any) => event.stopPropagation()}
      checkedIcon={<CheckboxChecked />}
      className={classes.checkboxStyle}
      />
      {t`All Day`}
    </Typography>
    <div className={classes.btnsHolder}>

    {isIOS ? (
        <>
          <div
            className={clsx(classes.iosHourButton, {
              [classes.timeSelected]: !!powerOnTime
            })}
          >
            <InputLabel
            className={classes.iosTimeLabel}
            >
              {powerOnTime
                ? powerOnTime
                : "Start Hour"}
            </InputLabel>
            <Input
              className={classes.iosTimeInput}
              disableUnderline
              type="time"
              value={powerOnTime}
              onChange={handlePowerOnTime}
              onInput={(event: any) => onInput(event, "start")}
            />
          </div>
          <div
            className={clsx(classes.iosHourButton, {
              [classes.timeSelected]: !!powerOffTime
            })}
          >
            <InputLabel
            className={classes.iosTimeLabel}
            >
              {powerOffTime ? powerOffTime : "End Hour"}
            </InputLabel>
            <Input
              className={classes.iosTimeInput}
              disableUnderline
              type="time"
              value={powerOffTime}
              onChange={handlePowerOffTime}
              onInput={(event: any) => onInput(event, "end")}
            />
          </div>
        </>
      ) : (
          <>
            <MuiButton
              variant="contained"
              disableRipple
              disableElevation
              className={clsx(classes.timeContainer, {
                [classes.timeSelected]: !!powerOnTime
              })}
              onClick={() => setOpenPicker("start")}
            >
              {powerOnTime ? powerOnTime : "Start Hour"}
            </MuiButton>
            <MuiButton
              variant="contained"
              disableRipple
              disableElevation
              className={clsx(classes.timeContainer, {
                [classes.timeSelected]: !!powerOffTime
              })}
              onClick={() => setOpenPicker("end")}
            >
              {powerOffTime ? powerOffTime : "End Hour"}
            </MuiButton>
          </>
        )}
    </div>
    </div>
    <div className={classes.flexContainer}>
      <Typography className={classes.textStyle}>{t`Choose Days`}</Typography>
      <DaysList
        days={weekDaysOptions}
        activeDays={days}
        action={addRemoveDay}
        isMobile
      />
    </div>

    <Typography className={classes.rowSectionTitle}>{t`On & Off`}
        <Switch
        checked={eWrcEnableOnoff}
        disableRipple={true}
        switchChange={(e: any) => setEnableOnOff(!eWrcEnableOnoff)}
        value="checkedA"
        />
    </Typography>
    {/*<Typography className={classes.rowSectionTitle}>{t`Off Only`}
        <Switch
        checked={eWrcEnableOffOnly}
        disableRipple={true}
        switchChange={(e: any) => setEnableOffOnly(!enableOffOnly)}
        value="checkedA"
        />
    </Typography>*/}
    <Typography className={classes.rowSectionTitle}>{t`Operation Mode`}
        <Switch
        checked={eWrcEnableMode}
        disableRipple={true}
        switchChange={(e: any) => setEnableMode(!eWrcEnableMode)}
        value="checkedA"
        />
    </Typography>
    <Typography className={classes.rowSectionTitle}>{t`Setpoint Change`}
        <Switch
        checked={eWrcEnableSetpoint}
        disableRipple={true}
        switchChange={(e: any) => setEnableSetpoint(!eWrcEnableSetpoint)}
        value="checkedA"
        />
    </Typography>
  {/*  <Typography className={classes.rowSectionTitle}>{t`WRC min/max Setpoint cooling`}
        <Switch
        // checked={}
        disableRipple={true}
        switchChange={(e: any) => {}}
        value="checkedA"
        />
    </Typography> */}

    <div className={classes.controlSectionContainer}>
      <div className={classes.titleContainer}>
          <Typography className={classes.controlSectionTitle}>
            <ColdBlue className={classes.coldIcon}/>
            {t`Min/max Setpoint cooling`}
          </Typography>
        <Switch
        checked={eWrcEnableCoolMode}
        disableRipple={true}
        switchChange={(e: any) => setEnableCoolMode(!eWrcEnableCoolMode)}
        value="checkedA"
        />
      </div>
      <div className={classes.sliderHolder}>
      <Slider
          className={classes.coolSlider}
          onChange={handleCoolRange}
          // defaultValue={supportedValues.temperatureLimits[0][temperatureScale]}
          aria-labelledby="discrete-slider-always"
          min={temperatureScale === 1 ? 0 : 32}
          max={temperatureScale === 1 ? 50 : 122}
          value={tempLimits[0]}
          valueLabelDisplay="on"
          valueLabelFormat={valueLabelFormat}
          step={1}
          disabled={!eWrcEnableCoolMode}
      />
      </div>
    </div>
    <div className={classes.controlSectionContainer}>
      <div className={classes.titleContainer}>
          <Typography className={classes.controlSectionTitle}>
            <HotRed className={classes.coldIcon} style={{transform: "scale(1.778)"}}/>
            {t`Min/max Setpoint heating`}
          </Typography>
        <Switch
        checked={eWrcEnableHeatMode}
        disableRipple={true}
        switchChange={(e: any) => setEnableHeatMode(!eWrcEnableHeatMode)}
        value="checkedA"
        />
      </div>
      <div className={classes.sliderHolder}>
      <Slider
          className={classes.heatSlider}
          onChange={handleHeatRange}
          aria-labelledby="discrete-slider-always"
          min={temperatureScale === 1 ? 0 : 32}
          max={temperatureScale === 1 ? 50 : 122}
          value={tempLimits[1]}
          valueLabelDisplay="on"
          valueLabelFormat={valueLabelFormat}
          step={1}
          disabled={!eWrcEnableHeatMode}
      />
      </div>
    </div>
{  /*  <div className={clsx(classes.controlSectionContainer, classes.removeBorder)}>
      <div className={classes.titleContainer}>
          <Typography className={classes.controlSectionTitle}>
            <AutoMode maincolor="#aaa2aa" className={classes.autoIcon} />
            {t`Auto Mode Temp Settings`}
          </Typography>
        <Switch
        checked={eWrcEnableAutoMode}
        disableRipple={true}
        switchChange={(e: any) => setEnableAutoMode(eWrcEnableAutoMode)}
        value="checkedA"
        />
      </div>
      <div className={classes.sliderHolder}>
      <Slider
          className={classes.autoSlider}
          // onChange={() => {}}
          // defaultValue={supportedValues.temperatureLimits[0][temperatureScale]}
          aria-labelledby="discrete-slider-always"
          min={temperatureScale === 1 ? 0 : 32}
          max={temperatureScale === 1 ? 50 : 122}
          // value={supportedValues.temperatureLimits[0]}
          value={40}
          valueLabelDisplay="on"
          valueLabelFormat={valueLabelFormat}
          step={1}
          // ValueLabelComponent={ValueLabelComponent}
          disabled={eWrcEnableAutoMode}
      />
      </div>
    </div>*/}
      </div>
      </div>
      {errorMsg && (
        <InputLabel
          className={classes.errorLabelStyle}
        >{errorMsg}</InputLabel>
      )}
      <Button
        className={classes.applyBtn}
        onClick={save}
        uppercase
      >{t`Save`}</Button>
      {!isIOS && (
        <TimePicker
          show={!!openPicker}
          onSet={onSetTime}
          time={openPicker === "start" ? powerOnTime : powerOffTime}
          onDismiss={() => setOpenPicker("")}
          onClear={onClear}
          is12Hours={is12Hours}
        />
      )}
    </div>
  );
}
