import React from 'react';

function SvgArrow(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 40 40" {...props}>
      <defs>
        <path
          id="arrow_svg__a"
          d="M6.118 6.867a.98.98 0 01-.696-.285L.52 1.681A.985.985 0 011.911.288l4.206 4.216L10.324.298a.98.98 0 011.382 1.383L6.804 6.582a.986.986 0 01-.686.285z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 26 17)">
        <mask id="arrow_svg__b" fill="#fff">
          <use xlinkHref="#arrow_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#arrow_svg__a" />
        <g fill="#7F7692" mask="url(#arrow_svg__b)">
          <path d="M0 0h12v8H0z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgArrow;
