import React from "react";

const SvgCold1 = (props: any) => (
  <svg width={40} height={40} {...props}>
    <path
      d="M19.631 3.096v33.5M24.56 6.878l-4.93 3.007-4.93-3.007m0 26.212l4.93-3.007 4.93 3.007m9.521-21.707l-28.9 16.926m28.135-10.763l-5.09-2.731.106-5.78M5.712 22.284l5.09 2.731-.107 5.78M5.096 11.521l28.953 16.851M10.844 9.162l.117 5.77-5.08 2.752M28.534 30.87l-.116-5.77 5.078-2.741"
      fill="none"
      fillRule="evenodd"
      stroke={props.colorblue || "#FDFDFE"}
      strokeLinecap="round"
      strokeWidth={1.8}
      opacity={props.opacity}
    />
  </svg>
);

export default SvgCold1;
