import { Card, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Clear } from "@material-ui/icons";
import React from "react";
import { t } from "ttag";
import ErrorLogs from "../../components/ErrorLogs/ErrorLogs";
import SystemOverview from "../../components/SystemOverview/SystemOverview";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import useStyles from "./SystemOverviewPopUp.style";

export default function SystemOverviewPopUp(props: { open: boolean, systemId: string, isUnit: boolean, handleClose: any }) {
    const { open, systemId, isUnit, handleClose } = props;
    const allSystems = useStoreState((s) => s.systems.allSystems);
    const system = allSystems[systemId];
    const classes = useStyles();
    // const handleAgree = () => {
    //     onSystemSelect(systemId);
    // };

    return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
                classes={{ paper: classes.dialogWidth }}
            >
                    <div className={classes.dialogHeader}>
                        <Typography className={classes.headerTitle}>{t`System Overview`}</Typography>
                        <IconButton disableRipple onClick={handleClose} className={classes.iconBtnStyle}>
                            <Close color="#7f7692" />
                        </IconButton>
                    </div>
                    <div className={classes.dialogContent}>
                    <Card className={classes.container}>
                        <Card className={classes.title}>
                            <Typography variant="h6">{t`System Information`}</Typography>
                        </Card>
                        <Card className={classes.systemOverviewContent}>
                            <SystemOverview system={system} hideTitle={true} />
                        </Card>
                    </Card>
                    <Card className={classes.container}>
                        <Card className={classes.title}>
                            <Typography variant="h6">{t`Alert Logs`}</Typography>
                        </Card>
                        <Card>
                            <ErrorLogs
                                systemId={systemId}
                                usePadding={true}
                                hideColumns={["customerName", "systemName", "status"]}
                                order={["alertType", "time", "unitName", "siteName", "alertType1", "description", "errorCode"]}
                                showTitle={false}
                                hideAlertOfType={["2"]}
                            />
                        </Card>
                    </Card>
                    </div>
                {/* <DialogActions>
                    <Button onClick={handleAgree} disabled={isUnit}
                        white uppercase width={210} className={classes.btn}>
                        {t`System Diagnostics`}
                    </Button>
                </DialogActions> */}
            </Dialog>
    );
}
