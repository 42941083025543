import _ from 'lodash';
import React from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import { t } from 'ttag';
import { useStoreState } from '../../models/RootStore';
import { IDevice } from '../../models/Devices';
import { ISystem } from '../../models/Systems';
import { IUnit } from '../../models/Units';
import SystemInfoPanel from './SystemInfoPanel';
import OutdoorUnitInfoPanel from './OutdoorUnitInfoPanel';
import { systemOverviewStyle } from './SystemOverview.style';

interface IProps {
  system: ISystem;
  hideTitle?: boolean;
}

const SystemOverview: React.FC<IProps> = (props: IProps) => {
  const { system, hideTitle } = props;
  const classes = systemOverviewStyle();

  const allDevices = useStoreState(state => state.devices.allDevices);
  const getDeviceUnits = useStoreState(state => state.devices.getDeviceUnits);

  const systemDevice: IDevice | undefined = allDevices[system.device];
  const systemOutdoorUnits = systemDevice
    ? getDeviceUnits(systemDevice.id, system.id, 'outdoor')
    : [];

  return (
    <Paper classes={{ elevation1: classes.elevation }} className={classes.root}>
      <div>
        <div className={classes.header}>
          {!hideTitle && <Typography className={classes.title}>{t`System Overview`}</Typography>}
        </div>
        <SystemInfoPanel system={system} />
      </div>
      <Grid container direction={'row'} className={classes.compressorsContainer}>
        {_.map(systemOutdoorUnits, (unit: IUnit, index: number) => (
          <Grid key={index} item>
            <OutdoorUnitInfoPanel key={unit.id} unit={unit} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default SystemOverview;
