import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  menuHeader: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    color: "black",
    fontSize: "0.9375rem",
    height: 31.2
  },
  icon: {
    color: "#7f7692",
    paddingTop: "5px",
    fontSize: "1.5em"
  },
  menuTitle: {
    cursor: "pointer",
    display: "inline",
    lineHeight: "31.2px",
    verticalAlign: "middle",
    padding: "0 0.5rem",
    width: "100%",
    fontFamily: "Roboto",
    fontSize: "0.8125rem"

  },
  selectedItem: {
    backgroundColor: "white",
    color: "black",

    "&>span": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
});

export default useStyles;
